"use strict";

const cache: Record<string, RegExp> = {};
const start = "(?:^|\\s)";
const end = "(?:\\s|$)";

export const lookupClass = (className: string) => {
  let cached = cache[className];
  if (cached) {
    cached.lastIndex = 0;
  } else {
    cache[className] = cached = new RegExp(start + className + end, "g");
  }
  return cached;
};

function addClass(el: HTMLElement, className: string) {
  const current = el.className;
  if (!current.length) {
    el.className = className;
  } else if (!lookupClass(className).test(current)) {
    el.className += " " + className;
  }
}

function rmClass(el: HTMLElement, className: string) {
  el.className = el.className.replace(lookupClass(className), " ").trim();
}

export const add = addClass;
export const rm = rmClass;
