import { CSSProperties } from "@emotion/serialize";
import styled from "@emotion/styled";
import { includes } from "ramda";
import React, { DetailedHTMLProps, HTMLAttributes, PropsWithChildren, RefObject } from "react";
import { useDragAndDropContext } from "../providers/DragAndDropProvider/DragAndDropProvider";
import { dndClasses, useDragAndDrop, useDraggable } from "../providers/DragAndDropProvider/useDragAndDrop";

export interface DraggableProps<TData = any> extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  /**
   * control of the dnd, get from useDragAndDrop hook
   */
  control?: ReturnType<typeof useDragAndDrop>;
  /**
   * Storing data which are propagated to dropped area
   */
  data?: TData;
  /**
   * Set styles for drag states
   * @example
   *
   * // Define state styles for dragging
   * const statesStyles = {
   *   dragging: {
   *     color: 'blue',
   *     background: 'yellow',
   *     //...other style properties
   *   }
   * };
   *
   */
  statesStyles?: {
    dragging?: CSSProperties;
    hover?: CSSProperties;
  };
  isDraggable?: boolean;
  ref?: RefObject<HTMLDivElement>;
}

/**
 * Draggable component allows an element to be draggable within a draggable context.
 */
export const Draggable = <TData = any,>({
  control,
  data,
  statesStyles,
  isDraggable,
  children,
  ...props
}: PropsWithChildren<DraggableProps<TData>>) => {
  const controlContext = useDragAndDropContext();
  const dndControl = control ?? controlContext;
  const { ref } = useDraggable<TData>({ control: dndControl, data, isDraggable });
  return (
    <DraggableContainer
      ref={ref}
      {...props}
      draggingStyle={statesStyles?.dragging}
      hoverStyle={statesStyles?.hover}
      isDraggable={isDraggable}
    >
      {children}
    </DraggableContainer>
  );
};

export const DraggableContainer = styled("div", {
  shouldForwardProp: (propName) => !includes(propName, ["style", "draggingStyle", "hoverStyle", "isDraggable"]),
})<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    isDraggable?: boolean;
    draggingStyle?: Record<string, any> | undefined;
    hoverStyle?: Record<string, any> | undefined;
  }
>(({ draggingStyle, hoverStyle, isDraggable, style }) => ({
  ...style,
  cursor: isDraggable ? "grab" : "default",
  pointerEvents: isDraggable ? "inherit" : "none",
  [`&.${dndClasses.dragging}`]: draggingStyle ?? {},
  [`&.${dndClasses.draggable}:hover`]: isDraggable && hoverStyle ? hoverStyle : {},
}));
