import addressFormatter, { CommonOptions } from "@fragaria/address-formatter";
import { rejectFalsy } from "@sinch/utils/array";
import { add, dateTimeRangeFormat } from "@sinch/utils/dateTime/dateFns";

import { DurationUnitFormat } from "@sinch/utils/durationUnitFormat/DurationUnitFormat";
import { CountryCode, NumberFormat, parsePhoneNumber } from "libphonenumber-js";
import prettyBytes from "pretty-bytes";
import { includes, values } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import { useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";
import { useSettings } from "../providers/AppSettings";
import { ExpandedFormats, FormatScope, FormatterFn, NamedFormats } from "./format.types";

/**
 * Assign defined formats to formatting functions for easier calling formatting functions
 */
const assignFormatter = <
  Scope extends FormatScope,
  Method extends FormatterFn<Method> = (...args: unknown[]) => string,
>(
  formats: Record<string, unknown>,
  formatter: Method
): NamedFormats<Scope, Method> =>
  // For each format, create method with name of format and formatted method
  Object.keys(formats).reduce<NamedFormats<Scope, Method>>(
    (carry, key) => ({
      ...carry,
      [key]: (value: Parameters<Method>[0], additionalConfig?: Parameters<Method>[1]) =>
        formatter(value, { ...additionalConfig, format: key }),
    }),
    {} as NamedFormats<Scope, Method>
  );

/**
 * Hook for providing formatting methods for specified scopes,
 * @see frontend/src/core/Config/formats.tsx for format definition
 */
export const useFormat = () => {
  const { country } = useSettings();
  const {
    formatDate,
    formatTime,
    formatNumber,
    formatList: listFormatter,
    formats,
    formatDisplayName,
    locale,
  } = useIntl() as IntlShape & {
    formats: ExpandedFormats;
  };
  return useMemo(() => {
    // Assignee method for duration, duration is provided by library out of Formatjs lib
    const formatDuration = (value: number, { format }: { format?: keyof ExpandedFormats["duration"] }) => {
      const options = format ? { useFloat: false, ...formats.duration[format] } : undefined;
      const durationFormat = new DurationUnitFormat(locale.toLowerCase(), options);
      if (options?.useFloat) {
        // This hack can create duration with float values, eg. 1.5 hours
        return durationFormat
          .formatToParts(value)
          .map(({ type, value: partVal }) => {
            if (includes(type, values(DurationUnitFormat.units))) {
              const secondsInUnit = add(new Date(0), { [`${type}s`]: 1 }).getTime() / 1000;
              return formatNumber(value / secondsInUnit);
            }
            return partVal;
          })
          .join("");
      } else {
        return durationFormat.format(value);
      }
    };

    const formatByteUnits = (value: number, { format }: { format?: keyof ExpandedFormats["bytes"] }) => {
      const options = format ? formats.bytes[format] : {};

      if (!Number.isFinite(value)) {
        return prettyBytes(0, { ...options, locale });
      }
      return prettyBytes(value, { ...options, locale });
    };

    const formatPhone = (value: string, { format }: { format?: keyof ExpandedFormats["phone"] }) => {
      const formatVariant: NumberFormat = format ? formats.phone[format].format : "E.164";

      try {
        const isHomeCountryNumber = parsePhoneNumber(value, country as CountryCode)?.country === country;
        const includePrefix = format && formats.phone[format].foreignNumberPrefix;

        if (formatVariant === "NATIONAL" && includePrefix && isHomeCountryNumber) {
          return parsePhoneNumber(value, country as CountryCode)?.format("INTERNATIONAL") ?? value;
        }
        return parsePhoneNumber(value, country as CountryCode)?.format(formatVariant) ?? value;
      } catch (e) {
        console.warn(`Failed parsing phone number ${value}`, e);
        return value;
      }
    };

    const formatList = (
      value: string[] | readonly string[],
      { format }: { format?: string | keyof ExpandedFormats["list"] }
    ) => {
      const options = format ? formats.list[format as keyof ExpandedFormats["list"]] : {};

      return listFormatter(value, { ...options });
    };

    const addressFormat = addressFormatter.format;
    const formatAddress = (value: AddressParts, { format }: { format?: keyof ExpandedFormats["address"] }) => {
      const formatVariant: CommonOptions = format ? formats.address[format] : {};

      const addressParts = rejectFalsy({
        attention: value.name,
        street: value.address,
        city: value.city,
        region: value.region,
        postcode: value.zip,
      });
      const placePartsArray = [
        ...(addressFormat(addressParts, {
          ...formatVariant,
          appendCountry: false,
          countryCode: value.country && formatVariant.abbreviate ? value.country : undefined,
          output: "array",
        }) ?? values(addressParts)),
        // In almost all countries, country is last, expand functionality to show abbrevied country
        ...(value.country && formatVariant.appendCountry
          ? [
              value.country.match(/^\w{2}|\d{3}$/)?.[0] === value.country
                ? formatDisplayName(value.country ?? "", { type: "region" })
                : value.country,
            ]
          : []),
      ];

      return placePartsArray.filter(isNotNilOrEmpty).join(", ");
    };

    // Create objects with formatting functions
    const time = assignFormatter<"time", typeof formatTime>(formats?.time || {}, formatTime);
    const date = assignFormatter<"date", typeof formatDate>(formats?.date || {}, formatDate);
    const number = assignFormatter<"number", typeof formatNumber>(formats?.number || {}, formatNumber);
    const list = assignFormatter<"list", typeof formatList>(formats?.list || {}, formatList);
    const duration = assignFormatter<"duration", typeof formatNumber>(formats?.duration || {}, formatDuration);
    const unit = assignFormatter<"bytes", typeof formatNumber>(formats?.bytes || {}, formatByteUnits);
    const phone = assignFormatter<"phone", typeof formatPhone>(formats?.phone || {}, formatPhone);
    const address = assignFormatter<"address", typeof formatAddress>(formats?.address || {}, formatAddress);

    return {
      date,
      time,
      num: number,
      list,
      duration,
      unit,
      phone,
      address,
    };
  }, [formatDate, formatNumber, formatTime, formats, locale, country]);
};

interface AddressParts {
  address?: string | null;
  city?: string | null;
  country?: string | null;
  name?: string | null;
  region?: string | null;
  zip?: string | null;
}

type AdaptableUnit = "hour" | "minute" | "second";
/**
 * Simplify long duration to short or narrow when there is fraction of unit, eg. 1 hour 30 minutes -> 1 hr 30 min
 */
export const useAdaptableDuration = () => {
  const { duration } = useFormat();
  return (value: number, unit?: AdaptableUnit) => {
    if (unit === "second") {
      return duration.long(value);
    }
    if (unit === "minute") {
      return value % 60 === 0 ? duration.long(value) : duration.short(value);
    }
    const shortOrNarrow = value % 60 === 0 ? duration.short(value) : duration.narrow(value);
    return value % 3600 === 0 ? duration.long(value) : shortOrNarrow;
  };
};

type FormatMethods = ReturnType<typeof useFormat>;
/**
 * Format date range. IF start and end date are the same, only time is shown, otherwise date and time are shown
 */
export const useDateRangeFormat = (options?: {
  timeFormatter?: FormatMethods["time"][keyof FormatMethods["time"]];
  dateFormatter?: FormatMethods["date"][keyof FormatMethods["date"]];
}) => {
  const { date, time } = useFormat();
  const formatTime = options?.timeFormatter ?? time.short;
  const formatDate = options?.dateFormatter ?? date.short;
  return (start: Date, end: Date) =>
    dateTimeRangeFormat(formatTime(start), formatDate(start), formatTime(end), formatDate(end));
};
