import { FetchResult } from "@apollo/client";
import { DEFAULT_MAP_LAT, DEFAULT_MAP_LNG } from "@sinch/core/config/globals";
import { FullPlace } from "@sinch/core/entities/fragments.graphql";

import { useErrorLogger } from "@sinch/hooks/useErrorLogger";
import { useFormMutation } from "@sinch/hooks/useFormMutation";
import { useMuiForm } from "@sinch/hooks/useMuiForm";

import { useGoogleMapsApiLoad } from "@sinch/hooks/utilities/useGoogleMapsApiLoad";
import { setFormRootData } from "@sinch/utils/react-hook-form/setFormRootData";
import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { CreatePlace } from "../query.graphql";
import { CreatePlaceMutation, CreatePlaceMutationVariables } from "../query.types";
import { PlaceFormFields, placeSchema } from "./formSchema";
import { getCoordinates, parseAddress } from "./utils";

export const useLoadGoogleLibraries = () => useGoogleMapsApiLoad();

export const useNewLocationDialogForm = (form: UseFormReturn<PlaceFormFields>) => {
  const [mapCenterCoords, setMapCenterCoords] = useState({ lat: DEFAULT_MAP_LAT, lng: DEFAULT_MAP_LNG });

  const fillPlaceForm = (place: google.maps.places.PlaceResult | undefined) => {
    if (!place) {
      return;
    }
    const address = parseAddress(place);
    const coordinates = getCoordinates(place);

    setFormRootData(form, { name: place.name ?? "", ...address, coordinates });
    if (coordinates) {
      setMapCenterCoords(coordinates);
    }
  };

  return {
    mapCenterCoords,
    fillPlaceForm,
    setMapCenterCoords,
  };
};

export const useAddNewLocation = ({ open, onConfirm }: { open: boolean; onConfirm: (payload: any) => void }) => {
  const form = useMuiForm<PlaceFormFields>({ schema: placeSchema });

  const { handleSubmit, error, reset, loading, mutationMessages } = useFormMutation<
    CreatePlaceMutation,
    CreatePlaceMutationVariables,
    PlaceFormFields
  >({
    mutation: {
      mutate: CreatePlace,
      baseOptions: {
        update(cache, { data }: Omit<FetchResult<CreatePlaceMutation>, "context">) {
          if (!data?.createPlace?.result) {
            return;
          }
          cache.writeFragment({
            id: `Place:${data?.createPlace?.payload?.id}`,
            fragment: FullPlace,
            data: data?.createPlace?.payload,
          });
        },
      },
    },
    form,
    variableMapper: ({ coordinates, ...fields }) => ({ input: { ...fields, ...coordinates } }),
  });

  const submitForm = async () => {
    const response = await handleSubmit({
      errorMapper: (mutationResponse) =>
        mutationResponse.createPlace.messages?.map((message) => ({
          ...message,
          field: message?.field?.replace(/^Place\./, ""),
        })) ?? [],
    });
    if (response?.createPlace?.result) {
      const payload = response?.createPlace?.payload;
      if (payload) {
        form.reset();
        onConfirm(payload);
      }
    }
  };

  useEffect(() => {
    if (open) {
      reset();
      form.reset();
    }
  }, [open, reset, form]);

  const errorId = useErrorLogger(error);

  return {
    form,
    errorId,
    loading,
    mutationMessages,
    submitForm,
  };
};
