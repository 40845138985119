import { useTheme } from "@mui/material";
import {
  TransportAttendanceRole,
  TransportDirection as TransportDirectionEnum,
} from "@sinch/core/entities/serverEnums";
import { MdsLuggageFillXs, MdsNoLuggageXs, SinchDriverXs, SinchTransportBack, SinchTransportThere } from "@sinch/icons";
import type { TransportDirection } from "@sinch/types/sinch.types";

import { ElementType } from "react";
import { useTranslation } from "react-i18next";
import { useEntityProps } from "../utilsHooks";

/**
 * Hook for provide formatting and getting methods for Transport entity
 */
export const useTransportTrip = () => {
  const { t } = useTranslation();

  const getProp = useEntityProps({
    [TransportDirectionEnum.There]: {
      label: t("Transport.there"),
      icon: SinchTransportThere,
    },
    [TransportDirectionEnum.Back]: { label: t("Transport.back"), icon: SinchTransportBack },
  } as Record<TransportDirection | TransportDirectionEnum, { label: string; icon: ElementType }>);

  const getDirectionLabel = getProp<string>("label");
  const getDirectionIcon = getProp<ElementType>("icon");

  return { getDirectionLabel, getDirectionIcon };
};

/**
 * Hook for provide formatting and getting methods for Transport entity
 */
export const useTransportRole = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const transportRoles = {
    [TransportAttendanceRole.Driver]: {
      label: t("Transport.driver"),
      icon: SinchDriverXs,
      color: palette.custom.green["800"],
    },
    [TransportAttendanceRole.Passenger]: {
      label: t("Transport.passenger"),
      icon: MdsLuggageFillXs,
      color: palette.custom.blue["600"],
    },
    [TransportAttendanceRole.NoTransport]: {
      label: t("Transport.noTransport"),
      icon: MdsNoLuggageXs,
      color: palette.grey["800"],
    },
  } as Record<TransportAttendanceRole, { label: string; icon: ElementType; color: string }>;
  const getProp = useEntityProps(transportRoles);

  const getTransportRoleLabel = getProp<string>("label");
  const getTransportRoleIcon = getProp<ElementType>("icon");
  const getTransportRoleColor = getProp<string>("color");
  const getSortedTransportRole = () => Object.keys(transportRoles) as TransportAttendanceRole[];

  return {
    getTransportRoleLabel,
    getTransportRoleIcon,
    getTransportRoleColor,
    getSortedTransportRole,
  };
};
