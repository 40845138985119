import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
/* eslint-disable import/no-internal-modules */
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LinkPlugin as LexicalLinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
/* eslint-enable import/no-internal-modules */
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { Divider, Stack } from "@mui/material";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { FC, useMemo } from "react";

import { AlignMenu } from "../commands/AlignMenu";
import { BlockMenu } from "../commands/BlockMenu";
import { Bold } from "../commands/Bold";
import { Italic } from "../commands/Italic";
import { Link } from "../commands/Link";
import { LinkFloatingBox } from "../commands/LinkFloatingBox";
import { OrderedList } from "../commands/OrderedList";
import { Strikethrough } from "../commands/Strikethrough";
import { Underline } from "../commands/Underline";
import { UnorderedList } from "../commands/UnorderedList";
import { ChangeHandler } from "../components/ChangeHandler";
import { Toolbar } from "../components/Toolbar";
import { ToolbarDivider } from "../components/ToolbarDivider";
import { MaxLengthPlugin } from "../plugins/MaxLengthPlugin";
import { ContentEditableStyled, editorTheme } from "../TextEditorTheme";
import { TextEditorVariantProps } from "./types";
import { Placeholders } from "@sinch/components/form/TextEditor/commands/Placeholders";
import { PlaceholderNode } from "@sinch/components/form/TextEditor/nodes/PlaceholderNode";
import { PlaceholdersPlugin } from "@sinch/components/form/TextEditor/plugins/PlaceholdersPlugin";

export const SimpleTextEditor: FC<TextEditorVariantProps & { placeholders?: { label: string; value: string }[] }> = ({
  onFocus,
  onBlur,
  innerRef,
  disabled,
  maxRows,
  minRows,
  maxLength,
  toolbarPosition,
  placeholders,
  secondaryToolbar,
}) => {
  const editorConfig: InitialConfigType = useMemo(
    () => ({
      namespace: "TextEditor",
      editable: !disabled,
      // Handling of errors during update
      onError(error) {
        throw error;
      },
      theme: editorTheme,
      // Any custom nodes go here
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
        ...(isNotNilOrEmpty(placeholders) ? [PlaceholderNode] : []),
      ],
    }),
    [disabled]
  );

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Stack
        direction={toolbarPosition === "bottom" ? "column-reverse" : "column"}
        sx={{
          width: "100%",
        }}
      >
        <Toolbar>
          <BlockMenu />
          <ToolbarDivider />
          <Bold />
          <Italic />
          <Underline />
          <Strikethrough />
          <ToolbarDivider />
          <AlignMenu />
          <UnorderedList />
          <OrderedList />
          <ToolbarDivider />
          <Link />
          {isNotNilOrEmpty(placeholders) && (
            <>
              <ToolbarDivider />
              <Placeholders placeholders={placeholders!} />
            </>
          )}
        </Toolbar>
        <Divider />
        <RichTextPlugin
          contentEditable={
            <ContentEditableStyled maxRows={maxRows} minRows={minRows} onBlur={onBlur} onFocus={onFocus} />
          }
          ErrorBoundary={LexicalErrorBoundary}
          placeholder={<></>}
        />
        {secondaryToolbar && (
          <>
            <Divider />
            <Toolbar>{secondaryToolbar}</Toolbar>
          </>
        )}
      </Stack>
      <ChangeHandler innerRef={innerRef} />
      <HistoryPlugin />
      <ListPlugin />
      <LexicalLinkPlugin />
      {maxLength ? <MaxLengthPlugin maxLength={maxLength} /> : <></>}
      <LinkFloatingBox />
      {isNotNilOrEmpty(placeholders) && <PlaceholdersPlugin />}
    </LexicalComposer>
  );
};
