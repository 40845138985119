// eslint-disable-next-line import/no-internal-modules
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { Paper, Popper } from "@mui/material";
import { $getSelection, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND } from "lexical";
import React, { FC, PropsWithChildren, useCallback, useEffect } from "react";

export const FloatingBoxPlugin: FC<PropsWithChildren> = ({ children }) => {
  const [editor] = useLexicalComposerContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const updateTextFormatFloatingToolbar = useCallback(() => {
    const selection = $getSelection();

    const nativeSelection = window.getSelection();

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);

      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild as HTMLElement;
        }
        setAnchorEl(inner);
      } else {
        setAnchorEl(domRange.commonAncestorContainer.parentElement);
      }
    }
  }, [editor]);

  useEffect(() => {
    const update = () => {
      editor.getEditorState().read(() => {
        updateTextFormatFloatingToolbar();
      });
    };

    window.addEventListener("resize", update);
    editor.getEditorState().read(() => {
      updateTextFormatFloatingToolbar();
    });
    return () => {
      window.removeEventListener("resize", update);

      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateTextFormatFloatingToolbar();
          });
        }),

        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateTextFormatFloatingToolbar();
            return false;
          },
          COMMAND_PRIORITY_LOW
        )
      )();
    };
  }, [editor, updateTextFormatFloatingToolbar]);

  return anchorEl ? (
    <Popper anchorEl={anchorEl} disablePortal open placement="bottom-start">
      <Paper sx={{ padding: 1, borderRadius: "12px !important", maxWidth: "320px" }}>{children}</Paper>
    </Popper>
  ) : null;
};
