import { Avatar, AvatarProps } from "@mui/material";
import { useSettings } from "@sinch/core/providers/AppSettings";
import type { User } from "@sinch/types/sinch.types";

import React, { FC } from "react";
import { useWorker } from "../Worker/hooks";

export interface UserAvatarProps extends AvatarProps {
  worker: Pick<User, "name" | "surname"> & {
    avatar?: { url: string | null } | null;
  };
}

/**
 * Avatar for user
 */
export const UserAvatar: FC<UserAvatarProps> = ({ worker, ...props }) => {
  const { getWorkerName } = useWorker();
  const { avatarDummy } = useSettings();
  return (
    <Avatar alt={getWorkerName(worker)} src={worker.avatar?.url ?? avatarDummy} {...props}>
      {worker.name?.[0]}
      {worker.surname?.[0]}
    </Avatar>
  );
};
