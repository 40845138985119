import React, { FC, PropsWithChildren } from "react";
import { CustomFormats, IntlProvider } from "react-intl";
import { formats } from "../config/formats";
import { useSettings } from "./AppSettings";

/**
 * Porvide settings and methods for localised formatting
 */
export const FormatJsIntlProvider: FC<PropsWithChildren<{ timezone?: string }>> = ({ children, timezone }) => {
  const { locale, currency } = useSettings();
  return (
    <IntlProvider formats={formats(timezone, currency) as CustomFormats} locale={locale ?? "en"}>
      {children}
    </IntlProvider>
  );
};
