import { useEffect, useRef } from "react";

/**
 * Custom hook that runs a callback function only when the component updates, not on the initial render.
 */
export const useOnUpdate: typeof useEffect = (callback, deps) => {
  const isFirst = useRef(true);
  useEffect(() => {
    if (!isFirst.current) {
      return callback();
    }
  }, deps);

  useEffect(() => {
    isFirst.current = false;
  }, []);
};
