import { useGoogleStaticMapApi } from "@sinch/hooks/utilities/useGoogleStaticMapApi";
import { Coordinates } from "@sinch/types/common.types";
import { isNilOrEmpty, isNotNil } from "ramda-adjunct";
import React, { FC } from "react";
import { ImageBox } from "@sinch/components/ImageBox/ImageBox";

interface MapPositionPreviewProps {
  coordinates: Coordinates | Coordinates[] | null;
  exactSize?: boolean;
  path?: string | null;
}
export const MapPositionPreview: FC<MapPositionPreviewProps> = ({ coordinates, exactSize, path }) => {
  const hasCoordinates = isNotNil(coordinates);
  const getMapUri = useGoogleStaticMapApi();

  return (
    <ImageBox
      disabled={isNilOrEmpty(coordinates)}
      exactSize={exactSize}
      imgHeight={480}
      imgWidth={640}
      render={({ width, height }) => (
        <img
          className="img-fluid"
          src={getMapUri({
            width,
            height,
            markerCoordinates: hasCoordinates ? coordinates : undefined,
            path: path ?? undefined,
          })}
        />
      )}
    />
  );
};
