import { Button, Collapse, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import { AttributeTypeEnum } from "@sinch/core/entities/serverEnums";
import { FormatJsIntlProvider } from "@sinch/core/providers/FormatJsIntlProvider";
import React, { MutableRefObject, useState } from "react";
import { FieldPath, FieldValues } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputWithExpirationDate } from "../../form/InputWithExpirationDate/InputWithExpirationDate";
import { MuiController } from "../../form/MuiController/MuiController";
import { CustomAttributeInput } from "./CustomAttributeInput";
import { BaseAttribute, BaseAttributeValue } from "./types";

const expirationHorizontal = [
  AttributeTypeEnum.Choice,
  AttributeTypeEnum.Text,
  AttributeTypeEnum.Multiplechoice,
  AttributeTypeEnum.Date,
  AttributeTypeEnum.Int,
  AttributeTypeEnum.Range,
];
const isExpirationHorizontal = <AttributeType extends BaseAttribute>(attribute: AttributeType) =>
  expirationHorizontal.includes(attribute.dataType as AttributeTypeEnum);

interface RefWrapperProps<AttributeType extends BaseAttribute> {
  attribute: AttributeType;
  inputRef?: MutableRefObject<HTMLInputElement>;
}

const RefWrapper = <AttributeType extends BaseAttribute>({ attribute, inputRef }: RefWrapperProps<AttributeType>) => (
  <Grid item md={isExpirationHorizontal(attribute) ? 6 : 12} xs={12}>
    <CustomAttributeInput<AttributeType>
      attribute={attribute}
      inputRef={inputRef}
      prefix={`AttributeValue.${attribute.id}`}
    />
  </Grid>
);

interface CustomAttributeWrapperProps<AttributeType extends BaseAttribute> {
  attribute: AttributeType;
}

export const CustomAttributeWrapper = <
  AttributeType extends BaseAttribute,
  FormFields extends FieldValues & { AttributeValue: BaseAttributeValue[] },
>({
  attribute,
}: CustomAttributeWrapperProps<AttributeType>) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  return (
    <Grid columnSpacing={3} container>
      {attribute.expirable ? (
        <Grid
          item
          sx={{
            paddingRight: isExpirationHorizontal(attribute) && !isMobile ? 3 : 0,
          }}
          xs={12}
        >
          <MuiController<FormFields, FieldPath<FormFields>>
            name={`AttributeValue.${attribute.id}.expirationDate` as FieldPath<FormFields>}
            render={({ field }) => (
              // Set local timezone for providers
              <FormatJsIntlProvider>
                <InputWithExpirationDate
                  {...field}
                  placement={!isMobile && isExpirationHorizontal(attribute) ? "right" : "under"}
                >
                  <RefWrapper attribute={attribute} />
                </InputWithExpirationDate>
              </FormatJsIntlProvider>
            )}
          />
        </Grid>
      ) : (
        <Grid item md={isExpirationHorizontal(attribute) ? 6 : 12} xs={12}>
          <CustomAttributeInput<AttributeType> attribute={attribute} prefix={`AttributeValue.${attribute.id}`} />
        </Grid>
      )}
      {attribute.description && (
        <Grid
          item
          sx={{
            ml: 0.5,
            mt: 0.5,
          }}
          xs={12}
        >
          <Typography
            sx={{
              color: ({ palette }) => palette.grey["800"],
            }}
          >
            {attribute.description}
          </Typography>
        </Grid>
      )}
      {attribute.longDescription && (
        <Grid
          item
          sx={{
            mb: showMore ? 0.5 : 0,
            ml: 0.5,
            mt: showMore ? 0.5 : 0,
          }}
          xs={12}
        >
          <Collapse in={showMore}>
            <Typography
              dangerouslySetInnerHTML={{ __html: attribute.longDescription }}
              sx={{
                color: ({ palette }) => palette.grey["800"],
              }}
            ></Typography>
          </Collapse>
        </Grid>
      )}
      {attribute.longDescription && (
        <Grid
          item
          sx={{
            ml: -0.5,
          }}
          xs={12}
        >
          <Button onClick={() => setShowMore(!showMore)} size="small" variant="text">
            {!showMore ? t("NewStaffMemberForm.showMoreDescription") : t("NewStaffMemberForm.showLessDescription")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
