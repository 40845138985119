import { Stack } from "@mui/material";
import { useFormat } from "@sinch/core/hooks/format";

import { rejectFalsy } from "@sinch/utils/array";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../../typography/SectionTitle/SectionTitle";
import { LabelValue } from "../LabelValue";
import { StaffFragment } from "../queries.types";
import { DataPair } from "../types";

interface WalletProps {
  staff: StaffFragment;
}

export const Wallet: FC<WalletProps> = ({ staff }) => {
  const { t } = useTranslation();
  const { num } = useFormat();

  const walletDataPairs = rejectFalsy([
    {
      label: t("Staff.walletBalance"),
      value: num.currency(staff.walletStatistics.balance),
    },
    {
      label: t("Staff.paidThisMonth"),
      value: num.currency(staff.walletStatistics.paidThisMonth),
    },
    {
      label: t("Staff.paidTotal"),
      value: num.currency(staff.walletStatistics.paidTotal),
    },
    {
      label: t("Staff.requested"),
      value: num.currency(staff.walletStatistics.paidTotal),
    },
  ]);

  return (
    <Stack>
      <SectionTitle title={t("Staff.wallet")} variant="subtitle1" />
      {(walletDataPairs as DataPair[])
        .filter(({ value }) => isNotNilOrEmpty(value))
        .map((pair) => (
          <LabelValue key={pair.label + pair.value} label={pair.label} value={pair.value} />
        ))}
    </Stack>
  );
};
