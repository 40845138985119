import { BoxProps, styled } from "@mui/material";
import { Box } from "@mui/system";
import { unstable_generateUtilityClasses as generateUtilityClasses } from "@mui/utils";
import clsx from "clsx";
import React, { FC } from "react";

const classes = generateUtilityClasses("SinchConnectorLine", ["root", "line", "placementRight", "placementUnder"]);

export interface ConnectorLineProps {
  placement?: "right" | "under";
}

export const ConnectorLine: FC<ConnectorLineProps> = ({ placement = "under" }) => (
  <ConnectorBox className={classes.root}>
    <div className={clsx(classes.line, placement === "right" ? classes.placementRight : classes.placementUnder)} />
  </ConnectorBox>
);

const ConnectorBox = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  height: theme.spacing(3),
  marginLeft: theme.spacing(1),

  [`&>.${classes.placementUnder}`]: {
    width: theme.spacing(1),
    height: "13px",
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderTop: "none",
    borderRight: "none",
  },

  [`&>.${classes.placementRight}`]: {
    width: theme.spacing(3),
    height: "50%",
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
  },
}));
