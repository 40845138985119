import { InputBaseComponentsPropsOverrides, OutlinedInput, OutlinedInputProps, styled } from "@mui/material";
import React, { FC } from "react";
import { FormControlWrapper } from "../FormControlWrapper/FormControlWrapper";
import { TimeInputLuxonAdapter } from "./TimeInputLuxonAdapter";
import { TimeInputProps } from "./types";

export const TimeInput: FC<TimeInputProps> = ({
  label,
  AutocompleteProps: autocompleteProps,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  helperText,
  errorMessage,
  error,
  required,
  infoTooltip,
  value,
  onChange,
  fullWidth,
  ...props
}) => (
  <FormControlWrapper
    error={error}
    errorMessage={errorMessage}
    FormHelperTextProps={formHelperTextProps}
    helperText={helperText}
    infoTooltip={infoTooltip}
    label={label}
    required={required}
    {...formControlProps}
    fullWidth={fullWidth}
  >
    <OutlinedWithTime
      disabled={props.disabled}
      error={error}
      onChange={onChange as unknown as OutlinedInputProps["onChange"]}
      readOnly={props.readOnly}
      slotProps={{
        input: {
          ...props,
          //AutocompleteProps: autocompleteProps,
          required,
        } as InputBaseComponentsPropsOverrides,
      }}
      slots={{
        input: TimeInputLuxonAdapter,
      }}
      value={value}
    />
  </FormControlWrapper>
);

const OutlinedWithTime = styled(OutlinedInput)<OutlinedInputProps>(({ theme }) => ({
  "& .MuiInputBase-input": {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
