import type { MessageSeverity } from "@sinch/types/sinch.types";

import { useSnackbar } from "notistack";
import { ReactNode } from "react";
import { notifyErrors } from "./errorSnackbars";

/**
 * Notify user about errors in response messages, or success message
 */
export const useMutationResponseNotify = () => {
  const { enqueueSnackbar } = useSnackbar();

  const notifyResponse = (
    responseData:
      | { result: boolean; messages?: { message: string; severity: MessageSeverity }[] | null | undefined }
      | undefined
      | null,
    successMsg: string | ReactNode
  ) => {
    if (responseData?.messages) {
      notifyErrors(responseData);
    }
    if (responseData?.result) {
      enqueueSnackbar(successMsg, { variant: "success" });
    }
  };

  return { notifyResponse };
};
