import { type StackProps, styled } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, { ReactNode } from "react";

export interface DescriptionListItem {
  title: ReactNode;
  detail: ReactNode;
}

export interface DescriptionListProps {
  /**
   * Array of items to be displayed in the description list.
   * Each item contains a title and a detail.
   */
  items: DescriptionListItem[];
}

/**
 * Functional component to render a description list aligned with descriptions to right.
 */
export const DescriptionList = ({ items }: DescriptionListProps): JSX.Element => (
  <DescriptionListContainer>
    {items.map(({ title, detail }, ix) => (
      <DescriptionListRow key={ix} component="dl" direction="row" spacing={3}>
        <Typography component="dt" variant="caption">
          {title}
        </Typography>
        <Typography component="dd" textAlign="right" variant="caption">
          {detail}
        </Typography>
      </DescriptionListRow>
    ))}
  </DescriptionListContainer>
);

const DescriptionListContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "fit-content",
});

const DescriptionListRow = styled(Stack)<StackProps>({
  margin: 0,
  width: "100%",
  justifyContent: "space-between",
});
