import React, { FC } from "react";
import { FormControlWrapper } from "../FormControlWrapper/FormControlWrapper";
import { DateInputProps } from "./DateInput.types";
import { DateInputLuxonAdapter } from "./DateInputLuxonAdapter";

/**
 * Date input, for selection date, calendar dialog is used
 */
export const DateInput: FC<DateInputProps> = ({
  label,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  helperText,
  errorMessage,
  error,
  required,
  infoTooltip,
  fullWidth,
  ...props
}) => (
  <FormControlWrapper
    error={error}
    errorMessage={errorMessage}
    FormHelperTextProps={formHelperTextProps}
    helperText={helperText}
    infoTooltip={infoTooltip}
    label={label}
    required={required}
    {...formControlProps}
    fullWidth={fullWidth}
  >
    <DateInputLuxonAdapter error={error} fullWidth={fullWidth} required={required} {...props} />
  </FormControlWrapper>
);
