import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import React from "react";
import { ArrayPath, FieldArrayWithId, FieldValues, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FieldArrayProvider } from "../providers/FieldArrayProvider";
import { EditRequirementRow } from "./EditRequirementRow";
import { RequirementRow } from "./RequirementRow";
import { PositionRequirementFormFields } from "./types";

interface RequirementTableDesktopProps<FormFields extends FieldValues, RequirementPath extends ArrayPath<FormFields>> {
  prefix: string;
  editedFieldIx: number | null;
  setEditedFieldIx: (ix: number | null) => void;
  fields: FieldArrayWithId<PositionRequirementFormFields>[];
  fieldArrayMethods: Omit<ReturnType<typeof useFieldArray<FormFields, RequirementPath>>, "fields">;
}
export const RequirementTableDesktop = <FormFields extends FieldValues, RequirementPath extends ArrayPath<FormFields>>({
  prefix,
  editedFieldIx,
  setEditedFieldIx,
  fields,
  fieldArrayMethods,
}: RequirementTableDesktopProps<FormFields, RequirementPath>) => {
  const { t } = useTranslation();
  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell size="small">{t("Shift.requirements")}</TableCell>
            <TableCell size="small">{t("Requirement.type")}</TableCell>
            <TableCell size="small">{t("Requirement.condition")}</TableCell>
            <TableCell size="small">{t("NewJobForm.requiredFor")}</TableCell>
            <TableCell size="small">{t("NewJobForm.expiration")}</TableCell>
            <TableCell size="small"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fields.map(({ id }, rowIndex) => (
            <FieldArrayProvider<FormFields, RequirementPath>
              key={id}
              methods={fieldArrayMethods}
              parentIndex={rowIndex}
              prefix={`${prefix}.requirements.${rowIndex}` as `${RequirementPath}.${number}`}
            >
              {editedFieldIx == rowIndex ? (
                <TableRow>
                  <TableCell colSpan={6} scope="row">
                    <EditRequirementRow onClose={() => setEditedFieldIx(null)} />
                  </TableCell>
                </TableRow>
              ) : (
                <RequirementRow onClick={() => setEditedFieldIx(rowIndex)} />
              )}
            </FieldArrayProvider>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
