import { blend } from "@mui/system";
import { max, min } from "@sinch/utils/dateTime/dateFns";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import { DateTime, Interval } from "luxon";
import { pluck, uniqBy } from "ramda";

import { TimeBarSegment } from "./types";

interface UseTimeBarGraphProps {
  /**
   * Segments of time bar
   */
  segments?: TimeBarSegment[];
}
export const useTimeBarGraph = ({ segments }: UseTimeBarGraphProps) => {
  if (!segments) {
    return { parts: [] };
  }
  const minInSegments = min(pluck("start", segments));
  const maxInSegments = max(pluck("end", segments));

  const segmentsDuration = maxInSegments.getTime() - minInSegments.getTime();

  // get all dates from segments and convert to luxon
  const dates = segments.reduce((acc, segment) => {
    acc.push(toLuxon(segment.start), toLuxon(segment.end));
    return acc;
  }, [] as DateTime[]);

  // create intervals for bar
  const intervals = Interval.fromDateTimes(minInSegments, maxInSegments).splitAt(
    ...uniqBy(
      (date) => date.toUnixInteger(),
      dates.filter((date) => !date.equals(toLuxon(minInSegments)) && !date.equals(toLuxon(maxInSegments)))
    )
  );

  // generate parts for bar, overlapping segmets will be color blended
  const parts = intervals.map((part) => {
    const overlapSegments = segments.filter((segment) =>
      Interval.fromDateTimes(segment.start, segment.end).overlaps(part)
    );
    const color = overlapSegments.reduce((acc, segment) => {
      if (acc !== "transparent") {
        return blend(acc, segment.color, 0.5);
      } else {
        return segment.color;
      }
    }, "transparent");
    return {
      color,
      percent: ((part.end?.toJSDate().getTime() ?? 0) - minInSegments.getTime()) / segmentsDuration,
    };
  });

  return { parts };
};
