import { DurationParts } from "./types";

const INITIAL_ZERO = /^0/;

function leftTrim(parts: DurationParts[]): DurationParts[] {
  let previousEmpty = true;
  return parts.filter((token) => {
    if (token.type === "literal" && !token.value.trim()) {
      if (previousEmpty) return false;
      previousEmpty = true;
      return true;
    } else {
      previousEmpty = false;
    }
    return true;
  });
}

export function trim(parts: DurationParts[], trimFirstPaddedValue = false) {
  const trimmed = leftTrim(leftTrim(parts).reverse()).reverse();
  if (trimFirstPaddedValue) {
    const first = trimmed.find((token) => token.type !== "literal");
    if (first) {
      first.value = first.value.replace(INITIAL_ZERO, "");
    }
  }
  return trimmed;
}
