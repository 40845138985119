import { MdsFormatItalicFillXs } from "@sinch/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { FormatTextToggleButton } from "./FormatTextToggleButton";

export const Italic: FC = () => {
  const { t } = useTranslation();
  return (
    <FormatTextToggleButton
      icon={<MdsIcon fontSize="xs" icon={MdsFormatItalicFillXs} />}
      textFormatType="italic"
      tooltip={t("TextEditor.italic")}
    />
  );
};
