import { filter, find, includes, pluck, prop, propEq } from "ramda";
import { IdNamePair } from "./types";

export const idToName = (ids: string[], options: IdNamePair[]) => {
  const filteredOptions = filter((option) => includes(option.id, ids), options);
  return pluck("name", filteredOptions);
};

export const findNameById = (id: string, options: IdNamePair[]) => {
  const foundObject = find(propEq("id", id), options ?? []);
  const name = foundObject ? prop("name", foundObject) : null;
  return name;
};
