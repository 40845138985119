import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { GlobalStyles, styled, ThemeProvider } from "@mui/material";
import { Toast } from "@sinch/components/Toast/Toast";
import { SnackbarProvider, SnackbarProviderProps } from "notistack";
import React, { FC, PropsWithChildren } from "react";
import { constants } from "../config/globals";
import { theme } from "../config/theme";

const cache = createCache({
  key: "sinch-mui", // prefix for all custom styles. Do not change, danger of conflict with other css frameworks
});
/**
 * Material-ui theme provider
 */
export const MuiThemeProvider: FC<PropsWithChildren> = ({ children }) => {
  const { noticeStackCount } = constants;
  return (
    // @ts-ignore Problem with incompatible types version and lib version, will be fixed in the future
    <CacheProvider value={cache}>
      <ThemeProvider theme={theme}>
        <GlobalStyles
          styles={{
            "&.notistack-SnackbarContainer": {
              zIndex: 10000,
              top: "calc( var(--sinch-header-height, 56px) + 14px )",
            },
          }}
        />
        <StyledSnackbarProvider
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          Components={{
            success: Toast,
            error: Toast,
            info: Toast,
            warning: Toast,
          }}
          maxSnack={noticeStackCount}
          preventDuplicate
        >
          {children}
        </StyledSnackbarProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

const StyledSnackbarProvider = styled(SnackbarProvider)<SnackbarProviderProps>({
  ".notistack-SnackbarContainer": {
    backgroundColor: "magenta",
  },
});
