import { Button, ButtonProps as MuiButtonProps } from "@mui/material";
import { asUploadButton, UploadButtonProps as UploadyButtonProps } from "@rpldy/upload-button";
import { useBatchAddListener, useBatchFinalizeListener } from "@rpldy/uploady";
import { MdsAttachFile } from "@sinch/icons";
import { omit } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { FC, ReactNode, useState } from "react";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";

export interface UploadButtonProps extends Omit<MuiButtonProps, "onChange" | "children" | "ref" | "onError"> {
  label?: ReactNode;
  onProcessing?: (processing: boolean) => void;
  multiple?: boolean;
}

/**
 * Button for select files for upload
 */
const UploadButtonComponent: FC<UploadButtonProps> = ({
  title,
  disabled,
  onProcessing,
  value,
  multiple,
  ...btnProps
}) => {
  const [processingBatches, setProcessingBatches] = useState(0);
  useBatchAddListener(() => {
    if (processingBatches + 1 === 1) {
      onProcessing?.(true);
    }
    setProcessingBatches((count) => count + 1);
  });
  useBatchFinalizeListener(() => {
    if (processingBatches - 1 >= 0) {
      onProcessing?.(false);
    }
    setProcessingBatches((count) => count - 1);
  });

  if (!multiple && isNotNilOrEmpty(value)) {
    return null;
  }

  return (
    <Button
      startIcon={<MdsIcon fontSize="small" icon={MdsAttachFile} />}
      {...omit(["inputRef"], btnProps)}
      disabled={disabled}
    >
      {title}
    </Button>
  );
};
export const UploadButton: FC<UploadyButtonProps & UploadButtonProps> = asUploadButton(UploadButtonComponent);
