import { MessageSeverity } from "@sinch/core/entities/serverEnums";

import { FORM_ERRORS_FIELD } from "@sinch/hooks/useMuiForm";
import type { MutationMessage } from "@sinch/types/sinch.types";

import { isEmpty, isNil, length, values } from "ramda";
import { UseFormReturn } from "react-hook-form";

export const applyErrorToForm =
  (form: UseFormReturn, type?: string) =>
  ({ field, message, severity }: MutationMessage) => {
    if (severity !== MessageSeverity.Error) return;
    const fieldKey = isNil(field) || isEmpty(field) ? FORM_ERRORS_FIELD : field;
    const { error } = form.getFieldState(fieldKey);
    const currentErrors = error?.types || {};
    const currentErrorsCount = length(values(currentErrors));

    form.setError(
      fieldKey,
      {
        type: type ?? "custom",
        // TODO: Add error keys from server
        types: { ...currentErrors, [`${type ?? "custom"}${currentErrorsCount + 1}`]: message },
      },
      { shouldFocus: true }
    );
  };
