/** WARNING! This file is generated from GraphQL Schema, please do not modify!
 * Any modification will be overridden
 */
/** Status of applicant */
export enum ApplicantStatusEnum {
  Declined = "DECLINED",
  Pending = "PENDING",
}

/** Enum with appointment visibility */
export enum AppointmentVisibilityEnum {
  Default = "DEFAULT",
  Hidden = "HIDDEN",
  OnlyNonverifiedProfile = "ONLY_NONVERIFIED_PROFILE",
  OnlyVerifiedProfile = "ONLY_VERIFIED_PROFILE",
}

/** Enum with attendance actions */
export enum AttendanceActionEnum {
  ResendInvitation = "RESEND_INVITATION",
  SignOff = "SIGN_OFF",
}

/** Enum with attendance note types */
export enum AttendanceNoteTypeEnum {
  AdminExcuse = "ADMIN_EXCUSE",
  Common = "COMMON",
  CrewbossExcuse = "CREWBOSS_EXCUSE",
  Problematic = "PROBLEMATIC",
  StaffClockOut = "STAFF_CLOCK_OUT",
}

/** Enum with attendance presence values */
export enum AttendancePresenceEnum {
  Excused = "EXCUSED",
  Late = "LATE",
  Miss = "MISS",
  Pending = "PENDING",
  Present = "PRESENT",
}

/** Reconfirmation statuses for attendance */
export enum AttendanceReconfirmationStatusEnum {
  Confirmed = "CONFIRMED",
  Pending = "PENDING",
}

/** Enum with attendance status */
export enum AttendanceStatusEnum {
  AssignedByAdmin = "ASSIGNED_BY_ADMIN",
  AssignedByWorker = "ASSIGNED_BY_WORKER",
  ConfirmationConfirmed = "CONFIRMATION_CONFIRMED",
  ConfirmationPending = "CONFIRMATION_PENDING",
}

/** Enum with attribute type */
export enum AttributeTypeEnum {
  Bool = "BOOL",
  Choice = "CHOICE",
  Date = "DATE",
  File = "FILE",
  Gallery = "GALLERY",
  Image = "IMAGE",
  Int = "INT",
  Multiplechoice = "MULTIPLECHOICE",
  Range = "RANGE",
  Text = "TEXT",
  Textarea = "TEXTAREA",
}

/** Enum with availability types (workerFilter) */
export enum AvailabilityTypeEnum {
  DemandNotAllocated = "DEMAND_NOT_ALLOCATED",
  JobDemand = "JOB_DEMAND",
  NoDemand = "NO_DEMAND",
  NoJobAvailable = "NO_JOB_AVAILABLE",
  Unavailable = "UNAVAILABLE",
  Working = "WORKING",
}

/** Avatar image variants */
export enum AvatarVariantsEnum {
  Thumb_200_200 = "THUMB_200_200",
  Thumb_500_500 = "THUMB_500_500",
}

/** Enum for bank account format */
export enum BankAccountFormat {
  Au = "AU",
  Common = "COMMON",
  Cz = "CZ",
  Dk = "DK",
  Es = "ES",
  Gb = "GB",
  Iban = "IBAN",
  It = "IT",
  Nl = "NL",
  Pl = "PL",
}

/** Calendar event type */
export enum CalendarEventTypeEnum {
  Common = "COMMON",
  JobDemand = "JOB_DEMAND",
  System = "SYSTEM",
  Unavailable = "UNAVAILABLE",
}

/** Enum with CitizenshipAttribute values */
export enum CitizenshipAttributeEnum {
  /** Allow set attribute for any citizenship worker have */
  Any = "ANY",
  /** Allow set attribute only for same citizenship as agency */
  HomeCountry = "HOME_COUNTRY",
  /** Allow set attribute for citizenship which do not match with agency country */
  OtherCountry = "OTHER_COUNTRY",
}

/** Enum with company pay rating */
export enum CompanyPayRatingEnum {
  Bad = "BAD",
  God = "GOD",
  Good = "GOOD",
}

/** Enum with company statuses */
export enum CompanyStatusEnum {
  Active = "ACTIVE",
  Inactive = "INACTIVE",
  Pending = "PENDING",
}

/** Feature list for "feature flag" functionality purpose */
export enum FeatureEnum {
  Awr = "AWR",
  ElectronicSignatures = "ELECTRONIC_SIGNATURES",
  MealPenalties = "MEAL_PENALTIES",
  OnsitePresence = "ONSITE_PRESENCE",
  PaidBreaks = "PAID_BREAKS",
  PushNotifications = "PUSH_NOTIFICATIONS",
  StaffClockIn = "STAFF_CLOCK_IN",
  Transport = "TRANSPORT",
}

/** Mode used to perform join mutation */
export enum JoinModeEnum {
  /** Join worker to position without any information about linked positions (default) */
  JoinSimple = "JOIN_SIMPLE",
  /** Join worker to position only (without joining into linked ones) */
  JoinWithoutGrouped = "JOIN_WITHOUT_GROUPED",
  /** Join worker to position and all other empty grouped positions */
  JoinWithGrouped = "JOIN_WITH_GROUPED",
}

/** Enum with message severity type */
export enum MessageSeverity {
  Error = "ERROR",
  Warning = "WARNING",
}

/** Possible notification channels */
export enum NotificationChannelEnum {
  Email = "EMAIL",
  Interactive = "INTERACTIVE",
  Push = "PUSH",
  Smart = "SMART",
  Sms = "SMS",
}

/** Enum with operator value types */
export enum OperatorValueTypeEnum {
  Boolean = "BOOLEAN",
  List = "LIST",
  Number = "NUMBER",
  String = "STRING",
}

/** Enum with order direction */
export enum OrderDirectionTypeEnum {
  Asc = "ASC",
  Desc = "DESC",
}

/** Placeholder data types */
export enum PlaceholderTypeEnum {
  Boolean = "BOOLEAN",
  Currency = "CURRENCY",
  Date = "DATE",
  Datetime = "DATETIME",
  File = "FILE",
  FileList = "FILE_LIST",
  Image = "IMAGE",
  Text = "TEXT",
}

/** Enum with position roles */
export enum PositionRoleEnum {
  /** Position role lead worker */
  Crewboss = "CREWBOSS",
  /** Position role standby. */
  Standby = "STANDBY",
  /** Position role staff */
  Worker = "WORKER",
}

/** Enum with position status */
export enum PositionStatusEnum {
  /** Closing position by closing CB */
  CbCompleted = "CB_COMPLETED",
  /** Completed position */
  Completed = "COMPLETED",
  /** Opened position */
  Opened = "OPENED",
  /** Opened position, but hidden */
  OpenedHidden = "OPENED_HIDDEN",
  /** Over position */
  Over = "OVER",
  /** Pending position */
  Pending = "PENDING",
  /** Running position */
  Running = "RUNNING",
}

/** Enum with rating values */
export enum RatingEnum {
  Admin = "ADMIN",
  Cb = "CB",
  Default = "DEFAULT",
  Duty = "DUTY",
  Excused = "EXCUSED",
  Late = "LATE",
  Na = "NA",
  Wb = "WB",
}

/** Enum with ReportType values */
export enum ReportTypeEnum {
  Bad = "BAD",
  Good = "GOOD",
  Neutral = "NEUTRAL",
}

/** Enum with types of role status */
export enum RoleStatusEnum {
  Active = "ACTIVE",
  Blocked = "BLOCKED",
  Deleted = "DELETED",
  Hibernated = "HIBERNATED",
}

/** Enum with sex values */
export enum SexEnum {
  Female = "FEMALE",
  Male = "MALE",
  NonBinary = "NON_BINARY",
}

/** Determine type of associated file for shift */
export enum ShiftFileTypeEnum {
  /** attachment uploaded by crewboss */
  CrewbossAttachment = "CREWBOSS_ATTACHMENT",
  /** file for description from admin to crewboss */
  CrewbossDescription = "CREWBOSS_DESCRIPTION",
  /** file for public description from admin to all workers */
  PublicDescription = "PUBLIC_DESCRIPTION",
  /** attachment uploaded by worker */
  WorkerAttachment = "WORKER_ATTACHMENT",
}

/** Field sort direction enum */
export enum SortDirectionEnum {
  Asc = "ASC",
  Desc = "DESC",
}

/** Enum with staff clock-in geolocation error code values */
export enum StaffClockInErrorCodeEnum {
  GpsUnavailable = "GPS_UNAVAILABLE",
  PermissionDenied = "PERMISSION_DENIED",
  PositionUnavailable = "POSITION_UNAVAILABLE",
  Timeout = "TIMEOUT",
}

/** Tag visibilities */
export enum TagVisibility {
  Admin = "ADMIN",
  Client = "CLIENT",
  Public = "PUBLIC",
  SignedWorkers = "SIGNED_WORKERS",
  Worker = "WORKER",
}

/** Enum with transport attendance role */
export enum TransportAttendanceRole {
  Driver = "DRIVER",
  NoTransport = "NO_TRANSPORT",
  Passenger = "PASSENGER",
}

/** Enum with transport directions */
export enum TransportDirection {
  /** Transport direction back. */
  Back = "BACK",
  /** Transport direction there. */
  There = "THERE",
}

/** Enum with types of transport roles */
export enum TransportsAttendanceRoleEnum {
  Driver = "DRIVER",
  NoTransport = "NO_TRANSPORT",
  Passenger = "PASSENGER",
}

/** Enum with user statuses */
export enum UserStatus {
  Confirmed = "CONFIRMED",
  /** user that was created by admin and later invited to sign in himself */
  Invited = "INVITED",
  /** user that signed up, but did not activate account yet */
  Pending = "PENDING",
  /** user without access rights (not invited, not registered; created manually by admin) */
  Shell = "SHELL",
}
