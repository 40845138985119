import { FormControlProps, OutlinedInput, OutlinedInputProps } from "@mui/material";

import { forceBoundary } from "@sinch/utils/math";
import { removeExceptFirst } from "@sinch/utils/string";
import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormControlWrapper, InputWrapperProps } from "../FormControlWrapper/FormControlWrapper";

export type TextInputProps<TType extends string = "text"> = OutlinedInputProps &
  Omit<InputWrapperProps, "slots" | "slotProps"> & {
    FormControlProps?: FormControlProps;
    type?: TType;
  } & (TType extends "number"
    ? {
        /**
         * Allow empty value for number input
         */
        allowEmpty?: boolean;
      }
    : { allowEmpty?: never });

/**
 * Outlined text input see [https://mui.com/material-ui/api/outlined-input/](https://mui.com/material-ui/api/outlined-input/)
 */
export const TextInputBase = <TType extends string = "text">(
  {
    label,
    FormHelperTextProps: formHelperTextProps,
    FormControlProps: formControlProps,
    helperText,
    errorMessage,
    error,
    required,
    infoTooltip,
    onChange,
    value,
    allowEmpty,
    ...props
  }: TextInputProps<TType>,
  ref: React.Ref<unknown>
): ReactElement => {
  const [inputVal, setInputVal] = useState(value);
  const [empty, setEmpty] = React.useState(false);
  const isNumeric = props.type === "number";
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // Special case for number input
      if (isNumeric && event.target.value === "") {
        setEmpty(true);
        if (allowEmpty) {
          setInputVal(event.target.value);
          onChange?.(event);
        }
      } else if (isNumeric && event.target.value !== "") {
        setEmpty(false);
        event.target.value = event.target.value.replace(/[^\d.-]/g, ""); // remove all non-numeric cahrs
        event.target.value = removeExceptFirst(event.target.value, ".");
        event.target.value = event.target.value.slice(0, 1) + event.target.value.slice(1).replace("-", "");
        setInputVal(event.target.value);
        onChange?.(event);
      } else {
        onChange?.(event);
      }
    },
    [onChange, props.type]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      props.onBlur?.(e);
      if (allowEmpty && e.target.value === "") {
        return;
      }
      // Special case for number input
      if (isNumeric && e.target.value === "") {
        setEmpty(false);
        setInputVal(props.inputProps?.min || 0);
        onChange?.(props.inputProps?.min || 0);
      } else if (isNumeric && e.target.value !== "") {
        setEmpty(false);
        const min = props.inputProps?.min;
        const max = props.inputProps?.max;
        e.target.value = String(forceBoundary(min, max)(+e.target.value));
        setInputVal(e.target.value);
        onChange?.(e);
      }
    },
    [onChange, props]
  );

  // Hold value with end dot so user can write decimal
  const inputValue = useMemo(() => {
    if (empty) {
      return "";
    }
    if (isNumeric) {
      return inputVal;
    }
    return value;
  }, [empty, isNumeric, value, inputVal]);

  useEffect(() => {
    setInputVal(value);
  }, [value]);

  return (
    <FormControlWrapper
      error={error}
      errorMessage={errorMessage}
      FormHelperTextProps={formHelperTextProps}
      helperText={helperText}
      infoTooltip={infoTooltip}
      label={label}
      required={required}
      {...formControlProps}
    >
      <OutlinedInput
        error={error}
        {...props}
        ref={ref}
        onBlur={handleBlur}
        onChange={handleChange}
        type={isNumeric ? "text" : props.type}
        value={inputValue}
      />
    </FormControlWrapper>
  );
};

export const TextInput = forwardRef(TextInputBase) as <TType extends string = "text">(
  props: TextInputProps<TType>
) => ReturnType<typeof TextInputBase>;
