import { GOOGLE_MAPS_API_KEY } from "@sinch/core";
import { DEFAULT_MAP_LAT, DEFAULT_MAP_LNG } from "@sinch/core/config/globals";
import { Coordinates } from "@sinch/types/common.types";
import { generateLetterName } from "@sinch/utils/arbitraryTagging";
import { roundAt } from "@sinch/utils/math";
import { includeIf } from "@sinch/utils/object";
import { DateTime } from "luxon";
import { ensureArray, isNotNil } from "ramda-adjunct";
import { useLocalStorage } from "@sinch/hooks/utilities/useLocalStorage";

interface BaseMapParams {
  width: number;
  height: number;
  markerCoordinates?: Coordinates | Coordinates[];
  path?: string;
}

const googleStaticMapsUri = `https://maps.googleapis.com/maps/api/staticmap`;

/**
 * Helper hook to return google static map
 */
export const useGoogleStaticMapApi =
  () =>
  ({ height, width, markerCoordinates, path }: BaseMapParams) => {
    const markers = ensureArray(markerCoordinates).filter(isNotNil);
    const searchParams = new URLSearchParams({
      size: `${width}x${height}`,
      ...includeIf(markers.length <= 1, { zoom: 14 }),
      scale: "1",
      ...includeIf(markers.length === 0, { center: `${DEFAULT_MAP_LAT},${DEFAULT_MAP_LNG}` }),
      format: "png",
      maptype: "roadmap",
      ...includeIf(isNotNil(GOOGLE_MAPS_API_KEY), { key: GOOGLE_MAPS_API_KEY }),
      ...includeIf(isNotNil(path), { path: `color:0xff0000ff|weight:5|enc:${path}` }),
    } as Record<string, string>);
    markers.forEach((marker, i) => {
      searchParams.append(
        "markers",
        `size:normal|color:red${markers.length === 1 ? "" : `|label:${generateLetterName(i)}`}|${marker.lat},${marker.lng}`
      );
    });

    return `${googleStaticMapsUri}?${searchParams.toString()}`;
  };

interface DirectionParams {
  origin?: Coordinates | null;
  destination?: Coordinates | null;
  units: "metric" | "imperial";
}
export interface BasicPathData {
  path: string | null;
  duration: number | null;
  distance: number | null;
}

const roundCoordinates = (coord: Coordinates | null) => {
  const rnd = roundAt(5); // 5 decimal places make about 1,1m accuracy
  return coord ? { lat: rnd(coord.lat), lng: rnd(coord.lng) } : null;
};

export const useGoogleDirectionApi = ({
  origin,
  destination,
  units,
}: DirectionParams): [() => void, BasicPathData | null] => {
  const originRounded = roundCoordinates(origin ?? null);
  const destinationRounded = roundCoordinates(destination ?? null);

  const pathKey = [
    window.google.maps.geometry.encoding.encodePath([originRounded, destinationRounded].filter(isNotNil)),
    units,
  ].join(",");
  const [directionData, setDirectionData] = useLocalStorage<Record<string, BasicPathData> | null>("SINCH:DIRECTION", {
    defaultValue: null,
    expiration: DateTime.now().plus({ day: 1 }).toJSDate(),
  });

  const dispatch = () => {
    if (!originRounded || !destinationRounded) {
      return;
    }

    new window.google.maps.DirectionsService().route(
      {
        origin: `${originRounded.lat},${originRounded?.lng}`,
        destination: `${destinationRounded.lat},${destinationRounded.lng}`,
        unitSystem: window.google.maps.UnitSystem.METRIC,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status == "OK") {
          setDirectionData({
            ...(directionData ?? {}),
            [pathKey]: {
              path: result?.routes[0].overview_polyline ?? null,
              duration: result?.routes[0].legs[0].duration?.value ?? null,
              distance: result?.routes[0].legs[0].distance?.value ?? null,
            },
          });
        }
      }
    );
  };
  return [dispatch, directionData?.[pathKey] ?? null];
};
