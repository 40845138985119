import { MdsFormatUnderlinedFillXs } from "@sinch/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { FormatTextToggleButton } from "./FormatTextToggleButton";

export const Underline: FC = () => {
  const { t } = useTranslation();
  return (
    <FormatTextToggleButton
      icon={<MdsIcon fontSize="xs" icon={MdsFormatUnderlinedFillXs} />}
      textFormatType="underline"
      tooltip={t("TextEditor.underline")}
    />
  );
};
