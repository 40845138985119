import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
// eslint-disable-next-line import/no-internal-modules
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import {
  $getSelection,
  $isRangeSelection,
  BaseSelection,
  COMMAND_PRIORITY_LOW,
  NodeSelection,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from "lexical";
import { useCallback, useEffect, useState } from "react";
import { getSelectedNode } from "../utils/getSelectedNode";
import { useSelectionIsLink } from "./useSelection";

export const DEFAULT_LINK_URL = "https://";

export const useLink = () => {
  const isLink = useSelectionIsLink();
  const [editor] = useLexicalComposerContext();

  const [editedLinkUrl, setEditedLinkUrl] = useState("");
  const [lastSelection, setLastSelection] = useState<RangeSelection | BaseSelection | NodeSelection | null>(null);

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setEditedLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setEditedLinkUrl(node.getURL());
      } else {
        setEditedLinkUrl("");
      }
    }
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    const rootElement = editor.getRootElement();

    if (
      selection !== null &&
      nativeSelection !== null &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode) &&
      editor.isEditable()
    ) {
      setLastSelection(selection);
    } else if (!activeElement) {
      setLastSelection(null);
      setEditedLinkUrl("");
    }

    return true;
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }),

        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateLinkEditor();
            return true;
          },
          COMMAND_PRIORITY_LOW
        )
      ),
    [editor, updateLinkEditor, isLink]
  );

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  const handleSave = useCallback(
    (url: string) => {
      if (lastSelection !== null) {
        if (url !== "") {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, { url, target: "_blank" });
        } else {
          editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
        setEditedLinkUrl(url);
      }
    },
    [editor, lastSelection]
  );

  const handleDelete = useCallback(() => {
    if (lastSelection !== null) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, lastSelection]);

  return { isLink, url: editedLinkUrl, setUrl: setEditedLinkUrl, save: handleSave, remove: handleDelete };
};
