import { Divider } from "@mui/material";
import React, { FC } from "react";

export const ToolbarDivider: FC = () => (
  <Divider
    flexItem
    orientation="vertical"
    sx={{
      height: "16px",
      alignSelf: "center",
    }}
    variant="middle"
  />
);
