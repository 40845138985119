import { CircularProgress, ListItemIcon, ListItemText, MenuItem, useTheme } from "@mui/material";
import { MdsDeleteXs } from "@sinch/icons";
import { enqueueSnackbar } from "notistack";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "@sinch/components";
import { useAttendance } from "@sinch/components/application/Attendace/useAttendance";

interface SignOffMenuItemProps {
  loading: boolean;
  handleClose: () => void;
  attendanceId: string;
  onLoading?: (loading: boolean) => void;
}

export const SignOffMenuItem: FC<SignOffMenuItemProps> = ({ loading, handleClose, attendanceId, onLoading }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { kickWorker } = useAttendance();

  const handleKick = async () => {
    onLoading?.(true);
    try {
      await kickWorker(attendanceId, true);
    } catch (e) {
      enqueueSnackbar(t("Error.anErrorHasOccurred"), { variant: "error" });
    }
    onLoading?.(false);
    handleClose();
  };

  return (
    <MenuItem disabled={loading} onClick={handleKick} sx={({ palette }) => ({ color: palette.error.main })}>
      <ListItemIcon>
        {loading ? (
          <CircularProgress color="error" size={16} thickness={5} />
        ) : (
          <MdsIcon color="error" fontSize="small" icon={MdsDeleteXs} />
        )}
      </ListItemIcon>
      <ListItemText primaryTypographyProps={{ color: theme.palette.error.main }}>
        {t("Attendance.signOff")}
      </ListItemText>
    </MenuItem>
  );
};
