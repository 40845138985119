import { AutocompleteProps, AutocompleteValue, Theme } from "@mui/material";

import { displayTextWidth } from "@sinch/utils/dom";
import { find, head, includes, isNil, prop, propEq, reduce } from "ramda";
import { ensureArray } from "ramda-adjunct";
import { useCallback, useMemo, useState } from "react";
import { AutocompleteInputProps, AutocompleteOptions } from "@sinch/components/form/AutocompleteInput/types";

export type UseAutocompleteInputProps<
  T extends AutocompleteOptions = AutocompleteOptions,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = Pick<
  AutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo>,
  "options" | "value" | "multiple" | "onChange" | "freeSolo" | "placeholder" | "onAddAction"
>;

export const useAutocompleteInput = <
  T extends AutocompleteOptions = AutocompleteOptions,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  options,
  value,
  multiple,
  onChange,
  freeSolo,
  placeholder,
  onAddAction,
  addOptionValue,
}: UseAutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo> & { addOptionValue: string }) => {
  const [inputValue, setInputValue] = useState<string>("");
  const cachedValues = useMemo<Map<unknown, AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>>>(
    () => new Map(),
    []
  );

  const realValues = useMemo(() => {
    if (isNil(value)) {
      return [value] as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>[];
    }
    return reduce(
      (acc, val) => {
        const matched = find<AutocompleteOptions>(propEq("value", val))(options);
        if (matched) {
          acc.push(matched as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>);
          cachedValues.set(val, matched as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>);
        } else if (cachedValues.has(val)) {
          acc.push(cachedValues.get(val) as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>);
        } else {
          acc.push({
            value: val,
            label: val,
          } as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>);
        }
        return acc;
      },
      [] as AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>[]
    )(ensureArray(value));
  }, [options, value]);

  const handleChange = useCallback<NonNullable<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>["onChange"]>>(
    (_, val) => {
      const scalarValues = reduce(
        (acc, item) => {
          const valProp = prop("value", item);
          if (!isNil(valProp)) {
            acc.push(valProp as string | number);
          } else {
            acc.push(item as string | number);
          }
          return acc;
        },
        [] as T["value"][],
        ensureArray(val as T | string)
      );
      const pureValue = multiple ? scalarValues : (head(scalarValues) ?? null);

      if (onAddAction && pureValue && includes(addOptionValue, ensureArray(pureValue).map(String))) {
        onAddAction(inputValue);
      } else {
        onChange?.(pureValue as Multiple extends true ? T["value"][] : T["value"]);
      }
    },
    [multiple, onChange]
  );

  const handleInputChange = useCallback<
    NonNullable<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>["onInputChange"]>
  >(
    (_, val) => {
      setInputValue(val);
      if (!multiple && freeSolo) {
        onChange?.(val as AutocompleteValue<string | number, Multiple, DisableClearable, FreeSolo> | null);
      }
    },
    [freeSolo, multiple, onChange]
  );

  const minInputWidthForMultiple = useMemo(
    () => (theme: Theme) =>
      placeholder && multiple ? `${displayTextWidth(placeholder, `14px ${theme.typography.fontFamily}`)}px` : undefined,
    [placeholder, multiple]
  );

  const pureValue = (multiple ? realValues : head(realValues)) as AutocompleteValue<
    T,
    Multiple,
    DisableClearable,
    FreeSolo
  >;

  return {
    onChange: handleChange,
    onInputChange: handleInputChange,
    value: pureValue,
    minWidth: minInputWidthForMultiple,
  };
};
