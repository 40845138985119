import { CircularProgress } from "@mui/material";
import { MessageSeverity } from "@sinch/core/entities/serverEnums";
import type { MutationMessage } from "@sinch/types/sinch.types";

import { head, length, pluck } from "ramda";
import { ensureArray, isArray, isNotNilOrEmpty } from "ramda-adjunct";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { AlertPopoverProps, AlertPopoverType } from "../../AlertPopover/AlertPopover";

type PopoverProps<T extends Element> = Pick<AlertPopoverProps<T>, "type" | "icon" | "actions" | "closeAfter"> & {
  title: string;
  content?: string[] | string | null;
};

interface ResultProps {
  result: boolean;
  messages?: MutationMessage[] | null;
  workerName: string;
  positionName: string;
  workerId: string;
  positionIdLabel: string;
  attendanceId: string | string[] | null;
}

/**
 * Provide properties for show popover for joining user. Popover can be rendered in any element by anchor ref.
 */
export const useTimelinePopover = <T extends Element>({
  onUndo,
  onRequireConfirmation,
  attendanceConfirmation,
}: {
  onUndo: (attendanceId: string) => void;
  onRequireConfirmation: (attendanceId: string) => void;
  attendanceConfirmation: boolean;
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const getWaitingProps = () => ({
    type: AlertPopoverType.Regular,
    title: t("Attendance.assigningToTheShift"),
    icon: <CircularProgress size="small" sx={{ height: 10, width: 10 }} />,
  });

  const [anchorEl, setAnchorEl] = React.useState<T | null>(null);
  const [popoverProps, setPopoverProps] = React.useState<PopoverProps<T>>(getWaitingProps());
  const [isClosable, setIsClosable] = React.useState(true);

  const handleClose = useCallback(() => {
    if (isClosable) {
      setAnchorEl(null);
      setPopoverProps(getWaitingProps());
    }
  }, [isClosable]);

  const handleSetAnchorEl = useCallback((el: T | null) => {
    setPopoverProps(getWaitingProps());
    setAnchorEl(el);
    setIsClosable(false);
  }, []);

  const onResponse = useCallback(
    (response: ResultProps) => {
      setIsClosable(true);
      if (response.result) {
        const actions: AlertPopoverProps<T>["actions"] = [
          {
            label: t("undo"),
            onClick: () => {
              setAnchorEl(null);
              onUndo(response.attendanceId as string);
            },
            color: "error",
          },
          ...(!isArray(response.attendanceId) || length(ensureArray(response.attendanceId)) === 1
            ? [
                {
                  label: attendanceConfirmation
                    ? t("Attendance.doNotRequireConfirmation")
                    : t("Attendance.requireConfirmation"),
                  onClick: () => {
                    setAnchorEl(null);
                    onRequireConfirmation(head(ensureArray(response.attendanceId)) as string);
                  },
                },
              ]
            : []),
        ];

        const anyWarning = response.messages?.some(({ severity }) => severity === MessageSeverity.Warning);

        if (anyWarning) {
          // If any warning, show warning popover with action buttons
          setPopoverProps({
            type: AlertPopoverType.Warning,
            title: t("Attendance.assignWarning"),
            content: pluck("message", response.messages ?? []),
            closeAfter: 5,
            actions: response.attendanceId ? actions : undefined,
          });
        } else {
          // If success, show regular popover with action buttons
          setPopoverProps({
            type: AlertPopoverType.Regular,
            title: `${response.workerName} - `,
            content: t("Attendance.hasBeenAssigned", {
              positionName: response.positionName,
              positionId: response.positionIdLabel,
            }),
            closeAfter: 5,
            actions: response.attendanceId ? actions : undefined,
          });
        }
      } else {
        // If error, show error popover without no action buttons
        setPopoverProps({
          type: AlertPopoverType.Error,
          title: `${response.workerName} - `,
          content: isNotNilOrEmpty(response.messages)
            ? pluck("message", response.messages ?? [])
            : t("Attendance.assignError"),
          closeAfter: 5,
        });
      }

      return;
    },
    [attendanceConfirmation, onRequireConfirmation, onUndo, t]
  );

  return {
    popoverProps: { anchorEl, onClose: handleClose, ...popoverProps },
    onResponse,
    setAnchorEl: handleSetAnchorEl,
    open,
    setOpen,
  };
};
