import { useTheme } from "@mui/material";
import { PositionRoleEnum } from "@sinch/core/entities/serverEnums";
import { useSettings } from "@sinch/core/providers/AppSettings";
import {
  MdsPerson,
  MdsPersonXs,
  SinchStaffLeader,
  SinchStaffLeaderXs,
  SinchStandbyAlt,
  SinchStandbyAltXs,
} from "@sinch/icons";
import React, { ElementType } from "react";
import { useTranslation } from "react-i18next";
import { useEntityProps } from "../utilsHooks";

/**
 * @deprecated use usePositionRole hook instead
 */
export const positionRoleIcon = {
  [PositionRoleEnum.Crewboss]: SinchStaffLeader,
  [PositionRoleEnum.Worker]: MdsPerson,
  [PositionRoleEnum.Standby]: SinchStandbyAlt,
};
/**
 * @deprecated use usePositionRole hook instead
 */
export const positionRoleIconXs = {
  [PositionRoleEnum.Crewboss]: SinchStaffLeaderXs,
  [PositionRoleEnum.Worker]: MdsPersonXs,
  [PositionRoleEnum.Standby]: SinchStandbyAltXs,
};

export const usePositionRole = () => {
  const { crewbossName, positionRoleAbbreviations } = useSettings();
  const { t } = useTranslation();
  const { palette } = useTheme();

  const positionRoles = {
    [PositionRoleEnum.Crewboss]: {
      label: crewbossName.crewbossNameSingular1,
      icon: SinchStaffLeader,
      iconXs: SinchStaffLeaderXs,
      color: palette.entity.positionRole[PositionRoleEnum.Crewboss],
      abbr: positionRoleAbbreviations[PositionRoleEnum.Crewboss],
    },
    [PositionRoleEnum.Worker]: {
      label: t("PositionRole.staffMember"),
      icon: MdsPerson,
      iconXs: MdsPersonXs,
      color: palette.entity.positionRole[PositionRoleEnum.Worker],
      abbr: positionRoleAbbreviations[PositionRoleEnum.Worker],
    },
    [PositionRoleEnum.Standby]: {
      label: t("PositionRole.standby"),
      icon: SinchStandbyAlt,
      iconXs: SinchStandbyAltXs,
      color: palette.entity.positionRole[PositionRoleEnum.Standby],
      abbr: positionRoleAbbreviations[PositionRoleEnum.Standby],
    },
  } as Record<
    PositionRoleEnum,
    { label: string; icon: React.ElementType; iconXs: React.ElementType; color: string; abbr: string }
  >;
  const getProp = useEntityProps(positionRoles);

  const getRoleLabel = getProp<string>("label");
  const getRoleAbbreviation = getProp<string>("abbr");
  const getRoleIcon = getProp<ElementType>("icon");
  const getRoleIconXs = getProp<ElementType>("iconXs");
  const getRoleColor = getProp<string>("color");
  const getSortedRoles = () => Object.keys(positionRoles) as PositionRoleEnum[];

  return { getRoleLabel, getRoleIcon, getRoleColor, getRoleIconXs, getSortedRoles, getRoleAbbreviation };
};
