import { FormControlProps } from "@mui/material";
import React, { FC } from "react";
import { InputWrapperProps, FormControlWrapper } from "../FormControlWrapper/FormControlWrapper";
import { NumberInputBase, NumberInputBaseProps } from "./NumberInputBase";

export type NumberInputProps = NumberInputBaseProps &
  InputWrapperProps & {
    FormControlProps?: FormControlProps;
  };

/**
 * Number input with vertical buttons on side
 */
export const NumberInput: FC<NumberInputProps> = ({
  label,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  helperText,
  errorMessage,
  error,
  required,
  infoTooltip,
  ...props
}) => (
  <FormControlWrapper
    error={error}
    errorMessage={errorMessage}
    FormHelperTextProps={formHelperTextProps}
    helperText={helperText}
    infoTooltip={infoTooltip}
    label={label}
    required={required}
    {...formControlProps}
  >
    <NumberInputBase error={error} {...props} />
  </FormControlWrapper>
);
