import { useSettings } from "@sinch/core/providers/AppSettings";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import React, { forwardRef } from "react";
import { TimeInputBase } from "./TimeInputBase";
import { TimeInputLuxonAdapterProps } from "./types";

export const TimeInputLuxonAdapter = forwardRef<HTMLDivElement, TimeInputLuxonAdapterProps>((props, ref) => {
  const { timeZone } = useSettings();
  return (
    <TimeInputBase
      {...props}
      ref={ref}
      maxTime={toLuxon(props.maxTime) ?? undefined}
      minTime={toLuxon(props.minTime) ?? undefined}
      onChange={(val) => props.onChange(val?.toJSDate() ?? null)}
      timePoint={toLuxon(props.value)}
      timezone={timeZone}
      value={toLuxon(props.value)}
    />
  );
});

TimeInputLuxonAdapter.displayName = "TimeInputLuxonAdapter";
