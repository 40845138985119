import type { LazyQueryHookOptions, NoInfer } from "@apollo/client";
import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, useLazyQuery } from "@apollo/client";
import { useBrowserStatus } from "@sinch/core/providers/BrowserStatusProvider";
import { useCallback } from "react";

/**
 * Lazy load data from server and return loading and error statuses.
 * See [Apollo lazy query](https://www.apollographql.com/docs/react/data/queries/#manual-execution-with-uselazyquery)
 */
export function useLazyQueryHandler<
  TData = Record<string, unknown>,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options: Omit<QueryHookOptions<TData, TVariables>, "variables"> = {}
) {
  const [dispatchTrigger, { data, loading, error, refetch, called, variables: queryVariables }] = useLazyQuery<
    TData,
    TVariables
  >(query, {
    ...(variables ? variables : {}),
    ...(options ? options : {}),
  });
  const { pageStatus } = useBrowserStatus();

  const dispatch = useCallback(
    (fetchVariables?: TVariables, dispatchOptions?: LazyQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>) =>
      dispatchTrigger({ variables: fetchVariables || variables, ...dispatchOptions }),
    [dispatchTrigger, variables]
  );

  if (pageStatus !== "unloading" && error) {
    throw error;
  }

  return { dispatch, data, loading, error, refetch, called, variables: queryVariables };
}
