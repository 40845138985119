import { removeExceptFirst } from "@sinch/utils/string";
import i18next from "i18next";
import { isEmpty } from "ramda";
import { addMethod, AnyObject, Flags, Maybe, number, Schema } from "yup";

/**
 * Register custom validation in yup module for typescript
 */
declare module "yup" {
  interface NumberSchema<
    TType extends Maybe<number> = number | undefined,
    TContext = AnyObject,
    TDefault = undefined,
    TFlags extends Flags = "",
  > extends Schema<TType, TContext, TDefault, TFlags> {
    maxDecimals(decimals: number): NumberSchema<TType, TContext>;
  }
}

/**
 * Validate field for number of decimal places
 */
addMethod(number, "maxDecimals", function format(decimals: number) {
  const patternTwoDigisAfterComma = new RegExp(`^-?\\d+(\\.\\d{0,${decimals}})?$`);
  return this.test(
    "is-decimal",
    () => i18next.t("maxDecimals", { decimals }),
    (val: any) => {
      if (val !== undefined && val !== "") {
        let inputValue = String(val).replace(/[^\d.-]/g, ""); // remove all non-numeric chars
        inputValue = removeExceptFirst(inputValue, "."); // remove other dots
        inputValue = inputValue.slice(0, 1) + inputValue.slice(1).replace("-", ""); // remove more than one minus char
        return isEmpty(inputValue) || patternTwoDigisAfterComma.test(inputValue); // test on decimals
      }
      return true;
    }
  );
});
