import { FC, ReactElement } from "react";

export interface ConditionalWrapperProps<TChild = ReactElement<any, any>> {
  /**
   * Rendering conditions
   */
  condition: boolean;
  /**
   * Wrapper component
   */
  wrapper: (children: TChild) => ReactElement<any, any>;
  children: TChild;
}

/**
 * Wrap children with component on base of condition
 */
export const ConditionalWrapper: FC<ConditionalWrapperProps> = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
