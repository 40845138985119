// eslint-disable-next-line import/no-internal-modules
import { yupResolver } from "@hookform/resolvers/yup";
import { isEmpty, isNil, values } from "ramda";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FieldPath, FieldValues, useForm, useFormContext, UseFormReturn, useFormState } from "react-hook-form";
import { UseMuiFormProps, UseMuiFormRegister, UseMuiFormReturn } from "./useMuiForm.types";

export const FORM_ERRORS_FIELD = "_formErrors";

/**
 * Create react-hook-form extended with methods for material-ui library
 */
export function useMuiForm<TFieldValues extends FieldValues = FieldValues, TFormContext = any>({
  schema,
  formOptions,
}: UseMuiFormProps<TFieldValues, TFormContext>): UseMuiFormReturn<TFieldValues, TFormContext> {
  const form = useForm({
    criteriaMode: "all",
    resolver: schema ? yupResolver(schema) : undefined,
    ...formOptions,
    defaultValues: formOptions?.defaultValues ?? schema?.getDefault(),
  });

  const isReady = useRef<boolean>(true);
  const readyControlStore = useRef<((ready: boolean) => void)[]>([]);
  const readyControl = useMemo(
    () => ({
      subscribe: (next: (ready: boolean) => void) => {
        readyControlStore.current.push(next);
      },
      unsubscribe: (next: (ready: boolean) => void) => {
        readyControlStore.current = readyControlStore.current.filter((item) => item !== next);
      },
      setReady: (ready: boolean) => {
        if (isReady.current !== ready) {
          readyControlStore.current.forEach((item) => item(ready));
        }
        isReady.current = ready;
      },
    }),
    []
  );

  /**
   * Provide form register method for material-ui framework
   */
  const muiRegister: UseMuiFormRegister<TFieldValues> = useCallback(getMuiRegister(form), [form]);

  /**
   * Return list of global form errors, not associated with any field
   */
  const getFormErrors = useCallback(() => {
    const { error } = form.getFieldState(FORM_ERRORS_FIELD as FieldPath<TFieldValues>);
    return values(error?.types || {});
  }, [form]);

  if (import.meta.env.MODE === "development") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { errors } = useFormState<TFieldValues>({ control: form.control });

    if (!isEmpty(errors)) {
      console.error(
        "%c[FORM]%c Some field of form are invalid: ",
        "padding: 3px 0px; font-weight: bold; background: rgba(255,64,64,0.2)",
        "padding: 3px 0px; color: red; background: rgba(255,64,64,0.2)",
        errors
      );
    }
  }

  /**
   * Extend form methods with additional methods for material ui
   */
  return useMemo(
    () => ({
      ...form,
      muiRegister,
      getFormErrors,
      readyControl: readyControl,
      setReady: readyControl.setReady,
      isReady: isReady.current,
    }),
    [readyControl, form, muiRegister, getFormErrors]
  );
}

/**
 * generate a variant of `form.register` compatible with MUI components
 */
export const getMuiRegister =
  <V extends FieldValues>(form: UseFormReturn<V>): UseMuiFormRegister<V> =>
  (name, options) => {
    const { ref, ...registerProps } = form.register(name, options);
    const fieldError = form.getFieldState(name, form.formState).error;

    return {
      ...registerProps,
      inputRef: ref,
      error: !isNil(fieldError),
      errorMessage: values(fieldError?.types || {}) as string[],
    };
  };

/**
 * Hook for checking if form is ready for submit.
 */
export const useFormReady = () => {
  const [isReady, setIsReady] = useState(true);
  const { readyControl } = useFormContext() as UseMuiFormReturn;

  // Set ready state
  const updateReadyState = useCallback(
    (ready: boolean) => {
      if (ready !== isReady) {
        setIsReady(ready);
      }
    },
    [isReady]
  );

  // subscribe to ready state store
  useEffect(() => {
    readyControl.subscribe(updateReadyState);
    return () => readyControl.unsubscribe(updateReadyState);
  }, [readyControl, updateReadyState]);

  return isReady;
};
