import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutationResponseNotify } from "../../tools/useMutationResponseNotify";
import { useAttendanceUpdate } from "../Attendace/AttendanceUpdateProvider";
import { ApproveApplicant, DeclineApplicant } from "./queries.graphql";
import {
  ApproveApplicantMutation,
  ApproveApplicantMutationVariables,
  DeclineApplicantMutation,
  DeclineApplicantMutationVariables,
} from "./queries.types";

/**
 * Provide methods for applicant, eg to approve or decline
 */
export const useApplicantStatus = () => {
  const trigger = useAttendanceUpdate();
  const { t } = useTranslation();
  const { notifyResponse } = useMutationResponseNotify();

  const [approve] = useMutation<ApproveApplicantMutation, ApproveApplicantMutationVariables>(ApproveApplicant, {
    errorPolicy: "all",
  });
  const [decline] = useMutation<DeclineApplicantMutation, DeclineApplicantMutationVariables>(DeclineApplicant, {
    errorPolicy: "all",
  });
  // TODO: add adding to queue

  const approveApplicant = useCallback(
    async (id: ApproveApplicantMutationVariables["applicantId"], notify?: boolean) => {
      const response = await approve({
        variables: { applicantId: id },
      });
      if (response.data?.approveApplicant.payload) {
        trigger?.([
          {
            positionId: response.data?.approveApplicant.payload?.position.id,
            shiftId: response.data?.approveApplicant.payload?.position.shift.id,
            workerId: response.data?.approveApplicant.payload?.worker.id,
            attendanceId: response.data?.approveApplicant.payload?.id,
          },
        ]);
      }
      if (notify) {
        notifyResponse(response.data?.approveApplicant, t("Applicant.approved"));
      }
      return response.data?.approveApplicant ?? null;
    },
    [approve]
  );

  const declineApplicant = useCallback(
    async (
      id: DeclineApplicantMutationVariables["applicantId"],
      reason?: DeclineApplicantMutationVariables["reason"],
      notify?: boolean
    ) => {
      const response = await decline({
        variables: { applicantId: id, reason: reason },
      });
      if (notify) {
        notifyResponse(response.data?.declineApplicant, t("Applicant.denied"));
      }
      return response.data?.declineApplicant ?? null;
    },
    [decline]
  );

  return { approveApplicant, declineApplicant };
};
