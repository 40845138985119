import type { Coordinates } from "@sinch/types/common.types";

import { isNumber } from "ramda-adjunct";

interface IncompleteCoordinates {
  lat?: number | null | string;
  lng?: number | null | string;
}

export const extractCompleteCoordinates = (coords?: IncompleteCoordinates | null): Coordinates | null => {
  if (isNumber(coords?.lat) && isNumber(coords?.lng)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { lat: coords!.lat, lng: coords!.lng };
  }

  return null;
};

export const stringifyCoordinates = (coordinates: Coordinates) => {
  const { lat, lng } = coordinates;
  return `${String(lat)},${String(lng)}`;
};
