import { FILE_STATES } from "@rpldy/shared";
import { useAbortItem, useItemProgressListener } from "@rpldy/uploady";
import React, { FC, useCallback, useState } from "react";
import { FileItem, PreviewItemProps, UploadContainerProps } from "./types";

export type PreviewItemStateWrapperProps = FileItem &
  PreviewItemProps &
  Pick<UploadContainerProps, "previewItemComponent"> & {
    /**
     * Call on delete of uploaded item
     */
    onDelete: (id: string) => void;
  };

/**
 * Wrapper handle uploading state, aborting, removing and progress of file item
 */
export const PreviewItemStateWrapper: FC<PreviewItemStateWrapperProps> = ({
  previewItemComponent: PreviewItem,
  onDelete,
  id,
  state,
  ...props
}) => {
  const [progress, setProgress] = useState(0);
  /**
   * Update progress of uploading file
   */
  useItemProgressListener((item) => {
    setProgress(item.completed);
  }, id);

  const abortItem = useAbortItem();

  const handleDelete = useCallback(() => {
    if (state !== FILE_STATES.FINISHED) {
      abortItem(id);
    }
    onDelete(id);
  }, [abortItem, state, id, onDelete]);

  return <PreviewItem {...props} id={id} progress={progress} removePreview={handleDelete} state={state} />;
};
