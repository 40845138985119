import { alpha, Paper, Popover, PopoverProps, styled } from "@mui/material";
import { Property } from "csstype";
import React, { FC } from "react";

export interface ArrowPopoverProps extends PopoverProps {
  maxWidth?: Property.MaxWidth | undefined;
  arrowPosition?: "left" | "center" | "right";
}

/**
 * Simple popover with arrow. Arrow is situated on top now, but it can be easily changed to bottom.
 */
export const ArrowPopover: FC<ArrowPopoverProps> = ({ children, maxWidth, arrowPosition, ...props }) => (
  <Popover
    {...props}
    slotProps={{
      paper: {
        sx: (theme) => ({
          maxWidth: maxWidth ?? theme.breakpoints.values.sm,
        }),
      },
    }}
    slots={{ paper: PopoverPaper }}
  >
    {children}
    <TooltipArrow
      sx={() => {
        if (arrowPosition === "left") {
          return {
            left: "1.5em",
          };
        }
        if (arrowPosition === "right") {
          return {
            right: "1.5em",
          };
        }
        return {
          left: "calc(50% - 1.5em)",
        };
      }}
    />
  </Popover>
);

export const PopoverPaper = styled(Paper, {
  name: "ArrowPopover",
  slot: "Paper",
  overridesResolver: (props, styles) => styles.paper,
})(({ theme }) => ({
  position: "absolute",
  overflowY: "auto",
  overflowX: "hidden",
  // So we see the popover when it's empty.
  // It's most likely on issue on userland.
  minWidth: 16,
  minHeight: 16,
  maxWidth: "calc(100% - 32px)",
  maxHeight: "calc(100% - 32px)",
  // We disable the focus ring for mouse, touch and keyboard users.
  outline: 0,
  padding: theme.spacing(2),
  overflow: "unset",
  "&.MuiPaper-rounded": {
    borderRadius: "12px !important",
  },
}));

const TooltipArrow = styled("span", {
  name: "ArrowPopover",
  slot: "Arrow",
  overridesResolver: (props, styles) => styles.arrow,
})(({ theme }) => ({
  overflow: "hidden",
  position: "absolute",
  width: "3em",
  height: "1.41em" /* = width / sqrt(2) = (length of the hypotenuse) */,
  boxSizing: "border-box",
  color: alpha(theme.palette.grey[700], 0.9),
  top: "-1.41em",
  "&::before": {
    content: '""',
    margin: "auto",
    display: "block",
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    transform: "rotate(45deg)",
    transformOrigin: "0 calc(100% + 1em)",
    boxShadow: "0px 3px 14px 2px rgba(0,0,0,0.12)",
  },
}));
