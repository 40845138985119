import { PositionStatusEnum } from "@sinch/core/entities/serverEnums";
import React, { FC, PropsWithChildren } from "react";

export interface DurationStatusProps extends PropsWithChildren {
  status: PositionStatusEnum;
}

/*
Prefix children with symbol depending on position status, used for duration times
 */
export const DurationStatus: FC<DurationStatusProps> = ({ status, children }) => {
  if (status === PositionStatusEnum.CbCompleted) {
    return <>?{children}</>;
  } else if (status !== PositionStatusEnum.Completed) {
    return <>~{children}</>;
  }
  return children;
};
