import { Stack } from "@mui/material";
import { useFormat } from "@sinch/core/hooks/format";
import { rejectFalsy } from "@sinch/utils/array";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGender } from "../../Worker/gender";
import { LabelValue } from "../LabelValue";
import { StaffFragment } from "../queries.types";
import { DataPair } from "../types";

interface PersonalProps {
  staff: StaffFragment;
}

export const Personal: FC<PersonalProps> = ({ staff }) => {
  const { t } = useTranslation();
  const { date, address } = useFormat();
  const { getGenderLabel } = useGender();

  const dataPairs = rejectFalsy([
    staff.workerinfo && {
      label: t("CompanyForm.bankAccount"),
      value: staff.workerinfo.bankAccountString,
    },
    {
      label: t("NewStaffMemberForm.dateOfBirth"),
      value: date.medium(staff.birthdate),
    },
    staff.workerinfo && {
      label: t("NewStaffMemberForm.nationality"),
      value: address.country({ country: staff.workerinfo.citizenship }),
    },
    staff.workerinfo &&
      staff.workerinfo.countryLocal && {
        label: t("Staff.permanentResidencyIn", {
          country: staff.workerinfo.countryLocal,
        }),
        value: staff.workerinfo.permanentResident ? t("yes") : t("no"),
      },
    staff.workerinfo?.sex && {
      label: t("NewStaffMemberForm.gender"),
      value: getGenderLabel(staff.workerinfo.sex),
    },
  ]);

  return (
    <Stack>
      {(dataPairs as DataPair[])
        .filter(({ value }) => isNotNilOrEmpty(value))
        .map((pair) => (
          <LabelValue key={pair.label + pair.value} label={pair.label} value={pair.value} />
        ))}
    </Stack>
  );
};
