import React, { FC, ReactNode } from "react";
import { UploadInputProps } from "../types";
import { UploadInput } from "../UploadInput";
import { PreviewCard } from "./PreviewCard";
import { UploadButton } from "./UploadButton";
import { UploadChipPreviewsContainer } from "./UploadChipPreviewsContainer";

export interface UploadChipPreviewsProps extends Omit<UploadInputProps, "slots"> {
  /**
   * Upload button label
   */
  title: ReactNode;
}

/**
 * Upload chip previews component
 */
export const UploadChipPreviews: FC<UploadChipPreviewsProps> = ({ title, ...props }) => (
  <UploadInput
    {...props}
    slots={{
      container: UploadChipPreviewsContainer,
      previewItem: PreviewCard,
      upload: (uploadProps) => (
        <UploadButton
          {...uploadProps}
          extraProps={{
            ...uploadProps.extraProps,
            title: title,
            disabled: props.disabled,
            multiple: props.multiple,
            value: props.value,
          }}
        />
      ),
    }}
  />
);
