import { Typography, Link, Grid } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";

interface GalleryAttributeProps {
  label: string;
  files?: { name: string; url: string }[];
}

const showLimit = 10;

export const GalleryAttribute: FC<GalleryAttributeProps> = ({ label, files }) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);
  if (!files) {
    return null;
  }
  const isMore = files.length > showLimit;
  const filesToDisplay = showMore || !isMore ? files : files.slice(0, showLimit);

  return (
    <>
      <Typography
        sx={{
          pt: 1,
        }}
        variant="descriptionTinyBold"
      >
        {label}
      </Typography>
      <Grid container spacing={1}>
        {filesToDisplay.map((file) => (
          <Grid key={file.url} direction="row" item>
            <Link href={file.url} target="_blank">
              <img alt={file.name} height={64} src={file.url} width={64} />
            </Link>
          </Grid>
        ))}
      </Grid>
      {isMore && (
        <Link
          onClick={() => setShowMore(!showMore)}
          sx={{
            pt: 1,
          }}
          target="_blank"
        >
          {showMore ? t("showLess") : t("showMore")}
        </Link>
      )}
    </>
  );
};
