import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { TRAVEL_TIME_DURATION_ESTIMATE_PRECISION_MIN } from "@sinch/core/config/globals";
import { useFormat } from "@sinch/core/hooks/format";
import { useSettings } from "@sinch/core/providers/AppSettings";
import { kilometersToMiles } from "@sinch/utils/geolocation/units";
import { ceilToNearestDivisor } from "@sinch/utils/math";
import { isNil } from "ramda";
import { ceil, isNotNil, isTruthy } from "ramda-adjunct";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AddressInfoProps } from "@sinch/components/application/PlaceSelect/AddressInfo";
import { DescriptionList, DescriptionListItem } from "@sinch/components/typography/DescriptionList/DescriptionList";

export const DistanceInfo: FC<NonNullable<AddressInfoProps["distanceInfo"]>> = ({
  distance,
  duration: durationMinutes,
}) => {
  const { t } = useTranslation();
  const { duration, num } = useFormat();
  const { distanceUnit } = useSettings();
  const distanceInKm = distance ? distance / 1000 : null;

  if (isNil(distance) || isNil(durationMinutes)) {
    return null;
  }

  const roundedDuration = ceilToNearestDivisor(durationMinutes, TRAVEL_TIME_DURATION_ESTIMATE_PRECISION_MIN * 60);
  return (
    <Box
      sx={{
        pt: 1,
        flexGrow: 1,
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: "column",
        width: "fit-content",
      }}
    >
      <Typography variant="descriptionSmallBold">{t("Location.travelInfo")}</Typography>
      <DescriptionList
        items={
          [
            isNotNil(durationMinutes) && {
              title: t("Transport.travelDuration"),
              detail: `${duration.daysTime(roundedDuration)} (${duration.floatHoursNarrow(roundedDuration)})`,
            },
            isNotNil(distanceInKm) && {
              title: t("Transport.distance"),
              detail: num[distanceUnit](ceil(distanceUnit === "km" ? distanceInKm : kilometersToMiles(distanceInKm))),
            },
          ].filter(isTruthy) as DescriptionListItem[]
        }
      />
    </Box>
  );
};
