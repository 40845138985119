import { DateTime } from "luxon";

export function toLuxon(date: Date | DateTime, timeZone?: string): DateTime;
export function toLuxon(date: undefined, timeZone?: string): undefined;
export function toLuxon(date: null, timeZone?: string): null;
export function toLuxon(date: Date | DateTime | undefined, timeZone?: string): DateTime | undefined;
export function toLuxon(date: Date | DateTime | null, timeZone?: string): DateTime | null;
export function toLuxon(date: Date | DateTime | undefined | null, timeZone?: string): DateTime | undefined | null; // This overload is NOT redundant!
export function toLuxon(date: Date | DateTime | undefined | null, timeZone?: string): DateTime | undefined | null {
  if (!date) {
    return date;
  }

  const luxon = DateTime.isDateTime(date)
    ? date // I <3 PRETTIER
    : DateTime.fromJSDate(date);

  if (timeZone) {
    return luxon.setZone(timeZone);
  }

  return luxon;
}
