// eslint-disable-next-line import/no-internal-modules
import translation from "./en/common.json";

const dictionary = {
  ...translation,
} as const;

export { dictionary };

//export { default as dictionary } from "./en/common.json";
