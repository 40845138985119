import { Divider, Grid2 as Grid, InputBase, OutlinedInputProps, styled } from "@mui/material";
import { useSettings } from "@sinch/core/providers/AppSettings";
import { identity } from "ramda";
import React, { forwardRef, useCallback, useMemo, useState } from "react";
import { AutocompleteInput } from "../../form/AutocompleteInput/AutocompleteInput";
import { AutocompleteInputProps } from "@sinch/components/form/AutocompleteInput/types";
import { GooglePlaceAutocompleteWrapper } from "@sinch/components/form/GooglePlaceAutocompleteWrapper/GooglePlaceAutocompleteWrapper";

const defaultRadiusOptions = [1, 2, 5, 10, 30, 50, 100];

export interface PlaceRadiusValue {
  latitude: number;
  longitude: number;
  radius: number;
}

export type PlaceRadiusSelectInputBaseProps = OutlinedInputProps & {
  /**
   * Value object of PlaceRadiusSelect. { latitude, longitude, radius }
   */
  value: PlaceRadiusValue | null;
  onChange?: (val: PlaceRadiusValue | null) => void;
  /**
   * Radius options array of numbers
   * @default [1, 10, 30, 50, 100]
   */
  radiusOptions?: number[];
  /**
   * Deafult radius (if not passed 10 by default)
   */
  defaultRadius?: number;
};

/**
 * InputBase of Place select input, for searching place within certain radius
 */
export const PlaceRadiusSelectInputBase = forwardRef<HTMLDivElement, PlaceRadiusSelectInputBaseProps>(
  ({ onChange, value, defaultValue, defaultRadius = 10, ...props }, ref) => {
    const { distanceUnit } = useSettings();
    const radiusOptions = useMemo(
      () =>
        (props.radiusOptions ?? defaultRadiusOptions).map((radius) => ({
          value: radius,
          label: `+${radius} ${distanceUnit}`,
        })),
      [props.radiusOptions]
    );

    const [inputVal, setInputVal] = useState<string>(value ? `${value.latitude}, ${value.longitude}` : "");

    const handlePlaceChange = useCallback(
      (autocompleteObject: google.maps.places.PlaceResult) => {
        const locationLat = autocompleteObject.geometry?.location?.lat();
        const locationLng = autocompleteObject.geometry?.location?.lng();

        if (locationLat && locationLng) {
          onChange?.({
            latitude: locationLat,
            longitude: locationLng,
            radius: value?.radius ?? defaultRadius,
          });
          setInputVal(autocompleteObject.formatted_address ?? "");
        }
      },
      [onChange]
    );

    const handleRadiusChange = (radius: any) => {
      const newValue = Number(radius);
      if (value && value.latitude && value.longitude && !isNaN(newValue)) {
        onChange?.({
          ...value,
          radius: newValue,
        });
      }
    };

    return (
      <Grid ref={ref} alignItems="center" container direction="row" sx={{ width: "100%" }}>
        <Grid size="grow">
          <GooglePlaceAutocompleteWrapper
            onPlaceChanged={(autocompleteObject) => handlePlaceChange(autocompleteObject.getPlace())}
            types={["geocode"]}
          >
            <InputBase
              fullWidth
              onChange={(event) => {
                // prevent backfire on select
                if (!event) return;
                setInputVal(event.target.value);
              }}
              value={inputVal ?? ""}
            />
          </GooglePlaceAutocompleteWrapper>
        </Grid>
        <Divider flexItem orientation="vertical" variant="middle" />
        <Grid size={3.5} sx={{ display: "flex", alignItems: "center" }}>
          <PlaceRadiusSelectRadiusInput
            disableClearable
            filterOptions={identity}
            FormControlProps={{
              sx: {
                justifyContent: "end",
              },
            }}
            freeSolo
            inputReadOnly
            onChange={handleRadiusChange}
            onFocus={(e) => props.onFocus?.(e as React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>)}
            openOnFocus
            options={radiusOptions}
            placeholder={`+${defaultRadius} ${distanceUnit}`}
            size={props.size}
            value={`+${value ? String(value.radius) : defaultRadius} ${distanceUnit}`}
          />
        </Grid>
      </Grid>
    );
  }
);

PlaceRadiusSelectInputBase.displayName = "PlaceRadiusSelectInputBase";

const PlaceRadiusSelectRadiusInput = styled(AutocompleteInput, {
  name: "PlaceRadiusSelect",
  slot: "radiusInput",
})<AutocompleteInputProps>(() => ({
  "& .MuiInputBase-root": {
    lineHeight: 0,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    outline: "none !important",
    border: "none !important",
    boxShadow: `none !important`,
  },
  "& .Mui-focused": {
    boxShadow: `none !important`,
    outline: "none !important",
  },
})) as typeof AutocompleteInput;
