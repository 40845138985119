import { ApolloLink, NextLink, Operation } from "@apollo/client";
import { either, is, map, omit, pipe, unless, when } from "ramda";

type RemoveTypenameFn = (arg: Record<string, unknown>) => Omit<Record<string, unknown>, "__typename">;

const omitFromObject = unless(is(Array), omit(["__typename"]));

const removeTypename: RemoveTypenameFn = when(
  either(is(Array), is(Object)),
  pipe(
    // @ts-ignore complex type definition
    omitFromObject,
    map((a: Record<string, unknown>) => removeTypename(a))
  )
);

type OperationTypeNode = "query" | "mutation" | "subscription";

const removeTypenameFromMutation = (operation: Operation, forward: NextLink) => {
  const definition = operation?.query?.definitions.filter((def) => def.kind === "OperationDefinition")?.[0];
  const mutation: OperationTypeNode = "mutation";
  if (definition?.kind == "OperationDefinition" && definition?.operation === mutation) {
    operation.variables = removeTypename(operation.variables);
    return forward?.(operation);
  }
  return forward?.(operation);
};

const removeTypenameFromMutationLink = new ApolloLink(removeTypenameFromMutation);

export { removeTypenameFromMutationLink, removeTypenameFromMutation };
