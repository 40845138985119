import {
  Box,
  Button,
  ButtonBase,
  Container,
  FormControl,
  Grid,
  Stack,
  Switch,
  SwitchProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { useFormat } from "@sinch/core/hooks/format";
import { MdsAddXs, MdsAttachMoneyXs, MdsDeleteXs } from "@sinch/icons";
import { differenceIn } from "@sinch/utils/dateTime/dateFns";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import { adjust, dropLast } from "ramda";
import React, { Fragment, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BinaryInputControlWrapperProps } from "../../form/BinaryInputControlWrapper/BinaryInputControlWrapper";
import { DateTimeDurationInput } from "../../form/DateTimeDurationInput/DateTimeDurationInput";
import { InputVariants } from "../../form/DateTimeDurationInput/DateTimeDurationInputBase";
import { DateTimeInput } from "../../form/DateTimeInput/DateTimeInput";
import { SwitchInput } from "../../form/SwitchInput/SwitchInput";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { QuickEditButton } from "../../QuickEditButton/QuickEditButton";

type BreakSwitchInputValue =
  | {
      beginning: Date;
      end: Date;
      paid?: boolean;
    }[]
  | null;

interface BreakSwitchInputProps
  extends Omit<SwitchProps, "onChange">,
    Pick<
      BinaryInputControlWrapperProps,
      "helperText" | "FormHelperTextProps" | "infoTooltip" | "label" | "error" | "errorMessage"
    > {
  value?: BreakSwitchInputValue;
  onChange: (value: BreakSwitchInputValue) => void;
  minDate: Date;
  maxDate: Date;
  paidBreaksEnabled: boolean;
}

const BreakLabel: React.FC<{ beginning: Date; end: Date; paid?: boolean }> = ({ beginning, end, paid }) => {
  const { t } = useTranslation();
  const { time, duration } = useFormat();

  return (
    <Stack direction="row">
      <Typography
        sx={{
          lineHeight: 1,
        }}
        variant="body2"
      >
        {`${time.short(beginning)},  ${duration.floatMinutes(differenceIn("seconds")(end, beginning))}`}
      </Typography>
      {paid && (
        <Tooltip placement="top" title={t("Break.paid")}>
          <MdsIcon
            color="primary"
            icon={MdsAttachMoneyXs}
            sx={({ typography }) => ({ fontSize: typography.body1.fontSize })}
          />
        </Tooltip>
      )}
    </Stack>
  );
};

const LabelContent: React.FC<{ value?: BreakSwitchInputValue; disabled?: boolean; paidBreaksEnabled: boolean }> = ({
  value,
  disabled,
  paidBreaksEnabled,
}) => {
  const { t } = useTranslation();

  if (!value || value.length === 0) {
    return (
      <Box
        component="span"
        sx={(theme) => ({
          color: disabled ? theme.palette.grey["500"] : theme.palette.grey["800"],
        })}
      >
        {t("Break.name")}
      </Box>
    );
  }

  if (value?.length === 1) {
    return (
      <Box
        component="span"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        <Typography
          sx={(theme) => ({
            fontSize: "0.6875rem",
            color: theme.palette.grey["800"],
          })}
        >
          {t("Break.name")}
        </Typography>
        <BreakLabel beginning={value[0].beginning} end={value[0].end} paid={paidBreaksEnabled && value[0].paid} />
      </Box>
    );
  }

  if (value?.length === 2) {
    return (
      <Stack>
        {value?.map((breakValue, index) => (
          <BreakLabel
            key={index}
            beginning={breakValue.beginning}
            end={breakValue.end}
            paid={paidBreaksEnabled && breakValue.paid}
          />
        ))}
      </Stack>
    );
  }
  return (
    <Box
      component="span"
      sx={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      <BreakLabel beginning={value[0].beginning} end={value[0].end} paid={paidBreaksEnabled && value[0].paid} />
      <Typography
        sx={(theme) => ({
          fontSize: "0.6875rem",
          color: theme.palette.grey["800"],
          lineHeight: 1.1,
        })}
      >
        {t("andNMore", { count: value.length - 1 })}
      </Typography>
    </Box>
  );
};

export const BreakSwitchInput: React.FC<BreakSwitchInputProps> = ({
  disabled,
  value,
  minDate,
  maxDate,
  onChange,
  paidBreaksEnabled,
}) => {
  const { t } = useTranslation();
  const anchorRef = useRef<HTMLButtonElement | null>();
  const [popoverOpen, setPopoverOpen] = useState(false);

  const handleSwitchChange = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (value) {
      onChange(null);
      e.stopPropagation();
    }
  };

  const handlePaidChange = (index: number, checked: boolean) => {
    onChange(adjust(index, (attendanceBreak) => ({ ...attendanceBreak, paid: checked }), value!));
  };

  const handleStartDateChange = (index: number, val: Date | null) => {
    if (val) {
      let end: Date = new Date();
      if (value![index]?.end) {
        const diff = differenceIn("minutes")(value![index]?.end, value![index]?.beginning ?? minDate);
        end = toLuxon(val).plus({ minute: diff }).toJSDate();
      } else {
        end = toLuxon(val).plus({ minute: 30 }).toJSDate();
      }
      const newValue = adjust(
        index,
        (attendanceBreak) => ({ beginning: val, end, paid: attendanceBreak.paid }),
        value!
      );
      onChange(newValue);
    }
  };

  const handleEndDateChange = (index: number, val: Date | null) => {
    if (val && !toLuxon(value![index]?.beginning ?? minDate).equals(toLuxon(val))) {
      const newValue = adjust(
        index,
        (attendanceBreak) => ({
          beginning: value![index]?.beginning ?? minDate,
          end: val,
          paid: attendanceBreak.paid,
        }),
        value!
      );
      onChange(newValue);
    }
  };

  const closePopover = () => setPopoverOpen(false);
  const openPopover = () => setPopoverOpen(true);
  const pushNewBreak = () => onChange([...(value ?? []), { beginning: minDate, end: endDefaultValue(minDate) }]);
  const removeBreak = () => {
    if (value?.length === 1) {
      onChange(null);
      closePopover();
      return;
    }
    onChange(dropLast(1, value ?? []));
  };

  const beginningDefaultValue = (index: number) => (value![index]?.end ? toLuxon(minDate).toJSDate() : undefined);
  const endDefaultValue = (beginning?: Date) =>
    toLuxon(beginning ?? minDate)
      ?.plus({ minute: 30 })
      .toJSDate();

  const isSameDay = toLuxon(minDate)?.hasSame(toLuxon(maxDate) ?? new Date(0), "day");

  const handleButtonClick = () => {
    if (disabled) {
      return;
    }
    if (!value || value.length === 0) {
      pushNewBreak();
    }
    return openPopover();
  };

  const popoverContent = (
    <Container data-cy="break-switch-popper" disableGutters maxWidth="xs">
      <Grid container spacing={2}>
        {value?.map((breakValue, index) => (
          <Fragment key={index}>
            <Grid item sm={6} xs={12}>
              <DateTimeInput
                dateDisabled={isSameDay}
                label={t("Break.start")}
                maxTime={maxDate}
                minTime={minDate}
                onChange={(val) => handleStartDateChange(index, val)}
                value={breakValue?.beginning ?? beginningDefaultValue(index)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <DateTimeDurationInput
                hideSwitch
                inputVariant={InputVariants.TimeDuration}
                labelLeft={t("duration")}
                maxTime={maxDate}
                onChange={(val) => handleEndDateChange(index, val)}
                timeDurationAdornmentLabel={t("minutes")}
                timePoint={value ? breakValue.beginning : minDate}
                unit="minutes"
                value={breakValue?.end ? breakValue.end : endDefaultValue(breakValue.beginning)}
              />
            </Grid>
            {paidBreaksEnabled && (
              <Grid
                item
                sx={{
                  ml: 1,
                }}
                xs={12}
              >
                <SwitchInput
                  checked={breakValue.paid}
                  color="success"
                  label={t("Break.paid")}
                  onChange={(_, checked) => handlePaidChange(index, checked)}
                />
              </Grid>
            )}
          </Fragment>
        ))}
        <Grid
          item
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          xs={12}
        >
          <Button onClick={pushNewBreak} startIcon={<MdsIcon icon={MdsAddXs} />}>
            {t("Break.addBreak")}
          </Button>
          <Button color="error" onClick={removeBreak} startIcon={<MdsIcon icon={MdsDeleteXs} />}>
            {t("Break.removeBreak")}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <QuickEditButton
      boxProps={{
        sx: (theme) => ({
          padding: 0,
          width: "fit-content",
          display: "flex",
          borderColor: theme.palette.grey["200"],
          ...(disabled
            ? {
                "&:hover": {
                  borderColor: theme.palette.grey["200"],
                },
              }
            : {}),
        }),
      }}
      onClose={closePopover}
      open={popoverOpen}
      title={popoverContent}
    >
      <FormControl disabled={disabled}>
        <ButtonBase data-cy="break-switch" onClick={handleButtonClick} sx={{ textAlign: "left", display: "block" }}>
          <Stack
            direction="row"
            spacing={0.5}
            sx={[
              {
                alignItems: "center",
              },
              (theme) => ({
                backgroundColor: theme.palette.grey["200"],
                borderRadius: "5px !important",
                width: "150px",
                height: theme.shape.inputSmallHeight - 2, //QuickEditButton border
                pl: 1,
              }),
            ]}
          >
            <Switch
              ref={(refEl) => (anchorRef.current = refEl)}
              checked={!!value && value.length > 0}
              color="success"
              disabled={disabled}
              onClick={handleSwitchChange}
              size="small"
            />
            <LabelContent paidBreaksEnabled={paidBreaksEnabled} value={value} />
          </Stack>
        </ButtonBase>
      </FormControl>
    </QuickEditButton>
  );
};
