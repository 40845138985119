import { Box, styled, Theme, Tooltip, TooltipProps, Typography } from "@mui/material";
import { ReportTypeEnum } from "@sinch/core/entities/serverEnums";
import {
  MdsSentimentDissatisfiedXs,
  MdsSentimentNeutralXs,
  MdsSentimentSatisfiedXs,
  SinchFeedbackGeneralXs,
} from "@sinch/icons";
import { Report, User } from "@sinch/types/sinch.types";

import { isNil, reduce, reduced } from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import { useWorker } from "../application/Worker/hooks";
import { MdsIcon } from "@sinch/components";

export interface FeedbackIconProps {
  /*
   * Array of feedbacks, final feedback icon is aggregated from all feedback types
   */
  feedback: (Pick<Report, "id" | "type" | "text"> & { fromUser: Pick<User, "name" | "surname"> })[];
}

export enum Score {
  "positive",
  "negative",
  "neutral",
  "mixed",
}

export const getScore = (type: ReportTypeEnum | undefined | null): Score => {
  if (type === "GOOD") {
    return Score.positive;
  }
  if (type === "BAD") {
    return Score.negative;
  }

  return Score.neutral;
};

export const getScoreColor = (theme: Theme): Record<Score, { color: string; textColor?: string }> => ({
  [Score.positive]: {
    color: theme.palette.success.main,
    textColor: theme.palette.success.main,
  },
  [Score.negative]: {
    color: theme.palette.error.main,
    textColor: theme.palette.custom.red["200"],
  },
  [Score.neutral]: {
    color: theme.palette.grey["800"],
    textColor: undefined,
  },
  [Score.mixed]: {
    color: theme.palette.secondary.dark,
  },
});

export const scoreIcon = {
  [Score.positive]: MdsSentimentSatisfiedXs,
  [Score.negative]: MdsSentimentDissatisfiedXs,
  [Score.neutral]: MdsSentimentNeutralXs,
  [Score.mixed]: SinchFeedbackGeneralXs,
};

/**
 * Icon for worker feedback with comments in Tooltip
 */
export const FeedbackIcon: React.FC<FeedbackIconProps> = React.memo(({ feedback }) => {
  const { t } = useTranslation();
  const { getWorkerName } = useWorker();

  if (!feedback?.length) {
    return;
  }

  const score = reduce(
    (acc, rating) => {
      const ratingScore = getScore(rating.type as ReportTypeEnum);
      return ratingScore === acc ? acc : reduced(Score.mixed);
    },
    getScore(feedback[0].type as ReportTypeEnum),
    feedback
  );

  return (
    <StyledFeedbackTooltip
      placement="bottom"
      score={score as Score}
      title={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          {feedback
            .filter(({ text }) => !isNil(text))
            .map(({ text, type, fromUser }, index) => (
              <Typography
                key={index}
                sx={{
                  color: (theme) => getScoreColor(theme)[getScore(type as ReportTypeEnum)].textColor,
                  fontSize: "0.6875rem",
                  lineHeight: 1.1,
                }}
              >
                {text} ({fromUser ? getWorkerName(fromUser) : t("you")})
              </Typography>
            ))}
        </Box>
      }
    >
      <div>
        <MdsIcon fontSize="xs" icon={scoreIcon[score]} />
        {feedback.length > 1 && feedback.length}
      </div>
    </StyledFeedbackTooltip>
  );
});
FeedbackIcon.displayName = "FeedbackIcon";

const StyledFeedbackTooltip = styled(Tooltip)<TooltipProps & { score: Score }>(({ theme, score }) => ({
  ...theme.typography.button,
  color: getScoreColor(theme)[score].color,
  display: "flex",
  alignItems: "center",
  width: "fit-content",
  height: 16,
  svg: {
    color: getScoreColor(theme)[score].color,
    marginRight: 3,
    marginTop: -2,
  },
}));
