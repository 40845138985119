import RangeItem from "./RangeItem";

/**
 * @constructor SimpleRangeItem
 * @extends Item
 */
export class SimpleRangeItem extends RangeItem {
  /**
   * @param {Object} data             Object containing parameters start, end
   *                                  content, className.
   * @param {{toScreen: function, toTime: function}} conversion
   *                                  Conversion functions from time to screen and vice versa
   * @param {Object} [options]        Configuration options
   *                                  // TODO: describe options
   */
  constructor(data, conversion, options) {
    super(data, conversion, options);
    this.props = {
      content: {
        width: 0,
      },
    };
    this.overflow = false; // if contents can overflow (css styling), this flag is set to true

    this.lastWidth = 0;
    this.lastTop = 0;

    this.lastStart = null;
    this.lastEnd = null;

    this.lastHeight = null;

    const self = this;
    this.resizeObserver = new ResizeObserver((entries) => {
      const height = Math.floor(entries[0].contentRect.height);
      if ( height > 0 ) {
        self.height = height;
        self.dirty = false;
        if (self.lastHeight !== height) {
          self.dirty = true;
          if (self.parent) {
            self.parent.stackDirty = true;
            self.parent.lastHeight = 0;
          }
          if (self.lastHeight !== null) {
            self.heightChangeCallbacks.forEach((callback) => (callback.bind(this))(height))
          }
          self.lastHeight = height
        }
      }
    });

    // validate data
    if (data) {
      if (data.start == undefined) {
        throw new Error(`Property "start" missing in item ${data.id}`);
      }
      if (data.end == undefined) {
        throw new Error(`Property "end" missing in item ${data.id}`);
      }
    }
  }

  /**
   * Check whether this item is visible inside given range
   *
   * @param {timeline.Range} range with a timestamp for start and end
   * @returns {boolean} True if visible
   */
  isVisible(range) {
    if (this.cluster) {
      return false;
    }
    // determine visibility
    return this.data.start < range.end && this.data.end > range.start;
  }

  /**
   * create DOM elements
   * @private
   */
  _createDomElement() {
    if (!this.dom) {
      // create DOM
      this.dom = {};

      // background box
      this.dom.box = document.createElement("div");
      // className is updated in redraw()

      // contents box
      this.dom.content = document.createElement("div");
      this.dom.content.className = "vis-item-content";
      this.dom.box.appendChild(this.dom.content);

      // attach this item as attribute
      this.dom.box["vis-item"] = this;


      this.resizeObserver.observe(this.dom.box);


      this.dirty = true;
    }
  }

  /**
   * append element to DOM
   * @private
   */
  _appendDomElement() {
    if (!this.parent) {
      throw new Error("Cannot redraw item: no parent attached");
    }
    if (!this.dom.box.parentNode) {
      const foreground = this.parent.dom.foreground;
      if (!foreground) {
        throw new Error("Cannot redraw item: parent has no foreground container element");
      }
      foreground.appendChild(this.dom.box);
    }
    this.displayed = true;
  }

  /**
   * update dirty DOM components
   * @private
   */
  _updateDirtyDomComponents() {
    // update dirty DOM. An item is marked dirty when:
    // - the item is not yet rendered
    // - the item's data is changed
    // - the item is selected/deselected
    if (this.dirty) {
      this._updateContents(this.dom.content);
      this._updateDataAttributes(this.dom.box);
      this._updateStyle(this.dom.box);

      const editable = this.editable.updateTime || this.editable.updateGroup;

      // update class
      const className =
        (this.data.className ? " " + this.data.className : "") +
        (this.selected ? " vis-selected" : "") +
        (editable ? " vis-editable" : " vis-readonly");
      this.dom.box.className = this.baseClassName + className;

      // turn off max-width to be able to calculate the real width
      // this causes an extra browser repaint/reflow, but so be it
      //setTimeout(() => {this.dom.content.style.maxWidth = 'none';}, 0);

      //this.dom.content.style.maxWidth = 'none';
    }
  }

  /**
   * get DOM component sizes
   * @return {object}
   * @private
   */
  _getDomComponentsSizes() {
    // determine from css whether this box has overflow
    this.whiteSpace = false;

    let start = this.conversion.toScreen(this.data.start);
    let end = this.conversion.toScreen(this.data.end);

    return {
      content: {
        width: end - start,
      }
    };
  }

  /**
   * update DOM component sizes
   * @param {array} sizes
   * @private
   */
  _updateDomComponentsSizes(sizes) {
    this.props.content.width = sizes.content.width;
    if (typeof this.height === 'undefined' || this.height === null) {
        this.height = this.dom.content.offsetHeight;
    }
    //this.height = sizes.box.height;
    //setTimeout(() => {this.dom.content.style.maxWidth = '';}, 0);
    //window.requestAnimationFrame(() => this.dom.content.style.maxWidth = '');
    //this.dom.content.style.maxWidth = '';
    this.dirty = false;
  }

  /**
   * repaint DOM additional components
   * @private
   */
  _repaintDomAdditionals() {
    this._repaintOnItemUpdateTimeTooltip(this.dom.box);
    this._repaintDeleteButton(this.dom.box);
    this._repaintDragCenter();
    this._repaintDragLeft();
    this._repaintDragRight();
  }

  /**
   * Repaint the item
   * @param {boolean} [returnQueue=false]  return the queue
   * @return {boolean} the redraw queue if returnQueue=true
   */
  redraw(returnQueue) {
    let sizes;
    const queue = [
      // create item DOM
      this._createDomElement.bind(this),

      // append DOM to parent DOM
      this._appendDomElement.bind(this),

      // update dirty DOM
      this._updateDirtyDomComponents.bind(this),

      () => {
        if (this.dirty) {
          sizes = this._getDomComponentsSizes.bind(this)();
        }
      },

      () => {
        if (this.dirty) {
          this._updateDomComponentsSizes.bind(this)(sizes);
        }
      },

      // repaint DOM additionals
      this._repaintDomAdditionals.bind(this),
    ];

    if (returnQueue) {
      return queue;
    } else {
      let result;
      queue.forEach((fn) => {
        result = fn();
      });
      return result;
    }
  }

  /**
   * Show the item in the DOM (when not already visible). The items DOM will
   * be created when needed.
   * @param {boolean} [returnQueue=false]  whether to return a queue of functions to execute instead of just executing them
   * @return {boolean} the redraw queue if returnQueue=true
   */
  show(returnQueue) {
    if (!this.displayed) {
      return this.redraw(returnQueue);
    }
  }

  /**
   * Hide the item from the DOM (when visible)
   */
  hide() {
    if (this.displayed) {
      const box = this.dom.box;

      if (box.parentNode) {
        box.parentNode.removeChild(box);
      }

      this.displayed = false;
    }
  }

  /**
   * Reposition the item horizontally
   * @param {boolean} [limitSize=true] If true (default), the width of the range
   *                                   item will be limited, as the browser cannot
   *                                   display very wide divs. This means though
   *                                   that the applied left and width may
   *                                   not correspond to the ranges start and end
   * @Override
   */
  repositionX() {
    let start = this.conversion.toScreen(this.data.start);
    let end = this.conversion.toScreen(this.data.end);

    // Check if changed xy position is not equal to the previous one
    if (this.lastStart === start && this.lastEnd === end) {
      return;
    }
    this.lastStart = start;
    this.lastEnd = end;

    //round to 3 decimals to compensate floating-point values rounding
    const boxWidth = Math.max(Math.round((end - start) * 1000) / 1000, 1);

    this.left = start;
    this.width = boxWidth;

    this.dom.box.style.transform = `translate3d(${this.left}px, 0px, 0px)`;
    if (this.lastWidth !== boxWidth) {
      this.dom.box.style.width = `${boxWidth}px`;
      this.lastWidth = boxWidth;
    }
  }

  /**
   * Reposition the item vertically
   * @Override
   */
  repositionY() {
    if (this.lastTop === this.top) {
      return;
    }

    const orientation = this.options.orientation.item;
    const box = this.dom.box;
    this.lastTop = this.top;

    if (orientation === "top") {
      box.style.top = `${this.top}px`;
    } else {
      box.style.top = `${this.parent.height - this.top - this.height}px`;
    }
  }
}

SimpleRangeItem.prototype.baseClassName = "vis-item vis-range";

export default SimpleRangeItem;
