import React from "react";
import { useTranslation } from "react-i18next";
import { GooglePlaceAutocompleteWrapper } from "../../../form/GooglePlaceAutocompleteWrapper/GooglePlaceAutocompleteWrapper";
import { useMuiController } from "../../../form/MuiController/MuiController";
import { TextInput } from "../../../form/TextInput/TextInput";
import { PlaceFormFields } from "./formSchema";

export const NameInput = ({ onPlaceChanged }: { onPlaceChanged: (place: google.maps.places.PlaceResult) => void }) => {
  const { t } = useTranslation();
  const { field } = useMuiController<PlaceFormFields>({
    name: "name",
  });

  return (
    <GooglePlaceAutocompleteWrapper
      onPlaceChanged={(autocompleteObject) => onPlaceChanged(autocompleteObject.getPlace())}
      types={["establishment"]}
    >
      <TextInput
        {...field}
        label={t("name")}
        slotProps={{
          input: { "data-cy": "locationName" },
        }}
      />
    </GooglePlaceAutocompleteWrapper>
  );
};
