import { Box, BoxProps, styled } from "@mui/material";
import { unstable_generateUtilityClasses as generateUtilityClasses } from "@mui/utils";
import React from "react";
import { ConditionalWrapper } from "../ConditionalWrapper/ConditionalWrapper";
import { MdsIcon } from "../MdsIcon/MdsIcon";
import { TextExtender } from "../typography/TextExtender/TextExtender";

interface InfoBoxItemProps extends BoxProps {
  /**
   * Icon at before Title
   */
  icon?: React.ElementType;
  /**
   * optional title (is in bold)
   */
  title?: string;
  text?: React.ReactNode;
  /**
   * if used text will collapse after chosen number of lines, and "show more" will be shown
   */
  maximumVisibleLines?: number;
}

const classes = generateUtilityClasses("InfoBoxItem", ["icon", "title", "text", "showMoreLess"]);

/**
 * Used as component for piece of information in InfoBox
 */
export const InfoBoxContent: React.FC<InfoBoxItemProps> = ({ icon, title, text, maximumVisibleLines, ...boxProps }) => (
  <InfoBoxItemBox data-cy="InfoBoxContent" {...boxProps}>
    {icon && <MdsIcon className={classes.icon} fontSize="xs" icon={icon} />}
    {title && <span className={classes.title}>{title}</span>}
    {text && (
      <ConditionalWrapper
        condition={!!maximumVisibleLines}
        wrapper={(children) => (
          <TextExtender className={classes.showMoreLess} lineHeight={17} maxLines={maximumVisibleLines}>
            {children}
          </TextExtender>
        )}
      >
        <>{text}</>
      </ConditionalWrapper>
    )}
  </InfoBoxItemBox>
);

const InfoBoxItemBox = styled(Box)<BoxProps>(({ theme }) => ({
  marginLeft: 20,
  display: "flex",
  lineHeight: 1.15,
  [`& .${classes.title}`]: theme.typography.descriptionSmallSemi,
  [`& .${classes.icon}`]: {
    marginLeft: -20,
    marginTop: 2,
    width: 20,
  },
  [`& .${classes.text}`]: { ...theme.typography.descriptionSmall, display: "inline" },
  [`& .${classes.showMoreLess}`]: {
    marginLeft: -20,
  },
  ["& p:first-of-type"]: {
    display: "inline-block",
  },
}));
