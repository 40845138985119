export const constants = {
  /**
   * Count of toasts visible in notice stack
   */
  noticeStackCount: 3,
  /**
   * Default language of application used for i18n libraries
   */
  defaultLng: "en",
};

/**
 * default limit of options for search fields
 */
export const SEARCH_FIELD_LIMIT = 30;

export const DEFAULT_MAP_LAT = 50.0808;
export const DEFAULT_MAP_LNG = 14.4248;

export const SMS_MAX_LENGTH = 459;

/**
 * Set to which hour fraction (minutes) should be an estimate of travel time rounded
 */
export const TRAVEL_TIME_DURATION_ESTIMATE_PRECISION_MIN = 15;
