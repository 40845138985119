import * as Sentry from "@sentry/react";
import { useCallback } from "react";

/**
 * Send error to error monitor if possible
 */
export const useErrorLogger = (error?: Error) => {
  const sendErrorIfPossible = useCallback(() => {
    if (error) {
      return Sentry.captureException(error);
    }
    return null;
  }, [error]);

  return sendErrorIfPossible();
};
