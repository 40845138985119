import { Typography } from "@mui/material";
import { TransportDirection } from "@sinch/core/entities/serverEnums";
import { useFormat } from "@sinch/core/hooks/format";
import React from "react";
import { useTranslation } from "react-i18next";
import { ShiftPanelDetailFragment } from "./queries.types";

interface BasicInfoProps {
  transportTrips: ShiftPanelDetailFragment["transportTrips"];
}

export const ShiftTransportInfo: React.FC<BasicInfoProps> = ({ transportTrips }) => {
  const { t } = useTranslation();
  const { date, time } = useFormat();
  return (
    <>
      {transportTrips?.map((trip, index) => (
        <>
          <Typography
            key={trip.direction}
            sx={{
              pt: index > 0 ? 2 : 0,
            }}
            variant="descriptionSmallSemi"
          >
            {trip.direction === TransportDirection.There ? t("Transport.there") : t("Transport.back")}
          </Typography>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {t("Shift.departure")}
          </Typography>
          <Typography variant="descriptionSmall">
            {date.short(trip.beginning)} {time.short(trip.beginning)}
          </Typography>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {t("Shift.arrival")}
          </Typography>
          <Typography variant="descriptionSmall">
            {date.short(trip.end)} {time.short(trip.end)}
          </Typography>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {t("location")}
          </Typography>
          <Typography variant="descriptionSmall">{trip.place.name}</Typography>
        </>
      ))}
    </>
  );
};
