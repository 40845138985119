import { Tooltip } from "@mui/material";
import { AttendanceReconfirmationStatusEnum } from "@sinch/core/entities/serverEnums";
import { AttendanceReconfirmationStatusEnum as AttendanceReconfirmationStatus } from "@sinch/types/sinch.types";
import React from "react";
import { MdsIcon, MdsIconProps } from "../../MdsIcon/MdsIcon";
import { useAttendanceReconfirmationStatus } from "@sinch/components/application/Attendace/useAttendanceReconfirmationStatus";

interface AttendanceStatusIconProps extends Omit<MdsIconProps, "icon"> {
  status?: AttendanceReconfirmationStatusEnum | AttendanceReconfirmationStatus | null;
}

export const ReconfirmationStatusIcon: React.FC<AttendanceStatusIconProps> = ({ status, ...props }) => {
  const { getReconfirmationStatusColor, getReconfirmationStatusLabel, getReconfirmationStatusIcon } =
    useAttendanceReconfirmationStatus();
  if (status !== AttendanceReconfirmationStatusEnum.Pending) {
    return null;
  }

  return (
    <Tooltip title={getReconfirmationStatusLabel(status)}>
      <MdsIcon
        fontSize="small"
        icon={getReconfirmationStatusIcon(status)}
        sx={{ color: getReconfirmationStatusColor(status) }}
        {...props}
      />
    </Tooltip>
  );
};
