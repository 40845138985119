import { setRef } from "@mui/material";
import { isFalsy } from "ramda-adjunct";
import React, { useImperativeHandle, useRef } from "react";
import {
  ControllerProps,
  FieldPath,
  FieldPathValue,
  FieldValues,
  get,
  set,
  useController,
  UseControllerProps,
  useFormContext,
} from "react-hook-form";
import { MuiControllerObject } from "./types";
import { toMuiFormat } from "./utils";

export function useMuiController<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  required,
  disabled,
  ...props
}: Omit<UseControllerProps<TFieldValues, TName>, "defaultValue"> & {
  /**
   * Shorthand for `rules: { required: true }`
   */
  required?: boolean | string;
  /**
   * Shorthand for `rules: { required: true }`
   */
  disabled?: boolean;
  /**
   * @deprecated Set default value at form level instead of field, setting on field can cause that async default value will be overwritten by field default value
   */
  defaultValue?: FieldPathValue<TFieldValues, TName>;
}) {
  // This is a workaround for setting default value at mout of input component
  const methods = useFormContext<TFieldValues>();
  const { name, control = methods.control, defaultValue } = props;
  if (typeof defaultValue !== "undefined" && typeof get(control._defaultValues, name) === "undefined") {
    set(control._defaultValues, name, defaultValue);
  }

  const controllerProps = useController<TFieldValues, TName>({
    ...props,
    rules: { required: !disabled && required, ...props.rules },
  });

  const inputRef = useRef<HTMLInputElement>(null);

  /**
   * Override ref to allow focus with scroll into viewport function
   */
  useImperativeHandle(
    controllerProps.field.ref,
    () =>
      ({
        focus() {
          // TODO: investigate why this line significantly slows down the app
          //if (inputRef.current?.tagName === "INPUT") {
          //  inputRef.current?.focus({ preventScroll: true });
          //}
          inputRef.current?.scrollIntoView({ behavior: "smooth", block: "center" });
        },

        reportValidity: () => inputRef.current?.reportValidity(),
        select: () => inputRef.current?.select(),
        setCustomValidity: (message) => inputRef.current?.setCustomValidity(message),
      }) as HTMLInputElement,
    []
  );

  return toMuiFormat(
    { ...controllerProps, field: { ...controllerProps.field, ref: (el) => setRef(inputRef, el) } },
    !isFalsy(required),
    disabled
  );
}

export interface MuiControllerProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> extends Omit<ControllerProps<TFieldValues, TName>, "render" | "defaultValue"> {
  render: (renderProps: MuiControllerObject<TFieldValues, TName>) => React.ReactElement;
  /**
   * Shorthand for `rules: { required: true }`
   */
  required?: boolean | string;
  /**
   * Shorthand for `rules: { disabled: true }`
   */
  disabled?: boolean;
  /**
   * @deprecated Set default value at form level instead of field, setting on field can cause that async default value will be overwritten by field default value
   */
  defaultValue?: FieldPathValue<TFieldValues, TName>;
}

export const MuiController = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: MuiControllerProps<TFieldValues, TName>
) => {
  const fields = useMuiController<TFieldValues, TName>(props);

  return props.render(fields);
};
