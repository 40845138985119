import { lighten, Paper, PaperProps, Stack, styled, Theme, Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

type InfoBoxColors = "default" | "error" | "info" | "success" | "warning" | "note";

interface InfoBoxProps extends PropsWithChildren {
  /**
   * Title of the box, show as header
   */
  title?: string;
  color?: InfoBoxColors;
  height?: string;
  button?: React.ReactNode;
  padding?: string;
  icon?: React.ReactNode;
}

const getColors = ({
  palette: { custom, error, grey, info, success, warning },
}: Theme): Record<InfoBoxColors, string> => ({
  default: grey["100"],
  note: custom.beige["100"],
  error: lighten(error.main, 0.8),
  info: lighten(info.main, 0.8),
  warning: lighten(warning.main, 0.8),
  success: lighten(success.main, 0.8),
});

/**
 * Visually distinctive box for information related to one topic
 */
export const InfoBox: React.FC<InfoBoxProps> = ({
  children,
  icon,
  title,
  color = "default",
  height,
  button,
  padding,
}) => (
  <InfoBoxPaper color={color} data-cy="InfoBoxPaper" sx={{ height, padding }} variant="outlined">
    {(icon || title) && (
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
            pb: icon ? undefined : 1,
          }}
        >
          {icon}
          {title && <Typography variant="descriptionSemi">{title}</Typography>}
        </Stack>
        {button ?? null}
      </Stack>
    )}
    {children}
  </InfoBoxPaper>
);

const InfoBoxPaper = styled(Paper)<{ color?: InfoBoxColors } & PaperProps>(({ theme, color = "default" }) => ({
  borderRadius: "12px !important",
  border: "none",
  backgroundColor: getColors(theme)[color],
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  color: theme.palette.getContrastText(getColors(theme)[color]),
  wordBreak: "break-word",
}));
