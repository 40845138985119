import { useTheme } from "@mui/material";
import { RoleStatusEnum } from "@sinch/core/entities/serverEnums";
import { MdsNoAccountsFill, MdsNotificationsPausedFill, MdsPersonFill, MdsPersonOffFill } from "@sinch/icons";
import { RoleStatusEnum as RoleStatus } from "@sinch/types/sinch.types";

import React, { ElementType } from "react";
import { useTranslation } from "react-i18next";
import { useEntityProps } from "../utilsHooks";

/**
 * Custom hook for handling role status display properties
 */
export const useRoleStatus = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  /**
   * Mapping of role statuses to their display properties
   */
  const RoleStatuses = {
    [RoleStatusEnum.Deleted]: {
      label: t("RoleStatus.deleted"),
      icon: MdsNoAccountsFill,
      color: theme.palette.error.main,
    },
    [RoleStatusEnum.Hibernated]: {
      label: t("RoleStatus.hybernated"),
      icon: MdsNotificationsPausedFill,
      color: theme.palette.custom.purple["500"],
    },
    [RoleStatusEnum.Blocked]: {
      label: t("RoleStatus.blocked"),
      icon: MdsPersonOffFill,
      color: theme.palette.error.main,
    },
    [RoleStatusEnum.Active]: {
      label: t("RoleStatus.active"),
      icon: MdsPersonFill,
      color: theme.palette.success.main,
    },
  } as Record<RoleStatusEnum | RoleStatus, { label: string; icon: React.ElementType; color: string }>;
  const getProp = useEntityProps(RoleStatuses);

  const getRoleStatusLabel = getProp<string>("label");
  const getRoleStatusIcon = getProp<ElementType>("icon");
  const getRoleStatusColor = getProp<string>("color");

  return { getRoleStatusLabel, getRoleStatusIcon, getRoleStatusColor };
};
