import { FormControlProps } from "@mui/material";
import type { Coordinates } from "@sinch/types/common.types";

import React, { FC } from "react";
import { FormControlWrapper, InputWrapperProps } from "../FormControlWrapper/FormControlWrapper";
import { MapCoordinatesSelectInputBase, MapCoordinatesSelectInputBaseProps } from "./MapCoordinatesSelectInputBase";

export type MapCoordinatesSelectInputProps = MapCoordinatesSelectInputBaseProps &
  InputWrapperProps & {
    FormControlProps?: FormControlProps;
    onChange?: (coords: Coordinates) => void;
    value?: Coordinates | null;
    required?: boolean;
  };

/**
 * Number input with vertical buttons on side
 */
export const MapCoordinatesSelectInput: FC<MapCoordinatesSelectInputProps> = ({
  label,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  helperText,
  errorMessage,
  error,
  required,
  infoTooltip,
  onChange,
  value,
  ...props
}) => (
  <FormControlWrapper
    error={error}
    errorMessage={errorMessage}
    FormHelperTextProps={formHelperTextProps}
    helperText={helperText}
    infoTooltip={infoTooltip}
    label={label}
    required={required}
    {...formControlProps}
  >
    <MapCoordinatesSelectInputBase
      mapContainerStyle={{
        width: "100%",
        height: 500,
      }}
      value={value}
      {...props}
      onChange={(coords) => {
        onChange?.(coords);
      }}
    />
  </FormControlWrapper>
);
