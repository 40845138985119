import { debounce, Grid, InputAdornment, Stack } from "@mui/material";
import type { FullPlaceFragment } from "@sinch/core/entities/fragments.types";
import { MdsLocationOnXs } from "@sinch/icons";
import { extractCompleteCoordinates } from "@sinch/utils/geolocation/coordinates";
import { find, identity, propEq } from "ramda";

import { isNotNil } from "ramda-adjunct";
import React, { FC, Ref, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../../form/AutocompleteInput/AutocompleteInput";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { AddNewLocationDialog } from "./AddNewLocationDialog/AddNewLocationDialog";
import { MapPositionPreview } from "./MapPositionPreview";
import { usePlaceSelect } from "./usePlaceSelect";
import { AddressInfo } from "@sinch/components/application/PlaceSelect/AddressInfo";
import { useDirectionData } from "@sinch/components/application/PlaceSelect/UseDirectionData";
import { AutocompleteInputProps, AutocompleteOptions } from "@sinch/components/form/AutocompleteInput/types";

export interface PlaceSelectProps<TPathPoint extends string | null = string | null>
  extends Pick<AutocompleteInputProps, "slotProps" | "textFieldProps"> {
  /**
   * Input name
   */
  name: string;

  label: string;

  required?: boolean | string;

  value: string;
  onChange?: (val: string | number | null) => void;
  disabled?: boolean;
  error?: boolean;
  errorMessage: string[];
  inputRef: Ref<any>;
  onBlur: () => void;
  pathPoint?: TPathPoint;
  /**
   * Path direction is used to determine if the path point is from origin to destination or vice versa
   */
  pathDirection?: TPathPoint extends string ? "origin" | "destination" : never;
}

/**
 * Find place in DB and return his ID as value
 */
export const PlaceSelect: FC<PlaceSelectProps> = ({
  value,
  onChange,
  label,
  required,
  pathPoint,
  pathDirection = "destination",
  ...props
}) => {
  const {
    options,
    onChange: handleChange,
    selectedPlace,
    dispatch,
    loading,
    setIsAddLocationDialogOpen,
    isAddLocationDialogOpen,
  } = usePlaceSelect({ value, onChange });
  const { selectedPlace: destinationPlace } = usePlaceSelect({ value: pathPoint ?? null });

  const handleAddPlace = (place: FullPlaceFragment) => {
    setIsAddLocationDialogOpen(false);
    onChange?.(place.id);
  };

  const { origin, destination, path, distance, duration } = useDirectionData({
    destination: extractCompleteCoordinates(pathDirection === "destination" ? destinationPlace : selectedPlace),
    origin: extractCompleteCoordinates(pathDirection === "destination" ? selectedPlace : destinationPlace),
  });

  const mapPreviewCoordiantes = useMemo(
    () => (pathPoint && !destination ? [] : [origin, destination].filter(isNotNil)),
    [pathPoint, origin, destination]
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Stack spacing={2} sx={{ height: "100%" }}>
            <PlaceAutocompleteInput
              {...props}
              dispatch={dispatch}
              label={label}
              loading={loading}
              onAddAction={() => setIsAddLocationDialogOpen(true)}
              onChange={handleChange}
              options={options}
              required={required}
              value={value}
            />
            {selectedPlace && (
              <AddressInfo
                currentSelectedPlace={selectedPlace}
                distanceInfo={{
                  distance,
                  duration,
                }}
              />
            )}
          </Stack>
        </Grid>
        <Grid item md={6} sx={{ maxWidth: "100%", width: "100%" }} xs={12}>
          <MapPositionPreview coordinates={mapPreviewCoordiantes} exactSize={!!destinationPlace} path={path} />
        </Grid>
      </Grid>

      <AddNewLocationDialog
        onCancel={() => setIsAddLocationDialogOpen(false)}
        onConfirm={handleAddPlace}
        open={isAddLocationDialogOpen}
      />
    </>
  );
};

interface PlaceAutocompleteInputProps extends Pick<AutocompleteInputProps, "slotProps" | "textFieldProps"> {
  label?: string;
  loading?: boolean;
  onChange?: PlaceSelectProps["onChange"];
  options: AutocompleteOptions[];
  required?: boolean | string;
  value: string;
  dispatch: ReturnType<typeof usePlaceSelect>["dispatch"];
  onAddAction: () => void;
}

const PlaceAutocompleteInput: FC<PlaceAutocompleteInputProps> = ({
  label,
  loading,
  onChange: handleChange,
  options,
  required,
  value,
  dispatch,
  onAddAction,
  textFieldProps,
  ...props
}) => {
  const { t } = useTranslation();
  const currentLabel = find(propEq("value", value), options)?.label;
  const [inputVal, setInputVal] = useState<string>(currentLabel ?? "");
  useEffect(() => {
    if (currentLabel) {
      setInputVal(currentLabel);
    }
  }, [currentLabel]);

  return (
    <AutocompleteInput
      {...props}
      autoComplete
      dropdownWidth="input-width"
      filterOptions={identity}
      includeInputInList
      inputValue={inputVal ?? ""}
      label={label}
      loading={loading}
      onAddAction={onAddAction}
      onChange={handleChange}
      onInputChange={(event, newInputValue) => {
        // prevent backfire on select
        if (!event) return;

        setInputVal(newInputValue);
        debounce((queryValue) => {
          dispatch({ filter: { search: { LIKE: queryValue } } });
        }, 200)(newInputValue);
      }}
      options={options}
      placeholder={t("Location.enterLocationNameOrAddress")}
      required={!!required}
      slotProps={{ addOption: { label: t("Location.add"), "data-cy": "addNewLocation" } }}
      textFieldProps={{
        onFocus: () => dispatch({ filter: { search: { LIKE: value ?? "" } } }),
        inputProps: textFieldProps?.inputProps,
        InputProps: {
          startAdornment: (
            <InputAdornment position="start">
              <MdsIcon fontSize="xs" icon={MdsLocationOnXs} />
            </InputAdornment>
          ),
        },
      }}
      value={value}
    />
  );
};
