import { Skeleton, Stack } from "@mui/material";
import type { Coordinates } from "@sinch/types/common.types";

import { roundAt } from "@sinch/utils/math";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MapCoordinatesSelectInput } from "../../../form/MapCoordinatesSelectInput/MapCoordinatesSelectInput";
import { useMuiController } from "../../../form/MuiController/MuiController";
import { TextInput } from "../../../form/TextInput/TextInput";
import { mapSelectOptions } from "./defaults";
import { useLoadGoogleLibraries } from "./hooks";

export const MapCoordinatesSelect: FC<{
  mapCenterCoords?: Coordinates | undefined;
  onCoordinateInputChange: (coordinates: Coordinates) => void;
}> = ({ mapCenterCoords, onCoordinateInputChange }) => {
  const decimalPlaces = 6;
  const { t } = useTranslation();
  const {
    field: { value, onChange, ...field },
  } = useMuiController({
    name: "coordinates",
    defaultValue: null,
  });
  const { isLoaded } = useLoadGoogleLibraries();
  const handleChange = (coordinates: Coordinates) => {
    onChange({
      lat: roundAt(decimalPlaces)(coordinates.lat),
      lng: roundAt(decimalPlaces)(coordinates.lng),
    });
  };

  return (
    <Stack direction="column" spacing={3}>
      {isLoaded ? (
        <MapCoordinatesSelectInput
          {...field}
          helperText={t("Location.clickOnMapToSelect")}
          mapCenter={mapCenterCoords}
          onChange={handleChange}
          options={mapSelectOptions}
          required
          value={value}
        />
      ) : (
        <Skeleton height={500} variant="rectangular" width="100%"></Skeleton>
      )}
      <Stack direction="row" spacing={3}>
        <TextInput
          {...field}
          inputProps={{
            step: "any",
            min: -90,
            max: 90,
          }}
          label={t("Location.lat")}
          onChange={(event) => {
            // In some rare cases, target is not set.
            if (!event.target?.value) {
              return;
            }
            const newLatVal = roundAt(decimalPlaces)(parseFloat(event.target.value));
            onCoordinateInputChange({
              lng: parseFloat(value?.lng ?? 0),
              lat: newLatVal,
            });
            onChange({
              ...value,
              lat: newLatVal,
            });
          }}
          type="number"
          value={value?.lat ?? ""}
        />
        <TextInput
          {...field}
          inputProps={{
            step: "any",
            min: -180,
            max: 180,
          }}
          label={t("Location.lng")}
          onChange={(event) => {
            if (event.target?.value) {
              const newLngVal = roundAt(decimalPlaces)(parseFloat(event.target.value));
              onCoordinateInputChange({
                lng: newLngVal,
                lat: parseFloat(value?.lat ?? 0),
              });
              onChange({
                ...value,
                lng: newLngVal,
              });
            }
          }}
          type="number"
          value={value?.lng ?? ""}
        />
      </Stack>
    </Stack>
  );
};
