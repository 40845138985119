import { Chip, chipClasses, ChipProps, styled, Tooltip } from "@mui/material";
import { MdsCheckXs } from "@sinch/icons";
import { isNil, omit } from "ramda";
import React, { ReactElement } from "react";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import {
  BinaryInputControlWrapperProps,
  BinaryInputControlWrapper,
} from "../BinaryInputControlWrapper/BinaryInputControlWrapper";

const ChipCheckbox = styled(Chip)<ChipProps>(({ label }) => ({
  [`&.${chipClasses.root}`]: { borderRadius: "12px !important" },
  [`&.${chipClasses.root} .${chipClasses.label}`]: isNil(label)
    ? {
        paddingLeft: 0,
        paddingRight: 0,
      }
    : {},
}));

export interface ChipCheckboxInputProps
  extends Omit<ChipProps, "onChange" | "color" | "variant" | "clickable">,
    Pick<
      BinaryInputControlWrapperProps,
      "helperText" | "FormHelperTextProps" | "label" | "error" | "errorMessage" | "required" | "infoTooltip"
    > {
  /**
   * Value, same as checked
   */
  value?: boolean;
  onChange?: (value: boolean) => void;
}

/**
 * Looks like Chip, but it's checkbox! Basically checkbox.
 * See (https://mui.com/material-ui/api/chip/)[https://mui.com/material-ui/api/chip/]
 */
export function ChipCheckboxInput({
  helperText,
  FormHelperTextProps: formHelperTextProps,
  infoTooltip,
  label,
  error,
  errorMessage,
  required,
  value,
  onChange,
  onDelete,
  deleteIcon,
  ...props
}: ChipCheckboxInputProps): ReactElement {
  return (
    <BinaryInputControlWrapper error={error} errorMessage={errorMessage} required={required}>
      <Tooltip
        placement="bottom-end"
        PopperProps={{
          sx: {
            '&[data-popper-placement*="bottom"] .MuiTooltip-tooltip.MuiTooltip-tooltipPlacementBottom': {
              marginTop: 0.5,
            },
          },
        }}
        title={infoTooltip}
      >
        <ChipCheckbox
          {...(omit(["inputRef"], props) as ChipProps)}
          clickable
          deleteIcon={value ? <MdsIcon fontSize="xs" icon={MdsCheckXs} sx={{ width: 16, margin: 0 }} /> : undefined}
          label={label}
          onClick={() => onChange?.(!value)}
          onDelete={onDelete}
          sx={{ paddingX: value ? "4px" : "12px" }}
          variant={value ? "selected" : "filled"}
        />
      </Tooltip>
    </BinaryInputControlWrapper>
  );
}
