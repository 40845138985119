import { boolean, object, string } from "yup";

export const signInDialogFormSchema = ({
  includePaymentMethod,
  attendanceConfirmation,
}: {
  includePaymentMethod: boolean;
  attendanceConfirmation: boolean;
}) =>
  object({
    paymentMethodId: includePaymentMethod
      ? string().default(null).nonNullable()
      : string().default(null).optional().nullable(),
    joinLinked: boolean().default(false),
    requireConfirmation: boolean().default(attendanceConfirmation),
  });
