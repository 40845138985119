import { Button, Menu, MenuItem, useTheme } from "@mui/material";
import { MdsAdd, MdsExpandMore } from "@sinch/icons";
import React, { FC, useCallback } from "react";
import { UseFieldArrayAppend, useWatch } from "react-hook-form";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { Wage, WageForm, WageType } from "./types";
import { getWageLabel } from "./utils";

export interface AddWageButtonProps {
  fields: Wage[];
  prefix: string;
  onAdd: UseFieldArrayAppend<WageForm, string>;
}

export const AddWageButton: FC<AddWageButtonProps> = ({ onAdd, fields, prefix }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const lastWage = useWatch({ name: `${prefix}.${fields.length - 1}` });

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAdd = useCallback(
    (wageType: WageType) => {
      setAnchorEl(null);
      onAdd({
        type: wageType,
        duration: lastWage.duration + 1,
        value: Number(lastWage.value) + 10,
      });
    },
    [setAnchorEl, onAdd, lastWage]
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hasType = (type: WageType) => fields.some((obj) => obj.type === type);
  const isHourlyThresholdDisabled = (selectedWageType: WageType) =>
    selectedWageType === WageType.HOURLY && (hasType(WageType.HOURLY_THRESHOLD) || hasType(WageType.THRESHOLD_FIX));

  return (
    <>
      <Button
        endIcon={<MdsIcon icon={MdsExpandMore} sx={{ marginLeft: -0.5 }} />}
        fullWidth={false}
        onClick={openMenu}
        startIcon={<MdsIcon icon={MdsAdd} sx={{ marginRight: -0.5 }} />}
        sx={{
          paddingX: 0.875,
          paddingY: 0.875,
          minWidth: "unset",
          color: theme.palette.custom.blue["500"],
          borderColor: theme.palette.custom.blue["500"],
          ":hover": {
            color: theme.palette.custom.blue["600"],
            borderColor: theme.palette.custom.blue["600"],
          },
        }}
        tabIndex={-1}
        variant="outlined"
      />
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        {Object.values(WageType)
          .filter((selectedWageType) => selectedWageType !== WageType.FIXED)
          .map((wageType) => (
            <MenuItem key={wageType} disabled={isHourlyThresholdDisabled(wageType)} onClick={() => handleAdd(wageType)}>
              {getWageLabel(wageType)}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};
