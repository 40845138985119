import React, { FC } from "react";
import { UploadInputProps } from "../types";
import { UploadInput } from "../UploadInput";
import { DropZoneFileUploadContainer } from "./DropZoneFileUploadContainer";
import { PreviewFile } from "./PreviewFile";
import { SimpleUploadButton } from "./SimpleUploadButton";

/**
 * Dropzone image upload component
 */
export const DropZoneFileUpload: FC<Omit<UploadInputProps, "slots">> = (props) => (
  <UploadInput
    {...props}
    slots={{
      container: DropZoneFileUploadContainer,
      previewItem: PreviewFile,
      upload: SimpleUploadButton,
    }}
  />
);
