// eslint-disable-next-line import/no-internal-modules
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Tooltip } from "@mui/material";
import { TextFormatType } from "lexical";
import React, { FC, ReactElement } from "react";
import { ToggleToolbarButton } from "../components/ToolbarButton";
import { useFormatText } from "../hooks/useFormatText";

interface FormatTextToggleButtonProps {
  icon: ReactElement;
  textFormatType: TextFormatType;
  tooltip?: string;
}

export const FormatTextToggleButton: FC<FormatTextToggleButtonProps> = ({ icon, textFormatType, tooltip }) => {
  const [format, isFormatted] = useFormatText(textFormatType);
  const [editor] = useLexicalComposerContext();
  const disabled = !editor.isEditable();

  return (
    <Tooltip placement="top" title={tooltip}>
      <ToggleToolbarButton
        disabled={disabled}
        onClick={format}
        selected={isFormatted}
        tabIndex={-1}
        value={textFormatType}
      >
        {icon}
      </ToggleToolbarButton>
    </Tooltip>
  );
};
