import { useMutation } from "@apollo/client";
import { AttendanceStatusEnum } from "@sinch/core/entities/serverEnums";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutationResponseNotify } from "../../tools/useMutationResponseNotify";
import { useAttendanceUpdate } from "./AttendanceUpdateProvider";
import {
  ConfirmInvitation,
  ConfirmMessage,
  JoinWorker,
  KickWorker,
  RequireConfirmation,
  ResendInvitation,
} from "./queries.graphql";
import {
  type ConfirmInvitationMutation,
  type ConfirmInvitationMutationVariables,
  type ConfirmMessageMutation,
  type ConfirmMessageMutationVariables,
  JoinWorkerMutation,
  JoinWorkerMutationVariables,
  KickWorkerMutation,
  KickWorkerMutationVariables,
  RequireConfirmationMutation,
  RequireConfirmationMutationVariables,
  ResendInvitationMutation,
  ResendInvitationMutationVariables,
} from "./queries.types";

/**
 * Custom hook that provides methods for managing worker attendance
 *
 * This hook encapsulates functionality for:
 * - Joining workers to positions
 * - Removing workers from positions
 * - Managing attendance confirmation workflows
 * - Handling invitations and their confirmations
 */
export const useAttendance = () => {
  const trigger = useAttendanceUpdate();
  const { notifyResponse } = useMutationResponseNotify();
  const { t } = useTranslation();

  const [join] = useMutation<JoinWorkerMutation, JoinWorkerMutationVariables>(JoinWorker, {
    errorPolicy: "all",
  });
  const [kick] = useMutation<KickWorkerMutation, KickWorkerMutationVariables>(KickWorker, {
    errorPolicy: "all",
  });
  const [requireConfirmation] = useMutation<RequireConfirmationMutation, RequireConfirmationMutationVariables>(
    RequireConfirmation,
    {
      errorPolicy: "all",
    }
  );

  const [resendInvitation] = useMutation<ResendInvitationMutation, ResendInvitationMutationVariables>(
    ResendInvitation,
    {
      errorPolicy: "all",
    }
  );

  const [confirmInvitation] = useMutation<ConfirmInvitationMutation, ConfirmInvitationMutationVariables>(
    ConfirmInvitation,
    {
      errorPolicy: "all",
    }
  );

  const [confirmMessage] = useMutation<ConfirmMessageMutation, ConfirmMessageMutationVariables>(ConfirmMessage, {
    errorPolicy: "all",
  });

  /**
   * Assigns a worker to a position
   */
  const joinWorker = useCallback(
    async (variables: JoinWorkerMutationVariables) => {
      const response = await join({
        variables: variables,
      });
      if (response.data?.join.payload) {
        trigger?.(
          response.data?.join.payload.map((attendance) => ({
            positionId: attendance.position.id,
            shiftId: attendance.position.shift.id,
            workerId: attendance.worker.id,
            attendanceId: attendance.id,
          }))
        );
      }
      return response.data?.join ?? null;
    },
    [join]
  );

  /**
   * Changes the attendance status to require confirmation from a worker or remove the requirement
   */
  const requireConfirmationByWorker = useCallback(
    async ({
      attendanceId,
      attendanceConfirmation,
    }: Omit<RequireConfirmationMutationVariables, "status"> & { attendanceConfirmation: boolean }) => {
      const response = await requireConfirmation({
        variables: {
          attendanceId,
          status: attendanceConfirmation
            ? AttendanceStatusEnum.ConfirmationPending
            : AttendanceStatusEnum.AssignedByAdmin,
        },
      });
      if (response.data?.changeAttendanceStatus) {
        trigger?.([
          {
            attendanceId: attendanceId,
            shiftId: response.data?.changeAttendanceStatus?.payload?.position.shift.id,
            positionId: response.data?.changeAttendanceStatus?.payload?.position.id,
            workerId: response.data?.changeAttendanceStatus?.payload?.worker.id,
          },
        ]);
      }
      return response.data?.changeAttendanceStatus ?? null;
    },
    [requireConfirmation]
  );

  /**
   * Removes worker(s) from position(s)
   */
  const kickWorker = useCallback(
    async (attendanceIds: KickWorkerMutationVariables["attendanceIds"], notify?: boolean) => {
      const response = await kick({
        variables: { attendanceIds },
      });
      if (response.data?.kick?.payload) {
        trigger?.(
          response.data?.kick?.payload?.map((attendance) => ({
            shiftId: attendance.position.shift.id,
            positionId: attendance.position.id,
            workerId: attendance.worker.id,
          }))
        );
      }
      if (notify) {
        notifyResponse(response.data?.kick, t("Attendance.signedOut"));
      }
      return response.data?.kick ?? null;
    },
    [kick]
  );

  /**
   * Resends an invitation to a worker for position confirmation
   */
  const handleResendInvitation = useCallback(
    async (attendanceId: ResendInvitationMutationVariables["attendanceId"], notify?: boolean) => {
      const response = await resendInvitation({
        variables: { attendanceId },
      });
      if (notify) {
        notifyResponse(response.data?.resendInvitation, t("Attendance.invitationResent"));
      }
      return response.data?.resendInvitation ?? null;
    },
    [resendInvitation]
  );

  /**
   * Confirms an invitation
   */
  const handleConfirmInvitation = useCallback(
    async (attendanceId: ConfirmInvitationMutationVariables["attendanceId"], notify?: boolean) => {
      const response = await confirmInvitation({
        variables: { attendanceId },
      });
      if (response.data?.confirmInvitation?.payload) {
        trigger?.([
          {
            attendanceId: attendanceId,
            shiftId: response.data?.confirmInvitation?.payload?.position.shift.id,
            positionId: response.data?.confirmInvitation?.payload?.position.id,
            workerId: response.data?.confirmInvitation?.payload?.worker.id,
          },
        ]);
      }
      if (notify) {
        notifyResponse(response.data?.confirmInvitation, t("Attendance.invitationConfirmed"));
      }
      return response.data?.confirmInvitation ?? null;
    },
    [confirmInvitation]
  );

  /**
   * Marks a reconfirmation message as confirmed
   */
  const handleMessageConfirm = useCallback(
    async (attendanceId: ConfirmMessageMutationVariables["attendanceId"], notify?: boolean) => {
      const response = await confirmMessage({
        variables: { attendanceId },
      });
      if (response.data?.confirmReconfirmationMessage?.payload) {
        trigger?.([
          {
            attendanceId: attendanceId,
            shiftId: response.data?.confirmReconfirmationMessage?.payload?.position.shift.id,
            positionId: response.data?.confirmReconfirmationMessage?.payload?.position.id,
            workerId: response.data?.confirmReconfirmationMessage?.payload?.worker.id,
          },
        ]);
      }
      if (notify) {
        notifyResponse(response.data?.confirmReconfirmationMessage, t("Attendance.messageConfirmed"));
      }
      return response.data?.confirmReconfirmationMessage ?? null;
    },
    [confirmMessage]
  );

  return {
    joinWorker,
    kickWorker,
    requireConfirmationByWorker,
    resendInvitation: handleResendInvitation,
    confirmInvitation: handleConfirmInvitation,
    confirmMessage: handleMessageConfirm,
  };
};
