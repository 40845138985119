import { Grid, Typography, useTheme } from "@mui/material";
import React from "react";

interface FormSecondTitleProps {
  title: string;
}

export const FormSecondTitle: React.FC<FormSecondTitleProps> = ({ title }) => {
  const theme = useTheme();
  return (
    <Grid
      container
      direction="row"
      sx={{
        justifyContent: "stretch",
      }}
    >
      <Typography variant="h6">{title}</Typography>
      <Grid
        sx={{
          borderBottom: `1px solid ${theme.palette.grey["400"]}`,
          flexGrow: 1,
          marginLeft: 3,
        }}
      />
    </Grid>
  );
};
