import {
  complement,
  either,
  filter,
  find,
  isEmpty,
  isNil,
  length,
  map,
  path,
  pipe,
  pluck,
  propEq,
  propOr,
  sortBy,
  sum,
} from "ramda";
import { isTruthy } from "ramda-adjunct";

/**
 * Find and return object in array by his key
 * Example: `getBy('id', 1)([{id: 1, name: 'A'}, {id: 2, name: 'B'}])` return {id: 1, name: 'A'}
 */
export function getBy(field: string, val: string | number) {
  return find(propEq(field, val));
}

export function sumBy<P extends string>(field: P): <T>(list: readonly Record<P, T>[]) => number;
export function sumBy<P extends keyof T, T>(field: P, list: readonly T[]): number;
/**
 * Sum all fields in object array
 */
export function sumBy<P extends string, T extends readonly Record<P, T>[]>(field: P, list?: T) {
  const sumFn = pipe(
    pluck(field),
    map((item) => parseFloat(String(item ?? 0))),
    sum
  );

  if (!isNil(list)) {
    return sumFn(list);
  }
  return sumFn;
}

/**
 * Sort array by length of property
 */
export function sortByPropLength<P extends string>(propName: P): <T>(list: readonly T[]) => readonly T[] {
  return sortBy(pipe<any[], [], number>(propOr([], propName), length));
}

export const insertBetween = <T, S>(arr: T[], separator: S): (T | S)[] =>
  arr
    .slice()
    .flatMap((n) => [n, separator])
    .slice(0, -1) as (T | S)[];

/**
 * Return first nonNull nonEmpty value in array
 */
export const firstNonEmpty = find(complement(either(isEmpty, isNil)));

/**
 * Unfortunately NaN value cannot be added to excluded values because it doesn't
 * have its own specific type implemented in TypeScript.
 */
type WithoutFalsy<T> = Exclude<T, undefined | null | false | 0 | "">;
/**
 * Removes falsy values from array or object.
 */
interface RejectFalsy {
  <T>(list: readonly T[]): WithoutFalsy<T>[];

  <T, K extends string | number>(record: Partial<Record<K, T>>): Partial<Record<K, WithoutFalsy<T>>>;
}

export const rejectFalsy: RejectFalsy = filter(isTruthy) as RejectFalsy;

export const sortByPath = pipe(path, sortBy);

/**
 * Return items in offset around item in array (item is found by indexOf method)
 */
export const getOffsetAroundItem = <T,>(item: T, arr: T[], downOffset: number, upOffset?: number) =>
  arr.slice(Math.max(0, arr.indexOf(item) - downOffset), arr.indexOf(item) + (upOffset ?? downOffset));
