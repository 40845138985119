import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogCloseIcon } from "../DialogCloseIcon/DialogCloseIcon";

export interface ConfrirmDialogRenderProps {
  openConfirmation: () => void;
}
export interface ConfirmDialogWrapperProps {
  label: ReactNode;
  content: ReactNode;
  render: ({ openConfirmation }: ConfrirmDialogRenderProps) => React.ReactNode;
  onConfirm?: () => void;
  onClose?: () => void;
}

/**
 * Component allow wrap any component with confirm dialog, render prop provide open function for open dialog, events then are used for handle confirm and close dialog
 */
export const ConfirmDialogWrapper: FC<ConfirmDialogWrapperProps> = ({ render, onConfirm, onClose, label, content }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirm?.();
  };

  return (
    <>
      {render({
        openConfirmation: () => setOpen(true),
      })}
      <Dialog maxWidth="lg" onClose={handleClose} open={open}>
        <DialogTitle>
          {label}
          <DialogCloseIcon onClick={handleClose} />
        </DialogTitle>
        <DialogContent>
          <Typography>{content}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} size="large">
            {t("cancel")}
          </Button>
          <Button onClick={handleConfirm} size="large" variant="contained">
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
