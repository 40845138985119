import { Tooltip } from "@mui/material";
import { MdsNewReleases, SinchNonVerified } from "@sinch/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdsIconProps, MdsIcon } from "../../MdsIcon/MdsIcon";

interface AttendanceStatusIconProps extends Omit<MdsIconProps, "icon"> {
  verified?: boolean;
}

/**
 * Indication if user is verified
 */
export const ProfileVerificationIcon: React.FC<AttendanceStatusIconProps> = ({ verified, ...props }) => {
  const { t } = useTranslation();

  return (
    <Tooltip arrow enterTouchDelay={0} placement="top" title={verified ? t("verified") : t("unverified")}>
      <MdsIcon
        fontSize="small"
        icon={verified ? MdsNewReleases : SinchNonVerified}
        sx={{ color: ({ palette }) => palette.custom.blue["900"] }}
        {...props}
      />
    </Tooltip>
  );
};
