import { UploadyProps } from "@rpldy/shared-ui";
import { UploadButtonProps } from "@rpldy/upload-button";
import { UploadOptions, useFileInput, useUploadyContext } from "@rpldy/uploady";
import React, { ElementType, FC, ReactNode, useCallback, useRef } from "react";

export interface UploadButtonWrapperProps
  extends Omit<UploadButtonProps, "children">,
    Pick<UploadyProps, "multiple" | "capture" | "accept"> {
  uploadComponent: ElementType<UploadButtonProps>;
  disabled?: boolean;
  uploadComponentProps?: UploadButtonProps;
  children?: UploadButtonProps["children"] | ReactNode;
}

/**
 * Wrapper modify file upload input so it can accept multiple files and accept specific file types
 */
export const UploadButtonWrapper: FC<UploadButtonWrapperProps> = ({
  uploadComponent: UploadComponent,
  multiple,
  accept,
  capture,
  disabled,
  uploadComponentProps,
  ...props
}) => {
  const { showFileUpload } = useUploadyContext();
  const inputRef = useFileInput();
  const uploadOptionsRef = useRef<UploadOptions>();

  uploadOptionsRef.current = props;
  const onButtonClick = useCallback(() => {
    if (disabled) {
      return;
    }
    if (inputRef.current) {
      if (multiple) {
        inputRef.current.setAttribute("multiple", "true");
      } else {
        inputRef.current.removeAttribute("multiple");
      }
      if (accept) {
        inputRef.current.setAttribute("accept", accept);
      } else {
        inputRef.current.removeAttribute("accept");
      }
      if (capture) {
        inputRef.current.setAttribute("capture", capture);
      } else {
        inputRef.current.removeAttribute("capture");
      }
    }
    showFileUpload(uploadOptionsRef.current);
  }, [disabled, accept, capture, inputRef, multiple, showFileUpload]);

  return (
    // @ts-ignore too complex type of uploady component
    <UploadComponent
      {...props}
      {...uploadComponentProps}
      extraProps={{
        ...props.extraProps,
        onClick: onButtonClick,
      }}
    />
  );
};
