import { default as i18next, Module } from "i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import { initReactI18next } from "react-i18next";
import { setLocale } from "yup";
import { constants } from "./globals";
import { ReactElementPostprocessor } from "./reactPostprocessor/postprocessor";

/**
 * Initialize translate service and load default dictionary
 */
export function initIntl(dictionary: Record<string, string>, lng?: string): void {
  const { defaultLng } = constants;
  i18next
    .use(initReactI18next)
    // load languages dynamically
    .use(resourcesToBackend((language: string) => import(`../../locale/${language}/common.json`)))
    // Allow inject react elements into the translates
    .use(
      new ReactElementPostprocessor({
        keepUnknownVariables: true,
        prefix: "{{",
        suffix: "}}",
      }) as Module
    )
    .init(
      {
        lng: lng || defaultLng,

        fallbackLng: [lng?.split(/[-_]/)?.[0] ?? defaultLng, defaultLng],

        ns: ["common"],

        nsSeparator: ":",

        // for flat format
        keySeparator: false,

        defaultNS: "common",

        resources: {
          common: { dictionary },
        },

        partialBundledLanguages: true,

        interpolation: {
          // not needed for react as it escapes by default
          escapeValue: false,
          prefix: "<<",
          suffix: ">>",
        },

        postProcess: ["reactPostprocessor"],

        appendNamespaceToCIMode: false,

        returnNull: false,
      },
      () => {}
    );
}

/**
 * Set locales for yup validation
 */
setLocale({
  mixed: {
    required: () => i18next.t("required"),
    notType: () => i18next.t("invalidType"),
    notNull: () => i18next.t("required"),
  },
  string: {
    max: ({ max }) => i18next.t("inputMax", { max }),
  },
  array: {
    min: ({ min }) => i18next.t("arrayMin", { min }),
  },
});
