import { TableCell, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { AttendanceTableRow } from "./AttendanceRow";

export const EmptyApplicantRow = () => {
  const { t } = useTranslation();
  return (
    <AttendanceTableRow>
      <TableCell
        colSpan={6}
        size="small"
        sx={{
          minHeight: "35px",
        }}
      >
        <Typography
          sx={[
            ({ palette: { error } }) => ({
              color: error.main,
              px: 2,
            }),
            ({ palette: { grey } }) => ({ color: grey[500] }),
          ]}
          variant="descriptionSmallSemi"
        >
          {t("Shift.emptyApplicants")}
        </Typography>
      </TableCell>
    </AttendanceTableRow>
  );
};
