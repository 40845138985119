import { Box, BoxProps, styled } from "@mui/material";

export const TimeLine = styled(Box)<BoxProps & { length: number; fixed: boolean }>(({ theme, length, fixed }) => ({
  backgroundColor: theme.palette.grey[400],
  height: "2px",
  marginLeft: `${fixed ? 0 : 160 + 32}px`,
  width: `${fixed ? length * (160 + 32) - 32 : (length - 1) * (160 + 32) - 32}px`,
}));

export const ActiveTimeLine = styled(Box)<BoxProps & { length: number; fixed: boolean }>(
  ({ theme, length, fixed }) => ({
    position: "absolute",
    top: "0",
    left: `${fixed ? 0 : 160 + 32}px`,
    width: fixed ? `${length ?? 0 + 3}px` : `${length ? length - (160 + 32) + 3 : 0}px`,
    height: "2px",
    backgroundColor: theme.palette.secondary.dark,
    transition: "0.5s ease",
  })
);
