import { setRef, styled, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { equals, isNil, reject } from "ramda";
import React, { ReactElement, Ref, useEffect, useRef, useState } from "react";
import { DataSet } from "vis-data";
// eslint-disable-next-line import/no-internal-modules
import { UpdateEventPayload } from "vis-data/declarations/data-interface";
import { useDropPlaceholder, useRegisterEvents, useTemplates, useUpdateTimelineOptions } from "./hooks";
import { Timeline as VisTimeline } from "./lib/Timeline";
import {
  DataGroup,
  DataGroupCollectionType,
  DataItem,
  DataItemCollectionType,
  TimelineEventsCallbacks,
  TimelineOptions,
  TimelineTemplateRender,
} from "./types";

/**
 * Options for vis-timline component (https://visjs.github.io/vis-timeline/docs/timeline/#Configuration_Options)
 * Please be aware that any change of props rerenders whole timeline, use memoization to prevent unnecessary rerenders
 */
export interface TimelineProps<TDataItem extends DataItem = DataItem, TDataGroup extends DataGroup = DataGroup>
  extends TimelineOptions,
    TimelineTemplateRender<TDataItem, TDataGroup>,
    Partial<Pick<HTMLDivElement, "className">>,
    Partial<TimelineEventsCallbacks> {
  /**
   * Call after creating an instance of timeline
   * @param timeline
   */
  onInit?: (timeline: VisTimeline) => void;
  /**
   * Items for timeline (content of timeline)
   */
  items: DataItemCollectionType;
  /**
   * Groups for timeline (vertical axis of timeline)
   */
  groups?: DataGroupCollectionType;
  /**
   * Ref to vis-timeline instance
   */
  timelineRef?: Ref<VisTimeline | undefined>;
  /**
   * Allow to drop anything into timeline
   */
  allowDropAny?: boolean;
  sx?: SxProps<Theme>;
}

export const TimelineStatic = <TDataItem extends DataItem = DataItem, TDataGroup extends DataGroup = DataGroup>({
  className,
  items,
  groups,
  renderItem,
  renderMinorAxis,
  renderMajorAxis,
  renderGroup,
  renderFilterArea,
  renderDropTarget,
  timelineRef,
  allowDropAny,
  onInit,
  ...props
}: TimelineProps<TDataItem, TDataGroup>): ReactElement => {
  const [currentTimeline, setCurrentTimeline] = useState<VisTimeline | null>(null);
  const timeline = useRef<VisTimeline | null>(null);

  const { clearRenderCache, ...renderers } = useTemplates<TDataItem, TDataGroup>(
    {
      renderItem,
      renderMinorAxis,
      renderMajorAxis,
      renderGroup,
      renderFilterArea,
      renderDropTarget,
    },
    timeline
  );
  const timelineOptions = useRegisterEvents(reject(isNil, props), currentTimeline);

  useDropPlaceholder(timeline, allowDropAny, [items]);

  const { setIsInitialized } = useUpdateTimelineOptions({ ...timelineOptions, ...renderers }, timeline);

  useEffect(() => {
    if (timeline.current) {
      // We need to wait for the next tick to set items, it detach rendering items from lifecycle
      // rendering phase so it will not thrwo warning for flushSync
      setTimeout(() => {
        timeline.current?.setItems(items);
      }, 0);
    }
  }, [items, currentTimeline]);

  useEffect(() => {
    if (timeline.current) {
      // We need to wait for the next tick to set groups, it detach rendering items from lifecycle
      // rendering phase so it will not thrwo warning for flushSync
      setTimeout(() => {
        timeline.current?.setGroups(groups);
      }, 0);
    }
  }, [groups, currentTimeline]);

  return (
    <TimelineContainer
      ref={(container) => {
        if (container === null || timeline.current) {
          setRef(timelineRef, timeline.current);
          return;
        }

        // We need to wait for the next tick to initialize the timeline, it detach rendering items from lifecycle
        // rendering phase so it will not thrwo warning for flushSync
        setTimeout(() => {
          if (timeline.current) {
            return;
          }
          // Configuration for the Timeline
          const options: TimelineOptions = {
            ...renderers,
            ...timelineOptions,
            onInitialDrawComplete: () => setIsInitialized(true),
          };

          // Create a Timeline
          timeline.current = new VisTimeline(container, items, options);
          timeline.current.setGroups(groups);

          if (items instanceof DataSet) {
            items.on("update", (_, { data, oldData }: UpdateEventPayload<TDataItem, "id">) => {
              if (!equals(data, oldData)) {
                clearRenderCache?.();
                timeline.current?.redraw();
              }
            });
          }

          setRef(timelineRef, timeline.current);
          setCurrentTimeline(timeline.current);
          onInit?.(timeline.current);
        }, 0);
      }}
      className={className}
      sx={props.sx}
    />
  );
};

export const Timeline = React.memo(TimelineStatic) as typeof TimelineStatic;

const TimelineContainer = styled("div")(() => ({}));
