import { add, differenceIn } from "@sinch/utils/dateTime/dateFns";

import { useLayoutEffect, useState } from "react";
import { FieldPath, FieldPathValue, FieldValues, useFormContext, useWatch } from "react-hook-form";

export function useDateRangeSync<
  TFieldValues extends FieldValues = FieldValues,
  TBeginName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TEndName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  TValue extends FieldPathValue<TFieldValues, TEndName> = FieldPathValue<TFieldValues, TEndName>,
>(
  startInputName: TBeginName,
  endInputName: TEndName,
  onEndChange?: (val: Date | null) => void,
  options: { syncOnlyOver?: boolean } = { syncOnlyOver: false }
) {
  const [lastVal, setLastVal] = useState<Date | null>(null);
  const { getValues, setValue, trigger } = useFormContext<TFieldValues>();
  const beginningTime = useWatch<TFieldValues>({ name: startInputName });
  const endTime = getValues(endInputName);
  useLayoutEffect(() => {
    if (beginningTime && endTime && lastVal) {
      const diff = differenceIn("seconds")(beginningTime, lastVal);
      const endValue = add(endTime, { seconds: diff }) as TValue;
      if (!options?.syncOnlyOver || beginningTime.getTime() >= endTime.getTime()) {
        setValue(endInputName, endValue);
        onEndChange?.(endValue);
        trigger(endInputName);
      }
    }
    setLastVal(beginningTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beginningTime]);
}
