// eslint-disable-next-line import/no-internal-modules
import type { ServerError } from "@apollo/client";
import {
  ApolloClient,
  ApolloProvider,
  Context,
  createHttpLink,
  from,
  InMemoryCache,
  ServerParseError,
} from "@apollo/client";
// eslint-disable-next-line import/no-internal-modules
import { createFragmentRegistry } from "@apollo/client/cache"; // eslint-disable-next-line import/no-internal-modules
import type { GraphQLErrors } from "@apollo/client/errors"; // eslint-disable-next-line import/no-internal-modules
import { onError } from "@apollo/client/link/error"; // eslint-disable-next-line import/no-internal-modules
import { createPersistedQueryLink } from "@apollo/client/link/persisted-queries";
import { generatePersistedQueryIdsFromManifest } from "@apollo/persisted-query-lists";
import * as Sentry from "@sentry/react";
import { withScalars } from "apollo-link-scalars";
import { buildClientSchema, OperationDefinitionNode, stripIgnoredCharacters } from "graphql";
import { prop } from "ramda";
import { isNotNil, isNotNilOrEmpty } from "ramda-adjunct";
import React, { FC, PropsWithChildren, useMemo } from "react";
import { persistedQueryManifest, schema } from "../entities"; // eslint-disable-next-line import/no-internal-modules
import fragments from "../entities/fragments.graphql";
import { API_ROOT } from "../env";
import { removeTypenameFromMutationLink } from "../tools/apolloMiddleware/removeTypenameFromMutation";
import { typeMap } from "../tools/apolloMiddleware/scalarTypesParser";
import { useSettings } from "./AppSettings";

const schemaDef = buildClientSchema(schema);

export const useSinchApolloClient = () => {
  const { timeZone } = useSettings();

  const httpLink = createHttpLink({
    uri: API_ROOT,
    print: (ast, originalPrint) =>
      import.meta.env.MODE !== "production" ? originalPrint(ast) : stripIgnoredCharacters(originalPrint(ast)),
  });

  const persistedQueryLink = createPersistedQueryLink(
    generatePersistedQueryIdsFromManifest({
      loadManifest: () => persistedQueryManifest,
    })
  );

  const link = from([
    withScalars({
      schema: schemaDef,
      typesMap: typeMap(timeZone),
    }),
    removeTypenameFromMutationLink,
    errorLink,
    persistedQueryLink,
    httpLink,
  ]);

  return useMemo(
    () =>
      new ApolloClient({
        cache: new InMemoryCache({
          fragments: createFragmentRegistry(fragments),
        }),
        link: link,
        devtools: {
          enabled: import.meta.env.MODE !== "production",
        },
      }),
    [timeZone]
  );
};

/**
 * Provide apollo client context
 */
export const ApolloClientProvider: FC<PropsWithChildren> = ({ children }) => {
  const client = useSinchApolloClient();
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

interface ErrorsResponse {
  errors?: GraphQLErrors;
}

interface ErrorExtensions {
  extensions: { redirectUri: string } | Record<string, unknown>;
}

interface GraphqlErrorContext extends Context {
  httpCode: number | undefined;
  errorMessage: string | undefined;
  operationName: string;
  graphqlErrors: string | null;
  queries: { type: string; query: string | undefined }[];
}

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  const graphqlContext: GraphqlErrorContext = {
    httpCode: (networkError as ServerParseError | undefined)?.statusCode,
    errorMessage: networkError?.name,
    operationName: operation.operationName,
    graphqlErrors: isNotNilOrEmpty(graphQLErrors) ? JSON.stringify(graphQLErrors) : null,
    queries: operation.query.definitions.map((def) => ({
      type: (def as OperationDefinitionNode).operation,
      query: (def as OperationDefinitionNode).name?.value,
    })),
  };

  // send network error to sentry
  if (networkError) {
    networkErrorHandler(networkError as ServerParseError | ServerError, graphqlContext);
  }
  // send graphql error to sentry
  if (graphQLErrors) {
    Sentry.captureMessage(`Graphql error`, (scope) => {
      scope.setTransactionName(`${operation.operationName}`);
      scope.setLevel("error");
      scope.setContext("Graphql", graphqlContext);
      return scope;
    });
  }
});

/**
 * Handles network errors by dispatching them to specific error handlers based on their status code.
 *
 * This function checks the status code of a network error and directs it to the appropriate handler.
 * - Handles 401 Unauthorized error to manage authentication-related issues.
 * - For all other errors, it manage general network errors and send event to Sentry.
 *
 */
const networkErrorHandler = (networkError: ServerParseError | ServerError, context: GraphqlErrorContext) => {
  switch (networkError.statusCode) {
    case 401:
      handleUnauthenticatedError(networkError);
      break;
    default:
      handleUnspecifiedError(networkError, context);
  }
};

/**
 * Handles 401 Unauthorized error by redirecting the user to the login page.
 */
const handleUnauthenticatedError = (serverError: ServerParseError | ServerError) => {
  const responseErrors = prop<ErrorsResponse>("result", serverError)?.errors;
  const redirectError = responseErrors?.find(({ extensions }: ErrorExtensions) =>
    isNotNil(prop("redirectUri", extensions))
  )?.extensions.redirectUri as string | null;
  window.location.href = redirectError || window.location.href;
};

/**
 * Handles unspecified errors by sending them to Sentry.
 */
const handleUnspecifiedError = (networkError: ServerParseError | ServerError, context: GraphqlErrorContext) => {
  const serverError = networkError as ServerParseError;
  Sentry.captureException(networkError, (scope) => {
    scope.setContext("response", {
      type: serverError.name ?? null,
      statusCode: serverError.statusCode ?? null,
      body: serverError.bodyText ?? null,
    });
    scope.setContext("Graphql", context);
    return scope;
  });
};
