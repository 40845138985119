export enum SchemaType {
  /**
   * Section of the input
   */
  SECTION = "section",
  /**
   * Delimiter of the input
   */
  DELIMITER = "delimiter",
}

/**
 * Delimiter type schema
 */
export interface DelimiterSchema {
  type: SchemaType.DELIMITER;
  /**
   * Delimiter string
   */
  title: string;
}

/**
 * Section type schema, represent input
 */
export interface SectionSchema {
  type: SchemaType.SECTION;
  /**
   * placeholder for input (title of input)
   */
  title: string;
  /**
   * mask for input, see imask lib
   */
  mask: string | null;
  /**
   * size of input in characters
   */
  size: number;
  /**
   * name of input,
   */
  name: string;
  /**
   * is input flexible, have not maximum width
   */
  flexible?: boolean;
}

export type BankNumberInputValue = Record<string, string>;
