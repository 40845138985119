import { Link } from "@mui/material";

import { useFormat } from "@sinch/core/hooks/format";
import React, { FC } from "react";

export interface PhoneLinkProps {
  phone: string;
}

export const PhoneLink: FC<PhoneLinkProps> = ({ phone }) => {
  const {
    phone: { uri, national },
  } = useFormat();
  return <Link href={`tel:${uri(phone)}`}>{national(phone)}</Link>;
};
