export { API_ROOT, ERROR_MONITOR_API_KEY, ERROR_MONITOR_DEBUG, ERROR_MONITOR_ENV, GOOGLE_MAPS_API_KEY } from "./env";

declare global {
  interface Window {
    SinchApp: Record<string, any>;
  }
}

window.SinchApp = window.SinchApp || {}; // Global namespace for communication with scripts outside of react

import "./init";
