import { DelimiterSchema, SchemaType, SectionSchema } from "./types";

/**
 * Type guard for delimiter schema
 */
export function isDelimiter(item: DelimiterSchema | SectionSchema): item is DelimiterSchema {
  return item.type === SchemaType.DELIMITER;
}

/**
 * Type guard for section schema
 */
export function isSection(item: DelimiterSchema | SectionSchema): item is SectionSchema {
  return item.type === SchemaType.SECTION;
}
