import type { Worker } from "@sinch/types/sinch.types";

import { join } from "@sinch/utils/string";
import { isNil } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import { useTranslation } from "react-i18next";

/**
 * Provide methods for render worker information
 */
export const useWorker = () => {
  const { t } = useTranslation();
  const getWorkerName = (worker: Pick<Worker, "name" | "surname">) => {
    if (isNil(worker.name) && isNil(worker.surname)) {
      return t("Worker.unknownWorker");
    }
    return join([worker.name, worker.surname].filter(isNotNilOrEmpty), " ");
  };

  return {
    getWorkerName,
  };
};
