import { Divider, Link, Stack, Typography } from "@mui/material";
import { AttributeTypeEnum } from "@sinch/core/entities/serverEnums";
import { useFormat } from "@sinch/core/hooks/format";
import { AttributeTypeEnum as TAttributeTypeEnum } from "@sinch/types/sinch.types";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { GalleryAttribute } from "./GalleryAttribute";
import { IdNamePair } from "./types";
import { findNameById, idToName } from "./utils";

interface LabelValueProps {
  label: string;
  value: any;
  type?: TAttributeTypeEnum | undefined;
  files?: { name: string; url: string }[];
  options?: IdNamePair[];
}

export const LabelValue: FC<LabelValueProps> = ({ label, value, type, files, options }) => {
  const { t } = useTranslation();
  const { date } = useFormat();
  switch (type) {
    case AttributeTypeEnum.Bool:
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          <Typography variant="descriptionSmall">{value ? t("yes") : t("no")}</Typography>
        </>
      );
    case AttributeTypeEnum.Choice:
      if (!options) {
        return null;
      }
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          <Typography variant="descriptionSmall">{findNameById(value, options)}</Typography>
        </>
      );
    case AttributeTypeEnum.Multiplechoice:
      if (!options) {
        return null;
      }
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          <Typography variant="descriptionSmall">{idToName(value, options).join(", ")}</Typography>
        </>
      );
    case AttributeTypeEnum.Range:
      if (!options) {
        return null;
      }
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          <Typography variant="descriptionSmall">{idToName(value, options).join(", ")}</Typography>
        </>
      );
    case AttributeTypeEnum.Date:
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          <Typography variant="descriptionSmall">{date.medium(value as string)}</Typography>
        </>
      );
    case AttributeTypeEnum.Text:
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          <Typography variant="descriptionSmall">{value}</Typography>
        </>
      );
    case AttributeTypeEnum.Int:
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          <Typography variant="descriptionSmall">{value}</Typography>
        </>
      );
    case AttributeTypeEnum.Textarea:
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          <Typography variant="descriptionSmall">{value}</Typography>
        </>
      );
    case AttributeTypeEnum.File:
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          {files?.map((file) => (
            <Stack key={file.url} direction="row" spacing={1}>
              <Link href={file.url} target="_blank">
                {t("Action.view")}
              </Link>
              <Divider flexItem orientation="vertical" />
              <Link download={file.name} href={file.url}>
                {t("Action.download")}
              </Link>
            </Stack>
          ))}
        </>
      );
    case AttributeTypeEnum.Image:
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          {files?.map((file) => (
            <Stack key={file.url} direction="row" spacing={1}>
              <Link href={file.url} target="_blank">
                <img alt={file.name} height={64} src={file.url} width={64} />
              </Link>
            </Stack>
          ))}
        </>
      );
    case AttributeTypeEnum.Gallery:
      return <GalleryAttribute files={files} label={label} />;
    default:
      return (
        <>
          <Typography
            sx={{
              pt: 1,
            }}
            variant="descriptionTinyBold"
          >
            {label}
          </Typography>
          <Typography variant="descriptionSmall">{value}</Typography>
        </>
      );
  }
};
