// @ts-ignore no types definition
import { emitter } from "contra/dist/contra";
import * as classes from "./classes";

interface CanStartElements {
  item: HTMLElement;
  source: HTMLElement;
}

export interface DragulaOptions {
  containers?: HTMLElement[] | undefined;
  isContainer?: ((el?: HTMLElement) => boolean) | undefined;
  moves?:
    | ((el?: HTMLElement, container?: HTMLElement, handle?: HTMLElement, sibling?: HTMLElement) => boolean)
    | undefined;
  accepts?:
    | ((el?: HTMLElement, target?: HTMLElement, source?: HTMLElement, sibling?: HTMLElement) => boolean)
    | undefined;
  invalid?: ((el?: HTMLElement, target?: HTMLElement) => boolean) | undefined;
  direction?: string | undefined;
  copy?: ((el: HTMLElement, source: HTMLElement) => boolean) | boolean | undefined;
  copySortSource?: boolean | undefined;
  revertOnSpill?: boolean | undefined;
  removeOnSpill?: boolean | undefined;
  delay?: number | undefined;
  mirrorContainer?: HTMLElement | undefined;
  ignoreInputTextSelection?: boolean | undefined;
  slideFactorX?: number;
  slideFactorY?: number;
  /**
   * If true, dragging start with move event
   */
  startWithMove?: boolean;
}

export interface Drake {
  containers: Element[];
  dragging: boolean;
  start(item: Element): void;
  end(): void;
  cancel(revert?: boolean): void;
  canMove(item: Element): boolean;
  remove(): void;
  on(event: "drag", listener: (el: Element, source: Element) => void): Drake;
  on(event: "dragend", listener: (el: Element) => void): Drake;
  on(event: "drop", listener: (el: Element, target: Element, source: Element, sibling: Element) => void): Drake;
  on(
    event: "cancel" | "remove" | "shadow" | "over" | "out",
    listener: (el: Element, container: Element, source: Element) => void
  ): Drake;
  on(event: "cloned", listener: (clone: Element, original: Element, type: "mirror" | "copy") => void): Drake;
  destroy(): void;
}

const doc = document;
const documentElement = doc.documentElement;

function isTouch(e: TouchEvent | MouseEvent): e is TouchEvent {
  return typeof (e as TouchEvent).touches !== "undefined";
}

function isMouse(e: TouchEvent | MouseEvent): e is MouseEvent {
  return typeof (e as MouseEvent).buttons !== "undefined";
}

interface Dragula {
  (containers: HTMLElement[], options?: DragulaOptions): Drake;
  (options?: DragulaOptions): Drake;
}

export const dragula: Dragula = (initialContainers?: HTMLElement[] | DragulaOptions, options?: DragulaOptions) => {
  if (!options && Array.isArray(initialContainers) === false) {
    options = initialContainers as DragulaOptions;
    initialContainers = [];
  }
  let mirrorEl: HTMLElement | null; // mirror image
  let sourceContainer: HTMLElement | null; // source container
  let draggedItem: HTMLElement | null; // item being dragged
  let refOffsetX: number; // reference x
  let refOffsetY: number; // reference y
  let refMoveX: number; // reference move x
  let refMoveY: number; // reference move y
  let initialSibling: HTMLElement | null; // reference sibling when grabbed
  let currentSibling: HTMLElement | null; // reference sibling now
  let copyElement: HTMLElement | null; // item used for copying
  let renderTimer: string | number | NodeJS.Timeout | undefined | null; // timer for setTimeout renderMirrorImage
  let lastDropTarget: HTMLElement | null = null; // last container item was over
  let grabbedContext: boolean | ReturnType<typeof canStart>; // holds mousedown context until first mousemove

  const defaults: Required<DragulaOptions> = {
    moves: always,
    accepts: always,
    invalid: invalidTarget,
    containers: (initialContainers as HTMLElement[]) || [],
    isContainer: never,
    copy: false,
    copySortSource: false,
    revertOnSpill: false,
    removeOnSpill: false,
    direction: "vertical",
    ignoreInputTextSelection: true,
    mirrorContainer: doc.body,
    slideFactorX: 0,
    slideFactorY: 0,
    delay: 0,
    startWithMove: true,
  };
  const o: Required<DragulaOptions> = { ...defaults, ...options };

  const drake = emitter({
    containers: o.containers,
    start: manualStart,
    end: end,
    cancel: cancel,
    remove: remove,
    destroy: destroy,
    canMove: canMove,
    dragging: false,
  });

  if (o.removeOnSpill === true) {
    drake.on("over", spillOver).on("out", spillOut);
  }

  events();

  return drake;

  function isContainer(el: HTMLElement) {
    return drake.containers.indexOf(el) !== -1 || o.isContainer(el);
  }

  function cancelDelay() {
    if (!drake.dragging) {
      if (renderTimer) {
        clearTimeout(renderTimer);
        touchy(documentElement, "remove", "mousemove", cancelDelay);
      }
    }
  }

  function delayedGrab(e: PointerEvent) {
    const ignore = whichMouseButton(e) !== 1 || e.metaKey || e.ctrlKey;
    if (ignore) {
      return; // we only care about honest-to-god left clicks and touch events
    }
    const item = e.target as HTMLElement;
    if (!canStart(item)) {
      return;
    }
    renderTimer = setTimeout(() => {
      touchy(documentElement, "remove", "mousemove", cancelDelay);
      grab(e);
    }, o.delay);
    touchy(documentElement, "add", "mousemove", cancelDelay);
  }

  function events(removeEvent?: boolean) {
    const op = removeEvent ? "remove" : "add";
    touchy(documentElement, op, "mousedown", grab, true);
    touchy(documentElement, op, "touchstart", delayedGrab, true);
    touchy(documentElement, op, "mouseup", release);
  }

  function eventualMovements(removeEvent?: boolean) {
    const op = removeEvent ? "remove" : "add";
    touchy(documentElement, op, "mousemove", startBecauseMouseMoved);
  }

  function movements(removeEvent?: boolean) {
    const op = removeEvent ? "remove" : "add";
    if (op === "add") {
      document.addEventListener("selectstart", preventGrabbed);
      document.addEventListener("click", preventGrabbed);
    } else if (op === "remove") {
      document.removeEventListener("selectstart", preventGrabbed);
      document.removeEventListener("click", preventGrabbed);
    }
  }

  function destroy() {
    events(true);
    release();
  }

  function preventGrabbed(e: Event) {
    if (grabbedContext) {
      e.preventDefault();
    }
  }

  function grab(e: PointerEvent) {
    refMoveX = e.clientX;
    refMoveY = e.clientY;

    const ignore = whichMouseButton(e) !== 1 || e.metaKey || e.ctrlKey;
    if (ignore) {
      return; // we only care about honest-to-god left clicks and touch events
    }
    const item = e.target as HTMLElement;
    const context = canStart(item);
    if (!context) {
      return;
    }
    grabbedContext = context;
    if (o.startWithMove) {
      eventualMovements();
    } else {
      startBecauseMouseMoved(e);
    }
    if (e.type === "mousedown") {
      if (isInput(item)) {
        // see also: https://github.com/bevacqua/dragula/issues/208
        item.focus(); // fixes https://github.com/bevacqua/dragula/issues/176
      } else {
        e.preventDefault(); // fixes https://github.com/bevacqua/dragula/issues/155
      }
    }
  }

  function startBecauseMouseMoved(e: PointerEvent) {
    if (!grabbedContext) {
      return;
    }
    if (whichMouseButton(e) === 0) {
      release();
      return; // when text is selected on an input and then dragged, mouseup doesn't fire. this is our only hope
    }

    // truthy check fixes #239, equality fixes #207, fixes #501
    if (
      o.startWithMove &&
      e.clientX !== void 0 &&
      Math.abs(e.clientX - refMoveX) <= (o.slideFactorX || 0) &&
      e.clientY !== void 0 &&
      Math.abs(e.clientY - refMoveY) <= (o.slideFactorY || 0)
    ) {
      return;
    }

    if (o.ignoreInputTextSelection) {
      const clientX = getCoord("clientX", e) || 0;
      const clientY = getCoord("clientY", e) || 0;
      const elementBehindCursor = doc.elementFromPoint(clientX, clientY) as HTMLElement | null;
      if (!elementBehindCursor || isInput(elementBehindCursor)) {
        return;
      }
    }

    const grabbed = grabbedContext as CanStartElements; // call to end() unsets _grabbed
    eventualMovements(true);
    movements();
    end();
    start(grabbed);

    const offset = getOffset(draggedItem as HTMLElement);
    refOffsetX = getCoord("pageX", e) - offset.left;
    refOffsetY = getCoord("pageY", e) - offset.top;

    classes.add(copyElement || (draggedItem as HTMLElement), "gu-transit");
    renderMirrorImage();
    drag(e);
  }

  function canStart(item: HTMLElement) {
    let handledItem: HTMLElement | null = item;
    if (drake.dragging && mirrorEl) {
      return;
    }
    if (isContainer(handledItem)) {
      return; // don't drag container itself
    }
    const handle = handledItem;
    while (getParent(handledItem) && isContainer(getParent(handledItem) as HTMLElement) === false) {
      if (o.invalid(handledItem, handle)) {
        return;
      }
      handledItem = getParent(handledItem); // drag target should be a top element
      if (!handledItem) {
        return;
      }
    }
    const source = getParent(handledItem);
    if (!source) {
      return;
    }
    if (o.invalid(handledItem, handle)) {
      return;
    }

    const movable = o.moves(handledItem, source, handle, nextEl(handledItem));
    if (!movable) {
      return;
    }

    return {
      item: handledItem,
      source: source,
    };
  }

  function canMove(item: HTMLElement) {
    return !!canStart(item);
  }

  function manualStart(item: HTMLElement) {
    const context = canStart(item);
    if (context) {
      start(context);
    }
  }

  function start(context: CanStartElements) {
    if (isCopy(context.item, context.source)) {
      copyElement = context.item.cloneNode(true) as HTMLElement;
      drake.emit("cloned", copyElement, context.item, "copy");
    }

    sourceContainer = context.source;
    draggedItem = context.item;
    initialSibling = currentSibling = nextEl(context.item);

    drake.dragging = true;
    drake.emit("drag", draggedItem, sourceContainer);
  }

  function invalidTarget() {
    return false;
  }

  function end() {
    if (!drake.dragging) {
      return;
    }
    const item = copyElement || draggedItem;
    drop(item as HTMLElement, getParent(item) as HTMLElement);
  }

  function ungrab() {
    grabbedContext = false;
    eventualMovements(true);
    movements(true);
  }

  function release(e?: PointerEvent) {
    ungrab();

    if (!drake.dragging) {
      return;
    }
    const item = copyElement || (draggedItem as HTMLElement);
    const clientX = e ? getCoord("clientX", e) || 0 : 0;
    const clientY = e ? getCoord("clientY", e) || 0 : 0;
    const elementBehindCursor = getElementBehindPoint(mirrorEl, clientX, clientY) as HTMLElement;
    const dropTarget = findDropTarget(elementBehindCursor, clientX, clientY);
    if (dropTarget && ((copyElement && o.copySortSource) || !copyElement || dropTarget !== sourceContainer)) {
      drop(item, dropTarget);
    } else if (o.removeOnSpill) {
      remove();
    } else {
      cancel();
    }
  }

  function drop(item: HTMLElement, target: HTMLElement) {
    const parent = getParent(item);
    if (copyElement && o.copySortSource && target === sourceContainer) {
      (parent as HTMLElement).removeChild(draggedItem as HTMLElement);
    }
    if (isInitialPlacement(target)) {
      drake.emit("cancel", item, sourceContainer, sourceContainer);
    } else {
      drake.emit("drop", item, target, sourceContainer, currentSibling);
    }
    cleanup();
  }

  function remove() {
    if (!drake.dragging) {
      return;
    }
    const item = copyElement || draggedItem;
    const parent = getParent(item);
    if (parent) {
      parent.removeChild(item as HTMLElement);
    }
    drake.emit(copyElement ? "cancel" : "remove", item, parent, sourceContainer);
    cleanup();
  }

  function cancel(revert?: boolean) {
    if (!drake.dragging) {
      return;
    }
    const reverts = arguments.length > 0 ? revert : o.revertOnSpill;
    const item = copyElement || (draggedItem as HTMLElement);
    const parent = getParent(item) as HTMLElement;
    const initial = isInitialPlacement(parent);
    if (initial === false && reverts) {
      if (copyElement) {
        if (parent) {
          parent.removeChild(copyElement);
        }
      } else {
        (sourceContainer as HTMLElement).insertBefore(item, initialSibling);
      }
    }
    if (initial || reverts) {
      drake.emit("cancel", item, sourceContainer, sourceContainer);
    } else {
      drake.emit("drop", item, parent, sourceContainer, currentSibling);
    }
    cleanup();
  }

  function cleanup() {
    const item = copyElement || draggedItem;
    ungrab();
    removeMirrorImage();
    if (item) {
      classes.rm(item, "gu-transit");
    }
    if (renderTimer) {
      clearTimeout(renderTimer);
    }
    drake.dragging = false;
    if (lastDropTarget) {
      drake.emit("out", item, lastDropTarget, sourceContainer);
    }
    drake.emit("dragend", item);
    sourceContainer = draggedItem = copyElement = initialSibling = currentSibling = renderTimer = lastDropTarget = null;
  }

  function isInitialPlacement(target: HTMLElement, s?: HTMLElement) {
    let sibling;
    if (s !== void 0) {
      sibling = s;
    } else if (mirrorEl) {
      sibling = currentSibling;
    } else {
      sibling = nextEl(copyElement || draggedItem);
    }
    return target === sourceContainer && sibling === initialSibling;
  }

  function findDropTarget(elementBehindCursor: HTMLElement, clientX: number, clientY: number) {
    let target = elementBehindCursor;
    while (target && !accepted()) {
      target = getParent(target) as HTMLElement;
    }
    return target;

    function accepted() {
      const droppable = isContainer(target);
      if (droppable === false) {
        return false;
      }

      const immediate = getImmediateChild(target, elementBehindCursor) as HTMLElement;
      const reference = getReference(target, immediate, clientX, clientY) as HTMLElement;
      const initial = isInitialPlacement(target, reference);
      if (initial) {
        return true; // should always be able to drop it right back where it was
      }
      return o.accepts(draggedItem as HTMLElement, target, sourceContainer as HTMLElement, reference);
    }
  }

  function drag(e: PointerEvent) {
    if (!mirrorEl) {
      return;
    }
    e.preventDefault();

    const clientX = getCoord("clientX", e) || 0;
    const clientY = getCoord("clientY", e) || 0;
    const x = clientX - refOffsetX;
    const y = clientY - refOffsetY;

    mirrorEl.style.left = x + "px";
    mirrorEl.style.top = y + "px";

    const item = copyElement || (draggedItem as HTMLElement);
    const elementBehindCursor = getElementBehindPoint(mirrorEl, clientX, clientY) as HTMLElement;
    let dropTarget = findDropTarget(elementBehindCursor, clientX, clientY);
    const changed = dropTarget !== null && dropTarget !== lastDropTarget;
    if (changed || dropTarget === null) {
      out();
      lastDropTarget = dropTarget;
      over();
    }
    const parent = getParent(item);
    if (dropTarget === sourceContainer && copyElement && !o.copySortSource) {
      if (parent) {
        parent.removeChild(item);
      }
      return;
    }
    let reference;
    const immediate = getImmediateChild(dropTarget, elementBehindCursor);
    if (immediate !== null) {
      reference = getReference(dropTarget, immediate, clientX, clientY) as HTMLElement;
    } else if (o.revertOnSpill === true && !copyElement) {
      reference = initialSibling;
      dropTarget = sourceContainer as HTMLElement;
    } else {
      if (copyElement && parent) {
        parent.removeChild(item);
      }
      return;
    }
    if ((reference === null && changed) || (reference !== item && reference !== nextEl(item))) {
      currentSibling = reference;
      dropTarget.insertBefore(item, reference);
      drake.emit("shadow", item, dropTarget, sourceContainer);
    }
    function moved(type: "over" | "out") {
      drake.emit(type, item, lastDropTarget, sourceContainer);
    }
    function over() {
      if (changed) {
        moved("over");
      }
    }
    function out() {
      if (lastDropTarget) {
        moved("out");
      }
    }
  }

  function spillOver(el: HTMLElement) {
    classes.rm(el, "gu-hide");
  }

  function spillOut(el: HTMLElement) {
    if (drake.dragging) {
      classes.add(el, "gu-hide");
    }
  }

  function renderMirrorImage() {
    if (mirrorEl) {
      return;
    }
    const rect = (draggedItem as HTMLElement).getBoundingClientRect();
    mirrorEl = (draggedItem as HTMLElement).cloneNode(true) as HTMLElement;
    mirrorEl.style.width = getRectWidth(rect) + "px";
    mirrorEl.style.height = getRectHeight(rect) + "px";
    classes.rm(mirrorEl, "gu-transit");
    classes.add(mirrorEl, "gu-mirror");
    o.mirrorContainer.appendChild(mirrorEl);
    touchy(documentElement, "add", "mousemove", drag);
    classes.add(o.mirrorContainer, "gu-unselectable");
    drake.emit("cloned", mirrorEl, draggedItem, "mirror");
  }

  function removeMirrorImage() {
    if (mirrorEl) {
      classes.rm(o.mirrorContainer, "gu-unselectable");
      touchy(documentElement, "remove", "mousemove", drag);
      (getParent(mirrorEl) as HTMLElement).removeChild(mirrorEl);
      mirrorEl = null;
    }
  }

  function getImmediateChild(dropTarget: HTMLElement, target: HTMLElement) {
    let immediate = target;
    while (immediate !== dropTarget && getParent(immediate) !== dropTarget) {
      immediate = getParent(immediate) as HTMLElement;
    }
    if (immediate === documentElement) {
      return null;
    }
    return immediate;
  }

  function getReference(dropTarget: HTMLElement, target: HTMLElement, x: number, y: number) {
    const horizontal = o.direction === "horizontal";
    const reference = target !== dropTarget ? inside() : outside();
    return reference;

    function outside() {
      // slower, but able to figure out any position
      const len = dropTarget.children.length;
      let i;
      let el;
      let rect;
      for (i = 0; i < len; i++) {
        el = dropTarget.children[i];
        rect = el.getBoundingClientRect();
        if (horizontal && rect.left + rect.width / 2 > x) {
          return el;
        }
        if (!horizontal && rect.top + rect.height / 2 > y) {
          return el;
        }
      }
      return null;
    }

    function inside() {
      // faster, but only available if dropped inside a child element
      const rect = target.getBoundingClientRect();
      if (horizontal) {
        return resolve(x > rect.left + getRectWidth(rect) / 2);
      }
      return resolve(y > rect.top + getRectHeight(rect) / 2);
    }

    function resolve(after: boolean) {
      return after ? nextEl(target) : target;
    }
  }

  function isCopy(item: HTMLElement, container: HTMLElement) {
    return typeof o.copy === "boolean" ? o.copy : o.copy(item, container);
  }
};

function touchy(el: HTMLElement, op: string, type: keyof DocumentEventMap, fn: (e: any) => void, onlyMouse?: boolean) {
  const touch = {
    mouseup: "touchend",
    mousedown: "touchstart",
    mousemove: "touchmove",
  } as const;

  if (op === "add") {
    document.addEventListener(type, fn);
  } else if (op === "remove") {
    document.removeEventListener(type, fn);
  }

  const touchType = type in touch ? touch[type as keyof typeof touch] : undefined;
  if (!onlyMouse && op === "add" && touchType !== void 0) {
    document.addEventListener(touchType, fn);
  } else if (!onlyMouse && op === "remove" && touchType !== void 0) {
    document.removeEventListener(touchType, fn);
  }
}

function whichMouseButton(e: TouchEvent | MouseEvent) {
  if (isTouch(e) && e.touches !== void 0) {
    return e.touches.length;
  }
  if (e.which !== void 0 && e.which !== 0) {
    return e.which;
  } // see https://github.com/bevacqua/dragula/issues/261
  if (isMouse(e) && e.buttons !== void 0) {
    return e.buttons;
  }
  const button = isMouse(e) ? e.button : undefined;
  if (button !== void 0) {
    // see https://github.com/jquery/jquery/blob/99e8ff1baa7ae341e94bb89c3e84570c7c3ad9ea/src/event.js#L573-L575
    return button & 1 ? 1 : button & 2 ? 3 : button & 4 ? 2 : 0;
  }
}

function getOffset(el: HTMLElement) {
  const rect = el.getBoundingClientRect();
  return {
    left: rect.left + getScroll("scrollLeft", "pageXOffset"),
    top: rect.top + getScroll("scrollTop", "pageYOffset"),
  };
}

function getScroll(scrollProp: "scrollLeft" | "scrollTop", offsetProp: "pageXOffset" | "pageYOffset") {
  if (typeof window[offsetProp] !== "undefined") {
    return window[offsetProp];
  }
  if (documentElement.clientHeight) {
    return documentElement[scrollProp];
  }
  return doc.body[scrollProp];
}

function getElementBehindPoint(point: HTMLElement | null, x: number, y: number) {
  const overEl: HTMLElement | Record<string, unknown> = point || {};
  const state = overEl.className || "";
  overEl.className += " gu-hide";
  const el = doc.elementFromPoint(x, y);
  overEl.className = state;
  return el;
}

function never() {
  return false;
}
function always() {
  return true;
}
function getRectWidth(rect: DOMRect) {
  return rect.width || rect.right - rect.left;
}
function getRectHeight(rect: DOMRect) {
  return rect.height || rect.bottom - rect.top;
}
function getParent(el: HTMLElement | null): HTMLElement | null {
  return !el || el.parentNode === doc ? null : (el.parentNode as HTMLElement);
}
function isInput(el: HTMLElement): el is HTMLInputElement {
  return el.tagName === "INPUT" || el.tagName === "TEXTAREA" || el.tagName === "SELECT" || isEditable(el);
}
function isEditable(el: HTMLElement | null) {
  if (!el) {
    return false;
  } // no parents were editable
  if (el.contentEditable === "false") {
    return false;
  } // stop the lookup
  if (el.contentEditable === "true") {
    return true;
  } // found a contentEditable element in the chain
  return isEditable(getParent(el)); // contentEditable is set to 'inherit'
}

function nextEl(el: HTMLElement | null) {
  return (el?.nextElementSibling as HTMLElement) || manually();
  function manually() {
    let sibling = el;
    do {
      sibling = (sibling?.nextSibling as HTMLElement) || null;
    } while (sibling && sibling.nodeType !== 1);
    return sibling;
  }
}

function getEventHost(e: MouseEvent | TouchEvent) {
  // on touchend event, we have to use `e.changedTouches`
  // see http://stackoverflow.com/questions/7192563/touchend-event-properties
  // see https://github.com/bevacqua/dragula/issues/34
  if (isTouch(e) && e.targetTouches && e.targetTouches.length) {
    return e.targetTouches[0];
  }
  if (isTouch(e) && e.changedTouches && e.changedTouches.length) {
    return e.changedTouches[0];
  }
  return e as MouseEvent;
}

function getCoord(coord: "clientX" | "clientY" | "pageX" | "pageY", e: MouseEvent | TouchEvent) {
  const host = getEventHost(e);
  return host[coord] || 0;
}
