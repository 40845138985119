import styled from "@emotion/styled";
import { useTheme } from "@mui/material";

export const StatusLabelPill = styled("div")(() => {
  const { spacing, typography } = useTheme();
  return {
    height: "13px",
    paddingLeft: spacing(0.5),
    paddingRight: spacing(0.5),
    maxWidth: "100%",
    fontFamily: "Figtree",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    whiteSpace: "nowrap",
    cursor: "unset",
    textDecoration: "none",
    verticalAlign: "middle",
    fontSize: typography.pxToRem(11),
    borderRadius: "4px !important",
    fontWeight: typography.fontWeightMedium,
    lineHeight: 1,
  };
});
