import { parseJsonSafe } from "@sinch/utils/json";
import { DateTime } from "luxon";
import { isEmpty, prop } from "ramda";
import { isArray, isNilOrEmpty, isObject } from "ramda-adjunct";
import { captureError } from "../../config/sentry";

/**
 * This object holds parsers for scalar types, keep sychronized with schema
 */
export const typeMap = (timeZone: string) => ({
  DateTime: {
    serialize: (parsed: unknown): string | null => (parsed instanceof Date ? parsed.toISOString() : null),
    parseValue: (raw: unknown): any | null => {
      const dateTime = DateTime.fromISO(raw as string);
      if (!dateTime.isValid) {
        captureError(new Error(`Invalid DateTime: ${raw}`));
      }
      return dateTime.toJSDate();
    },
  },
  Date: {
    serialize: (parsed: unknown): string | null =>
      parsed instanceof Date ? DateTime.fromJSDate(parsed).toFormat("yyyy-MM-dd") : null,
    parseValue: (raw: unknown): any | null =>
      isNilOrEmpty(raw) ? null : DateTime.fromSQL(raw as string, { zone: timeZone }).toJSDate(),
  },
  FileHash: {
    serialize: (parsed: unknown): string | null => prop("id", parsed) ?? null,
    parseValue: (raw: unknown): any | null => raw,
  },
  MixedAttributeValue: {
    serialize: (parsed: unknown): any | null => {
      if (parsed instanceof Date) {
        return DateTime.fromJSDate(parsed).toFormat("yyyy-MM-dd");
      } else if (isArray(parsed) && !isEmpty(parsed)) {
        return parsed.map((item) => (isObject(item) && prop("id", item) ? prop("id", item) : item));
      } else if (isObject(parsed) && !isArray(parsed)) {
        return prop("id", parsed) ? prop("id", parsed) : parsed;
      } else if (isNilOrEmpty(parsed)) {
        return null;
      } else {
        return parsed;
      }
    },
    parseValue: (raw: unknown): any | null => raw,
  },
  // Parse received JSON and transform date strings to date
  JsonValueScalar: {
    serialize: (parsed: unknown): any | null => JSON.parse(JSON.stringify(parsed)),
    parseValue: (raw: unknown): any | null => parseJsonSafe(JSON.stringify(raw)),
  },
});
