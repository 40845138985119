#import "../../../core/entities/fragments.graphql"

query Places($filter: PlaceFilter) {
  places(filter: $filter, limit: 20) {
    ...FullPlace
  }
}

query Place($id: ID!) {
  place(id: $id) {
    ...FullPlace
  }
}

query Countries {
  countries {
    code
    name
    regionLabel
    regions {
      name
      code
    }
  }
}

mutation CreatePlace($input: PlaceInput!) {
  createPlace(input: $input) {
    result
    messages {
      field
      message
      severity
    }
    payload {
      ...FullPlace
      alias
    }
  }
}
