import { Box, TableCell } from "@mui/material";
import React from "react";
import { ProfileVerificationIcon } from "../../Icons/ProfileVerificationIcon";
import { WorkerListItem } from "../../Worker/WorkerListItem";
import { ShiftPanelDetailFragment } from "../queries.types";
import { AttendanceTableRow } from "./AttendanceRow";
import { ApplicantActions } from "@sinch/components/application/Applicant/ApplicantActions";

export const ApplicantRow: React.FC<{
  applicant: ShiftPanelDetailFragment["positions"][0]["applicants"][0];
  onUpdate?: () => void;
}> = ({ applicant, onUpdate }) => (
  <AttendanceTableRow>
    <TableCell colSpan={2} size="small" width={24}>
      <WorkerListItem worker={applicant.worker} />
    </TableCell>
    <TableCell size="small">
      <Box
        sx={{
          display: "flex",
          justifyItems: "center",
        }}
      >
        <ProfileVerificationIcon verified={!!applicant.worker.workerinfo?.verified} />
      </Box>
    </TableCell>
    <TableCell align="right" colSpan={3} size="small">
      <ApplicantActions applicantId={applicant.id} onUpdate={onUpdate} />
    </TableCell>
  </AttendanceTableRow>
);
