import { JoinModeEnum } from "@sinch/core/entities/serverEnums";
import type { Position, Worker } from "@sinch/types/sinch.types";

import { includeIf } from "@sinch/utils/object";
import { isNil } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import { useCallback, useMemo, useState } from "react";
import { SinchEntity, useSinchEntity } from "../entities/entitiesProperties";
import { SignInDialogProps } from "../SignInDialog/types";
import { useWorker } from "../Worker/hooks";
import { JoinWorkerMutation } from "./queries.types";
import { useAttendance } from "./useAttendance";
import { useTimelinePopover } from "./useTimelinePopover";

type PositionDataForAttendance = Pick<Position, "id" | "name" | "beginning" | "end" | "groupNumber"> & {
  profession?: { name: Position["profession"]["name"] };
};

type WorkerDataForAttendance = Pick<Worker, "id" | "name" | "surname">;

// Format position name to be displayed in popover or dialog
const getPositionName = (position: PositionDataForAttendance) =>
  [position.profession?.name, position.name].filter((name) => name?.length).join(" – ");
// Format worker name to be displayed in popover or dialog

interface DataForJoin {
  position: PositionDataForAttendance;
  worker: WorkerDataForAttendance;
  needClarifyPaymentMethod?: boolean;
  needClarifyLinkedPositions?: boolean;
  linkedPositions?: string[] | null;
}

export interface UseChangeAttendanceActionsProps {
  attendanceConfirmation: boolean;
}

/**
 * Provide methods for join and manage staff join and attendance with drop functionality
 */
export const useChangeAttendanceActions = ({ attendanceConfirmation }: UseChangeAttendanceActionsProps) => {
  const [currentJoiningData, setCurrentJoiningData] = useState<DataForJoin | null>(null);
  const { getEntityIdLabel } = useSinchEntity();
  const { getWorkerName } = useWorker();

  const { joinWorker, kickWorker, requireConfirmationByWorker } = useAttendance();

  const handleRequireConfirmation = useCallback(
    async (attendanceId: string) => {
      await requireConfirmationByWorker({
        attendanceId,
        attendanceConfirmation: !attendanceConfirmation,
      });
    },
    [attendanceConfirmation, requireConfirmationByWorker]
  );

  const handleUndo = useCallback(
    async (attendanceId: string | string[]) => {
      await kickWorker(attendanceId);
    },
    [kickWorker]
  );

  const { setAnchorEl, popoverProps, onResponse, open, setOpen } = useTimelinePopover({
    attendanceConfirmation,
    onRequireConfirmation: handleRequireConfirmation,
    onUndo: handleUndo,
  });

  // Set popover data after join worker mutation
  const setPopoverData = useCallback(
    ({
      response,
      worker,
      position,
    }: DataForJoin & {
      response: JoinWorkerMutation["join"];
    }) => {
      onResponse({
        messages: response.messages,
        result: response.result,
        workerId: worker.id,
        attendanceId: response?.payload?.map(({ id }) => id) ?? null,
        workerName: getWorkerName(worker),
        positionName: getPositionName(position),
        positionIdLabel: getEntityIdLabel(SinchEntity.Position, position.id) as string,
      });
    },
    [onResponse]
  );

  // Handle join worker mutation, set data for dialog or popover
  const handleJoinWorker = useCallback(
    async ({
      position,
      worker,
      paymentMethodId,
      joinMode,
      requireConfirmation,
    }: DataForJoin & {
      paymentMethodId?: string;
      joinMode?: JoinModeEnum;
      requireConfirmation?: boolean;
    }) => {
      setOpen(true);
      const response = await joinWorker({
        workerId: worker.id,
        positionId: position.id,
        paymentMethodId: paymentMethodId,
        requireConfirmation: requireConfirmation ?? attendanceConfirmation,
        joinMode: joinMode ?? JoinModeEnum.JoinSimple,
      });
      if (response?.forceChoosePayoutMethod || isNotNilOrEmpty(response?.availableGroupedPositionIds)) {
        setOpen(false);
        setCurrentJoiningData({
          position,
          worker,
          linkedPositions: response?.availableGroupedPositionIds,
          needClarifyPaymentMethod: response?.forceChoosePayoutMethod || false,
          needClarifyLinkedPositions: isNotNilOrEmpty(response?.availableGroupedPositionIds),
        });
      } else if (response) {
        setPopoverData({
          response,
          worker,
          position,
        });
      }
    },
    [joinWorker, setOpen, setPopoverData, attendanceConfirmation]
  );

  // Handle drop on position cell
  const handleDrop = useCallback<
    (position: PositionDataForAttendance, worker: WorkerDataForAttendance, element?: Element) => Promise<void>
  >(
    async (position, worker, element) => {
      if (element) {
        setAnchorEl(element);
      }
      await handleJoinWorker({
        position,
        worker,
      });
    },
    [joinWorker, handleJoinWorker]
  );

  // Set dialog data
  const dialogProps: SignInDialogProps | null = useMemo(
    () =>
      currentJoiningData
        ? {
            ...includeIf(!!currentJoiningData.needClarifyPaymentMethod, {
              paymentMethod: {
                effectiveDate: currentJoiningData.position.beginning,
              },
            }),
            onChange: ({ paymentMethodId, joinLinked, requireConfirmation }) => {
              const joinMode = joinLinked ? JoinModeEnum.JoinWithGrouped : JoinModeEnum.JoinWithoutGrouped;
              handleJoinWorker({
                position: currentJoiningData.position,
                worker: currentJoiningData.worker,
                paymentMethodId: paymentMethodId ?? undefined,
                joinMode: isNil(joinMode) ? undefined : joinMode,
                requireConfirmation: requireConfirmation,
              });
              setCurrentJoiningData(null);
            },
            onClose: () => setCurrentJoiningData(null),
            workerId: currentJoiningData.worker.id,
            positionName: `${getPositionName(currentJoiningData.position)} (${getEntityIdLabel(
              SinchEntity.Position,
              currentJoiningData.position.id
            )})`,
            workerName: getWorkerName(currentJoiningData.worker),
            ...includeIf(!!currentJoiningData.needClarifyLinkedPositions, {
              linkedPositions: currentJoiningData.linkedPositions!,
            }),
            attendanceConfirmation: attendanceConfirmation,
          }
        : null,
    [currentJoiningData, handleJoinWorker]
  );

  return {
    popoverProps: open ? popoverProps : null,
    dialogProps,
    onDropHandler: handleDrop,
  };
};
