import { Box, OutlinedInputProps, Skeleton, SkeletonProps, useTheme } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";

type InputSkeletonProps = SkeletonProps & { active?: boolean; size?: OutlinedInputProps["size"] };
export const InputSkeleton: FC<InputSkeletonProps> = ({ children, size, active = false, ...props }) => {
  const { shape } = useTheme();
  if (!active) {
    return children;
  }

  return (
    <>
      <Skeleton
        {...props}
        component={SkeletonPlaceholder}
        height={size === "small" ? shape.inputSmallHeight : shape.inputMediumHeight}
        variant="input"
      />
      <Box
        sx={{
          display: "none",
        }}
      >
        {children}
      </Box>
    </>
  );
};

const SkeletonPlaceholder: FC<PropsWithChildren> = ({ children: componentChildren, ...componentProps }) => (
  <div {...componentProps}>
    <Box sx={{ visibility: "visible !important", position: "absolute", padding: "0px 16px" }}>Loading...</Box>
    {componentChildren}
  </div>
);
