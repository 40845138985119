import { InputAdornment, InputBaseComponentsPropsOverrides, OutlinedInputProps, styled } from "@mui/material";

import { MdsCalendarTodayXs } from "@sinch/icons";
import React, { FC } from "react";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { FormControlWrapper } from "../FormControlWrapper/FormControlWrapper";
import { NullableValueOutlinedInput } from "../NullableValueOutlinedInput/NullableValueOutlinedInput";
import { DateTimeInputProps } from "./DateTimeInput.types";
import { DateTimeInputLuxonAdapter } from "./DateTimeInputLuxonAdapter";
// eslint-disable-next-line import/no-internal-modules

/**
 * Date time input, for selection date part, calendar dialog is used, for selection time part, select box is used
 */
export const DateTimeInput: FC<DateTimeInputProps> = ({
  label,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  helperText,
  errorMessage,
  error,
  required,
  infoTooltip,
  fullWidth,
  value,
  onChange,
  size,
  startAdornment,
  endAdornment,
  "data-cy": dataCy,
  ...props
}) => {
  const [focused, setFocused] = React.useState(false);

  return (
    <FormControlWrapper
      error={error}
      errorMessage={errorMessage}
      focused={focused}
      FormHelperTextProps={formHelperTextProps}
      fullWidth={fullWidth}
      helperText={helperText}
      infoTooltip={infoTooltip}
      label={label}
      onBlurCapture={() => setFocused(false)}
      required={required}
      {...formControlProps}
    >
      <DateTimeOutlinedInput
        data-cy={dataCy}
        disabled={props.disabled}
        endAdornment={endAdornment ? <InputAdornment position="end">{endAdornment}</InputAdornment> : undefined}
        error={error}
        fullWidth={fullWidth}
        onChange={onChange as (val: unknown) => void}
        onFocus={() => setFocused(true)}
        required={required}
        size={size}
        slotProps={{ input: { ...props, size } as InputBaseComponentsPropsOverrides }}
        slots={{ input: DateTimeInputLuxonAdapter }}
        startAdornment={
          startAdornment ?? (
            <InputAdornment position="start">
              <MdsIcon fontSize="xs" icon={MdsCalendarTodayXs} />
            </InputAdornment>
          )
        }
        sx={(theme) => ({ padding: theme.spacing(0, 1) })}
        value={value}
      />
    </FormControlWrapper>
  );
};

const DateTimeOutlinedInput = styled(NullableValueOutlinedInput)<OutlinedInputProps>(({ theme, startAdornment }) => {
  if (!startAdornment) {
    return { paddingLeft: theme.spacing(2) };
  }
  return {};
});
