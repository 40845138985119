import {
  Box,
  formLabelClasses,
  getFormLabelUtilityClasses,
  InputLabel,
  InputLabelProps,
  Stack,
  styled,
  unstable_composeClasses as composeClasses,
} from "@mui/material";
import React, { FC, ReactNode } from "react";
import { InfoTooltip } from "./InfoTooltip";

export interface LabelWithInfoProps
  extends Omit<InputLabelProps, "onChange" | "variant" | "size" | "shrink" | "margin"> {
  /**
   * Tooltip of info icon after label, if nil, no icon is rendered
   */
  infoTooltip?: ReactNode;
}

/**
 * Label with info tooltip
 */
export const LabelWithInfo: FC<LabelWithInfoProps> = ({ children, infoTooltip, required, ...props }) => (
  <InputLabel {...props} required={false}>
    <Stack
      direction="row"
      spacing={0.5}
      sx={{
        alignItems: "center",
      }}
    >
      <Box>{children}</Box>
      {required && (
        <AsteriskComponent
          className={
            composeClasses(
              {
                asterisk: ["asterisk", props.error && "error"],
              },
              getFormLabelUtilityClasses
            ).asterisk
          }
        >
          *
        </AsteriskComponent>
      )}
      {infoTooltip && <InfoTooltip title={infoTooltip} />}
    </Stack>
  </InputLabel>
);

const AsteriskComponent = styled("span", {
  name: "MuiFormLabel",
  slot: "Asterisk",
  overridesResolver: (props, styles) => styles.asterisk,
})(({ theme }) => ({
  [`&.${formLabelClasses.error}`]: {
    color: theme.palette.error.main,
  },
}));
