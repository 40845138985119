import React, { createContext, FC, useContext } from "react";
import { AppSettingsProps, SessionSettings } from "./AppSettings.types";

/**
 * Create context for easy acces to global settings of an app
 */
export const ContextSettings = createContext<SessionSettings>({} as SessionSettings);

/**
 * Return list of application settings.
 */
export const useSettings: () => SessionSettings = () => useContext(ContextSettings);

/**
 * Provide context for application settings
 */
export const AppSettingsProvider: FC<AppSettingsProps> = ({ settings, children }) => (
  <ContextSettings.Provider value={settings}>{children}</ContextSettings.Provider>
);
