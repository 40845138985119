import { DateTime } from "luxon";
import React, { FC, useEffect, useRef } from "react";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import { ITEM_HEIGHT, ItemContext, TimeListProps } from "./types";
import { useInfiniteTimeScroll } from "./UseInfiniteTimeScroll";
import { TimePickerOption, VirtuosoItem } from "./VirtuosoItem";
import { VirtuosoList } from "./VirtuosoList";

export const TimeList: FC<TimeListProps> = ({
  options,
  maxTime,
  minTime,
  selectedTime,
  timePoint,
  dateInOption,
  onChange,
  minWidth,
  minutesStep = 15,
  timeItemsStyle,
}) => {
  const virtuoso = useRef<VirtuosoHandle>(null);

  const { firstItemIndex, initialSelectedIndex, onEndReached, onStartReached, selectedIndexOverall, times } =
    useInfiniteTimeScroll({
      options,
      maxTime,
      minTime,
      selectedTime,
      minutesStep,
    });

  useEffect(() => {
    virtuoso.current?.scrollToIndex({
      index: initialSelectedIndex,
      align: "center",
      behavior: "auto",
    });
  }, [initialSelectedIndex]);

  if (!options?.length) {
    return null;
  }
  return (
    <>
      <Virtuoso<DateTime, ItemContext>
        ref={virtuoso}
        components={{
          Item: VirtuosoItem,
          List: VirtuosoList,
        }}
        context={{ selectedIndexOverall, onChange, timeItemsStyle }}
        data={times}
        endReached={onEndReached}
        firstItemIndex={Math.max(firstItemIndex, 0)}
        fixedItemHeight={ITEM_HEIGHT}
        increaseViewportBy={100}
        initialItemCount={10}
        initialTopMostItemIndex={{ index: initialSelectedIndex, align: "center" }}
        itemContent={(index, option) => (
          <TimePickerOption dateInOption={dateInOption} option={option} timePoint={timePoint} />
        )}
        startReached={onStartReached}
        style={{
          overflowX: "hidden",
          height: Math.min(400, ITEM_HEIGHT * options.length),
          width: minWidth,
        }}
      />
    </>
  );
};
