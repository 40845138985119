import { Box, BoxProps, styled } from "@mui/material";
import React, { FC, ReactNode } from "react";

const ImageBoxContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "exactSize" && prop !== "imgWidth" && prop !== "imgHeight",
})<BoxProps & Omit<ImageBoxProps, "render">>(({ theme, imgWidth, imgHeight, disabled, exactSize }) => ({
  height: exactSize ? undefined : "12rem",
  display: "flex",
  overflow: "hidden",
  alignItems: "center",
  justifyContent: "center",
  borderColor: theme.palette.grey[400],
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: `${theme.shape.borderRadius}px !important`,
  "&>img": {
    width: exactSize ? undefined : `${imgWidth}px`,
    height: exactSize ? undefined : `${imgHeight}px`,
    minWidth: "100%",
    maxWidth: "100%",
    minHeight: "100%",
    objectFit: "cover",
    opacity: disabled ? 0.3 : 1,
  },
}));

export interface ImageBoxProps {
  render: (props: { width: number; height: number }) => ReactNode;
  imgWidth: number;
  imgHeight: number;
  disabled: boolean;
  exactSize?: boolean;
}

export const ImageBox: FC<ImageBoxProps> = ({ render, imgWidth, imgHeight, disabled, exactSize }) => (
  <ImageBoxContainer disabled={disabled} exactSize={exactSize} imgHeight={imgHeight} imgWidth={imgWidth}>
    {render({ width: imgWidth, height: imgHeight })}
  </ImageBoxContainer>
);
