import { getTimeStepsAroundDate } from "@sinch/utils/dateTime/getTimeStepsAroundDate";

import { DateTime } from "luxon";
import { useEffect, useMemo, useRef } from "react";
import { TimeInputBaseProps } from "./types";

export interface UseTimeInputProps
  extends Pick<
    TimeInputBaseProps,
    "value" | "minutesStep" | "minTime" | "maxTime" | "onChange" | "optionsRangeInMinutes" | "referenceDate"
  > {
  autocompleteProps: TimeInputBaseProps["AutocompleteProps"];
}

export const useTimeInput = ({
  value,
  minutesStep = 15,
  minTime,
  maxTime,
  onChange,
  referenceDate,
  optionsRangeInMinutes,
}: UseTimeInputProps) => {
  const lastValidValue = useRef<DateTime | undefined>();

  useEffect(() => {
    if (value?.isValid) {
      lastValidValue.current = value;
    }
  }, [value]);

  const getReferenceDate = () => {
    if (value?.isValid) {
      return value;
    }
    if (referenceDate) {
      return referenceDate;
    }
    return lastValidValue.current;
  };

  const options = useMemo(
    () =>
      getTimeStepsAroundDate(getReferenceDate(), {
        stepSizeInMinutes: minutesStep,
        minTime,
        maxTime,
        rangeSizeInMinutes: optionsRangeInMinutes,
      }),
    [value, minTime, maxTime, minutesStep, optionsRangeInMinutes]
  );

  /**
   * Handles blur event for an input field.
   * If last valid value exists, it calls the `onChange` callback with the last valid value.
   */
  const handleBlur = () => {
    if (lastValidValue.current) {
      onChange?.(lastValidValue.current || null);
    }
  };

  /**
   * Handles the change event for a DateTime input. If date is invalid, it not calls the `onChange` callback.
   */
  const onChangeHandler = (newValue: DateTime | null) => {
    if (newValue?.isValid) {
      onChange?.(newValue);
      lastValidValue.current = undefined;
    } else if (value) {
      lastValidValue.current = value;
    }
  };
  return {
    options,
    onBlur: handleBlur,
    onChangeHandler,
  };
};
