import { DocumentNode, OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from "@apollo/client";
import { useBrowserStatus } from "@sinch/core/providers/BrowserStatusProvider";

/**
 * Load data from server and return loading and error statuses
 * See https://www.apollographql.com/docs/react/api/react/hooks#usequery
 */
export function useQueryHandler<
  TData = Record<string, unknown>,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  variables?: TVariables,
  options: Omit<QueryHookOptions<TData, TVariables>, "variables"> = {}
) {
  const {
    error,
    variables: currentVariables,
    ...queryReturnProps
  } = useQuery<TData, TVariables>(query, {
    ...(variables ? { variables } : {}),
    ...options,
  });
  const { pageStatus } = useBrowserStatus();

  if (pageStatus !== "unloading" && error) {
    throw error;
  }

  return { error, variables: currentVariables, ...queryReturnProps };
}
