import { isString } from "ramda-adjunct";

const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

export const parseJsonSafe = (toParse: any) => {
  try {
    return JSON.parse(toParse, (_, value) => {
      if (isString(value) && isoDateRegex.test(value)) {
        return new Date(value);
      }
      return value;
    });
  } catch {
    return null;
  }
};
