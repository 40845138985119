import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { StatusLabelPill } from "./StatusLabelPill";

/**
 * Styled component for the confirmation waiting pill.
 */
const ConfirmationWaitingPillStyled = styled(StatusLabelPill)(() => {
  const theme = useTheme();
  return {
    backgroundColor: theme.palette.custom.green["800"],
    color: theme.palette.common.white,
  };
});

/**
 * Component for displaying a pill indicating that confirmation is waiting to confirmation state.
 */
export const ConfirmationWaitingPill = () => {
  const { t } = useTranslation();
  return <ConfirmationWaitingPillStyled>{t("Shift.pending")}</ConfirmationWaitingPillStyled>;
};
