fragment FullPlace on Place {
  id
  name
  shortName
  fullName
  lng
  lat
  address
  city
  country
  note
  region
  zip
  geolocationRadius
}

fragment FullClient on Client {
  id
  name
  surname
  email
  phone
}

fragment FullFile on File {
  id
  lastModified
  name
  size
  type
  url
}

fragment FullCompany on Company {
  address
  bankAccount
  city
  clients {
    ...FullClient
  }
  contactAddress
  contactCity
  contactCountry
  contactZip
  contacts {
    id
    name
    phone
  }
  country
  defaultPricelistCategory
  dic
  emailInvoice
  emailInvoiceAttachment
  ico
  id
  invoiceName
  name
  orders {
    id
    name
  }
  payRating
  pricelistCategories {
    id
    name
  }
  region
  status
  www
  zip
}

fragment FullTransportAttendance on TransportAttendance {
  beginning
  distance
  end
  id
  role
  totalWage
  totalWageDistance
  totalPrice
  totalPriceDistance
  trip {
    beginning
    direction
    distance
    drivers
    end
    id
    meetingTime
    note
    place {
      ...FullPlace
    }
  }
}

fragment FullAttendance on Attendance {
  attendanceNotes {
    id
    key
    value
  }
  arrival
  attendanceBreaks {
    beginning
    end
  }
  departure
  id
  presence
  transportAttendances {
    ...FullTransportAttendance
  }
  worker {
    ...FullWorker
  }
  rating {
    amount
    author {
      ...FullUser
    }
    created
    description
    id
    modified
    shadow
    type
  }
  penalty
  priceFix
  priceH
  pricePercentage
  totalPrice
  totalPriceFix
  totalPriceH
  totalPricePercentage
  wage
  wageFix
  wageH
  wagePercentage
  totalWageFix
  totalWageH
}

fragment FullWorkerInfo on Workerinfo {
  addressLocal
  addressPreferred
  avatarVerified
  bankAccountFormat
  bankAccountNumber
  citizenship
  city
  cityLocal
  country
  countryLocal
  goodArrivalsInARow
  id
  lastAttendance
  localAddressPreferred
  maxRating
  permanentDurationFrom
  permanentDurationTo
  permanentResident
  phoneVerified
  privateNote
  rating
  ratingCoeficient
  region
  regionLocal
  sex
  userId
  verified
  zip
  zipLocal
}

fragment FullAttributeValue on AttributeValue {
  id
  attribute {
    id
    dataType
  }
  expirationDate
  files {
    ...FullFile
  }
  value
}

fragment FullWorker on Worker {
  avatar(variant: THUMB_200_200) {
    url
    name
  }
  email
  id
  name
  personalAttributes {
    ...FullAttributeValue
  }
  phone
  preferredAddresses {
    id
    lat
    lng
    name
  }
  professions {
    choosable
    id
    name
  }
  status
  surname
  workerinfo {
    ...FullWorkerInfo
  }
}

fragment FullUser on User {
  birthdate
  email
  id
  name
  phone
  status
  surname
  languageId
}

fragment FullJob on Job {
  id
  name
  order {
    name
    id
    number
  }
  supervisor {
    ...FullUser
  }
}

fragment FullPosition on Position {
  antigroupNumber
  applicantRequirements
  applicantSize
  attendances {
    ...FullAttendance
  }
  beginning
  blockAfterHours
  cancelled
  concept
  emergencyThreshold
  end
  featured
  groupNumber
  hidden
  id
  leavableHours
  lockstatus
  meetingTime
  profession {
    choosable
    id
    name
  }
  role
  size
  positionBreaks {
    beginning
    end
    id
  }
  positionLocation {
    description
    id
    place {
      ...FullPlace
    }
  }
  status
}

fragment FullReport on Report {
  created
  creator {
    ...FullUser
  }
  fromUser {
    ...FullUser
  }
  id
  modified
  modifier {
    ...FullUser
  }
  note
  text
  toUser {
    ...FullUser
  }
  type
}
