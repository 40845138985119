import {
  Box,
  Link,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  Typography,
} from "@mui/material";
import { MdsOpenInNewXs } from "@sinch/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { StaffAttendanceFragment } from "../queries.types";
import { AttendanceRow } from "./AttendanceRow";

interface ShiftAttendanceTableProps {
  attendances: StaffAttendanceFragment[];
  count: number;
  workerId: string;
}

export const ShiftAttendanceTable: FC<ShiftAttendanceTableProps> = ({ attendances, count, workerId }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <TableContainer>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            gap: 1,
            mb: 1,
            ml: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 700,
            }}
            variant="subtitle1"
          >
            {count}
          </Typography>
          <Typography variant="subtitle1">{t("ShiftTimeline.shiftsTotal", { count })}</Typography>
        </Box>
        <Table padding="none">
          <TableHead>
            <AttendanceTableRow>
              <TableCell colSpan={2} size="small" width={150}>
                {t("ShiftTimeline.shiftName")}
              </TableCell>
              <TableCell size="small">
                <Stack
                  direction="column"
                  sx={{
                    alignItems: "start",
                    justifyItems: "center",
                  }}
                >
                  {t("dateAndTime")}
                </Stack>
              </TableCell>
              <TableCell size="small">
                <Stack
                  direction="row"
                  spacing={0.5}
                  sx={{
                    alignItems: "center",
                    justifyItems: "center",
                  }}
                >
                  {t("NewStaffMemberForm.staff")}
                </Stack>
              </TableCell>
            </AttendanceTableRow>
          </TableHead>
          <TableBody>
            {attendances.map((attendance) => (
              <AttendanceRow key={attendance.id} attendance={attendance} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Link
        href={`/admin/workers/view/${workerId}#attendances`}
        sx={{ py: 1, px: 2, justifyContent: "right", display: "flex", alignItems: "center" }}
        target="_blank"
      >
        {t("showAll")} <MdsIcon fontSize="xs" icon={MdsOpenInNewXs} sx={{ ml: 0.5 }} />
      </Link>
    </Stack>
  );
};
export const AttendanceTableRow = styled(TableRow)<TableRowProps>(({ theme: { spacing } }) => ({
  padding: spacing(0, 1),
  "& .MuiTableCell-root": {
    padding: spacing(0, 0.5),
  },
  "& .MuiTableCell-root:first-of-type": {
    paddingLeft: spacing(2),
  },
}));
