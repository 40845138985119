import { RefObject, useEffect, useRef, useState } from "react";

/**
 * Hook for checking if component has overflown content
 */
export const useOverflowObserver = (containerRef: RefObject<HTMLElement>) => {
  const [isOverflown, setIsOverflown] = useState(false);

  const resizeObserver = useRef<ResizeObserver | null>(null);
  const lastWidth = useRef<number>(0);

  useEffect(() => {
    if (containerRef.current) {
      resizeObserver.current = new ResizeObserver((entries) => {
        if (lastWidth.current !== entries[0].contentRect.width) {
          lastWidth.current = entries[0].contentRect.width;
          setIsOverflown(entries[0].target.scrollWidth > entries[0].target.clientWidth);
        }
      });
      resizeObserver.current.observe(containerRef.current);
    }
  }, []);
  return isOverflown;
};
