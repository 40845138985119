import { CircularProgress, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { MdsHowToRegXs } from "@sinch/icons";
import { enqueueSnackbar } from "notistack";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "@sinch/components";
import { useAttendance } from "@sinch/components/application/Attendace/useAttendance";

interface ResendInvitationMenuItemProps {
  loading: boolean;
  handleClose: () => void;
  attendanceId: string;
  onLoading?: (loading: boolean) => void;
}

export const ConfirmInvitationMenuItem: FC<ResendInvitationMenuItemProps> = ({
  loading,
  handleClose,
  attendanceId,
  onLoading,
}) => {
  const { t } = useTranslation();
  const { confirmInvitation } = useAttendance();

  const handleConfirmInvitation = async () => {
    onLoading?.(true);
    try {
      await confirmInvitation(attendanceId, true);
    } catch (e) {
      enqueueSnackbar(t("Error.anErrorHasOccurred"), { variant: "error" });
    }
    onLoading?.(false);
    handleClose();
  };

  return (
    <MenuItem disabled={loading} onClick={handleConfirmInvitation}>
      <ListItemIcon>
        {loading ? <CircularProgress size={16} thickness={5} /> : <MdsIcon fontSize="small" icon={MdsHowToRegXs} />}
      </ListItemIcon>
      <ListItemText>{t("Attendance.confirmInvitation")}</ListItemText>
    </MenuItem>
  );
};
