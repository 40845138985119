import { Grid, GridProps, InputLabel, InputLabelProps, styled, Switch } from "@mui/material";
import React, { ReactElement, ReactNode } from "react";
import { InfoTooltip } from "../LabelWithInfo/InfoTooltip";
import { LabelWithInfoProps } from "../LabelWithInfo/LabelWithInfo";

interface SwitchableLabelProps extends InputLabelProps {
  checked?: boolean;
}

/**
 * Styled label change text color by state
 */
const SwitchableLabel = styled(InputLabel, {
  shouldForwardProp: (prop) => prop !== "checked",
})<SwitchableLabelProps>(({ checked, theme }) => {
  const textColor = checked ? theme.palette.grey[800] : theme.palette.secondary.dark;
  return {
    color: textColor,
    cursor: "pointer",
    maxWidth: "100%",
    "&.Mui-focused": {
      color: textColor,
    },
    "&.Mui-error": {
      color: textColor,
    },
    "&.Mui-focused.Mui-error": {
      color: textColor,
    },
  };
});

export enum SwitchableLabelState {
  Left = "left",
  Right = "right",
}

export interface SwitchLabelProps extends Omit<LabelWithInfoProps, "onChange" | "children" | "sx"> {
  /**
   * `Left label
   */
  labelLeft?: ReactNode;
  /**
   * Right label
   */
  labelRight?: ReactNode;
  /**
   * State of switch
   */
  switchState?: SwitchableLabelState;
  /**
   * Callback fired when worker click on switch or label
   */
  onChange?: (state: SwitchableLabelState, checked: boolean) => void;
  /**
   * Sx styles applied to the root element
   */
  sx?: GridProps["sx"];
}

/**
 * Label with switcher, switch between two states and provide value and onChange props, so it can be used as regular input
 */
export function SwitchLabel({
  labelRight,
  labelLeft,
  switchState = SwitchableLabelState.Left,
  infoTooltip,
  onChange,
  color,
  disabled,
  required,
  sx,
  ...props
}: SwitchLabelProps): ReactElement {
  const isChecked = switchState === SwitchableLabelState.Right;
  return (
    <Grid
      container
      direction="row"
      spacing={1}
      sx={[
        {
          flexWrap: "nowrap",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Grid item xs="auto">
        <SwitchableLabel
          {...props}
          checked={isChecked}
          disabled={disabled}
          onClick={() => onChange?.(SwitchableLabelState.Left, false)}
          required={false}
        >
          {labelLeft}
        </SwitchableLabel>
      </Grid>
      <Grid item xs="auto">
        <Switch
          checked={isChecked}
          color={color}
          disabled={disabled}
          onChange={(_, checked) =>
            onChange?.(checked ? SwitchableLabelState.Right : SwitchableLabelState.Left, checked)
          }
          size="mini"
          sx={{ "&": { marginBottom: "4px" } }}
          tabIndex={-1}
        />
      </Grid>
      <Grid item xs="auto">
        <SwitchableLabel
          {...props}
          checked={!isChecked}
          disabled={disabled}
          onClick={() => onChange?.(SwitchableLabelState.Right, false)}
          required={required}
        >
          {labelRight}
        </SwitchableLabel>
      </Grid>
      {infoTooltip && (
        <Grid item xs="auto">
          <InfoTooltip title={infoTooltip} />
        </Grid>
      )}
    </Grid>
  );
}
