import { Menu } from "@mui/material";
import {
  AttendanceActionEnum,
  AttendanceReconfirmationStatusEnum,
  AttendanceStatusEnum,
} from "@sinch/core/entities/serverEnums";
import { includes, isEmpty } from "ramda";
import React, { FC, useState } from "react";
import { ConfirmInvitationMenuItem } from "@sinch/components/application/ShiftDetailPanel/ActionMenu/ConfirmInvitationMenuItem";
import { ConfirmMessageMenuItem } from "@sinch/components/application/ShiftDetailPanel/ActionMenu/ConfirmMessageMenuItem";
import { ResendInvitationMenuItem } from "@sinch/components/application/ShiftDetailPanel/ActionMenu/ResendInvitationMenuItem";
import { SignOffMenuItem } from "@sinch/components/application/ShiftDetailPanel/ActionMenu/SignOffMenuItem";

export interface AttendanceActionMenuProps {
  event: React.MouseEvent<HTMLElement, MouseEvent>;
  attendanceId: string;
  allowedActions: AttendanceActionEnum[];
  onUpdate?: () => void;
  preventDefault?: boolean;
}

interface MousePosition {
  x: number;
  y: number;
}

interface ActionMenuProps {
  attendanceId: string;
  allowedActions: AttendanceActionEnum[];
  anchorEl: HTMLElement | MousePosition | null;
  onClose: () => void;
  attendanceStatus?: AttendanceStatusEnum;
  attendanceReconfirmationStatus?: AttendanceReconfirmationStatusEnum;
}

export const ActionMenu: FC<ActionMenuProps> = ({
  attendanceId,
  allowedActions,
  anchorEl,
  onClose: handleClose,
  attendanceStatus,
  attendanceReconfirmationStatus,
}) => {
  const [loading, setLoading] = useState<string | null>(null);
  const open = Boolean(anchorEl);

  const isHtmlElement = (anchor: HTMLElement | MousePosition | null): anchor is HTMLElement =>
    anchor instanceof HTMLElement;
  const isCoordinates = (anchor: HTMLElement | MousePosition | null): anchor is MousePosition =>
    !(anchor instanceof HTMLElement) && anchorEl !== null;

  return (
    <Menu
      anchorEl={isHtmlElement(anchorEl) ? anchorEl : undefined}
      anchorOrigin={isHtmlElement(anchorEl) ? { horizontal: "right", vertical: "bottom" } : undefined}
      anchorPosition={isCoordinates(anchorEl) ? { top: anchorEl.y + 8, left: anchorEl.x + 8 } : undefined}
      anchorReference={isCoordinates(anchorEl) ? "anchorPosition" : "anchorEl"}
      onClose={loading ? undefined : handleClose}
      open={open && !isEmpty(allowedActions)}
      transformOrigin={isHtmlElement(anchorEl) ? { horizontal: "right", vertical: "top" } : undefined}
    >
      {includes(AttendanceActionEnum.ResendInvitation, allowedActions) && (
        <ResendInvitationMenuItem
          attendanceId={attendanceId}
          handleClose={handleClose}
          loading={loading === "resendInvitation"}
          onLoading={() => setLoading("resendInvitation")}
        />
      )}
      {attendanceStatus === AttendanceStatusEnum.ConfirmationPending && (
        <ConfirmInvitationMenuItem
          attendanceId={attendanceId}
          handleClose={handleClose}
          loading={loading === "confirmInvitation"}
          onLoading={() => setLoading("confirmInvitation")}
        />
      )}
      {attendanceReconfirmationStatus === AttendanceReconfirmationStatusEnum.Pending && (
        <ConfirmMessageMenuItem
          attendanceId={attendanceId}
          handleClose={handleClose}
          loading={loading === "confirmMessage"}
          onLoading={() => setLoading("confirmMessage")}
        />
      )}
      {includes(AttendanceActionEnum.SignOff, allowedActions) && (
        <SignOffMenuItem
          attendanceId={attendanceId}
          handleClose={handleClose}
          loading={loading === "signOff"}
          onLoading={() => setLoading("signOff")}
        />
      )}
    </Menu>
  );
};
