// eslint-disable-next-line import/no-internal-modules
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Tooltip } from "@mui/material";
import { MdsFormatListBulletedFillXs } from "@sinch/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { ToggleToolbarButton } from "../components/ToolbarButton";
import { useUnorderedList } from "../hooks/useBlock";

export const UnorderedList: FC = () => {
  const { t } = useTranslation();
  const [toggle, isBlock] = useUnorderedList();

  const [editor] = useLexicalComposerContext();
  const disabled = !editor.isEditable();
  return (
    <Tooltip placement="top" title={t("TextEditor.numberedList")}>
      <ToggleToolbarButton disabled={disabled} onClick={toggle} selected={isBlock} tabIndex={-1} value="list">
        <MdsIcon fontSize="xs" icon={MdsFormatListBulletedFillXs} />
      </ToggleToolbarButton>
    </Tooltip>
  );
};
