import { styled } from "@mui/material";
import { asUploadButton, UploadButtonProps } from "@rpldy/upload-button";
import React, { ButtonHTMLAttributes, forwardRef } from "react";

const Btn = forwardRef<HTMLButtonElement, ButtonHTMLAttributes<HTMLButtonElement>>((props, ref) => (
  <button type="button" {...props} ref={ref} />
));
Btn.displayName = "SimpleUploadButton";

export const SimpleUploadButton = styled(asUploadButton(Btn))<UploadButtonProps>(({ theme }) => ({
  border: "none",
  background: "none",
  padding: 0,
  color: theme.palette.primary.light,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));
