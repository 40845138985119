import { mapCrewbossTranslations } from "@sinch/utils/translations";
import React from "react";
import ReactDOM from "react-dom/client";
import { dictionary } from "../locale";
import { App } from "./App";
// WARNING! Do not change these imports, for some reason storybook stop working when short imports are used
// eslint-disable-next-line import/no-internal-modules
import { initIntl } from "./config/i18n";
// eslint-disable-next-line import/no-internal-modules
import { captureError, initErrorMonitor } from "./config/sentry";
// eslint-disable-next-line import/no-internal-modules
import "./config/customValidationRules";

// Top level services initialisation
initErrorMonitor();
initIntl(dictionary);

/**
 * React entrypoint TODO: set by env
 */
const domContainer = document.querySelector<HTMLElement>("#sinch-react-runtime");

/**
 * Initialisation method of application
 */
function init() {
  if (domContainer) {
    const root = ReactDOM.createRoot(domContainer);
    const settings = domContainer.dataset?.settings;
    const settingsObject = settings ? JSON.parse(settings) : null;
    settingsObject.crewbossName = mapCrewbossTranslations(settingsObject.crewbossName);

    initIntl(dictionary, settingsObject?.language ?? "en");

    root.render(<App {...settingsObject} />);
  } else {
    captureError("React runtime entrypoint not found!");
  }
}

init();
