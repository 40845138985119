// @ts-ignore for testing
import util from "../../util";
// @ts-ignore for testing
import Component from "./Component";

type NestedDom = Record<string, any>;

/**
 * A filter are top left of timeline
 */
export class FilterArea extends Component {
  private body: NestedDom | null;

  protected defaultOptions: Record<string, any>;

  public options: Record<string, any>;

  /**
   * @constructor FilterArea
   * @extends Component
   */
  constructor(body: NestedDom, options: Record<string, any>) {
    super();
    this.body = body;

    // default options
    this.defaultOptions = {
      showFilterArea: true,
    };
    this.options = util.extend({}, this.defaultOptions);
    this.setOptions(options);

    this._create();
  }

  /**
   * Create the HTML DOM for the current filter area
   * @private
   */
  _create() {}

  /**
   * Destroy the FilterArea bar
   */
  destroy() {
    this.options.showFilterArea = false;
    this.redraw(); // will remove the bar from the DOM and stop refreshing

    this.body = null;
  }

  /**
   * Set options for the component. Options will be merged in current options.
   * @param {Object} options  Available parameters:
   *                          {boolean} [showCurrentTime]
   *                          {String}  [alignCurrentTime]
   */
  setOptions(options: Record<string, any>) {
    if (options) {
      // copy all options that we know
      util.selectiveExtend(["filterAreaTemplate", "showFilterArea"], this.options, options);
    }
  }

  /**
   * Repaint the component
   * @return {boolean} Returns true if the component is resized
   */
  redraw() {
    if (this.options.showFilterArea && this.body) {
      const parent = this.body.dom.topLeftContainer;

      let content;
      if (this.options.filterAreaTemplate) {
        const templateFunction = this.options.filterAreaTemplate.bind(this);
        content = templateFunction(parent);
      } else {
        content = "";
      }

      if (content instanceof Object && !(content instanceof Element)) {
        const templateFunction = this.options.filterAreaTemplate.bind(this);
        templateFunction(parent);
      } else {
        // only replace the content when changed
        if (content instanceof Element) {
          parent.innerHTML = "";
          parent.appendChild(content);
        } else if (content != undefined) {
          parent.innerHTML = util.xss(content);
        }
      }
    }

    return false;
  }
}
