import { has } from "ramda";
import { useCallback } from "react";
import { InferType, Schema, ValidationError } from "yup";

/**
 * Sanitize object by schema, used to receive valid object from store for cases where data definitions can change.
 * The external store data wouldn't be invalidated or versioned then.
 */
export const useSanitizationFromExternalStore = <TSchema extends Schema = Schema>(schema: TSchema) => {
  const sanitize: any = useCallback(
    (data: any): InferType<Schema> => {
      try {
        // Parse the value as JSON
        const cleanedValue = schema.validateSync(data, { stripUnknown: true });
        return removeEmptyObjects(cleanedValue); // Replace the original value with the validated value
      } catch (error) {
        if (error instanceof ValidationError) {
          console.error(`Failed to validate field ${error.path}: ${error.message}`);
        }
      }
      return null;
    },
    [schema]
  );

  return sanitize;
};

/**
 * Remove empty object leafs from object
 */
const removeEmptyObjects = (obj: any): any => {
  const result: any = {};
  for (const prop in obj) {
    if (has(prop, obj)) {
      if (typeof obj[prop] !== "object" || Object.keys((obj as Record<string, any>)[prop]).length !== 0) {
        result[prop] = obj[prop];
      }
    }
  }
  return result;
};
