import { TransportAttendanceRole } from "@sinch/core/entities/serverEnums";
import { MdsLuggageFillXs, MdsNoLuggageXs, SinchDriverXs } from "@sinch/icons";
import React from "react";
import { MdsIcon, MdsIconProps } from "../../MdsIcon/MdsIcon";

interface TransportRoleIconProps extends Omit<MdsIconProps, "icon"> {
  role: TransportAttendanceRole;
}

export const TransportRoleIcon: React.FC<TransportRoleIconProps> = ({ role, ...props }) => {
  if (role === TransportAttendanceRole.Driver) {
    return (
      <MdsIcon
        fontSize="small"
        icon={SinchDriverXs}
        sx={{ color: ({ palette }) => palette.custom.blue["900"] }}
        {...props}
      />
    );
  }
  if (role === TransportAttendanceRole.Passenger) {
    return (
      <MdsIcon
        fontSize="small"
        icon={MdsLuggageFillXs}
        sx={{ color: ({ palette }) => palette.custom.blue["900"] }}
        {...props}
      />
    );
  }
  return (
    <MdsIcon
      fontSize="small"
      icon={MdsNoLuggageXs}
      sx={{ color: ({ palette }) => palette.custom.blue["900"] }}
      {...props}
    />
  );
};
