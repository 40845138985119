import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { StatusLabelPill } from "./StatusLabelPill";

export const SuccessStatusPill = styled(StatusLabelPill)(() => {
  const theme = useTheme();
  return {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
  };
});
