fragment AttendanceShiftPanelDetail on Attendance {
  arrival
  departure
  wage
  id
  allowedActions
  transportAttendances {
    id
    role
    trip {
      direction
    }
  }
  status
  reconfirmationStatus
  rating {
    amount
  }
  worker {
    avatar(variant: THUMB_500_500) {
      url
    }
    id
    name
    surname
    workerinfo {
      rating
    }
    phone
  }
}

fragment PositionShiftPanelDetail on Position {
  beginning
  end
  id
  name
  profession {
    name
  }
  positionLocation {
    place {
      name
    }
  }
  role
  status
  cancelled
  attendances {
    ...AttendanceShiftPanelDetail
  }
  applicantSize
  applicants {
    id
    worker {
      id
      name
      surname
      phone
      avatar(variant: THUMB_200_200) {
        url
      }
      workerinfo {
        rating
        verified
      }
    }
  }
  tags {
    id
    name
  }
  requirements {
    id
    text
  }
  size
}

fragment ShiftPanelDetail on Shift {
  id
  clientNote
  name
  transportTrips {
    direction
    beginning
    end
    meetingTime
    place {
      name
    }
    distance
  }
  contactList {
    name
    phone
    id
  }
  description
  crewbossDescription
  job {
    id
    name
    order {
      id
      name
      number
      quote
      provisional
      company {
        id
        name
      }
    }
  }
  positions(filter: { cancelled: { IN: [true, false] } }) {
    ...PositionShiftPanelDetail
  }
}

query ShiftDetail($id: ID!) {
  shift(id: $id) {
    ...ShiftPanelDetail
  }
}

mutation TransportRoleChange($transportTripIds: [ID!]!, $role: TransportAttendanceRole!) {
  switchTransportAttendanceRole(transportAttendanceIds: $transportTripIds, role: $role) {
    messages {
      message
      severity
    }
    payload {
      id
      role
    }
    result
  }
}
