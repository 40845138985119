import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MdsContentCopy, MdsDelete, MdsEdit, MdsMoreVert } from "@sinch/icons";
import React, { FC } from "react";
import { ArrayPath, FieldArrayWithId, FieldValues, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../MdsIcon/MdsIcon";
import { FieldArrayProvider } from "../providers/FieldArrayProvider";
import { EditRequirementRow } from "./EditRequirementRow";
import { useRequirementRow } from "./hooks";
import { PositionRequirementFormFields } from "./types";

interface RequirementTableMobileProps<FormFields extends FieldValues, RequirementPath extends ArrayPath<FormFields>> {
  prefix: string;
  editedFieldIx: number | null;
  setEditedFieldIx: (ix: number | null) => void;
  fields: FieldArrayWithId<PositionRequirementFormFields>[];
  fieldArrayMethods: Omit<ReturnType<typeof useFieldArray<FormFields, RequirementPath>>, "fields">;
}
export const RequirementTableMobile = <FormFields extends FieldValues, RequirementPath extends ArrayPath<FormFields>>({
  prefix,
  editedFieldIx,
  setEditedFieldIx,
  fieldArrayMethods,
  fields,
}: RequirementTableMobileProps<FormFields, RequirementPath>) => (
  <TableContainer>
    {fields.map(({ id }, ix) => (
      <FieldArrayProvider<FormFields, RequirementPath>
        key={id}
        methods={fieldArrayMethods}
        parentIndex={ix}
        prefix={`${prefix}.requirements.${ix}` as `${RequirementPath}.${number}`}
      >
        {editedFieldIx == ix ? (
          <EditRequirementRow onClose={() => setEditedFieldIx(null)} />
        ) : (
          <RequirementTableMobileRow key={id} onEdit={() => setEditedFieldIx(ix)} />
        )}
      </FieldArrayProvider>
    ))}
  </TableContainer>
);

const RequirementTableMobileRow: FC<{ onEdit: () => void }> = ({ onEdit }) => {
  const { t } = useTranslation();
  const {
    labels: { expiration, operator, percentage, requirement, subject, value },
    values: { expiration: expirationValue },
    copy,
    remove,
  } = useRequirementRow();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={2} size="small">
            <Grid
              container
              direction="row"
              sx={{
                alignItems: "center",
              }}
            >
              <Grid item xs>
                <Grid
                  container
                  direction="column"
                  spacing={0.5}
                  sx={{ color: ({ palette: { custom } }) => custom.jet[500] }}
                >
                  <Grid
                    item
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Typography variant="descriptionSemi">{requirement}</Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{
                      display: "flex",
                    }}
                  >
                    <Typography>
                      <Typography variant="descriptionSmallSemi">{`${subject} `}</Typography>
                      <Typography variant="descriptionSmall">
                        {operator} {value}
                      </Typography>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs="auto">
                <IconButton onClick={handleClick}>
                  <MdsIcon fontSize="small" icon={MdsMoreVert} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  onClose={handleClose}
                  open={open}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onEdit();
                    }}
                  >
                    <ListItemIcon color="primary">
                      <MdsIcon color="primary" fontSize="small" icon={MdsEdit} />
                    </ListItemIcon>
                    <ListItemText>{t("Action.edit")}</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      copy();
                    }}
                  >
                    <ListItemIcon color="primary">
                      <MdsIcon color="primary" fontSize="small" icon={MdsContentCopy} />
                    </ListItemIcon>
                    <ListItemText>{t("Action.copy")}</ListItemText>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      remove();
                    }}
                  >
                    <ListItemIcon color="error">
                      <MdsIcon color="error" fontSize="small" icon={MdsDelete} />
                    </ListItemIcon>
                    <ListItemText>{t("Action.remove")}</ListItemText>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell scope="row" sx={{ whiteSpace: "nowrap" }}>
            {t("NewJobForm.requiredFor")}
          </TableCell>
          <TableCell scope="row" width="100%">
            {t("NewJobForm.offStaff", { percent: percentage })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell scope="row" sx={{ whiteSpace: "nowrap" }}>
            {t("NewJobForm.expiration")}
          </TableCell>
          <TableCell scope="row" width="100%">
            {expirationValue === 0 ? t("none") : `${expiration} ${t("NewJobForm.beforeShiftStart")}`}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
