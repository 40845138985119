import { Stack, StackProps, Theme } from "@mui/material";
import { SystemCssProperties } from "@mui/system";
import React, { FC, PropsWithChildren } from "react";
import { MdsIconProps, MdsIcon } from "../MdsIcon/MdsIcon";

interface IconListRowProps extends PropsWithChildren {
  containerProps?: StackProps;
  spacing?: StackProps["spacing"];
  icon: MdsIconProps["icon"];
  iconSize?: MdsIconProps["fontSize"];
  color?: SystemCssProperties<Theme>["color"];
}

export const IconListRow: FC<IconListRowProps> = ({
  children,
  containerProps,
  icon,
  iconSize = "small",
  spacing = 0.5,
  color,
}) => (
  <Stack
    direction="row"
    spacing={spacing}
    {...containerProps}
    sx={[
      {
        alignItems: "center",
        mt: 1,
      },
      ...(Array.isArray(containerProps?.sx) ? containerProps.sx : [containerProps?.sx ?? {}]),
    ]}
  >
    <MdsIcon fontSize={iconSize} icon={icon} sx={{ color: color ?? (({ palette }) => palette.grey["800"]) }} />
    {children}
  </Stack>
);
