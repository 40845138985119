import { DateTime, Duration, DurationLike, Interval } from "luxon";
import { pick } from "ramda";
import { toLuxon } from "./toLuxon";

export const getIntervalAroundPoint = (intervalSize: DurationLike) => (date: Date | DateTime) => {
  const intervalDuration = Duration.fromDurationLike(intervalSize);
  const halfDuration = Duration.fromMillis(intervalDuration.toMillis() / 2);
  return Interval.fromDateTimes(toLuxon(date).minus(halfDuration), toLuxon(date).plus(halfDuration));
};

export const shiftIntervalByMinMax = (min: DateTime, max: DateTime) => (interval: Interval) => {
  const minMaxInterval = Interval.fromDateTimes(min, max);
  const intersectionInterval = minMaxInterval.intersection(interval);

  if (intersectionInterval && intersectionInterval.length() === interval.length()) {
    return interval;
  }

  if (minMaxInterval.length() <= interval.length()) {
    return minMaxInterval;
  }

  if (interval.start && interval.start <= min) {
    return Interval.fromDateTimes(min, min.plus(interval.toDuration()));
  }

  if (interval.end && interval.end >= min) {
    return Interval.fromDateTimes(max.minus(interval.toDuration()), max);
  }

  throw new Error("This should never happen");
};

const roundDateMinutes = (stepSizeInMinutes: number) => (date: DateTime) =>
  toLuxon(date).set({ minute: date.minute - (date.minute % stepSizeInMinutes) });

export const roundIntervalMinutes = (stepSizeInMinutes: number) => (interval: Interval) =>
  interval.mapEndpoints(roundDateMinutes(stepSizeInMinutes));

export const getStepsForInterval =
  (stepSize: DurationLike) =>
  ({ start, end }: Interval): DateTime[] => {
    if (!start || !end) {
      return [];
    }

    const dateList = [];

    for (let currDate = start; currDate <= end; currDate = currDate.plus(stepSize)) {
      dateList.push(currDate);
    }

    return dateList;
  };

export const stripDateList = (min: DateTime, max: DateTime) => (dateList: DateTime[]) => {
  const interval = Interval.fromDateTimes(min, max);
  return dateList.filter((date) => interval.contains(date));
};

/**
 * Limit date to min and max time, try to modify only date to fit in interval if possible, otherwise, convert to nearest
 * possible date in interval
 */
export const fitToInterval = (start: Date, end: Date, target: Date | null) => {
  if (!start || !end || !target) {
    return target;
  }
  const dateRange = Interval.fromDateTimes(start, end);
  const dateObj = pick(["year", "month", "day"])(DateTime.fromJSDate(start).toObject());
  const dateTimeTarget = DateTime.fromJSDate(target);
  const dateModifiedDateTimeTarget = dateTimeTarget.set(dateObj);

  if (dateRange.contains(dateModifiedDateTimeTarget)) {
    return dateModifiedDateTimeTarget.toJSDate();
  } else {
    if (start.getTime() > target.getTime()) {
      return start;
    } else if (end.getTime() < target.getTime()) {
      return end;
    }
  }
  return target;
};
