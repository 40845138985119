import { ThemeProvider } from "@mui/material";
/* eslint-disable import/no-internal-modules */
import { createTheme } from "@mui/material/styles";
import { theme } from "@sinch/core/config/theme";
import React, { FC, PropsWithChildren } from "react";

export const componentModTheme = createTheme(theme, {
  //shadows: baseTheme.shadows.map(() => "none") as Shadows,
  components: {
    MuiInputBase: {
      defaultProps: {
        inputProps: {
          form: "nosubmit",
          dir: "auto",
        },
      },
    },
  },
});
/**
 * Add to inputs a inputProp for prevent the form to be attached to the form, used if you need hide some form fields from parent form
 */
export const DetachedFormFieldsProvider: FC<PropsWithChildren> = ({ children }) => (
  <ThemeProvider theme={componentModTheme}>{children}</ThemeProvider>
);
