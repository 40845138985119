import { forceBoundary } from "@sinch/utils/math";
import { removeExceptFirst } from "@sinch/utils/string";
import { ChangeEvent, useCallback, useState } from "react";

/**
 * Interface representing the properties for the useNumberInputValue hook.
 */
interface UseNumberInputValueProps {
  /**
   * The current value of the input.
   */
  value: unknown;

  /**
   * Whether to allow empty input.
   */
  allowEmpty?: boolean;

  /**
   * Callback function to handle changes to the input value.
   */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;

  /**
   * The minimum value allowed for the input. No limit if undefined
   */
  min?: number;

  /**
   * The maximum value allowed for the input. No limit if undefined
   */
  max?: number;
}

interface UseNumberInputReturn {
  /**
   * Function to handle changes to the input value. Force a value to be a number.
   */
  toNumber: (value: string) => string;

  /**
   * Function to handle blur events on the input field. Trigger convertion to number if field blur.
   */
  toClosestLimit: (value: string) => string;

  /**
   * The current value of the input.
   */
  value: unknown;
}
/**
 * Custom hook to manage the value of a number input field.
 */
export const useNumberInputValue = ({
  value,
  allowEmpty,
  onChange,
  min,
  max,
}: UseNumberInputValueProps): UseNumberInputReturn => {
  const [empty, setEmpty] = useState(false);

  const toNumber = useCallback(
    (val: string) => {
      if (val === "") {
        setEmpty(true);
        if (allowEmpty) {
          return val;
        }
        // special case for number input, if just dot, add zero before
      } else if (val === ".") {
        setEmpty(false);
        return "0.";
      } else {
        setEmpty(false);
        return removeExceptFirst(val.replace(/[^\d.-]/g, ""), ".").replace(/(?!^)-/g, "");
      }
      return val;
    },
    [allowEmpty, onChange]
  );

  const toClosestLimit = useCallback(
    (val: string) => {
      if (allowEmpty && val === "") {
        return val;
      }
      if (val === "") {
        setEmpty(false);
        return String(min || 0);
      } else {
        setEmpty(false);
        return String(forceBoundary(min, max)(+val));
      }
    },
    [allowEmpty, min, max, onChange]
  );

  return {
    toNumber,
    toClosestLimit,
    value: empty ? "" : value,
  };
};
