import useResizeObserver from "use-resize-observer";

const defaultBreakpoints = {
  xs: 0,
  sm: 60,
  md: 150,
  lg: 300,
  xl: 600,
} as const;

export interface UseContainerQueriesProps<
  TBreakpointThresholds extends Record<string, number> = typeof defaultBreakpoints,
> {
  /**
   * Container for checking size
   */
  container: HTMLElement;
  /**
   * List of breakpoints, if size of container is between some breakpoints, hook return key of lower boundary
   * @default {
   *     xs: 0,
   *     sm: 60,
   *     md: 150,
   *     lg: 300,
   *     xl: 600,
   *   }
   */
  breakpoints?: TBreakpointThresholds;
}

/**
 * Simulate css container queries
 */
export const useContainerQueries = <
  TBreakpointThresholds extends Record<string, number> = typeof defaultBreakpoints,
  TBreakpoints extends keyof TBreakpointThresholds = keyof typeof defaultBreakpoints,
>({
  container,
  breakpoints,
}: UseContainerQueriesProps<TBreakpointThresholds>): TBreakpoints | null => {
  const breakpointsObj = breakpoints ?? defaultBreakpoints;

  const { width } = useResizeObserver<HTMLElement>({
    ref: container,
  });

  if (!width) {
    return null;
  }

  // Sort breakpoints by width in descending order
  const breakpointsSorted = (Object.entries(breakpointsObj) as [TBreakpoints, number][]).sort((a, b) => b[1] - a[1]);
  const foundBreakpoint = breakpointsSorted?.find(([, threshold]) => width > threshold)?.[0];
  const lastBreakpoint = breakpointsSorted?.slice(-1)[0][0];
  return foundBreakpoint ?? lastBreakpoint ?? null;
};
