import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ListItemText, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { MdsVariableInsertXs } from "@sinch/icons";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { ToolbarButton } from "../components/ToolbarButton";
import { INSERT_PLACEHOLDER_COMMAND } from "@sinch/components/form/TextEditor/plugins/PlaceholdersPlugin";

interface PlaceholdersProps {
  placeholders: { label: string; value: string }[];
}

export const Placeholders: FC<PlaceholdersProps> = ({ placeholders }) => {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const insertPlaceholder = useCallback(
    (placeholder: string) => {
      editor.dispatchCommand(INSERT_PLACEHOLDER_COMMAND, { placeholder });
      setAnchorEl(null);
    },
    [editor]
  );

  const disabled = !editor.isEditable();

  return (
    <div>
      <Tooltip placement="top" title={t("TextEditor.placeholders")}>
        <ToolbarButton
          disabled={disabled}
          onClick={handleOpen}
          //selected={isPlaceholder}
          tabIndex={-1}
          value="placeholder"
        >
          <MdsIcon fontSize="xs" icon={MdsVariableInsertXs} />
        </ToolbarButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        disablePortal
        onClose={handleClose}
        open={open}
        slotProps={{ paper: { sx: { minWidth: "240px" } } }}
      >
        {placeholders.map(({ value, label }) => (
          <MenuItem key={value} onClick={() => insertPlaceholder(value)}>
            <ListItemText>{label}</ListItemText>
            <Typography sx={{ color: "text.secondary" }} variant="body2">
              [[{value}]]
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
