// eslint-disable-next-line import/no-internal-modules
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import { MdsExpandMoreFillXs, MdsFormatH1, MdsFormatH2, MdsFormatH3, MdsFormatQuote, MdsSubject } from "@sinch/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { ToolbarButton } from "../components/ToolbarButton";
import { useBlockType, useHeading, useParagraph, useQuote } from "../hooks/useBlock";

const useBlockTypeToBlockName = (blockType: string) => {
  const { t } = useTranslation();
  return (
    {
      bullet: t("TextEditor.bulletedList"),
      check: t("TextEditor.checkList"),
      code: t("TextEditor.codeBlock"),
      h1: t("TextEditor.heading1"),
      h2: t("TextEditor.heading2"),
      h3: t("TextEditor.heading3"),
      h4: t("TextEditor.heading4"),
      h5: t("TextEditor.heading5"),
      h6: t("TextEditor.heading6"),
      number: t("TextEditor.numberedList"),
      paragraph: t("TextEditor.normal"),
      quote: t("TextEditor.quote"),
    }[blockType] ?? t("TextEditor.normal")
  );
};

export const BlockMenu: FC = () => {
  const [editor] = useLexicalComposerContext();

  const { t } = useTranslation();
  const blockType = useBlockType();
  const paragraph = useParagraph();
  const heading = useHeading();
  const quote = useQuote();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const disabled = !editor.isEditable();
  return (
    <div>
      <Tooltip placement="top" slotProps={{ popper: { disablePortal: true } }} title={t("TextEditor.changeBlockType")}>
        <ToolbarButton
          disabled={disabled}
          endIcon={<MdsIcon fontSize="xs" icon={MdsExpandMoreFillXs} />}
          onClick={handleClick}
          tabIndex={-1}
        >
          {useBlockTypeToBlockName(blockType)}
        </ToolbarButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} disablePortal onClose={handleClose} open={Boolean(anchorEl)}>
        <MenuItem
          disabled={disabled}
          onClick={() => {
            paragraph();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <MdsIcon fontSize="small" icon={MdsSubject} />
          </ListItemIcon>
          <ListItemText>{useBlockTypeToBlockName("normal")}</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disabled}
          onClick={() => {
            heading("h1");
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <MdsIcon fontSize="small" icon={MdsFormatH1} />
          </ListItemIcon>
          <ListItemText>{useBlockTypeToBlockName("h1")}</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disabled}
          onClick={() => {
            heading("h2");
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <MdsIcon fontSize="small" icon={MdsFormatH2} />
          </ListItemIcon>
          <ListItemText>{useBlockTypeToBlockName("h2")}</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disabled}
          onClick={() => {
            heading("h3");
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <MdsIcon fontSize="small" icon={MdsFormatH3} />
          </ListItemIcon>
          <ListItemText>{useBlockTypeToBlockName("h3")}</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disabled}
          onClick={() => {
            quote();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <MdsIcon fontSize="small" icon={MdsFormatQuote} />
          </ListItemIcon>
          <ListItemText>{useBlockTypeToBlockName("quote")}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
