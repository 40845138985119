import { Chip } from "@mui/material";
import { MdsCloseXs } from "@sinch/icons";
import React from "react";
import { AutocompleteOptions } from "../../form/AutocompleteInput/types";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { SinchEntity, useSinchEntity } from "./entitiesProperties";

export const AttendanceChip: React.FC<{
  val: AutocompleteOptions;
  index: number;
  getTagProps: any;
  variant: SinchEntity;
}> = ({ getTagProps, val, index, variant }) => {
  const { getEntityIdLabel } = useSinchEntity();
  return (
    <Chip
      {...getTagProps({ index })}
      key={val?.value || index}
      deleteIcon={<MdsIcon fontSize="xs" icon={MdsCloseXs} />}
      label={getEntityIdLabel(variant, val.value)}
    />
  );
};
