import { InputAdornment, InputBaseComponentsPropsOverrides, styled } from "@mui/material";
import { MdsLocationOnXs } from "@sinch/icons";
import React, { FC } from "react";
import { FormControlWrapper, InputWrapperProps } from "../../form/FormControlWrapper/FormControlWrapper";
import {
  NullableValueOutlinedInputProps,
  NullableValueOutlinedInput,
} from "../../form/NullableValueOutlinedInput/NullableValueOutlinedInput";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import {
  PlaceRadiusSelectInputBaseProps,
  PlaceRadiusSelectInputBase,
  PlaceRadiusValue,
} from "./PlaceRadiusSelectInputBase";

export type PlaceRadiusSelectProps = PlaceRadiusSelectInputBaseProps & InputWrapperProps;

/**
 * Place select input, for searching place within certain radius
 */
export const PlaceRadiusSelect: FC<PlaceRadiusSelectProps> = ({
  label,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  helperText,
  errorMessage,
  error,
  required,
  infoTooltip,
  fullWidth,
  value,
  onChange,
  size,
  startAdornment,
  ...props
}) => {
  const [focused, setFocused] = React.useState(false);

  return (
    <FormControlWrapper
      error={error}
      errorMessage={errorMessage}
      focused={focused}
      FormHelperTextProps={formHelperTextProps}
      fullWidth={fullWidth}
      helperText={helperText}
      infoTooltip={infoTooltip}
      label={label}
      onBlurCapture={() => setFocused(false)}
      required={required}
      {...formControlProps}
    >
      <PlaceRadiusOutlinedInput
        disabled={props.disabled}
        error={error}
        fullWidth={fullWidth}
        onChange={onChange as (val: unknown) => void}
        onFocus={() => setFocused(true)}
        required={required}
        size={size}
        slotProps={{ input: { ...props, size } as InputBaseComponentsPropsOverrides }}
        slots={{ input: PlaceRadiusSelectInputBase }}
        startAdornment={
          startAdornment ?? (
            <InputAdornment position="start">
              <MdsIcon fontSize="xs" icon={MdsLocationOnXs} />
            </InputAdornment>
          )
        }
        value={value}
      />
    </FormControlWrapper>
  );
};

const PlaceRadiusOutlinedInput = styled(NullableValueOutlinedInput)<NullableValueOutlinedInputProps<PlaceRadiusValue>>(
  ({ theme, startAdornment }) => {
    if (!startAdornment) {
      return { paddingLeft: theme.spacing(2) };
    }
    return {};
  }
);
