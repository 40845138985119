import { DependencyList, useEffect } from "react";
import { FieldPath, FieldValues, PathValue, useFormContext, useFormState } from "react-hook-form";

/**
 * update field value when dependency changes
 */
export function useUpdateValue<
  TFieldValues extends FieldValues = FieldValues,
  TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(name: TFieldName, updateValueFn: () => PathValue<TFieldValues, TFieldName>, deps: DependencyList) {
  const { setValue } = useFormContext<TFieldValues>();
  const { isLoading } = useFormState();

  useEffect(() => {
    // Do not set a value for the field while the form is still loading async default values.
    // This is because if a value is set during this loading phase, the form may assume that the field already has a value
    // and consequently, it may not load the default value.
    if (!isLoading) {
      setValue(name, updateValueFn());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...deps, isLoading]);
}
