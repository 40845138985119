import { useTheme } from "@mui/material";
import { CalendarEventTypeEnum } from "@sinch/core/entities/serverEnums";
import { useTranslation } from "react-i18next";
import { useEntityProps } from "../utilsHooks";

export const useCalendarEvent = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const calendarEvents = {
    [CalendarEventTypeEnum.JobDemand]: {
      color: theme.palette.success.main,
      label: t("Calendar.jobDemand"),
    },
    [CalendarEventTypeEnum.Unavailable]: {
      color: theme.palette.error.main,
      label: t("Calendar.unavailable"),
    },
    [CalendarEventTypeEnum.Common]: {
      color: undefined,
      label: undefined,
    },
    [CalendarEventTypeEnum.System]: {
      color: undefined,
      label: undefined,
    },
  } as Record<CalendarEventTypeEnum, { label: string | undefined; color: string | undefined }>;
  const getProp = useEntityProps(calendarEvents);

  const getCalendarEventLabel = getProp<string | undefined>("label");
  const getCalendarEventColor = getProp<string | undefined>("color");

  return { getCalendarEventLabel, getCalendarEventColor };
};
