mutation ApproveApplicant($applicantId: ID!) {
  approveApplicant(applicantId: $applicantId) {
    messages {
      message
      severity
    }
    result
    payload {
      id
      worker {
        id
      }
      position {
        id
        shift {
          id
        }
      }
    }
  }
}

mutation DeclineApplicant($applicantId: ID!, $reason: String) {
  declineApplicant(applicantId: $applicantId, reason: $reason) {
    messages {
      message
      severity
    }
    result
    payload {
      id
      worker {
        id
      }
      position {
        id
        shift {
          id
        }
      }
    }
  }
}
