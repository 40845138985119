import { IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { MdsContentCopy, MdsDelete, MdsPersonXs, MdsTimer } from "@sinch/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../MdsIcon/MdsIcon";
import { FocusTrigger } from "../providers/AutofocusProvider/FocusTrigger";
import { useFieldArrayContext } from "../providers/FieldArrayProvider";
import { useRequirementRow } from "./hooks";

export const RequirementRow: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useTranslation();
  const { prefix } = useFieldArrayContext();
  const {
    labels: { expiration, operator, percentage, requirement, subject, value },
    values: { expiration: expirationValue },
    copy,
    remove,
  } = useRequirementRow();

  return (
    <TableRow onClick={onClick}>
      <TableCell component="th" scope="row">
        <Stack
          direction="row"
          spacing={1}
          sx={{
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
            }}
          >
            <FocusTrigger target={`${prefix}requirementId`}>{requirement}</FocusTrigger>
          </Typography>
        </Stack>
      </TableCell>
      <TableCell component="th" scope="row">
        <FocusTrigger target={`${prefix}subject`}>{subject}</FocusTrigger>
      </TableCell>
      <TableCell component="th" scope="row">
        <FocusTrigger target={`${prefix}operator`}>{operator}</FocusTrigger>{" "}
        <FocusTrigger target={`${prefix}value`}>{value}</FocusTrigger>
      </TableCell>
      <TableCell component="th" scope="row">
        <FocusTrigger target={`${prefix}percentage`}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <MdsIcon fontSize="xs" icon={MdsPersonXs} sx={{ color: ({ palette }) => palette.grey[800] }} />
            <Typography>{percentage}</Typography>
          </Stack>
        </FocusTrigger>
      </TableCell>
      <TableCell component="th" scope="row">
        <FocusTrigger target={`${prefix}expiration`}>
          {expirationValue === 0 ? (
            t("none")
          ) : (
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
              }}
            >
              <MdsIcon fontSize="xs" icon={MdsTimer} sx={{ color: ({ palette }) => palette.grey[800] }} />
              <Typography
                sx={{
                  whiteSpace: "nowrap",
                }}
              >
                {expiration}
              </Typography>
            </Stack>
          )}
        </FocusTrigger>
      </TableCell>
      <TableCell component="th" scope="row">
        <Stack
          direction="row"
          spacing={1}
          sx={{
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title={t("Action.copy")}>
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                copy();
              }}
              size="small"
            >
              <MdsIcon fontSize="small" icon={MdsContentCopy} sx={{ color: "primary.light" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Action.remove")}>
            <IconButton
              color="error"
              onClick={(e) => {
                e.stopPropagation();
                remove();
              }}
              size="small"
            >
              <MdsIcon fontSize="small" icon={MdsDelete} sx={({ palette }) => ({ color: palette.error.main })} />
            </IconButton>
          </Tooltip>
        </Stack>
      </TableCell>
    </TableRow>
  );
};
