import { TOGGLE_LINK_COMMAND } from "@lexical/link";
// eslint-disable-next-line import/no-internal-modules
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Tooltip } from "@mui/material";
import { MdsLinkXs } from "@sinch/icons";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { ToggleToolbarButton } from "../components/ToolbarButton";
import { useLink, DEFAULT_LINK_URL } from "../hooks/useLink";

export const Link: FC = () => {
  const { t } = useTranslation();
  const [editor] = useLexicalComposerContext();
  const { isLink } = useLink();

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, DEFAULT_LINK_URL);
    }
  }, [editor, isLink]);
  const disabled = !editor.isEditable();

  return (
    <Tooltip placement="top" title={t("TextEditor.link")}>
      <ToggleToolbarButton disabled={disabled} onClick={insertLink} selected={isLink} tabIndex={-1} value="link">
        <MdsIcon fontSize="xs" icon={MdsLinkXs} />
      </ToggleToolbarButton>
    </Tooltip>
  );
};
