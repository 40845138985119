import { Box, Button, Stack, Typography } from "@mui/material";
import { ErrorBoundary, ErrorBoundaryProps } from "@sentry/react";
import { ErrorCoverSvg } from "@sinch/components/assets";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

/**
 * Sentry error boundary component
 */
export const SentryErrorBoundary: FC<ErrorBoundaryProps> = ({ children, ...props }) => {
  const { t } = useTranslation();

  return (
    <ErrorBoundary
      fallback={(params) => (
        <Stack
          spacing={2}
          sx={{
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "60%",
            }}
          >
            <ErrorCoverSvg />
          </Box>
          <Typography variant="h1">{t("Error.somethingWentWrong")}</Typography>
          <Typography variant="h4">{t("Error.anErrorHasOccurred")}</Typography>
          <Typography>{t("Error.weApologiseForTheInconvenience")}</Typography>
          <Typography
            sx={{
              fontSize: "10px",
            }}
          >
            {t("Error.errorId")}: {params.eventId}
          </Typography>
          <Button fullWidth={false} onClick={() => window.location.reload()} size="large" variant="contained">
            {t("Action.reloadPage")}
          </Button>
        </Stack>
      )}
      {...props}
    >
      {children}
    </ErrorBoundary>
  );
};
