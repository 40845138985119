// eslint-disable-next-line import/no-internal-modules
import { PersistedQueryManifestForGeneratingPersistedQueryIds } from "@apollo/persisted-query-lists/src";
import { IntrospectionQuery } from "graphql";
import persistedQueryManifest from "./persisted-query-manifest.json";
import schema from "./schema.json";

const persistedQueryManifestDef: PersistedQueryManifestForGeneratingPersistedQueryIds = persistedQueryManifest;
export { persistedQueryManifestDef as persistedQueryManifest };

const schemaDef = schema as unknown as IntrospectionQuery;
export { schemaDef as schema };
