import { NotificationChannelEnum } from "@sinch/core/entities/serverEnums";
import { MdsMail, MdsNotifications, MdsSms } from "@sinch/icons";
import React, { ElementType } from "react";
import { useTranslation } from "react-i18next";
import { useEntityProps } from "../utilsHooks";

export enum NotificationChannel {
  Sms = NotificationChannelEnum.Sms,
  Email = NotificationChannelEnum.Email,
  Push = NotificationChannelEnum.Push,
}

/**
 * Provide message channel properties.
 *
 * This hook returns the translated labels and corresponding icon components
 * for each message channel based on their configuration defined within the hook.
 *
 */
export const useMessageChannels = () => {
  const { t } = useTranslation();

  const channels = {
    [NotificationChannel.Sms]: {
      label: t("Messages.channelSms"),
      icon: MdsSms,
    },
    [NotificationChannel.Email]: {
      label: t("Messages.channelEmail"),
      icon: MdsMail,
    },
    [NotificationChannel.Push]: {
      label: t("Messages.channelPush"),
      icon: MdsNotifications,
    },
  } as Record<NotificationChannel, { label: string; icon: React.ElementType }>;
  const getProp = useEntityProps(channels);

  const getChannelLabel = getProp<string>("label");
  const getChannelIcon = getProp<ElementType>("icon");
  const getChannelList: () => NotificationChannel[] = () => [
    NotificationChannel.Sms,
    NotificationChannel.Email,
    NotificationChannel.Push,
  ];

  return { getChannelLabel, getChannelIcon, getChannelList };
};
