import { FullPlace as FullPlaceFragmentType } from "@sinch/core/entities/fragments.graphql";
import type { FullPlaceFragment } from "@sinch/core/entities/fragments.types";

import { useCachedFragment } from "@sinch/hooks/useCachedFragment";
import { useLazyQueryHandler } from "@sinch/hooks/useLazyQueryHandler";
import { isNil, propEq } from "ramda";
import { useEffect, useState } from "react";
import { Place, Places } from "./query.graphql";

import { PlaceQuery, PlaceQueryVariables, PlacesQuery, PlacesQueryVariables } from "./query.types";
import { usePlaceOptions } from "./usePlaceOptions";

interface UsePlaceSelectProps {
  value: string | null;
  onChange?: (val: string | number | null) => void;
}

export const usePlaceSelect = ({ value, onChange }: UsePlaceSelectProps) => {
  const { data: places, dispatch, loading } = useLazyQueryHandler<PlacesQuery, PlacesQueryVariables>(Places);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  // Try to search place in cache, if none found, try to fetch it from server
  const place = useCachedFragment<FullPlaceFragment, PlaceQuery, PlaceQueryVariables>({
    fragment: FullPlaceFragmentType,
    type: "Place",
    id: value ?? "",
    query: Place,
    variables: {
      id: value ?? "",
    },
  });

  const [currentSelectedPlace, setCurrentSelectedPlace] = useState<PlacesQuery["places"][0] | null>(place ?? null);

  const options = usePlaceOptions(places?.places, currentSelectedPlace, !loading);

  // Update current selected place if place from cache was updated
  useEffect(() => {
    setCurrentSelectedPlace(place ?? null);
  }, [place]);

  const handleChange = (val: string | number | null) => {
    const selectedPlace = places?.places.find(propEq("id", val));
    if (selectedPlace || isNil(val)) {
      onChange?.(val);
      setCurrentSelectedPlace(selectedPlace ?? null);
    }
  };

  return {
    options,
    onChange: handleChange,
    selectedPlace: currentSelectedPlace,
    dispatch,
    loading,
    isAddLocationDialogOpen: isAddDialogOpen,
    setIsAddLocationDialogOpen: setAddDialogOpen,
  };
};
