import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import type { TagVisibility } from "@sinch/types/sinch.types";
import { find, isNil, map, pipe, prop, propEq } from "ramda";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../../form/AutocompleteInput/AutocompleteInput";
import { MuiControllerObject } from "../../form/MuiController/types";
import { Tags } from "./queries.graphql";
import { TagsQuery } from "./queries.types";
import { TagOption } from "./TagOption";

type TagSelectInputProps = MuiControllerObject["field"] & {
  label: string;
};

/**
 * Select input for selecting shift tags
 */
export const TagSelectInput: FC<TagSelectInputProps> = ({ value, label, ...field }) => {
  const { t } = useTranslation<any>();

  const { data: tagsData, loading } = useQueryHandler<TagsQuery>(Tags);

  const getTagVisibilityLabel = useCallback(
    (visibility: TagVisibility) => pipe(find(propEq("id", visibility)), prop("label"))(tagsData?.tagVisibilities || []),
    [tagsData?.tagVisibilities]
  );

  const options = useMemo(
    () =>
      map(
        ({ id, name, visibility }) => ({
          value: id,
          label: name,
          option: <TagOption name={name} visibilityLabel={getTagVisibilityLabel(visibility)} />,
        }),
        tagsData?.tags || []
      ),
    [getTagVisibilityLabel, tagsData?.tags]
  );

  return (
    <AutocompleteInput
      {...field}
      disableCloseOnSelect
      filterSelectedOptions
      label={label}
      loading={loading}
      multiple
      options={options}
      placeholder={t("enterPlaceholder", { inputName: t("Tag.tags").toLowerCase() })}
      skeleton={!isNil(value) && loading}
      textFieldProps={{
        inputProps: { "data-cy": "defaultTagInput" },
      }}
      value={value}
    />
  );
};
