import { useHoldAndRepeat } from "@sinch/hooks/useHoldAndRepeat";
import { forceBoundary, forceStep } from "@sinch/utils/math";
import { isNil, pipe } from "ramda";
import { ChangeEventHandler, useMemo, useRef } from "react";

export interface UseNumberInputProps {
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  anyStep?: boolean;
  value?: number | null;
  disabled?: boolean;
  readOnly?: boolean;
  decimal?: number;
}

export const useNumberInput = ({
  onChange,
  min,
  max,
  step,
  value,
  disabled,
  readOnly,
  anyStep,
  decimal = Infinity,
}: UseNumberInputProps) => {
  const numberInputRef = useRef<HTMLInputElement>();

  const isDisabledUp = useMemo(
    () => disabled || (!isNil(max) && !isNil(value) && value >= max),
    [max, disabled, value]
  );
  const isDisabledDown = useMemo(
    () => disabled || (!isNil(min) && !isNil(value) && value <= min),
    [min, disabled, value]
  );

  const getVal = () => {
    const precision = Math.pow(10, decimal);
    return parseInt(String(+(numberInputRef?.current?.value ?? 0) * precision)) / precision;
  };
  const restrictValue = pipe(forceBoundary(min, max), forceStep(anyStep ? "any" : step));

  const longPressHoldUp = useHoldAndRepeat(
    () => {
      if (anyStep && step && numberInputRef?.current) {
        const newVal = getVal() + +step;
        numberInputRef.current.value = String(restrictValue(newVal - (newVal % +step)));
      } else {
        numberInputRef?.current?.stepUp?.();
      }
      if (!max || getVal() <= max) {
        onChange(getVal());
        return true;
      }
      return false;
    },
    { onFinish: () => onChange(getVal()) }
  );
  const longPressHoldDown = useHoldAndRepeat(
    () => {
      if (anyStep && step && numberInputRef?.current) {
        const newVal = getVal() - +step;
        numberInputRef.current.value = String(restrictValue(newVal - (newVal % +step)));
      } else {
        numberInputRef?.current?.stepDown?.();
      }
      if (!min || getVal() >= min) {
        onChange(getVal());
        return true;
      }
      return false;
    },
    { onFinish: () => onChange(getVal()) }
  );

  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => onChange(restrictValue(+e.target.value));

  return {
    inputProps: {
      onChange: handleChange,
      inputRef: numberInputRef,
      value: value ? restrictValue(value) : value,
      disabled,
      readOnly,
    },
    plusButtonProps: { ...(readOnly ? {} : longPressHoldUp), disabled: isDisabledUp },
    minusButtonProps: { ...(readOnly ? {} : longPressHoldDown), disabled: isDisabledDown },
  };
};
