import { styled } from "@mui/material";
import { GoogleMap, GoogleMapProps, MarkerF } from "@react-google-maps/api";
import { DEFAULT_MAP_LAT, DEFAULT_MAP_LNG } from "@sinch/core/config/globals";
import type { Coordinates } from "@sinch/types/common.types";

import { extractCompleteCoordinates } from "@sinch/utils/geolocation/coordinates";
import React, { FC } from "react";

export type MapCoordinatesSelectInputBaseProps = Omit<GoogleMapProps, "center" | "onClick"> & {
  mapCenter?: Coordinates | null;
  value?: Coordinates | null;
  onChange?: (coords: Coordinates) => void;
};

export const MapCoordinatesSelectInputBase: FC<MapCoordinatesSelectInputBaseProps> = ({
  mapCenter,
  value,
  onChange,
  zoom = 16,
  ...nativeMapProps
}) => {
  const centerStableRef = mapCenter ?? { lat: DEFAULT_MAP_LAT, lng: DEFAULT_MAP_LNG };

  return (
    <StyledGoogleMap
      {...nativeMapProps}
      center={centerStableRef ?? undefined}
      onClick={(e) => {
        const completeCoords = extractCompleteCoordinates({ lat: e.latLng?.lat(), lng: e.latLng?.lng() });
        if (completeCoords) onChange?.(completeCoords);
      }}
      zoom={zoom}
    >
      {value?.lat && value?.lng && <MarkerF position={value} />}
    </StyledGoogleMap>
  );
};

const StyledGoogleMap = styled(({ className, ...props }: GoogleMapProps & { className?: string }) => (
  <GoogleMap mapContainerClassName={className} {...props} />
))<GoogleMapProps>(({ theme }) => ({
  borderRadius: `${theme.shape.borderRadius}px !important`,
  borderColor: theme.palette.grey[400],
  borderWidth: "1px",
  borderStyle: "solid",
}));
