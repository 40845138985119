import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { StatusLabelPill } from "./StatusLabelPill";

const ProvisionalPillStyled = styled(StatusLabelPill)(() => {
  const theme = useTheme();
  return {
    backgroundColor: theme.palette.custom.orange["500"],
    color: theme.palette.common.white,
  };
});
export const ProvisionalPill = () => {
  const { t } = useTranslation();
  return <ProvisionalPillStyled>{t("ShiftTimeline.provisional")}</ProvisionalPillStyled>;
};
