import { getTimeStepsAroundDate } from "@sinch/utils/dateTime/getTimeStepsAroundDate";

import { DateTime } from "luxon";
import { head, indexOf, isEmpty, last } from "ramda";
import { useCallback, useMemo, useState } from "react";
import { TimeListProps } from "./types";

export const useInfiniteTimeScroll = ({
  options,
  selectedTime,
  maxTime,
  minTime,
  minutesStep = 15,
}: Omit<TimeListProps, "minWidth" | "onChange">) => {
  const [times, setTimes] = useState<DateTime[]>(options);
  const initialSelectedIndex = useMemo(
    () => (selectedTime ? findIndexByClosestTime(selectedTime, times) : 0),
    [selectedTime]
  );
  const selectedIndexOverall = useMemo(
    () => (selectedTime ? findIndexByClosestTime(selectedTime, times) : 0),
    [selectedTime, times]
  );
  // large number is required for reverse infinite scroll
  const [firstItemIndex, setFirstItemIndex] = useState(10000000);

  // Add more items to the end of the list
  const handleEndReached = useCallback(() => {
    if (isEmpty(times)) {
      return;
    }
    const newItems = getTimeStepsAroundDate(last(times), {
      stepSizeInMinutes: minutesStep,
      minTime: last(times)!.plus({ minute: minutesStep }),
      maxTime,
      rangeSizeInMinutes: 60 * 24,
    });
    if (isEmpty(newItems)) {
      return;
    }
    setTimes((currentTimes) => [...currentTimes, ...newItems]);
  }, [times, setTimes, maxTime]);

  // Add more items to the start of the list
  const handleStartReached = useCallback(() => {
    if (isEmpty(times)) {
      return;
    }
    const intervalStart = head(times)!.minus({ minute: 60 * 24 });
    const newItems = getTimeStepsAroundDate(intervalStart, {
      stepSizeInMinutes: minutesStep,
      minTime: DateTime.max(intervalStart, minTime ?? intervalStart),
      maxTime: DateTime.min(maxTime ?? head(times)!, head(times)!),
      rangeSizeInMinutes: 60 * 24,
    });

    if (isEmpty(newItems)) {
      return;
    }

    setFirstItemIndex((index) => index - newItems.length);
    setTimes((currentTimes) => [...newItems, ...currentTimes]);
  }, [times, setTimes, setFirstItemIndex, minTime, maxTime]);

  return {
    onEndReached: handleEndReached,
    onStartReached: handleStartReached,
    initialSelectedIndex,
    selectedIndexOverall,
    firstItemIndex,
    times,
  };
};

/**
 * Finds the index of the time closest to the target time in the list.
 */
const findIndexByClosestTime = (target: DateTime, list: DateTime[]) => {
  if (isEmpty(list)) {
    return 0;
  }
  return indexOf(
    list.reduce(
      (acc, option) =>
        Math.abs(option.toMillis() - target.toMillis()) < Math.abs(acc!.toMillis() - target.toMillis()) ? option : acc,
      head(list)
    ),
    list
  );
};
