import { ClickAwayListener, SlotComponentProps } from "@mui/base";
import {
  alpha,
  debounce,
  InputBase,
  InputBaseProps,
  Paper,
  Popper,
  setRef,
  TextField,
  TextFieldProps,
  Theme,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { TimeField, TimeFieldProps } from "@mui/x-date-pickers"; // eslint-disable-next-line import/no-internal-modules
import { TimeFieldOwnerState } from "@mui/x-date-pickers/TimeField/TimeField.types";
import { isDatesEquals } from "@sinch/utils/dateTime/dateFns";

import { DateTime } from "luxon";
import { omit } from "ramda";
import React, { FocusEventHandler, forwardRef, PointerEventHandler, useCallback, useState } from "react";
import { useTimeInput } from "./hooks";
import { AutocompletePaper, AutocompletePopper } from "./TimeInputSlots";
import { TimeList } from "./TimeList/TimeList";
import { TimeInputBaseProps } from "./types";

/**
 * Base time input without label, helper text etc...
 */
export const TimeInputBase = forwardRef<HTMLDivElement, TimeInputBaseProps>(
  (
    {
      AutocompleteProps: autocompleteProps = {},
      className,
      error,
      minTime,
      maxTime,
      minutesStep = 15,
      onChange,
      timePoint,
      value,
      dateInOption = true,
      disabled,
      optionsRangeInMinutes,
      inputRef: inputRefProp,
      disablePortal = true,
      anchorEl,
      referenceDate,
      timeItemsStyle,
      ...props
    },
    ref
  ) => {
    const debouncedOnChange = debounce((currentVal) => {
      onChange(currentVal);
    }, 200);

    const { options, onChangeHandler, onBlur } = useTimeInput({
      value,
      minutesStep,
      minTime,
      maxTime,
      autocompleteProps,
      onChange: debouncedOnChange,
      optionsRangeInMinutes: optionsRangeInMinutes ?? 60 * 24 * 7,
      referenceDate,
    });

    // Change to max, min value after change focus of section
    const handleChangeSectionsChange: TimeFieldProps<any>["onSelectedSectionsChange"] = () => {
      if (minTime && value && value?.valueOf() < minTime?.valueOf()) {
        debouncedOnChange(minTime);
      }
      if (maxTime && value && value?.valueOf() > maxTime?.valueOf()) {
        debouncedOnChange(maxTime);
      }
    };
    const [dropdownAnchorEl, setDropdownAnchorEl] = useState<HTMLInputElement | null>(null);
    const [focused, setFocused] = useState<boolean>(false);
    const popupOpen = Boolean(dropdownAnchorEl);

    const handleFocus: FocusEventHandler<HTMLInputElement> = (event) => {
      setFocused(true);
      setDropdownAnchorEl(event.target);
    };
    const handlePointerDown: PointerEventHandler<HTMLInputElement> = (event) => {
      if (focused) {
        setDropdownAnchorEl(event.target as HTMLInputElement);
      }
    };

    const handleChangeSelected = useCallback(
      (val: DateTime | null) => {
        setDropdownAnchorEl(null);
        onChangeHandler(val);
      },
      [onChangeHandler]
    );

    return (
      <>
        <TimeField<DateTime>
          ref={ref}
          {...omit(["as"], props)}
          disabled={disabled}
          inputProps={{
            ...props.inputProps,
            "data-cy": "time-input",
            onFocus: handleFocus,
            onBlur: () => {
              setFocused(false);
            },
            onPointerDown: handlePointerDown,
          }}
          inputRef={(inputEl) => {
            setRef<any>(inputRefProp, inputEl);
          }}
          maxTime={maxTime}
          minTime={minTime}
          onBlur={() => {
            onBlur();
          }}
          onChange={onChangeHandler}
          onSelectedSectionsChange={handleChangeSectionsChange}
          readOnly={props.readOnly}
          referenceDate={referenceDate}
          slotProps={{
            textField: {
              error: error,
              onFocus: props.onFocus,
              sx: {
                ...props.sx,
                height: "100%",
                input: {
                  height: "100%",
                  paddingTop: 0,
                  paddingBottom: 0,
                },
              },
            } as SlotComponentProps<typeof TextField, unknown, TimeFieldOwnerState<DateTime, false>>,
          }}
          slots={{ textField: TimePickerInputBase }}
          value={value}
        />
        <AutocompletePopper
          anchorEl={dropdownAnchorEl}
          as={Popper}
          className="MuiAutocomplete-popper"
          data-cy="time-input-popper"
          disablePortal={disablePortal}
          open={popupOpen && !props.readOnly}
          placement="bottom-start"
          role="presentation"
        >
          <ClickAwayListener
            onClickAway={() => {
              if (!focused) {
                setDropdownAnchorEl(null);
              }
            }}
          >
            <AutocompletePaper
              as={Paper}
              className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAutocomplete-paper"
            >
              <TimeList
                dateInOption={dateInOption}
                maxTime={maxTime}
                minTime={minTime}
                minutesStep={minutesStep}
                minWidth={dropdownAnchorEl ? dropdownAnchorEl.clientWidth : undefined}
                onChange={handleChangeSelected}
                options={options}
                selectedTime={value}
                timeItemsStyle={
                  timeItemsStyle ??
                  ((item): SxProps<Theme> => {
                    if (value && !isDatesEquals(item, value?.toJSDate())) {
                      return (theme) => ({
                        backgroundColor: alpha(theme.palette.warning.main, 0.07),
                      });
                    }
                    return null;
                  })
                }
                timePoint={timePoint}
              />
            </AutocompletePaper>
          </ClickAwayListener>
        </AutocompletePopper>
      </>
    );
  }
);
TimeInputBase.displayName = "TimeInputBase";

const TimePickerInputBase = forwardRef<HTMLInputElement, InputBaseProps & { InputProps: TextFieldProps["InputProps"] }>(
  ({ InputProps, ...props }, ref) => <InputBase {...props} {...InputProps} ref={ref} />
);
TimePickerInputBase.displayName = "TimePickerInputBase";
