import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useRef } from "react";

interface AttendanceUpdate {
  workerId?: string;
  positionId?: string;
  attendanceId?: string;
  shiftId?: string;
}
type AttendanceUpdateListener = (update: AttendanceUpdate[]) => void;
/**
 * Share updated status of attendances actions set to server, eg. after join or kick, hook is called and components can be udpdated
 */
export const ContextAttendanceUpdate = createContext<Set<AttendanceUpdateListener>>(
  new Set<AttendanceUpdateListener>()
);

/**
 * Register watcher for change of attendance
 */
export const useAttendanceUpdate = (listener?: AttendanceUpdateListener) => {
  const attendanceUpdateListeners = useContext(ContextAttendanceUpdate);

  useEffect(() => {
    if (listener) {
      attendanceUpdateListeners.add(listener);
    }
    return () => {
      if (listener) {
        attendanceUpdateListeners.delete(listener);
      }
    };
  }, [listener]);

  if (!attendanceUpdateListeners) {
    return undefined;
  }

  return (attendanceUpdateData: AttendanceUpdate[]) => {
    attendanceUpdateListeners.forEach((triggeredListener) => triggeredListener(attendanceUpdateData));
  };
};

/**
 * Provide context for attendance status update notifier
 */
export const AttendanceUpdateProvider: FC<PropsWithChildren> = ({ children }) => {
  const attendanceUpdateListeners = useRef(new Set<AttendanceUpdateListener>());
  return (
    <ContextAttendanceUpdate.Provider value={attendanceUpdateListeners.current}>
      {children}
    </ContextAttendanceUpdate.Provider>
  );
};
