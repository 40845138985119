import { Stack, Typography } from "@mui/material";

import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import { mapToOptions } from "@sinch/utils/option";
import { head, isNil } from "ramda";
import { isNotNil } from "ramda-adjunct";
import React, { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../../form/AutocompleteInput/AutocompleteInput";
import { AutocompleteInputProps } from "../../form/AutocompleteInput/types";
import { useMuiController } from "../../form/MuiController/MuiController";
import { WorkerPaymentMethods } from "./queries.graphql";
import { WorkerPaymentMethodsQuery, WorkerPaymentMethodsQueryVariables } from "./queries.types";
import { SignInDialogFormFields } from "./types";

interface PaymentMethodSelectProps extends Pick<AutocompleteInputProps, "value" | "slotProps" | "textFieldProps"> {
  /**
   * ID of the worker, used for fetch available payment methods
   */
  workerId: string;
  /**
   * Effective date for the payment method, used for fetch available payment methods
   */
  effectiveDate: Date;
  /**
   * Name of the worker, used in the description
   */
  workerName: string;
  /**
   * Name of the position, used in the description
   */
  positionName: string;
}

/**
 * Component for selecting a payment method for a worker.
 */
export const PaymentMethodSelect: FC<PaymentMethodSelectProps> = ({
  workerName,
  positionName,
  workerId,
  effectiveDate,
}) => {
  const { t } = useTranslation();

  // Fetch payment methods data from the server
  const { data, loading } = useQueryHandler<WorkerPaymentMethodsQuery, WorkerPaymentMethodsQueryVariables>(
    WorkerPaymentMethods,
    { dateValid: effectiveDate, workerId: workerId }
  );

  // Hook to control the form field for payment method ID
  const { field } = useMuiController<SignInDialogFormFields, "paymentMethodId">({
    name: "paymentMethodId",
  });

  // Map the fetched payment methods to options for the AutocompleteInput
  const paymentMethodsOptions = useMemo(() => mapToOptions("id", "name")(data?.availablePayoutMethods || []), [data]);

  // Set value after fetching data
  useEffect(() => {
    if (isNotNil(data?.availablePayoutMethods) && field.value === null) {
      field.onChange(head(data.availablePayoutMethods)?.id ?? null);
    }
  }, [data]);

  return (
    <Stack spacing={2}>
      <Typography>
        {t("SignInDialog.description", {
          workerName: (
            <Typography component="span" fontWeight="bold">
              {workerName}
            </Typography>
          ),
          positionName: (
            <Typography component="span" fontWeight="bold">
              {positionName}
            </Typography>
          ),
        })}
      </Typography>
      <AutocompleteInput
        {...field}
        disableClearable
        includeInputInList
        loading={loading}
        onChange={(val) => {
          field.onChange(String(val));
        }}
        openOnFocus
        options={paymentMethodsOptions}
        skeleton={!isNil(field.value) && loading}
        value={field.value as string}
      />
    </Stack>
  );
};
