import { InputBase, TextFieldProps, useForkRef, useTheme } from "@mui/material";

import { useFontSizeCalculate } from "@sinch/hooks/utilities/useFontSizeCalculate";
import { omit } from "ramda";
import { isArray } from "ramda-adjunct";
import React, { ElementType, forwardRef, MutableRefObject, RefCallback, useImperativeHandle, useRef } from "react";
import { DateTimeInputBaseProps } from "./DateTimeInput.types";

type DateRenderInputProps = TextFieldProps<"outlined"> & {
  /**
   * Set calendar dialog open or close
   */
  setOpen?: (open: boolean) => void;
  focusRef?: MutableRefObject<HTMLInputElement | undefined> | RefCallback<HTMLInputElement> | undefined;
  value: string;
} & Pick<DateTimeInputBaseProps, "className" | "value">;

export const DateRenderInput = forwardRef(({ focusRef, className, setOpen, ...props }: DateRenderInputProps, ref) => {
  const theme = useTheme();
  const inputRefForked = useRef<HTMLInputElement | null>(null);
  const inRef = useForkRef(focusRef, inputRefForked, ref);

  useImperativeHandle(
    focusRef,
    () =>
      ({
        focus() {
          inputRefForked?.current?.focus();
          setOpen?.(true);
        },

        blur() {
          setOpen?.(false);
          inputRefForked?.current?.blur();
        },
      }) as HTMLInputElement,
    []
  );

  const placeholderWidth = useFontSizeCalculate(props.placeholder ? (props.value ?? props.placeholder) : null, 14);

  return (
    <InputBase
      {...props.InputProps}
      ref={inRef}
      {...omit(
        ["margin", "onKeyPress", "onKeyDown", "onKeyUp", "onInvalid", "InputProps", "focused", "slotProps", "slots"],
        props
      )}
      endAdornment={undefined}
      inputProps={{
        ...props.inputProps,
        className: className,
        onClick: () => {
          setOpen?.(true);
        },
      }}
      sx={{
        ...(isArray(props.sx) ? props.sx.reduce((acc, item) => ({ ...acc, ...item }), {}) : props.sx),
        width: placeholderWidth ? `calc(${Math.ceil(placeholderWidth)}px + ${theme.spacing(0.5)})` : "6rem",
        height: "100%",
        input: {
          minWidth: placeholderWidth ? `calc(${Math.ceil(placeholderWidth)}px + ${theme.spacing(0.5)})` : "6rem",
          height: "100%",
          padding: 0,
        },
      }}
    />
  );
}) as ElementType<TextFieldProps>;
DateRenderInput.displayName = "DateRenderInput";
