import { Tooltip } from "@mui/material";
import { max, min } from "@sinch/utils/dateTime/dateFns";

import { pluck } from "ramda";
import React, { FC } from "react";
import { TimeBarHorizontalAxis } from "./TimeBarHorizontalAxis";
import { TimeBarLabel } from "./TimeBarLabel";
import { TimeBarLine } from "./TimeBarLine";
import { TimeBarTimePoint } from "./TimeBarTimePoint";
import { TimeBarGraphProps } from "./types";
import { useTimeBarGraph } from "./UseTimeBarGraph";

/**
 * Render a horizontal time bar graph. It render a time axis lines separated by hour.
 */
export const TimeBarGraph: FC<TimeBarGraphProps> = ({
  rangeEnd,
  rangeStart,
  segments,
  points,
  maxBarHeight,
  labels,
}) => {
  const minInSegments = segments ? min(pluck("start", segments)) : rangeStart;
  const maxInSegments = segments ? max(pluck("end", segments)) : rangeEnd;

  const minAll = min([minInSegments, rangeStart]);
  const maxAll = max([maxInSegments, rangeEnd]);

  const rangeAll = maxAll.getTime() - minAll.getTime();

  const { parts } = useTimeBarGraph({ segments });

  /**
   * If zero range, do not render
   */
  if (rangeAll === 0) {
    return null;
  }
  const leftPercents = (minInSegments.getTime() - minAll.getTime()) / rangeAll;
  const rightPercents = (maxAll.getTime() - maxInSegments.getTime()) / rangeAll;
  const barPercents = 1 - leftPercents - rightPercents;

  return (
    <TimeBarHorizontalAxis rangeEnd={maxAll} rangeStart={minAll} sx={{ width: `100%` }}>
      {segments && (
        <TimeBarLine
          parts={parts}
          sx={{
            maxHeight: maxBarHeight ?? undefined,
            marginLeft: `${leftPercents * 100}%`,
            width: `${barPercents * 100}%`,
          }}
        />
      )}
      {points?.map(({ point, label, side }) => (
        <TimeBarTimePoint key={point.getTime()} point={point} rangeEnd={maxAll} rangeStart={minAll} side={side}>
          {label}
        </TimeBarTimePoint>
      ))}
      {labels?.map(({ label, tooltip, ...labelProps }) => (
        <Tooltip key={labelProps.start.getTime()} placement="top" title={tooltip}>
          <TimeBarLabel
            rangeEnd={maxAll}
            rangeStart={minAll}
            sx={{ maxHeight: maxBarHeight ?? undefined }}
            {...labelProps}
          >
            {label}
          </TimeBarLabel>
        </Tooltip>
      ))}
    </TimeBarHorizontalAxis>
  );
};
