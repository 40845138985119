import { Grid } from "@mui/material";
import { isEmpty } from "ramda";
import React, { FC } from "react";
import { UploadContainerProps } from "../types";

export type UploadChipPreviewsProps = UploadContainerProps;

export const UploadChipPreviewsContainer: FC<UploadChipPreviewsProps> = ({
  value = [],
  disabled,
  multiple,
  previewItemComponent: PreviewItem,
  uploadComponent: UploadButton,
}) => (
  <Grid container direction="column">
    <Grid item>
      <Grid container spacing={1} sx={{ pb: !multiple || isEmpty(value) ? 0 : 2 }}>
        {value.map((file) => (
          <PreviewItem key={file.id} {...file} disabled={disabled} />
        ))}
      </Grid>
    </Grid>
    <Grid item>
      <UploadButton />
    </Grid>
  </Grid>
);
