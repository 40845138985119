import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { DialogCloseIcon } from "../../DialogCloseIcon/DialogCloseIcon";
import { TextEditor } from "../../form/TextEditor/TextEditor";

export interface DeclineReasonDialogProps {
  onClose: () => void;
  onConfirm: (reason: string) => void;
}

/**
 * Dialog for add message for declined applicant
 */
export const DeclineReasonDialog: FC<DeclineReasonDialogProps> = ({ onClose, onConfirm }) => {
  const { t } = useTranslation();
  const [reason, setReason] = useState<string>("");

  return (
    <Dialog maxWidth="md" onClose={onClose} open>
      <DialogTitle>
        {t("Applicant.declineApplicantTitle")}
        <DialogCloseIcon onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <TextEditor
          helperText={t("Applicant.messageWillBeSendToStaffMember")}
          label={t("Applicant.declineReason")}
          onChange={setReason}
          toolbarPosition="bottom"
          value={reason}
          variant="minimal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text">
          {t("cancel")}
        </Button>
        <Button
          onClick={() => {
            onConfirm(reason);
          }}
          variant="contained"
        >
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
