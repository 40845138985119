import { alpha, autocompleteClasses, ListProps, Paper, Popper, styled } from "@mui/material";

/**
 * Popper component of time input
 */
export const AutocompletePopper = styled(Popper, {
  name: "MuiAutocomplete",
  slot: "Popper",
  overridesResolver: (props, styles) => [{ [`& .${autocompleteClasses.option}`]: styles.option }, styles.popper, true],
})(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  position: "absolute",
}));

/**
 * Paper component of time input
 */
export const AutocompletePaper = styled(Paper, {
  name: "MuiAutocomplete",
  slot: "Paper",
  overridesResolver: (props, styles) => styles.paper,
})(({ theme }) => ({
  ...theme.typography.body1,
  overflow: "auto",
}));

/**
 * Listbox (select list) of time input
 */
export const AutocompleteListbox = styled("div", {
  name: "MuiAutocomplete",
  slot: "Listbox",
  overridesResolver: (props, styles) => styles.listbox,
})<ListProps>(({ theme }) => ({
  minWidth: "max-content",
  //minWidth: "fit-content",
  margin: 0,
  padding: "8px 0",

  [`& .SinchTimePickerOption-root`]: {
    margin: theme.spacing(0, 1),
    minHeight: 48,
    display: "flex",
    overflow: "hidden",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
    paddingTop: 6,
    boxSizing: "border-box",
    outline: "0",
    WebkitTapHighlightColor: "transparent",
    paddingBottom: 6,
    paddingLeft: 16,
    paddingRight: 16,
    //marginRight: `calc(${theme.spacing(1)} + 20px)`,

    [`&.Mui-selected`]: {
      backgroundColor: alpha(theme.palette.primary.main, 0.12),
      borderRadius: `${theme.shape.borderRadius}px !important`,
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "auto",
    },
    [`&:hover`]: {
      backgroundColor: alpha(theme.palette.secondary.dark, 0.25),
      borderRadius: `${theme.shape.borderRadius}px !important`,
      // Reset on touch devices, it doesn't add specificity
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    [`&.Mui-disabled`]: {
      opacity: theme.palette.action.disabledOpacity,
      pointerEvents: "none",
    },
  },
}));
