import { useSettings } from "@sinch/core/providers/AppSettings";
import { BasicPathData, useGoogleDirectionApi } from "@sinch/hooks/utilities/useGoogleStaticMapApi";
import { Coordinates } from "@sinch/types/common.types";
import { isNil } from "ramda";
import { useEffect, useMemo, useState } from "react";

/**
 * Custom hook to fetch and manage direction data between two coordinates.
 *
 */
export const useDirectionData = ({
  origin,
  destination,
}: {
  origin: Coordinates | null;
  destination: Coordinates | null;
}) => {
  // Retrieve distance unit setting from the application settings
  const { distanceUnit } = useSettings();

  // Memoize origin and destination coordinates to optimize performance
  const originMemo = useMemo(() => origin, [origin?.lat, origin?.lng]);
  const destinationMemo = useMemo(() => destination, [destination?.lat, destination?.lng]);

  // Initialize state to store direction data
  const [directionData, setDirectionData] = useState<
    BasicPathData & { origin: Coordinates | null; destination: Coordinates | null }
  >({
    origin: isNil(destination) ? origin : null,
    destination: null,
    path: null,
    distance: null,
    duration: null,
  });

  // Hook to fetch direction data from Google Direction API
  const [dispatchDirection, data] = useGoogleDirectionApi({
    destination: destinationMemo,
    origin: originMemo,
    units: distanceUnit === "mi" ? "imperial" : "metric",
  });

  // Effect to dispatch direction fetch when origin and destination are set
  useEffect(() => {
    if (origin && destination) {
      dispatchDirection();
    } else if (origin) {
      setDirectionData({ origin, destination: null, path: null, distance: null, duration: null });
    } else {
      setDirectionData({ origin: null, destination: null, path: null, distance: null, duration: null });
    }
  }, [originMemo, destinationMemo]);

  // Effect to update direction data state when new data is fetched
  useEffect(() => {
    if (origin && destination && data) {
      setDirectionData({
        origin,
        destination,
        ...data,
      });
    }
  }, [data]);

  return directionData;
};
