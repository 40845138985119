import { DocumentNode, OperationVariables, TypedDocumentNode, useApolloClient } from "@apollo/client";
import { useLazyQueryHandler } from "./useLazyQueryHandler";

export interface UseCachedFragmentParams<
  TFragment = any,
  TData extends Record<string, TFragment | null> = any,
  TVariables extends OperationVariables = OperationVariables,
> {
  /**
   * Type name of fragment
   */
  type: string;
  /**
   * id of fragment entity
   */
  id: string;
  /**
   * Query for fetch data if fragment is not cached
   */
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  /**
   * Variables for query
   */
  variables?: TVariables;
  /**
   * Fragment
   */
  fragment: DocumentNode | TypedDocumentNode<TData, TVariables>;
}

/**
 * Get cached data from fragment and fetch data if fragment is not cached
 */
export function useCachedFragment<
  TFragment = any,
  TData extends Record<string, TFragment | null> = any,
  TVariables extends OperationVariables = OperationVariables,
>({ type, id, query, variables, fragment }: UseCachedFragmentParams<TFragment, TData, TVariables>) {
  const client = useApolloClient();
  const { dispatch, data, called } = useLazyQueryHandler<TData, TVariables>(query);

  const cached = client.readFragment<TFragment>({
    id: `${type}:${id}`,
    fragment: fragment,
  });

  if (!called && !data && id && !cached) {
    dispatch(variables);
  }

  return cached;
}
