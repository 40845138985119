import React, { PropsWithChildren, ReactElement, useState } from "react";
import { FieldValues } from "react-hook-form";
import { usePersistFormState } from "./hooks";

export interface RenderConfirmationProps<TFieldValues extends FieldValues> {
  /**
   * Confirm and apply load of persisted data
   */
  confirm: () => void;
  /**
   * Cancel load of persistent data and clear it from localstorage
   */
  cancel: () => void;
  /**
   * If true, confirmation of load of persisted data is required
   */
  open: boolean;
  /**
   * currently persisted state
   */
  persistedState: TFieldValues;
}

export interface FormPersistorProps<TFieldValues extends FieldValues> extends PropsWithChildren {
  /**
   * Key to use for localstorage
   */
  persistKey: string;

  /**
   * Render confirmation dialog or any other element
   */
  renderConfirmation: (args: RenderConfirmationProps<TFieldValues>) => ReactElement;

  pending: boolean;
  confirm: () => void;
  cancel: () => void;
  persistedState: TFieldValues | undefined;
}

export function FormPersistorDialogWrapper<TFieldValues extends FieldValues>({
  persistKey,
  renderConfirmation,
  children,
  pending,
  confirm,
  cancel,
  persistedState,
}: FormPersistorProps<TFieldValues>) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(pending);

  usePersistFormState<TFieldValues>(persistKey, pending);

  const handleConfirm = () => {
    confirm();
    setIsConfirmOpen(false);
  };

  const handleCancel = () => {
    cancel();
    setIsConfirmOpen(false);
  };

  // We need to make sure to set the form values either BEFORE the form fields have been
  // rendered, OR AFTER we 100% know they have been registered, otherwise they will not
  // reflect the form value. This is imo the easiest way to ensure that by far.
  // More here: https://stackoverflow.com/a/59547360/10467064
  if (pending && persistedState) {
    return (
      <>{renderConfirmation({ persistedState, cancel: handleCancel, confirm: handleConfirm, open: isConfirmOpen })}</>
    );
  }

  return <>{children}</>;
}
