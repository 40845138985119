// eslint-disable-next-line import/no-internal-modules
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip } from "@mui/material";
import {
  MdsExpandMoreFillXs,
  MdsFormatAlignCenterXs,
  MdsFormatAlignLeftXs,
  MdsFormatAlignRightXs,
  MdsFormatIndentDecreaseFillXs,
  MdsFormatIndentIncreaseXs,
} from "@sinch/icons";
import { ElementFormatType } from "lexical";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { ToolbarButton } from "../components/ToolbarButton";
import { useFormatElement } from "../hooks/useFormatElement";
import { useIndentElement, useOutdentElement } from "../hooks/useIndentFormat";

export const AlignMenu: FC = () => {
  const [editor] = useLexicalComposerContext();

  const { t } = useTranslation();
  const format = useFormatElement();
  const indent = useIndentElement();
  const outdent = useOutdentElement();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleFormat = (align: ElementFormatType) => {
    handleClose();
    format(align);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const disabled = !editor.isEditable();
  return (
    <div>
      <Tooltip placement="top" slotProps={{ popper: { disablePortal: true } }} title={t("TextEditor.alignText")}>
        <ToolbarButton
          disabled={disabled}
          endIcon={<MdsIcon fontSize="xs" icon={MdsExpandMoreFillXs} />}
          onClick={handleClick}
          tabIndex={-1}
        >
          <MdsIcon fontSize="xs" icon={MdsFormatAlignLeftXs} />
        </ToolbarButton>
      </Tooltip>
      <Menu anchorEl={anchorEl} disablePortal onClose={handleClose} open={open}>
        <MenuItem disabled={disabled} onClick={() => handleFormat("left")}>
          <ListItemIcon>
            <MdsIcon fontSize="small" icon={MdsFormatAlignLeftXs} />
          </ListItemIcon>
          <ListItemText>{t("TextEditor.leftAlign")}</ListItemText>
        </MenuItem>
        <MenuItem disabled={disabled} onClick={() => handleFormat("center")}>
          <ListItemIcon>
            <MdsIcon fontSize="small" icon={MdsFormatAlignCenterXs} />
          </ListItemIcon>
          <ListItemText>{t("TextEditor.centerAlign")}</ListItemText>
        </MenuItem>
        <MenuItem disabled={disabled} onClick={() => handleFormat("right")}>
          <ListItemIcon>
            <MdsIcon fontSize="small" icon={MdsFormatAlignRightXs} />
          </ListItemIcon>
          <ListItemText>{t("TextEditor.rightAlign")}</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={disabled}
          onClick={() => {
            handleClose();
            indent();
          }}
        >
          <ListItemIcon>
            <MdsIcon fontSize="small" icon={MdsFormatIndentIncreaseXs} />
          </ListItemIcon>
          <ListItemText>{t("TextEditor.indent")}</ListItemText>
        </MenuItem>
        <MenuItem
          disabled={disabled}
          onClick={() => {
            handleClose();
            outdent();
          }}
        >
          <ListItemIcon>
            <MdsIcon fontSize="small" icon={MdsFormatIndentDecreaseFillXs} />
          </ListItemIcon>
          <ListItemText>{t("TextEditor.outdent")}</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
};
