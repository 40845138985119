import { styled } from "@mui/material";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import { hasNotProp } from "@sinch/utils/object";
import { Interval } from "luxon";
import { PropsWithChildren } from "react";
import { TimeBarLabelProps } from "./types";

export const TimeBarLabel = styled("div", {
  shouldForwardProp: hasNotProp<TimeBarLabelProps>([
    "rangeStart",
    "rangeEnd",
    "start",
    "end",
    "tooltip",
    "color",
    "ref",
    "sx",
  ]),
})<PropsWithChildren<TimeBarLabelProps>>(({ start, end, rangeEnd, rangeStart, color }) => {
  const intervalMillisDuration = toLuxon(rangeEnd).diff(toLuxon(rangeStart)).as("milliseconds");
  const percentStart =
    (Interval.fromDateTimes(rangeStart, start).toDuration().as("milliseconds") / intervalMillisDuration) * 100;
  const percentEnd =
    (Interval.fromDateTimes(rangeStart, end).toDuration().as("milliseconds") / intervalMillisDuration) * 100;

  return {
    whiteSpace: "nowrap",
    justifyContent: "space-around",
    color: color,
    position: "absolute",
    minWidth: "1px",
    height: "100%",
    //top: 0,
    zIndex: "inherit",
    display: "flex",
    alignItems: "center",
    left: `${percentStart}%`,
    width: `${percentEnd - percentStart}%`,
    "&:empty": {
      padding: 0,
    },
    "&>*": {
      textAlign: "center",
      display: "inline-block",
      whiteSpace: "nowrap",
      minWidth: "inherit",
    },
  };
});
