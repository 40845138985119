import { t } from "i18next";
import { WageStep, WageType } from "./types";

export const decimalToTimeString = (decimalNumber: number): string => {
  const totalMinutes = decimalNumber * 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);

  const formattedHours = hours.toString();
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
};

export const timeStringToDecimal = (timeString: string): number => {
  const [hours, minutes] = timeString.split(":").map(Number);
  const decimalNumber = hours + minutes / 60;

  return decimalNumber;
};

export const roundDuration = (value: number, roundType: WageStep): number => {
  switch (roundType) {
    case WageStep.QUARTER:
      return Math.round(value * 4) / 4;
    case WageStep.HALF:
      return Math.round(value * 2) / 2;
    case WageStep.HOUR:
      return Math.round(value);
    default:
      return value; // Return the original value if roundType is not recognized
  }
};

export const getWageLabel = (wageType: WageType): string => {
  const labels: Record<WageType, string> = {
    [WageType.FIXED]: t("WageBuilder.fixed"),
    [WageType.HOURLY]: t("WageBuilder.hourly"),
    [WageType.HOURLY_THRESHOLD]: t("WageBuilder.hourlyThreshold"),
    [WageType.THRESHOLD_FIX]: t("WageBuilder.thresholdFix"),
  };
  return labels[wageType];
};
