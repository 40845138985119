import { PositionStatusEnum } from "@sinch/core/entities/serverEnums";
import { useTranslation } from "react-i18next";
import { useEntityProps } from "../utilsHooks";

export const usePositionStatus = () => {
  const { t } = useTranslation();

  const positionRoles = {
    [PositionStatusEnum.CbCompleted]: { label: t("Shift.closedByStaffLeaderLabel") },
    [PositionStatusEnum.Completed]: { label: t("Shift.closedByAdmin") },
    [PositionStatusEnum.Opened]: { label: t("Shift.open") },
    [PositionStatusEnum.OpenedHidden]: { label: t("Shift.openHidden") },
    [PositionStatusEnum.Over]: { label: t("Shift.finished") },
    [PositionStatusEnum.Pending]: { label: t("Shift.pending") },
    [PositionStatusEnum.Running]: { label: t("Shift.running") },
  } as Record<PositionStatusEnum, { label: string }>;
  const getProp = useEntityProps(positionRoles);

  const getStatusLabel = getProp<string>("label");

  return { getStatusLabel };
};
