import "@fontsource/figtree/300.css";
import "@fontsource/figtree/400.css";
import "@fontsource/figtree/600.css";
import "@fontsource/figtree/700.css";
import "@fontsource/figtree/300-italic.css";
import "@fontsource/figtree/400-italic.css";
import "@fontsource/figtree/600-italic.css";
import "@fontsource/figtree/700-italic.css";

import "@fontsource-variable/figtree/index.css"; // Contains ONLY variable weights and no other axes.
import "@fontsource-variable/figtree/wght-italic.css"; // Italic variant.
import {
  alpha,
  ComponentsVariants,
  darken,
  lighten,
  Palette,
  PaletteColor,
  SvgIcon,
  tableCellClasses,
} from "@mui/material";
/* eslint-disable import/no-internal-modules */
import { createTheme } from "@mui/material/styles";

/* eslint-disable @typescript-eslint/no-unused-vars */
// noinspection ES6UnusedImports
import { PaletteOptions, TypeBackground } from "@mui/material/styles/createPalette";
// noinspection ES6UnusedImports
import { TypographyOptions } from "@mui/material/styles/createTypography";
/* eslint-enable @typescript-eslint/no-unused-vars,import/no-internal-modules */
import { isTouchDevice } from "@sinch/utils/browser";
import { identity, map, reduce, times } from "ramda";
import React from "react";
import { PositionRoleEnum } from "../entities/serverEnums";

const palette = {
  text: {
    primary: "#333333", // Jet/500
  },
  primary: {
    main: "#127AAA",
    light: "#1693CD",
    dark: darken("#127AAA", 0.15),
  },
  secondary: {
    main: "rgba(2, 55, 70, 0.25)",
    light: "rgba(2, 55, 70, 0.1)",
    dark: "#023746",
  },
  error: {
    main: "#D9534F",
    dark: darken("#D9534F", 0.15),
    light: "#F3565D",
  },
  success: {
    main: "#5cb85c",
    light: lighten("#5cb85c", 0.15),
    dark: "#00825B",
  },
  grey: {
    100: "#F9F9F9",
    200: "#F1F1F1",
    300: "#EEEEEE",
    400: "#D3D3D3",
    500: "#AAAAAA",
    800: "#666666",
    900: "#333333",
  },
  custom: {
    green: {
      100: "#e0f1df",
      800: "#00825B",
    },
    jet: {
      500: "#333333",
    },
    deepSpace: {
      200: "#EAEDEF",
      500: "#82898D",
    },
    blue: {
      50: "#E0F4FA",
      100: "#B2E2F3",
      200: "#81CFEC",
      400: "#35AEE0",
      500: "#1693CD",
      600: "#127AAA",
      800: "#2C414B",
      900: "#023746",
    },
    beige: {
      100: "#FFF5E8",
    },
    red: {
      100: "#f9dddc",
      200: "#FA8186",
      400: "#F3565D",
    },
    orange: {
      50: "#FDE7DD",
      400: "#F57943",
      500: "#F36421",
      800: "#CD5016",
    },
    purple: {
      500: "#9747FF",
    },
    table: {
      header: "#E6EBEC",
    },
    cyan: {
      600: "#00ACC1",
    },
    availableTaggingColors: [
      "#35aa47",
      "#D91E18",
      "#4B8DF8",
      "#e09840",
      "#BFBFBF",
      "#67809F",
      "#ffd837",
      "#9A12B3",
      "#4B77BE",
      "#4DB3A2",
      "#2C3E50",
      "#95A5A6",
      "#E08283",
      "#4DB3A2",
      "#f2784b",
      "#5C9BD1",
      "#BF55EC",
    ],
  },
  background: {
    yellow: "#FFF5E8",
  },
  entity: {
    common: {
      order: "#F36421",
      job: "#00825B",
      shift: "#35AEE0",
      position: "#9747FF",
    },
    positionRole: {
      [PositionRoleEnum.Crewboss]: "#F3565D",
      [PositionRoleEnum.Worker]: "#82898D",
      [PositionRoleEnum.Standby]: "#35AEE0",
    },
  },
};

const typography: TypographyOptions = {
  fontSize: 14,
  htmlFontSize: 16,
  fontFamily: "Figtree",
  fontWeightMedium: 600,
  h1: {
    fontSize: "1.875rem",
    fontWeight: 500,
    lineHeight: "39px",
  },
  h2: {
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "31px",
  },
  h3: {
    fontSize: "1.1875rem",
    fontWeight: 500,
    lineHeight: "25px",
  },
  h4: {
    fontSize: "1.0625rem",
    fontWeight: 500,
    lineHeight: "22px",
  },
  h5: {
    fontSize: "1.0625rem",
    fontWeight: 500,
    lineHeight: "22px",
  },
  h6: {
    fontSize: "1.0625rem",
    fontWeight: 500,
    lineHeight: "22px",
  },
  body1: {
    fontSize: "0.875rem",
  },
  body2: {
    fontSize: "0.75rem",
  },
  button: {
    fontSize: "0.8125rem",
    fontWeight: 600,
  },
  caption: {
    fontSize: "0.9375rem",
  },
  overline: {
    fontSize: "0.8125rem",
  },
  subtitle1: {
    fontSize: "0.9375rem",
  },
  subtitle2: {
    fontSize: "0.8125rem",
  },
  // CUSTOM STYLES
  descriptionSemi: {
    fontSize: "0.9375rem",
    fontWeight: 600,
    lineHeight: "19.5px",
    color: palette.grey[900],
  },
  descriptionSmall: {
    fontSize: "0.8125rem",
    fontWeight: 400,
    lineHeight: "16.9px",
  },
  descriptionSmallBold: {
    fontSize: "0.8125rem",
    fontWeight: 700,
    lineHeight: "16.9px",
  },
  descriptionSmallSemi: {
    fontSize: "0.8125rem",
    fontWeight: 600,
    lineHeight: "16.9px",
  },
  descriptionTiny: {
    fontSize: `${11 / 16}rem`,
    fontWeight: 400,
    lineHeight: "14.3px",
    color: palette.grey[800],
  },
  descriptionTinySemi: {
    fontSize: `${11 / 16}rem`,
    fontWeight: 600,
    lineHeight: "14.3px",
    color: palette.grey[800],
  },
  descriptionTinyBold: {
    fontSize: `${11 / 16}rem`,
    fontWeight: 700,
    lineHeight: "14.3px",
    color: palette.grey[800],
  },
};

const BASE_THEME_OPTIONS = {
  shape: {
    borderRadius: 6,
    inputSmallHeight: 32,
    inputMediumHeight: 40,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 1024,
      lg: 1280,
      xl: 1440,
    },
  },
  palette: palette,
  typography: typography,
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 15000,
  },
} as const;

const baseTheme = createTheme(BASE_THEME_OPTIONS);

type PaletteColors = { [K in keyof Palette]: Palette[K] extends PaletteColor ? K : never }[keyof Palette];
type ArrayElement<ArrayType extends readonly unknown[]> = ArrayType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

const baseColors = Object.entries(baseTheme.palette).filter(([, paletteColor]) => paletteColor && paletteColor.main);

export const theme = createTheme(baseTheme, {
  //shadows: baseTheme.shadows.map(() => "none") as Shadows,
  components: {
    MuiScopedCssBaseline: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "100%",
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          borderRadius: "50% !important",
        },
      },
      variants: [
        {
          props: { size: "lg" },
          style: {
            width: "56px",
            height: "56px",
            fontSize: "1.5rem",
          },
        },
        {
          props: { size: "sm" },
          style: {
            width: "24px",
            height: "24px",
            fontSize: "1rem",
          },
        },
        {
          props: { size: "xs" },
          style: {
            width: "16px",
            height: "16px",
            fontSize: "0.625rem",
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          ".notistack-Snackbar &": {
            borderRadius: `${baseTheme.shape.borderRadius}px !important`,
            boxShadow:
              "0px 1px 18px 0px rgba(0, 0, 0, 0.12), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 3px 5px -1px rgba(0, 0, 0, 0.20)",
            color: baseTheme.palette.custom.jet[500],
            fontSize: `${15 / 16}rem`,
            display: "flex",
            alignItems: "center",
            "& .MuiAlert-action": {
              padding: 0,
              paddingLeft: baseTheme.spacing(1),
            },
          },
        },
        message: {
          display: "flex",
          alignItems: "center",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          marginLeft: baseTheme.spacing(1),
          marginRight: baseTheme.spacing(1),
          paddingLeft: baseTheme.spacing(1),
          paddingRight: baseTheme.spacing(1),
          borderRadius: `${baseTheme.shape.borderRadius}px !important`,
        },
        listbox: {
          "& .MuiAutocomplete-option.Mui-focused": {
            backgroundColor: baseTheme.palette.secondary.main,
          },
        },
        popper: {
          "& .MuiPaper-root": {
            maxWidth: "100vw",
          },
        },
        input: {
          "&[readonly]": {
            cursor: "pointer",
          },
        },
        root: {
          "& .MuiOutlinedInput-root": {
            padding: 0,
            "& .MuiInputBase-input": {
              '&[type="search"]': {
                boxSizing: "initial",
              },
              paddingTop: baseTheme.spacing(1.5),
              paddingBottom: baseTheme.spacing(1.5),
              paddingLeft: baseTheme.spacing(1),
            },
          },
          "& .MuiInputAdornment-positionStart": {
            paddingLeft: baseTheme.spacing(0.75),
          },
          "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input": {
            paddingLeft: 0,
          },
          "& .MuiAutocomplete-endAdornment>.MuiAutocomplete-popupIndicator>*:nth-of-type(1)": {
            color: baseTheme.palette.grey[500],
          },
          "& .MuiAutocomplete-endAdornment>.MuiAutocomplete-clearIndicator>*:nth-of-type(1)": {
            fontSize: "1rem",
            color: baseTheme.palette.grey[500],
          },
          "&:hover .MuiAutocomplete-endAdornment>.MuiAutocomplete-popupIndicator>*:nth-of-type(1)": {
            color: baseTheme.palette.primary.main,
          },
          "&:hover .MuiAutocomplete-endAdornment>.MuiAutocomplete-clearIndicator>*:nth-of-type(1)": {
            color: baseTheme.palette.primary.main,
          },
          "&.Mui-focused .MuiAutocomplete-endAdornment>.MuiAutocomplete-popupIndicator>*:nth-of-type(1)": {
            color: baseTheme.palette.primary.main,
          },
          "&.Mui-focused .MuiAutocomplete-endAdornment>.MuiAutocomplete-clearIndicator>*:nth-of-type(1)": {
            color: baseTheme.palette.primary.main,
          },
          "& .MuiAutocomplete-inputRoot": {
            paddingLeft: baseTheme.spacing(1),
          },
        },
        tag: {
          "& .MuiChip-deleteIcon": {
            margin: 0,
            fontSize: "1rem",
          },
          "& .MuiChip-label": {
            paddingLeft: 0,
            paddingRight: baseTheme.spacing(0.5),
          },
          height: "24px",
          fontWeight: baseTheme.typography.fontWeightMedium,
          fontSize: "0.875rem",
          justifyContent: "space-between",
          paddingLeft: baseTheme.spacing(1),
          paddingRight: baseTheme.spacing(1),
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          color: baseTheme.palette.primary.light,
          "&:hover": {
            textDecoration: "underline",
            color: baseTheme.palette.primary.main,
          },
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.custom.jet[500],
          backgroundColor: baseTheme.palette.grey[300],
          borderBottom: `1px solid ${baseTheme.palette.grey[400]}`,
          fontWeight: baseTheme.typography.fontWeightMedium,
          lineHeight: "32px",
        },
      },
    },
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicatorStart: {
          position: "initial",
          paddingRight: baseTheme.spacing(1),
        },
        loadingIndicatorEnd: {
          position: "initial",
          paddingLeft: baseTheme.spacing(1),
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          letterSpacing: "0.03rem",
          borderRadius: `${baseTheme.shape.borderRadius}px !important`,
          padding: `${baseTheme.spacing(1)} ${baseTheme.spacing(2)}`,
          lineHeight: "15px",
          "&.Mui-focusVisible": {
            boxShadow: `0px 0px 0px 4px ${baseTheme.palette.custom.blue[100]}`,
          },
          "&:focus": {
            textDecoration: `none`,
          },
        },
        containedPrimary: {
          backgroundColor: baseTheme.palette.primary.light,
          "&:hover": {
            backgroundColor: baseTheme.palette.primary.main,
          },
          "&:active": {
            boxShadow: `0px 0px 8px ${alpha(baseTheme.palette.primary.main, 0.25)}`,
          },
          "&.Mui-disabled": {
            backgroundColor: baseTheme.palette.custom.blue[200],
            color: baseTheme.palette.common.white,
          },
        },
        outlinedPrimary: {
          borderColor: baseTheme.palette.primary.light,
          "&:hover": {
            backgroundColor: baseTheme.palette.custom.blue[50],
          },
          "&:active": {
            boxShadow: `0px 0px 8px ${alpha(baseTheme.palette.primary.main, 0.25)}`,
          },
          "&.Mui-disabled": {
            backgroundColor: "transparent",
            borderColor: baseTheme.palette.custom.blue[200],
            color: baseTheme.palette.custom.blue[200],
          },
        },
        textPrimary: {
          color: baseTheme.palette.primary.light,
          "&:hover": {
            backgroundColor: baseTheme.palette.custom.blue[50],
          },
          "a&:focus": {
            color: baseTheme.palette.primary.main,
          },
          "&:active": {
            boxShadow: `0px 0px 8px ${alpha(baseTheme.palette.primary.main, 0.25)}`,
          },
          "&.Mui-disabled": {
            backgroundColor: "transparent",
            color: baseTheme.palette.custom.blue[200],
          },
        },
        contained: {
          color: baseTheme.palette.common.white,
          "a&:hover": {
            color: baseTheme.palette.common.white,
          },
          "&.Mui-disabled": {
            color: baseTheme.palette.common.white,
          },
          variants: baseColors.map(([paletteColor, { main, dark, light }]) => ({
            props: { color: paletteColor },
            style: {
              backgroundColor: main,
              "&:hover": {
                backgroundColor: dark,
              },
              "&:active": {
                boxShadow: `0px 0px 8px ${alpha(dark, 0.25)}`,
              },
              "&.Mui-disabled": {
                backgroundColor: alpha(light, 0.25),
              },
            },
          })),
        },
        outlined: {
          "&.Mui-disabled": {
            backgroundColor: "transparent",
          },
          variants: baseColors.map(([paletteColor, { main, light }]) => ({
            props: { color: paletteColor },
            style: {
              borderColor: main,
              "a&:hover": {
                color: main,
              },
              "&:hover": {
                backgroundColor: alpha(light, 0.25),
              },
              "&:active": {
                boxShadow: `0px 0px 8px ${alpha(main, 0.25)}`,
              },
              "&.Mui-disabled": {
                borderColor: alpha(main, 0.25),
                color: alpha(main, 0.25),
              },
            },
          })),
        },
        text: {
          padding: `${baseTheme.spacing(1)} ${baseTheme.spacing(2)}`,
          "&.Mui-disabled": {
            backgroundColor: "transparent",
          },
          variants: baseColors.map(([paletteColor, { main, dark, light }]) => ({
            props: { color: paletteColor },
            style: {
              color: main,
              "a&:hover": {
                color: main,
              },
              "a&:focus": {
                color: main,
              },
              "&:hover": {
                backgroundColor: lighten(light, 0.85),
              },
              "&:active": {
                boxShadow: `0px 0px 8px ${lighten(dark, 0.85)}`,
              },
              "&.Mui-disabled": {
                color: lighten(main, 0.85),
              },
            },
          })),
        },
        sizeLarge: {
          fontSize: "0.9375rem",
          minHeight: "40px",
        },
        sizeSmall: {
          fontSize: "0.75rem",
          padding: "4px 8px",
          lineHeight: 1.5,
          height: "24px",
          "& .MuiButton-startIcon>*:nth-of-type(1)": {
            fontSize: "1rem",
          },
        },
        startIcon: {
          marginRight: 0,
          marginLeft: 0,
          paddingRight: baseTheme.spacing(1),
          "&>*:nth-of-type(1)": {
            fontSize: "1rem",
          },
        },
        endIcon: {
          marginRight: 0,
          marginLeft: 0,
          paddingLeft: baseTheme.spacing(1),
          "&>*:nth-of-type(1)": {
            fontSize: "1rem",
          },
        },
      },
      defaultProps: {
        disableRipple: !isTouchDevice(),
        disableElevation: true,
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: baseTheme.palette.grey[300],
            color: baseTheme.palette.grey[500],
          },
        },
      },
      defaultProps: {
        disableRipple: !isTouchDevice(),
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        groupedHorizontal: {
          "&:not(:last-of-type)": {
            borderBottomRightRadius: "0px !important",
            borderTopRightRadius: "0px !important",
          },
          "&:not(:first-of-type)": {
            borderBottomLeftRadius: "0px !important",
            borderTopLeftRadius: "0px !important",
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        indeterminateIcon: (
          <SvgIcon
            className="MuiCheckbox--default"
            sx={{
              border: "none",
              borderRadius: "6px",
              width: "20px",
              height: "20px",
              padding: "2px",
              fontSize: "1rem",
              backgroundColor: baseTheme.palette.primary.main,
              color: baseTheme.palette.getContrastText(baseTheme.palette.primary.main),
            }}
            //viewBox="0 0 16 16"
          >
            <path d="M19,14H5V11H19V14Z" />
          </SvgIcon>
        ),
        checkedIcon: (
          <SvgIcon
            className="MuiCheckbox--default"
            sx={{
              border: "none",
              borderRadius: "6px",
              width: "20px",
              height: "20px",
              padding: "2px",
              fontSize: "1rem",
              backgroundColor: baseTheme.palette.primary.main,
              color: baseTheme.palette.getContrastText(baseTheme.palette.primary.main),
            }}
            viewBox="0 0 16 16"
          >
            <path d="m 6.3957343,11.937732 c -0.11093,0 -0.22213,-0.0224 -0.3336,-0.0672 -0.11093,-0.04427 -0.21626,-0.11653 -0.316,-0.2168 l -2.816798,-2.816 c -0.178133,-0.17813 -0.264267,-0.3976 -0.2584,-0.6584 0.00533,-0.26133 0.097067,-0.4808 0.2752,-0.6584 0.177598,-0.17813 0.397068,-0.2672 0.658398,-0.2672 0.2608,0 0.48,0.08907 0.6576,0.2672 l 2.1672,2.1664 5.3495997,-5.3496 c 0.17814,-0.178133 0.39201,-0.2698662 0.64161,-0.2751995 0.2501,-0.00587 0.464,0.085867 0.6416,0.2751995 0.1781,0.1776 0.2672,0.394134 0.2672,0.6496 0,0.25547 -0.0891,0.47227 -0.2672,0.6504 l -6.0160097,6.016 c -0.10026,0.10027 -0.20586,0.17253 -0.3168,0.2168 -0.11093,0.0448 -0.22213,0.0672 -0.3336,0.0672 z" />
          </SvgIcon>
        ),
        icon: (
          <SvgIcon
            className="MuiCheckbox--default"
            sx={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: baseTheme.palette.grey[500],
              borderRadius: "6px",
              width: "20px",
              height: "20px",
              padding: "1px",
              fontSize: "1rem",
              backgroundColor: baseTheme.palette.common.white,
            }}
            viewBox="0 0 16 16"
          >
            <path d="" />
          </SvgIcon>
        ),
      },
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          "&.Mui-disabled": {
            backgroundColor: "transparent",
          },
          "& .MuiSvgIcon-root.MuiCheckbox--default.MuiSvgIcon-fontSizeNormal": {
            width: "20px",
            height: "20px",
          },
          "& .MuiSvgIcon-root.MuiCheckbox--default.MuiSvgIcon-fontSizeSmall": {
            width: "16px",
            height: "16px",
            padding: 0,
            borderRadius: "4px",
          },
          "&.MuiCheckbox-colorError .MuiSvgIcon-root.MuiCheckbox--default": {
            borderColor: baseTheme.palette.error.main,
          },
          "&:hover .MuiSvgIcon-root.MuiCheckbox--default": {
            borderColor: baseTheme.palette.primary.main,
          },
          "&.Mui-disabled .MuiSvgIcon-root.MuiCheckbox--default": {
            borderColor: baseTheme.palette.grey[400],
            backgroundColor: baseTheme.palette.grey[200],
          },
          "&.Mui-disabled.Mui-checked .MuiSvgIcon-root.MuiCheckbox--default": {
            backgroundColor: baseTheme.palette.grey[400],
          },
          "&.Mui-focusVisible .MuiSvgIcon-root": {
            boxShadow: `0px 0px 0px 4px ${baseTheme.palette.custom.blue[100]}`,
          },
          '& input[type="checkbox"]': {
            margin: "0 !important", // fix for conflict with bootstrap
          },
          variants: baseColors.map(([paletteColor, { main }]) => ({
            props: { color: paletteColor },
            style: {
              "&.Mui-checked .MuiSvgIcon-root.MuiCheckbox--default": {
                backgroundColor: main,
                color: baseTheme.palette.getContrastText(darken(main, 0.2)),
                fill: baseTheme.palette.getContrastText(darken(main, 0.2)),
              },
            },
          })),
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "16px !important",
          paddingLeft: baseTheme.spacing(1.5),
          paddingRight: baseTheme.spacing(1.5),
        },
        sizeSmall: {
          borderRadius: "12px !important",
          fontSize: "0.75rem",
        },
        icon: {
          margin: 0,
        },
        clickable: {
          textTransform: "uppercase",
        },
        filled: {
          color: baseTheme.palette.secondary.dark,
          backgroundColor: alpha(baseTheme.palette.secondary.dark, 0.1),
          "&:active": {
            boxShadow: "none",
          },
          "&:hover": {
            backgroundColor: alpha(baseTheme.palette.secondary.dark, 0.25),
          },
          "&.Mui-disabled": {
            color: baseTheme.palette.grey[500],
            backgroundColor: baseTheme.palette.grey[400],
          },
          "& .MuiChip-icon": {
            color: baseTheme.palette.secondary.dark,
          },
        },
      },
      variants: [
        {
          props: { variant: "selected" },
          style: {
            backgroundColor: baseTheme.palette.secondary.dark,
            color: baseTheme.palette.getContrastText(baseTheme.palette.secondary.dark),
            "&:hover": {
              backgroundColor: alpha(baseTheme.palette.secondary.dark, 0.9),
            },
            "&>.MuiChip-deleteIcon": {
              color: baseTheme.palette.getContrastText(baseTheme.palette.secondary.dark),
            },
            "&>.MuiChip-deleteIcon:hover": {
              color: alpha(baseTheme.palette.getContrastText(baseTheme.palette.secondary.dark), 0.75),
            },
            "& .MuiChip-icon": {
              color: baseTheme.palette.getContrastText(baseTheme.palette.secondary.dark),
            },
          },
        },
        {
          props: { size: "tiny" },
          style: {
            height: "13px",
            fontSize: baseTheme.typography.pxToRem(11),
          },
        },
      ],
    },
    MuiFormControl: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          fontSize: "0.8125rem",
          marginTop: baseTheme.spacing(1),
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            color: baseTheme.palette.custom.jet[500],
          },
          "&.Mui-focused.Mui-error": {
            color: baseTheme.palette.error.main,
          },
          "&.Mui-disabled": {
            color: baseTheme.palette.grey[500],
          },
          fontWeight: baseTheme.typography.fontWeightMedium,
          color: baseTheme.palette.custom.jet[500],
          marginBottom: 0,
        },
        asterisk: {
          color: baseTheme.palette.error.main,
        },
      },
    },
    MuiRadio: {
      defaultProps: {
        checkedIcon: (
          <SvgIcon
            className="MuiRadio--default"
            fill="none"
            sx={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              fontSize: "1rem",
              borderColor: baseTheme.palette.custom.blue[500],
              backgroundColor: baseTheme.palette.primary.main,
              color: baseTheme.palette.custom.blue[500],
            }}
            viewBox="0 0 20 20"
          >
            <circle cx="10" cy="10" fill="none" r="9.5" stroke="transparent" />
            <circle cx="10" cy="10" fill="white" r="4" />
          </SvgIcon>
        ),
        icon: (
          <SvgIcon
            className="MuiRadio--default"
            fill="none"
            sx={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderRadius: "50%",
              width: "20px",
              height: "20px",
              borderColor: baseTheme.palette.grey[500],
              fontSize: "1rem",
              backgroundColor: baseTheme.palette.common.white,
              color: "transparent",
            }}
            viewBox="0 0 20 20"
          >
            <circle cx="10" cy="10" r="9.5" stroke="transparent" />
          </SvgIcon>
        ),
      },
      styleOverrides: {
        root: {
          "&> .MuiRadio--default.MuiSvgIcon-fontSizeMedium": {
            width: "20px",
            height: "20px",
          },
          "&> .MuiRadio--default.MuiSvgIcon-fontSizeSmall": {
            width: "16px",
            height: "16px",
          },
          "&.Mui-focusVisible svg": {
            boxShadow: `0px 0px 0px 4px ${baseTheme.palette.custom.blue[100]}`,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          [`[role="radiogroup"] &`]: {
            marginLeft: 0,
            marginRight: 8,
            marginBottom: 8,
            paddingRight: 9,
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: `${baseTheme.shape.borderRadius}px !important`,

            borderColor: baseTheme.palette.grey[400],
            color: baseTheme.palette.grey[800],

            "&:hover": {
              backgroundColor: baseTheme.palette.custom.blue[50],
              borderColor: baseTheme.palette.custom.blue[600],
              cursor: "pointer",
              "&>.MuiRadio-root > .MuiSvgIcon-root": {
                borderColor: baseTheme.palette.custom.blue[600],
              },
            },
            "&.Mui-disabled": {
              borderColor: baseTheme.palette.grey[400],
              backgroundColor: baseTheme.palette.grey[300],
              cursor: "not-allowed",
              "&>.MuiRadio-root": {
                backgroundColor: "transparent",
              },
              "&>.MuiRadio-root > .MuiSvgIcon-root": {
                borderColor: baseTheme.palette.grey[400],
                backgroundColor: baseTheme.palette.grey[300],
              },
            },
            "&.Mui-error": {
              borderColor: baseTheme.palette.error.main,
              "&>.MuiRadio-root > .MuiSvgIcon-root": {
                borderColor: baseTheme.palette.error.main,
                color: "transparent",
                backgroundColor: "transparent",
              },
              "&:hover": {
                backgroundColor: alpha(baseTheme.palette.error.main, 0.2),
              },
            },
          },
          variants: [
            {
              props: { checked: true },
              style: {
                [`[role="radiogroup"] &`]: {
                  borderColor: baseTheme.palette.custom.blue[500],
                  color: baseTheme.palette.custom.blue[900],
                  "&.Mui-disabled": {
                    "&>.MuiRadio-root > .MuiSvgIcon-root": {
                      backgroundColor: baseTheme.palette.grey[400],
                    },
                  },
                  "&.Mui-error": {
                    "&>.MuiRadio-root > .MuiSvgIcon-root": {
                      color: baseTheme.palette.error.main,
                      backgroundColor: baseTheme.palette.error.main,
                    },
                  },
                },
              },
            },
          ],
        },
      },
    },
    MuiIconButton: {
      defaultProps: { disableRipple: true, tabIndex: -1 },
      styleOverrides: {
        root: {
          borderRadius: `${baseTheme.shape.borderRadius}px !important`,
          variants: baseColors.map(([paletteColor, { light }]) => ({
            props: { color: paletteColor },
            style: {
              "&:hover": {
                backgroundColor: lighten(light, 0.85),
              },
            },
          })),
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "&>.MuiTypography-root": { color: baseTheme.palette.grey[500] },
          "&>.MuiSvgIcon-root": { color: baseTheme.palette.grey[500] },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: `${baseTheme.shape.inputMediumHeight}px`,
          "&>.MuiInputBase-input::placeholder": {
            color: baseTheme.palette.custom.jet[500],
          },
          "&.MuiInputBase-multiline": {
            padding: 0,
          },
        },
        sizeSmall: {
          minHeight: `${baseTheme.shape.inputSmallHeight}px`,
        },
        sizeMedium: {
          minHeight: `${baseTheme.shape.inputMediumHeight}px`,
        },
        multiline: {
          padding: 0,
        },
        input: {
          color: baseTheme.palette.custom.jet[500],
          caretColor: baseTheme.palette.primary.main,
          //padding: `${baseTheme.spacing(2)} ${baseTheme.spacing(1)}`,
          '&[type="search"]': {
            boxSizing: "initial",
          },
          "&[type=number]": {
            MozAppearance: "textfield",
          },
          "&[type=number]::-webkit-outer-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "&[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "&.SinchDateTime-dimmed": {
            color: palette.grey["400"],
          },
        },
      },
      defaultProps: {
        inputProps: {
          dir: "auto",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: baseTheme.typography.fontWeightMedium,
          color: baseTheme.palette.custom.jet[500],
          paddingBottom: baseTheme.spacing(1),
          marginBottom: 0,
          "&.Mui-focused": {
            color: "inherit",
          },
          "&.Mui-focused.Mui-error": {
            color: "inherit",
          },
          "&.Mui-error": {
            color: "inherit",
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        root: {
          borderRadius: `${baseTheme.shape.borderRadius}px !important`,
          backgroundColor: baseTheme.palette.background.paper,
          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            top: "-6px !important",
          },
          "&.Mui-disabled": {
            backgroundColor: baseTheme.palette.grey[200],
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: baseTheme.palette.grey[400],
            },
          },
          "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: baseTheme.palette.grey[400],
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderWidth: 1,
            boxShadow: "0px 0px 4px rgba(18, 122, 170, 0.25)",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: baseTheme.palette.primary.main,
          },
          "&.Mui-focused": {
            boxShadow: `0px 0px 0px 4px ${baseTheme.palette.custom.blue[100]}`,
          },
        },
        notchedOutline: {
          borderColor: baseTheme.palette.grey[400],
        },
        input: {
          height: "1rem",
          padding: `${baseTheme.spacing(1.5)} ${baseTheme.spacing(2)}`,
          '&[type="search"]': {
            boxSizing: "initial",
          },
          "&.MuiInputBase-inputSizeSmall": {
            height: "1rem",
            padding: "8px 16px",
          },
          "&.MuiInputBase-inputAdornedStart": {
            paddingLeft: 0,
          },
          "&.MuiInputBase-inputAdornedEnd": {
            paddingRight: 0,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          "&.MuiPaper-rounded": {
            borderRadius: `${baseTheme.shape.borderRadius}px !important`,
          },
        },
      },
      variants: [
        {
          props: { variant: "filled" },
          style: {
            backgroundColor: baseTheme.palette.grey[100],
          },
        },
      ],
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: `${baseTheme.shape.borderRadius}px !important`,
        },
      },
    },
    MuiPickersYear: {
      styleOverrides: {
        yearButton: {
          borderRadius: `${baseTheme.shape.borderRadius}px !important`,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          height: "1rem",
          minHeight: "1rem",
          display: "flex",
          alignItems: "center",
          "&.MuiInputBase-inputSizeSmall": {
            paddingTop: baseTheme.spacing(1),
            paddingBottom: baseTheme.spacing(1),
          },
        },
        icon: {
          color: baseTheme.palette.grey[500],
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          height: "48px",
          paddingTop: "12px",
          paddingBottom: "12px",
          paddingLeft: baseTheme.spacing(3),
          paddingRight: baseTheme.spacing(3),
          "&:first-of-type": {
            paddingLeft: baseTheme.spacing(2),
          },
          "&:last-of-type": {
            paddingRight: baseTheme.spacing(2),
          },
          marginRight: baseTheme.spacing(-2),
          marginLeft: baseTheme.spacing(-2),
          variants: [
            {
              props: { active: true },
              style: {
                backgroundColor: baseTheme.palette.custom.deepSpace[200],
                borderRadius: "8px !important",
                fontWeight: baseTheme.typography.fontWeightMedium,
              },
            },
            {
              props: { active: false },
              style: {
                padding: "12px 16px",
              },
            },
          ],
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          zIndex: 1,
          minWidth: "56px",
        },
        line: {
          borderColor: baseTheme.palette.grey[500],
        },
      },
    },
    MuiStepButton: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          "&.Mui-disabled": {
            backgroundColor: "transparent",
            color: "inherit",
          },
          padding: "12px 24px",
          margin: "-12px -24px",
          verticalAlign: "unset",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.custom.deepSpace[200],
          "&.Mui-active": {
            color: baseTheme.palette.secondary.dark,
          },
          "&.Mui-active .MuiStepIcon-text": {
            color: baseTheme.palette.getContrastText(baseTheme.palette.secondary.dark),
            fill: baseTheme.palette.getContrastText(baseTheme.palette.secondary.dark),
          },
        },
        text: {
          color: baseTheme.palette.secondary.dark,
          fill: baseTheme.palette.secondary.dark,
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        root: {
          "& .MuiStepLabel-iconContainer.Mui-completed .MuiStepIcon-text": {
            color: baseTheme.palette.common.white,
            fill: baseTheme.palette.common.white,
          },
        },
        iconContainer: {
          "&.Mui-completed .MuiStepIcon-root, &.Mui-active.Mui-completed .MuiStepIcon-root": {
            color: baseTheme.palette.success.main,
          },
          "&.Mui-active .MuiStepIcon-root": {
            color: baseTheme.palette.secondary.dark,
            "& .MuiStepIcon-text": {
              color: baseTheme.palette.getContrastText(baseTheme.palette.secondary.dark),
              fill: baseTheme.palette.getContrastText(baseTheme.palette.secondary.dark),
            },
          },
          "&.Mui-error .MuiStepIcon-root .MuiStepIcon-text": {
            color: baseTheme.palette.getContrastText(baseTheme.palette.error.main),
            fill: baseTheme.palette.getContrastText(baseTheme.palette.error.main),
          },
          "&.Mui-error .MuiSvgIcon-root": {
            color: baseTheme.palette.error.main,
          },
        },
        label: {
          display: "flex",
          alignItems: "center",
          fontSize: "1.0625rem",
          color: baseTheme.palette.secondary.dark,
          "&:.Mui-active": {
            fontWeight: baseTheme.typography.fontWeightMedium,
          },
          "&.Mui-error": {
            color: baseTheme.palette.secondary.dark,
          },
        },
      },
    },
    MuiSkeleton: {
      variants: [
        {
          props: { variant: "input" },
          style: {
            display: "flex",
            borderRadius: "6px !important",
            alignItems: "center",
            borderColor: baseTheme.palette.grey[400],
            borderWidth: "1px",
            borderStyle: "solid",
          },
        },
      ],
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: "xs" },
          style: {
            fontSize: "1rem",
          },
        },
      ],
    },
    MuiSwitch: {
      variants: [
        {
          props: { size: "mini" },
          style: {
            marginLeft: 0,
            marginBottom: "inherit",
            marginTop: 0,
            padding: 0,
            width: "28px",
            height: "16px",
            borderRadius: "8px !important",
            "&>.MuiSwitch-switchBase": {
              padding: "2px",
            },
            "&>.MuiSwitch-switchBase.Mui-checked": {
              transform: "translateX(12px)",
            },
            "&>.MuiSwitch-switchBase+.MuiSwitch-track": {
              opacity: 1,
            },
            "&>.MuiSwitch-switchBase>.MuiSwitch-thumb": {
              width: "12px",
              height: "12px",
              border: "none",
            },
          },
        },
        ...map<PaletteColors, ArrayElement<NonNullable<ComponentsVariants["MuiSwitch"]>>>(
          (color) => ({
            props: { size: "mini", color },
            style: {
              "&>.MuiSwitch-switchBase+.MuiSwitch-track": {
                backgroundColor: baseTheme.palette[color].main,
              },
            },
          }),
          ["primary", "secondary", "error", "info", "success", "warning"] as PaletteColors[]
        ),
        {
          props: { size: "mini", disabled: true },
          style: {
            "&>.MuiSwitch-switchBase+.MuiSwitch-track": {
              backgroundColor: baseTheme.palette.grey[500],
            },
          },
        },
      ],
      styleOverrides: {
        switchBase: {
          borderRadius: `50% !important`,
          paddingLeft: "7px",
          "&.Mui-checked": { color: baseTheme.palette.common.white },
          "&.Mui-checked+.MuiSwitch-track": {
            opacity: null,
          },
          "&.Mui-checked.Mui-disabled .MuiSwitch-thumb": {
            color: baseTheme.palette.grey[200],
          },
          "&.Mui-checked.Mui-disabled+.MuiSwitch-track": {
            backgroundColor: null,
          },
          "&.Mui-disabled": {
            backgroundColor: "transparent",
          },
          variants: baseColors.map(([paletteColor, { dark }]) => ({
            props: { color: paletteColor },
            style: {
              "&.Mui-checked+.MuiSwitch-track": {
                opacity: 1,
              },
              "&.Mui-checked.Mui-disabled+.MuiSwitch-track": {
                backgroundColor: dark,
              },
            },
          })),
        },
        sizeSmall: {
          width: "36px",
          "&>.MuiSwitch-switchBase.Mui-checked": {
            transform: "translateX(12px)",
          },
        },
        thumb: {
          boxShadow: "none",
          borderRadius: `50% !important`,
          borderColor: baseTheme.palette.grey[400],
          borderWidth: "1px",
          borderStyle: "solid",
          variants: [
            {
              props: { disabled: false },
              style: {
                color: baseTheme.palette.common.white,
              },
            },
          ],
        },
        track: {
          opacity: 1,
          backgroundColor: alpha(baseTheme.palette.common.black, 0.38),
          borderRadius: `${14 / 2}px !important`,
          ".Mui-focusVisible+&": {
            boxShadow: `0px 0px 0px 8px ${baseTheme.palette.custom.blue[100]}`,
          },
        },
        root: {
          marginLeft: "-7px",
          marginBottom: "-8px",
          marginTop: "-8px",
          "&.Mui-disabled": {
            ".MuiSwitch-thumb": {
              color: baseTheme.palette.grey[200],
            },
          },
          width: "54px",
          "& input[type=checkbox]": {
            margin: 0, // bootstrap fix
          },
        },
      },
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          overflow: "visible",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root:hover .MuiTableCell-root": {
            backgroundColor: baseTheme.palette.custom.deepSpace[200],
          },
          "& .MuiTableRow-root:nth-of-type(even) .MuiTableCell-root": {
            backgroundColor: baseTheme.palette.grey[100],
          },
          "& .MuiTableCell-root": {
            fontSize: `${14 / 16}rem`,
            paddingLeft: baseTheme.spacing(1),
            paddingRight: baseTheme.spacing(1),
          },
        },
      },
    },
    MuiTableFooter: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root .MuiTableCell-root": {
            backgroundColor: baseTheme.palette.custom.beige[100],
          },
          "& .MuiTableCell-root": {
            fontSize: `${14 / 16}rem`,
            paddingLeft: baseTheme.spacing(1),
            paddingRight: baseTheme.spacing(1),
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${baseTheme.palette.grey[400]}`,
          [`&.${tableCellClasses.head}`]: {
            backgroundColor: baseTheme.palette.grey[300],
            color: baseTheme.palette.grey[800],
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        labelIcon: {
          minHeight: "initial",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: baseTheme.palette.grey[300],
          "& .MuiTableCell-root": {
            color: baseTheme.palette.grey[800],
            fontSize: `${13 / 16}rem`,
            fontWeight: baseTheme.typography.fontWeightRegular,
            paddingTop: baseTheme.spacing(0.5),
            paddingBottom: baseTheme.spacing(0.5),
            paddingLeft: baseTheme.spacing(1),
            paddingRight: baseTheme.spacing(1),
          },
        },
      },
    },
    MuiTimeField: {
      defaultProps: {
        fullWidth: true,
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: { borderRadius: `${baseTheme.shape.borderRadius}px !important` },
      },
    },
    MuiTooltip: {
      defaultProps: {
        enterDelay: 300,
        enterNextDelay: 300,
      },
      styleOverrides: {
        tooltip: {
          fontWeight: baseTheme.typography.fontWeightRegular,
          borderRadius: `${baseTheme.shape.borderRadius}px !important`,
          backgroundColor: baseTheme.palette.secondary.dark,
        },
        arrow: {
          color: baseTheme.palette.secondary.dark,
        },
      },
    },
    MuiTouchRipple: {
      styleOverrides: {
        root: {
          borderRadius: `inherit !important`,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: typography.fontFamily,
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        maxWidth: "md",
        scroll: "body",
      },
      styleOverrides: {
        root: {
          zIndex: 10000,
        },
        paper: {
          overflowY: "visible",
          "&.MuiPaper-rounded": {
            borderRadius: "16px !important",
          },
          borderRadius: "16px !important",
          "&.MuiDialog-paperFullScreen.MuiPaper-rounded": {
            borderRadius: "0px !important",
          },
          [baseTheme.breakpoints.down("md")]: {
            marginLeft: baseTheme.spacing(0.5),
            marginRight: baseTheme.spacing(0.5),
            width: `calc(100% - ${baseTheme.spacing(1)})`,
            "&.MuiDialog-paperScrollBody": {
              maxWidth: `calc(100% - ${baseTheme.spacing(1)})`,
            },
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: baseTheme.palette.custom.jet[500],
          ...baseTheme.typography.h2,
          padding: "24px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: "visible",
          paddingBottom: "24px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "24px",
          paddingTop: "16px",
          "&>:not(:first-of-type)": {
            marginLeft: baseTheme.spacing(3),
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          "&> .MuiDivider-wrapperVertical": {
            padding: 0,
            color: baseTheme.palette.grey[800],
          },
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        columnHeaderHeight: 32,
        rowHeight: 44,
      },
      styleOverrides: {
        root: {
          border: "none",
        },
        pinnedColumnHeaders: {
          backgroundColor: baseTheme.palette.grey[300],
          color: baseTheme.palette.grey[800],
          borderBottomColor: baseTheme.palette.grey[400],
          fontSize: `${13 / 16}rem`,
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: baseTheme.typography.fontWeightRegular,
          },
        },
        columnHeaders: {
          backgroundColor: baseTheme.palette.grey[300],
          color: baseTheme.palette.grey[800],
          borderBottomColor: baseTheme.palette.grey[400],
          fontSize: `${13 / 16}rem`,
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: baseTheme.typography.fontWeightRegular,
          },
        },
        treeDataGroupingCell: {
          justifyContent: "inherit",
          "&>.MuiDataGrid-treeDataGroupingCellToggle": {
            marginRight: 0,
          },
          "& .MuiSvgIcon-root": {
            color: baseTheme.palette.grey[800],
          },
          "&.Mui-expanded .MuiSvgIcon-root": {
            color: baseTheme.palette.secondary.dark,
          },
          "& .Mui-disabled .MuiSvgIcon-root": { color: baseTheme.palette.grey[500] },
        },
        withBorderColor: {
          borderColor: baseTheme.palette.grey[400],
        },
        cell: {
          fontSize: baseTheme.typography.pxToRem(14),
          "&:focus, &:focus-within": {
            outline: "none",
          },
          padding: `0 ${baseTheme.spacing(1)}`,
        },
        columnHeader: {
          "&:focus, &:focus-within": {
            outline: "none",
          },
          "&.MuiDataGrid-columnHeader--sorted": {
            color: baseTheme.palette.secondary.dark,
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: baseTheme.typography.fontWeightBold,
            },
            "& .MuiDataGrid-sortIcon": {
              color: baseTheme.palette.secondary.dark,
            },
          },
        },
        row: {
          "&:hover": {
            backgroundColor: baseTheme.palette.custom.deepSpace[200],
            "& .MuiDataGrid-treeDataGroupingCell .MuiSvgIcon-root": {
              color: baseTheme.palette.primary.main,
            },
            "& .MuiDataGrid-treeDataGroupingCell .Mui-disabled .MuiSvgIcon-root": {
              color: baseTheme.palette.grey[500],
            },
          },
          "&.MuiDataGrid-row-even:hover": {
            backgroundColor: baseTheme.palette.custom.deepSpace[200],
          },
          "&.MuiDataGrid-row-even": {
            backgroundColor: baseTheme.palette.grey[100],
          },
          "&.MuiDataGrid-row-opened": {
            backgroundColor: alpha(baseTheme.palette.secondary.dark, 0.1),
          },
          "&.Mui-selected, &.MuiDataGrid-row-even.Mui-selected": {
            backgroundColor: alpha(baseTheme.palette.secondary.dark, 0.1),
          },
          "&.MuiDataGrid-row-level-0 .MuiDataGrid-cell": {
            fontSize: "initial",
          },
          "&[class*=MuiDataGrid-row-level-] .MuiDataGrid-cell": {
            fontSize: "0.8125rem",
          },
          "&.MuiDataGrid-row-subHeader": {
            backgroundColor: baseTheme.palette.custom.table.header,
          },
          ...reduce(
            (acc, ix: number) => ({
              ...acc,
              [`&.MuiDataGrid-row-level-${ix + 1} > div:first-of-type`]: {
                paddingLeft: `${(ix + 1) * 8}px`,
              },
            }),
            {},
            times(identity, 4)
          ),
        },
      },
    },
  },
});

declare module "@mui/material/Switch" {
  interface SwitchPropsSizeOverrides {
    mini: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsSizeOverrides {
    xs: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsVariantOverrides {
    selected: true;
  }
  interface ChipPropsSizeOverrides {
    tiny: true;
  }
}

declare module "@mui/material/Skeleton" {
  interface SkeletonPropsVariantOverrides {
    input: true;
  }
}

declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    filled: true;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography/Typography" {
  interface TypographyPropsVariantOverrides {
    descriptionSemi: true;
    descriptionSmallSemi: true;
    descriptionSmall: true;
    descriptionSmallBold: true;
    descriptionTiny: true;
    descriptionTinySemi: true;
    descriptionTinyBold: true;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Avatar" {
  interface AvatarOwnProps {
    size?: "xs" | "sm" | "lg";
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    custom: typeof palette.custom;
    entity: typeof palette.entity;
    inputSmallHeight: number;
  }

  interface TypeBackground {
    yellow: string;
  }

  interface PaletteOptions {
    custom: typeof palette.custom;
    entity: typeof palette.entity;
    inputSmallHeight?: number;
  }

  interface TypographyVariants {
    descriptionSemi: React.CSSProperties;
    descriptionSmallSemi: React.CSSProperties;
    descriptionSmall: React.CSSProperties;
    descriptionSmallBold: React.CSSProperties;
    descriptionTiny: React.CSSProperties;
    descriptionTinySemi: React.CSSProperties;
    descriptionTinyBold: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    descriptionSemi?: React.CSSProperties;
    descriptionSmallSemi?: React.CSSProperties;
    descriptionSmall?: React.CSSProperties;
    descriptionSmallBold?: React.CSSProperties;
    descriptionTiny?: React.CSSProperties;
    descriptionTinySemi?: React.CSSProperties;
    descriptionTinyBold?: React.CSSProperties;
  }

  interface Theme {
    shape: {
      borderRadius: number;
      inputSmallHeight: number;
      inputMediumHeight: number;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    shape?: {
      borderRadius?: number;
      inputSmallHeight?: number;
      inputMediumHeight?: number;
    };
  }
}
