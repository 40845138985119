interface LanguageToolsReturn {
  /**
   * In case of use custom languages as cs-VOL, convert it to correct language (eg cs-VOL -> cs)
   */
  convertToStandardString: (language: string) => string;
}

/**
 * Provide language specific tools
 */
export const useLanguageTools = (): LanguageToolsReturn => {
  const convertToStandardString = (language: string) =>
    language.match(/^[a-zA-Z]{2}-[a-zA-Z]{2}$/gm) ? language : language.substring(0, 2);

  return { convertToStandardString };
};
