import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import { MdsInfo } from "@sinch/icons";
import { isNotEmpty } from "ramda-adjunct";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "@sinch/components";
import { FillData, useAutofill } from "@sinch/components/Autofill/AutofillProvider";
import { InfoBox } from "@sinch/components/InfoBox/InfoBox";
import { DescriptionList } from "@sinch/components/typography/DescriptionList/DescriptionList";

interface AutofillSuggestionBoxProps<TPath> {
  title: string;
  caption: ReactNode;
  onClose?: () => void;
  onFill: (data: FillData<TPath>[]) => void;
}
export const AutofillSuggestionBox = <TPath,>({
  title,
  caption,
  onClose,
  onFill,
}: AutofillSuggestionBoxProps<TPath>) => {
  const { t } = useTranslation();

  const { autofillData: fillDataPreview, applyCallback, resetData } = useAutofill<TPath>();

  const handleApply = () => {
    applyCallback(onFill);
    resetData();
    onClose?.();
  };

  return (
    <Collapse in={isNotEmpty(fillDataPreview)}>
      <InfoBox color="info" icon={<MdsIcon icon={MdsInfo} />} title={title}>
        <Grid container direction="column" spacing={1} sx={{ pt: 1 }}>
          <Grid>
            <Typography variant="caption">{caption}</Typography>
          </Grid>
          <Grid container direction="row" spacing={2}>
            <Grid size="grow">
              <DescriptionList
                items={fillDataPreview.map(({ label, valueLabel }) => ({
                  title: label,
                  detail: valueLabel,
                }))}
              />
            </Grid>
            <Grid size="auto" sx={{ textAlign: "right", alignContent: "end" }}>
              <Button onClick={handleApply} variant="text">
                {t("apply")}
              </Button>
              <Button
                color="error"
                onClick={() => {
                  resetData();
                  onClose?.();
                }}
                variant="text"
              >
                {t("discard")}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </InfoBox>
    </Collapse>
  );
};
