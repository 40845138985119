import { Divider, Grid, GridDirection, useForkRef, useTheme } from "@mui/material";
import { useSettings } from "@sinch/core/providers/AppSettings";
import React, { forwardRef, useRef } from "react";
import { TimeInputBase } from "../TimeInput/TimeInputBase";
import { DateInputBase } from "./DateInputBase";
import { DateTimeInputBaseProps } from "./DateTimeInput.types";

const useVariantStyle = (variant: DateTimeInputBaseProps["variant"] | undefined) => {
  const theme = useTheme();

  switch (variant) {
    case "horizontal":
      return {
        direction: "row" as GridDirection,
        sxDate: {},
        sxTime: {},
      };
    case "vertical":
      return {
        direction: "column-reverse" as GridDirection,
        sxDate: {
          display: "flex",
          minHeight: "unset",
          height: theme.spacing(2),
          fontSize: theme.typography.pxToRem(11),
        },
        sxTime: {
          minHeight: "unset",
          height: theme.spacing(2),
          fontSize: theme.typography.pxToRem(14),
          "&.MuiInputBase-sizeSmall": {
            marginTop: "-2px",
          },
        },
      };
    case "vertical-reverse":
      return {
        direction: "column" as GridDirection,
        sxDate: {
          minHeight: "unset",
          height: theme.spacing(2),
          fontSize: theme.typography.pxToRem(14),
        },
        sxTime: {
          display: "flex",
          minHeight: "unset",
          height: theme.spacing(2),
          fontSize: theme.typography.pxToRem(11),
          "&.MuiInputBase-sizeSmall": {
            marginTop: "-2px",
          },
        },
      };

    default:
      return {};
  }
};
/**
 * Date time input, for selection date part, calendar dialog is used, for selection time part, select box is used, no other form parts included (as label, helper text etc)
 */
export const DateTimeInputBase = forwardRef<HTMLElement, DateTimeInputBaseProps>(
  (
    {
      label,
      error,
      required,
      onChange,
      value,
      minTime,
      maxTime,
      minutesStep = 15,
      timePoint,
      timeInputRef,
      dateInputRef,
      dateDisabled,
      defaultValue,
      dateFormat,
      referenceDate,
      timeDisabled,
      dimmedDate,
      timeItemsStyle,
      ...props
    },
    ref
  ) => {
    const { timeZone } = useSettings();

    // Forked ref for time input
    const forkedTimeRef = useRef<HTMLInputElement>(null);
    const timeRef = useRef<HTMLInputElement>(null);
    const forkRef = useForkRef(timeInputRef, timeRef);
    const isVertical = props.variant === "vertical";
    const getMaxHeight = (size: string | undefined) => (isVertical ? (size === "small" ? "16px" : "20px") : "");

    const containerRef = useRef<HTMLDivElement | null>(null);
    const style = useVariantStyle(props.variant);

    return (
      <Grid ref={containerRef} container direction={style.direction}>
        <Grid item sx={{ maxHeight: getMaxHeight(props.size) }} xs="auto">
          <DateInputBase
            ref={ref}
            className={dimmedDate ? "SinchDateTime-dimmed" : undefined}
            dateDisabled={dateDisabled}
            dateInputRef={dateInputRef}
            defaultValue={defaultValue}
            disabled={props.disabled}
            disablePortal={props.disablePortal}
            format={dateFormat}
            inputProps={props.inputProps}
            maxTime={maxTime}
            minTime={minTime}
            onChange={(val) => {
              if (!val || val.isValid) {
                onChange(val);
              }
            }}
            onCloseExited={() => timeRef.current?.focus()}
            onFocus={props.onFocus}
            readOnly={props.readOnly}
            referenceDate={referenceDate}
            size={props.size}
            sx={style.sxDate}
            timezone={timeZone}
            value={value}
            variant={props.variant}
          />
        </Grid>
        {!isVertical && (
          <Grid
            item
            sx={{
              pr: 0.5,
            }}
          >
            <Divider
              orientation="vertical"
              sx={(theme) => ({
                height: "100%",
                ".MuiInputBase-root.Mui-error &": { borderColor: theme.palette.error.main },
                ".MuiInputBase-root:hover &, .MuiInputBase-root.Mui-focused &": {
                  borderColor: theme.palette.custom.blue[600],
                },
              })}
            />
          </Grid>
        )}
        <Grid item sx={{ maxHeight: getMaxHeight(props.size) }} xs>
          <TimeInputBase
            ref={forkedTimeRef}
            anchorEl={dateDisabled ? containerRef.current : undefined}
            dateInOption
            defaultValue={defaultValue}
            disabled={props.disabled || timeDisabled}
            disablePortal={props.disablePortal}
            error={error}
            inputProps={{ ...props.inputProps?.timeInput }}
            inputRef={forkRef}
            maxTime={maxTime}
            minTime={minTime}
            minutesStep={minutesStep}
            onChange={onChange}
            onFocus={props.onFocus}
            readOnly={props.readOnly}
            referenceDate={referenceDate}
            size={props.size}
            sx={style.sxTime}
            timeItemsStyle={timeItemsStyle}
            timePoint={timePoint}
            timezone={timeZone}
            value={value}
          />
        </Grid>
      </Grid>
    );
  }
);

DateTimeInputBase.displayName = "DateTimeInputBase";
