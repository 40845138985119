import React from "react";
import { useTranslation } from "react-i18next";
import { useMuiController } from "../../../form/MuiController/MuiController";
import { TextInput } from "../../../form/TextInput/TextInput";
import { PlaceFormFields } from "./formSchema";

export const ZipInput = () => {
  const { t } = useTranslation();
  const { field } = useMuiController<PlaceFormFields>({
    name: "zip",
  });

  return (
    <TextInput
      {...field}
      label={t("postalCode")}
      slotProps={{
        input: { "data-cy": "locationZipInput" },
      }}
    />
  );
};
