import {
  FormControlProps,
  InputBaseComponentsPropsOverrides,
  OutlinedInput,
  OutlinedInputProps,
  styled,
} from "@mui/material";
import React, { FC } from "react";
import {
  InputWrapperProps,
  FormControlWrapperProps,
  FormControlWrapper,
} from "../FormControlWrapper/FormControlWrapper";
import { BankNumberBaseInput } from "./BankNumberInputBase";
import { BankNumberInputValue, DelimiterSchema, SectionSchema } from "./types";

export type BankNumberInputProps = OutlinedInputProps &
  InputWrapperProps & {
    FormControlProps?: FormControlProps;
    schema: (SectionSchema | DelimiterSchema)[];
    onChange: (value: BankNumberInputValue) => void;
    value?: BankNumberInputValue;
  };

const StyledFormControl = styled(FormControlWrapper)<FormControlWrapperProps>(() => ({
  padding: "5px",
  margin: "-5px",
  width: "calc(100% + 10px)",
  overflowX: "auto",
}));

export const BankNumberInput: FC<BankNumberInputProps> = ({
  label,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  helperText,
  errorMessage,
  error,
  required,
  infoTooltip,
  schema,
  onChange,
  value,
  ...props
}) => {
  const inputRefs = React.useRef<(HTMLInputElement | null)[]>([]);

  const [focusedIndex, setFocusedIndex] = React.useState(-1);

  return (
    <StyledFormControl
      disabled={props.disabled}
      error={error}
      errorMessage={errorMessage}
      focused={focusedIndex > -1}
      FormHelperTextProps={formHelperTextProps}
      fullWidth
      helperText={helperText}
      infoTooltip={infoTooltip}
      label={label}
      onBlurCapture={() => setFocusedIndex(-1)}
      required={required}
      {...formControlProps}
    >
      <OutlinedInput
        error={error}
        fullWidth
        onChange={onChange}
        onClick={() => inputRefs?.current[focusedIndex > -1 ? focusedIndex : 0]?.focus()}
        slotProps={{
          input: {
            disabled: props.disabled,
            schema: schema,
            onFocusChange: setFocusedIndex,
            inputRefs: inputRefs,
          } as InputBaseComponentsPropsOverrides & {
            schema: (SectionSchema | DelimiterSchema)[];
            onFocusChange: (index: number) => void;
            inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
          },
        }}
        slots={{ input: BankNumberBaseInput }}
        sx={{
          minWidth: "max-content",
        }}
        value={value}
        {...props}
      />
    </StyledFormControl>
  );
};
