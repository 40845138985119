import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import React, { forwardRef } from "react";
import { DateTimeInputLuxonAdapterProps } from "./DateTimeInput.types";
import { DateTimeInputBase } from "./DateTimeInputBase";

/**
 * Adapter for timezones
 * TODO: refactor and remove because time inputs now have timezone integrated
 */
export const DateTimeInputLuxonAdapter = forwardRef<HTMLElement, DateTimeInputLuxonAdapterProps>((props, ref) => (
  <DateTimeInputBase
    {...props}
    ref={ref}
    maxTime={props.maxTime ? toLuxon(props.maxTime) : undefined}
    minTime={props.minTime ? toLuxon(props.minTime) : undefined}
    onChange={(val) => {
      props.onChange(val?.toJSDate() ?? null);
    }}
    referenceDate={props.referenceDate ? toLuxon(props.referenceDate as Date) : undefined}
    timePoint={props.timePoint ? toLuxon(props.timePoint) : undefined}
    value={toLuxon(props.value) ?? null}
  />
));
DateTimeInputLuxonAdapter.displayName = "DateTimeInputLuxonAdapter";
