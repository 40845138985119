import { SexEnum } from "@sinch/core/entities/serverEnums";
import { SexEnum as Gender } from "@sinch/types/sinch.types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getOptions, useEntityProps } from "../utilsHooks";

/**
 * Hook for provide formatting and getting methods for Transport entity
 */
export const useGender = () => {
  const { t } = useTranslation();

  const entityMapping = {
    [SexEnum.Male]: {
      label: t("Gender.male"),
    },
    [SexEnum.Female]: {
      label: t("Gender.female"),
    },
    [SexEnum.NonBinary]: {
      label: t("Gender.nonBinary"),
    },
  } as Record<SexEnum | Gender, { label: string }>;

  const getProp = useEntityProps(entityMapping);

  const getGenderLabel = getProp<string>("label");
  const genderOptions = useMemo(() => getOptions(entityMapping)("label"), [t]);

  return { getGenderLabel, genderOptions };
};
