import { FormControlProps, OutlinedInputProps } from "@mui/material";
import React, { FC, ReactNode } from "react";
import { InputWrapperProps, FormControlWrapper } from "../FormControlWrapper/FormControlWrapper";
import { SwitchLabel, SwitchableLabelState } from "../SwitchLabel/SwitchLabel";
import { InputVariants } from "./DateTimeDurationInputBase";
import {
  DateTimeDurationInputLuxonAdapter,
  DateTimeDurationInputLuxonAdapterProps,
} from "./DateTimeDurationInputLuxonAdapter";

export type DateTimeDurationInputProps = DateTimeDurationInputLuxonAdapterProps &
  InputWrapperProps & {
    FormControlProps?: FormControlProps;
    /**
     * Left part of the label, before switch
     */
    labelLeft: ReactNode;
    /**
     * Right part of label after switch
     */
    labelRight?: ReactNode;
    onChangeVariant?: (variant: InputVariants) => void;
    hideSwitch?: boolean;
    disablePortal?: boolean;
    variant?: "horizontal" | "vertical";
  } & Pick<OutlinedInputProps, "startAdornment">;

/**
 * Combined date time input with text input duration, switched by switch in label
 */
export const DateTimeDurationInput: FC<DateTimeDurationInputProps> = ({
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  helperText,
  errorMessage,
  error,
  required,
  infoTooltip,
  fullWidth,
  inputVariant = InputVariants.DateTime,
  onChangeVariant,
  labelLeft,
  labelRight,
  timeDurationAdornmentLabel,
  hideSwitch,
  disablePortal,
  "data-cy": dataCy,
  ...props
}) => (
  <FormControlWrapper
    data-cy={dataCy}
    error={error}
    errorMessage={errorMessage}
    FormHelperTextProps={formHelperTextProps}
    helperText={helperText}
    infoTooltip={infoTooltip}
    label={labelLeft}
    required={required}
    {...formControlProps}
    fullWidth={fullWidth}
    slotProps={{
      label: {
        "data-cy": "switch-duration-label",
        ...props.inputProps?.switchDurationButton,
        ...(hideSwitch
          ? {}
          : {
              labelLeft,
              labelRight,
              switchState:
                inputVariant === InputVariants.DateTime ? SwitchableLabelState.Left : SwitchableLabelState.Right,
            }),
        onChange: (currentSwitchState: SwitchableLabelState) =>
          onChangeVariant?.(
            currentSwitchState === SwitchableLabelState.Left ? InputVariants.DateTime : InputVariants.TimeDuration
          ),
      },
    }}
    slots={
      hideSwitch
        ? undefined
        : {
            label: SwitchLabel,
          }
    }
  >
    <DateTimeDurationInputLuxonAdapter
      disablePortal={disablePortal}
      error={error}
      fullWidth={fullWidth}
      inputVariant={inputVariant}
      required={required}
      timeDurationAdornmentLabel={timeDurationAdornmentLabel}
      {...props}
    />
  </FormControlWrapper>
);
