import { styled } from "@mui/material";
import { ComponentProps, JSXElementConstructor } from "react";

export const makeVerticalStyle = <C extends JSXElementConstructor<ComponentProps<C>>>(component: C) =>
  styled(component)(() => ({
    "& label": {
      position: "initial",
      transform: "none",
    },
  }));
