import { useSettings } from "@sinch/core/providers/AppSettings";
import type { OverwriteProps } from "@sinch/types/utility.types";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import React, { FC } from "react";

import { DateTimeInputLuxonAdapterProps } from "../DateTimeInput/DateTimeInput.types";
import { DateTimeDurationInputBase, DateTimeDurationInputBaseProps } from "./DateTimeDurationInputBase";

type Props = OverwriteProps<
  DateTimeDurationInputBaseProps,
  {
    value?: Date | null;
    /**
     * If set, in select menu will be duration information with value relative from value to this time point, in duration mode its hours relative to this point
     */
    timePoint: Date;
    /**
     * Minimum time user can select
     */
    minTime?: Date | null;
    /**
     * Maxium time user can select
     */
    maxTime?: Date | null;
    onChange: (val: Date | null) => void;

    DateTimeInputProps?: Omit<DateTimeInputLuxonAdapterProps, "onChange" | "timePoint" | "value">;
    disablePortal?: boolean;
  }
>;

export const DateTimeDurationInputLuxonAdapter: FC<Props> = (props) => {
  const { timeZone } = useSettings();

  return (
    <DateTimeDurationInputBase
      {...props}
      DateTimeInputProps={
        props.DateTimeInputProps && {
          ...props.DateTimeInputProps,
          defaultValue: toLuxon(props.value)?.setZone(timeZone),
        }
      }
      disablePortal={props.disablePortal}
      maxTime={toLuxon(props.maxTime || props.DateTimeInputProps?.maxTime)?.setZone(timeZone)}
      minTime={toLuxon(props.minTime || props.DateTimeInputProps?.minTime)?.setZone(timeZone)}
      onChange={(val) => props.onChange(val?.toJSDate() ?? null)}
      referenceDate={props.referenceDate ? toLuxon(props.referenceDate as Date)?.setZone(timeZone) : undefined}
      timePoint={toLuxon(props.timePoint)?.setZone(timeZone)}
      value={toLuxon(props.value)?.setZone(timeZone) ?? null}
    />
  );
};

export type DateTimeDurationInputLuxonAdapterProps = Props;
