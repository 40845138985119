import { includes } from "ramda";
import { useEffect, useRef } from "react";

const promiseCache = new Map();
const depKeys = new Map<any, number>();
let globalIndex = 0;

export const useSuspendedPromise = <T,>(promise: Promise<T>, dependencies: any[]): T => {
  const usedData = useRef<{ depKeys: any[]; key: string | undefined }>({ depKeys: [], key: undefined });

  // Generate key for the promise based on the dependencies
  const depKeysList = dependencies.map((dep) => {
    if (!depKeys.has(dep)) {
      depKeys.set(dep, globalIndex++);
    }
    return depKeys.get(dep);
  });
  const key = depKeysList.join("/");

  // Save the dependencies and key to clean up later
  usedData.current = { depKeys: depKeysList ?? [], key };

  if (!promiseCache.has(key)) {
    const pending = promise.then(
      (result) => {
        promiseCache.set(key, { status: "success", result });
        return result;
      },
      (error) => {
        promiseCache.set(key, { status: "error", error });
        throw error;
      }
    );

    promiseCache.set(key, { status: "pending", promise: pending });
  }

  const entry = promiseCache.get(key);
  if (entry.status === "pending") {
    throw entry.promise;
  } else if (entry.status === "error") {
    throw entry.error;
  }

  // Clean up the cache and dependencies when the component unmounts
  useEffect(
    () => () => {
      promiseCache.delete(key);
      usedData.current.depKeys?.forEach((depKey) => {
        depKeys.delete(depKey);
      });
      // Clean up the dependencies that are not used anymore
      const usedKeys = [...promiseCache.keys()].flatMap((cacheKey) => cacheKey.split("/"));
      depKeys.forEach((depKey, dep) => {
        if (!includes(depKey, usedKeys)) {
          depKeys.delete(dep);
        }
      });
    },
    []
  );

  return entry.result;
};
