import { MdsFormatStrikethroughFillXs } from "@sinch/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { FormatTextToggleButton } from "./FormatTextToggleButton";

export const Strikethrough: FC = () => {
  const { t } = useTranslation();
  return (
    <FormatTextToggleButton
      icon={<MdsIcon fontSize="xs" icon={MdsFormatStrikethroughFillXs} />}
      textFormatType="strikethrough"
      tooltip={t("TextEditor.strikethrough")}
    />
  );
};
