export const mapCrewbossTranslations = (crewbossTranslationsObject: {
  singular: Record<number, string>;
  plural: Record<number, string>;
}): Record<`crewbossNameSingular${string}` | `crewbossNamePlural${string}`, string> => ({
  ...Object.entries(crewbossTranslationsObject.singular).reduce(
    (result: Record<`crewbossNameSingular${string}`, string>, [key, string]) => {
      result[`crewbossNameSingular${key.match(/\d+$/)![0]}`] = string;
      return result;
    },
    {}
  ),
  ...Object.entries(crewbossTranslationsObject.plural).reduce(
    (result: Record<`crewbossNamePlural${string}`, string>, [key, string]) => {
      result[`crewbossNamePlural${key.match(/\d+$/)![0]}`] = string;
      return result;
    },
    {}
  ),
});
