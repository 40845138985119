import { SvgIcon, SvgIconProps } from "@mui/material";
import React, { ElementType, forwardRef, ReactElement } from "react";

export interface MdsIconProps extends SvgIconProps {
  /**
   * SVG string to render, accept any mds prefixed icon
   */
  icon: ElementType;
}

/**
 * Render svg icon from Material design icons.
 * See [https://pictogrammers.com/](https://pictogrammers.com/) and [https://mui.com/material-ui/api/svg-icon/](https://mui.com/material-ui/api/svg-icon/) for parameters docs
 */
export const MdsIcon = forwardRef<SVGSVGElement, MdsIconProps>(function MdsIcon({ icon, ...props }, ref): ReactElement {
  return <SvgIcon component={icon} {...props} ref={ref} inheritViewBox />;
});
