import React, { createElement, FC } from "react";
import { createPortal } from "react-dom";
import { captureError } from "../config/sentry";
import { ComponentContainer } from "./ComponentContainer";

interface ViewComponentProps {
  id: string;
  selector: string;
  component: FC;
}

/**
 * Get selector, find entrypoint at page and create component instance
 */
export const ViewComponent: FC<ViewComponentProps> = ({ id, selector, component }) => {
  // Get all entrypoint for component
  const portalElements = document.querySelectorAll(selector);

  // Check if entrypoint exists
  if (portalElements.length === 0) {
    captureError(`Cannot find entrypoint ${selector} for component ${component.displayName}`);
    return null;
  }

  // Set every entrypoint id (redrawing optimisation)
  portalElements.forEach((element, i) => {
    element.setAttribute("id", element.id ?? `${id}-${i}`);
  });

  return (
    <>
      {Array.from(portalElements).map((entryPoint: Element) => {
        const elementProps = JSON.parse((entryPoint as HTMLElement).dataset.props ?? "{}");
        return createPortal(
          <ComponentContainer>{createElement(component, { key: entryPoint.id, ...elementProps })}</ComponentContainer>,
          entryPoint
        );
      })}
    </>
  );
};
