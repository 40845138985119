import React from "react";
import { useTranslation } from "react-i18next";
import { GooglePlaceAutocompleteWrapper } from "../../../form/GooglePlaceAutocompleteWrapper/GooglePlaceAutocompleteWrapper";
import { useMuiController } from "../../../form/MuiController/MuiController";
import { TextInput } from "../../../form/TextInput/TextInput";
import { PlaceFormFields } from "./formSchema";

export const AddressInput = ({
  onPlaceChanged,
}: {
  onPlaceChanged: (place: google.maps.places.PlaceResult) => void;
}) => {
  const { t } = useTranslation();
  const { field } = useMuiController<PlaceFormFields>({
    name: "address",
    required: true,
  });

  return (
    <GooglePlaceAutocompleteWrapper
      onPlaceChanged={(autocompleteObject) => onPlaceChanged(autocompleteObject.getPlace())}
      types={["address"]}
    >
      <TextInput
        {...field}
        FormHelperTextProps={{
          "data-cy": "locationAddressError",
        }}
        label={t("address")}
        slotProps={{
          input: { "data-cy": "locationAddress" },
        }}
      />
    </GooglePlaceAutocompleteWrapper>
  );
};
