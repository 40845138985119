import { alpha, CircularProgress, Stack, TableCell, Typography, useTheme } from "@mui/material";
import type { DragAndDropData } from "@sinch/types/common.types";

import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Droppable } from "../../../DragNDrop/Droppable";
import { useDragAndDropContext } from "../../../providers/DragAndDropProvider/DragAndDropProvider";
import { WorkerJoinDragType } from "../../Attendace/entities";
import { WorkerMinimalInfo } from "../../Attendace/types";
import { useChangeAttendanceActions } from "../../Attendace/useWorkerAttendance";
import { SignInDialog } from "../../SignInDialog/SignInDialog";
import { ShiftPanelDetailFragment } from "../queries.types";
import { AttendanceTableRow } from "./AttendanceRow";

export const EmptyPositionRow = ({
  emptyPositions,
  position,
  onUpdate,
  attendanceConfirmation,
  isJoinable,
}: {
  emptyPositions: number;
  position: ShiftPanelDetailFragment["positions"][0];
  onUpdate: () => Promise<any>;
  attendanceConfirmation?: boolean;
  isJoinable?: boolean;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [progress, setProgress] = useState(false);
  const { t } = useTranslation();
  const theme = useTheme();
  const dndControl = useDragAndDropContext();

  const { onDropHandler, dialogProps } = useChangeAttendanceActions({
    attendanceConfirmation: attendanceConfirmation ?? false,
  });

  const handleDrop = async (workerDropData: DragAndDropData<WorkerMinimalInfo, typeof WorkerJoinDragType>) => {
    if (!isJoinable || workerDropData.type !== WorkerJoinDragType) {
      return;
    }
    setProgress(true);
    try {
      await onDropHandler(position, workerDropData.data);
      await onUpdate();
    } catch (e) {
      enqueueSnackbar(t("Error.somethingWentWrong"), { variant: "error" });
    }
    setProgress(false);
  };

  return (
    <>
      <Droppable<DragAndDropData<WorkerMinimalInfo, typeof WorkerJoinDragType>, typeof AttendanceTableRow>
        component={AttendanceTableRow}
        control={dndControl}
        isDropInvalid={(data) => !isJoinable && data?.type === WorkerJoinDragType}
        isDroppable={(data) => !!isJoinable && data && data.type === WorkerJoinDragType}
        onDrop={handleDrop}
        statesStyles={{
          invalidDrop: isJoinable
            ? {}
            : {
                "&": {
                  outline: `2px dashed ${theme.palette.error.main}`,
                  outlineOffset: "-2px",
                  "&>.MuiTableCell-root": {
                    background: `repeating-linear-gradient(
                  45deg,
                  ${alpha(theme.palette.error.main, 0.2)},
                  ${alpha(theme.palette.error.main, 0.2)} 10px,
                  transparent 10px,
                  transparent 20px
                  );`,
                  },
                },
              },
          droppable: {
            "&": {
              outline: `1px dashed ${theme.palette.grey[900]}`,
              outlineOffset: "-1px",
            },
          },
          over: {
            "&:not(.SinchDnD-invalidDrop) td.MuiTableCell-root": {
              backgroundColor: alpha(theme.palette.custom.blue[400], 0.25),
            },
          },
        }}
        sx={{
          minHeight: "35px",
          height: "35px",
        }}
      >
        <TableCell
          colSpan={6}
          size="small"
          sx={{
            minHeight: "35px",
          }}
        >
          {progress ? (
            <Stack direction="row">
              <CircularProgress color="info" size={16} thickness={5} />
              <Typography
                sx={[
                  {
                    px: 2,
                  },
                  ({ palette }) => ({ color: palette.info.main }),
                ]}
                variant="descriptionSmallSemi"
              >
                {t("Attendance.assigningToTheShift")}
              </Typography>
            </Stack>
          ) : (
            <Typography
              sx={({ palette }) => ({
                color: palette.error.main,
                px: 2,
              })}
              variant="descriptionSmallSemi"
            >
              {t("Shift.emptyPosition", { count: emptyPositions })}
            </Typography>
          )}
        </TableCell>
      </Droppable>
      {dialogProps && <SignInDialog {...dialogProps} />}
    </>
  );
};
