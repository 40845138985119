import { SlotComponentProps, useSlotProps } from "@mui/base";
import { Box, FormControl, FormControlProps, FormHelperText, FormHelperTextProps } from "@mui/material";
import type { UncapitalizeObjectKeys } from "@sinch/types/utility.types";

import { isEmpty, isNil } from "ramda";
import React, { ElementType, FC, ReactNode } from "react";
import { makeVerticalStyle } from "../../tools/globalStyling";
import { LabelWithInfo, LabelWithInfoProps } from "../LabelWithInfo/LabelWithInfo";

export interface InputWrapperProps {
  /**
   * Label of input, can be string or ReactNode
   */
  label?: ReactNode;
  /**
   * Text under input
   */
  helperText?: FormHelperTextProps["children"];
  /**
   * FormHelper text props of [FormHelperText](https://mui.com/material-ui/api/form-helper-text/) under input
   */
  FormHelperTextProps?: FormHelperTextProps;
  /**
   * Text in tooltip of info icon placed after label
   */
  infoTooltip?: ReactNode;
  /**
   * List of error messages
   */
  errorMessage?: string[];

  /**
   * Props for FormControl component
   */
  FormControlProps?: FormControlProps;
  /**
   * true if input is marked with error
   */
  error?: boolean;
  /**
   * Overrideable component slots.
   * Slots are way to customise components, see https://mui.com/base/getting-started/customization/ for more info
   */
  slots?: UncapitalizeObjectKeys<FormControlWrapperSlotsComponent>;
  /**
   * Additional props for slots
   */
  slotProps?: {
    label?: SlotComponentProps<typeof LabelWithInfo, Record<string, any>, FormControlWrapperProps>;
  };
}

interface FormControlWrapperSlotsComponent {
  Label?: ElementType;
}

export type FormControlWrapperProps = FormControlProps & InputWrapperProps;

/**
 * Wrap inputs as text, textarea, select etc.
 */
const FormControlWrapperBase: FC<FormControlWrapperProps> = (props) => {
  const {
    label,
    FormHelperTextProps: formHelperTextProps,
    helperText,
    errorMessage,
    required,
    infoTooltip,
    children,
    error,
    slots,
    slotProps,
    disabled,
    ...formControlProps
  } = props;

  const Label: ElementType = slots?.label ?? LabelWithInfo;
  const labelProps = useSlotProps({
    elementType: Label,
    externalSlotProps: slotProps?.label,
    externalForwardedProps: { infoTooltip, required, error, disabled } as LabelWithInfoProps,
    ownerState: props,
  });

  return (
    <FormControl {...formControlProps} disabled={disabled} error={error} required={required} variant="outlined">
      {label && (
        <Label {...labelProps} shrink>
          {label}
        </Label>
      )}
      {children}
      <Box>
        {!isNil(errorMessage) &&
          !isEmpty(errorMessage) &&
          errorMessage?.map((message, index) => (
            <FormHelperText {...formHelperTextProps} key={index}>
              {message}
            </FormHelperText>
          ))}
        {helperText && (
          <FormHelperText error={false} {...formHelperTextProps}>
            {helperText}
          </FormHelperText>
        )}
      </Box>
    </FormControl>
  );
};

export const FormControlWrapper = makeVerticalStyle(FormControlWrapperBase);
