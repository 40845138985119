import { Stack, Typography } from "@mui/material";
import { groupBy } from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../typography/SectionTitle/SectionTitle";
import { LabelValue } from "./LabelValue";
import { StaffFragment } from "./queries.types";

interface AttributesTabProps {
  staff: StaffFragment;
}

export const AttributesTab: FC<AttributesTabProps> = ({ staff }) => {
  const { t } = useTranslation();
  const aggregatedAttributes = groupBy(
    (item) => item.attribute.block?.name ?? t("miscellaneous"),
    staff?.profileAttributes ?? []
  );

  if (isNilOrEmpty(staff?.profileAttributes)) {
    return (
      <Typography
        sx={{
          color: ({ palette }) => palette.grey["800"],
          textTransform: "capitalize",
        }}
        variant="button"
      >
        {t("ShiftTimeline.noAttributes")}
      </Typography>
    );
  }

  return (
    <Stack spacing={1}>
      {Object.entries(aggregatedAttributes).map(([category, items]) => (
        <Stack key={category}>
          <SectionTitle title={category} variant="subtitle1" />
          <Stack direction="column">
            {items.map((item: Record<string, any>) => (
              <LabelValue
                key={item.id}
                files={item.files}
                label={item.attribute.name}
                options={item.attribute.options}
                type={item.attribute.dataType}
                value={item.value}
              />
            ))}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
