import { useTheme } from "@mui/material";
import { AttendanceReconfirmationStatusEnum } from "@sinch/core/entities/serverEnums";
import { MdsForwardToInboxXs, MdsMarkEmailReadXs } from "@sinch/icons";
import { AttendanceReconfirmationStatusEnum as AttendanceReconfirmationStatus } from "@sinch/types/sinch.types";
import { ElementType } from "react";
import { useTranslation } from "react-i18next";
import { useEntityProps } from "../utilsHooks";

export const useAttendanceReconfirmationStatus = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const attendancePresence = {
    [AttendanceReconfirmationStatusEnum.Pending]: {
      label: t("Attendance.reconfirmationPending"),
      icon: MdsForwardToInboxXs,
      color: palette.custom.orange["400"],
    },
    [AttendanceReconfirmationStatusEnum.Confirmed]: {
      label: t("Attendance.confirmed"),
      icon: MdsMarkEmailReadXs,
      color: palette.success.main,
    },
  } as Record<
    AttendanceReconfirmationStatusEnum | AttendanceReconfirmationStatus,
    { label: string; icon: ElementType; color: string }
  >;
  const getProp = useEntityProps(attendancePresence);

  const getReconfirmationStatusLabel = getProp<string>("label");
  const getReconfirmationStatusIcon = getProp<ElementType>("icon");
  const getReconfirmationStatusColor = getProp<string>("color");

  return { getReconfirmationStatusLabel, getReconfirmationStatusIcon, getReconfirmationStatusColor };
};
