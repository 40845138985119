import { PositionStatusEnum } from "@sinch/core/entities/serverEnums";
import { PositionStatusEnum as TPositionStatusEnum } from "@sinch/types/sinch.types";
import React, { FC, memo } from "react";
import { usePositionStatus } from "../usePositionStatus";
import { SuccessStatusPill } from "./SuccessStatusPill";

export const StatusPill: FC<{
  minStatus?: TPositionStatusEnum | null | undefined;
}> = memo(({ minStatus }) => {
  const { getStatusLabel } = usePositionStatus();

  if (!minStatus) {
    return null;
  }

  return <SuccessStatusPill>{getStatusLabel(minStatus as PositionStatusEnum)}</SuccessStatusPill>;
});
StatusPill.displayName = "StatusPill";
