import { Card, CardProps, styled, Theme } from "@mui/material";
import { WageType } from "./types";

// Define a map to handle different types of WageType
const wageBanckgroundColor = {
  [WageType.FIXED]: (theme: Theme, fixed: boolean | undefined) =>
    fixed === true ? theme.palette.custom.deepSpace["200"] : theme.palette.grey["200"],
  [WageType.HOURLY]: (theme: Theme) => theme.palette.custom.orange["50"],
  [WageType.HOURLY_THRESHOLD]: (theme: Theme) => theme.palette.custom.blue["50"],
  [WageType.THRESHOLD_FIX]: (theme: Theme) => theme.palette.custom.blue["50"],
};

const wageBorderColor = {
  [WageType.FIXED]: (theme: Theme) => theme.palette.custom.blue["600"],
  [WageType.HOURLY]: (theme: Theme) => theme.palette.custom.orange["800"],
  [WageType.HOURLY_THRESHOLD]: (theme: Theme) => theme.palette.custom.blue["600"],
  [WageType.THRESHOLD_FIX]: (theme: Theme) => theme.palette.custom.blue["600"],
};

export const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== "fixed" && prop !== "type",
})<CardProps & { type: WageType; fixed?: boolean }>(({ theme, fixed, type }) => ({
  width: 160,
  height: 132,
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(1.875),
  paddingBottom: theme.spacing(1.875),
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  justifyContent: "start",
  gap: theme.spacing(1),
  transition: "border-color 0.3s ease",
  border: "1px solid transparent",
  boxShadow: "none",
  backgroundColor: wageBanckgroundColor[type](theme, fixed),

  "&.MuiPaper-rounded": {
    borderRadius: "12px !important",
  },

  "&:hover": {
    ".WageCard-deleteButton": {
      display: "block",
    },
    borderColor: wageBorderColor[type](theme),
  },

  "& .WageCard-deleteButton": {
    display: "none",
  },
}));
