import { ScopedCssBaseline } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { SentryErrorBoundary } from "../providers/SentryErrorBoundary";

/**
 * Add wrappers for each component
 */
export const ComponentContainer: FC<PropsWithChildren> = ({ children }) => (
  <SentryErrorBoundary>
    <ScopedCssBaseline>{children}</ScopedCssBaseline>
  </SentryErrorBoundary>
);
