import { mapToOptions, Option } from "@sinch/utils/option";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../../../form/AutocompleteInput/AutocompleteInput";
import { useMuiController } from "../../../form/MuiController/MuiController";
import { CountryWithRegions } from "./utils";

interface RegionSelectProps {
  selectedCountry: CountryWithRegions;
}

export const RegionSelect: FC<RegionSelectProps> = ({ selectedCountry }) => {
  const { t } = useTranslation();
  const { regionLabel, regions } = selectedCountry;
  const { field } = useMuiController({
    name: "region",
  });

  const options: Option[] = useMemo(() => mapToOptions("code", "name")(regions), [regions]);

  return <AutocompleteInput {...field} label={regionLabel} options={options} placeholder={t("choose")} />;
};
