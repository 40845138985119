import styled from "@emotion/styled";
import { PositionRoleEnum } from "@sinch/core/entities/serverEnums";
import React, { FC } from "react";
import { usePositionRole } from "../application/PositionRole/PositionRole";

export interface AttendanceCounterProps {
  /**
   * attending attendance count
   */
  attendance: number;
  /**
   * total attendance count
   */
  attendanceSlots: number;
  /**
   * attending standby count
   */
  standby?: number | null;
  /**
   * total standby count
   */
  standbySlots?: number | null;
  /**
   * pending confirmation attendance count
   */
  attendancePending?: number | null;
}

/**
 * AttendanceCounter displays the state of the attendance in the day column header.
 * Attendance can have up to 3 segments for attendees, invited staff and standbys.
 * Each segment is colored based on if there are empty slots or not
 */
export const AttendanceCounter: FC<AttendanceCounterProps> = ({
  attendance,
  attendanceSlots,
  standby,
  standbySlots,
  attendancePending,
}) => {
  const { getRoleAbbreviation } = usePositionRole();
  return (
    <AttendanceCounterRoot>
      <AttendanceCountPart className={getFulfilmentClassName(attendanceSlots - attendance)}>
        {`${attendance ?? 0}/${attendanceSlots ?? 0}`}
      </AttendanceCountPart>
      {attendancePending ? <VerticalDivider /> : null}
      {attendancePending ? <AttendanceCountPart>{`${attendancePending}?`}</AttendanceCountPart> : null}
      {standbySlots && standby ? <VerticalDivider /> : null}
      {standbySlots && standby ? (
        <AttendanceCountPart
          className={getFulfilmentClassName(standbySlots - standby)}
        >{`${standby}/${standbySlots} ${getRoleAbbreviation(PositionRoleEnum.Standby)}`}</AttendanceCountPart>
      ) : null}
    </AttendanceCounterRoot>
  );
};

const VerticalDivider = styled.div`
  height: 100%;
  width: 1px;
  background-color: #e0e0e0; /* Default MUI divider color, adjust as needed */
  margin-left: auto; /* For flexItem effect */
  margin-right: auto; /* For flexItem effect */
`;

const AttendanceCounterRoot = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: fit-content;
  flex-direction: row;
  gap: 2px;
`;

const AttendanceCountPart = styled.span`
  font-size: 11px; /* Assuming 11px as the base font size, adjust as necessary */
  line-height: 1;
  color: #f36421;
  &.fulfilment-positive {
    color: #d9534f; /* Replace with your actual error color */
  }
  &.fulfilment-zero {
    color: #5cb85c; /* Replace with your actual success color */
  }
  &.fulfilment-negative {
    color: #0288d1; /* Replace with your actual info color */
  }
`;

function getFulfilmentClassName(fulfilment: number | null | undefined) {
  const number = fulfilment ?? 0;
  if (number > 0) {
    return "fulfilment-positive";
  } else if (number === 0) {
    return "fulfilment-zero";
  } else {
    return "fulfilment-negative";
  }
}
