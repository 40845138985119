import { CommonOptions } from "@fragaria/address-formatter";

import { DurationUnitFormat } from "@sinch/utils/durationUnitFormat/DurationUnitFormat";
import { NumberFormat } from "libphonenumber-js";

/**
 * Definition of custom formats for Format.js lib
 */

export const formats = (timeZone: string | undefined, currency: string | undefined) =>
  ({
    /**
     * Date formats
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
     */
    date: {
      // Only date and month = 2.12
      short: {
        month: "numeric",
        day: "numeric",
        timeZone,
      },
      // Day, month, year = 2.12.2022
      medium: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        timeZone,
      },
      // Full date with time = 2.12.2022 14:00
      full: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "2-digit",
        timeZone,
      },
      // Month, day = Mar 2
      mediumMonth: {
        month: "short",
        day: "numeric",
        timeZone,
      },
      // Month, day, year = Mar 2 2022
      mediumMonthYear: {
        year: "numeric",
        month: "short",
        day: "numeric",
        timeZone,
      },
      // Weekday = Mon, Tue, ...
      weekdayShort: {
        weekday: "short",
        timeZone,
      },
    },

    /**
     * Time formats formats
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
     */
    time: {
      // only hours and minutes = 12:22
      short: {
        hour: "numeric",
        minute: "2-digit",
        timeZone,
      },
      // day of the week = tu
      weekday: {
        weekday: "short",
        timeZone,
      },
    },

    /**
     * Number formats
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
     */
    number: {
      // two decimals
      decShort: {
        maximumFractionDigits: 2,
      },
      currency: {
        style: "currency",
        currencyDisplay: "narrowSymbol",
        currency: currency,
        minimumFractionDigits: 0,
      },
      percent: {
        style: "percent",
        maximumFractionDigits: 0,
      },
      km: {
        style: "unit",
        unit: "kilometer",
        maximumFractionDigits: 1,
      },
      mi: {
        style: "unit",
        unit: "mile",
        maximumFractionDigits: 1,
      },
    },

    /**
     * Duration formats
     * @see https://github.com/piuccio/intl-unofficial-duration-unit-format
     */
    duration: {
      timer: {
        style: DurationUnitFormat.styles.TIMER,
        format: "{hour}:{minutes}:{seconds}",
      },
      narrow: {
        style: DurationUnitFormat.styles.NARROW,
        format: "{hour} {minutes} {seconds}",
      },
      narrowShort: {
        style: DurationUnitFormat.styles.NARROW,
        format: "{hour} {minutes}",
      },
      short: {
        style: DurationUnitFormat.styles.SHORT,
        format: "{hour} {minutes} {seconds}",
      },
      daysTime: {
        style: DurationUnitFormat.styles.SHORT,
        format: "{days} {hour} {minutes}",
      },
      long: {
        style: DurationUnitFormat.styles.LONG,
        format: "{hour} {minutes}",
      },
      days: {
        style: DurationUnitFormat.styles.LONG,
        format: "{day}",
        useFloat: false,
      },
      floatHours: {
        style: DurationUnitFormat.styles.SHORT,
        format: "{hour}",
        useFloat: true,
      },
      floatHoursNarrow: {
        style: DurationUnitFormat.styles.NARROW,
        format: "{hour}",
        useFloat: true,
      },
      floatMinutes: {
        style: DurationUnitFormat.styles.SHORT,
        format: "{minutes}",
        useFloat: true,
      },
    },

    /**
     * Number lists
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/ListFormat/ListFormat
     */
    list: {
      and: { style: "long", type: "conjunction" },
      or: { style: "long", type: "disjunction" },
    },

    /**
     * Bytes formats
     * @see https://github.com/sindresorhus/pretty-bytes#readme
     */
    bytes: {
      bytes: {
        bits: false,
      },
      bits: {
        bits: true,
      },
      bytesPrecise: {
        minimumFractionDigits: 3,
      },
    },

    /**
     * Phone formats
     * @see: https://www.npmjs.com/package/libphonenumber-js
     */
    phone: {
      national: { format: "NATIONAL", foreignNumberPrefix: true },
      international: { format: "INTERNATIONAL" },
      uri: { format: "RFC3966" },
      plain: { format: "E.164" },
    } as Record<
      string,
      {
        format: NumberFormat;
        foreignNumberPrefix?: boolean;
      }
    >,

    /**
     * Address formats
     * @see: https://github.com/fragaria/address-formatter
     */
    address: {
      country: { abbreviate: false, appendCountry: true },
      full: { abbreviate: false, appendCountry: true, cleanupPostcode: true },
      local: { abbreviate: false, appendCountry: false, cleanupPostcode: true },
      short: { abbreviate: true, appendCountry: false, cleanupPostcode: true },
    } as Record<string, CommonOptions>,
  }) as const;
