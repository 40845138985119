import { useTheme } from "@mui/material";

import { displayTextWidth } from "@sinch/utils/dom";
import { useCallback, useEffect, useMemo, useState } from "react";

/**
 * Calculate width of text in pixels, theme font is used for calculation
 */
export const useFontSizeCalculate = (text: string | null, textSize: number) => {
  const theme = useTheme();
  const [fontChanged, setFontChanged] = useState(false);

  const handleFontLoaded = useCallback((fontFaceSetEvent: FontFaceSetEventMap["loadingdone"]) => {
    if (
      (fontFaceSetEvent as FontFaceSetLoadEvent).fontfaces.find(
        (fontface: FontFace) => fontface.family === theme.typography.fontFamily
      )
    ) {
      setFontChanged(true);
    }
  }, []);

  // In case font is loaded after calculation, recalculate width
  useEffect(() => {
    document.fonts.ready.then(function () {
      if (document.fonts.check(`${textSize}px ${theme.typography.fontFamily}`)) {
        setFontChanged(true);
      }
    });
    document.fonts.addEventListener("loadingdone", handleFontLoaded);
    return () => {
      document.fonts.removeEventListener("loadingdone", handleFontLoaded);
    };
  }, [textSize, theme.typography.fontFamily]);

  return useMemo(
    () => (text ? displayTextWidth(text, `${textSize}px ${theme.typography.fontFamily}`) : null),
    [text, textSize, fontChanged, theme.typography.fontFamily]
  );
};
