import { Divider, Link, Stack, Tooltip, Typography } from "@mui/material";
import { useFormat } from "@sinch/core/hooks/format";
import {
  MdsAlarmXs,
  MdsAssignmentTurnedInXs,
  MdsLocationOnXs,
  MdsMailXs,
  MdsPhoneIphoneXs,
  MdsTimelapseXs,
  SinchAbsentXs,
  SinchAmbulanceXs,
} from "@sinch/icons";

import { rejectFalsy } from "@sinch/utils/array";
import { parseAddress } from "@sinch/utils/geolocation/address";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React from "react";
import { useTranslation } from "react-i18next";
import { StaffFragment } from "../application/StaffDetailPanel/queries.types";
import { UserAvatar } from "../application/UserAvatar/UserAvatar";
import { useWorker } from "../application/Worker/hooks";
import { IconListRow } from "../IconListRow/IconListRow";
import { MdsIcon } from "../MdsIcon/MdsIcon";

interface StaffHeaderProps {
  staff: StaffFragment;
}

export const StaffHeader: React.FC<StaffHeaderProps> = ({ staff }) => {
  const { phone, address, num } = useFormat();
  const { t } = useTranslation();

  const { getWorkerName } = useWorker();

  const assigmentStat = staff.attendanceStatistics.presentCount / staff.attendanceStatistics.totalCount;
  const dataPairs = rejectFalsy([
    {
      icon: MdsTimelapseXs,
      value: staff.attendanceStatistics.totalCount,
      title: t("ShiftTimeline.shiftsInTotal"),
    },
    {
      icon: MdsAssignmentTurnedInXs,
      value: Number.isNaN(assigmentStat) ? "N/A" : num.percent(assigmentStat),
      title: t("ShiftTimeline.noProblem"),
    },
    {
      icon: MdsAlarmXs,
      value: staff.attendanceStatistics.lateCount,
      title: t("ClosingForm.presenceLate"),
    },
    {
      icon: SinchAmbulanceXs,
      value: staff.attendanceStatistics.excusedCount,
      title: t("ClosingForm.presenceExcused"),
    },
    {
      icon: SinchAbsentXs,
      value: staff.attendanceStatistics.missCount,
      title: t("ShiftTimeline.notPresent"),
    },
  ]);

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: "start",
        }}
      >
        <UserAvatar worker={staff} />
        <Stack direction="column">
          <Stack direction="row">
            <Typography
              sx={{
                fontSize: "17px",
                fontWeight: 500,
                lineHeight: "20px",
              }}
            >
              {getWorkerName(staff)}
            </Typography>
            &nbsp;(
            <Typography
              sx={{
                color: "success.main",
              }}
            >
              {staff.workerinfo?.rating ?? 0}
            </Typography>
            )
          </Stack>
          <Stack
            direction="row"
            divider={
              <Divider
                orientation="vertical"
                sx={{
                  borderColor: ({ palette }) => palette.grey["800"],
                  height: "14px",
                  marginRight: "6px !important",
                }}
                variant="middle"
              />
            }
            spacing={0.75}
            sx={{
              alignItems: "flex-start",
              flexWrap: "wrap",
              mb: 1,
              mt: 0.5,
            }}
          >
            {staff.professions?.map((profession) => (
              <Typography
                key={profession.id}
                component="span"
                noWrap
                style={{ margin: 0, padding: 0 }}
                sx={{
                  lineHeight: "15px",
                }}
                variant="descriptionSmall"
              >
                {profession.name}
              </Typography>
            ))}
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              flexWrap: "wrap",
            }}
          >
            {dataPairs.map((pair, i) => (
              <Stat key={i} icon={pair.icon} title={pair.title} value={pair.value} />
            ))}
          </Stack>
        </Stack>
      </Stack>
      {staff.workerinfo && isNotNilOrEmpty(parseAddress(staff.workerinfo)) && (
        <IconListRow icon={MdsLocationOnXs}>
          <Typography>{address.full(staff.workerinfo)}</Typography>
        </IconListRow>
      )}
      {isNotNilOrEmpty(staff.email) && (
        <IconListRow icon={MdsMailXs}>
          <Link
            href={staff.email ? `mailto:${staff.email}` : undefined}
            sx={(theme) => ({
              "&:hover": {
                color: theme.palette.primary.main,
              },
            })}
            variant="descriptionSmall"
          >
            {staff.email}
          </Link>
        </IconListRow>
      )}
      {isNotNilOrEmpty(staff.phone) && (
        <IconListRow icon={MdsPhoneIphoneXs}>
          <Link
            href={staff.phone ? phone.uri(staff.phone) : undefined}
            sx={(theme) => ({
              "&:hover": {
                color: theme.palette.primary.main,
              },
            })}
            variant="descriptionSmall"
          >
            {staff.phone && phone.national(staff.phone)}
          </Link>
        </IconListRow>
      )}
    </Stack>
  );
};

const Stat: React.FC<{ icon: React.ElementType; value: string | number; title: string }> = ({ icon, value, title }) => (
  <Tooltip enterTouchDelay={0} placement="top" title={title}>
    <Stack
      direction="row"
      spacing={0.25}
      sx={{
        alignItems: "center",
      }}
    >
      <MdsIcon fontSize="small" icon={icon} sx={{ color: ({ palette }) => palette.grey["800"] }} />
      <Typography
        sx={{
          color: ({ palette }) => palette.grey["800"],
          lineHeight: 1,
        }}
        variant="descriptionSmallBold"
      >
        {value}
      </Typography>
    </Stack>
  </Tooltip>
);
