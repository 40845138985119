import type { RequiredAndNonNullable } from "@sinch/types/utility.types";

import { add, both, either, includes, is, isEmpty, isNil, map, mergeWith, pipe, reduce, reject, when } from "ramda";
import { isArray, isNotNil } from "ramda-adjunct";

/**
 * Recursively remove empty values from object
 */
export function cleanNested<T>(obj: T): T {
  return pipe(
    map(
      when(
        both(is(Object), (item) => !(item instanceof Date)),
        cleanNested
      )
    ),
    reject(either(isNil, isEmpty))
  )(obj as T[]) as T; // Type definition for ramda is not defined for objects
}

export const hasTruthyProps =
  <Prop extends keyof any>(props: Prop[]) =>
  <ObjectIn extends { [K in Prop]?: any }>(o: ObjectIn): o is ObjectIn & RequiredAndNonNullable<Pick<ObjectIn, Prop>> =>
    props.every((prop) => !!o[prop as keyof ObjectIn]);

/**
 * Calculates the sum of numeric properties of an object.
 *
 * @example sumByProps<{ a: number, b: number }>()([{ a: 1, b: 2 }, { a: 3, b: 4 }]) // { a: 4, b: 6 }
 */
export const sumByProps = <TObj extends Record<string, number> = Record<string, number>>(list: TObj[]): TObj =>
  reduce<TObj, TObj>(mergeWith(add), {} as TObj)(list);

export const hasNotProp =
  <T,>(excludedList: (keyof T | string)[]): ((propName: keyof T | string) => boolean) =>
  (propName) =>
    !includes(propName, excludedList);

/**
 * If first argument is true, return second argument, otherwise return empty type of array or object base on first argument
 */
export function includeIf<T extends Record<string, any> | never[]>(
  cond: boolean,
  val: T
): T | (T extends never[] ? [] : Record<string, never>);
/**
 * If first argument is true, return second argument, otherwise return third argument
 */
export function includeIf<
  T extends Record<string, never> | never[],
  D extends T extends never[] ? never[] : Record<string, any>,
>(cond: boolean, val: T, def?: D): T | D;
export function includeIf<
  T extends Record<string, any> | never[],
  D extends T extends never[] ? never[] : Record<string, any>,
>(cond: boolean, val: T, def?: D): T | D | Record<string, never> | [] {
  if (cond) {
    return val;
  } else if (isNotNil(def)) {
    return def;
  }
  return isArray(val) ? [] : {};
}
