// eslint-disable-next-line import/no-internal-modules
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
// eslint-disable-next-line import/no-internal-modules
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  SELECTION_CHANGE_COMMAND,
  TextFormatType,
} from "lexical";
import { useCallback, useEffect, useState } from "react";

export const useFormatText = (textFormatType: TextFormatType): [() => void, boolean] => {
  const [editor] = useLexicalComposerContext();
  const [isFormatted, setIsFormatted] = useState<boolean>(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      // Update text format
      setIsFormatted(selection.hasFormat(textFormatType));
    }
  }, [textFormatType]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateToolbar();
          });
        }),
        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateToolbar();
            return false;
          },
          COMMAND_PRIORITY_LOW
        )
      ),
    [editor, updateToolbar]
  );

  return [() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, textFormatType), isFormatted];
};
