import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { PropsWithChildren, useContext, useMemo } from "react";
import {
  FieldArray,
  FieldArrayPath,
  FieldArrayWithId,
  FieldValues,
  UseFieldArrayReturn,
  useFormContext,
} from "react-hook-form";

const FieldArrayContext = React.createContext<any | undefined>(undefined);
FieldArrayContext.displayName = "FieldArray";

export type UseFieldsArrayMethods<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
> = Omit<UseFieldArrayReturn<TFieldValues, TFieldArrayName>, "fields">;

interface FieldArrayProviderProps<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
> extends PropsWithChildren {
  /**
   * Prefix for form fields
   */
  prefix: `${TFieldArrayName}.${number}`;
  /**
   * Index of field in array
   */
  parentIndex: number;
  /**
   * FieldArray methods
   */
  methods: UseFieldsArrayMethods<TFieldValues, TFieldArrayName>;
}

/**
 * Provide context of current used FieldArray with methods for easy manipulating and accessing fields
 */
export function FieldArrayProvider<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
>({ children, methods, parentIndex, prefix }: FieldArrayProviderProps<TFieldValues, TFieldArrayName>) {
  return <FieldArrayContext.Provider value={{ prefix, parentIndex, methods }}>{children}</FieldArrayContext.Provider>;
}

interface UseFieldArrayContextReturn<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
> {
  /**
   * Prefix for form fields (with added dot at the end)
   */
  prefix: `${TFieldArrayName}.${number}.`;
  /**
   * Scope (name and index) of field
   */
  scope: `${TFieldArrayName}.${number}`;
  /**
   * Index of field in array
   */
  parentIndex: number;
  /**
   * Swap field with another field
   */
  swapWith: (to: number) => void;
  /**
   * Move field to another position
   */
  moveTo: (to: number) => void;
  /**
   * Update field with new values, if function provided, as argument of function is provided current values of field
   */
  update: (
    obj:
      | FieldArray<TFieldValues, TFieldArrayName>
      | ((values: FieldArrayWithId<TFieldValues, TFieldArrayName>) => FieldArray<TFieldValues, TFieldArrayName>)
  ) => void;
  /**
   * Remove field from array
   */
  remove: () => void;
  /**
   * Copy field and add to end of array
   */
  copy: () => void;
}

/**
 * Provide methods for easy manipulation and access of FieldArray props
 */
export function useFieldArrayContext<
  TFieldValues extends FieldValues = FieldValues,
  TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
>(): UseFieldArrayContextReturn<TFieldValues, TFieldArrayName> {
  const context = useContext<FieldArrayProviderProps<TFieldValues, TFieldArrayName>>(FieldArrayContext);
  const { getValues } = useFormContext();
  return useMemo(
    () => ({
      prefix: `${context?.prefix ?? ""}.`,
      scope: context?.prefix ?? "",
      parentIndex: context?.parentIndex ?? 0,
      moveTo: (to) => context?.methods.move(context?.parentIndex, to),
      update: (obj) => {
        if (typeof obj === "function" && isNotNilOrEmpty(context?.prefix)) {
          const values = getValues(context?.prefix as string);
          // @ts-expect-error obj is function
          context?.methods.update(context?.parentIndex, obj(values));
          return;
        }
        // @ts-expect-error obj is object
        context?.methods.update(context?.parentIndex, obj);
      },
      swapWith: (to) => context?.methods.swap(context?.parentIndex, to),
      remove: () => context?.methods.remove(context?.parentIndex),
      copy: () => {
        if (isNotNilOrEmpty(context?.prefix)) {
          context?.methods.append(getValues(context?.prefix));
        }
      },
    }),
    [context, getValues]
  );
}
