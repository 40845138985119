import { Box, Table, TableBody, TableContainer, Typography } from "@mui/material";
import React, { FC, Fragment, ReactNode } from "react";
import { usePosition } from "../../Position/usePosition";
import { ShiftPanelDetailFragment } from "../queries.types";
import { PositionHead } from "./PositionHead";

interface ShiftAttendanceTableProps {
  shift: ShiftPanelDetailFragment;
  renderRows: (position: ShiftPanelDetailFragment["positions"][0]) => React.ReactNode;
  label: ReactNode;
  labelIcon?: ReactNode;
  /**
   * If true, capacities of position will be added to end of row
   */
  includeCapacity?: boolean;
}
export const ShiftAttendanceTable: FC<ShiftAttendanceTableProps> = ({
  shift,
  renderRows,
  label,
  labelIcon,
  includeCapacity,
}) => {
  const { sortPositions } = usePosition();
  const sortedPositions = sortPositions(shift.positions);
  return (
    <TableContainer>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          gap: 1,
          mb: 1,
          ml: 2,
        }}
      >
        {labelIcon}
        <Typography variant="descriptionSemi">{label}</Typography>
      </Box>
      <Table padding="none">
        <TableBody>
          {sortedPositions.map((position) => (
            <Fragment key={position.id}>
              <PositionHead includeCapacity={includeCapacity} position={position} />
              {renderRows(position)}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
