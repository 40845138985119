import { Switch, SwitchProps } from "@mui/material";
import { isNil } from "ramda";
import React, { FC } from "react";
import {
  BinaryInputControlWrapperProps,
  BinaryInputControlWrapper,
} from "../BinaryInputControlWrapper/BinaryInputControlWrapper";

/**
 * Switch input extended with tooltip
 */
export interface SwitchInputProps
  extends SwitchProps,
    Pick<
      BinaryInputControlWrapperProps,
      "helperText" | "FormHelperTextProps" | "infoTooltip" | "label" | "error" | "errorMessage"
    > {
  value?: boolean;
}

/**
 * Switch input, see [https://mui.com/material-ui/api/switch/](https://mui.com/material-ui/api/switch/)
 */
export const SwitchInput: FC<SwitchInputProps> = ({
  helperText,
  FormHelperTextProps: formHelperTextProps,
  infoTooltip,
  label,
  error,
  errorMessage,
  required,
  value,
  color = "success",
  disabled,
  ...props
}) => (
  <BinaryInputControlWrapper
    disabled={disabled}
    error={error}
    errorMessage={errorMessage}
    helperText={helperText}
    infoTooltip={infoTooltip}
    label={label}
    required={required}
  >
    <Switch
      checked={!isNil(value) && value}
      color={error ? "error" : color}
      disabled={disabled}
      required={required}
      value={value}
      {...props}
    />
  </BinaryInputControlWrapper>
);
