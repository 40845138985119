import { Stack, Typography } from "@mui/material";

import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import { MdsLink } from "@sinch/icons";
import { isNil } from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxInput } from "../../form/CheckboxInput/CheckboxInput";
import { useMuiController } from "../../form/MuiController/MuiController";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { SimplePositionsTable } from "../SimplePositionsTable/SimplePositionsTable";
import { GroupedPositions } from "./queries.graphql";
import { GroupedPositionsQuery, GroupedPositionsQueryVariables } from "./queries.types";
import { SignInDialogFormFields } from "./types";

/**
 * Interface for LinkedPositionsCheckbox component props
 */
interface LinkedPositionsCheckboxProps {
  /**
   * Optional array of linked position IDs. Positions detail are fetched from server
   */
  linkedPositions?: string[];
  /**
   * Name of the worker
   */
  workerName: string;
  /**
   * Name of the position
   */
  positionName: string;
}

/**
 * Component for displaying a checkbox to join linked positions and a table of linked positions.
 */
export const LinkedPositionsCheckbox: FC<LinkedPositionsCheckboxProps> = ({
  linkedPositions,
  workerName,
  positionName,
}) => {
  const { t } = useTranslation();

  // Fetch linked positions data from the server
  const { data, loading } = useQueryHandler<GroupedPositionsQuery, GroupedPositionsQueryVariables>(
    GroupedPositions,
    { positionIds: linkedPositions ?? [] },
    { skip: isNilOrEmpty(linkedPositions) }
  );

  const { field } = useMuiController<SignInDialogFormFields, "joinLinked">({
    name: "joinLinked",
  });

  return (
    <Stack spacing={2}>
      <Typography>
        {t("SignInDialog.linkedPositionsDescription", {
          workerName: (
            <Typography component="span" fontWeight="bold">
              {workerName}
            </Typography>
          ),
          positionName: (
            <Typography component="span" fontWeight="bold">
              {positionName}
            </Typography>
          ),
        })}
      </Typography>
      <CheckboxInput {...field} color="info" label={t("SignInDialog.addStaffToLinkedPositions")} />
      <SimplePositionsTable
        label={t("SignInDialog.linkedPositions")}
        labelIcon={<MdsIcon icon={MdsLink} />}
        loading={loading || isNil(data?.positions) ? (linkedPositions?.length ?? true) : false}
        positions={data?.positions ?? []}
      />
    </Stack>
  );
};
