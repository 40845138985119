import { parseJsonSafe } from "@sinch/utils/json";
import { isNilOrEmpty } from "ramda-adjunct";
import { useCallback, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";

export interface UseLocalStorage<TFieldValues extends FieldValues> {
  /**
   * Value to be saved to localstorage
   */
  localStorage: TFieldValues;
  /**
   * Function to get saved value from localstorage
   */
  setLocalStorage: (value: TFieldValues) => void;
}

/**
 * Custom hook to manage local storage with optional expiration.
 *
 * @template TFieldValues - The type of the stored value.
 * @param {string} localStorageKey - The key to store the value under in local storage.
 * @param {Object} [options] - Optional settings.
 * @param {TFieldValues} [options.defaultValue] - The default value to use if none is found in local storage.
 * @param {Date} [options.expiration] - The expiration date for the stored value.
 * @returns {[TFieldValues | undefined, (value: TFieldValues) => void, () => void]} - The stored value, a function to update it, and a function to remove it.
 */
export const useLocalStorage = <TFieldValues = any,>(
  localStorageKey: string,
  { defaultValue, expiration }: { defaultValue?: TFieldValues; expiration?: Date } = {}
): [TFieldValues | undefined, (value: TFieldValues) => void, () => void] => {
  const initStoreValue = getLocalStore(localStorageKey);
  const initDefaultValue = !isNilOrEmpty(initStoreValue) ? initStoreValue : defaultValue;

  const [localStorage, setLocalStorage] = useState<TFieldValues | undefined>(initDefaultValue);

  /**
   * Handle changes to the local storage.
   */
  const handleStoreChange = useCallback(() => {
    const storageData = window.localStorage.getItem(localStorageKey);
    if (storageData !== JSON.stringify(localStorage)) {
      setLocalStorage(parseJsonSafe(storageData));
    }
  }, [localStorageKey]);

  useEffect(() => {
    if (isNilOrEmpty(localStorage)) {
      removeLocalStorage(localStorageKey)();
    } else {
      setLocalStore(localStorageKey, localStorage, expiration);
    }
  }, [localStorage, localStorageKey]);

  useEffect(() => {
    window.addEventListener("storage", handleStoreChange);
    return () => window.removeEventListener("storage", handleStoreChange);
  }, [handleStoreChange]);

  if (isExpired(localStorageKey)) {
    removeLocalStorage(localStorageKey)();
    setLocalStorage(defaultValue);
  }

  return [localStorage, setLocalStorage, removeLocalStorage(localStorageKey)];
};

/**
 * Set a value in local storage with optional expiration.
 *
 * @param {string} localStorageKey - The key to store the value under in local storage.
 * @param {any} state - The value to store.
 * @param {Date} [expiration] - The expiration date for the stored value.
 */
const setLocalStore = (localStorageKey: string, state: any, expiration?: Date) => {
  window.localStorage.setItem(localStorageKey, JSON.stringify(state));
  if (expiration) {
    window.localStorage.setItem(`__expiration__${localStorageKey}`, expiration.toISOString());
  }
};

/**
 * Remove a value from local storage.
 *
 * @param {string} localStorageKey - The key of the value to remove from local storage.
 * @returns {() => void} - A function to remove the value.
 */
const removeLocalStorage = (localStorageKey: string) => () => {
  window.localStorage.removeItem(localStorageKey);
  window.localStorage.removeItem(`__expiration__${localStorageKey}`);
};

/**
 * Get a value from local storage.
 *
 * @param {string} localStorageKey - The key of the value to retrieve from local storage.
 * @returns {any} - The retrieved value, or null if not found.
 */
const getLocalStore = (localStorageKey: string) => {
  const data = window.localStorage.getItem(localStorageKey);
  if (data === null) {
    return null;
  }
  return parseJsonSafe(data);
};

/**
 * Check if a value in local storage has expired.
 *
 * @param {string} localStorageKey - The key of the value to check.
 * @returns {boolean} - True if the value has expired, false otherwise.
 */
const isExpired = (localStorageKey: string) => {
  const data = window.localStorage.getItem(`__expiration__${localStorageKey}`);
  if (data === null) {
    return false;
  }
  return new Date(data) < new Date();
};
