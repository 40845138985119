import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { Box, Divider, IconButton, InputAdornment, Link, Stack, Tooltip } from "@mui/material";
import { MdsCheck, MdsClose, MdsEdit, MdsLinkOff, MdsLinkXs, MdsOpenInNewXs } from "@sinch/icons";
import React, { FC, useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { TextInput } from "../../TextInput/TextInput";
import { DEFAULT_LINK_URL, useLink } from "../hooks/useLink";
import { FloatingBoxPlugin } from "../plugins/FloatingBoxPlugin";

export const LinkFloatingBox: FC = () => {
  const { t } = useTranslation();
  const [isEditMode, setEditMode] = useState(false);
  const [editUrl, setEditUrl] = useState("");
  const { isLink, url, save, remove } = useLink();

  const [editor] = useLexicalComposerContext();
  const disabled = !editor.isEditable();

  const focusRef = useHotkeys(
    "Enter",
    () => {
      handleConfirm();
    },
    {
      enableOnFormTags: ["input"],
      preventDefault: true,
    }
  );

  useEffect(() => {
    if (isLink && !disabled && DEFAULT_LINK_URL === url) {
      setEditUrl(DEFAULT_LINK_URL);
      setEditMode(true);
    }
  }, [disabled, url, isLink]);

  const handleConfirm = () => {
    setEditMode(false);
    save(editUrl);
  };

  if (!isLink || disabled) {
    return null;
  }

  return (
    <FloatingBoxPlugin>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          alignItems: "center",
        }}
      >
        {isEditMode ? (
          <>
            <TextInput
              ref={focusRef}
              onChange={(event) => {
                setEditUrl(event.target.value);
              }}
              size="small"
              startAdornment={
                <InputAdornment position="start">
                  <MdsIcon fontSize="xs" icon={MdsLinkXs} />
                </InputAdornment>
              }
              value={editUrl}
            />
            <Tooltip placement="top" title={t("Action.save")}>
              <IconButton color="info" onClick={handleConfirm} size="small">
                <MdsIcon icon={MdsCheck} />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title={t("cancel")}>
              <IconButton
                color="error"
                onClick={() => {
                  setEditMode(false);
                  setEditUrl(url);
                }}
              >
                <MdsIcon icon={MdsClose} />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
            <Link
              href={url}
              sx={{
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
                minWidth: 0,
              }}
              target="_blank"
            >
              <Box
                component="span"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {url}
              </Box>
              <MdsIcon fontSize="xs" icon={MdsOpenInNewXs} sx={{ ml: 0.5 }} />
            </Link>
            <Divider flexItem orientation="vertical" />
            <Tooltip placement="top" title={t("Action.edit")}>
              <IconButton
                color="info"
                onClick={() => {
                  setEditUrl(url);
                  setEditMode(true);
                }}
                size="small"
              >
                <MdsIcon icon={MdsEdit} />
              </IconButton>
            </Tooltip>
            <Tooltip placement="top" title={t("Action.remove")}>
              <IconButton color="error" onClick={remove} size="small">
                <MdsIcon icon={MdsLinkOff} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Stack>
    </FloatingBoxPlugin>
  );
};
