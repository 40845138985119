import type { DocumentNode } from "@apollo/client";
import { ApolloCache, ApolloError, FetchResult } from "@apollo/client";

import { MutationData } from "@sinch/hooks/useFormMutation.types";
import { SinchMutations } from "@sinch/hooks/useMutationHandler";
import type { Mutation as Mutations, MutationMessage, MutationResponseInterface } from "@sinch/types/sinch.types";

import { keys, prop, values } from "ramda";
import { isNotNil } from "ramda-adjunct";

export const parseMutationErrorsFromData = <TData extends Partial<Record<keyof Mutations, MutationResponseInterface>>>(
  data: TData
): MutationMessage[] => {
  if (!data) return [];

  return values(data)
    .filter((res) => !prop("result", res) && prop("messages", res))
    .flatMap(prop("messages")) as MutationMessage[];
};

export const extractMutationData = <TData extends SinchMutations>(result: FetchResult<TData>): TData =>
  result.data as TData;

export class FormSubmitError extends Error {
  messages: string[];

  constructor(messages: string[], options?: ErrorOptions) {
    super(messages.join("\n"), options);
    this.messages = messages;
  }
}

export enum ApolloErrorQueryType {
  Mutation = "mutation",
  Query = "query",
}
export class ApolloMutationError extends ApolloError {
  queryType: ApolloErrorQueryType;

  constructor({ type, ...options }: ConstructorParameters<typeof ApolloError>[0] & { type: ApolloErrorQueryType }) {
    super(options);
    this.queryType = type;
  }
}

export const throwIfNoMutationData = <TData extends Partial<Record<keyof Mutations, MutationResponseInterface>>>(
  data?: MutationData<TData> | null
): data is TData => {
  if (!data || !keys(data).length) {
    throw new FormSubmitError(["Received empty response"]);
  }

  return true;
};

interface UpdateQueryArgs<TVariables = any, TData = any> {
  cache: ApolloCache<any>;
  variables: TVariables;
  query: DocumentNode;
  mergeFn: (originalData: TData | null) => TData | null;
}
export const updateQuery = <TData = any, TVariables = any>({
  cache,
  mergeFn,
  query,
  variables,
}: UpdateQueryArgs<TVariables, TData>) => {
  const cachedList = cache.readQuery<TData, TVariables>({
    variables: variables,
    query: query,
  });
  const newData = mergeFn(cachedList);
  if (isNotNil(newData)) {
    cache.writeQuery<TData, TVariables>({
      query: query,
      data: newData,
      variables: variables,
    });
  }
};
