import { Box, Button, ButtonProps, styled } from "@mui/material";
import { MdsKeyboardArrowDown, MdsKeyboardArrowUp } from "@sinch/icons";
import React, { ElementType, PropsWithChildren, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../MdsIcon/MdsIcon";

interface TextExtenderProps extends ButtonProps {
  lineHeight: number;
  maxLines?: number;
  slots?: {
    expandButton?: ElementType;
  };
  slotProps?: {
    expandButton?: ButtonProps & Record<string, unknown>;
  };
}

export const TextExtender: React.FC<PropsWithChildren<TextExtenderProps>> = ({
  lineHeight,
  maxLines = 2,
  children,
  slots,
  slotProps,
  ...buttonProps
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>();
  const [expanded, setExpanded] = useState<boolean | null>(null);
  useEffect(() => {
    const height = ref.current?.clientHeight;
    if (height && height > lineHeight * (maxLines + 1)) {
      setExpanded(false);
    }
  }, [lineHeight, maxLines]);

  const handleExpand = () => setExpanded(true);
  const handleCollapse = () => setExpanded(false);

  const ExtendButton = slots?.expandButton ?? ExtenderButton;

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        ref={ref}
        sx={{
          maxHeight: expanded === false ? lineHeight * maxLines : undefined,
          overflow: "hidden",
          position: "relative",
          backgroundColor: "inherit",

          mask:
            expanded === false
              ? `linear-gradient(to bottom,
          rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 40%,
          rgba(0,0,0, 0) 95%, rgba(0,0,0, 0) 0
          ) 100% 50% / 100% 100% repeat-x`
              : undefined,
        }}
      >
        {children}
      </Box>
      {expanded !== null && (
        <ExtendButton
          endIcon={<MdsIcon icon={expanded ? MdsKeyboardArrowUp : MdsKeyboardArrowDown} />}
          lineHeight={lineHeight}
          onClick={expanded ? handleCollapse : handleExpand}
          {...buttonProps}
          {...slotProps?.expandButton}
        >
          {expanded ? t("showLess") : t("showMore")}
        </ExtendButton>
      )}
    </Box>
  );
};

const ExtenderButton = styled(Button, { shouldForwardProp: (propName) => propName !== "lineHeight" })<
  ButtonProps & { lineHeight: number }
>(({ lineHeight, theme }) => ({
  height: lineHeight,
  marginY: theme.spacing(0.5),
}));
