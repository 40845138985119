import { styled } from "@mui/material";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import { hasNotProp } from "@sinch/utils/object";
import { Interval } from "luxon";
import { TimeBarTimePointProps } from "./types";

export const TimeBarTimePoint = styled("div", {
  shouldForwardProp: hasNotProp<TimeBarTimePointProps>(["ref", "sx", "side", "rangeStart", "rangeEnd", "point"]),
})<TimeBarTimePointProps>(({ theme, side = "left", rangeStart, rangeEnd, point }) => {
  const startDate = toLuxon(rangeStart);
  const endDate = toLuxon(rangeEnd);

  const intervalMillisDuration = endDate.diff(startDate).as("milliseconds");
  const percentPosition =
    (Interval.fromDateTimes(rangeStart, point).toDuration().as("milliseconds") / intervalMillisDuration) * 100;

  return {
    color: theme.palette.custom.deepSpace[500],
    position: "absolute",
    minWidth: "1px",
    height: "100%",
    top: 0,
    zIndex: "inherit",
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    ...(side === "left"
      ? {
          paddingLeft: theme.spacing(0.5),
          justifyContent: "flex-start",
          left: `${percentPosition}%`,
          borderLeft: `1px solid ${theme.palette.custom.deepSpace[500]}`,
        }
      : {
          paddingRight: theme.spacing(0.5),
          justifyContent: "flex-end",
          right: `calc(${100 - percentPosition}% - 1px)`,
          borderRight: `1px solid ${theme.palette.custom.deepSpace[500]}`,
        }),
    "&:empty": {
      padding: 0,
    },
  };
});
