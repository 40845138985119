import { isNil, pluck, prop } from "ramda";
import { isFunction, isNotEmpty, isNotNil } from "ramda-adjunct";

type OptionValue = string | number;
type OptionLabel = string;

export interface Option<V extends OptionValue = string, L extends OptionLabel = string> {
  value: V;
  label: L;
}

type ExtractStrings<T> = Extract<T, string>;

/**
 * Map object to select/autocomplete options
 * Example 1: `mapToOptions('id', 'name')([{id: 1, name: 'A'}, {id: 2, name: 'B'}])` return [{value: 1, label: 'A'}, {value: 2, label: 'B'}]
 * Example 2: `mapToOptions('id', ({id, name}) => `${id}: ${name}`)([{id: 1, name: 'A'}, {id: 2, name: 'B'}])` return [{value: 1, label: '1: A'}, {value: 2, label: '2: B'}]
 */
export const mapToOptions =
  <
    ValueKey extends string,
    // There is no sane way to type this :( https://stackoverflow.com/questions/76469429/currying-breaks-argument-type-inference-because-argument-list-gets-split-in-two
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    LabelKey extends string | ((objIn: Record<keyof any, any>) => OptionLabel),
  >(
    valueKey: ValueKey,
    labelKey: LabelKey
  ) =>
  <
    ObjectIn extends Record<ExtractStrings<ValueKey | LabelKey>, OptionValue>,
    Value extends OptionValue = OptionValue,
    Label extends OptionLabel = OptionLabel,
  >(
    listIn: readonly (ObjectIn | undefined | null)[] | undefined | null
  ): Option<Value, Label>[] => {
    if (!listIn) {
      return [];
    }

    return listIn.map(toOption(valueKey, labelKey)).filter(isNotNil) as Option<Value, Label>[];
  };

export const toOption =
  <
    ValueKey extends string,
    // There is no sane way to type this :(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    LabelKey extends string | ((objIn: Record<keyof any, any>) => OptionLabel),
  >(
    valueKey: ValueKey,
    labelKey: LabelKey
  ) =>
  <
    ObjectIn extends Record<ExtractStrings<ValueKey | LabelKey>, OptionValue>,
    Value extends OptionValue = OptionValue,
    Label extends OptionLabel = OptionLabel,
  >(
    objectIn: ObjectIn | null | undefined
  ): Option<Value, Label> | null => {
    if (isNil(objectIn)) {
      return null;
    }

    const value = prop(valueKey)(objectIn);
    const label = isFunction(labelKey) ? labelKey(objectIn) : prop(labelKey, objectIn);

    if (isNotEmpty(value) && isNotNil(label)) {
      return { value, label } as unknown as Option<Value, Label>;
    }

    return null;
  };

export const addOptionIfMissing =
  <O extends Option<any, any>>(option?: O | null) =>
  (list: O[]): O[] => {
    if (!option) {
      return list;
    }

    return pluck("value", list).includes(option.value) ? list : [...list, option];
  };
