import { Global } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import type { FullPlaceFragment } from "@sinch/core/entities/fragments.types";
import { isEmpty } from "ramda";
import React, { FC, useEffect, useRef } from "react";
import { useFormContext, ValidateResult } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DialogCloseIcon } from "../../../DialogCloseIcon/DialogCloseIcon";
import { FormContainer } from "../../../form/FormContainer/FormContainer";
import { HandleErrorBox } from "../../../HandleErrorBox/HandleErrorBox";
import { AddressInput } from "./AddressInput";
import { AliasInput } from "./AliasInput";
import { CityInput } from "./CityInput";
import { CountryRegionSelect } from "./CountryRegionSelect";
import { PlaceFormFields } from "./formSchema";
import { useAddNewLocation, useNewLocationDialogForm } from "./hooks";
import { MapCoordinatesSelect } from "./MapCoordinatesSelect";
import { NameInput } from "./NameInput";
import { NoteInput } from "./NoteInput";
import { ZipInput } from "./ZipInput";

export interface AddNewLocationDialogProps {
  open: boolean;
  // A getter to prevent having to watch for parent state.
  onCancel: () => void;
  onConfirm: (state: FullPlaceFragment) => void;
}

export const AddNewLocationDialog: FC<AddNewLocationDialogProps> = ({ onCancel, open, onConfirm }) => {
  const { t } = useTranslation();
  const { form, errorId, loading, mutationMessages, submitForm } = useAddNewLocation({ open, onConfirm });

  return (
    <FormContainer form={form} responseMessages={mutationMessages}>
      <Global
        styles={{
          ".pac-container": {
            zIndex: "10005 !important",
          },
        }}
      />

      <Dialog fullWidth maxWidth="sm" onClose={onCancel} open={open} scroll="body">
        <DialogTitle>
          {t("Location.add")}
          <DialogCloseIcon onClick={onCancel} />
        </DialogTitle>
        <DialogContent>
          {errorId && <HandleErrorBox errorId={errorId} />}
          {!isEmpty(form.getFormErrors()) && <HandleFormErrorBox errors={form.getFormErrors()} />}
          <AddPlaceDialogContent />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} size="large">
            {t("cancel")}
          </Button>
          <LoadingButton
            data-cy="locationContinueButton"
            loading={loading}
            onClick={submitForm}
            size="large"
            variant="contained"
          >
            {loading ? t("sending") : t("confirm")}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </FormContainer>
  );
};

export const HandleFormErrorBox: FC<{
  errors: ValidateResult[];
}> = ({ errors }) => {
  const ref = useRef<HTMLDivElement | null>();

  useEffect(() => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, []);

  return (
    <Alert ref={(refEl) => (ref.current = refEl)} icon={false} severity="error" sx={{ mb: 2 }}>
      <Stack>
        {errors.map((message, i) => (
          <Typography key={i}>{message}</Typography>
        ))}
      </Stack>
    </Alert>
  );
};

const AddPlaceDialogContent = () => {
  const form = useFormContext<PlaceFormFields>();

  const { mapCenterCoords, fillPlaceForm, setMapCenterCoords } = useNewLocationDialogForm(form);

  return (
    <Stack direction="column" spacing={3}>
      <NameInput onPlaceChanged={fillPlaceForm} />
      <AddressInput onPlaceChanged={fillPlaceForm} />
      <CityInput />
      <ZipInput />
      <CountryRegionSelect />
      <AliasInput />
      <NoteInput />
      <MapCoordinatesSelect mapCenterCoords={mapCenterCoords} onCoordinateInputChange={setMapCenterCoords} />
    </Stack>
  );
};
