import { Box, BoxProps, styled, SxProps } from "@mui/material";
import { BoxTypeMap } from "@mui/system";
import React, { PropsWithChildren } from "react";
import { useDragAndDropContext } from "../providers/DragAndDropProvider/DragAndDropProvider";
import { useDroppable, useDragAndDrop, TargetData, dndClasses } from "../providers/DragAndDropProvider/useDragAndDrop";

export type DroppableProps<TData = any, TComponent extends React.ElementType = BoxTypeMap["defaultComponent"]> = Omit<
  BoxProps<TComponent>,
  "onDrop"
> &
  TargetData<TData> & {
    /**
     * control of the dnd, get from useDragAndDrop hook
     */
    control?: ReturnType<typeof useDragAndDrop>;
    /**
     * Set styles for drop states
     */
    statesStyles?: {
      droppable?: SxProps;
      over?: SxProps;
      invalidDrop?: SxProps;
    };
  };

/**
 * Represents a droppable container that allows draggable elements to be dropped onto it.
 * @template TData - The type of data which drop container expect
 */
export const Droppable = <TData = any, TComponent extends React.ElementType = "div">({
  control,
  statesStyles,
  onDrop,
  isDroppable,
  onDropLeave,
  onDropOver,
  children,
  isDropInvalid,
  ...props
}: PropsWithChildren<DroppableProps<TData, TComponent>>) => {
  const controlContext = useDragAndDropContext();
  const dndControl = control ?? controlContext;
  const { ref } = useDroppable<TData>({
    control: dndControl,
    onDrop,
    isDroppable,
    isDropInvalid,
    onDropLeave,
    onDropOver,
  });
  return (
    <DroppableWrapper ref={ref} {...props} statesStyles={statesStyles} sx={props.sx}>
      {children}
    </DroppableWrapper>
  );
};

const DroppableWrapper = styled(Box, {
  shouldForwardProp: (propName) => propName !== "statesStyles",
})<
  BoxProps & {
    statesStyles?: {
      droppable?: SxProps;
      over?: SxProps;
      invalidDrop?: SxProps;
    };
  }
>(({ statesStyles }) => ({
  [`&.${dndClasses.droppable}`]: statesStyles?.droppable ?? {},
  [`&.${dndClasses.over}`]: statesStyles?.over ?? {},
  [`&.${dndClasses.invalidDrop}`]: statesStyles?.invalidDrop ?? {},
}));
