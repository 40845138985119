import { InputBaseComponentsPropsOverrides, InputBaseProps, OutlinedInput, OutlinedInputProps } from "@mui/material";
import { isNil } from "ramda";
import React, { ElementType, ForwardedRef, forwardRef, useCallback } from "react";

export type NullableValueOutlinedInputProps<TVal = unknown> = Omit<OutlinedInputProps, "onChange" | "value"> & {
  onChange?: (val: TVal | null) => void;
  value: TVal | null;
};
/**
 * This component allow have nullable OutlinedInput. Original MUI input starting to behave uncontrolled when value is `null`,
 * this component wrap outlined input and allow to behave as controlled when value is `null`
 */
const NullableValueOutlinedInputBase = (
  { onChange, value, ...props }: NullableValueOutlinedInputProps,
  ref?: ForwardedRef<HTMLDivElement | undefined> | undefined
) => {
  const handleChange = useCallback(
    (val: any) => {
      if (val === "") {
        onChange?.(null);
      } else {
        onChange?.(val);
      }
    },
    [onChange]
  );

  return (
    <OutlinedInput
      {...props}
      ref={ref}
      onChange={handleChange}
      slotProps={{
        ...props.slotProps,
        input: { ...props.slotProps?.input, component: props.slots?.input } as InputBaseComponentsPropsOverrides,
      }}
      slots={{ input: NullableValueInputComponent }}
      value={value === null ? "" : value}
    />
  );
};

export const NullableValueOutlinedInput = forwardRef(NullableValueOutlinedInputBase) as <TVal = unknown>(
  props: NullableValueOutlinedInputProps<TVal> & {
    ref?: ForwardedRef<HTMLDivElement | undefined> | undefined;
  }
) => ReturnType<typeof NullableValueOutlinedInputBase>;

const NullableValueInputComponent = forwardRef<
  unknown,
  Omit<NonNullable<NonNullable<InputBaseProps["slotProps"]>["input"]>, "onChange"> & {
    onChange: (val: unknown | string) => void;
    component: ElementType;
  }
>(({ component: Component, ...props }, ref) => (
  <Component
    {...props}
    ref={ref}
    onChange={(val: unknown) => (isNil(val) ? props?.onChange?.("") : props?.onChange?.(val))}
    value={props.value === "" ? null : props.value}
  />
));
NullableValueInputComponent.displayName = "NullableValueInputComponent";
