import React, { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react";

interface BrowserStatus {
  /**
   * Page status, "standard" - page is not unloading, "unloading" - page is unloading
   */
  pageStatus: "standard" | "unloading";
}
/**
 * Create context for get browser status, currently supported only unload status (online/offline, visible/hidden, etc statuses will be implemeted on demand)
 */
export const ContextBrowserStatus = createContext<BrowserStatus>({} as BrowserStatus);

/**
 * Return browser status
 */
export const useBrowserStatus: () => BrowserStatus = () => useContext(ContextBrowserStatus);

/**
 * Provide context for browser status
 */
export const BrowserStatusProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pageStatus, setPageStatus] = useState<BrowserStatus["pageStatus"]>("standard");
  const beforeUnloadListener = () => {
    console.warn("Unloading page, network errors will be suppressed.");
    setPageStatus("unloading");
  };

  useEffect(() => {
    window.addEventListener("beforeunload", beforeUnloadListener);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadListener);
      setPageStatus("standard");
    };
  }, []);
  return <ContextBrowserStatus.Provider value={{ pageStatus }}>{children}</ContextBrowserStatus.Provider>;
};
