import { Stack } from "@mui/material";

import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import React from "react";
import { useWatch } from "react-hook-form";
import { Countries } from "../query.graphql";
import { CountriesQuery } from "../query.types";
import { CountrySelect } from "./CountrySelect";
import { RegionSelect } from "./RegionSelect";
import { hasRegions } from "./utils";

export const CountryRegionSelect = () => {
  const { data } = useQueryHandler<CountriesQuery>(Countries);
  const countries = data?.countries;

  const selectedCountryCode = useWatch({ name: "country" });
  const selectedCountry = countries?.find((country) => country.code === selectedCountryCode);

  return (
    <Stack direction="column" spacing={3}>
      <CountrySelect />
      {selectedCountry && hasRegions(selectedCountry) && <RegionSelect selectedCountry={selectedCountry} />}
    </Stack>
  );
};
