import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEntityProps } from "../utilsHooks";

export enum PrefixedEntities {
  Order = "R",
  Job = "J",
  Shift = "S",
  Position = "P",
}

export enum SinchEntity {
  Order = "order",
  Job = "job",
  Shift = "shift",
  Position = "position",
}

/**
 * Remove prefx for entity if in ID format
 */
export const clearPrefixForSearch = (val: string, prefix: PrefixedEntities) => {
  const regExp = new RegExp("^" + prefix + "(\\d+)$", "i");
  if (!val || !val.trim().match(regExp)) return val;
  return val.trim().replace(regExp, "$1");
};

export const useSinchEntity = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const getProp = useEntityProps({
    [SinchEntity.Order]: {
      label: t("Order.label"),
      prefix: PrefixedEntities.Order,
      color: palette.entity.common.order,
    },
    [SinchEntity.Job]: {
      label: t("Job.label"),
      prefix: PrefixedEntities.Job,
      color: palette.entity.common.job,
    },
    [SinchEntity.Shift]: {
      label: t("Shift.shifts", { count: 1 }),
      prefix: PrefixedEntities.Shift,
      color: palette.entity.common.shift,
    },
    [SinchEntity.Position]: {
      label: t("Position.label"),
      prefix: PrefixedEntities.Position,
      color: palette.entity.common.position,
    },
  } as Record<SinchEntity, { label: string; prefix: string; color: string }>);

  const getEntityLabel = getProp<string>("label");
  const getEntityPrefix = getProp<string>("prefix");
  const getEntityColor = getProp<string>("color");

  const getEntityIdLabel = (entity: SinchEntity, id: string | number): string => `${getEntityPrefix(entity)}${id}`;

  return { getEntityLabel, getEntityPrefix, getEntityColor, getEntityIdLabel };
};
