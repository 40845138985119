import { Divider } from "@mui/material";
import { FactoryArg, InputMask } from "imask";
import React, { FC } from "react";
import { IMaskInput } from "react-imask";
import { DelimiterSchema, SectionSchema } from "./types";
import { isSection } from "./utils";

interface MaskedInputProps {
  item: DelimiterSchema | SectionSchema;
  onComplete: (index: number) => void;
  inputIndex: number;
  handleInputRef: (index: number, ref: HTMLInputElement | null) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>, index: number, size: number) => void;
  onChange: (value: string, mask: InputMask<FactoryArg>, index: number) => void;
  onFocus: () => void;
  disabled?: boolean;
  value?: string;
  name?: string;
  readOnly?: boolean;
}
export const MaskedInput: FC<MaskedInputProps> = ({
  name,
  value,
  onFocus,
  inputIndex,
  onKeyDown,
  handleInputRef,
  item,
  onChange,
  onComplete,
  disabled,
  readOnly,
}) =>
  isSection(item) ? (
    <IMaskInput
      key={item.title}
      // extension of definitions for compatibility with jquery.mask
      definitions={{ A: /[A-Za-z0-9]/, S: /[A-Za-z]/ }}
      disabled={disabled}
      inputRef={(ref) => handleInputRef(inputIndex, ref)}
      mask={item.mask ?? "*".repeat(item.size)}
      name={name}
      onAccept={(newValue, mask) => onChange(newValue, mask, inputIndex)}
      onComplete={() => onComplete(inputIndex)}
      onFocus={onFocus}
      onKeyDown={(e) => onKeyDown(e, inputIndex, item.size)}
      placeholder={item.title}
      readOnly={readOnly}
      size={item.size ?? 1}
      style={{
        border: "none",
        outline: "none",
        marginLeft: "16px",
        minWidth: (item.title.length > item.size ? item.title.length : item.size) * 8,
        width: "100%",
      }}
      value={value}
    />
  ) : (
    <Divider key={item.title} flexItem orientation="vertical">
      {item.title}
    </Divider>
  );
