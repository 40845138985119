import type { DialogProps } from "@mui/material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { FC, PropsWithChildren, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { DialogCloseIcon } from "../DialogCloseIcon/DialogCloseIcon";

export interface ConfirmDialogProps extends PropsWithChildren, Omit<DialogProps, "title"> {
  /**
   * Callback to be called when the user confirms the dialog.
   */
  onConfirm: () => void;
  /**
   * Callback to be called when the user cancels the dialog.
   */
  onCancel: () => void;
  /**
   * Title of the dialog.
   */
  title: ReactNode;
}

/**
 * Dialog with confirm and cancel buttons. Use for confirmation dialog messages
 */
export const ConfirmDialog: FC<ConfirmDialogProps> = ({ onConfirm, onCancel, open, title, children, ...props }) => {
  const { t } = useTranslation();

  return (
    <Dialog onClose={onCancel} open={open} {...props}>
      <DialogTitle>
        {title}
        <DialogCloseIcon onClick={onCancel} />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} size="large">
          {t("cancel")}
        </Button>
        <Button data-cy="confirmDialogConfirmButton" onClick={onConfirm} size="large" variant="contained">
          {t("confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
