import { DateObjectUnits, DateTime, DateTimeUnit, DurationLike, DurationObjectUnits, Zone } from "luxon";
import { equals } from "ramda";
import { toLuxon } from "./toLuxon";

export const add = (date: Date, duration: DurationLike): Date => toLuxon(date).plus(duration).toJSDate();
export const sub = (date: Date, duration: DurationLike): Date => toLuxon(date).minus(duration).toJSDate();
export const isEqual = (date1: Date, date2: Date) => date1.getTime() === date2.getTime();
export const set = (date1: Date, values: DateObjectUnits, timeZone?: string) =>
  toLuxon(date1, timeZone).set(values).toJSDate();

export const isSame =
  (unit: DateTimeUnit | DateTimeUnit[]) =>
  (date1: Date, date2: Date, timeZone?: string | undefined): boolean => {
    if (Array.isArray(unit)) {
      return unit.every((u) => isSame(u)(date1, date2, timeZone));
    }

    const luxon1 = toLuxon(date1, timeZone);
    const luxon2 = toLuxon(date2, timeZone);
    return luxon1.hasSame(luxon2, unit);
  };

export const differenceIn = (unit: keyof DurationObjectUnits) => (date1: Date, date2: Date) => {
  const luxon1 = toLuxon(date1);
  const luxon2 = toLuxon(date2);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return luxon1.diff(luxon2, unit).toObject()[unit]!;
};

export const startOf = (unit: DateTimeUnit) => (date: Date, timeZone?: string | undefined) =>
  toLuxon(date, timeZone).startOf(unit).toJSDate();

/**
 * Get min date from list of dates
 */
export const min = (dates: Date[]) => DateTime.min(...dates.map((date: Date) => DateTime.fromJSDate(date))).toJSDate();
/**
 * Get max date from list of dates
 */
export const max = (dates: Date[]) => DateTime.max(...dates.map((date: Date) => DateTime.fromJSDate(date))).toJSDate();

/**
 * Floor date to nearest unit (day, hours, etc)
 */
export const floorTo = (unit: DateTimeUnit, zone?: string | Zone) => (date: Date) =>
  DateTime.fromJSDate(date).setZone(zone).startOf(unit).toJSDate();

/**
 * Ceil date to nearest unit (day, hours, etc)
 */
export const ceilTo = (unit: DateTimeUnit, zone?: string | Zone) => (date: Date) =>
  DateTime.fromJSDate(date).setZone(zone).endOf(unit).toJSDate();

/**
 * Check if times are equal, ignoring date
 */
export const isTimesEquals = (dateA: Date, dateB: Date) =>
  equals(
    DateTime.fromJSDate(dateA).toLocaleString(DateTime.TIME_SIMPLE),
    DateTime.fromJSDate(dateB).toLocaleString(DateTime.TIME_SIMPLE)
  );

/**
 * Check if dated are equal, ignoring time
 */
export const isDatesEquals = (dateA: Date, dateB: Date) =>
  equals(
    DateTime.fromJSDate(dateA).toLocaleString(DateTime.DATE_FULL),
    DateTime.fromJSDate(dateB).toLocaleString(DateTime.DATE_FULL)
  );

/**
 * Applies only the time portion from the "timeDate" to the "originalDate" and returns a new Date object.
 */
export const applyOnlyTime = (originalDate: Date, timeDate: Date) => {
  const luxonValue = toLuxon(timeDate);
  return toLuxon(originalDate)
    .set({ hour: luxonValue?.hour, minute: luxonValue?.minute, second: luxonValue?.second })
    .toJSDate();
};

/**
 * Sets the day, month, and year of the original date to match the specified date
 */
export const applyOnlyDate = (originalDate: Date, date: Date) => {
  const luxonValue = toLuxon(date);
  return toLuxon(originalDate)
    .set({ day: luxonValue?.day, month: luxonValue?.month, year: luxonValue?.year })
    .toJSDate();
};

/**
 * Get the start of the next day based on the given date.
 */
export const nextDayStart = (date: Date) => DateTime.fromJSDate(date).plus({ day: 1 }).startOf("day").toJSDate();

// get utc offset for timezone
export const getTimezoneOffset = (timeZone: string) =>
  DateTime.fromJSDate(new Date(), { zone: timeZone }).toFormat("ZZ");

/**
 * Format date range, if date is same, only times are shown
 */
export const dateTimeRangeFormat = (startTime: string, startDate: string, endTime: string, endDate: string) => {
  const isEqualDay = startDate === endDate;
  if (isEqualDay) {
    return `${startTime} - ${endTime}`;
  }
  return `${startTime} ${startDate} - ${endTime} ${endDate}`;
};
