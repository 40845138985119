import {
  Box,
  Button,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { MdsEditXs, MdsMoreVert, MdsVisibilityXs } from "@sinch/icons";
import React, { ElementType, FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon, MdsIconProps } from "../MdsIcon/MdsIcon";

export interface ActionMenuItem {
  /**
   * Title for menu or tooltip
   */
  title: string;
  /**
   * Click event on icon or menu item
   */
  href: string;
  /**
   * Icon for menu or icon
   */
  icon: ElementType;
  /**
   * Color of icon and item
   */
  color?: string;
}

export interface ActionMenuProps {
  /**
   * Size of icon applied for kebab menu icon and list of icon buttons, but not on icons in menu
   */
  iconSize?: MdsIconProps["fontSize"];
  /**
   * If true, version for touch is used, if udnefined, check touch device status automatically
   */
  touch?: boolean;
  /**
   * List of item to show
   */
  items: ActionMenuItem[];
  sx?: SxProps<Theme>;
}

export const HeaderActionMenuMobile: FC<ActionMenuProps> = ({ items, sx, iconSize }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box component="span" sx={sx}>
      <IconButton onClick={handleClick}>
        <MdsIcon color="info" fontSize={iconSize} icon={MdsMoreVert} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        onClose={handleClose}
        open={open}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
      >
        {items.map(({ title, color, href, icon }) => (
          <MenuItem key={title} component={Link} href={href} sx={{ minHeight: "unset" }}>
            <ListItemIcon color={color}>
              <MdsIcon color={color as MdsIconProps["color"]} fontSize="small" icon={icon} />
            </ListItemIcon>
            <ListItemText>{title}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

interface StaffHeaderActionMenuProps {
  staffId: string;
}

export const StaffHeaderActionMenu: FC<StaffHeaderActionMenuProps> = ({ staffId }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const items = [
    {
      title: t("Action.view"),
      href: decodeURI(`/admin/workers/view/${staffId}`),
      icon: MdsVisibilityXs,
      color: "info",
    },
    {
      title: t("Action.edit"),
      href: decodeURI(`/admin/workers/edit/${staffId}`),
      icon: MdsEditXs,
      color: "info",
    },
  ];

  if (isMobile) {
    return <HeaderActionMenuMobile items={items} />;
  }

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: "start",
      }}
    >
      {items.map((item) => (
        <Button key={item.title} href={item.href} startIcon={<MdsIcon icon={item.icon} />} target="_blank">
          {item.title}
        </Button>
      ))}
    </Stack>
  );
};
