import { CircularProgress, IconButton, Stack, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { MdsThumbDown, MdsThumbUp } from "@sinch/icons";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { DeclineReasonDialog } from "./DeclineReasonDialog";
import { useApplicantStatus } from "./useApplicantStatus";

interface ApplicantActionsProps {
  applicantId: string;
  onUpdate?: () => void;
}

/**
 * Icon buttons for applicants, for approve or decline
 */
export const ApplicantActions: FC<ApplicantActionsProps> = ({ applicantId, onUpdate }) => {
  const { t } = useTranslation();
  const { approveApplicant, declineApplicant } = useApplicantStatus();
  const [reasonDialogOpen, setReasonDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleApprove = async () => {
    setLoading(true);
    const response = await approveApplicant(applicantId, true);
    onUpdate?.();
    if (!response || !response.result) {
      setLoading(false);
    }
  };
  const handleDecline = async (reason: string) => {
    setLoading(true);
    setReasonDialogOpen(false);
    const response = await declineApplicant(applicantId, reason, true);
    onUpdate?.();
    if (!response || !response.result) {
      setLoading(false);
    }
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      sx={{
        alignItems: "center",
        display: "inline-flex",
        justifyContent: "flex-end",
        minWidth: "80px",
      }}
    >
      {!loading && (
        <IconButton onClick={handleApprove} size="small">
          <Tooltip arrow enterTouchDelay={0} placement="top" title={t("approve")}>
            <MdsIcon color="success" icon={MdsThumbUp} />
          </Tooltip>
        </IconButton>
      )}
      {!loading && (
        <IconButton onClick={() => setReasonDialogOpen(true)} size="small">
          <Tooltip arrow enterTouchDelay={0} placement="top" title={t("decline")}>
            <MdsIcon color="error" icon={MdsThumbDown} />
          </Tooltip>
        </IconButton>
      )}
      {loading && (
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            px: 2,
          }}
        >
          <CircularProgress size={16} thickness={5} />
        </Box>
      )}
      {reasonDialogOpen && <DeclineReasonDialog onClose={() => setReasonDialogOpen(false)} onConfirm={handleDecline} />}
    </Stack>
  );
};
