import React from "react";

/**
 * Hook for auto jump focus between inputs, change focus to next input in list when new last character of input is typed, jump to previous input when backspace is pressed and input is empty
 */
export const useAutoJumpFocus = (inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>) => {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number, size: number) => {
    const cursorPosition = (e.target as HTMLInputElement).selectionEnd;

    // Move cursor to next input when right arrow is pressed
    if (e.key === "ArrowRight" && cursorPosition === e.currentTarget.value.length) {
      inputRefs.current[index + 1]?.focus();
      inputRefs.current[index + 1]?.setSelectionRange(0, 0);
    }
    if (e.key === "ArrowLeft" && cursorPosition === 0 && index > 0) {
      inputRefs.current[index - 1]?.focus();
      inputRefs.current[index - 1]?.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
    }

    if (e.key === "Backspace" && cursorPosition === 0 && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
    if (e.key !== "Backspace" && cursorPosition === size && isVisualKey(e.key)) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  return { onKeyDown: handleKeyDown };
};

/**
 * List of non visuals keys for which input should react
 */
const isVisualKey = (keyCode: string) => {
  switch (keyCode) {
    //case "ArrowRight":
    //case "ArrowLeft":
    case "Shift":
    case "Control":
    case "Alt":
    case "ArrowDown":
    case "Delete":
    case "End":
    case "PageDown":
    case "Insert":
    case "Home":
    case "PageUp":
    case "Escape":
      return false;
    default:
      return true;
  }
};
