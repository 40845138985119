import { Box, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { PositionStatusEnum } from "@sinch/core/entities/serverEnums";
import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import { MdsClose, MdsEmojiPeople, MdsGroup } from "@sinch/icons";
import { filter, modify } from "ramda";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingWrapper } from "../../LoadingWrapper/LoadingWrapper";
import { MdsIcon } from "../../MdsIcon";
import { ShiftHeader, ShiftHeaderActionMenu } from "../../Shift";
import { ShiftDetail } from "./queries.graphql";
import { PositionShiftPanelDetailFragment, ShiftDetailQuery } from "./queries.types";
import { ShiftBasicInfo } from "./ShiftBasicInfo";
import { ApplicantRow, AttendanceRow, EmptyApplicantRow, EmptyPositionRow } from "./ShiftTable";
import { ShiftAttendanceTable } from "./ShiftTable/ShiftAttendanceTable";
import { ShiftTransportInfo } from "./ShiftTransportInfo";

interface ShiftDetailPanelProps {
  shiftId: string;
  onClose: () => void;
  attendanceConfirmation?: boolean;
}

export enum ShiftDetailPanelTabs {
  BasicInfo,
  Transport,
}

export const ShiftDetailPanel: React.FC<ShiftDetailPanelProps> = ({ shiftId, onClose, attendanceConfirmation }) => {
  const { data, refetch } = useQueryHandler<ShiftDetailQuery>(ShiftDetail, { id: shiftId });
  const [tab, setTab] = useState<ShiftDetailPanelTabs>(ShiftDetailPanelTabs.BasicInfo);
  const { t } = useTranslation();

  if (!data?.shift) {
    return <LoadingWrapper on sx={{ height: "100%" }} />;
  }

  const handleChangeTab = (_: React.SyntheticEvent, value: ShiftDetailPanelTabs) => {
    setTab(value);
  };

  const staffCount = data.shift.positions.reduce((result, position) => result + position.attendances.length, 0);
  const applicantsCapacitySize = data.shift.positions.reduce((result, position) => result + position.applicantSize, 0);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          backgroundColor: theme.palette.background.paper,
          zIndex: theme.zIndex.appBar,
          padding: theme.spacing(2, 2, 0, 0),
        })}
      >
        <ShiftHeaderActionMenu disableCollapse shift={data.shift} />
        {onClose && (
          <IconButton onClick={onClose}>
            <MdsIcon fontSize="small" icon={MdsClose} sx={{ color: ({ palette }) => palette.info.main }} />
          </IconButton>
        )}
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          padding: 2,
        }}
      >
        <Grid item xs={12}>
          <ShiftHeader shift={data.shift} />
        </Grid>
        {!!data.shift.transportTrips?.length && (
          <Grid item xs={12}>
            <Tabs
              onChange={handleChangeTab}
              sx={(theme) => ({
                minHeight: "unset",
                "& .MuiButtonBase-root.MuiTab-root": {
                  minHeight: "unset",
                  padding: theme.spacing(0.5, 1),
                },
              })}
              value={tab}
            >
              <Tab label={t("basicInfo")} value={ShiftDetailPanelTabs.BasicInfo} />
              <Tab label={t("Transport.transport")} value={ShiftDetailPanelTabs.Transport} />
            </Tabs>
          </Grid>
        )}
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          xs={12}
        >
          {tab === ShiftDetailPanelTabs.BasicInfo && <ShiftBasicInfo shift={data.shift} />}
          {tab === ShiftDetailPanelTabs.Transport && <ShiftTransportInfo transportTrips={data.shift.transportTrips} />}
        </Grid>
      </Grid>
      <ShiftAttendanceTable
        includeCapacity
        label={t("Staff.labelCount", {
          staffCount: staffCount,
        })}
        labelIcon={<MdsIcon icon={MdsGroup} />}
        renderRows={(position) => {
          const emptyPositions = position.size - position.attendances.length;
          return (
            <>
              {position.attendances.map((attendance) => (
                <AttendanceRow key={attendance.id} attendance={attendance} onUpdate={refetch} />
              ))}
              {emptyPositions > 0 && (
                <EmptyPositionRow
                  attendanceConfirmation={attendanceConfirmation}
                  emptyPositions={emptyPositions}
                  isJoinable={!data?.shift?.job.order.quote && position.status !== PositionStatusEnum.Pending}
                  onUpdate={refetch}
                  position={position}
                />
              )}
            </>
          );
        }}
        shift={data.shift}
      />
      {applicantsCapacitySize > 0 && (
        <Box
          sx={{
            pt: 2,
          }}
        >
          <ShiftAttendanceTable
            label={t("Applicant.labelPlural")}
            labelIcon={<MdsIcon icon={MdsEmojiPeople} />}
            renderRows={(position) => (
              <>
                {position.applicants.map((applicant) => (
                  <ApplicantRow key={applicant.id} applicant={applicant} onUpdate={refetch} />
                ))}
                {position.applicants.length <= 0 && <EmptyApplicantRow />}
              </>
            )}
            shift={modify(
              "positions",
              filter<PositionShiftPanelDetailFragment>(({ applicantSize }) => applicantSize > 0),
              data.shift
            )}
          />
        </Box>
      )}
    </Box>
  );
};
