import { Tooltip } from "@mui/material";
import { AttendanceStatusEnum } from "@sinch/core/entities/serverEnums";
import { SinchPersonErrorXs } from "@sinch/icons";
import React from "react";
import { MdsIcon, MdsIconProps } from "../../MdsIcon/MdsIcon";
import { useAttendanceStatus } from "../Attendace/useAttendanceStatus";

interface AttendanceStatusIconProps extends Omit<MdsIconProps, "icon"> {
  status?: AttendanceStatusEnum | null;
}

export const AttendanceStatusIcon: React.FC<AttendanceStatusIconProps> = ({ status, ...props }) => {
  const { getStatusColor, getStatusLabel, getStatusIcon } = useAttendanceStatus();
  if (!status) {
    return (
      <MdsIcon
        fontSize="small"
        icon={SinchPersonErrorXs}
        sx={{ color: ({ palette }) => palette.error.main }}
        {...props}
      />
    );
  }

  return (
    <Tooltip title={getStatusLabel(status)}>
      <MdsIcon fontSize="small" icon={getStatusIcon(status)} sx={{ color: getStatusColor(status) }} {...props} />
    </Tooltip>
  );
};
