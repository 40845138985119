const allowedTypes = ["image/png", "image/jpeg", "image/jpg"] as const;

type AllowedTypes = (typeof allowedTypes)[number];

/**
 * Converts a base64 image to a specified image type. Used eg. eg to convert a WEBP image to JPEG or png.
 *
 * @param {string} base64Image - The base64 encoded image string.
 * @param {AllowedTypes} type - The desired MIME type for the output image.
 * @returns {Promise<string>} - A promise that resolves to the converted image as a data URL.
 */
export const convertTo = function (base64Image: string, type: AllowedTypes) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  return new Promise<string>((resolve, reject) => {
    if (!context) {
      reject("Could not create canvas context");
      return;
    }
    const image = new Image();
    image.onload = function () {
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0);
      resolve(canvas.toDataURL(type));
    };
    image.src = base64Image;
  });
};

/**
 * Determines the MIME type of a given file. MIME type is check from file content instead of extension. Currently supports PNG, JPEG, and WEBP.
 *
 * @param {File} file - The file whose MIME type is to be determined.
 * @returns {Promise<"image/webp" | "image/png" | "image/jpeg" | null>} - A promise that resolves to the MIME type of the file, or null if the type is not recognized.
 */
export const getMimeType = (file: File) => {
  const reader = new FileReader();
  return new Promise<AllowedTypes | "image/webp" | null>((resolve) => {
    reader.onloadend = function () {
      const arr = new Uint8Array(reader.result as ArrayBuffer).subarray(0, 4);
      let header = "";
      for (const item of arr) {
        header += item.toString(16);
      }
      switch (header) {
        case "89504e47":
          resolve("image/png");
          break;
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
          resolve("image/jpeg");
          break;
        case "52494646":
          resolve("image/webp");
          break;
        default:
          resolve(null);
          break;
      }
    };
    reader.readAsArrayBuffer(file);
  });
};

export const isPdf = (file: File) => {
  const reader = new FileReader();
  return new Promise<boolean>((resolve) => {
    reader.onloadend = function () {
      const arr = new Uint8Array(reader.result as ArrayBuffer).subarray(0, 4);
      let header = "";
      for (const item of arr) {
        header += item.toString(16);
      }
      if (header === "25504446") {
        resolve(true);
      } else {
        resolve(false);
      }
    };
    reader.readAsArrayBuffer(file);
  });
};
