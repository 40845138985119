import { OperatorValueTypeEnum } from "@sinch/core/entities/serverEnums";
import { useFormat } from "@sinch/core/hooks/format";

import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import { find, prop, propEq } from "ramda";
import { ensureArray, isArray } from "ramda-adjunct";
import { useWatch } from "react-hook-form";
import { useFieldArrayContext } from "../providers/FieldArrayProvider";
import { Requirements } from "./queries.graphql";
import { OperatorFieldsFragment, RequirementsQuery, SubjectFieldsFragment } from "./queries.types";

export const useRequirementRow = () => {
  const { duration } = useFormat();
  const { data: requirementsData } = useQueryHandler<RequirementsQuery>(Requirements);

  const { scope, remove, copy } = useFieldArrayContext();
  const { requirementId, subject, operator, value, percentage, expiration } = useWatch({ name: scope });

  const requirementItem = find<RequirementsQuery["requirements"][0]>(propEq("id", requirementId))(
    requirementsData?.requirements || []
  );
  const subjectItem = find<SubjectFieldsFragment>(propEq("subject", subject))(requirementItem?.subjects || []);
  const operatorItem = find<OperatorFieldsFragment>(propEq("value", operator))(subjectItem?.operators || []);

  const arrayToList = (val: any) => (isArray(val) ? value.join(", ") : val);

  //TODO: Create formatter for lists
  const stringValue =
    subjectItem?.valueType === OperatorValueTypeEnum.List
      ? ensureArray(value)
          .map((val) => prop("label", find(propEq("value", val))(subjectItem?.possibleValues || [])))
          .join(", ")
      : arrayToList(value);

  return {
    labels: {
      requirement: requirementItem?.label,
      subject: subjectItem?.label,
      operator: operatorItem?.label,
      value: stringValue,
      percentage: `${(percentage ?? 0) * 100}%`,
      expiration: duration.short(expiration * 60 * 60),
    },
    values: { requirementId, subject, operator, value, percentage, expiration },
    remove,
    copy,
  };
};
