import { debounce, TextFieldProps, useForkRef } from "@mui/material";
import { DesktopDatePicker, DesktopDatePickerProps } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import React, { forwardRef, useCallback, useRef, useState } from "react";
import { DateRenderInput } from "./DateRenderInput";
import { DateTimeInputBaseProps } from "./DateTimeInput.types";

/**
 * Date time input, for selection date part, calendar dialog is used, for selection time part, select box is used, no other form parts included (as label, helper text etc)
 */
export const DateInputBase = forwardRef<HTMLElement, DateTimeInputBaseProps & { onCloseExited?: () => void }>(
  (
    {
      label,
      error,
      required,
      onChange,
      value,
      minTime,
      maxTime,
      timePoint,
      timeInputRef,
      dateInputRef,
      dateDisabled,
      defaultValue,
      format: dateFormat,
      onCloseExited,
      referenceDate,
      timezone,
      ...props
    },
    ref
  ) => {
    const [open, setOpen] = useState(false);

    // Forked ref for date picker for anchoring popper
    const anchorRef = useRef<HTMLDivElement>(null);
    const datePickerRef = useForkRef(anchorRef, ref);

    // Forked ref for date picker focusing
    const forkedDatePickerRef = useRef<HTMLInputElement>();
    const focusRef = useForkRef(dateInputRef, forkedDatePickerRef);

    const handleOpen = useCallback(
      (openState: boolean) => {
        if (!dateDisabled && !props.disabled) {
          setOpen(openState);
        }
      },
      [props.disabled, dateDisabled, setOpen]
    );

    /**
     * Limit date in min and max time to closest possible date
     */
    const getDateInLimit = useCallback(
      (checkedValue: DateTime | null | undefined) => {
        if (minTime && checkedValue && checkedValue?.valueOf() < minTime?.valueOf()) {
          return limitOnlyDate(minTime, checkedValue);
        }
        if (maxTime && checkedValue && checkedValue?.valueOf() > maxTime?.valueOf()) {
          return limitOnlyDate(maxTime, checkedValue);
        }
        return checkedValue ?? null;
      },
      [maxTime, minTime]
    );

    const handleChangeSectionsChange: DesktopDatePickerProps<DateTime>["onSelectedSectionsChange"] = () => {
      const dateInLimit = getDateInLimit(value);
      if (dateInLimit?.isValid) {
        onChange(dateInLimit);
      }
    };

    const accepted = useRef<boolean>(false);

    const onChangeDebounced = debounce(onChange, 200);

    return (
      <DesktopDatePicker<DateTime>
        ref={datePickerRef}
        className={dateDisabled ? "Mui-disabled" : ""}
        defaultValue={defaultValue}
        disabled={props.disabled}
        disableOpenPicker={dateDisabled}
        format={dateFormat}
        maxDate={maxTime}
        minDate={minTime}
        onAccept={() => {
          accepted.current = true;
        }}
        onChange={(val) => {
          if (!val || val.isValid) {
            if (accepted.current) {
              onChange(val);
            } else {
              onChangeDebounced(val);
            }
          }
        }}
        onClose={() => {
          handleOpen(false);
        }}
        onOpen={() => handleOpen(true)}
        onSelectedSectionsChange={handleChangeSectionsChange}
        open={open && !props.readOnly}
        readOnly={props.readOnly}
        selectedSections={null}
        slotProps={{
          day: { "data-cy": "date-picker-day", ...props.inputProps?.day },
          nextIconButton: { "data-cy": "date-picker-next-btn", ...props.inputProps?.nextIconButton },
          previousIconButton: { "data-cy": "date-picker-prev-btn", ...props.inputProps?.previousIconButton },
          switchViewButton: { "data-cy": "date-time-picker-switch-btn", ...props.inputProps?.switchViewButton },
          popper: {
            anchorEl: anchorRef.current,
            disablePortal: props.disablePortal,
            "data-cy": "date-picker-popper",
          },
          desktopTransition: {
            onExited: () => {
              if (accepted.current) {
                accepted.current = false;
                onCloseExited?.();
              }
            },
            unmountOnExit: true,
            timeout: 200,
          },
          textField: {
            size: props.size,
            sx: props.sx,
            onFocus: props.onFocus,
            focusRef: focusRef,
            setOpen: handleOpen,
            value: value,
            onBlur: () => {
              onChange(getDateInLimit(value));
            },
            "data-cy": "date-input",
            className: props.className,
            inputProps: {
              ...props.inputProps?.dateInput,
              readOnly: "readonly",
            },
          } as TextFieldProps,
        }}
        slots={{
          textField: DateRenderInput,
        }}
        timezone={timezone}
        value={value}
        referenceDate={referenceDate}
        // @ts-expect-error expected, type for size is missing
        size={props.size}
      />
    );
  }
);

DateInputBase.displayName = "DateInputBase";

/**
 * Limit only date part of date time if time is valid
 */
const limitOnlyDate = (limitTime: DateTime, value: DateTime) => {
  if (!value.isValid) {
    return limitTime;
  } else {
    return value.set({
      day: limitTime.day,
      month: limitTime.month,
      year: limitTime.year,
    });
  }
};
