import { Chip, ChipProps, styled, Theme, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { isFunction } from "ramda-adjunct";
import React, { HTMLAttributes } from "react";
import { useSinchEntity, SinchEntity } from "../application/entities/entitiesProperties";

/**
 * Variant of Chip
 */
export const Pill = styled(Chip)<ChipProps>(({ theme, sx }) => ({
  borderRadius: `4px !important`,
  height: "24px",
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  "&:hover": {
    // @ts-ignore - sx is not fully defined
    backgroundColor: sx?.backgroundColor || "inherit",
  },
  "& .MuiChip-label": {
    padding: 0,
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
  },
  "&.MuiChip-sizeSmall": {
    height: "16px",
    "& .MuiChip-label": {
      fontSize: theme.typography.pxToRem(13),
    },
  },
  "&.MuiChip-sizeTiny": {
    height: "13px",
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    "& .MuiChip-label": {
      fontSize: theme.typography.pxToRem(11),
    },
  },
}));

type EntityChipProp = HTMLAttributes<HTMLDivElement> & {
  /**
   * Size of the chip
   */
  size?: "normal" | "small" | "tiny";
  /**
   * Background color of the chip
   */
  background?: "paper" | "grey";
  sx?: SxProps<Theme>;
};

/**
 * Simplified entity chip
 */
export const EntityChip = styled("div")<EntityChipProp>(({ size = "normal", background = "grey" }) => {
  const theme = useTheme();
  return {
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "4px !important",
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1,
    ...{
      normal: {
        height: "24px",
        padding: theme.spacing(0, 1),
        fontSize: theme.typography.pxToRem(14),
      },
      small: {
        height: "16px",
        padding: theme.spacing(0, 0.5),
        fontSize: theme.typography.pxToRem(13),
      },
      tiny: {
        height: "12px",
        padding: theme.spacing(0, 0.5),
        fontSize: theme.typography.pxToRem(11),
      },
    }[size],
    ...{
      paper: {
        backgroundColor: theme.palette.background.paper,
      },
      grey: {
        backgroundColor: theme.palette.secondary.light,
      },
    }[background],
  };
});

type EntityPillProp = EntityChipProp & { labelColor: string | ((theme: Theme) => string) };
export const EntityPill = styled(EntityChip, {
  shouldForwardProp: (prop) => prop !== "labelColor",
})<EntityPillProp>(({ theme, labelColor }) => ({
  color: isFunction(labelColor) ? labelColor(theme) : labelColor,
}));

type PillProps = Omit<EntityPillProp, "labelColor" | "id"> & {
  id: string | number;
};

export const PositionPill: React.FC<PillProps> = ({ id, ...other }) => {
  const { getEntityIdLabel, getEntityColor } = useSinchEntity();
  return (
    <EntityPill {...other} labelColor={getEntityColor(SinchEntity.Position)}>
      {getEntityIdLabel(SinchEntity.Position, id)}
    </EntityPill>
  );
};
export const ShiftPill: React.FC<PillProps> = ({ id, ...other }) => {
  const { getEntityIdLabel, getEntityColor } = useSinchEntity();
  return (
    <EntityPill {...other} labelColor={getEntityColor(SinchEntity.Shift)}>
      {getEntityIdLabel(SinchEntity.Shift, id)}
    </EntityPill>
  );
};
export const JobPill: React.FC<PillProps> = ({ id, ...other }) => {
  const { getEntityIdLabel, getEntityColor } = useSinchEntity();
  return (
    <EntityPill {...other} labelColor={getEntityColor(SinchEntity.Job)}>
      {getEntityIdLabel(SinchEntity.Job, id)}
    </EntityPill>
  );
};
export const OrderPill: React.FC<PillProps> = ({ id, ...other }) => {
  const { getEntityIdLabel, getEntityColor } = useSinchEntity();
  return (
    <EntityPill {...other} labelColor={getEntityColor(SinchEntity.Order)}>
      {getEntityIdLabel(SinchEntity.Order, id)}
    </EntityPill>
  );
};
