// eslint-disable-next-line import/no-internal-modules
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { INDENT_CONTENT_COMMAND, OUTDENT_CONTENT_COMMAND } from "lexical";

export const useIndentElement = () => {
  const [editor] = useLexicalComposerContext();

  return () => editor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
};
export const useOutdentElement = () => {
  const [editor] = useLexicalComposerContext();

  return () => editor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
};
