import { LicenseInfo } from "@mui/x-license-pro";

import { FocusProvider } from "@sinch/components/providers/AutofocusProvider/FocusProvider";
import React, { FC, useEffect, useState } from "react";
import { AppNavigationProvider } from "./providers";
import { AppSettingsProvider } from "./providers/AppSettings";
import { TranslateProvider } from "./providers/TranslateProvider";
import { ViewComponent } from "@sinch/core/components/ViewComponent";
import { ApolloClientProvider } from "@sinch/core/providers/ApolloClientProvider";
import { SessionSettings } from "@sinch/core/providers/AppSettings.types";
import { BrowserStatusProvider } from "@sinch/core/providers/BrowserStatusProvider";
import { DateTimePickersL10nProvider } from "@sinch/core/providers/DateTimePickersL10nProvider";
import { FormatJsIntlProvider } from "@sinch/core/providers/FormatJsIntlProvider";
import { MuiThemeProvider } from "@sinch/core/providers/MuiThemeProvider";
import { SentryErrorBoundary } from "@sinch/core/providers/SentryErrorBoundary";
import { subscribeComponents, ViewComponentDefinition, ViewComponentHandler } from "@sinch/core/tools/viewLoader";

LicenseInfo.setLicenseKey(
  "466aff280262d4c7d4bcd1ee392baefbTz05ODMzMSxFPTE3NTgyMDI2NzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLFBWPVEzLTIwMjQsS1Y9Mg=="
);

/**
 * Top level component of react component tree
 */
export const App: FC<SessionSettings> = (settings) => (
  <AppSettingsProvider settings={settings}>
    <TranslateProvider language={settings.language}>
      <SentryErrorBoundary>
        <BrowserStatusProvider>
          <FormatJsIntlProvider timezone={settings.timeZone}>
            <MuiThemeProvider>
              <DateTimePickersL10nProvider>
                <ApolloClientProvider>
                  <FocusProvider>
                    <AppNavigationProvider>
                      <ComponentLoader />
                    </AppNavigationProvider>
                  </FocusProvider>
                </ApolloClientProvider>
              </DateTimePickersL10nProvider>
            </MuiThemeProvider>
          </FormatJsIntlProvider>
        </BrowserStatusProvider>
      </SentryErrorBoundary>
    </TranslateProvider>
  </AppSettingsProvider>
);

/**
 * Handle loading of components
 */
const ComponentLoader = () => {
  const [viewComponents, setViewComponents] = useState<Required<ViewComponentDefinition>[]>([]);

  const handleAddComponent: ViewComponentHandler = (components) => {
    setViewComponents([...components]);
  };

  // Handle registration of app components, which are dynamically loaded to react component tree
  useEffect(() => {
    subscribeComponents(handleAddComponent);
  }, []);

  return (
    <>
      {viewComponents.map(({ selector, component, id }) => (
        <ViewComponent key={id} component={component} id={id} selector={selector} />
      ))}
    </>
  );
};
