import { Stack } from "@mui/material";
import { groupBy } from "ramda";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../../typography/SectionTitle/SectionTitle";
import { LabelValue } from "../LabelValue";
import { StaffFragment } from "../queries.types";

interface PersonalAttributesProps {
  staff: StaffFragment;
}

export const PersonalAttributes: FC<PersonalAttributesProps> = ({ staff }) => {
  const { t } = useTranslation();
  const aggregatedAttributes = groupBy(
    (item) => item.attribute.block?.name ?? t("miscellaneous"),
    staff?.personalAttributes ?? []
  );

  return Object.entries(aggregatedAttributes).map(([category, items]) => (
    <Stack key={category}>
      <SectionTitle title={category} variant="subtitle1" />
      <Stack direction="column">
        {items.map((item: Record<string, any>) => (
          <LabelValue
            key={item.id}
            files={item.files}
            label={item.attribute.name}
            options={item.attribute.options}
            type={item.attribute.dataType}
            value={item.value}
          />
        ))}
      </Stack>
    </Stack>
  ));
};
