import { InputWrapperProps } from "../FormControlWrapper/FormControlWrapper";

export enum WageType {
  FIXED = "FIXED",
  HOURLY = "HOURLY",
  HOURLY_THRESHOLD = "HOURLY_THRESHOLD",
  THRESHOLD_FIX = "THRESHOLD_FIX",
}
export enum WageStep {
  NONE = 1 / 60, // 1 min
  QUARTER = 0.25, // 15 min
  HALF = 0.5, // 30 min
  HOUR = 1, // 60 min
}

export interface Wage {
  id?: number;
  duration: number;
  value: number;
  type: WageType;
}

export type WageBuilderProps = {
  prefix: string;
  step: WageStep;
} & InputWrapperProps;

export type WageForm = Record<string, Wage[]>;
