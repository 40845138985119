import { DateTime } from "luxon";
import { pipe } from "ramda";
import { YEAR_0_AD, YEAR_5000_AD } from "./constants";
import {
  getIntervalAroundPoint,
  getStepsForInterval,
  roundIntervalMinutes,
  shiftIntervalByMinMax,
  stripDateList,
} from "./interval";

interface GetStepsForTimeRangeOptions {
  stepSizeInMinutes: number;
  rangeSizeInMinutes?: number;
  minTime?: DateTime;
  maxTime?: DateTime;
  timePoint?: DateTime;
}

/**
 * Return list of times around given date
 */
export const getTimeStepsAroundDate = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  value: DateTime | null | undefined,
  {
    stepSizeInMinutes = 30,
    minTime = YEAR_0_AD,
    maxTime = YEAR_5000_AD,
    rangeSizeInMinutes = 60 * 48,
  }: GetStepsForTimeRangeOptions
): DateTime[] => {
  if (maxTime < minTime) {
    return [];
  }

  if (!maxTime.isValid || !minTime.isValid) {
    return [];
  }

  return pipe(
    getIntervalAroundPoint({ minutes: rangeSizeInMinutes }),
    roundIntervalMinutes(stepSizeInMinutes),
    shiftIntervalByMinMax(minTime, maxTime),
    getStepsForInterval({ minutes: stepSizeInMinutes }),
    stripDateList(minTime, maxTime)
  )(value || DateTime.now());
};
