import { GridRowClassNameParams } from "@mui/x-data-grid";
// eslint-disable-next-line import/no-internal-modules
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { juxt } from "ramda";
import { MutableRefObject, useRef } from "react";

export const composeHelpers = (methods: ((...args: any[]) => string)[]) => (params: GridRowClassNameParams) =>
  juxt(methods)(params).join(" ");

/**
 * Helpers for DataGrid coponent
 */
export const useDataGridHelpers = () => {
  const levelIndexes = useRef([]);

  /**
   * Returns a function that can be used as `getRowClassName` prop for DataGrid and style rows based on they are odd or even.
   * The function also takes a `levelField` prop that can be used to style rows based on their level.
   */
  const strippedGetRowClassNameMethod =
    (levelField?: string) =>
    ({ isFirstVisible, row }: GridRowClassNameParams) => {
      // Ship if first row is not visible
      if (isFirstVisible) {
        levelIndexes.current = [];
      }
      // If no level field is provided, return default row index
      const level = levelField ? row[levelField]?.length || 0 : 1;
      levelIndexes.current = levelIndexes.current.slice(0, level);
      levelIndexes.current[level - 1] = levelIndexes.current[level - 1] || 0;
      levelIndexes.current[level - 1]++;
      return levelIndexes.current[level - 1] % 2 === 0 ? "MuiDataGrid-row-even" : "MuiDataGrid-row-odd";
    };

  /**
   * Returns a function that can be used as `getRowClassName` prop for DataGrid and style rows based on they level.
   */
  const leveledGetRowClassNameMethod =
    (levelField: string) =>
    ({ row }: GridRowClassNameParams) => {
      const level = row[levelField]?.length || 0;
      return `MuiDataGrid-row-level-${level - 1}`;
    };

  const openedGetRowClassNameMethod =
    (apiRef: MutableRefObject<GridApiPro>) =>
    ({ row }: GridRowClassNameParams) => {
      if (apiRef) {
        const node = apiRef.current.getRowNode(row.id);
        if (node?.type === "group" && node?.childrenExpanded === true) {
          return "MuiDataGrid-row-opened";
        }
      }
      return "";
    };

  return { strippedGetRowClassNameMethod, leveledGetRowClassNameMethod, openedGetRowClassNameMethod };
};
