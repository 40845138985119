import { rejectFalsy } from "./array";

/**
 * Remove all except first occurrence of patter from text
 */
export const removeExceptFirst = (text: string, patter: string) => {
  const from = text.indexOf(patter);
  if (from === -1) return text;
  return text.slice(0, from + 1) + text.slice(from + 1).replace(patter, "");
};

export const join = (items: (string | false | null | undefined)[], separator = " ") =>
  rejectFalsy(items).join(separator);

/**
 * Remove all html tags from text
 */
export const stripTags = (text: string) => text.replace(/(<([^>]+)>)/gi, "");

/**
 * First letter to upper case
 */
export const capitalize = (text: string) => text.charAt(0).toUpperCase() + text.slice(1);
