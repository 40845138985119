import { setRef } from "@mui/material";

import { roundAt } from "@sinch/utils/math";
import React, { FC, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import Split, { SplitProps } from "react-split";
import SplitLib from "split.js";

export interface SplitterGutterProps {
  /**
   * Sizes of the two containers
   */
  sizes: [number, number];
  /**
   * Set sizes of containers, in percents
   */
  onSetSizes?: (sizes: [number, number]) => void;
  /**
   * Collapse container at given index
   */
  onCollapse?: (index: number) => void;
  /**
   * Expand container to last know sizes
   */
  onExpand?: () => void;
}

export interface SplitterProps extends Omit<SplitProps, "gutter" | "sizes"> {
  sizes?: [number, number];
  children: [React.ReactElement, React.ReactElement];
  /**
   * render prop for rendering gutter
   */
  renderGutter?: (props: SplitterGutterProps) => React.ReactElement;
}

/**
 * Split two containers with slideable gutter between
 */
export const Splitter: FC<SplitterProps> = ({
  gutterSize = 32,
  onDragEnd,
  direction = "vertical",
  cursor,
  renderGutter,
  children,
  sizes: defaultSizes = [50, 50],
  ...props
}) => {
  const split = useRef<SplitLib.Instance>();
  const gutterContainer = useRef<HTMLElement>(document.createElement("div"));
  gutterContainer.current.style.cursor = cursor ?? (direction === "vertical" ? "row-resize" : "col-resize");

  const [sizes, setSizes] = useState<[number, number]>(defaultSizes);
  const lastSizes = useRef<[number, number] | null>(sizes);

  if (Array.from(children).length !== 2) {
    throw new Error("Splitter must have exactly two children");
  }

  const renderGutterProps: SplitterGutterProps = useMemo(
    () => ({
      onSetSizes: (newSizes) => split.current?.setSizes(newSizes),
      onCollapse: (index) => {
        lastSizes.current = (split.current?.getSizes() as [number, number]) ?? null;
        setSizes(index === 0 ? [0, 100] : [100, 0]);
        split.current?.collapse(index);
      },
      onExpand: () => {
        split.current?.setSizes(lastSizes.current ?? defaultSizes);
        setSizes(lastSizes.current ?? defaultSizes);
      },
      sizes: sizes,
    }),
    [defaultSizes, sizes]
  );
  // Splitter are calculating sizes in percents included gutter size, so I need to calculate size of gutter relative to parent
  // @ts-ignore unimplemented for typescript
  const topMinimum = split.current?.parent
    ? // @ts-ignore unimplemented for typescript
      (gutterSize / split.current?.parent?.getBoundingClientRect().height ?? 1) * 100
    : 0;

  return (
    <>
      <Split
        ref={(splitInstance) => {
          if (splitInstance) {
            // @ts-ignore unimplemented for typescript
            setRef(split, splitInstance.split as SplitLib.Instance);
          }
        }}
        cursor={cursor ?? (direction === "vertical" ? "row-resize" : "col-resize")}
        direction={direction}
        dragInterval={1}
        expandToMin={false}
        gutter={renderGutter ? () => gutterContainer.current : undefined}
        gutterAlign="start"
        gutterSize={gutterSize}
        minSize={[0, 0]}
        sizes={sizes}
        snapOffset={0}
        style={{ height: "100%" }}
        {...props}
        onDragEnd={(currentSizes: [number, number]) => {
          setSizes(currentSizes);
          onDragEnd?.(currentSizes);
        }}
      >
        <div style={{ width: "100%", height: "100%" }}>
          {roundAt(2)(sizes[0]) > roundAt(2)(topMinimum) && children[0]}
        </div>
        <div style={{ width: "100%", height: "100%" }}>{sizes[1] > 0 && children[1]}</div>
      </Split>
      {renderGutter && createPortal(renderGutter?.(renderGutterProps), gutterContainer.current)}
    </>
  );
};
