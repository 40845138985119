import React, { PropsWithChildren, useContext } from "react";

const ViewPropsContext = React.createContext<any>(undefined);
ViewPropsContext.displayName = "ViewProps";

interface ViewPropsProviderProps<T = any> extends PropsWithChildren {
  /**
   * View props
   */
  data: T;
}

/**
 * Provide context to propagate view props
 */
export function ViewPropsProvider<T = any>({ children, data }: ViewPropsProviderProps<T>) {
  return <ViewPropsContext.Provider value={data}>{children}</ViewPropsContext.Provider>;
}

/**
 * Provide function for get props of view
 */
export function useViewProps<T = any>(): T {
  const data = useContext(ViewPropsContext);
  return data;
}
