import { Alert, AlertTitle, Stack, Typography } from "@mui/material";
import React, { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

export interface HandleErrorBoxProps {
  errorId?: string;
}

export const HandleErrorBox: FC<HandleErrorBoxProps> = ({ errorId }) => {
  const ref = useRef<HTMLDivElement | null>();
  const { t } = useTranslation();
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);
  return (
    <Alert ref={(refEl) => (ref.current = refEl)} icon={false} severity="error" sx={{ mb: 2 }}>
      <AlertTitle>{t("Error.failedToSendData")}</AlertTitle>
      <Stack>
        <Typography>{t("Error.failedMessageDescription")}</Typography>
        {errorId && (
          <Typography
            sx={{
              fontSize: "10px",
            }}
          >
            {t("Error.errorId")}: {errorId}
          </Typography>
        )}
      </Stack>
    </Alert>
  );
};
