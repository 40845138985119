import { DateTime } from "luxon";
import { TimeInputBaseProps, TimeItemsStyleMethod } from "../types";

export interface TimeListProps extends Pick<TimeInputBaseProps, "dateInOption" | "timePoint"> {
  /**
   * List of options
   */
  options: DateTime[];

  /**
   * The selected time, list is automatically scrolled to this time.
   */
  selectedTime?: DateTime<boolean> | null;

  /**
   * Handle change a date by click on item
   */
  onChange: (value: DateTime | null) => void;

  /**
   * The minimum time that can be selected.
   */
  minTime?: DateTime;

  /**
   * The maximum time that can be selected.
   */
  maxTime?: DateTime;

  /**
   * The minimum width of the time list component.
   */
  minWidth?: number;
  minutesStep?: number;
  timeItemsStyle?: TimeItemsStyleMethod;
}

export interface ItemContext {
  selectedIndexOverall: number;
  onChange: (value: DateTime | null) => void;
  timeItemsStyle?: TimeItemsStyleMethod;
}

export const ITEM_HEIGHT = 33;
