import { styled } from "@mui/material";
import { hasNotProp } from "@sinch/utils/object";
import { prop, sortBy } from "ramda";
import { HTMLAttributes } from "react";

interface TimeBarPart {
  /**
   * Color of time bar segment
   */
  color: string;
  /**
   * How many percents segment takes
   */
  percent: number;
}

export interface TimeBarLineProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Parts of time bar
   */
  parts: TimeBarPart[];
}

/**
 * Render a time bar inside timeline. Bar is separated into segments
 */
export const TimeBarLine = styled("div", {
  shouldForwardProp: hasNotProp<TimeBarLineProps>(["ref", "sx", "parts"]),
})<TimeBarLineProps>(({ parts }) => {
  const gradient = sortBy(prop("percent"), parts).reduce(
    (acc, part) => {
      acc.push(`${part.color} ${part.percent * 100}%, ${part.percent * 100}%`);
      return acc;
    },
    ["transparent 0%, 0%"]
  );
  gradient.push("transparent 100%");

  return {
    background: `linear-gradient(90deg, ${gradient.join(",")})`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    borderTopLeftRadius: "6px !important",
    borderBottomLeftRadius: "6px !important",
    borderRight: "none",
    borderBottomRightRadius: "6px !important",
    borderTopRightRadius: "6px !important",
    height: "100%",
  };
});
