import { Link, Stack, styled, TableCell, TableRow, TableRowProps, Tooltip, Typography } from "@mui/material";
import { PositionStatusEnum } from "@sinch/core/entities/serverEnums";
import { useFormat } from "@sinch/core/hooks/format";
import { useAppNavigate } from "@sinch/core/providers";
import { MdsPersonXs } from "@sinch/icons";
import { differenceIn } from "@sinch/utils/dateTime/dateFns";
import React from "react";

import { useTranslation } from "react-i18next";
import { ConditionalWrapper } from "../../../ConditionalWrapper/ConditionalWrapper";
import { MdsIcon } from "../../../MdsIcon";
import { JobPill, ShiftPill } from "../../../Pill/Pill";
import { DurationStatus } from "../../DurationStatus";
import type { StaffAttendanceFragment } from "../queries.types";

export const AttendanceRow: React.FC<{
  attendance: StaffAttendanceFragment;
}> = ({ attendance }) => {
  const { t } = useTranslation();
  const { date, time, duration } = useFormat();

  const { navigateToShiftDetail, shiftDetailPath } = useAppNavigate();

  const isSameYear = attendance.arrival.getFullYear() === new Date().getFullYear();

  const isCompleted = attendance.position.status === PositionStatusEnum.Completed;

  const beginning = isCompleted ? attendance.arrival : attendance.position.beginning;
  const end = isCompleted ? attendance.departure : attendance.position.end;

  const attendanceDuration = Math.round(differenceIn("seconds")(end, beginning));

  return (
    <AttendanceTableRow>
      <TableCell colSpan={2} size="small">
        <Typography
          sx={{
            fontWeight: 600,
          }}
        >
          <Link
            href={shiftDetailPath({ shiftId: attendance.position.shift.id, jobId: attendance.position.shift.job.id })}
            onClick={navigateToShiftDetail({
              shiftId: attendance.position.shift.id,
              jobId: attendance.position.shift.job.id,
            })}
            sx={{ "&, &:hover": { color: "inherit" } }}
            target="_blank"
          >
            <Stack sx={{ paddingTop: 0.5, paddingBottom: 0.5 }}>
              <Typography sx={{ whiteSpace: "nowrap" }} variant="descriptionTiny">
                {attendance.position.shift.job.name} <JobPill id={attendance.position.shift.job.id} size="tiny" />
              </Typography>
              <Typography sx={{ lineHeight: 1 }}>
                {attendance.position.shift.name} <ShiftPill id={attendance.position.shift.id} size="small" />
              </Typography>
            </Stack>
          </Link>
        </Typography>
      </TableCell>
      <TableCell size="small" sx={{ minWidth: "120px" }}>
        <ConditionalWrapper
          condition={isCompleted}
          wrapper={(children) => (
            <Tooltip
              title={t("Attendance.realArrivalTooltip", {
                positionBeginning: `${time.short(attendance.position.beginning)} ${date.short(attendance.position.beginning)}`,
              })}
            >
              {children}
            </Tooltip>
          )}
        >
          <Stack
            direction="column"
            sx={{
              alignItems: "start",
              justifyItems: "center",
            }}
          >
            <Typography>
              {time.short(beginning)} – {time.short(end)}
            </Typography>
            <Typography variant="descriptionTiny">
              {isSameYear ? date.short(beginning) : date.medium(beginning)},{" "}
              <DurationStatus status={attendance.position.status as PositionStatusEnum}>
                {isSameYear ? duration.floatHours(attendanceDuration) : duration.floatHoursNarrow(attendanceDuration)}
              </DurationStatus>
            </Typography>
          </Stack>
        </ConditionalWrapper>
      </TableCell>
      <TableCell size="small">
        <Stack
          direction="row"
          spacing={0.5}
          sx={{
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          <MdsIcon
            fontSize="small"
            icon={MdsPersonXs}
            sx={{ color: ({ palette }) => palette.custom.deepSpace["500"] }}
          />
          <Typography>
            {attendance.position.attendances.length}/{attendance.position.size}
          </Typography>
        </Stack>
      </TableCell>
    </AttendanceTableRow>
  );
};

export const AttendanceTableRow = styled(TableRow)<TableRowProps>(({ theme: { spacing } }) => ({
  padding: spacing(0, 1),
  height: "44px",
  "& .MuiTableCell-root": {
    padding: spacing(0, 0.5),
  },
  "& .MuiTableCell-root:first-of-type": {
    paddingLeft: spacing(2),
  },
}));
