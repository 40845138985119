import { Stack } from "@mui/material";
import { MessageSeverity as MessageSeverityEnum } from "@sinch/core/entities/serverEnums";
import type { MessageSeverity } from "@sinch/types/sinch.types";

import { enqueueSnackbar } from "notistack";
import React from "react";

const mapSeverityToSnackbarVariant = (severity: MessageSeverityEnum) => {
  switch (severity) {
    case MessageSeverityEnum.Error:
      return "error";
    case MessageSeverityEnum.Warning:
      return "warning";
    default:
      return "info";
  }
};

export const notifyErrors = (
  mutationResponse:
    | { result: boolean; messages?: { message: string; severity: MessageSeverity }[] | null | undefined }
    | undefined
    | null
) => {
  if (!mutationResponse?.result) {
    const errorsGroups = mutationResponse?.messages?.reduce(
      (errors, message) => ({
        ...errors,
        [message.severity ?? MessageSeverityEnum.Error]: [...(errors[message.severity] ?? []), message.message],
      }),
      {} as Record<MessageSeverity, string[]>
    );
    if (errorsGroups) {
      Object.entries(errorsGroups).forEach(([severity, messages]) => {
        enqueueSnackbar(<Stack>{messages}</Stack>, {
          variant: mapSeverityToSnackbarVariant(severity as MessageSeverityEnum),
        });
      });
    }
  }
};
