import { setRef, useForkRef } from "@mui/material";
import { noop } from "ramda-adjunct";
import React, {
  FC,
  PropsWithChildren,
  RefCallback,
  useCallback,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";

interface State {
  pendingFocusId: string | null;
  triggerFocusTarget: (id: string | null) => void;
}
export const FocusContext = React.createContext<State>({ pendingFocusId: null, triggerFocusTarget: noop });
FocusContext.displayName = "FocusContext";

/**
 * Hold pending autofocus id and trigger autofocus. Keep this provider in top level of app, autofocus is exclusive for
 * only one input element at time. Using multiple autofocus providers can cause unexpected behavior.
 */
export const FocusProvider: FC<PropsWithChildren> = ({ children }) => {
  const [pendingAutofocusId, setPendingAutofocusId] = useState<string | null>(null);

  return (
    <FocusContext.Provider value={{ pendingFocusId: pendingAutofocusId, triggerFocusTarget: setPendingAutofocusId }}>
      {children}
    </FocusContext.Provider>
  );
};

interface UseAutofocusTargetParams<T extends HTMLElement | null = HTMLElement> {
  /**
   * Autofocus target id
   */
  autofocusId: string;
  /**
   * Ref of input element, this ref is forked.
   */
  ref?: React.RefObject<T> | RefCallback<T>;
  /**
   * Focus methods, by default it call focus() method of ref.
   */
  focus?: (ref: React.RefObject<T>) => void;
}

type FocusFn<T extends HTMLElement | null = HTMLElement> = UseAutofocusTargetParams<T>["focus"];

/**
 * Register autofocus target. Set ref of input element and use return as new ref reference for correct work.
 *
 * @example const ref = useAutofocusTarget({ autofocusId: "someId", ref: inputRef });
 *          <input ref={ref} />
 */
export function useAutofocusTarget<T extends HTMLElement | null = HTMLElement>({
  autofocusId,
  focus,
  ref,
}: UseAutofocusTargetParams<T>): RefCallback<T> {
  const { pendingFocusId, triggerFocusTarget } = useContext(FocusContext);

  const forkedRef = useRef<T>(null);
  const elRef = useForkRef<T>(forkedRef, ref);
  const isReady = useCallback(() => !!forkedRef?.current, [forkedRef]);
  const defaultFocus: FocusFn<T> = (currentRef) => {
    setTimeout(() => {
      currentRef?.current?.focus();
    });
  };
  const focusFn: FocusFn<T> = focus || defaultFocus;

  const triggerFocus = () => {
    if (isReady() && pendingFocusId && autofocusId === pendingFocusId) {
      triggerFocusTarget(null);
      focusFn(forkedRef);
    }
  };

  // This part focus element when it's already mounted
  useEffect(() => {
    triggerFocus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingFocusId, autofocusId, isReady, triggerFocusTarget]);

  // This part focus element when in a moment of mounting
  const refCallback = useCallback(
    (refArg: T) => {
      setRef<T>(elRef, refArg);
      triggerFocus();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pendingFocusId, autofocusId, isReady, triggerFocusTarget]
  );

  return refCallback as RefCallback<T>;
}

export const useTriggerAutofocus = () => useContext(FocusContext)?.triggerFocusTarget;

/**
 * Set focus on element when it's mounted
 */
export const useFocusOnMount = <T extends HTMLElement | null = HTMLElement>(
  ref: React.RefObject<T> | RefCallback<T>,
  options?: { disabled?: boolean }
) => {
  const setFocus = useTriggerAutofocus();
  const id = useId();
  useEffect(() => {
    if (!options?.disabled) {
      setFocus(id);
    }
  }, []);
  return useAutofocusTarget({
    autofocusId: id,
    ref: ref,
  });
};
