import { Stack, Typography } from "@mui/material";
import { MdsLocationOnXs } from "@sinch/icons";
import React, { FC } from "react";
import { MdsIcon } from "../../MdsIcon/MdsIcon";

export const AddressSelectOption: FC<{ placeName?: string | null }> = ({ placeName }) => (
  <Stack
    direction="row"
    spacing={1}
    sx={{
      alignItems: "center",
      width: "100%",
    }}
  >
    <MdsIcon fontSize="xs" icon={MdsLocationOnXs} sx={{ color: ({ palette }) => palette.grey[800] }} />
    <Typography
      sx={({ typography }) => ({
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        fontSize: typography.pxToRem(14),
        color: ({ palette }) => palette.custom.jet[500],
      })}
    >
      {placeName}
    </Typography>
  </Stack>
);
