import { Stack, styled } from "@mui/material";
import React, { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import { FormControlWrapper } from "../FormControlWrapper/FormControlWrapper";
import { AddWageButton } from "./AddWageButton";
import { WageBuilderProps, WageForm, WageStep, WageType } from "./types";
import { WageCard } from "./WageCard";
import { WageTimeline } from "./WageTimeline";

const HorizontalScrollStack = styled(Stack)`
  overflow-x: scroll;
`;

/**
 * WageBuilder array of input for wages, reqiures FormContext(to be wrap in <FormProvider>)
 */
export const WageBuilder: React.FC<WageBuilderProps> = ({
  label,
  FormHelperTextProps: formHelperTextProps,
  helperText,
  errorMessage,
  error,
  infoTooltip,
  prefix = "wage",
  step = WageStep.NONE,
  ...props
}) => {
  const { fields, append, remove } = useFieldArray<WageForm>({
    name: prefix,
  });

  useEffect(() => {
    if (fields.length === 0) {
      append({
        duration: 0,
        value: 200,
        type: WageType.FIXED,
      });
    }
  }, [fields.length]);

  return (
    <FormControlWrapper
      error={error}
      errorMessage={errorMessage}
      FormHelperTextProps={formHelperTextProps}
      helperText={helperText}
      infoTooltip={infoTooltip}
      label={label}
      {...props}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: "center",
        }}
      >
        <HorizontalScrollStack direction="column" ml={-2} pb={3} px={2}>
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              width: "100%",
            }}
          >
            {fields.map((wage, i) => (
              <WageCard key={wage.id} index={i} onRemove={remove} prefix={prefix} step={step} wage={wage} />
            ))}
          </Stack>
          <WageTimeline prefix={prefix} />
        </HorizontalScrollStack>
        <Stack
          sx={{
            pb: 6,
          }}
        >
          <AddWageButton fields={fields} onAdd={append} prefix={prefix} />
        </Stack>
      </Stack>
    </FormControlWrapper>
  );
};
