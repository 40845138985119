import { Stack, Theme, Typography, TypographyProps } from "@mui/material";
import React, { Children, ElementType, PropsWithChildren, ReactElement } from "react";
import { MdsIconProps, MdsIcon } from "../../MdsIcon/MdsIcon";

export interface IconTypographyProps extends PropsWithChildren, TypographyProps {
  /**
   * Icon to add before text
   */
  icon: ElementType;
  /**
   * Icon size (same as fontSize for MdsIcon component)
   */
  iconSize?: MdsIconProps["fontSize"];
  /**
   * Icon color, is used in sx attribute, it can be any valid color
   */
  iconColor?: string | ((theme: Theme) => string);
  /**
   * Spacing between icon and text
   */
  spacing?: number;
}

/**
 * Text with icon in front of text
 */
export function IconTypography({
  icon,
  iconSize = "small",
  iconColor,
  children,
  spacing = 1,
  ...props
}: IconTypographyProps): ReactElement {
  return (
    <Stack
      direction="row"
      spacing={spacing}
      sx={{
        alignItems: "center",
      }}
    >
      <MdsIcon fontSize={iconSize} icon={icon} sx={{ color: iconColor }} />
      <Typography {...props}>{Children.toArray(children)[0]}</Typography>
      {Children.toArray(children).slice(1)}
    </Stack>
  );
}
