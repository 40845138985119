import { map, pick, propEq } from "ramda";
import React, { MutableRefObject, PropsWithChildren, ReactNode, useContext, useRef, useState } from "react";

export interface FillData<TPath> {
  path: TPath;
  value: any;
}

export interface RenderFillData {
  label: ReactNode;
  valueLabel: ReactNode;
}

export type AutofillData<TPath> = FillData<TPath> & RenderFillData;

type DataState = [
  AutofillData<any>[],
  React.Dispatch<React.SetStateAction<AutofillData<any>[]>>,
  MutableRefObject<Map<string, () => void>>,
];
const defaultState: DataState = [[], () => {}, { current: new Map<string, () => void>() }];
const AutofillContext = React.createContext<DataState>(defaultState);
AutofillContext.displayName = "Autofill";

export const AutofillProvider = <TPath,>({ children }: PropsWithChildren) => {
  const state = useState<AutofillData<TPath>[]>([]);
  const applyHandlers = useRef<Map<string, () => void>>(new Map<string, () => void>());
  return <AutofillContext.Provider value={[...state, applyHandlers]}>{children}</AutofillContext.Provider>;
};

export const useAutofillField = ({ name }: { name: string }) => {
  const [isFilled, setIsFilled] = useState(false);
  const context = useContext(AutofillContext);
  if (!context) {
    throw new Error("useAutofillField must be used within AutofillProvider");
  }

  const [autofillData, , applyCallbacks] = context;

  const hasSuggestion = autofillData.some(propEq("path", name));
  if (hasSuggestion) {
    if (isFilled) {
      setIsFilled(false);
    }
    applyCallbacks.current.set(name, () => {
      setIsFilled(true);
      applyCallbacks.current.delete(name);
    });
  } else {
    applyCallbacks.current.delete(name);
  }
  return { isFilled };
};

export const useAutofill = <TPath,>() => {
  const context = useContext(AutofillContext);
  if (!context) {
    throw new Error("useAutofill must be used within AutofillProvider");
  }
  const [autofillData, setData, onApplyCallbacks] = context;
  const resetData = () => {
    setData([]);
  };

  const applyCallback = (callback: (data: Pick<AutofillData<TPath>, "path" | "value">[]) => void) => {
    callback(map(pick(["path", "value"]), autofillData));
    onApplyCallbacks.current.forEach((onApplyHandler) => onApplyHandler());
    onApplyCallbacks.current.clear();
  };

  return { autofillData, applyCallback, resetData, setData };
};
