import { Box, Stack, Theme, Typography, TypographyTypeMap } from "@mui/material";
import React, { FC, ReactNode } from "react";

interface SectionTitleProps {
  title: ReactNode;
  variant?: TypographyTypeMap["props"]["variant"];
}

export const SectionTitle: FC<SectionTitleProps> = ({ title, variant = "h3" }) => (
  <Stack
    direction="row"
    sx={{
      alignItems: "baseline",
      justifyContent: "space-between",
    }}
  >
    <Typography
      sx={{
        whiteSpace: "nowrap",
      }}
      variant={variant}
    >
      {title}
    </Typography>
    <Box
      sx={{
        borderBottom: 1,
        borderColor: (theme: Theme) => theme.palette.grey[400],
        ml: 2,
        width: "100%",
      }}
    />
  </Stack>
);
