import { Grid, Stack, styled, Typography, useTheme } from "@mui/material";
import { unstable_generateUtilityClasses as generateUtilityClasses } from "@mui/utils";
import { UploadDropZone, UploadDropZoneProps } from "@rpldy/upload-drop-zone";
import { MdsUpload } from "@sinch/icons";
import clsx from "clsx";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { UploadContainerProps, UploadInputProps } from "../types";

type DropZoneUploadProps = UploadContainerProps;
/**
 * DropZone Image Upload - Drag and drop upload for images(can be used for single or multiple files)
 */

const classes = generateUtilityClasses("SinchDragZoneImageUploader", [
  "uploadStack",
  "uploadIcon",
  "textContainer",
  "hideOnDrag",
  "uploadButton",
  "textContainer",
  "showOnDrag",
  "dragOver",
]);

export const DropZoneFileUploadContainer: React.FC<DropZoneUploadProps> = ({
  value = [],
  endpoint,
  multiple,
  name,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  error, // TODO: implement errors to input
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  disabled, // TODO: implement disabled to input
  previewItemComponent: PreviewItem,
  uploadComponent: UploadButtonComponent,
  UploadyProps: uploadyProps,
}) => {
  const indicatorRef = useRef<HTMLDivElement>(null);

  return (
    <StyledUploadDropZone
      destination={{ url: endpoint }}
      enableOnContains
      onDragOverClassName={classes.dragOver}
      params={{
        name: name,
      }}
      shouldHandleDrag
      shouldRemoveDragOver={(e: DragEvent) => !indicatorRef?.current?.contains(e.relatedTarget as Node)}
      {...uploadyProps}
    >
      <Stack
        ref={indicatorRef}
        className={classes.uploadStack}
        spacing={1}
        sx={{
          padding: 3,
        }}
      >
        <DragZoneFileUploadContent multiple={multiple} uploadButtonComponent={UploadButtonComponent} />
        {value?.length > 0 && (
          <Grid
            container
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "center",
            }}
          >
            {value?.map((file) => (
              <Grid key={file.id} item>
                <PreviewItem {...file} multiple={multiple} />
              </Grid>
            ))}
          </Grid>
        )}
      </Stack>
    </StyledUploadDropZone>
  );
};

interface DragZoneImageUploadContentProps {
  multiple?: boolean;
  helperText?: string;
  uploadButtonComponent: UploadInputProps["slots"]["upload"];
}

const DragZoneFileUploadContent: React.FC<DragZoneImageUploadContentProps> = ({
  multiple,
  uploadButtonComponent: UploadButtonComponent,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();

  const secondaryText = multiple ? t("ImageUpload.multipleHelperText") : undefined;
  return (
    <>
      <MdsIcon className={classes.uploadIcon} htmlColor={theme.palette.grey["400"]} icon={MdsUpload} />
      <Grid className={clsx(classes.textContainer, classes.hideOnDrag)}>
        <Typography variant="body1">
          {multiple ? t("FileUpload.dropImagesHereOr") : t("FileUpload.dropImageHereOr")}&nbsp;
          <UploadButtonComponent>{t("ImageUpload.selectText")}</UploadButtonComponent>
        </Typography>
        {secondaryText && <Typography variant="body2">{secondaryText}</Typography>}
      </Grid>
      <Grid className={clsx(classes.textContainer, classes.showOnDrag)}>
        <Typography>{t("FileUpload.dropHere")}</Typography>
      </Grid>
    </>
  );
};

const StyledUploadDropZone = styled(UploadDropZone)<UploadDropZoneProps>(({ theme }) => ({
  [`& > .${classes.uploadStack}`]: {
    position: "relative",
    overflow: "hidden",
    [`& > .${classes.textContainer}`]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "2.4rem",
      [`&.${classes.showOnDrag}`]: {
        display: "none",
      },
    },
    border: `1px solid ${theme.palette.grey["400"]}`,
    borderRadius: "6px !important",
    display: "flex",
    alignItems: "center",
    [`& .${classes.uploadButton}`]: {
      border: "none",
      background: "none",
      padding: 0,
      color: theme.palette.primary.light,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  },
  [`&.${classes.dragOver}`]: {
    [`& > .${classes.uploadStack}`]: {
      borderStyle: "dashed",
      borderColor: theme.palette.primary.dark,
      [`& .${classes.uploadIcon}`]: {
        color: theme.palette.primary.dark,
      },
      [`& .${classes.textContainer}.${classes.hideOnDrag}`]: {
        display: "none",
      },
      [`& .${classes.textContainer}.${classes.showOnDrag}`]: {
        display: "flex",
      },
    },
  },
  "&:hover": {
    [`& .${classes.uploadIcon}`]: {
      color: theme.palette.primary.dark,
    },
    [`& > .${classes.uploadStack}`]: {
      borderColor: theme.palette.primary.dark,
    },
  },
}));
