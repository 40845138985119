import { useEffect } from "react";
import { FieldValues } from "react-hook-form";
import { UseMuiFormReturn } from "../useMuiForm.types";

export const useOptimisticUpdateForm = <
  TFieldValues extends FieldValues = FieldValues,
  TForm extends UseMuiFormReturn<TFieldValues> = UseMuiFormReturn<TFieldValues>,
>({
  form,
  value,
  onChange,
}: {
  form: TForm;
  value: TFieldValues;
  onChange: (data: TFieldValues) => void;
}) => {
  useEffect(() => {
    const subscription = form.watch(() => form.handleSubmit(onChange)());
    return () => subscription.unsubscribe();
  }, [form, form.handleSubmit, form.watch, onChange]);

  useEffect(() => {
    form.reset(value);
  }, [value]);
};
