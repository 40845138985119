import { Box, IconButton, styled, TableCell, TableRow, TableRowProps, Typography } from "@mui/material";
import {
  AttendanceActionEnum,
  AttendanceReconfirmationStatusEnum,
  AttendanceStatusEnum,
} from "@sinch/core/entities/serverEnums";

import { useCurrencySymbol } from "@sinch/hooks/useCurrencySymbol";
import { MdsMoreVert } from "@sinch/icons";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { MouseEventHandler } from "react";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { AttendanceStatusIcon } from "../../Icons/AttendanceStatusIcon";
import { WorkerListItem } from "../../Worker/WorkerListItem";
import { ActionMenu } from "../ActionMenu/ActionMenu";
import { ShiftPanelDetailFragment } from "../queries.types";
import { TransportsCell } from "../TransportCell";
import { ReconfirmationStatusIcon } from "@sinch/components/application/Icons/ReconfirmationStatusIcon";

export const AttendanceRow: React.FC<{
  attendance: ShiftPanelDetailFragment["positions"][0]["attendances"][0];
  onUpdate?: () => Promise<any>;
}> = ({ attendance }) => {
  const symbol = useCurrencySymbol();
  return (
    <AttendanceTableRow key={attendance.id}>
      <TableCell colSpan={2} size="small" width={24}>
        <WorkerListItem expectedRating={attendance.rating?.amount} worker={attendance.worker} />
      </TableCell>
      <TableCell size="small">
        <Box
          sx={{
            display: "flex",
            justifyItems: "center",
            gap: 0.5,
          }}
        >
          <AttendanceStatusIcon status={attendance.status as AttendanceStatusEnum} />
          <ReconfirmationStatusIcon status={attendance.reconfirmationStatus} />
        </Box>
      </TableCell>
      <TableCell size="small">
        <TransportsCell transportAttendances={attendance.transportAttendances} />
      </TableCell>
      <TableCell size="small">
        <Typography
          sx={{
            whiteSpace: "nowrap",
          }}
        >
          {attendance.wage} {symbol}
        </Typography>
      </TableCell>
      {isNotNilOrEmpty(attendance.allowedActions) && (
        <TableCell size="small">
          <ActionMenuCell
            allowedActions={attendance.allowedActions as AttendanceActionEnum[]}
            attendanceId={attendance.id}
            attendanceReconfirmationStatus={attendance.reconfirmationStatus as AttendanceReconfirmationStatusEnum}
            attendanceStatus={attendance.status as AttendanceStatusEnum}
          />
        </TableCell>
      )}
    </AttendanceTableRow>
  );
};

export const AttendanceTableRow = styled(TableRow)<TableRowProps>(({ theme: { spacing } }) => ({
  padding: spacing(0, 1),
  height: "32px",
  "& .MuiTableCell-root": {
    padding: spacing(0, 0.5),
  },
  "& .MuiTableCell-root:first-of-type": {
    paddingLeft: spacing(2),
  },
}));

const ActionMenuCell: React.FC<{
  attendanceId: string;
  allowedActions: AttendanceActionEnum[];
  attendanceStatus: AttendanceStatusEnum;
  attendanceReconfirmationStatus: AttendanceReconfirmationStatusEnum;
}> = ({ attendanceId, allowedActions, attendanceStatus, attendanceReconfirmationStatus }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleClick} sx={{ padding: 0 }}>
        <MdsIcon color="info" fontSize="small" icon={MdsMoreVert} />
      </IconButton>
      <ActionMenu
        allowedActions={allowedActions}
        anchorEl={anchorEl}
        attendanceId={attendanceId}
        attendanceReconfirmationStatus={attendanceReconfirmationStatus}
        attendanceStatus={attendanceStatus}
        onClose={handleClose}
      />
    </>
  );
};
