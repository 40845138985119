import { Box, Grid, IconButton, Tab, Tabs } from "@mui/material";
import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import { MdsClose } from "@sinch/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoadingWrapper } from "../../LoadingWrapper/LoadingWrapper";
import { MdsIcon } from "../../MdsIcon";
import { StaffHeader } from "../../Staff/StaffHeader";
import { StaffHeaderActionMenu } from "../../Staff/StaffHeaderActionMenu";
import { AttributesTab } from "./AttributesTab";
import { BadgesTab } from "./BadgesTab";
import { PersonalTab } from "./PersonalTab/PersonalTab";
import { StaffDetail } from "./queries.graphql";
import { StaffDetailQuery } from "./queries.types";
import { ShiftAttendanceTable } from "./ShiftTable/ShiftAttendanceTable";

interface StaffDetailPanelProps {
  staffId: string;
  onClose: () => void;
}

export enum StaffDetailPanelTabs {
  Personal,
  AllAttributes,
  Badges,
}

export const StaffDetailPanel: React.FC<StaffDetailPanelProps> = ({ staffId, onClose }) => {
  const { data } = useQueryHandler<StaffDetailQuery>(StaffDetail, { id: staffId });
  const [tab, setTab] = useState<StaffDetailPanelTabs>(StaffDetailPanelTabs.Personal);
  const { t } = useTranslation();
  if (!data?.worker) {
    return <LoadingWrapper on sx={{ height: "100%" }} />;
  }

  const handleChangeTab = (_: React.SyntheticEvent, value: StaffDetailPanelTabs) => {
    setTab(value);
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          backgroundColor: theme.palette.background.paper,
          zIndex: theme.zIndex.appBar,
          padding: theme.spacing(2, 2, 0, 0),
        })}
      >
        <StaffHeaderActionMenu staffId={staffId} />
        {onClose && (
          <IconButton onClick={onClose}>
            <MdsIcon fontSize="small" icon={MdsClose} sx={{ color: ({ palette }) => palette.info.main }} />
          </IconButton>
        )}
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          padding: 2,
          position: "relative",
        }}
      >
        <Grid item xs={12}>
          <StaffHeader staff={data.worker} />
        </Grid>
        <Grid item xs={12}>
          <Tabs
            onChange={handleChangeTab}
            sx={(theme) => ({
              minHeight: "unset",
              "& .MuiButtonBase-root.MuiTab-root": {
                minHeight: "unset",
                padding: theme.spacing(0.5, 1),
              },
              borderBottom: 1,
              borderColor: theme.palette.grey[400],
            })}
            value={tab}
          >
            <Tab label={t("ShiftTimeline.personal")} value={StaffDetailPanelTabs.Personal} />
            <Tab label={t("ShiftTimeline.attributes")} value={StaffDetailPanelTabs.AllAttributes} />
            <Tab label={t("ShiftTimeline.badges")} value={StaffDetailPanelTabs.Badges} />
          </Tabs>
        </Grid>
        <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          xs={12}
        >
          {tab === StaffDetailPanelTabs.Personal && <PersonalTab staff={data.worker} />}
          {tab === StaffDetailPanelTabs.AllAttributes && <AttributesTab staff={data.worker} />}
          {tab === StaffDetailPanelTabs.Badges && <BadgesTab staff={data.worker} />}
        </Grid>
      </Grid>
      {tab === StaffDetailPanelTabs.Personal && (
        <ShiftAttendanceTable
          attendances={data.worker.attendances}
          count={data.worker.attendanceStatistics.totalCount}
          workerId={data.worker.id}
        />
      )}
    </Box>
  );
};
