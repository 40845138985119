import { Grid, styled } from "@mui/material";
import { useFormat } from "@sinch/core/hooks/format";
import clsx from "clsx";
import { DateTime } from "luxon";
import React, { HTMLAttributes, memo } from "react";
import { ItemProps } from "react-virtuoso";
import { testIds } from "../constants";
import { ITEM_HEIGHT, ItemContext } from "./types";

export const VirtuosoItem = memo(
  ({ children, item, context, ...props }: ItemProps<DateTime> & { context?: ItemContext }) => {
    if (!item) {
      return null;
    }
    return (
      <StyledVirtuosoItem
        className={clsx(
          props["data-index"] === context?.selectedIndexOverall ? "Mui-selected" : "",
          "SinchTimePickerOption-root"
        )}
        sx={context?.timeItemsStyle?.(item.toJSDate())}
        {...props}
        data-cy="timeInputOption-date"
        onClick={() => {
          context?.onChange(item);
        }}
        onPointerDown={(e) => {
          e.preventDefault();
        }}
        style={{ ...props.style, height: `${ITEM_HEIGHT}px` }}
      >
        {children}
      </StyledVirtuosoItem>
    );
  }
);
VirtuosoItem.displayName = "VirtuosoItem";

const StyledVirtuosoItem = styled("div")<HTMLAttributes<HTMLDivElement>>({});

export const TimePickerOption = memo(
  ({ option, dateInOption, timePoint }: { option: DateTime; dateInOption?: boolean; timePoint?: DateTime | null }) => {
    const { date, time, duration } = useFormat();
    if (!option) {
      return;
    }
    const jsDate = option.toJSDate();
    return (
      <Grid container spacing={1} wrap="nowrap">
        {dateInOption && (
          <Grid
            item
            sx={{
              color: "text.secondary",
            }}
            xs="auto"
          >
            {date.short(jsDate)}
          </Grid>
        )}
        <Grid data-cy={testIds.timeInputOptionTime} item sx={{ minWidth: "3em" }} xs="auto">
          {time.short(jsDate)}
        </Grid>
        {timePoint && (
          <Grid
            item
            sx={{
              color: "text.disabled",
              fontSize: "smaller",
            }}
            xs="auto"
          >
            ({duration.narrow(option.diff(timePoint, "seconds").seconds)})
          </Grid>
        )}
      </Grid>
    );
  }
);
TimePickerOption.displayName = "TimePickerOption";
