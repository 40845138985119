import { alpha, Button, ButtonProps, styled, ToggleButton, ToggleButtonProps } from "@mui/material";

export const ToggleToolbarButton = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
  color: theme.palette.secondary.dark,
  border: "none",
  padding: theme.spacing(0.5),
  "&.Mui-disabled": {
    color: alpha(theme.palette.secondary.dark, 0.5),
    border: "none",
    backgroundColor: "transparent",
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.grey["900"], 0.04),
  },
}));

export const ToolbarButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: "initial",
  "&::first-letter": {
    textTransform: "capitalize",
  },
  fontWeight: theme.typography.fontWeightMedium,
  color: theme.palette.secondary.dark,
  border: "none",
  padding: theme.spacing(0.5),
  fontSize: theme.typography.pxToRem(11),
  minWidth: "auto",
  "&:hover": {
    backgroundColor: alpha(theme.palette.grey["900"], 0.04),
  },
  "&.Mui-disabled": {
    color: alpha(theme.palette.secondary.dark, 0.5),
  },
  "& .MuiButton-endIcon": {
    paddingLeft: 0,
  },
  "& .MuiButton-startIcon": {
    paddingRight: 0,
  },
}));
