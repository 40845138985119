query Tags {
    tagVisibilities {
        id
        label
    }
    tags {
        id
        name
        visibility
    }
}
