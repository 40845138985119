import { Alert, AlertProps, Stack, Typography } from "@mui/material";

import { MessageSeverity } from "@sinch/core/entities/serverEnums";
import { useMuiForm } from "@sinch/hooks/useMuiForm";
import { MutationMessage } from "@sinch/types/sinch.types";
import { flatten, groupBy, isNil, values } from "ramda";
import { isFunction, isNotNilOrEmpty } from "ramda-adjunct";
import React, { PropsWithChildren, useEffect, useRef } from "react";
import { FieldValues, FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ConditionalWrapper } from "../../ConditionalWrapper/ConditionalWrapper";
import { ConfirmDialog } from "../../ConfirmDialog/ConfirmDialog";
import { LoadingWrapper } from "../../LoadingWrapper/LoadingWrapper";
import { FormPersistorDialogWrapper, usePersistFormData } from "../FormPersistor";

export interface FormContainerProps<TFieldValues extends FieldValues = FieldValues> extends PropsWithChildren {
  /**
   * Form methods from react-hook-form
   */
  form: ReturnType<typeof useMuiForm<TFieldValues>>;
  /**
   * Persistor options for form, if null, persistor will not be used
   */
  persistor?: {
    control: ReturnType<typeof usePersistFormData<TFieldValues>>;
    /**
     * Get name of persisted state
     */
    persistName?: (persistedState: TFieldValues) => string | string;
  };
  /**
   * Response messages from mutation
   */
  responseMessages?: Record<string, MutationMessage[]>;
  /**
   * Loading state of form
   */
  loading?: boolean;
}

/**
 * Container for form, handles persistor, response messages and loading state, simplify initialization of form
 */
export const FormContainer = <TFieldValues extends FieldValues = FieldValues>({
  children,
  form,
  persistor,
  responseMessages,
  loading,
}: FormContainerProps<TFieldValues>) => {
  const { t } = useTranslation();

  const messages = groupBy(
    (message: MutationMessage) => message.severity,
    flatten(values(responseMessages ?? ({} as Record<string, MutationMessage[]>))).filter(({ field }) => isNil(field))
  );

  return (
    <FormProvider {...form}>
      <ConditionalWrapper
        condition={!!persistor}
        wrapper={(persistorChildren) => (
          <FormPersistorDialogWrapper<TFieldValues>
            cancel={persistor!.control!.discard}
            confirm={persistor!.control!.apply}
            pending={persistor!.control!.pending}
            persistedState={persistor!.control!.persistedState}
            persistKey={persistor!.control!.key}
            renderConfirmation={({ cancel, confirm, open, persistedState }) => {
              const persistedName = isFunction(persistor!.persistName)
                ? persistor!.persistName(persistedState)
                : persistor!.persistName;
              return (
                <ConfirmDialog
                  maxWidth="sm"
                  onCancel={cancel}
                  onConfirm={confirm}
                  open={open}
                  title={t("continueEditing")}
                >
                  <Typography
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {t("Form.youHaveUnsaved", {
                      persistName: isNotNilOrEmpty(persistedName) ? ` (${persistedName})` : "​",
                    })}
                  </Typography>
                </ConfirmDialog>
              );
            }}
          >
            {persistorChildren}
          </FormPersistorDialogWrapper>
        )}
      >
        <>
          {isNotNilOrEmpty(messages[MessageSeverity.Error]) && (
            <ErrorBox messages={messages[MessageSeverity.Error]} severity={MessageSeverity.Error} />
          )}
          {isNotNilOrEmpty(messages[MessageSeverity.Warning]) && (
            <ErrorBox messages={messages[MessageSeverity.Warning]} severity={MessageSeverity.Warning} />
          )}

          <LoadingWrapper on={loading}>{children}</LoadingWrapper>
        </>
      </ConditionalWrapper>
    </FormProvider>
  );
};

const ErrorBox = ({ messages, severity }: { messages: MutationMessage[]; severity: MessageSeverity }) => {
  const ref = useRef<HTMLDivElement | null>();
  useEffect(() => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  const severityMap: Record<MessageSeverity, AlertProps["severity"]> = {
    [MessageSeverity.Error]: "error",
    [MessageSeverity.Warning]: "warning",
  };

  return (
    <Alert
      ref={(refEl) => (ref.current = refEl)}
      icon={false}
      severity={severityMap[severity] ?? "info"}
      sx={{ mb: 2 }}
    >
      <Stack>
        {messages.map(({ message }, index) => (
          <Typography key={index}>{message}</Typography>
        ))}
      </Stack>
    </Alert>
  );
};
