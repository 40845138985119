export const isTouchDevice = () => {
  if (window.PointerEvent && "maxTouchPoints" in window.navigator) {
    // if Pointer Events are supported, just check maxTouchPoints
    if (window.navigator.maxTouchPoints > 0) {
      return true;
    }
  } else {
    // no Pointer Events...
    if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) {
      // check for any-pointer:coarse which mostly means touchscreen
      return true;
    } else if (window.TouchEvent || "ontouchstart" in window) {
      // last resort - check for exposed touch events API / event handler
      return true;
    }
  }
  return false;
};

const appleIphone = /iPhone/i;
const appleTablet = /iPad/i;
const androidPhone = /\bAndroid(?:.+)Mobile\b/i; // Match 'Android' AND 'Mobile'
const androidTablet = /Android/i;

export type UserAgent = string;
export interface Navigator {
  userAgent: string;
  platform: string;
  maxTouchPoints?: number;
}

const isAppleTabletOnIos13 = (navigator?: Navigator): boolean =>
  typeof navigator !== "undefined" &&
  navigator.platform === "MacIntel" &&
  typeof navigator.maxTouchPoints === "number" &&
  navigator.maxTouchPoints > 1 &&
  !("MSStream" in window);

function createMatch(userAgent: UserAgent): (regex: RegExp) => boolean {
  return (regex: RegExp): boolean => regex.test(userAgent);
}

export interface IsMobileResult {
  apple: boolean;
  android: boolean;
  isMobile: boolean;
}

export const isMobile = (): IsMobileResult => {
  const navigator = window.navigator;
  const nav: Navigator = {
    userAgent: navigator.userAgent,
    platform: navigator.platform,
    maxTouchPoints: navigator.maxTouchPoints || 0,
  };

  let userAgent = nav.userAgent;

  // Facebook mobile app's integrated browser adds a bunch of strings that
  // match everything. Strip it out if it exists.
  let tmp = userAgent.split("[FBAN");
  if (typeof tmp[1] !== "undefined") {
    userAgent = tmp[0];
  }

  // Twitter mobile app's integrated browser on iPad adds a "Twitter for
  // iPhone" string. Same probably happens on other tablet platforms.
  // This will confuse detection so strip it out if it exists.
  tmp = userAgent.split("Twitter");
  if (typeof tmp[1] !== "undefined") {
    userAgent = tmp[0];
  }

  const match = createMatch(userAgent);

  const apple = match(appleIphone) || (!match(appleIphone) && (match(appleTablet) || isAppleTabletOnIos13(nav)));
  const android = match(androidPhone) || (!match(androidPhone) && match(androidTablet));
  return {
    apple: apple,
    android: android,
    isMobile: apple || android,
  };
};
