import {
  Box,
  IconButton,
  IconButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Theme,
  Tooltip,
} from "@mui/material";
import { SxProps } from "@mui/system";
import { MdsMoreVert } from "@sinch/icons";

import { isTouchDevice } from "@sinch/utils/browser";
import { isNil } from "ramda";
import React, { ElementType, FC, ReactElement } from "react";
import { MdsIcon, MdsIconProps } from "../MdsIcon/MdsIcon";

export interface RowActionMenuItem {
  /**
   * Title for menu or tooltip
   */
  title: string;
  /**
   * Click event on icon or menu item
   */
  onClick: () => void;
  /**
   * Icon for menu or icon
   */
  icon: ElementType;
  color?: string;
}
export interface RowActionMenuProps {
  /**
   * Size of icon applied for kebab menu icon and list of icon buttons, but not on icons in menu
   */
  iconSize?: MdsIconProps["fontSize"];
  /**
   * If true, version for touch is used, if udnefined, check touch device status automatically
   */
  touch?: boolean;
  /**
   * List of item to show
   */
  items: RowActionMenuItem[];
  sx?: SxProps<Theme>;
}

export const RowActionMenu: FC<RowActionMenuProps> = ({ items, sx, iconSize, touch }): ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const isTouch = isNil(touch) ? isTouchDevice() : touch;

  if (isTouch) {
    return (
      <Box component="span" sx={sx}>
        <IconButton onClick={handleClick}>
          <MdsIcon color="info" fontSize={iconSize} icon={MdsMoreVert} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          onClose={handleClose}
          open={open}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
        >
          {items.map(({ title, color, onClick, icon }) => (
            <MenuItem
              key={title}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleClose();
                onClick();
              }}
            >
              <ListItemIcon color={color}>
                <MdsIcon color={color as MdsIconProps["color"]} fontSize="small" icon={icon} />
              </ListItemIcon>
              <ListItemText>{title}</ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  }

  return (
    <Box sx={sx}>
      <Stack
        direction="row"
        spacing={1}
        sx={{
          justifyContent: "flex-end",
        }}
      >
        {items.map(({ title, color, onClick, icon }) => (
          <Tooltip key={title} title={title}>
            <IconButton
              color={color as IconButtonProps["color"]}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                onClick();
              }}
              size="small"
            >
              <MdsIcon color={color as MdsIconProps["color"]} fontSize={iconSize} icon={icon} />
            </IconButton>
          </Tooltip>
        ))}
      </Stack>
    </Box>
  );
};
