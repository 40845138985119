import React from "react";
import { useTranslation } from "react-i18next";
import { useMuiController } from "../../../form/MuiController/MuiController";
import { TextInput } from "../../../form/TextInput/TextInput";
import { PlaceFormFields } from "./formSchema";

export const AliasInput = () => {
  const { t } = useTranslation();
  const { field } = useMuiController<PlaceFormFields>({
    name: "alias",
  });

  return (
    <TextInput
      slotProps={{
        input: { "data-cy": "alias" },
      }}
      {...field}
      label={t("Place.alias")}
    />
  );
};
