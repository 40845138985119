import { Box, BoxProps, styled } from "@mui/material";

export interface PointProps {
  value: number;
  location: number;
  hours: number;
  type: PointType;
  endAdornment?: boolean;
}

export enum PointType {
  FIRST,
  SMALL,
  LAST,
}

export const Point = styled(Box)<BoxProps & PointProps & { currentLocation: number | null }>(
  ({ theme, type, location, currentLocation }) => ({
    width: type !== PointType.SMALL ? "8px" : "6px",
    height: type !== PointType.SMALL ? "8px" : "6px",
    backgroundColor:
      currentLocation && location <= currentLocation ? theme.palette.secondary.dark : theme.palette.grey[400],
    transition: "0.25s ease",
    borderRadius: "50% !important",
  })
);

export const PointWrapper = styled(Box)<BoxProps & PointProps>(({ theme, location, type }) => ({
  position: "absolute",
  zIndex: 1,
  left: `${location}px`,
  top: type !== PointType.SMALL ? "-3px" : "-2px",
  cursor: "pointer",
  padding: theme.spacing(1.2),
  marginLeft: theme.spacing(-1.2),
  marginTop: theme.spacing(-1.2),
}));
