import { GridProps } from "@mui/material";
import React, { FC, PropsWithChildren, useContext } from "react";

const GridTableContext = React.createContext<GridTableProps["columns"] | undefined>(undefined);
GridTableContext.displayName = "GridTable";

interface GridTableProps {
  /**
   * Grid pros for define columns
   */
  columns: GridProps[];
}

/**
 * Provide context to create table from Grid component, props of each colum are defined as value of context and is provided by hook for all Grid components
 */
export const GridTableProvider: FC<PropsWithChildren<GridTableProps>> = ({ children, columns }) => (
  <GridTableContext.Provider value={columns}>{children}</GridTableContext.Provider>
);

interface UseGridReturn {
  /**
   * Get props for Grid element by column index
   */
  colProps: (index: number) => GridProps | undefined;
}

/**
 * Provide function for get props of Grid by column index
 */
export const useGridTable = (): UseGridReturn => {
  const colDefs = useContext(GridTableContext);
  return { colProps: (index) => colDefs?.[index] };
};
