import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $wrapNodeInElement } from "@lexical/utils";
import {
  $createParagraphNode,
  $insertNodes,
  $isRootOrShadowRoot,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
  LexicalCommand,
} from "lexical";
import { FC, useEffect } from "react";
import { $createPlaceholderNode, PlaceholderNode } from "@sinch/components/form/TextEditor/nodes/PlaceholderNode";

interface CommandPayload {
  placeholder: string;
}

export const INSERT_PLACEHOLDER_COMMAND: LexicalCommand<CommandPayload> = createCommand("INSERT_PLACEHOLDER_COMMAND");

export const PlaceholdersPlugin: FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (!editor.hasNodes([PlaceholderNode])) {
      throw new Error("PlaceholderPlugins: PlaceholderNode not registered on editor");
    }

    editor.registerCommand<CommandPayload>(
      INSERT_PLACEHOLDER_COMMAND,
      (payload) => {
        const { placeholder } = payload;
        const placeholderNode = $createPlaceholderNode(placeholder);

        $insertNodes([placeholderNode]);
        if ($isRootOrShadowRoot(placeholderNode.getParentOrThrow())) {
          $wrapNodeInElement(placeholderNode, $createParagraphNode).selectEnd();
        }

        return true;
      },
      COMMAND_PRIORITY_EDITOR
    );
  }, [editor]);

  return null;
};
