import { Button, debounce, Divider, Menu, MenuItem, Stack, Theme, Typography, useMediaQuery } from "@mui/material";

import { useLazyQueryHandler } from "@sinch/hooks/useLazyQueryHandler";
import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import { MdsAdd, MdsExpandMore } from "@sinch/icons";
import { filter, isNil, pick, reduce, values } from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { FC, ReactElement, useEffect, useMemo, useState } from "react";
import { ArrayPath, FieldArray, FieldValues, useFieldArray, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../MdsIcon/MdsIcon";
import { RequirementFulfill, Requirements as RequirementsQueryDefinition } from "./queries.graphql";
import { RequirementFulfillQuery, RequirementFulfillQueryVariables, RequirementsQuery } from "./queries.types";
import { RequirementTableDesktop } from "./RequirementsTableDesktop";
import { RequirementTableMobile } from "./RequirementTableMobile";
import { PositionRequirementFormFields } from "./types";

interface AddPositionButtonProps {
  /**
   * Click event, call with profession id
   */
  onClick: (professionId: string) => void;
  /**
   * Button label
   */
  label: string;
}

/**
 * Button for creating position with selected profession
 */
const AddRequirementButton: FC<AddPositionButtonProps> = ({ onClick, label }) => {
  const { data: requirementsData } = useQueryHandler<RequirementsQuery>(RequirementsQueryDefinition);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick = (positionId: string) => {
    setAnchorEl(null);
    onClick(positionId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Button
        endIcon={<MdsIcon icon={MdsExpandMore} />}
        fullWidth={false}
        onClick={openMenu}
        startIcon={<MdsIcon icon={MdsAdd} />}
        variant="text"
      >
        {label}
      </Button>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        {requirementsData?.requirements?.map(({ id, label: requirementLabel }) => (
          <MenuItem key={id} onClick={() => handleClick(id)}>
            {requirementLabel}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

interface StaffCountProps {
  prefix: string;
}

const StaffCount = ({ prefix }: StaffCountProps) => {
  const { t } = useTranslation();
  const requirements = useWatch({
    name: `${prefix}.requirements`,
  });

  const filled = useMemo(
    () =>
      reduce(
        (acc, requirement: PositionRequirementFormFields) => {
          const requirementParams = pick(["operator", "requirementId", "subject"], requirement);
          if (isNilOrEmpty(filter(isNilOrEmpty, values(requirementParams)))) {
            acc.push({ ...requirementParams, value: requirement.value });
          }
          return acc;
        },
        [] as Pick<PositionRequirementFormFields, "operator" | "requirementId" | "subject" | "value">[],
        requirements ?? ([] as PositionRequirementFormFields[])
      ),
    [requirements]
  );

  const queryVariables = useMemo<RequirementFulfillQueryVariables>(
    () => ({ filter: { requirementList: filled } }),
    [filled]
  );

  const { data, dispatch, refetch } = useLazyQueryHandler<RequirementFulfillQuery, RequirementFulfillQueryVariables>(
    RequirementFulfill,
    queryVariables
  );

  useEffect(() => {
    debounce(dispatch, 500)(queryVariables);
  }, [queryVariables, refetch, dispatch]);

  return (
    <Typography
      sx={{
        fontWeight: 600,
        whiteSpace: "nowrap",
      }}
    >
      {t("NewJobForm.fulfillsRequirement")}:{" "}
      <Typography
        component="span"
        sx={{
          fontWeight: 700,
        }}
      >
        {data?.requirementFulfill ?? 0}
      </Typography>
    </Typography>
  );
};

export interface RequirementTableProps {
  prefix: string;
  label: string;
  icon: ReactElement;
  hideStaffCount?: boolean;
  secondaryLabel?: string;
}
/**
 * Table with positions created for shift
 */
export const RequirementsTable = <FormFields extends FieldValues, RequirementPath extends ArrayPath<FormFields>>({
  label,
  prefix,
  icon,
  hideStaffCount,
  secondaryLabel,
}: RequirementTableProps) => {
  const { t } = useTranslation();
  const { fields, ...fieldArrayMethods } = useFieldArray<FormFields, RequirementPath>({
    name: `${prefix}.requirements` as RequirementPath,
  });
  const [editedFieldIx, setEditedFieldIx] = useState<number | null>(null);

  const handleAdd = (requirementId: string) => {
    fieldArrayMethods.append({ requirementId: requirementId } as FieldArray<FormFields, RequirementPath>);
    setEditedFieldIx(fields.length);
  };
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  return (
    <Stack spacing={1}>
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Stack direction="row" spacing={1}>
          {icon}
          <Typography
            sx={{
              alignItems: "center",
              display: "flex",
              marginTop: (theme) => theme.spacing(3),
            }}
            variant="h4"
          >
            {label}
          </Typography>
          {secondaryLabel && (
            <Typography
              sx={{
                color: (theme) => theme.palette.grey["800"],
              }}
              variant="body1"
            >
              ({secondaryLabel})
            </Typography>
          )}
        </Stack>
        {!hideStaffCount && <StaffCount prefix={prefix} />}
      </Stack>
      {isMobile && (
        <RequirementTableMobile
          editedFieldIx={editedFieldIx}
          fieldArrayMethods={fieldArrayMethods}
          fields={fields}
          prefix={prefix}
          setEditedFieldIx={(ix) => setEditedFieldIx(ix)}
        />
      )}
      {!isMobile && (
        <RequirementTableDesktop
          editedFieldIx={editedFieldIx}
          fieldArrayMethods={fieldArrayMethods}
          fields={fields}
          prefix={prefix}
          setEditedFieldIx={(ix) => setEditedFieldIx(ix)}
        />
      )}
      {isMobile && <Divider />}
      {isNil(editedFieldIx) && (
        <Stack direction="row">
          <AddRequirementButton label={t("addRequirement")} onClick={(requirementId) => handleAdd(requirementId)} />
        </Stack>
      )}
    </Stack>
  );
};
