import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CheckboxInput } from "../../form/CheckboxInput/CheckboxInput";
import { useMuiController } from "../../form/MuiController/MuiController";
import { SignInDialogFormFields } from "./types";

/**
 * Component for displaying a checkbox to require confirmation.
 **/
export const RequireConfirmationCheckbox: FC = () => {
  const { t } = useTranslation();

  // Hook to control the form field for require confirmation
  const { field } = useMuiController<SignInDialogFormFields, "requireConfirmation">({
    name: "requireConfirmation",
  });

  return (
    <CheckboxInput
      {...field}
      color="info"
      infoTooltip={t("SignInDialog.requireConfirmationDescription")}
      label={t("SignInDialog.requireConfirmation")}
    />
  );
};
