import React, { PropsWithChildren, useContext } from "react";
import { DragAndDropControl } from "./useDragAndDrop";

const DragAndDropContext = React.createContext<DragAndDropControl | undefined>(undefined);
DragAndDropContext.displayName = "DragAndDrop";

type DragAndDropProviderProps = PropsWithChildren<DragAndDropControl>;

/**
 * Provide context for drag and drop functionality
 */
export function DragAndDropProvider({ children, ...props }: DragAndDropProviderProps) {
  return <DragAndDropContext.Provider value={props}>{children}</DragAndDropContext.Provider>;
}

/**
 * Provide function for getting dnd context
 */
export function useDragAndDropContext() {
  return useContext<DragAndDropControl | undefined>(DragAndDropContext);
}
