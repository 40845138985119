import { reject } from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";

interface Address {
  address?: null | string;
  city?: null | string;
  region?: null | string;
  country?: null | string;
  zip?: null | string;
}

// TODO: replace it with address formatter
export const parseAddress = ({ address, city, region, country, zip }: Address): string =>
  reject(isNilOrEmpty, [address, city, region, country, zip]).join(", ");
