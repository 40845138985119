import { Grid, InputBaseComponentsPropsOverrides } from "@mui/material";

import { useAutoJumpFocus } from "@sinch/hooks/useAutoJumpFocus";
import { FactoryArg, InputMask } from "imask";
import React, { forwardRef } from "react";
import { MaskedInput } from "./MaskedInput";
import { BankNumberInputValue, DelimiterSchema, SectionSchema } from "./types";
import { isSection } from "./utils";

export interface BankNumberBaseInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange" | "value">,
    InputBaseComponentsPropsOverrides {
  schema: (SectionSchema | DelimiterSchema)[];
  onFocusChange: (index: number) => void;
  onChange?: (value: BankNumberInputValue) => void;
  value?: Record<string, string>;
  inputRefs: React.MutableRefObject<(HTMLInputElement | null)[]>;
}

export const BankNumberBaseInput = forwardRef(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ disabled, readOnly, value, onChange, schema, onFocusChange, inputRefs }: BankNumberBaseInputProps, _) => {
    const inputsList = schema.filter(isSection);

    const handleInputRef = (index: number, ref: HTMLInputElement | null) => {
      inputRefs.current[index] = ref;
    };

    const { onKeyDown } = useAutoJumpFocus(inputRefs);

    const handleChange = (newValue: string, mask: InputMask<FactoryArg>, index: number) => {
      if (newValue === "") {
        inputRefs?.current[index - 1]?.focus();
      }
      if (newValue.length === inputsList[index].size) {
        inputRefs?.current[index + 1]?.focus();
      }
      onChange?.({ ...(value || {}), [inputsList[index].name]: newValue });
    };

    const handleComplete = (index: number) => inputRefs?.current[index + 1]?.focus();
    const findInputIndex = (item: DelimiterSchema | SectionSchema): number =>
      isSection(item) ? inputsList.indexOf(item) : -1;

    return (
      <Grid
        container
        direction="row"
        spacing={1}
        sx={{
          alignItems: "stretch",
          pr: 2,
        }}
        wrap="nowrap"
      >
        {schema.map((item, i) => (
          <Grid
            key={isSection(item) ? item.name : `${item.title}_${i}`}
            item
            xs={isSection(item) && item.flexible ? true : "auto"}
          >
            <MaskedInput
              disabled={disabled}
              handleInputRef={handleInputRef}
              inputIndex={findInputIndex(item)}
              item={item}
              name={isSection(item) ? item.name : undefined}
              onChange={handleChange}
              onComplete={handleComplete}
              onFocus={() => onFocusChange(findInputIndex(item))}
              onKeyDown={onKeyDown}
              readOnly={readOnly}
              value={value?.[isSection(item) ? item.name : ""] ?? undefined}
            />
          </Grid>
        ))}
      </Grid>
    );
  }
);
BankNumberBaseInput.displayName = "Test";
