import { Box, Chip, ChipProps, CircularProgress, Stack, styled } from "@mui/material";
import { useFormat } from "@sinch/core/hooks/format";
import { MdsCloseXs, MdsDraftXs } from "@sinch/icons";
import React, { FC } from "react";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";

const FileChipStyled = styled(Chip)<ChipProps>(({ theme }) => ({
  borderRadius: "8px !important",
  color: theme.palette.secondary.dark,
  padding: theme.spacing(1),
  fontSize: "0.75rem",
  fontWeight: theme.typography.fontWeightMedium,
  width: "100%",
  "& .MuiChip-icon": {
    color: theme.palette.secondary.dark,
  },
  "& .MuiChip-deleteIcon": { color: theme.palette.primary.main, fontSize: "1rem" },
  "& .MuiChip-deleteIcon:hover": { color: theme.palette.primary.main },
  "& .MuiChip-label": {
    width: "inherit",
    paddingLeft: theme.spacing(0.5),
  },
}));

interface FileChipProps {
  finished: boolean;
  name?: string;
  percent: number;
  size?: number;
  onDelete: () => void;
  disabled?: boolean;
}

export const FileChip: FC<FileChipProps> = ({ finished, disabled, name, percent, size, onDelete }) => {
  const { unit } = useFormat();

  return (
    <FileChipStyled
      deleteIcon={<MdsIcon fontSize="xs" icon={MdsCloseXs} />}
      disabled={disabled}
      icon={
        finished ? (
          <MdsIcon fontSize="xs" icon={MdsDraftXs} />
        ) : (
          <CircularProgress size={16} sx={{ minWidth: "16px" }} thickness={22} value={percent} variant="determinate" />
        )
      }
      label={
        <Stack direction="row" spacing={1}>
          {name && <Box sx={{ overflow: "hidden", textOverflow: "ellipsis", width: "100%" }}>{name}</Box>}
          {size && <Box sx={{ color: ({ palette }) => palette.grey[500] }}>{unit.bytes(size)}</Box>}
        </Stack>
      }
      onDelete={onDelete}
      sx={{ "& .MuiChip-deleteIcon": { margin: 0 } }}
      variant="outlined"
    />
  );
};
