import { Box, Stack, Typography } from "@mui/material";
import React, { FC } from "react";

export const TagOption: FC<{ name: string; visibilityLabel: string | undefined }> = ({ name, visibilityLabel }) => (
  <Stack
    direction="row"
    spacing={3}
    sx={{
      justifyContent: "space-between",
      width: "100%",
    }}
  >
    <Box>{name}</Box>
    {visibilityLabel && <Typography>{visibilityLabel}</Typography>}
  </Stack>
);
