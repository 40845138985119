import React, { FC, PropsWithChildren } from "react";
import { useTriggerAutofocus } from "./FocusProvider";

type Props = PropsWithChildren<{ target?: string }>;

/**
 * Trigger focus on target at click
 */
export const FocusTrigger: FC<Props> = ({ target, children }) => {
  const triggerAutofocus = useTriggerAutofocus();

  return <span onClick={() => target && triggerAutofocus(target)}>{children}</span>;
};
