import { Badge, BadgeProps, Grid, Stack, styled, Typography } from "@mui/material";
import { groupBy } from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../typography/SectionTitle/SectionTitle";
import { StaffFragment } from "./queries.types";

interface BadgesTabProps {
  staff: StaffFragment;
}

const RankBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    top: 9,
    right: 9,
    background: theme.palette.custom.cyan["600"],
    color: "#fff ",
    width: "18px",
    height: "18px",
    borderRadius: "9px !important",
    minWidth: "18px",
    fontWeight: 700,
    fontSize: "13px",
  },
}));

export const BadgesTab: FC<BadgesTabProps> = ({ staff }) => {
  const { t } = useTranslation();
  const aggregatedBadges = groupBy((item) => item.badge.category.name ?? "", staff.badgeUserAggregations);

  if (isNilOrEmpty(staff?.badgeUserAggregations)) {
    return (
      <Typography
        sx={{
          color: ({ palette }) => palette.grey["800"],
          textTransform: "capitalize",
        }}
        variant="button"
      >
        {t("ShiftTimeline.noBadges")}
      </Typography>
    );
  }

  return (
    <>
      {Object.entries(aggregatedBadges).map(([category, items]) => (
        <Stack key={category}>
          <SectionTitle title={category} variant="subtitle1" />
          <Grid
            container
            spacing={2}
            sx={{
              my: 1,
            }}
          >
            {items.map((item: Record<string, any>) => (
              <Grid
                key={item.id}
                direction="column"
                item
                sx={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "start",
                  maxWidth: 64,
                  textAlign: "center",
                }}
                xs={3}
              >
                <RankBadge badgeContent={item.rank} invisible={!item.rank || item.rank === 0}>
                  <img alt={item.badge.name} height={64} src={item.rankedImageUrl} width={64} />
                </RankBadge>
                <Typography
                  sx={{
                    color: ({ palette }) => palette.grey["900"],
                  }}
                  variant="descriptionTinySemi"
                >
                  {item.badge.name}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Stack>
      ))}
    </>
  );
};
