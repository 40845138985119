import * as Sentry from "@sentry/react";
import { User } from "@sentry/react";
import { isNotNil } from "ramda-adjunct";
import { ERROR_MONITOR_API_KEY, ERROR_MONITOR_DEBUG, ERROR_MONITOR_ENV } from "../env";

// set true if page uload, to suppress network errors and other errors occured at unload of page
let unloading = false;

/**
 * Initialize error monitor and provide methods for capture errors and exceptions
 */
export const initErrorMonitor = () => {
  if (isNotNil(ERROR_MONITOR_API_KEY)) {
    Sentry.init({
      dsn: ERROR_MONITOR_API_KEY,
      debug: ERROR_MONITOR_DEBUG || false,
      environment: ERROR_MONITOR_ENV || "development",
      // release: version || undefined, // TODO: Add version
      normalizeDepth: 4,
      ignoreErrors: [
        "Non-Error promise rejection captured", // caused by some browser plugins (LastPass, Outlook link check, etc...)
        // Random plugins/extensions
        'Could not load "stats".', // Caused by adblocks which blocking google maps
      ],
      denyUrls: [
        /maps\.googleapis\.com\//i, // block errors in google maps api (caused by adblocks or other plugins)
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
      ],
      tracesSampleRate: 0.25,
      beforeSend: (event) => {
        // Ignore error handling when page unloading
        if (unloading) {
          return null;
        }
        return event;
      },
    });
  }
};

window.addEventListener("beforeunload", () => (unloading = true));

/**
 * Set current user as sentry context
 */
export const setUser = (user: User) => {
  Sentry.setUser(user);
};

/**
 * Capture error manually
 */
export const captureError = (err: unknown) => {
  Sentry.captureException(err);
};
