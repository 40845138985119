import { styled } from "@mui/material";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import { hasNotProp } from "@sinch/utils/object";
import { Duration, Interval } from "luxon";
import { HTMLAttributes } from "react";
import { TimeBarGraphRange } from "./types";

export type TimeBarHorizontalAxisProps = HTMLAttributes<HTMLDivElement> & TimeBarGraphRange;

/**
 * Render axis of time bar, in interval of one hour
 */
export const TimeBarHorizontalAxis = styled("div", {
  shouldForwardProp: hasNotProp<TimeBarHorizontalAxisProps>(["ref", "sx", "rangeStart", "rangeEnd"]),
})<TimeBarHorizontalAxisProps>(({ theme, rangeStart, rangeEnd }) => {
  const startDate = toLuxon(rangeStart);
  const endDate = toLuxon(rangeEnd);

  const intervalRange = Interval.fromDateTimes(startDate.startOf("hour"), endDate.endOf("hour"));

  const splitUnit = getSplitUnit(intervalRange.toDuration());
  if (!splitUnit) {
    return {};
  }
  const intervals = intervalRange.splitBy({
    [splitUnit]: 1,
  });
  const intervalMillisDuration = endDate.diff(startDate).as("milliseconds");

  const gradient = intervals.reduce(
    (acc, interval) => {
      const percents =
        (Interval.fromDateTimes(startDate, interval.end!).toDuration().as("milliseconds") / intervalMillisDuration) *
        100;
      acc.push(
        `transparent ${percents}%, ${percents}%, ${theme.palette.grey[400]} calc(${percents}% + 1px), calc(${percents}% + 1px)`
      );
      return acc;
    },
    [`${theme.palette.grey[400]} 1px, 1px`]
  );
  gradient.push("transparent 100%");

  return {
    position: "relative",
    display: "flex",
    alignItems: "center",
    background: `linear-gradient(90deg, ${gradient.join(",")})`,
    height: "100%",
    zIndex: 1,
  };
});

const getSplitUnit = (duration: Duration) => {
  if (duration.as("hours") < 48) {
    return "hour";
  }
  if (duration.as("days") < 30) {
    return "day";
  }
  if (duration.as("months") < 12) {
    return "month";
  }
  if (duration.as("year") < 50) {
    return "year";
  }
  return null;
};
