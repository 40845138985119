import styled from "@emotion/styled";
import React from "react";

interface WorkerRatingProps {
  rating: number;
  includePlus?: boolean;
}

export const WorkerRating: React.FC<WorkerRatingProps> = ({ rating, includePlus }) => {
  if (rating > 0) {
    return (
      <RatingValPositive>
        {includePlus && "+"}
        {rating}
      </RatingValPositive>
    );
  }
  if (rating < 0) {
    return <RatingValNegative>{rating}</RatingValNegative>;
  }
  return <RatingVal>0</RatingVal>;
};

export const RatingVal = styled.span`
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  font-size: inherit;
`;

// TODO: Find the way to get it from theme
export const RatingValPositive = styled.span`
  color: #5cb85c;
`;

export const RatingValNegative = styled.span`
  color: #d9534f;
`;
