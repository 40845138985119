mutation JoinWorker(
  $workerId: ID!
  $positionId: ID!
  $paymentMethodId: ID
  $requireConfirmation: Boolean!
  $joinMode: JoinModeEnum!
) {
  join(
    input: {
      positionId: $positionId
      workerId: $workerId
      paymentMethodId: $paymentMethodId
      requireConfirmation: $requireConfirmation
      joinMode: $joinMode
    }
  ) {
    messages {
      message
      severity
    }
    result
    forceChoosePayoutMethod
    availableGroupedPositionIds
    payload {
      id
      worker {
        id
        name
        surname
      }
      position {
        id
        name
        shift {
          id
        }
        profession {
          id
          name
        }
      }
    }
  }
}

mutation KickWorker($attendanceIds: [ID!]!) {
  kick(attendanceIds: $attendanceIds) {
    messages {
      message
      severity
    }
    result
    payload {
      position {
        id
        shift {
          id
        }
      }
      worker {
        id
      }
    }
  }
}

mutation RequireConfirmation($attendanceId: ID!, $status: AttendanceStatusEnum!) {
  changeAttendanceStatus(input: { attendanceId: $attendanceId, attendanceStatus: $status }) {
    messages {
      message
      severity
    }
    result
    payload {
      id
      position {
        id
        shift {
          id
        }
      }
      worker {
        id
      }
    }
  }
}

mutation ResendInvitation($attendanceId: ID!) {
  resendInvitation(attendanceId: $attendanceId) {
    messages {
      message
      severity
    }
    result
    payload {
      id
    }
  }
}

mutation ConfirmInvitation($attendanceId: ID!) {
    confirmInvitation(attendanceId: $attendanceId) {
        messages {
            message
            severity
        }
        result
        payload {
            worker {
                id
            }
            position {
                id
                shift {
                    id
                }
            }
        }
    }
}

mutation ConfirmMessage($attendanceId: ID!) {
    confirmReconfirmationMessage(attendanceId: $attendanceId) {
        messages {
            message
            severity
        }
        result
        payload {
            worker {
                id
            }
            position {
                id
                shift {
                    id
                }
            }
        }
    }
}
