import type { FullPlaceFragment } from "@sinch/core/entities/fragments.types";
import { addOptionIfMissing } from "@sinch/utils/option";
import { isNil, map, pipe, reject } from "ramda";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteOptions } from "../../form/AutocompleteInput/types";
import { AddressSelectOption } from "./AddressSelectOption";
import { PlacesQuery } from "./query.types";

export const usePlaceOptions = (
  existingPlaces: FullPlaceFragment[] | undefined,
  selectedPlace: FullPlaceFragment | null,
  loaded: boolean
): AutocompleteOptions[] => {
  const { t } = useTranslation();

  const placeToOption = useCallback(
    ({ id, fullName }: PlacesQuery["places"][0]): AutocompleteOptions => ({
      value: id as number | string,
      label: fullName ?? t("Error.unknownName"),
      option: <AddressSelectOption placeName={fullName} />,
    }),
    [t]
  );

  return useMemo((): AutocompleteOptions[] => {
    const optionForSelectedPlace = selectedPlace && placeToOption(selectedPlace);

    if (!loaded) {
      return reject(isNil, [optionForSelectedPlace]);
    }

    return pipe(
      map(placeToOption),
      addOptionIfMissing(optionForSelectedPlace),
      reject<AutocompleteOptions>(isNil)
    )(existingPlaces ?? []);
  }, [existingPlaces, selectedPlace, loaded]);
};
