import { debounce } from "@mui/material";
import { DateTime } from "luxon";
import momentInstance from "moment";
import { omit } from "ramda";
import {
  DependencyList,
  memo,
  MutableRefObject,
  PropsWithChildren,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { flushSync } from "react-dom";
import { createRoot, Root } from "react-dom/client";
import { Timeline as VisTimeline, Timeline as TimelineClass } from "./lib/Timeline";
import {
  AxisTimelineOptionsTemplateFunction,
  DataGroup,
  DataItem,
  FilterAreaTemplateFunction,
  TimelineEventPropertiesResult,
  TimelineEvents,
  TimelineEventsCallbacks,
  TimelineOptions,
  TimelineOptionsTemplateFunction,
  TimelineTemplateMethods,
  TimelineTemplateRender,
} from "./types";

export const dragPlaceholderId = "__placeholder__";
/**
 * Handle rendering of drop placeholder in vis-timeline
 */
export const useDropPlaceholder = (
  timeline: RefObject<VisTimeline | null>,
  allowDropAny: boolean | undefined,
  dependencies: DependencyList
) => {
  // Handle drag over event, add "virtual" item to timeline so it looks like placeholder
  const handleDragOver = useCallback((e: TimelineEventPropertiesResult<DragEvent>) => {
    const snappedDate = momentInstance.isMoment(e.snappedTime) ? e.snappedTime.toDate() : e.snappedTime;
    const itemsData = timeline.current?.getItems();
    const dropData = timeline.current?.getDefaultDropOverData();
    itemsData?.update({
      id: dragPlaceholderId,
      type: "point",
      content: "",
      group: e.group,
      start: snappedDate,
      end: DateTime.fromJSDate(snappedDate)
        .plus({ millisecond: dropData?.duration ?? 1000 * 60 * 60 * 12 })
        .toJSDate(),
      ...dropData,
    });
    itemsData?.flush?.();
  }, dependencies);

  /**
   * if out of timeline drop area, remove virtual item from timeline
   */
  const handleDragLeaveOrDrop = useCallback(() => {
    const itemsData = timeline.current?.getItems();
    const { id: overriddenDropPlaceholderId } = timeline.current?.getDefaultDropOverData() ?? {};
    itemsData?.remove(overriddenDropPlaceholderId ?? dragPlaceholderId);
  }, dependencies);

  useEffect(() => {
    if (timeline && allowDropAny) {
      timeline.current?.on("dragover", handleDragOver);
      timeline.current?.on("dragleave", handleDragOver);
      timeline.current?.on("drop", handleDragLeaveOrDrop);
    }
    return () => {
      timeline.current?.off("dragover", handleDragOver);
      timeline.current?.off("dragleave", handleDragOver);
      timeline.current?.off("drop", handleDragLeaveOrDrop);
    };
  }, []);
};

/**
 * Create callback props for Timeline component. This callback will be registered in vis-timeline instance.
 * Allow use callbacks props as events in vis-timeline.
 */
export const useRegisterEvents = <
  TProps extends Partial<TimelineEventsCallbacks> & Record<string, any> = Partial<TimelineEventsCallbacks> &
    Record<string, any>,
>(
  events: TProps,
  timeline?: TimelineClass | null
): Omit<TProps, keyof TimelineEventsCallbacks> => {
  const registeredEvents = useRef<Map<TimelineEvents, any>>(new Map());
  const {
    onCurrentTimeTick,
    onClick,
    onContextmenu,
    onDoubleClick,
    onDragOver,
    onDragLeave,
    onDrop,
    onMouseOver,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onGroupDragged,
    onChanged,
    onRangeChange,
    onRangeChanged,
    onVerticalScroll,
    onSelect,
    onItemOver,
    onItemOut,
    onTimeChange,
    onTimeChanged,
    ...timelineOptions
  } = events;

  useEffect(() => {
    if (!timeline) {
      return;
    }
    const eventMapping: [TimelineEvents, ((properties?: any) => void) | undefined][] = [
      ["currentTimeTick", onCurrentTimeTick],
      ["click", onClick],
      ["contextmenu", onContextmenu],
      ["doubleClick", onDoubleClick],
      ["dragover", onDragOver],
      ["dragleave", onDragLeave],
      ["drop", onDrop],
      ["mouseOver", onMouseOver],
      ["mouseDown", onMouseDown],
      ["mouseUp", onMouseUp],
      ["mouseMove", onMouseMove],
      ["groupDragged", onGroupDragged],
      ["changed", onChanged],
      ["rangechange", onRangeChange],
      ["rangechanged", onRangeChanged],
      ["verticalScroll", onVerticalScroll],
      ["select", onSelect],
      ["itemover", onItemOver],
      ["itemout", onItemOut],
      ["timechange", onTimeChange],
      ["timechanged", onTimeChanged],
    ];
    eventMapping.forEach(([eventName, callback]) => {
      if (callback) {
        registeredEvents.current.set(eventName, callback);
        timeline?.on(eventName, callback);
      }
    });
    return () => {
      registeredEvents.current.forEach((callback, eventName) => {
        timeline?.off(eventName, callback);
      });
      registeredEvents.current.clear();
    };
  }, [
    timeline,
    onCurrentTimeTick,
    onClick,
    onContextmenu,
    onDoubleClick,
    onDragOver,
    onDragLeave,
    onDrop,
    onMouseOver,
    onMouseDown,
    onMouseUp,
    onMouseMove,
    onGroupDragged,
    onChanged,
    onRangeChange,
    onRangeChanged,
    onVerticalScroll,
    onSelect,
    onItemOver,
    onItemOut,
    onTimeChange,
    onTimeChanged,
  ]);
  return timelineOptions;
};

/**
 * Render template wrapper allowing to react to templete rendering event so it can be used to redraw timeline after render
 * of react component. Need to be used because render components asynchronously and vis-timeline does not know about it.
 */
export const AfterMountUpdater = memo<PropsWithChildren<{ onRendered: () => void }>>(({ children, onRendered }) => {
  useEffect(() => {
    onRendered();
  }, [onRendered]);
  return children;
});
AfterMountUpdater.displayName = "AfterMountUpdater";

// Check if element has dataset property
const hasDataset = (element: Element | DocumentFragment): element is HTMLElement =>
  typeof (element as HTMLElement).dataset !== "undefined";

// Check if rendered item is already rendered in container
const isAlreadyRenderedToContainer = (
  renderedItems: MutableRefObject<Set<string | number>>,
  renderItemId: string | number | undefined,
  container?: Element | DocumentFragment
) =>
  renderItemId &&
  renderedItems.current.has(renderItemId) &&
  // Check if item is already rendered in this container
  (!container || (hasDataset(container) && container.dataset.renderedItemId === renderItemId.toString()));

/**
 * Handle rendering of react components in vis-timeline
 */
export const useTemplates = <TDataItem extends DataItem = DataItem, TDataGroup extends DataGroup = DataGroup>(
  {
    renderItem,
    renderMinorAxis,
    renderMajorAxis,
    renderGroup,
    renderFilterArea,
    renderDropTarget,
  }: TimelineTemplateRender<TDataItem, TDataGroup>,
  timeline: RefObject<VisTimeline | null>
): TimelineTemplateMethods => {
  const entrypointsStore = useRef<Map<any, Root>>(new Map());
  // Holds promises for rendering of items which are not rendered yet
  const renderItemsQueue = useRef<Map<string | number, () => Promise<void>>>(new Map());
  const renderGroupsQueue = useRef<Map<string | number, () => Promise<void>>>(new Map());
  const renderedItems = useRef<Set<string | number>>(new Set());

  const redrawTimeline = useCallback(() => {
    // @ts-expect-error item set is not defined in types, but it is needed for correct redraw
    const itemSet = timeline.current?.itemSet;
    itemSet?.markDirty({ restackGroups: true });
    timeline.current?.redraw();
  }, [timeline]);

  const debouncedRedraw = useCallback(debounce(redrawTimeline, 100), [redrawTimeline]);

  // Clearing render cache
  const clearRenderCache = () => {
    renderItemsQueue.current.clear();
    renderGroupsQueue.current.clear();
    renderedItems.current.clear();
  };

  // If rendered items or groups is changed, clear caches
  useEffect(() => {
    clearRenderCache();
  }, [renderItem, renderGroup, renderFilterArea]);

  const renderTemplateRoot = (
    container: Element | DocumentFragment,
    node: ReactNode,
    renderItemId?: string | number
  ) => {
    const entrypoint = entrypointsStore.current.get(container) ?? createRoot(container);
    entrypointsStore.current.set(container, entrypoint);

    if (isAlreadyRenderedToContainer(renderedItems, renderItemId, container)) {
      return false;
    }

    if (renderItemId) {
      (container as HTMLElement).dataset.renderedItemId = renderItemId.toString();
      renderedItems.current.add(renderItemId);
    }
    flushSync(() => {
      entrypoint.render(node);
    });
    return false;
  };

  // remember entrypoint for react render, it's important to have only one entrypoint for each container and do not init it again
  const rememberTemplateRoot = (
    container: Element | DocumentFragment,
    node: ReactNode,
    renderItemId?: string | number
  ) => {
    const entrypoint = entrypointsStore.current.get(container) ?? createRoot(container);
    entrypointsStore.current.set(container, entrypoint);

    // Skip if already rendered
    if (isAlreadyRenderedToContainer(renderedItems, renderItemId)) {
      return;
    }

    // @ts-ignore for some reason, it need element with children which is wrong
    flushSync(() => {
      entrypoint.render(node);
    });
    if (renderItemId) {
      if (hasDataset(container)) {
        container.dataset.renderedItemId = renderItemId.toString();
      }
      renderedItems.current.add(renderItemId);
    }
  };

  // Create template functions for vis-timeline, useCallback is used so it will not be recreated on each render

  const renderTemplateFn = useCallback<TimelineOptionsTemplateFunction>(
    (item, element, _, opts) => {
      renderTemplateRoot(
        element,
        item.id === dragPlaceholderId && renderDropTarget
          ? renderDropTarget(item)
          : renderItem?.(item, debouncedRedraw, opts),
        `I:${item.id}`
      );
      return false;
    },
    [renderDropTarget, renderItem]
  );

  const renderMinorAxisTemplateFn = useCallback<AxisTimelineOptionsTemplateFunction>(
    (label, element, time, step) => {
      rememberTemplateRoot(element, renderMinorAxis?.(label, time, element, step));
      return undefined;
    },
    [renderMinorAxis]
  );

  const renderMajorAxisTemplate = useCallback<AxisTimelineOptionsTemplateFunction>(
    (label, element, time, step) => {
      rememberTemplateRoot(element, renderMajorAxis?.(label, time, element, step));
      return undefined;
    },
    [renderMajorAxis]
  );

  const renderGroupTemplate = useCallback<TimelineOptionsTemplateFunction>(
    (group, element) =>
      //rememberTemplateRootForGroups(element, renderGroup?.(group), group.id, `G:${group.id}`);
      renderTemplateRoot(element, renderGroup?.(group), `G:${group.id}`), // renderToStaticMarkup(renderGroup?.(group));
    [renderGroup]
  );

  const renderFilterAreaTemplate = useCallback<FilterAreaTemplateFunction>(
    (element) => {
      rememberTemplateRoot(element, renderFilterArea?.(), `FilterArea`);
      return undefined;
    },
    [renderFilterArea]
  );

  return {
    clearRenderCache,
    beforeHtmlElementRemove: (element) => {
      if (entrypointsStore.current.has(element)) {
        entrypointsStore.current.get(element)?.unmount();
      }
      return true;
    },
    template: renderItem ? renderTemplateFn : undefined,
    minorAxisTemplate: renderMinorAxis ? renderMinorAxisTemplateFn : undefined,
    majorAxisTemplate: renderMajorAxis ? renderMajorAxisTemplate : undefined,
    groupTemplate: renderGroup ? renderGroupTemplate : undefined,
    filterAreaTemplate: renderFilterArea ? renderFilterAreaTemplate : undefined,
  };
};

export const useUpdateTimelineOptions = (timelineOptions: TimelineOptions, timeline: RefObject<VisTimeline | null>) => {
  const [isInitialized, setInitialized] = useState(false);
  const {
    align,
    autoResize,
    clickToUse,
    cluster,
    dataAttributes,
    editable,
    format,
    groupEditable,
    groupHeightMode,
    groupOrder,
    hideGroupsOutOfViewport,
    groupOrderSwap,
    height,
    hiddenDates,
    horizontalScroll,
    itemsAlwaysDraggable,
    locale,
    locales,
    longSelectPressTime,
    moment,
    margin,
    max,
    maxHeight,
    maxMinorChars,
    min,
    minHeight,
    moveable,
    multiselect,
    multiselectPerGroup,
    onAdd,
    onAddGroup,
    onDropObjectOnItem,
    onInitialDrawComplete,
    onUpdate,
    onMove,
    onMoveGroup,
    onMoving,
    onRemove,
    onRemoveGroup,
    order,
    orientation,
    preferZoom,
    rollingMode,
    rtl,
    selectable,
    sequentialSelection,
    showCurrentTime,
    showMajorLabels,
    showMinorLabels,
    showFilterArea,
    showWeekScale,
    showTooltips,
    stack,
    stackSubgroups,
    snap,
    visibleFrameTemplate,
    timeAxis,
    timeAxisScale,
    type,
    tooltip,
    tooltipOnItemUpdateTime,
    verticalScroll,
    width,
    leftColumnWidth,
    zoomable,
    zoomKey,
    zoomFriction,
    zoomMax,
    zoomMin,
    xss,
    template,
    minorAxisTemplate,
    majorAxisTemplate,
    groupTemplate,
    filterAreaTemplate,
  } = timelineOptions;

  useEffect(() => {
    if (timeline.current && isInitialized) {
      if (import.meta.env.MODE === "development") {
        console.debug(
          "%c[Timeline] Options updated, redrawing timeline. Warning: Unexpected change of options can cause redraw and performance issues.",
          "background-color: #B27E3333; color: #B27E33FF"
        );
      }
      timeline.current.setOptions(omit(["start", "end", "configure"], timelineOptions));
    }
  }, [
    align,
    autoResize,
    clickToUse,
    cluster,
    dataAttributes,
    editable,
    format,
    groupEditable,
    groupHeightMode,
    hideGroupsOutOfViewport,
    groupOrder,
    groupOrderSwap,
    height,
    hiddenDates,
    horizontalScroll,
    itemsAlwaysDraggable,
    locale,
    locales,
    longSelectPressTime,
    moment,
    margin,
    max,
    maxHeight,
    maxMinorChars,
    min,
    minHeight,
    moveable,
    multiselect,
    multiselectPerGroup,
    onAdd,
    onAddGroup,
    onDropObjectOnItem,
    onInitialDrawComplete,
    onUpdate,
    onMove,
    onMoveGroup,
    onMoving,
    onRemove,
    onRemoveGroup,
    order,
    orientation,
    preferZoom,
    rollingMode,
    rtl,
    selectable,
    sequentialSelection,
    showCurrentTime,
    showMajorLabels,
    showMinorLabels,
    showFilterArea,
    showWeekScale,
    showTooltips,
    stack,
    stackSubgroups,
    snap,
    visibleFrameTemplate,
    timeAxis,
    timeAxisScale,
    type,
    tooltip,
    tooltipOnItemUpdateTime,
    verticalScroll,
    width,
    leftColumnWidth,
    zoomable,
    zoomKey,
    zoomFriction,
    zoomMax,
    zoomMin,
    xss,
    template,
    minorAxisTemplate,
    majorAxisTemplate,
    groupTemplate,
    filterAreaTemplate,
    isInitialized,
  ]);

  // Redraw only when group or item template changes
  useEffect(() => {
    // uses flushSync to ensure that timeline is redrawn after all items are rendered, so it need to be called in separate async context
    setTimeout(() => {
      // @ts-expect-error item set is not defined in types, but it is needed for correct redraw
      timeline.current?.itemSet.markDirty({ restackGroups: true });
      timeline.current?.redraw();
    }, 0);
  }, [groupTemplate, template]);

  return { setIsInitialized: (value: boolean) => setInitialized(value) };
};
