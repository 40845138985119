import { debounce } from "@mui/material";

import { SEARCH_FIELD_LIMIT } from "@sinch/core/config/globals";
import { useLazyQueryHandler } from "@sinch/hooks/useLazyQueryHandler";
import { mapToOptions, Option } from "@sinch/utils/option";
import React, { FC, Ref, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../../form/AutocompleteInput/AutocompleteInput";
import { AttendanceChip } from "./AttendanceChip";
import { clearPrefixForSearch, PrefixedEntities, SinchEntity, useSinchEntity } from "./entitiesProperties";
import { JobsData } from "./queries.graphql";
import { JobsDataQuery, JobsDataQueryVariables } from "./queries.types";
import { AutocompleteInputProps, AutocompleteOptions } from "@sinch/components/form/AutocompleteInput/types";

interface JobsSelectInputProps
  extends Pick<AutocompleteInputProps<AutocompleteOptions, true>, "slotProps" | "textFieldProps"> {
  /**
   * Input name
   */
  name: string;
  label: string;
  required?: boolean;
  value: (string | number)[] | undefined;
  onChange?: (val: (string | number)[] | null) => void;
  disabled?: boolean;
  error?: boolean;
  errorMessage: string[];
  inputRef: Ref<any>;
  onBlur: () => void;
  /**
   * Limit searched list of options
   */
  limit?: number;
}

export const JobsSelectInput: FC<JobsSelectInputProps> = ({ limit = SEARCH_FIELD_LIMIT, ...field }) => {
  const { t } = useTranslation();
  const [inputVal, setInputVal] = useState<string>("");
  const { getEntityIdLabel } = useSinchEntity();

  const { data, dispatch, loading } = useLazyQueryHandler<JobsDataQuery, JobsDataQueryVariables>(JobsData);
  const options: Option[] = useMemo(
    () => mapToOptions("id", ({ name, id }) => `${getEntityIdLabel(SinchEntity.Job, id)} ${name}`)(data?.jobs || []),
    [data?.jobs]
  );

  const debounceDispatch = useCallback(debounce(dispatch, 200), [dispatch]);

  const handleSearch = (search: string) => {
    debounceDispatch({ limit, filter: { search: { LIKE: clearPrefixForSearch(search, PrefixedEntities.Job) } } });
  };

  return (
    <AutocompleteInput<AutocompleteOptions, true>
      size="small"
      {...field}
      inputValue={inputVal ?? ""}
      loading={loading}
      multiple
      onFocus={() => handleSearch(inputVal)}
      onInputChange={(event, newInputValue) => {
        // prevent backfire on select
        if (!event) return;

        setInputVal(newInputValue);
        handleSearch(newInputValue);
      }}
      openOnFocus
      options={options}
      placeholder={t("search")}
      renderTags={(vals, getTagProps) =>
        vals.map((val, index) => (
          <AttendanceChip key={val.value} getTagProps={getTagProps} index={index} val={val} variant={SinchEntity.Job} />
        ))
      }
    />
  );
};
