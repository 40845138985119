query Requirements {
    requirements {
        id
        label
        subjects {
            ...subjectFields
        }
    }
}

query RequirementFulfill($filter: RequirementsFilter!) {
    requirementFulfill(filter: $filter)
}

fragment operatorFields on RequirementOperator {
    hasValue
    label
    value
    isMultiple
}

fragment subjectFields on RequirementSubject {
    group
    label
    operators {
        ...operatorFields
    }
    possibleValues {
        value
        label
    }
    subject
    valueType
}
