import { OnProgress } from "@rpldy/sender";
import { BatchItem, FILE_STATES, SendOptions, UploadData } from "@rpldy/shared";
import { F } from "ramda";
import React, { FC, useCallback, useId, useMemo } from "react";
import { FormControlWrapper } from "../FormControlWrapper/FormControlWrapper";
import { PreviewItemStateWrapper } from "./PreviewItemStateWrapper";
import { FileItem, UploadInputProps } from "./types";
import { UploadButtonWrapper } from "./UploadButtonWrapper";
import { useUploadStore } from "./UploadyProvider";

const fakeUrl = "test";

/**
 * Generic upload input component. This component provide "framework" for creating file upload components.
 * See documentation of slot props.
 * Use UploadyProvider to manage upload state and asynchronous upload.
 */
export const UploadInput: FC<UploadInputProps> = ({
  error,
  errorMessage,
  FormHelperTextProps: formHelperTextProps,
  helperText,
  infoTooltip,
  required,
  FormControlProps: formControlProps,
  UploadyProps: uploadyProps,
  onChange,
  onError,
  disabled,
  value = [],
  endpoint,
  name,
  multiple = false,
  capture,
  accept,
  slots: { container: PreviewUploadContainer, previewItem: PreviewItem, upload: UploadButton },
  label,
}) => {
  const defaultName = useId();
  /**
   * Handle and memoizing change of uploaded files
   */
  const handleUploadedChange = useCallback(
    (uploadFiles: FileItem[]) => {
      if (!multiple) {
        onChange?.(uploadFiles.slice(-1));
      } else {
        onChange?.(uploadFiles);
      }
    },
    [onChange]
  );

  const statedValues = useMemo(() => value.map((file) => ({ state: FILE_STATES.FINISHED, ...file })), [value]);

  /**
   * Use UploadyProvider to manage upload state and asynchronous upload.
   */
  useUploadStore({
    name: name || defaultName,
    value: statedValues,
    onUploaded: handleUploadedChange,
    onError,
  });

  const handleRemove = useCallback(
    (id: string) => {
      onChange?.(statedValues.filter((file) => file.id !== id));
    },
    [statedValues, onChange]
  );

  //simulate successfull upload
  const localSender = useCallback(
    (item: BatchItem[], url: string | undefined, options: SendOptions, onProgress?: OnProgress) => {
      onProgress?.(
        {
          total: 1,
          loaded: 1,
        },
        []
      );
      return {
        request: new Promise<UploadData>((resolve) =>
          resolve({ status: 200, state: FILE_STATES.FINISHED, response: {} })
        ),
        abort: F,
        senderType: "string",
      };
    },
    []
  );

  return (
    <FormControlWrapper
      error={error}
      errorMessage={errorMessage}
      FormHelperTextProps={formHelperTextProps}
      helperText={helperText}
      infoTooltip={infoTooltip}
      label={label}
      required={required}
      {...formControlProps}
    >
      <PreviewUploadContainer
        disabled={disabled}
        endpoint={endpoint ?? fakeUrl}
        error={error}
        multiple={multiple}
        name={name || defaultName}
        onRemove={handleRemove}
        previewItemComponent={(props) => (
          <PreviewItemStateWrapper onDelete={handleRemove} previewItemComponent={PreviewItem} {...props} />
        )}
        uploadComponent={(props) => (
          <UploadButtonWrapper
            accept={accept}
            autoUpload
            capture={capture}
            destination={{ url: endpoint ?? fakeUrl }}
            disabled={disabled}
            multiple={multiple}
            params={{ name }}
            send={endpoint ? undefined : localSender}
            uploadComponent={UploadButton}
            {...uploadyProps}
            uploadComponentProps={props}
          />
        )}
        UploadyProps={endpoint ? uploadyProps : { send: localSender, ...uploadyProps }}
        value={statedValues}
      />
    </FormControlWrapper>
  );
};
