import { Box, ButtonBase, ButtonBaseProps, FormHelperText, Stack, StackProps, styled, Typography } from "@mui/material";
import { unstable_generateUtilityClasses as generateUtilityClasses } from "@mui/utils";
import { DatePickerProps } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { useFormat } from "@sinch/core/hooks/format";

import { useVerticalCenterWithElement } from "@sinch/hooks/useVerticalCenterWithElement";
import { MdsCalendarToday, MdsEdit } from "@sinch/icons";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import { DateTime } from "luxon";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { ReactNode, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../MdsIcon/MdsIcon";

interface InputWithExpirationDateProps
  // @ts-ignore Date is correct type, TODO: change to DateTime
  extends Pick<DatePickerProps<Date>, "value" | "onChange">,
    Omit<DatePickerProps<DateTime>, "value" | "onChange"> {
  /**
   * Position of the expiration input, can be on right or under the children
   */
  placement?: "right" | "under";
  children: ReactNode;
  error?: boolean;
  errorMessage?: string[];
}

const classes = generateUtilityClasses("SinchInputWithExpirationDate", [
  "connectorBox",
  "calendarIconBox",
  "expDateText",
  "rightBox",
  "connectorHorizontal",
  "connectorCorner",
  "selectedDate",
  "selectedDateText",
  "selectDateText",
  "editIcon",
  "picker",
]);

/**
 * Wrap input component with expiration date picker
 */
export const InputWithExpirationDate: React.FC<InputWithExpirationDateProps> = ({
  children,
  placement = "right",
  error,
  errorMessage,
  ...fieldProps
}) => {
  const { date } = useFormat();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  const { marginTop, parentRef, anchorChildren } = useVerticalCenterWithElement<HTMLDivElement>({
    anchorElement: children,
    alignedElementRef: placement === "right" ? ref : undefined,
    refPropName: "inputRef",
  });

  const handleTogglePicker = () => setOpen((state) => !state);

  return (
    <Stack
      ref={parentRef}
      direction={placement === "right" ? "row" : "column"}
      sx={{
        flexWrap: "nowrap",
      }}
    >
      {anchorChildren}
      <Stack direction="column">
        <ExpirationDateInputWrapper
          ref={ref}
          expirationInputPlacement={placement}
          sx={placement === "right" ? { marginTop: `${marginTop}px` } : {}}
        >
          <Box className={classes.connectorBox}>
            <div className={placement === "right" ? classes.connectorHorizontal : classes.connectorCorner} />
          </Box>
          <Box className={classes.calendarIconBox}>
            <MdsIcon icon={MdsCalendarToday} sx={(theme) => ({ color: theme.palette.grey["500"] })} />
          </Box>

          <DatePicker<DateTime>
            {...fieldProps}
            className={classes.picker}
            onAccept={handleTogglePicker}
            onChange={(val, context) => fieldProps.onChange?.(val?.toJSDate() ?? null, context)}
            onClose={handleTogglePicker}
            open={open}
            slotProps={{
              popper: { anchorEl: ref.current, "data-cy": "inputWithExpirationDatePopper" },
              day: { "data-cy": "inputWithExpirationDateDay" },
              switchViewButton: { "data-cy": "inputWithExpirationDateSwitchView" },
              nextIconButton: { "data-cy": "inputWithExpirationDateNext" },
              previousIconButton: { "data-cy": "inputWithExpirationDatePrev" },
            }}
            slots={{
              toolbar: undefined,
              field: () => (
                <DateSelectButton data-cy="inputWithExpirationDateButton" error={error} onClick={handleTogglePicker}>
                  <Typography className={classes.expDateText} variant="body2">
                    {t("ExpirationInput.expirationDate")}
                  </Typography>

                  {fieldProps.value ? (
                    <Box className={classes.selectedDate}>
                      <span className={classes.selectedDateText} data-cy="inputWithExpirationDateSelectedDate">
                        {date.mediumMonthYear(fieldProps.value)}
                      </span>
                      <MdsIcon className={classes.editIcon} icon={MdsEdit} />
                    </Box>
                  ) : (
                    <span className={classes.selectDateText}>{t("ExpirationInput.selectDate")}</span>
                  )}
                </DateSelectButton>
              ),
            }}
            value={toLuxon(fieldProps.value) ?? null}
          />
        </ExpirationDateInputWrapper>
        {isNotNilOrEmpty(errorMessage) &&
          errorMessage?.map((message, index) => (
            <FormHelperText
              key={index}
              error
              sx={{ paddingLeft: placement !== "right" ? ({ spacing }) => spacing(3) : ({ spacing }) => spacing(5) }}
            >
              {message}
            </FormHelperText>
          ))}
      </Stack>
    </Stack>
  );
};

const DateSelectButton = styled(ButtonBase, { shouldForwardProp: (prop) => prop !== "error" })<
  ButtonBaseProps & { error?: boolean }
>(({ theme, error }) => ({
  fontWeight: theme.typography.fontWeightBold,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "4px 8px",
  border: "1px solid transparent",
  borderRadius: "6px !important",
  borderColor: error ? theme.palette.error.main : undefined,

  "&.Mui-focusVisible": {
    boxShadow: `0px 0px 0px 4px ${theme.palette.custom.blue[100]}`,
  },

  "&:hover": {
    borderColor: theme.palette.primary.main,
  },

  [`& .${classes.expDateText}`]: {
    fontSize: theme.typography.body2.fontSize,
    lineHeight: 1.3,
    color: theme.palette.grey["800"],
  },

  [`& > .${classes.selectDateText}`]: {
    color: theme.palette.primary.main,
    lineHeight: 1.6,
  },

  [`& > .${classes.selectedDate}`]: {
    display: "flex",
    gap: "5px",

    [`& > .${classes.selectedDateText}`]: {
      color: theme.palette.text.primary,
      lineHeight: 1.6,
    },

    [`& > .${classes.editIcon}`]: {
      fontSize: "15px",
      color: theme.palette.primary.main,
    },
  },
}));

const ExpirationDateInputWrapper = styled(Stack, { shouldForwardProp: (prop) => prop !== "expirationInputPlacement" })<
  StackProps & { expirationInputPlacement?: "right" | "under" }
>(({ theme, expirationInputPlacement }) => ({
  alignItems: "center",
  flexDirection: "row",
  height: "40px",
  marginTop: expirationInputPlacement === "right" ? undefined : "6px",
  position: "relative",
  width: "220px",

  [`& > .${classes.connectorBox}, & > .${classes.calendarIconBox}`]: {
    height: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },

  [`& > .${classes.connectorBox}`]: {
    display: "flex",
    justifyContent: "center",
  },

  [`& .${classes.connectorCorner}`]: {
    width: theme.spacing(1),
    height: "13px",
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderTop: "none",
    borderRight: "none",
  },

  [`& .${classes.connectorHorizontal}`]: {
    width: theme.spacing(3),
    height: "50%",
    border: `1px solid ${theme.palette.grey["500"]}`,
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
  },

  [`& .${classes.picker}`]: {
    width: "320px",
    position: "absolute",
  },
}));
