import { Box, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import { PositionRoleEnum } from "@sinch/core/entities/serverEnums";
import type { Attendance, Position, Shift } from "@sinch/types/sinch.types";

import { times } from "ramda";
import React, { FC, ReactNode } from "react";
import { AttendanceCounter } from "../../AttendanceCounter/AttendanceCounter";
import { DateRange } from "../../DateRange/DateRange";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { PositionPill } from "../../Pill/Pill";
import { usePositionRole } from "../PositionRole/PositionRole";

/**
 * Props for the SimpleShiftTable component.
 */
export interface SimpleShiftTableProps {
  /**
   * Array of positions with their details.
   */
  positions: (Pick<Position, "id" | "beginning" | "end" | "role" | "name" | "size"> & {
    shift: Pick<Shift, "name" | "id">;
    attendances: Pick<Attendance, "id">[];
  })[];

  /**
   * Label for the table.
   */
  label: ReactNode;

  /**
   * Optional icon to display next to the label.
   */
  labelIcon?: ReactNode;
  /**
   * Loading state, if true - show skeleton, if number - show skeleton with number of rows
   */
  loading?: number | boolean;
}

/**
 * Component for displaying a table of shifts with positions and their details.
 */
export const SimplePositionsTable: FC<SimpleShiftTableProps> = ({ positions, label, labelIcon, loading }) => {
  const { getRoleIcon, getRoleColor } = usePositionRole();
  return (
    <TableContainer>
      <Box alignItems="center" display="flex" gap={1} mb={1}>
        {labelIcon}
        <Typography variant="descriptionSemi">{label}</Typography>
      </Box>
      {loading ? (
        <Stack spacing={0.5}>
          {times(
            () => (
              <Skeleton height={25} variant="rectangular" />
            ),
            loading === true ? 2 : loading
          )}
        </Stack>
      ) : (
        <Table padding="none">
          <TableBody sx={({ palette }) => ({ borderTop: `1px solid ${palette.grey[400]}` })}>
            {positions.map(({ beginning, end, size, role, name, id, shift, attendances }) => (
              <TableRow key={id}>
                <TableCell size="small">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MdsIcon
                      icon={getRoleIcon(role as PositionRoleEnum)}
                      sx={({ spacing }) => ({ color: getRoleColor(role as PositionRoleEnum), marginRight: spacing(1) })}
                    />
                    <Typography
                      component="span"
                      sx={(theme) => ({
                        fontSize: theme.typography.pxToRem(15),
                      })}
                    >
                      {[shift.name, name].filter(Boolean).join(" - ")}
                    </Typography>
                    <Box mx={1}>
                      <PositionPill id={id} size="small" />
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography component="span" whiteSpace="nowrap">
                    <DateRange end={end} start={beginning} />
                  </Typography>
                </TableCell>
                <TableCell sx={{ "&>div": { display: "inline-block" }, textAlign: "right" }}>
                  <AttendanceCounter attendance={attendances.length} attendanceSlots={size} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};
