import { FetchResult } from "@apollo/client";
import { Box, CircularProgress, List, ListItem, ListItemButton, Stack, Tooltip, Typography } from "@mui/material";
import { TransportAttendanceRole, TransportDirection } from "@sinch/core/entities/serverEnums";

import { useMutationHandler } from "@sinch/hooks/useMutationHandler";
import { useSnackbar } from "notistack";
import { pluck } from "ramda";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { QuickEditButton } from "../../QuickEditButton/QuickEditButton";
import { TransportRoleIcon } from "../Icons/TransportRoleIcon";
import { useTransportRole } from "../Transport/hooks";
import { TransportRoleChange } from "./queries.graphql";
import {
  AttendanceShiftPanelDetailFragment,
  TransportRoleChangeMutation,
  TransportRoleChangeMutationVariables,
} from "./queries.types";

interface TransportCellProps {
  transportAttendances?: AttendanceShiftPanelDetailFragment["transportAttendances"];
}

export const TransportsCell: React.FC<TransportCellProps> = ({ transportAttendances }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { getTransportRoleLabel } = useTransportRole();

  const [open, setOpen] = useState(false);

  const [changeTransportRole, { loading }] = useMutationHandler<
    TransportRoleChangeMutation,
    TransportRoleChangeMutationVariables
  >(TransportRoleChange, {
    update(cache, { data }: Omit<FetchResult<TransportRoleChangeMutation>, "context">) {
      if (!data || !data.switchTransportAttendanceRole.result) {
        return;
      }
      data?.switchTransportAttendanceRole.payload?.forEach((transportAttendance) => {
        cache.modify({
          id: `TransportAttendance:${transportAttendance.id}`,
          fields: {
            role() {
              return transportAttendance.role;
            },
          },
        });
      });
    },
    notifyMessages: true,
  });

  if (!transportAttendances || transportAttendances.length === 0) {
    return null;
  }

  const transportDirectionTranslation = {
    [TransportDirection.There]: t("Transport.there"),
    [TransportDirection.Back]: t("Transport.back"),
  };

  const tooltipTitle = (
    <>
      {transportAttendances.map((transport) => (
        <Typography
          key={transport.id}
          sx={{
            color: (theme) => theme.palette.common.white,
            display: "block",
          }}
          variant="descriptionTiny"
        >
          {transportDirectionTranslation[transport.trip.direction]}:{" "}
          {getTransportRoleLabel(transport.role as TransportAttendanceRole)}
        </Typography>
      ))}
    </>
  );

  const onTransportRoleInputChange = async (role: TransportAttendanceRole) => {
    setOpen(false);
    const result = await changeTransportRole({
      variables: {
        transportTripIds: pluck("id", transportAttendances),
        role: role,
      },
    });
    if (result.data?.switchTransportAttendanceRole?.result) {
      enqueueSnackbar(t("Transport.changeRoleSuccess"), { variant: "success" });
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
        }}
      >
        <CircularProgress color="secondary" size={20} />
      </Box>
    );
  }

  return (
    <QuickEditButton
      fullWidth={false}
      onClick={() => setOpen(!open)}
      onClose={() => setOpen(false)}
      open={open}
      title={<ChangeTransportRole onChange={onTransportRoleInputChange} />}
      variant="menu"
    >
      <Tooltip placement="top" title={tooltipTitle}>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "center",
          }}
        >
          {transportAttendances.length === 1 || transportAttendances[0].role === transportAttendances[1].role ? (
            <TransportRoleIcon role={transportAttendances[0].role as TransportAttendanceRole} />
          ) : (
            <>
              <TransportRoleIcon role={transportAttendances[0].role as TransportAttendanceRole} />
              <TransportRoleIcon role={transportAttendances[1].role as TransportAttendanceRole} />
            </>
          )}
        </Box>
      </Tooltip>
    </QuickEditButton>
  );
};

interface ChangeTransportRoleProps {
  onChange: (role: TransportAttendanceRole) => void;
}
const ChangeTransportRole: FC<ChangeTransportRoleProps> = ({ onChange }) => {
  const { getTransportRoleColor, getTransportRoleLabel, getTransportRoleIcon, getSortedTransportRole } =
    useTransportRole();

  return (
    <List disablePadding>
      {getSortedTransportRole().map((val) => (
        <ListItem key={val} disablePadding>
          <ListItemButton
            disableGutters
            onClick={() => onChange(val)}
            sx={{ px: 1, color: getTransportRoleColor(val) }}
          >
            <Stack direction="row" spacing={1}>
              <MdsIcon fontSize="small" icon={getTransportRoleIcon(val)} sx={{ color: getTransportRoleColor(val) }} />
              <Typography component="span">{getTransportRoleLabel(val)}</Typography>
            </Stack>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
