import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { Divider, Stack } from "@mui/material";
import React, { FC, useMemo } from "react";
import { ChangeHandler } from "../components/ChangeHandler";
import { Toolbar } from "../components/Toolbar";
import { MaxLengthPlugin } from "../plugins/MaxLengthPlugin";
import { ContentEditableStyled, editorTheme } from "../TextEditorTheme";
import { TextEditorVariantProps } from "./types";
import { PlaceholderNode } from "@sinch/components/form/TextEditor/nodes/PlaceholderNode";
import { PlaceholdersPlugin } from "@sinch/components/form/TextEditor/plugins/PlaceholdersPlugin";

export const PlainTextEditor: FC<TextEditorVariantProps & { placeholders?: { label: string; value: string }[] }> = ({
  onFocus,
  onBlur,
  innerRef,
  disabled,
  maxRows,
  minRows,
  maxLength,
  toolbarPosition,
  secondaryToolbar,
}) => {
  const editorConfig: InitialConfigType = useMemo(
    () => ({
      namespace: "TextEditor",
      editable: !disabled,
      // Handling of errors during update
      onError(error) {
        throw error;
      },
      theme: editorTheme,
      // Any custom nodes go here
      nodes: [PlaceholderNode],
    }),
    [disabled]
  );

  return (
    <LexicalComposer initialConfig={editorConfig}>
      <Stack
        direction={toolbarPosition === "bottom" ? "column-reverse" : "column"}
        sx={{
          width: "100%",
        }}
      >
        <PlainTextPlugin
          contentEditable={
            <ContentEditableStyled maxRows={maxRows} minRows={minRows} onBlur={onBlur} onFocus={onFocus} />
          }
          ErrorBoundary={LexicalErrorBoundary}
          placeholder={<></>}
        />
        {secondaryToolbar && (
          <>
            <Divider />
            <Toolbar>{secondaryToolbar}</Toolbar>
          </>
        )}
      </Stack>
      <ChangeHandler innerRef={innerRef} />
      <HistoryPlugin />
      {maxLength ? <MaxLengthPlugin maxLength={maxLength} /> : <></>}
      <PlaceholdersPlugin />
    </LexicalComposer>
  );
};
