import { PositionStatusEnum } from "@sinch/core/entities/serverEnums";
import type { Position, PositionRequirement } from "@sinch/types/sinch.types";

import { t } from "i18next";
import { DateTime } from "luxon";

export const getStatusLabel = (
  status: PositionStatusEnum,
  crewbossName: Record<`crewbossNameSingular${string}` | `crewbossNamePlural${string}`, string>
): string => {
  const labels: Record<PositionStatusEnum, string> = {
    [PositionStatusEnum.CbCompleted]: t("Shift.closedByStaffLeader", crewbossName),
    [PositionStatusEnum.Completed]: t("Shift.closedByAdmin"),
    [PositionStatusEnum.Opened]: t("Shift.open"),
    [PositionStatusEnum.OpenedHidden]: t("Shift.openHidden"),
    [PositionStatusEnum.Over]: t("Shift.finished"),
    [PositionStatusEnum.Pending]: t("Shift.pending"),
    [PositionStatusEnum.Running]: t("Shift.running"),
  };
  return labels[status];
};

export const findEarliestBeginning = (array: Pick<Position, "beginning">[]): DateTime | undefined => {
  const dates = array.map((obj) => DateTime.fromJSDate(obj.beginning));
  return DateTime.min(...dates);
};

export const findLatestEnd = (array: Pick<Position, "end">[]): DateTime | undefined => {
  const dates = array.map((obj) => DateTime.fromJSDate(obj.end));
  return DateTime.max(...dates);
};

export const getCommonTags = <T extends { id: string; name: string }>(
  positions: { tags?: T[] | null }[]
): T[] | null => {
  if (!positions[0]?.tags) {
    return null;
  }

  const tagIds = positions[0].tags?.map((tag) => tag.id);

  const commonTagIds = tagIds.filter((tagId) =>
    positions.slice(1).every((position) => position.tags?.some((tag) => tag.id === tagId))
  );

  return commonTagIds.length ? positions[0].tags.filter((tag) => commonTagIds.includes(tag.id)) : null;
};

/**
 * Creates array of objects with requirement and array of position ids that have this requirement
 */
export const mapRequirements = (
  positions: Pick<Position, "requirements" | "id">[]
): { requirement: PositionRequirement; positions: string[] }[] => {
  // Flatten requirements
  const requirements = positions.flatMap(
    (position) => position.requirements?.map((requirement) => ({ requirement, positionId: position.id })) ?? []
  );

  // Remove duplicate requirements
  const uniqueRequirements = Array.from(new Set(requirements.map((requirement) => requirement.requirement.text)));

  // Map requirements to array of objects with requirement and array of position ids that have this requirement
  return uniqueRequirements.map((requirement) => ({
    requirement: requirements.find((req) => req.requirement.text === requirement)!.requirement,
    positions: requirements.filter((req) => req.requirement.text === requirement).map((req) => req.positionId),
  }));
};
