import { useTheme } from "@mui/material";
import { AttendancePresenceEnum } from "@sinch/core/entities/serverEnums";
import {
  MdsAlarmXs,
  MdsAssignmentTurnedInXs,
  SinchAbsentXs,
  SinchAmbulanceXs,
  SinchAttendancePendingXs,
} from "@sinch/icons";
import React, { ElementType } from "react";
import { useTranslation } from "react-i18next";
import { useEntityProps } from "../utilsHooks";

export const useAttendancePresence = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const attendancePresence = {
    [AttendancePresenceEnum.Present]: {
      label: t("ClosingForm.presencePresent"),
      icon: MdsAssignmentTurnedInXs,
      color: palette.success.main,
    },
    [AttendancePresenceEnum.Late]: {
      label: t("ClosingForm.presenceLate"),
      icon: MdsAlarmXs,
      color: palette.custom.orange["500"],
    },
    [AttendancePresenceEnum.Excused]: {
      label: t("ClosingForm.presenceExcused"),
      icon: SinchAmbulanceXs,
      color: palette.custom.blue["400"],
    },
    [AttendancePresenceEnum.Miss]: {
      label: t("ClosingForm.presenceAbsent"),
      icon: SinchAbsentXs,
      color: palette.error.main,
    },
    [AttendancePresenceEnum.Pending]: {
      label: t("ClosingForm.presencePending"),
      icon: SinchAttendancePendingXs,
      color: palette.custom.orange["500"],
    },
  } as Record<AttendancePresenceEnum, { label: string; icon: React.ElementType; color: string }>;
  const getProp = useEntityProps(attendancePresence);

  const getPresenceLabel = getProp<string>("label");
  const getPresenceIcon = getProp<ElementType>("icon");
  const getPresenceColor = getProp<string>("color");
  const getSortedPresence = () => Object.keys(attendancePresence) as AttendancePresenceEnum[];

  return { getPresenceLabel, getPresenceIcon, getPresenceColor, getSortedPresence };
};
