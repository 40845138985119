import { Stack } from "@mui/material";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SectionTitle } from "../../../typography/SectionTitle/SectionTitle";
import { LabelValue } from "../LabelValue";
import { StaffFragment } from "../queries.types";

interface HighlightedAttributesProps {
  staff: StaffFragment;
}

export const HighlightedAttributes: FC<HighlightedAttributesProps> = ({ staff }) => {
  const { t } = useTranslation();

  const highlightedAttributes = [...(staff?.personalAttributes ?? []), ...(staff?.profileAttributes ?? [])]
    .filter((attribute) => attribute.attribute.important)
    .map((attribute) => ({
      label: attribute.attribute.name,
      value: attribute.value,
      files: attribute.files,
      type: attribute.attribute.dataType,
    }));

  const showHighlightedAttributes = highlightedAttributes.length > 0;
  if (!showHighlightedAttributes) {
    return null;
  }

  return (
    <Stack>
      <SectionTitle title={t("Staff.highlightedAttributes")} variant="subtitle1" />
      {highlightedAttributes.map((pair) => (
        <LabelValue
          key={pair.label + pair.value}
          files={pair.files ?? []}
          label={pair.label}
          type={pair.type}
          value={pair.value}
        />
      ))}
    </Stack>
  );
};
