import { map } from "ramda";

/**
 * Method for build getters of entity props
 */
export const useEntityProps =
  <
    TProps extends Record<string, Record<string, unknown>>,
    TKey extends keyof TProps,
    TPropName extends keyof TProps[TKey],
  >(
    props: TProps
  ) =>
  <TVal,>(propertyName: TPropName) =>
  (key: TKey): TVal =>
    props[key][propertyName] as TVal;

export const getOptions =
  <
    TProps extends Record<string, Record<string, unknown>>,
    TKey extends keyof TProps,
    TPropName extends keyof TProps[TKey],
  >(
    props: TProps
  ) =>
  (propertyName: TPropName) =>
    map(
      (value: TKey) => ({
        value,
        label: props[value][propertyName],
      }),
      Object.keys(props) as TKey[]
    );
