import React from "react";

// eslint-disable-next-line import/no-internal-modules
import { isElement } from "react-dom/test-utils";
import { buildRegexFromOptions, getKeyForElement } from "./utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function clone(element: any, key: string) {
  if (!element.$$typeof || !key) {
    throw new Error(`clone() requires an element and key`);
  }

  const { props, type: Type } = element;

  /* clone element and add a `key` prop so React won't complain */
  return (
    <Type {...props} key={key}>
      {props.children}
    </Type>
  );
}

interface ConstructorParams {
  keepUnknownVariables?: boolean;
  prefix?: string;
  suffix?: string;
}

export class ReactElementPostprocessor {
  name: string;

  type: string;

  keepUnknownVariables: boolean;

  prefix: string;

  suffix: string;

  searchRegex: RegExp;

  replaceRegex: RegExp;

  constructor(opts: ConstructorParams = {}) {
    this.name = `reactPostprocessor`;
    this.type = `postProcessor`;

    this.keepUnknownVariables = opts.keepUnknownVariables || false;
    this.prefix = opts.prefix || `<`;
    this.suffix = opts.suffix || `>`;

    this.searchRegex = buildRegexFromOptions({
      capturePrefixAndSuffix: true,
      prefix: this.prefix,
      suffix: this.suffix,
    });
    this.replaceRegex = buildRegexFromOptions({
      prefix: this.prefix,
      suffix: this.suffix,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  process(value: string, key: string, options: Record<string, any>) {
    const tokens = value.split(this.searchRegex);

    if (tokens.length === 1) {
      /* nothing to interpolate */
      return tokens[0];
    }

    const filled = tokens
      .filter(Boolean) // don't care about empty strings
      .map((token, tokenIndex) => {
        if (!this.searchRegex.test(token)) {
          return token;
        }

        /* strip prefix/suffix from token to get the interpolated variable name */
        const tokenName = token.replace(this.replaceRegex, `$1`);
        const element = options[tokenName];

        if (!element && element !== 0) {
          return this.keepUnknownVariables ? token : ``;
        }

        if (element.$$typeof) {
          return clone(element, getKeyForElement(element, tokenIndex));
        }
        if (typeof element === `function`) {
          const el = element();

          return clone(el, getKeyForElement(el, tokenIndex));
        }
        return element;
      });
    const hasElement = filled.filter(isElement).length > 0;
    return hasElement ? filled : filled.join("");
  }
}
