query Contacts($companyId: ID!) {
  contacts(filter: { companyId: { EQ: $companyId } }) {
    id
    name
    phone
  }
}

mutation AddContact($companyId: ID!, $input: ContactInput!) {
  createContact(companyId: $companyId, input: $input) {
    payload {
      id
      name
      phone
    }
    messages {
      field
      message
      severity
    }
    result
  }
}
