import { useMemo } from "react";
import { PointProps, PointType } from "./Point";
import { Wage, WageType } from "./types";

export const useWagePointConverter: (wages: Wage[]) => PointProps[] = (wages) => {
  const points: PointProps[] = useMemo(() => {
    let totalValue = 0;
    const newPoints: PointProps[] = [];
    if (!wages) {
      return newPoints;
    }
    wages.map((wage, index) => {
      if (wage.type === WageType.FIXED) {
        if ((wages[1] !== undefined && wages[1].duration !== 0) || wages[1] === undefined) {
          totalValue = wage.value;
          newPoints.push({
            value: totalValue,
            location: index * (160 + 32) - 4,
            type: PointType.FIRST,
            hours: wage.duration,
          });
          newPoints.push({
            value: totalValue,
            location: 160 + index * (160 + 32) - 4,
            type: PointType.LAST,
            hours: index + 1 === wages.length ? wage.duration + 1 : wages[index + 1].duration,
          });
        }
      } else if (wage.type === WageType.THRESHOLD_FIX) {
        newPoints.push({
          value: wage.value,
          location: index * (160 + 32) - 4,
          type: PointType.FIRST,
          hours: wage.duration,
        });
        newPoints.push({
          value: wage.value,
          location: 160 + index * (160 + 32) - 4,
          type: PointType.LAST,
          hours: index + 1 === wages.length ? wage.duration + 1 : wages[index + 1].duration,
        });
      } else {
        if (wage.type === WageType.HOURLY_THRESHOLD) {
          totalValue = wage.duration * wage.value;
        } else {
          totalValue += Number(wage.value);
        }

        // First point
        newPoints.push({
          value: totalValue,
          location: index * (160 + 32) - 4,
          type: PointType.FIRST,
          hours: wage.duration,
        });
        if (wages.length > index + 1) {
          const pointsNumber = wages[index + 1].duration - wage.duration;
          const limit = pointsNumber > 5;
          for (let i = 0; i < pointsNumber; i++) {
            if (pointsNumber === i + 1) {
              break;
            }
            totalValue += Number(wage.value);
            if (i > 4) {
              break;
            }

            newPoints.push({
              value: totalValue,
              location: index * (160 + 32) + ((i + 1) / (limit ? 5 : pointsNumber)) * 160 - 3,
              type: PointType.SMALL,
              hours: wage.duration + i + 1,
            });
          }
        }

        //Last point
        if (wages.length === index + 1) {
          newPoints.push({
            value: index + 1 === wages.length ? totalValue + wage.value : totalValue,
            location: index * (160 + 32) + 160 - 4,
            type: PointType.LAST,
            endAdornment: index + 1 === wages.length,
            hours: index + 1 === wages.length ? wage.duration + 1 : wages[index + 1].duration,
          });
        }
      }
    });
    return newPoints;
  }, [wages]);

  return points;
};
