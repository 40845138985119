import type { MutationTuple } from "@apollo/client";
import { DocumentNode, MutationHookOptions, OperationVariables, TypedDocumentNode, useMutation } from "@apollo/client";
import { Stack, Typography } from "@mui/material";
import { MessageSeverity } from "@sinch/core/entities/serverEnums";
import type { Mutation, MutationMessage, MutationResponseInterface } from "@sinch/types/sinch.types";

import { useSnackbar } from "notistack";
import { flatten, groupBy, isNil, map, values } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React from "react";
import { RequireAtLeastOne } from "type-fest";

export type SinchMutations = RequireAtLeastOne<Partial<Record<keyof Mutation, MutationResponseInterface>>>;

export type AliasedMutations = Record<
  string,
  Partial<Record<keyof Mutation, MutationResponseInterface>>[keyof Partial<
    Record<keyof Mutation, MutationResponseInterface>
  >]
>;

type UseMutationHandlerReturn<TData extends SinchMutations | AliasedMutations, TVariables = OperationVariables> = [
  MutationTuple<TData, TVariables>[0],
  MutationTuple<TData, TVariables>[1] & {
    mutationMessages: Record<keyof TData, MutationMessage[]>;
  },
];

export const useMutationHandler = <TData extends SinchMutations | AliasedMutations, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & { notifyMessages?: boolean }
): UseMutationHandlerReturn<TData, TVariables> => {
  const { enqueueSnackbar } = useSnackbar();
  const [mutate, mutationResult] = useMutation<TData, TVariables>(mutation, {
    errorPolicy: "all",
    ...options,
  });

  const mutationMessages = (
    mutationResult.data
      ? map<TData, Record<keyof Mutation, MutationMessage[]>>(
          (mutationResponse) => mutationResponse?.messages ?? [],
          mutationResult.data
        )
      : {}
  ) as Record<keyof TData, MutationMessage[]>;

  if (options?.notifyMessages) {
    const messages = groupBy(
      (message: MutationMessage) => message.severity,
      flatten(values(mutationMessages ?? ({} as Record<string, MutationMessage[]>))).filter(({ field }) => isNil(field))
    );
    if (isNotNilOrEmpty(messages[MessageSeverity.Error])) {
      enqueueSnackbar(<StackedMessages messages={messages[MessageSeverity.Error]} />, { variant: "error" });
    }
    if (isNotNilOrEmpty(messages[MessageSeverity.Warning])) {
      enqueueSnackbar(<StackedMessages messages={messages[MessageSeverity.Warning]} />, { variant: "warning" });
    }
  }

  return [mutate, { ...mutationResult, mutationMessages }];
};

const StackedMessages = ({ messages }: { messages: MutationMessage[] }) => (
  <Stack>
    {messages.map(({ message }, index) => (
      <Typography key={index}>{message}</Typography>
    ))}
  </Stack>
);
