import { Box, BoxProps, CircularProgress, Grid, GridProps, styled, Typography } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";

interface LoadingWrapperProps extends PropsWithChildren {
  /**
   * Loader title
   */
  title?: string | false;
  on: boolean | undefined;
  sx?: BoxProps["sx"];
  size?: number | string;
}

/**
 * Box with loading title and progress bar
 */
export const LoadingWrapper: FC<LoadingWrapperProps> = ({ on, title, children, sx, size }) => {
  const { t } = useTranslation();

  if (!on) {
    return children;
  }
  return (
    <LoadingWrapperContainer sx={sx}>
      <Box sx={{ opacity: 0.2 }}>{children}</Box>
      <BoxOverlay>
        <LoadingLabel alignItems="center" container direction="column">
          {title !== false && (
            <Grid
              item
              sx={{
                pb: 3,
              }}
              xs
            >
              <Typography variant="h3">{title ?? t("loading") + "..."}</Typography>
            </Grid>
          )}
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
            xs
          >
            <CircularProgress color="secondary" size={size} />
          </Grid>
        </LoadingLabel>
      </BoxOverlay>
    </LoadingWrapperContainer>
  );
};

const LoadingLabel = styled(Grid, { name: "LoadingWrapper", slot: "label" })<GridProps>(() => ({
  position: "sticky",
  bottom: "25vh",
  top: "25vh",
}));

const LoadingWrapperContainer = styled(Box, { name: "LoadingWrapper", slot: "container" })<BoxProps>(() => ({
  position: "relative",
}));

const BoxOverlay = styled(Box, { name: "LoadingWrapper", slot: "overlay" })<BoxProps>(() => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
}));
