import { useRef } from "react";
import { useLongPress } from "use-long-press";

interface UseHoldAndRepeatOptions {
  /**
   * Time after which first callback will be called
   */
  threshold?: number;
  /**
   * Repeat interval time
   */
  repeatInterval?: number;
  /**
   * Called when press is released or callback return false
   */
  onFinish?: () => void;
}

type UseHoldAndRepeatProps = (
  /**
   * Method to repeat after threshold interval, if callback return true, next loop will continue, otherwise clear interval
   */
  callback: () => boolean,
  options?: UseHoldAndRepeatOptions
) => ReturnType<ReturnType<typeof useLongPress>>;

/**
 * Provide events and handling hold and repeat functionality for elements. After threshold time, event will be repeated
 */
export const useHoldAndRepeat: UseHoldAndRepeatProps = (
  callback,
  { repeatInterval = 50, threshold = 750, onFinish } = {}
) => {
  const hold = useRef<ReturnType<typeof setInterval> | null>(null);

  const longPress = useLongPress(
    () => {
      hold.current = setInterval(() => {
        if (hold.current && !callback()) {
          clearInterval(hold.current);
          onFinish?.();
        }
      }, repeatInterval);
    },
    {
      threshold: threshold,
      onStart: () => callback(),
      onFinish: () => {
        if (hold.current) {
          clearInterval(hold.current);
        }
        onFinish?.();
      },
    }
  );

  return longPress();
};
