import React, { FC } from "react";
import { UploadInputProps } from "../types";
import { UploadInput } from "../UploadInput";
import { DropZoneImageUploadContainer } from "./DropZoneImageUploadContainer";
import { PreviewImage } from "./PreviewImage";
import { SimpleUploadButton } from "./SimpleUploadButton";

/**
 * Dropzone image upload component
 */
export const DropZoneImageUpload: FC<Omit<UploadInputProps, "slots">> = (props) => (
  <UploadInput
    {...props}
    slots={{
      container: DropZoneImageUploadContainer,
      previewItem: PreviewImage,
      upload: SimpleUploadButton,
    }}
  />
);
