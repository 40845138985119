import { Grid, Stack, Theme } from "@mui/material";
import React from "react";

/**
 * Positioning wrapper for buttons stuck to bottom of the screen
 * @param children
 */
export const StickyButtonsWrapper: React.FC<React.PropsWithChildren> = ({ children }) => (
  <Grid
    item
    sx={{
      bottom: 0,
      mb: 2,
      position: "sticky",
      zIndex: 10,
    }}
    xs={12}
  >
    <Stack
      direction={{ xs: "column", sm: "row" }}
      spacing={1}
      sx={[
        {
          alignItems: { xs: "start", sm: "center" },
          borderTop: (theme: Theme) => `2px solid ${theme.palette.grey[300]}`,
          marginTop: 1,
          paddingY: 2,
        },
        (theme) => ({
          backgroundColor: "white",
          "&::before": {
            content: "' '",
            display: "block",
            height: "100%",
            left: theme.spacing(-2.5),
            right: theme.spacing(-2.5),
            backgroundColor: "white",
            position: "absolute",
          },
        }),
      ]}
    >
      {children}
    </Stack>
  </Grid>
);
