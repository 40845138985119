import { debounce } from "@mui/material";

import { SEARCH_FIELD_LIMIT } from "@sinch/core/config/globals";
import { useLazyQueryHandler } from "@sinch/hooks/useLazyQueryHandler";
import { mapToOptions, Option } from "@sinch/utils/option";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { FC, Ref, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../../form/AutocompleteInput/AutocompleteInput";
import { AutocompleteInputProps, AutocompleteOptions } from "../../form/AutocompleteInput/types";
import { AttendanceChip } from "./AttendanceChip";
import { clearPrefixForSearch, PrefixedEntities, SinchEntity, useSinchEntity } from "./entitiesProperties";
import { OrdersData } from "./queries.graphql";
import { OrdersDataQuery, OrdersDataQueryVariables } from "./queries.types";

interface OrdersSelectInputProps
  extends Pick<AutocompleteInputProps<AutocompleteOptions, true>, "slotProps" | "textFieldProps"> {
  /**
   * Input name
   */
  name: string;
  label: string;
  required?: boolean;
  value: (string | number)[] | undefined;
  onChange?: (val: (string | number)[] | null) => void;
  disabled?: boolean;
  error?: boolean;
  errorMessage: string[];
  inputRef: Ref<any>;
  onBlur: () => void;
  /**
   * Limit searched list of options
   */
  limit?: number;
}

export const OrdersSelectInput: FC<OrdersSelectInputProps> = ({ limit = SEARCH_FIELD_LIMIT, ...field }) => {
  const { t } = useTranslation();
  const [inputVal, setInputVal] = useState<string>("");
  const { getEntityIdLabel } = useSinchEntity();
  const orderMap = useRef(new Map<string, string>());

  const { data, dispatch, loading } = useLazyQueryHandler<OrdersDataQuery, OrdersDataQueryVariables>(OrdersData);
  const options: Option[] = useMemo(
    () =>
      mapToOptions(
        "id",
        ({ name, number }) => `${getEntityIdLabel(SinchEntity.Order, number)} ${name}`
      )(data?.orders || []),
    [data?.orders]
  );

  const debounceDispatch = useCallback(debounce(dispatch, 200), [dispatch]);

  useEffect(() => {
    if (isNotNilOrEmpty(field.value)) {
      debounceDispatch({ limit, filter: { id: { IN: field.value!.map(String) } } });
    }
  }, []);

  const orderIdNumberMap = useMemo<Map<string, string>>(() => {
    data?.orders.forEach((order) => {
      orderMap.current.set(order.id, order.number);
    });
    return orderMap.current;
  }, [data?.orders]);

  const handleSearch = (search: string) => {
    debounceDispatch({ limit, filter: { search: { LIKE: clearPrefixForSearch(search, PrefixedEntities.Order) } } });
  };

  return (
    <AutocompleteInput<AutocompleteOptions, true>
      size="small"
      {...field}
      inputValue={inputVal ?? ""}
      loading={loading}
      multiple
      onFocus={() => handleSearch(inputVal)}
      onInputChange={(event, newInputValue) => {
        // prevent backfire on select
        if (!event) return;

        setInputVal(newInputValue);
        handleSearch(newInputValue);
      }}
      openOnFocus
      options={options}
      placeholder={t("search")}
      renderTags={(vals, getTagProps) =>
        vals.map((val, index) => (
          <AttendanceChip
            key={val.value}
            getTagProps={getTagProps}
            index={index}
            val={{ ...val, value: orderIdNumberMap.get(String(val.value)) ?? "" }}
            variant={SinchEntity.Order}
          />
        ))
      }
    />
  );
};
