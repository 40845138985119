import { useForkRef } from "@mui/material";
import { unstable_generateUtilityClasses as generateUtilityClasses } from "@mui/utils";
import { T, without } from "ramda";
import { RefObject, useEffect, useRef } from "react";
import { Drake, DragulaOptions, dragula } from "../../DragNDrop/dragula/dragula";
// eslint-disable-next-line import/no-internal-modules
import "@sinch/components/DragNDrop/dragula/dragula.css";

export type DragAndDropControl = ReturnType<typeof useDragAndDrop>;

interface SourceData<T = any> {
  data: T;
}

export interface TargetData<T = any> {
  onDrop?: (data: T, element: Element, target: Element, source: Element) => void;
  onDropOver?: (data: T, element: Element, target: Element) => void;
  onDropLeave?: (data: T, element: Element, target: Element) => void;
  isDroppable?: (data: T, element: Element, target: Element) => boolean;
  isDropInvalid?: (data: T, element: Element, target: Element) => boolean;
  dragula?: Drake;
}

export const dndClasses = generateUtilityClasses("SinchDnD", [
  "draggable",
  "droppable",
  "over",
  "dragging",
  "invalidDrop",
]);

/**
 * Initializes the drag and drop functionality using the Dragula library.
 * @see [Dragula instance options](https://github.com/bevacqua/dragula)
 */
export const useDragAndDrop = (props?: DragulaOptions) => {
  const sources = useRef<Map<Element, SourceData>>(new Map());
  const targets = useRef<Map<Element, TargetData>>(new Map());
  const dragulaInstance = useRef<Drake>(
    dragula({
      copy: true,
      accepts: (_, target) => target && targets.current.has(target),
      moves: (_, source) => source && sources.current.has(source),
      ...props,
    } as DragulaOptions)
  );

  useEffect(() => {
    dragulaInstance.current
      // Set class to all droppable containers and set source class to droppable
      .on("drag", (el, source) => {
        targets.current?.forEach((targetData, target) => {
          const isDroppableFn = targetData.isDroppable ?? T;
          const isDropInvalidFn = targetData.isDropInvalid ?? T;
          if (isDroppableFn(sources.current.get(source)?.data, el, target)) {
            target.classList.add(dndClasses.droppable);
          } else if (isDropInvalidFn(sources.current.get(source)?.data, el, target)) {
            target.classList.add(dndClasses.invalidDrop);
          }
        });
        (source as HTMLElement).classList.add(dndClasses.dragging);
      })
      // remove class from all droppable containers
      .on("dragend", () => {
        targets.current?.forEach((_, target) => {
          target.classList.remove(dndClasses.droppable, dndClasses.invalidDrop);
        });
      })
      // Call action when user drop element
      .on("drop", (el, target, source /*, sibling*/) => {
        targets.current.get(target)?.onDrop?.(sources.current.get(source)?.data ?? undefined, el, target, source);
        (source as HTMLElement).classList.remove(dndClasses.dragging, dndClasses.invalidDrop);
      })
      // Remove class from source when user cancel drag
      .on("cancel", (_, __, source) => {
        (source as HTMLElement).classList.remove(dndClasses.dragging, dndClasses.invalidDrop);
      })
      // Remove class from source when user remove drag
      .on("remove", (_, __, source) => {
        (source as HTMLElement).classList.remove(dndClasses.dragging, dndClasses.invalidDrop);
      })
      // this disable a shadow
      .on("shadow", (el /*, container, source*/) => {
        (el as HTMLElement).remove();
      })
      // Add over class to target
      .on("over", (el, container, source) => {
        targets.current.get(container)?.onDropOver?.(sources.current.get(source)?.data ?? undefined, el, container);
        (container as HTMLElement).classList.add(dndClasses.over);
      })
      // Remove over class from target
      .on("out", (el, container, source) => {
        targets.current.get(container)?.onDropLeave?.(sources.current.get(source)?.data ?? undefined, el, container);
        (container as HTMLElement).classList.remove(dndClasses.over);
      });
    //.on("cloned", (clone, original, type) => console.log("cloned", clone, original, type));
  }, []);

  /**
   * Makes the element draggable using Dragula library.
   */
  const addDraggable = (el: HTMLElement, options?: { data: SourceData["data"] }) => {
    sources.current.set(el, { data: options?.data });
    el.classList.add(dndClasses.draggable);
    if (dragulaInstance.current.containers.indexOf(el) === -1) {
      dragulaInstance.current.containers.push(el);
    }
  };

  /**
   * Removes the element from the list of draggable elements.
   */
  const removeDraggable = (el: HTMLElement) => {
    if (sources.current.has(el)) {
      el.classList.remove(dndClasses.draggable);
      sources.current.delete(el);
      dragulaInstance.current.containers = without([el], dragulaInstance.current.containers);
    }
  };

  /**
   * Makes the element droppable area using Dragula library.
   */
  const addDroppable = (el: HTMLElement, options?: TargetData) => {
    targets.current.set(el, options ?? {});
    if (dragulaInstance.current.containers.indexOf(el) === -1) {
      dragulaInstance.current.containers.push(el);
    }
  };

  /**
   * Removes the element from the list of droppable elements.
   */
  const removeDroppable = (el: HTMLElement) => {
    if (targets.current.has(el)) {
      targets.current.delete(el);
      dragulaInstance.current.containers = without([el], dragulaInstance.current.containers);
    }
  };

  return {
    addDraggable,
    removeDraggable,
    addDroppable,
    removeDroppable,
    dragula: dragulaInstance.current,
  };
};

export const useDraggable = <TData = any,>({
  control: controlObj,
  ref,
  data,
  isDraggable,
}: {
  ref?: RefObject<unknown>;
  control?: DragAndDropControl;
  data?: TData;
  isDraggable?: boolean;
}) => {
  const control = controlObj;
  const elRef = useRef<unknown>();
  const setRef = useForkRef(ref, elRef);

  if (!control) {
    console.error("useDraggable hook must be used inside DragAndDropProvider or receive control prop");
  }

  useEffect(() => {
    if (isDraggable && ref) {
      control?.addDraggable(ref.current as HTMLElement, { data });
    } else if (isDraggable && elRef.current) {
      control?.addDraggable(elRef.current as HTMLElement, { data });
    }
    if (!isDraggable && ref) {
      control?.removeDraggable(ref.current as HTMLElement);
    } else if (!isDraggable && elRef.current) {
      control?.removeDraggable(elRef.current as HTMLElement);
    }
    return () => {
      if (!isDraggable && ref) {
        control?.removeDraggable(ref.current as HTMLElement);
      } else if (!isDraggable && elRef.current) {
        control?.removeDraggable(elRef.current as HTMLElement);
      }
    };
  }, [ref, isDraggable]);

  return { ref: setRef };
};

export const useDroppable = <TData = any,>({
  control: controlObj,
  ref,
  onDrop,
  onDropOver,
  onDropLeave,
  isDroppable,
  isDropInvalid,
}: {
  ref?: RefObject<Element>;
  control?: DragAndDropControl;
} & TargetData<TData>) => {
  const control = controlObj;
  const elRef = useRef<Element>();
  const setRef = useForkRef(ref, elRef);

  if (!control) {
    console.error("useDraggable hook must be used inside DragAndDropProvider or receive control prop");
  }

  useEffect(() => {
    if (ref) {
      control?.addDroppable(ref.current as HTMLElement, {
        onDrop,
        isDroppable,
        isDropInvalid,
        onDropOver,
        onDropLeave,
      });
    } else if (elRef.current) {
      control?.addDroppable(elRef.current as HTMLElement, {
        onDrop,
        isDroppable,
        isDropInvalid,
        onDropOver,
        onDropLeave,
      });
    }
    return () => {
      if (ref) {
        control?.removeDroppable(ref.current as HTMLElement);
      } else if (elRef.current) {
        control?.removeDroppable(elRef.current as HTMLElement);
      }
    };
  }, [ref, onDrop, onDropOver, onDropLeave]);

  return { ref: setRef };
};
