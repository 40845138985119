import { ButtonProps, Link, Stack, Typography } from "@mui/material";
import { useSettings } from "@sinch/core/providers/AppSettings";
import React, { forwardRef, PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { EntityPill } from "../../Pill/Pill";
import { getCommonTags, mapRequirements } from "../../Shift";
import { TextExtender } from "../../typography/TextExtender/TextExtender";
import { SinchEntity, useSinchEntity } from "../entities/entitiesProperties";
import { ShiftPanelDetailFragment } from "./queries.types";
import { PhoneLink } from "@sinch/components/PhoneLink/PhoneLink";
import { PropertyLabel } from "@sinch/components/typography/PropertyLabel/PropertyLabel";

interface BasicInfoProps {
  shift: ShiftPanelDetailFragment;
}

export const ShiftBasicInfo: React.FC<BasicInfoProps> = ({ shift }) => {
  const { t } = useTranslation();
  const { crewbossName } = useSettings();
  const { getEntityIdLabel } = useSinchEntity();

  const tags = getCommonTags(shift.positions);
  const requirements = mapRequirements(shift.positions);

  return (
    <>
      {shift.positions[0]?.positionLocation.place?.name && (
        <PropertyLabel label={t("location")}>{shift.positions[0].positionLocation.place.name}</PropertyLabel>
      )}
      {shift.job.order.company.name && (
        <PropertyLabel label={t("Company.label")}>
          <Link href={`/admin/companies/view/${shift.job.order.company.id}`}>{shift.job.order.company.name}</Link>
        </PropertyLabel>
      )}
      {shift.contactList[0]?.name && (
        <PropertyLabel label={t("contactPerson")}>
          {shift.contactList.map((contact) => (
            <Stack
              key={contact.id}
              direction="row"
              spacing={0.5}
              sx={{
                alignItems: "center",
              }}
            >
              <Typography variant="descriptionSmall">{contact.name}</Typography>
              {contact.phone && <PhoneLink phone={contact.phone} />}
            </Stack>
          ))}
        </PropertyLabel>
      )}
      {shift.description && (
        <PropertyLabel label={t("Shift.descriptionForWorkers")}>
          <TextExtender lineHeight={15} maxLines={2} slots={{ expandButton: LinkButton }}>
            <span dangerouslySetInnerHTML={{ __html: shift.description }} />
          </TextExtender>
        </PropertyLabel>
      )}
      {shift.crewbossDescription && (
        <PropertyLabel label={t("Shift.descriptionForCrewboss", crewbossName)}>
          <TextExtender lineHeight={15} maxLines={2} slots={{ expandButton: LinkButton }}>
            <span dangerouslySetInnerHTML={{ __html: shift.crewbossDescription }} />
          </TextExtender>
        </PropertyLabel>
      )}
      {tags && (
        <PropertyLabel label={t("Tag.tags")}>
          <Stack
            direction="row"
            spacing={0.5}
            sx={{
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {tags.map((tag) => (
              <EntityPill
                key={tag.id}
                labelColor={(theme) => theme.palette.custom.blue[900]}
                size="small"
                sx={({ spacing }) => ({ mt: `${spacing(0.5)} !important` })}
              >
                {tag.name}
              </EntityPill>
            ))}
          </Stack>
        </PropertyLabel>
      )}
      {requirements && requirements.length > 0 && (
        <PropertyLabel label={t("Shift.requirements")}>
          {requirements.map((requirement) => (
            <Typography key={requirement.requirement.id} variant="descriptionSmall">
              {`${requirement.requirement.text} (${
                requirement.positions.length === shift!.positions.length
                  ? t("Staff.allPositions")
                  : requirement.positions.map((position) => getEntityIdLabel(SinchEntity.Position, position)).join(", ")
              })`}
            </Typography>
          ))}
        </PropertyLabel>
      )}
    </>
  );
};

const LinkButton = forwardRef<HTMLAnchorElement, PropsWithChildren<ButtonProps & { onClick: () => void }>>(
  ({ children, onClick }, ref) => (
    <Link
      ref={ref}
      onClick={() => onClick?.()}
      sx={{
        display: "block",
        textDecoration: "underline",
        "&:first-letter": {
          textTransform: "uppercase",
        },
      }}
      variant="descriptionSmallSemi"
    >
      {children}
    </Link>
  )
);
LinkButton.displayName = "LinkButton";
