query WorkerPaymentMethods($workerId: ID!, $dateValid: DateTime!) {
  availablePayoutMethods(dateValid: $dateValid, workerId: $workerId) {
    id
    name
  }
}

query GroupedPositions($positionIds: [ID!]!) {
  positions(filter: { id: { IN: $positionIds } }) {
    beginning
    end
    role
    name
    id
    size
    shift {
      id
      name
    }
    attendances {
      id
    }
  }
}
