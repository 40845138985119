import { useLocalStorage } from "@sinch/hooks/utilities/useLocalStorage";

import { isSuitableFormRootData, setFormRootData } from "@sinch/utils/react-hook-form/setFormRootData";
import { useEffect, useState } from "react";
import { FieldValues, useFormContext } from "react-hook-form";

export function usePersistFormData<TFieldValues extends FieldValues>(
  localstorageKey: string,
  afterLoad?: (persistedState: TFieldValues) => void,
  formControl?: ReturnType<typeof useFormContext<TFieldValues>>
) {
  const formContext = useFormContext<TFieldValues>();
  const form = formControl ?? formContext;

  const key = `FORM::${localstorageKey}`;
  const [persistedState, , clearPersistedState] = useLocalStorage<TFieldValues>(key);

  const [isAdoptionPending, setIsAdoptionPending] = useState(isSuitableFormRootData(persistedState));

  const clearPersistedData = () => clearPersistedState();

  useEffect(
    () => {
      if (!isAdoptionPending) {
        return;
      }

      if (!isSuitableFormRootData(persistedState)) {
        setIsAdoptionPending(false);
        clearPersistedData();
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isAdoptionPending]
  );

  const handleCancel = () => {
    setIsAdoptionPending(false);
    clearPersistedData();
  };

  const handleConfirm = () => {
    if (persistedState) {
      setFormRootData(form, persistedState, { shouldDirty: true });
      afterLoad?.(persistedState);
    }
    setIsAdoptionPending(false);
  };

  return {
    key: key,
    persistedState,
    pending: isAdoptionPending,
    apply: handleConfirm,
    discard: handleCancel,
  };
}
