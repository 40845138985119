import { Box, Chip, FormControlProps, MenuItem, MenuItemProps, Select, SelectProps, useForkRef } from "@mui/material";
import { MdsExpandMore } from "@sinch/icons";

import { isTouchDevice } from "@sinch/utils/browser";
import { find, propEq } from "ramda";
import React, { ForwardedRef, forwardRef, ReactNode, useImperativeHandle, useRef } from "react";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { OverflowTooltip } from "../../typography/OverflowTooltip/OverflowTooltip";
import { FormControlWrapper, InputWrapperProps } from "../FormControlWrapper/FormControlWrapper";

type SelectOption = MenuItemProps & { value: number | string; label: ReactNode };

export type SelectInputProps<T = unknown> = SelectProps<T> &
  InputWrapperProps & {
    FormControlProps?: FormControlProps;
    options?: SelectOption[];
    disablePortal?: boolean;
  };

const SelectInputBase = <T,>(
  {
    label,
    FormHelperTextProps: formHelperTextProps,
    FormControlProps: formControlProps,
    helperText,
    errorMessage,
    error,
    required,
    multiple,
    infoTooltip,
    options,
    fullWidth,
    children,
    value,
    disablePortal,
    ...props
  }: SelectInputProps<T>,
  ref: React.Ref<unknown>
) => {
  const isTouch = isTouchDevice();
  // Use imperative handle to automatically open select when focus is called on the component
  const inputRef = useRef<HTMLInputElement | null>(null);
  const inRef = useForkRef(inputRef, ref || props.inputRef);
  useImperativeHandle(
    ref,
    () =>
      ({
        focus() {
          setTimeout(() =>
            inputRef?.current
              ?.querySelector("[role=button]")
              ?.dispatchEvent(new MouseEvent("mousedown", { bubbles: true, cancelable: false }))
          );
        },
      }) as HTMLDivElement,
    []
  );

  const defaultOptions = (options ?? []).map(({ label: itemLabel, value: val, ...menuProps }) => (
    <MenuItem key={val} value={val} {...menuProps}>
      {itemLabel}
    </MenuItem>
  ));

  const defaultRenderValue: SelectProps["renderValue"] =
    multiple && options
      ? (selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {(selected as (string | number)[]).map((val) => {
              const selectedOption = find<SelectOption>(propEq("value", val), options);
              if (!selectedOption) {
                return null;
              }
              return <Chip key={val} label={selectedOption.label} />;
            })}
          </Box>
        )
      : (selected) => {
          // This part is only for fixing the issue of text overflow in select input
          const selectedOption = find<SelectOption>(propEq("value", selected), options ?? []);
          return (
            <OverflowTooltip title={selectedOption?.label}>
              <Box
                sx={{
                  textOverflow: "inherit",
                  whiteSpace: "inherit",
                  overflow: "inherit",
                }}
              >
                {selectedOption?.label}
              </Box>
            </OverflowTooltip>
          );
        };

  return (
    <FormControlWrapper
      error={error}
      errorMessage={errorMessage}
      FormHelperTextProps={formHelperTextProps}
      fullWidth={fullWidth}
      helperText={helperText}
      infoTooltip={infoTooltip}
      label={label}
      required={required}
      {...formControlProps}
    >
      <Select
        ref={inRef}
        IconComponent={(iconProps) => <MdsIcon {...iconProps} fontSize="small" icon={MdsExpandMore} />}
        MenuProps={{
          disableScrollLock: !isTouch,
          disablePortal,
          ...props.MenuProps,
        }}
        multiple={multiple}
        renderValue={defaultRenderValue}
        required={required}
        value={value}
        variant="outlined"
        {...props}
      >
        {children ?? defaultOptions}
      </Select>
    </FormControlWrapper>
  );
};

/**
 * Select input, See [https://mui.com/material-ui/api/select/](https://mui.com/material-ui/api/select/)
 */
export const SelectInput = forwardRef(SelectInputBase) as <T = unknown>(
  props: SelectInputProps<T> & {
    ref?: ForwardedRef<HTMLDivElement | undefined> | undefined;
  }
) => ReturnType<typeof SelectInputBase>;
