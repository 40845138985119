import { Step, StepProps, styled } from "@mui/material";

export const StepItem = styled(Step, { shouldForwardProp: (propName) => propName !== "size" })<
  StepProps & { size?: "medium" | "small" }
>(({ size = "medium", theme }) => {
  if (size === "small") {
    return {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginLeft: theme.spacing(-1),
      paddingLeft: theme.spacing(2),
      marginRight: theme.spacing(-1),
      paddingRight: theme.spacing(2),
      height: "32px",
      "& .MuiStepIcon-root": {
        fontSize: theme.spacing(2),
        fontWeight: 700,
      },
      "& .MuiStepLabel-label": {
        fontSize: theme.typography.pxToRem(13),
        height: theme.spacing(2),
        "&, &.Mui-active": {
          fontWeight: 600,
        },
      },
      "& .MuiStepButton-root": {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        marginLeft: theme.spacing(-2),
        marginRight: theme.spacing(-2),
        marginTop: theme.spacing(-1),
        marginBottom: theme.spacing(-1),
        verticalAlign: "unset",
      },
    };
  }
});
