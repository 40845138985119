fragment StaffAttendanceStatistics on WorkerAttendanceStatistics {
  excusedCount
  lateCount
  missCount
  pendingCount
  presentCount
  totalCount
}

fragment StaffAttendance on Attendance {
  arrival
  departure
  hours
  id
  position {
    id
    beginning
    end
    status
    attendances {
      id
    }
    size
    attendances {
      id
    }
    shift {
      id
      name
      job {
        id
        name
      }
    }
  }
}

fragment StaffBadge on Badge {
  category {
    id
    name
    sort
  }
  id
  image {
    url
    name
  }
  name
  ranks
}

fragment StaffBadgeAggregation on BadgeUserAggregation {
  badge {
    ...StaffBadge
  }
  id
  level
  rank
  rankedImageUrl
}

fragment StaffWalletStatistics on WorkerWalletStatistics {
  balance
  paidThisMonth
  paidTotal
  requested
}

fragment StaffAttribute on AttributeValue {
  attribute {
    block {
      id
      name
    }
    important
    citizenship
    dataType
    description
    expirable
    id
    longDescription
    mandatory
    name
    options {
      id
      name
    }
  }
  expirationDate
  files {
    name
    url
  }
  id
  value
}

fragment Staff on Worker {
  attendanceStatistics {
    ...StaffAttendanceStatistics
  }
  badgeUserAggregations {
    ...StaffBadgeAggregation
  }
  walletStatistics {
    ...StaffWalletStatistics
  }

  avatar(variant: THUMB_500_500) {
    url
    name
  }
  birthdate
  email
  id
  languageId
  name
  personalAttributes {
    ...StaffAttribute
  }
  profileAttributes {
    ...StaffAttribute
  }

  phone
  professions {
    id
    name
  }
  status
  surname
  attendances(limit: 10, sort: { arrival: DESC }) {
    ...StaffAttendance
  }
  workerinfo {
    address
    addressLocal
    addressPreferred
    avatarVerified
    bankAccountNumber
    bankAccountString
    citizenship
    city
    cityLocal
    country
    countryLocal
    goodArrivalsInARow
    lastAttendance
    localAddressPreferred
    maxRating
    permanentDurationFrom
    permanentDurationTo
    permanentResident
    phoneVerified
    privateNote
    rating
    ratingCoeficient
    region
    regionLocal
    sex
    userId
    verified
    zip
    zipLocal
  }
}

query StaffDetail($id: ID!) {
  worker(id: $id) {
    ...Staff
  }
}
