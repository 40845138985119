import {
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  Radio,
  RadioGroup,
  RadioGroupProps,
  RadioProps,
} from "@mui/material";
import React, { ReactNode } from "react";
import { InputWrapperProps, FormControlWrapper } from "../FormControlWrapper/FormControlWrapper";

export interface RadioOptions<TValue extends string | number> {
  label: React.ReactNode;
  value: TValue;
}

export type RadioGroupInputProps<TValue extends string | number> = RadioGroupProps &
  InputWrapperProps & {
    FormControlProps?: FormControlProps;
    options: RadioOptions<TValue>[];
    radioLabelProps?: Omit<FormControlLabelProps, "control" & "label">;
    required?: boolean;
    radioProps?: RadioProps;
    disabled?: boolean;
  };

/**
 * RadioGroup input with vertical buttons on side
 */
export const RadioGroupInput = <TValue extends string | number>({
  label,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  helperText,
  errorMessage,
  error,
  required,
  infoTooltip,
  value,
  options,
  radioLabelProps,
  radioProps,
  disabled,
  ...props
}: RadioGroupInputProps<TValue>): ReactNode => (
  <FormControlWrapper
    error={error}
    errorMessage={errorMessage}
    FormHelperTextProps={formHelperTextProps}
    helperText={helperText}
    infoTooltip={infoTooltip}
    label={label}
    required={required}
    {...formControlProps}
  >
    <RadioGroup {...props} sx={{ alignContent: "start", marginBottom: -1, marginRight: -1 }} value={value}>
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          {...radioLabelProps}
          {...option}
          checked={option.value === value}
          control={<Radio {...radioProps} data-cy={`radioButton-${option.value}`} />}
          disabled={disabled}
        />
      ))}
    </RadioGroup>
  </FormControlWrapper>
);
