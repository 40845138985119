import { FetchResult } from "@apollo/client";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";

import { useErrorLogger } from "@sinch/hooks/useErrorLogger";
import { useFormMutation } from "@sinch/hooks/useFormMutation";
import { useMuiForm } from "@sinch/hooks/useMuiForm";
import type { Contact, MutationCreateContactArgs } from "@sinch/types/sinch.types";

import { updateQuery } from "@sinch/utils/apollo";
import React, { FC } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DialogCloseIcon } from "../../../DialogCloseIcon/DialogCloseIcon";
import { MuiController, useMuiController } from "../../../form/MuiController/MuiController";
import { TextInput } from "../../../form/TextInput/TextInput";
import { HandleErrorBox } from "../../../HandleErrorBox/HandleErrorBox";
import { useFocusOnMount } from "../../../providers/AutofocusProvider/FocusProvider";
import { AddContact, Contacts } from "./queries.graphql";
import { AddContactMutation, ContactsQuery, ContactsQueryVariables } from "./queries.types";

interface AddContactFormFields {
  name: string;
  phone: string;
}

interface AddContactDialogProps {
  onCancel: () => void;
  open: boolean;
  onConfirm: (contact?: Contact | null) => void;
  companyId: string;
  defaults?: Partial<AddContactFormFields>;
}

export const AddContactDialog: FC<AddContactDialogProps> = ({ onCancel, open, onConfirm, companyId, defaults }) => {
  const { t } = useTranslation<any>();

  const form = useMuiForm<AddContactFormFields>({
    formOptions: { defaultValues: { name: "", phone: "", ...defaults } },
  });

  const { handleSubmit, error, loading } = useFormMutation<
    AddContactMutation,
    MutationCreateContactArgs,
    AddContactFormFields
  >({
    mutation: {
      mutate: AddContact,
      baseOptions: {
        update(cache, { data }: Omit<FetchResult<AddContactMutation>, "context">) {
          if (!data?.createContact?.result || !data?.createContact?.payload) {
            return;
          }
          updateQuery<ContactsQuery, ContactsQueryVariables>({
            cache,
            query: Contacts,
            variables: { companyId },
            mergeFn: (originalData) => {
              if (data?.createContact?.payload) {
                return {
                  contacts: [...(originalData?.contacts ?? []), data.createContact.payload],
                };
              }
              return originalData;
            },
          });
        },
      },
    },
    form,
    variableMapper: (fields) => ({ companyId, input: fields }),
  });

  const submitForm = async () => {
    const response = await handleSubmit();
    if (response?.createContact?.result) {
      form.reset();
      onConfirm(response?.createContact?.payload);
    }
  };

  const errorId = useErrorLogger(error);

  const { field: nameField } = useMuiController<AddContactFormFields>({
    name: "name",
    defaultValue: "",
    control: form.control,
    rules: { required: true },
  });
  const ref = useFocusOnMount(nameField.inputRef);

  return (
    <Dialog fullWidth maxWidth="xs" onClose={onCancel} open={open}>
      <DialogTitle>
        {t("Contact.add")}
        <DialogCloseIcon onClick={onCancel} />
      </DialogTitle>
      <DialogContent>
        {errorId && <HandleErrorBox errorId={errorId} />}
        <FormProvider {...form}>
          <Grid container direction="column" spacing={3}>
            <Grid item>
              <TextInput
                {...nameField}
                inputRef={ref}
                label={t("Contact.name")}
                placeholder={t("enterPlaceholder", { inputName: t("Contact.name").toLowerCase() })}
              />
            </Grid>
            <Grid item>
              <MuiController<AddContactFormFields>
                defaultValue=""
                name="phone"
                render={({ field }) => (
                  <TextInput
                    {...field}
                    label={t("Contact.phone")}
                    placeholder={t("enterPlaceholder", { inputName: t("Contact.phone").toLowerCase() })}
                  />
                )}
                required={t("required")}
              />
            </Grid>
          </Grid>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} size="large">
          {t("cancel")}
        </Button>
        <LoadingButton loading={loading} onClick={submitForm} size="large" variant="contained">
          {t("confirm")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
