/**
 * Get the width of a text element as it is rendered
 * @example displayTextWidth("This is demo text!", "italic 19pt verdana")
 * @param {string} text - The text to measure
 * @param {string} font - The font to use for the text, see [docs](https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/font)
 * @param {number} defaultWidth
 */
export function displayTextWidth(text: string, font: string, defaultWidth?: number) {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  if (!context) {
    return defaultWidth || text.length * 14;
  }
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

export function parseStringifiedHtml(stringifiedHtml: string) {
  const template = document.createElement("template");
  template.innerHTML = stringifiedHtml.trim();
  return template.content.children;
}

/**
 * Converts HTML content to plain text.
 */
export const htmlToText = (html: string | Node) => {
  if (html instanceof Node) {
    return html.textContent;
  }
  const parser = new DOMParser();
  const dom = parser.parseFromString(html, "text/html");
  return dom.body.textContent;
};
