import { Box, TableCell, TableRow, Typography } from "@mui/material";
import { PositionRoleEnum, PositionStatusEnum } from "@sinch/core/entities/serverEnums";
import { useFormat } from "@sinch/core/hooks/format";
import React from "react";
import { AttendanceCounter } from "../../../AttendanceCounter/AttendanceCounter";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { PositionPill } from "../../../Pill/Pill";
import { CancelledStatusPill } from "../../Position/Status/CancelledStatusPill";
import { ConfirmationWaitingPill } from "../../Position/Status/ConfirmationWatingPill";
import { usePositionRole } from "../../PositionRole/PositionRole";
import { ShiftPanelDetailFragment } from "../queries.types";

export const PositionHead: React.FC<{
  position: ShiftPanelDetailFragment["positions"][0];
  /**
   * If true, capacities of position will be added to end of row
   */
  includeCapacity?: boolean;
}> = ({ position, includeCapacity }) => {
  const { getRoleIcon } = usePositionRole();
  const { time } = useFormat();
  return (
    <TableRow
      sx={({ palette, spacing }) => ({
        backgroundColor: palette.custom.table.header,
        "& .MuiTableCell-root": {
          padding: spacing(0, 2),
        },
        "&:hover .MuiTableCell-root, &:nth-of-type(even) .MuiTableCell-root": {
          backgroundColor: `${palette.custom.table.header} !important`,
        },
      })}
    >
      <TableCell colSpan={6} size="small">
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <MdsIcon
              fontSize="small"
              icon={getRoleIcon(position.role as PositionRoleEnum)}
              sx={({ spacing }) => ({
                marginRight: spacing(1),
              })}
            />
            <Typography
              component="span"
              sx={(theme) => ({
                fontWeight: theme.typography.fontWeightMedium,
                fontSize: theme.typography.pxToRem(15),
              })}
            >
              {[position.profession.name, position.name].filter((str) => str?.length).join(" – ")}
            </Typography>
            <PositionPill
              background="paper"
              id={position.id}
              size="tiny"
              sx={(theme) => ({
                margin: `0 ${theme.spacing(1)}`,
              })}
            />
            {position.cancelled && <CancelledStatusPill />}
            {position.status === PositionStatusEnum.Pending && <ConfirmationWaitingPill />}
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
            }}
          >
            <Typography
              component="span"
              sx={{
                whiteSpace: "nowrap",
              }}
            >
              {time.short(position.beginning)}
              {" – "}
              {time.short(position.end)}
            </Typography>

            {includeCapacity && (
              <Box
                sx={(theme) => ({
                  backgroundColor: theme.palette.common.white,
                  marginLeft: 1,
                  px: 0.25,
                  height: 13,
                  borderRadius: "4px !important",
                })}
              >
                <AttendanceCounter attendance={position.attendances.length} attendanceSlots={position.size} />
              </Box>
            )}
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};
