import type { PositionRoles, Profession } from "@sinch/types/sinch.types";

import { find, prop, propEq } from "ramda";

export const getProfessionName =
  (professions: Pick<Profession, "id" | "name">[] = []) =>
  (professionId: string) =>
    prop("name", find(propEq("id", professionId), professions));

export const getPositionRoleLabel =
  (positionRoles: PositionRoles[] = []) =>
  (professionId: string) =>
    prop("label", find(propEq("id", professionId), positionRoles));
