import { useFormat } from "@sinch/core/hooks/format";
import { differenceIn } from "@sinch/utils/dateTime/dateFns";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import { join } from "@sinch/utils/string";
import { FC } from "react";

interface DatePartsFormats {
  start: (val: Date) => string;
  end: (val: Date) => string;
}

export interface DateRangeProps {
  /**
   * Start date.
   */
  start: Date;
  /**
   * End date.
   */
  end: Date;
  /**
   * Separator between dates.
   */
  separator?: string;
  /**
   * Show duration between dates at end of string
   */
  duration?: boolean;
  /**
   * Show weekday for start date.
   */
  weekday?: boolean;
}

/**
 * Date range component. Formats start and end dates according to the difference between them.
 * If the dates are in the same year, only the month and time is displayed.
 * If the dates are the same, only the start date and all times is displayed.
 */
export const DateRange: FC<DateRangeProps> = ({ start, end, separator = "-", duration, weekday }) => {
  const { date, time, duration: formatDuration } = useFormat();
  const formats: DatePartsFormats = {
    start: (val) => date.full(val),
    end: (val) => date.full(val),
  };

  const isEqualYear = toLuxon(start).year === toLuxon(end).year;
  if (isEqualYear) {
    formats.start = (val) => `${date.mediumMonth(val)} ${time.short(val)}`;
    formats.end = (val) => `${date.mediumMonth(val)} ${time.short(val)}`;
  }

  const isEqualDay = toLuxon(start).startOf("day").equals(toLuxon(end).startOf("day"));
  if (isEqualDay) {
    formats.start = (val) => `${date.mediumMonth(val)} ${time.short(val)}`;
    formats.end = (val) => time.short(val);
  }

  return join([
    weekday && date.weekdayShort(start),
    `${formats.start(start)} ${separator} ${formats.end(end)}`,
    duration && `(${formatDuration.floatHoursNarrow(differenceIn("seconds")(end, start))})`,
  ]);
};
