import { useForkRef } from "@mui/material";
import React, { forwardRef, useEffect, useRef } from "react";
import { ListProps } from "react-virtuoso";
import { AutocompleteListbox } from "../TimeInputSlots";

export const VirtuosoList = forwardRef<HTMLDivElement, ListProps>(({ style, children }, listRef) => {
  const currRef = useRef<HTMLElement>();
  const setRefs = useForkRef(currRef, listRef);
  useEffect(() => {
    if (currRef.current && currRef.current.parentElement?.parentElement?.hasAttribute("data-virtuoso-scroller")) {
      currRef.current.parentElement!.parentElement!.style.width =
        currRef.current.getBoundingClientRect().width + 20 + "px";
    }
  }, [style?.visibility]);
  return (
    <AutocompleteListbox ref={setRefs} style={{ padding: 0, ...style, margin: 0 }}>
      {children}
    </AutocompleteListbox>
  );
});
VirtuosoList.displayName = "VirtuosoList";
