import { identity, isNil, max, min, pipe } from "ramda";

/**
 * If date is out of boundary, move it to closest value
 */
export const forceBoundary = (minVal?: number, maxVal?: number) => {
  let forceMin: (a: number) => number;
  if (isNil(maxVal)) {
    forceMin = identity<number>;
  } else {
    forceMin = min(maxVal);
  }
  let forceMax: (a: number) => number;
  if (isNil(minVal)) {
    forceMax = identity<number>;
  } else {
    forceMax = max(minVal);
  }
  return pipe(forceMin, forceMax);
};

/**
 * Round number to given decimal places
 */
export const roundAt = (decimalPlaces: number) => (num: number) => {
  const d = Math.pow(10, decimalPlaces);
  return Math.round(num * d) / d;
};

/**
 * Round to floor value by step (e.g. 1.5 -> 1)
 */
export const forceStep = (step: number | "any" = 1) => {
  if (step === "any") {
    return identity<number>;
  }
  return (value: number) => value - (value % step);
};
/**
 * Ceil a number to the nearest multiple of the divisor without waste.
 *
 */
export const ceilToNearestDivisor = (num: number, divisor: number): number => {
  const remainder = num % divisor;
  if (remainder === 0) {
    return num;
  }
  return num + (divisor - remainder);
};
