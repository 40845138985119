// eslint-disable-next-line import/no-internal-modules
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { setRef } from "@mui/material";
import { LexicalEditor } from "lexical";
import React, { FC } from "react";

interface ChangeHandlerProps {
  innerRef: React.ForwardedRef<LexicalEditor>;
}

export const ChangeHandler: FC<ChangeHandlerProps> = ({ innerRef }) => {
  const [editor] = useLexicalComposerContext();
  setRef(innerRef, editor);
  return null;
};
