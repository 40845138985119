import { ClickAwayListener } from "@mui/base";
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  InputBaseProps,
  MenuItem,
  Select,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";

import { OperatorValueTypeEnum } from "@sinch/core/entities/serverEnums";
import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import { useUpdateValue } from "@sinch/hooks/useUpdateValue";
import { MdsContentCopy, MdsDelete, MdsExpandMore, MdsTimer } from "@sinch/icons";
import { forceBoundary } from "@sinch/utils/math";
import { decimalToPercents, percentsToDecimal } from "@sinch/utils/numberOperations";
import { mapToOptions, Option } from "@sinch/utils/option";
import { find, head, map, pipe, prop, propEq, when } from "ramda";
import { ensureArray, isNotNil, isNotNilOrEmpty, isTruthy, round } from "ramda-adjunct";
import React, { FC, useEffect, useMemo, useState } from "react";
import { ArrayPath, FieldValues, Path, useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../form/AutocompleteInput/AutocompleteInput";
import { FullHeightAdornment } from "../form/FullHeightAdornment/FullHeightAdornment";
import { useMuiController } from "../form/MuiController/MuiController";
import { SelectInput, SelectInputProps } from "../form/SelectInput/SelectInput";
import { TextInput } from "../form/TextInput/TextInput";
import { MdsIcon } from "../MdsIcon/MdsIcon";
import { useAutofocusTarget, useTriggerAutofocus } from "../providers/AutofocusProvider/FocusProvider";
import { useFieldArrayContext } from "../providers/FieldArrayProvider";
import { percentageOptions } from "./constants";
import { Requirements } from "./queries.graphql";
import { RequirementsQuery, SubjectFieldsFragment } from "./queries.types";
import { useNumberInputValue } from "@sinch/components/form/hooks/useNumberInputValue";

interface EditRequirementRowProps {
  onClose: () => void;
}

export const EditRequirementRow = <FormFields extends FieldValues, RequirementPath extends ArrayPath<FormFields>>({
  onClose,
}: EditRequirementRowProps) => {
  const { t } = useTranslation();
  const { prefix, scope, copy, remove } = useFieldArrayContext();
  const { trigger } = useFormContext();
  const { data: requirementsData } = useQueryHandler<RequirementsQuery>(Requirements);

  const requirementId = useWatch({ name: `${prefix}requirementId` });

  const currentRequirement = requirementsData?.requirements
    ? find(propEq("id", requirementId), requirementsData?.requirements)
    : null;

  const subjectValue = useWatch({ name: `${prefix}subject` });

  const currentSubject = currentRequirement?.subjects
    ? find(propEq("subject", subjectValue), currentRequirement?.subjects)
    : undefined;

  const handleConfirm = async () => {
    const result = await trigger(scope);
    if (result) {
      onClose();
    }
  };

  const handleRemove = () => {
    onClose();
    remove();
  };

  return (
    <ClickAwayListener onClickAway={handleConfirm}>
      <Grid
        container
        direction={{ xs: "column", md: "row" }}
        spacing={2}
        sx={{
          alignItems: { xs: "stretch", md: "center" },
        }}
        wrap="nowrap"
      >
        <Grid item md xs={12}>
          <Grid container direction="column" spacing={2} sx={{ py: 2 }}>
            <Grid item>
              <Grid container spacing={2}>
                <Grid item md={4} xs={6}>
                  <RequirementSelect<FormFields, RequirementPath> requirements={requirementsData?.requirements} />
                </Grid>
                <Grid item md={4} xs={6}>
                  <RequirementSubjectSelect subjects={currentRequirement?.subjects} />
                </Grid>
                <Grid item md={4} xs={8}>
                  <OperatorSelectInput subject={currentSubject} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={2}
                sx={{
                  alignItems: "flex-end",
                }}
              >
                <Grid item md={3} xs={6}>
                  <RequirementPercentageSelect />
                </Grid>
                <Grid item md={6} xs={6}>
                  <RequirementExpirationInput />
                </Grid>
                <Grid
                  item
                  sx={{
                    display: { xs: "none", md: "block" },
                    textAlign: "right",
                  }}
                  xs
                >
                  <Button color="info" onClick={handleConfirm} size="medium" variant="outlined">
                    {t("Action.done")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md="auto" xs={12}>
          <Grid
            container
            direction="row"
            spacing={1}
            sx={{
              justifyContent: "flex-end",
            }}
          >
            <Grid
              item
              sx={{
                display: { md: "none", xs: "block" },
              }}
              xs
            >
              <Button color="info" onClick={handleConfirm} size="medium" variant="outlined">
                {t("Action.done")}
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title={t("Action.copy")}>
                <IconButton color="primary" onClick={copy} size="small">
                  <MdsIcon fontSize="small" icon={MdsContentCopy} sx={{ color: "primary.light" }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs="auto">
              <Tooltip title={t("Action.remove")}>
                <IconButton color="error" onClick={handleRemove} size="small">
                  <MdsIcon fontSize="small" icon={MdsDelete} sx={({ palette }) => ({ color: palette.error.main })} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ClickAwayListener>
  );
};
//FormFields
interface RequirementSelectProps {
  requirements?: RequirementsQuery["requirements"];
}

const RequirementSelect = <FormFields extends FieldValues, RequirementPath extends ArrayPath<FormFields>>({
  requirements,
}: RequirementSelectProps) => {
  const { t } = useTranslation();
  const { prefix, update } = useFieldArrayContext<FormFields, RequirementPath>();

  const requirementOptions: Option[] = useMemo(() => mapToOptions("id", "label")(requirements || []), [requirements]);

  const { field } = useMuiController({
    // defaultValue: "",
    name: `${prefix}requirementId`,
    required: true,
  });

  return (
    <AutocompleteInput
      {...field}
      disablePortal={false}
      label={t("Requirement.requirement")}
      onChange={(e) => {
        update((data) => ({ ...data, subject: "", operator: "", value: "" }));
        field.onChange(e);
      }}
      options={requirementOptions}
      placeholder={t("Requirement.enterRequirement")}
      size="small"
    />
  );
};

interface RequirementSubjectSelectProps {
  subjects?: RequirementsQuery["requirements"][0]["subjects"];
}

const RequirementSubjectSelect = <FormFields extends FieldValues, RequirementPath extends ArrayPath<FormFields>>({
  subjects,
}: RequirementSubjectSelectProps) => {
  const { t } = useTranslation();
  const { prefix, update } = useFieldArrayContext<FormFields, RequirementPath>();

  const options: Option[] = useMemo(() => (subjects ? mapToOptions("subject", "label")(subjects) : []), [subjects]);

  const {
    field: { inputRef, ...field },
  } = useMuiController({
    name: `${prefix}subject`,
    required: t("required"),
  });

  const ref = useAutofocusTarget({
    autofocusId: field.name,
    ref: inputRef,
  });

  useUpdateValue<FormFields>(
    field.name as Path<FormFields>,
    // @ts-ignore cause by unsure type of field
    () => (field.value ? field.value : (head(options)?.value ?? null)),
    [field.name, options, field.value]
  );

  const hasGroups = useMemo(() => Boolean(subjects?.find(({ group }) => isNotNil(group))), [subjects]);

  return (
    <AutocompleteInput
      {...field}
      ref={ref}
      disabled={!subjects}
      disablePortal={false}
      groupBy={
        hasGroups
          ? (option) => subjects?.find(({ subject }) => option.value === subject)?.group ?? t("other")
          : undefined
      }
      inputRef={inputRef}
      label={t("Requirement.type")}
      onChange={(e) => {
        update((data) => ({ ...data, operator: "", value: "" }));
        field.onChange(e);
      }}
      options={options}
      placeholder={t("Requirement.enterRequirementSubject")}
      size="small"
      value={field.value ?? null}
    />
  );
};

function getListValues(fieldValue: any | any[], isMultiple?: boolean | null) {
  const arrayValue = isMultiple ? ensureArray(fieldValue) : fieldValue;
  const emptyValue = isMultiple ? [] : null;
  return isNotNilOrEmpty(fieldValue) ? arrayValue : emptyValue;
}

const RequirementValueInput: FC<{
  subject?: SubjectFieldsFragment;
  onValidate: (invalid: boolean) => void;
}> = ({ subject, onValidate }) => {
  const { t } = useTranslation();
  const { prefix } = useFieldArrayContext();

  const selectedOperator = useWatch({ name: `${prefix}operator` });

  const hasValue = subject?.operators
    ? prop("hasValue", find(propEq("value", selectedOperator), subject?.operators))
    : null;

  const isMultiple = subject?.operators
    ? prop("isMultiple", find(propEq("value", selectedOperator), subject?.operators))
    : null;

  const {
    field: { error, errorMessage, ...field },
    fieldState,
  } = useMuiController({
    defaultValue: "",
    name: `${prefix}value`,
    required: hasValue ? t("required") : false,
    disabled: !hasValue,
  });

  useEffect(() => onValidate(fieldState.invalid), [fieldState.invalid, onValidate]);

  const focusRef = useAutofocusTarget(
    subject?.valueType === OperatorValueTypeEnum.List
      ? {
          autofocusId: field.name,
          ref: field.inputRef,
          focus: (selectRefObj) => {
            setTimeout(() => {
              selectRefObj?.current
                ?.querySelector("[role=button]")
                ?.dispatchEvent(new MouseEvent("mousedown", { bubbles: true, cancelable: false }));
            });
          },
        }
      : {
          autofocusId: field.name,
          ref: field.inputRef,
        }
  );
  const setFocus = useTriggerAutofocus();

  useEffect(() => {
    setFocus(field.name);
  }, [subject?.valueType]);

  useEffect(() => {
    if (!hasValue && field.value !== null) {
      field.onChange(null);
    }
  }, [field, hasValue]);

  switch (subject?.valueType) {
    case OperatorValueTypeEnum.Number:
      return (
        <PlainNumberInput
          {...field}
          disabled={!hasValue}
          inputRef={focusRef}
          size="small"
          type="number"
          value={field.value ?? ""}
        />
      );
    case OperatorValueTypeEnum.Boolean:
      return <InputBase {...field} disabled size="small" value={field.value ?? ""} />;
    case OperatorValueTypeEnum.List:
      return (
        <Select
          ref={focusRef}
          disabled={!hasValue}
          IconComponent={(iconProps) => <MdsIcon {...iconProps} fontSize="small" icon={MdsExpandMore} />}
          input={<InputBase />}
          MenuProps={{ disablePortal: true }}
          multiple={isMultiple ?? false}
          renderValue={(selected) => {
            // This part is only for fixing the issue of text overflow in select input
            const selectedOption = map(
              (item) =>
                find<{ value: string; label: string }>(propEq("value", item), subject?.possibleValues || [])?.label,
              ensureArray(selected)
            ).filter(isTruthy);
            return (
              <Box
                sx={{
                  textOverflow: "inherit",
                  whiteSpace: "inherit",
                  overflow: "inherit",
                }}
              >
                {selectedOption.join(", ")}
              </Box>
            );
          }}
          size="small"
          {...field}
          value={getListValues(field.value, isMultiple)}
        >
          {map(
            (option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ),
            subject?.possibleValues || []
          )}
        </Select>
      );
    default:
      return <InputBase {...field} disabled={!hasValue} inputRef={focusRef} size="small" value={field.value ?? ""} />;
  }
};

const PlainNumberInput = (props: InputBaseProps) => {
  const {
    toClosestLimit,
    toNumber,
    value: numberValue,
  } = useNumberInputValue({
    allowEmpty: true,
    onChange: props.onChange,
    value: props.value,
  });

  return (
    <InputBase
      {...props}
      onBlur={(e) => {
        props?.onBlur?.(e);
        e.target.value = toClosestLimit(e.target.value);
        props.onChange?.(e);
      }}
      onChange={(e) => {
        e.target.value = toNumber(e.target.value);
        props.onChange?.(e);
      }}
      type="text"
      value={numberValue}
    />
  );
};

const CombinedSelectInput = styled(SelectInput)<SelectInputProps>(() => ({
  paddingRight: 0,
  "&>.MuiSelect-icon": { position: "relative", right: 0 },
  "&>.MuiSelect-select.MuiOutlinedInput-input.MuiOutlinedInput-input": { width: "50%", paddingRight: 0 },
  "&>.MuiInputAdornment-root": {
    width: "100%",
    maxWidth: "50%",
    "& .MuiInputBase-root": { width: "calc(100% - 8px)" },
  },
}));

const OperatorSelectInput: FC<{
  subject?: SubjectFieldsFragment;
}> = ({ subject }) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const { prefix } = useFieldArrayContext();
  const [invalidOperator, setInvalidOperator] = useState(false);
  const [invalidValue, setInvalidValue] = useState(false);

  const operatorsOptions: Option[] = useMemo(
    () => (subject?.operators ? mapToOptions("value", "label")(subject?.operators) : []),
    [subject?.operators]
  );

  const {
    field: { errorMessage, ...field },
    fieldState,
  } = useMuiController({
    defaultValue: "",
    name: `${prefix}operator`,
    required: t("required"),
  });

  useEffect(() => setInvalidOperator(fieldState.invalid), [fieldState.invalid]);

  const ref = useAutofocusTarget({
    autofocusId: field.name,
    ref: field.inputRef,
  });

  const hasValue = subject?.operators ? prop("hasValue", find(propEq("value", field.value), subject?.operators)) : null;

  useUpdateValue<FieldValues>(
    field.name as Path<FieldValues>,
    () => (field.value ? field.value : (head(operatorsOptions)?.value ?? null)),
    [field.name, operatorsOptions, field.value]
  );

  return (
    <CombinedSelectInput
      disabled={!subject}
      endAdornment={
        <FullHeightAdornment
          position="end"
          sx={{ backgroundColor: ({ palette }) => (hasValue ? undefined : palette.grey[200]) }}
        >
          <Divider orientation="vertical" sx={{ mr: 1 }} />
          <RequirementValueInput onValidate={setInvalidValue} subject={subject} />
        </FullHeightAdornment>
      }
      errorMessage={invalidOperator || invalidValue ? [t("required")] : undefined}
      fullWidth
      label={t("Requirement.condition")}
      MenuProps={{ disablePortal: true }}
      size="small"
      {...field}
      ref={ref}
      error={invalidOperator || invalidValue}
      onChange={(e) => {
        field.onChange(e);
        setValue(`${prefix}value`, "");
      }}
      options={operatorsOptions}
      value={field.value ?? null}
    />
  );
};

const RequirementPercentageSelect: FC = () => {
  const { t } = useTranslation();
  const { prefix } = useFieldArrayContext();

  const {
    field: { value, onChange, ...field },
  } = useMuiController({ defaultValue: "1", name: `${prefix}percentage` });

  const ref = useAutofocusTarget({
    autofocusId: field.name,
    ref: field.inputRef,
  });

  const sanitizeNumber = (val: string | number | null): string | number | null => {
    // Special case for number input
    if (val && val !== "") {
      return String(forceBoundary(0, 100)(+String(val).replace(/[^\d.]/g, "")));
    }
    return val;
  };

  return (
    <AutocompleteInput
      {...field}
      disableClearable
      disablePortal={false}
      freeSolo
      inputRef={ref}
      inputValue={pipe(decimalToPercents, round, String)(value)}
      label={t("NewJobForm.requiredFor")}
      onChange={(arg) => when(isNotNil, pipe(percentsToDecimal, String, onChange), sanitizeNumber(arg))}
      onInputChange={(_, val) => when(isNotNil, pipe(percentsToDecimal, String, onChange), sanitizeNumber(val))}
      openOnFocus
      options={percentageOptions}
      size="small"
      textFieldProps={{
        type: "number",
        inputProps: { min: 0, max: 100 },
        InputProps: { endAdornment: <InputAdornment position="start">%</InputAdornment> },
      }}
      value={pipe(decimalToPercents, round, String)(value)}
    />
  );
};

const RequirementExpirationInput: FC = () => {
  const { t } = useTranslation();
  const { prefix } = useFieldArrayContext();
  const {
    field: { onChange, ...field },
  } = useMuiController({
    defaultValue: 0,
    name: `${prefix}expiration`,
  });

  const ref = useAutofocusTarget({
    autofocusId: field.name,
    ref: field.inputRef,
  });

  return (
    <Grid container>
      <Grid item xs>
        <TextInput
          {...field}
          endAdornment={<InputAdornment position="end">{t("hours")}</InputAdornment>}
          inputProps={{ min: 0 }}
          inputRef={ref}
          label={t("NewJobForm.expires")}
          onChange={(event) => onChange(+event.target.value)}
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <MdsIcon fontSize="xs" icon={MdsTimer} />
            </InputAdornment>
          }
          type="number"
        />
      </Grid>
      <Grid item sx={{ alignSelf: "flex-end", display: "flex", height: "32px", alignItems: "center", pl: 1 }} xs>
        <Typography sx={{ color: ({ palette }) => palette.grey[500] }}>{t("NewJobForm.beforeShiftStart")}</Typography>
      </Grid>
    </Grid>
  );
};
