import { Grid } from "@mui/material";
import { FILE_STATES } from "@rpldy/shared";
import { noop } from "ramda-adjunct";
import React, { FC } from "react";
import { FileItem, PreviewItemProps } from "../types";
import { FileChip } from "./FileChip";

export type PreviewCardProps = FileItem &
  PreviewItemProps & {
    disabled?: boolean;
  };

/**
 * Preview item of file
 */
export const PreviewCard: FC<PreviewCardProps> = ({ disabled, name, removePreview, size, state, progress }) => (
  <Grid item xs={4}>
    <FileChip
      disabled={disabled}
      finished={state === FILE_STATES.FINISHED}
      name={name}
      onDelete={removePreview ?? noop}
      percent={progress ?? 0}
      size={size}
    />
  </Grid>
);
