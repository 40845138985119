import { capitalize } from "@sinch/utils/string";
import { prop, reduce } from "ramda";
import React, { createContext, FC, PropsWithChildren, useCallback, useContext } from "react";
import { navigationPaths } from "../config";

interface AppNavigationProps {
  onNavigate: (path: string | NavigationPathsKeys, params?: Record<string, string>) => boolean;
}

type RouteParams<T extends string> = string extends T
  ? Record<string, string>
  : // eslint-disable-next-line @typescript-eslint/no-unused-vars
    T extends `${infer _Start}:${infer Param}/${infer Rest}`
    ? Param extends ""
      ? Record<string, string>
      : { [k in Param | keyof RouteParams<`/${Rest}`>]: string }
    : // eslint-disable-next-line @typescript-eslint/no-unused-vars
      T extends `${infer _Start}:${infer Param}#${infer Rest}`
      ? Param extends ""
        ? Record<string, string>
        : { [k in Param | keyof RouteParams<`#${Rest}`>]: string }
      : // eslint-disable-next-line @typescript-eslint/no-unused-vars
        T extends `${infer _Start}:${infer Param}`
        ? Param extends ""
          ? Record<string, string>
          : { [k in Param]: string }
        : Record<string, string>;

type FunctionMap<T extends Record<string, string>> = {
  [K in keyof T as `${K & string}Path`]: (arg: RouteParams<T[K]>) => string;
} & {
  [K in keyof T as `navigateTo${Capitalize<K & string>}`]: (arg: RouteParams<T[K]>) => () => void;
};

export type NavigationPaths = FunctionMap<typeof navigationPaths>;
export type NavigationPathsKeys = keyof typeof navigationPaths;

/**
 * Create context for easy access to global settings of an app
 */
export const ContextSettings = createContext<AppNavigationProps>({
  onNavigate: (path, params) => {
    window.location.href = replacePlaceholders(prop(path, navigationPaths) ?? path, params ?? {});
    return false;
  },
});

/**
 * Return list of application settings.
 */
export const useAppNavigationContext: () => AppNavigationProps = () => useContext(ContextSettings);

/**
 * Provide context for application settings
 */
export const AppNavigationProvider: FC<PropsWithChildren<Partial<AppNavigationProps>>> = ({ onNavigate, children }) => {
  const parentContext = useContext(ContextSettings);
  const handleNavigate = useCallback<AppNavigationProps["onNavigate"]>(
    (path, params) => {
      if (!onNavigate) {
        return parentContext.onNavigate(path, params);
      }
      if (onNavigate(path, params)) {
        return parentContext.onNavigate(path, params);
      }
      return false;
    },
    [onNavigate]
  );
  return <ContextSettings.Provider value={{ onNavigate: handleNavigate }}>{children}</ContextSettings.Provider>;
};

/**
 * Custom hook to generate navigation paths and functions.
 *
 * @returns {NavigationPaths} An object containing path and navigation functions.
 */
export const useAppNavigate = (): NavigationPaths => {
  const { onNavigate } = useAppNavigationContext();

  return reduce(
    (list, [key, value]) => ({
      ...list,
      [`${key}Path`]: (params: RouteParams<keyof typeof navigationPaths>) => replacePlaceholders(value, params),
      [`navigateTo${capitalize(key)}`]: (params: RouteParams<keyof typeof navigationPaths>) => (e: PointerEvent) => {
        e.preventDefault();
        onNavigate(key, params);
      },
    }),
    {} as NavigationPaths,
    Object.entries(navigationPaths)
  );
};

/**
 * Replace placeholders in a string with corresponding values from an object.
 *
 * @param {string} str - The string containing placeholders.
 * @param {Record<string, string>} placeholders - An object with placeholder values.
 * @returns {string} The string with replaced placeholders.
 */
const replacePlaceholders = (str: string, placeholders: Record<string, string>) =>
  Object.entries(placeholders).reduce((acc, [key, value]) => acc.replace(`:${key}`, value), str);

export const isLink = <T extends NavigationPathsKeys>(
  navigationPathKey: T,
  path: NavigationPathsKeys | string,
  params?: Record<string, string> | undefined
): params is RouteParams<(typeof navigationPaths)[T]> => path === navigationPathKey;
