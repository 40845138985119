import { LoadScriptProps, useJsApiLoader } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "@sinch/core";

// Define the extra libraries to be loaded with the Google Maps API
const EXTRA_LIBRARIES: LoadScriptProps["libraries"] = ["places", "routes", "geometry"];

/**
 * Custom hook to load the Google Maps API.
 * Please use this hook instead of `useJsApiLoader` directly so map api can be loaded only once
 */
export const useGoogleMapsApiLoad = (apiKey?: string) =>
  useJsApiLoader({
    id: "google-dynamic-maps", // Unique identifier for the script tag
    googleMapsApiKey: apiKey || GOOGLE_MAPS_API_KEY || "", // Use provided API key or fallback to default
    libraries: EXTRA_LIBRARIES, // Load additional libraries
  });
