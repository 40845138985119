import { Stack } from "@mui/material";
import React, { FC } from "react";
import { StaffFragment } from "../queries.types";
import { HighlightedAttributes } from "./HighlightedAttributes.tsx";
import { Personal } from "./Personal";
import { PersonalAttributes } from "./PersonalAttributes";
import { Wallet } from "./Wallet";

interface PersonalTabProps {
  staff: StaffFragment;
}

export const PersonalTab: FC<PersonalTabProps> = ({ staff }) => (
  <Stack spacing={1}>
    <Personal staff={staff} />
    <PersonalAttributes staff={staff} />
    <HighlightedAttributes staff={staff} />
    <Wallet staff={staff} />
  </Stack>
);
