import { Alert, AlertTitle, Box, lighten, Stack } from "@mui/material";
import { MdsWorkspacePremium } from "@sinch/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "@sinch/components/MdsIcon/MdsIcon";

/**
 * PremiumNoticeAlert Component
 *
 * A component that displays a premium feature notice alert with a purple theme.
 * The alert includes a premium icon, a title and a description text.
 *
 */
export const PremiumNoticeAlert: FC = () => {
  const { t } = useTranslation();
  return (
    <Alert
      icon={<MdsIcon icon={MdsWorkspacePremium} sx={({ palette }) => ({ color: palette.custom.purple["500"] })} />}
      severity="info"
      sx={({ palette }) => ({ backgroundColor: lighten(palette.custom.purple["500"], 0.8) })}
      variant="standard"
    >
      <Stack>
        <AlertTitle>{t("App.premiumFeatureTitle")}</AlertTitle>
        <Box>{t("App.premiumFeatureDescription")}</Box>
      </Stack>
    </Alert>
  );
};
