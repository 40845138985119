import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { firstNonEmpty } from "@sinch/utils/array";
import { either, isEmpty, isNil, reject } from "ramda";
import React, { FC } from "react";
import { DistanceInfo } from "@sinch/components/application/PlaceSelect/DistanceInfo";
import { PlacesQuery } from "@sinch/components/application/PlaceSelect/query.types";

export interface AddressInfoProps {
  currentSelectedPlace: PlacesQuery["places"][0];
  distanceInfo?: {
    distance: number | null;
    duration: number | null;
  };
}

export const AddressInfo: FC<AddressInfoProps> = ({
  currentSelectedPlace: { address, city, country, note, zip },
  distanceInfo,
}) => (
  <Stack
    spacing={1}
    sx={{ position: "relative", bottom: 0, height: "100%", color: ({ palette }) => palette.grey[800] }}
  >
    {address && (
      <Typography
        sx={{
          fontSize: "0.9375rem",
        }}
      >
        {address}
      </Typography>
    )}
    {firstNonEmpty([city, zip]) && (
      <Typography
        sx={{
          fontSize: "0.9375rem",
        }}
      >
        {reject(either(isNil, isEmpty), [city, zip]).join(", ")}
      </Typography>
    )}
    {country && (
      <Typography
        sx={{
          fontSize: "0.9375rem",
        }}
      >
        {country}
      </Typography>
    )}
    {note && (
      <Typography
        sx={{
          fontSize: "0.8125rem",
        }}
      >
        {note}
      </Typography>
    )}
    {distanceInfo && <DistanceInfo {...distanceInfo} />}
  </Stack>
);
