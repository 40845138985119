import { alpha, MenuItem, Typography } from "@mui/material";
import { TransportAttendanceRole } from "@sinch/core/entities/serverEnums";
import React, { FC } from "react";
import { SelectInput, SelectInputProps } from "../../../form/SelectInput/SelectInput";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { useTransportRole } from "../hooks";

type TransportRoleInputProps = Omit<SelectInputProps<TransportAttendanceRole>, "defaultValue" | "ref" | "variant">;

export const TransportRoleInput: FC<TransportRoleInputProps> = (params) => {
  const { getTransportRoleColor, getTransportRoleLabel, getTransportRoleIcon, getSortedTransportRole } =
    useTransportRole();

  return (
    <SelectInput<TransportAttendanceRole>
      {...params}
      disabled={params.disabled}
      renderValue={(selected: TransportAttendanceRole) => (
        <>
          <MdsIcon
            fontSize="small"
            icon={getTransportRoleIcon(selected)}
            sx={(theme) => ({
              color: params.disabled ? theme.palette.grey["800"] : getTransportRoleColor(selected),
              marginRight: theme.spacing(1),
            })}
          />
          <Typography
            sx={{
              color: getTransportRoleColor(selected),
            }}
          >
            {getTransportRoleLabel(selected)}
          </Typography>
        </>
      )}
      size="small"
      sx={() => {
        const color = getTransportRoleColor(params.value as TransportAttendanceRole);
        if (params.value && color) {
          return {
            backgroundColor: params.value !== TransportAttendanceRole.NoTransport ? alpha(color, 0.2) : "",
            color,
          };
        }
        return {};
      }}
    >
      {getSortedTransportRole().map((val) => (
        <MenuItem key={val} value={val}>
          <MdsIcon
            fontSize="small"
            icon={getTransportRoleIcon(val)}
            sx={(theme) => ({ color: getTransportRoleColor(val), marginRight: theme.spacing(1) })}
          />
          {getTransportRoleLabel(val)}
        </MenuItem>
      ))}
    </SelectInput>
  );
};
