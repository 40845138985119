import { isNil, values } from "ramda";
import { FieldPath, FieldValues, UseControllerReturn } from "react-hook-form";
import { MuiControllerObject } from "./types";

/**
 * Adapt Controller (useController return value) to be compatible with MUI components
 */
export const toMuiFormat = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  controller: UseControllerReturn<TFieldValues, TName>,
  required?: boolean,
  disabled?: boolean
): MuiControllerObject<TFieldValues, TName> => {
  const { field, fieldState, formState } = controller;
  const { ref, ...fieldProps } = field;

  return {
    field: {
      inputRef: ref,
      error: !isNil(fieldState.error),
      errorMessage: values(fieldState.error?.types ?? {}) as string[],
      required,
      disabled,
      ...fieldProps,
    },
    fieldState,
    formState,
  };
};
