import { UploadyProps } from "@rpldy/shared-ui";
import { PreviewType } from "@rpldy/upload-preview";
import { Batch, BatchItem, CreateOptions, UPLOADER_EVENTS, Uploady } from "@rpldy/uploady";
import { useSnackbar } from "notistack";
import { noop } from "ramda-adjunct";
import React, { FC, PropsWithChildren, useContext, useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { FileInputItems, FileItem, QueueItems } from "./types";

interface UploadyContextType {
  unsubscribeDispatcher: (name: string) => void;
  subscribeDispatcher: (
    name: string,
    value: FileItem[],
    onChange: (file: FileItem[]) => void,
    onError?: (message?: string) => void
  ) => void;
}

const UploadyContext = React.createContext<UploadyContextType | undefined>(undefined);
UploadyContext.displayName = "Uploady";

type UploadyProviderProps = PropsWithChildren &
  UploadyProps & {
    /**
     * Called when some upload start, do not trigger when other upload is in progress.
     */
    onFinish?: () => void;
    /**
     * Called when all uploads are finished.
     */
    onStart?: () => void;
  };

const batchItemToFileItem = (item: BatchItem): FileItem => ({
  id: item.uploadResponse?.data?.hash ?? item.id,
  url: item.uploadResponse?.data?.url || URL.createObjectURL(item.file as unknown as MediaSource),
  name: item.file.name,
  type: item.file.type as PreviewType,
  size: item.file.size,
  lastModified: item.file.lastModified,
  state: item.state,
  file: item.file as File,
});

/**
 * Register set of input componets and their values and callbacks.
 */
const useUploaderInput = () => {
  const fileInputStore = useRef<FileInputItems>(new Map());
  const getUploader = (name: string | undefined) => (name ? fileInputStore.current.get(name) : undefined);
  return {
    get: getUploader,
    set: fileInputStore.current.set.bind(fileInputStore.current),
  };
};

/**
 * Hold set of all uploaded items and their associated input components.
 */
const useQueueItem = () => {
  const queueItem = useRef<QueueItems>(new Map());
  const getInputNameByItemId = (id: string | undefined) => (id ? queueItem.current.get(id) : undefined);
  return {
    get: getInputNameByItemId,
    set: queueItem.current.set.bind(queueItem.current),
    delete: queueItem.current.delete.bind(queueItem.current),
    getSize: () => queueItem.current.size,
  };
};

/**
 * Provide context for uploading files even when input component is unmounted.
 * Component accept Uploady props, but suggest to not use thame in favor of setting uploady pros at level of individual upload input components.
 *
 * ### How it works?
 * Input component register their value and onChange callback in context. This means that context hold onChange method even when component is unmounted.
 * When file is uploaded, context call onChange method of input component and pass new value.
 * Values are stored in context so it can be updated instead of rewritten.
 * When file is uploaded successfully or with error and input component is unmounted, it show snackbar notification.
 */
export const UploadyProvider: FC<UploadyProviderProps> = ({ children, onFinish, onStart, ...props }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { get: getUploader, set: setUploader } = useUploaderInput();
  const { get: getQueueItem, set: setQueueItem, delete: deleteQueueItem, getSize } = useQueueItem();

  /**
   * Update uploader value and call onChange callback.
   */
  const updateItem = (item: BatchItem) => {
    const name = getQueueItem(item.id);
    if (name && getUploader(name)) {
      const newValues = getUploader(name)?.value.map((file) => {
        if (file.id === item.id) {
          return batchItemToFileItem(item);
        }
        return file;
      });
      setUploader(name!, {
        ...getUploader(name)!,
        value: newValues ?? [],
      });
      getUploader(name)?.onChange(newValues ?? []);
    }
  };

  /**
   * Listeners for handling change of state of uploaded file.
   */
  const listeners = useMemo(
    () => ({
      /**
       * At the adding of file, call onChange callback for file even they are not uploaded yet.
       */
      [UPLOADER_EVENTS.BATCH_ADD]: (batch: Batch, context: CreateOptions) => {
        const uploader = getUploader(context.params?.name as string);
        if (uploader && context.params?.name) {
          if (getSize() <= 0) {
            onStart?.();
          }
          for (const item of batch.items) {
            setQueueItem(item.id, context.params?.name as string);
          }

          getUploader(context.params?.name as string)?.onChange([
            ...(uploader.value || []),
            ...batch.items.map(batchItemToFileItem),
          ]);
        }
      },
      /**
       * When file is uploaded, update value of input component. Check if input is mounted if not show snackbar notification.
       */
      [UPLOADER_EVENTS.ITEM_FINISH]: (item: BatchItem) => {
        updateItem(item);
        const name = getQueueItem(item.id);
        if (name && getUploader(name) && !getUploader(name)?.mounted) {
          enqueueSnackbar(t(`fileUploadSuccess`, { filename: item.file.name }), { variant: "success" });
        }
      },
      /**
       * When upload is thrown and input is not mounted, show snackbar notification.
       */
      [UPLOADER_EVENTS.ITEM_ERROR]: (item: BatchItem) => {
        const name = getQueueItem(item.id);
        if (name && getUploader(name)) {
          const newValues = getUploader(name)?.value.filter((file) => file.id !== item.id);
          setUploader(name!, {
            ...getUploader(name)!,
            value: newValues ?? [],
          });
          getUploader(name)?.onChange(newValues ?? []);
          getUploader(name)?.onError?.(item.uploadResponse?.data?.message);
        }
        enqueueSnackbar(t(`fileUploadFailed`, { filename: item.file.name }), { variant: "error" });
      },
      /**
       * Clean item from queue
       */
      [UPLOADER_EVENTS.ITEM_FINALIZE]: (item: BatchItem) => {
        if (getQueueItem(item.id)) {
          deleteQueueItem(item.id);
        }
        if (getSize() <= 0) {
          onFinish?.();
        }
      },
    }),
    []
  );

  /**
   * Register input component in context.
   */
  const subscribeDispatcher = (
    name: string,
    value: FileItem[],
    onChange: (file: FileItem[]) => void,
    onError?: (message?: string) => void
  ) => {
    setUploader(name, {
      value,
      onChange,
      mounted: true,
      onError,
    });
  };

  /**
   * Set mounted/unmounted flag of uploader input component to false when input is unmounted.
   * @param name
   */
  const unsubscribeDispatcher = (name: string) => {
    if (getUploader(name)) {
      getUploader(name)!.mounted = false;
    }
  };

  return (
    <Uploady listeners={listeners} {...props}>
      <UploadyContext.Provider
        value={{
          unsubscribeDispatcher,
          subscribeDispatcher,
        }}
      >
        {children}
      </UploadyContext.Provider>
    </Uploady>
  );
};

interface UseUploadStoreProps {
  /**
   * Id of component
   */
  name: string;
  /**
   * Called when file is uploaded
   */
  onUploaded: (file: FileItem[]) => void;
  /**
   * Value of upload input
   */
  value: FileItem[];
  /**
   * Called when error is thrown
   */
  onError?: (message?: string) => void;
}

/**
 * Register UploadInput component for handling upload of files asynchronously (files are uploaded when component is unmounted)
 */
export function useUploadStore({ name, value, onUploaded, onError = noop }: UseUploadStoreProps) {
  const uploadyContext = useContext(UploadyContext);
  if (!uploadyContext) {
    throw new Error("UploadyContext is not provided");
  }
  const { unsubscribeDispatcher, subscribeDispatcher } = uploadyContext;

  subscribeDispatcher(name, value, onUploaded, onError);

  useEffect(() => () => unsubscribeDispatcher(name), []);
}
