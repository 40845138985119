import { ComponentProps, FC } from "react";

const DEFAULT_SELECTOR_PREFIX = ".react-entrypoint";

/**
 * View loader hold list of app components loaded into page. These components are then subscribed by app.
 * Implements Observer pattern
 */

export interface ViewComponentDefinition<T = any> {
  /**
   * Css selector for use with querySelectorAll() method
   */
  selector: string;

  /**
   * React function component for render
   */
  component: FC<T>;

  /**
   * Id of component
   */
  id?: string;
}
export type ViewComponentHandler = <T>(components: Required<ViewComponentDefinition<T>>[]) => void;

const components: Required<ViewComponentDefinition>[] = [];
const handlers: ViewComponentHandler[] = [];

/**
 * Call subscribed handlers of components loader
 */
function publishComponents() {
  handlers.forEach((item) => {
    item.call(null, components);
  });
}

/**
 * Register component for loader
 */
export function registerComponent<T>(component: ViewComponentDefinition<T>) {
  components.push({ ...component, id: `app-component-${components.length}` });
  publishComponents();
}

/**
 * Register handler for loading components
 */
export function subscribeComponents(fn: ViewComponentHandler) {
  handlers.push(fn);
  publishComponents();
}

/**
 * Register app component to load. Component is rendered immediately after runtime load or registration of the component
 */
export const loadViewComponent = <TViewProps extends ComponentProps<any> = ComponentProps<any>>(
  component: FC<TViewProps>,
  selector?: string
) => {
  if (import.meta.env.MODE === "development") {
    // eslint-disable-next-line no-console
    console.info(
      `Registering component "${component.name}" with selector "${
        selector || `${DEFAULT_SELECTOR_PREFIX}-${component.name}`
      }"`
    );
  }
  registerComponent({ selector: selector || `${DEFAULT_SELECTOR_PREFIX}-${component.name}`, component });
};
