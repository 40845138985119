import { Stack, styled, Typography, TypographyProps } from "@mui/material";
import React, { FC, PropsWithChildren, ReactNode } from "react";

export interface PropertyLabelProps extends PropsWithChildren {
  label: ReactNode;
  labelProps?: TypographyProps;
}

export const PropertyLabel: FC<PropertyLabelProps> = ({ label, labelProps, children }) => (
  <Stack>
    <SmallLabel {...labelProps} variant="descriptionTinyBold">
      {label}
    </SmallLabel>
    <Typography variant="descriptionSmall">{children}</Typography>
  </Stack>
);

const SmallLabel = styled(Typography)<TypographyProps>(({ theme }) => ({
  paddingTop: theme.spacing(1),
}));
