import { lighten, styled } from "@mui/material";
import { includeIf } from "@sinch/utils/object";
import React, { PropsWithChildren } from "react";
import { useAutofillField } from "@sinch/components/Autofill/AutofillProvider";

export const AutofillHighlightField = ({ name, children }: PropsWithChildren<{ name: string }>) => {
  const { isFilled } = useAutofillField({ name });
  return <HighlightField in={isFilled}>{children}</HighlightField>;
};
export const HighlightField = styled("div", { shouldForwardProp: (field) => field !== "in" })<{ in: boolean }>(
  ({ theme, in: animationIn }) => ({
    outline: `4px solid transparent`,
    borderRadius: `4px !important`,
    backgroundColor: "transparent",
    ...includeIf(animationIn, { animation: `blink 1.2s ease-in-out` }),
    "@keyframes blink": {
      "0%": {
        backgroundColor: "transparent",
        outlineColor: "transparent",
      },
      "10%": {
        backgroundColor: lighten(theme.palette.info.main, 0.8),
        outlineColor: lighten(theme.palette.info.main, 0.8),
      },
      "100%": {
        backgroundColor: "transparent",
        outlineColor: "transparent",
      },
    },
  })
);
