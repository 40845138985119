import { isNil } from "ramda";
import React from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../AutocompleteInput/AutocompleteInput";
import { AutocompleteInputProps } from "@sinch/components/form/AutocompleteInput/types";

interface BinarySelectProps extends Omit<AutocompleteInputProps, "options" | "onChange" | "value" | "ref"> {
  onChange: (value: boolean | null) => void;
  value: boolean | null | undefined;
}

export const BinarySelect: React.FC<BinarySelectProps> = ({ ...field }) => {
  const { t } = useTranslation();

  const options = [
    { value: 1, label: t("yes") },
    { value: 0, label: t("no") },
  ];

  return (
    <AutocompleteInput
      includeInputInList
      openOnFocus
      placeholder={t("all")}
      size="small"
      {...field}
      onChange={(val) => field.onChange?.(isNil(val) ? null : !!val)}
      options={options}
      value={isNil(field.value) ? null : Number(field.value)}
    />
  );
};
