import { DateTimeFormatOptions } from "luxon";

/**
 * Convert Intl format setting to luxon format setting, used for correctly set formatting with localisation for date inputs
 */
export const intlToLuxonFormat = <TOptionsKey extends keyof DateTimeFormatOptions = keyof DateTimeFormatOptions>(
  locale: string,
  options: { [key in TOptionsKey]: DateTimeFormatOptions[TOptionsKey] },
  mapper: Record<TOptionsKey, string>
) => {
  const parts = new Intl.DateTimeFormat(locale ?? "en-GB", options).formatToParts(0);
  const format: string[] = [];
  parts.forEach((item) => {
    if (Object.prototype.hasOwnProperty.call(mapper, item.type)) {
      format.push(mapper[item.type.toString() as TOptionsKey]);
    }
    if (item.type === "literal") {
      const valueToBePushed = item.value.match(/^[ :,.]+$/g) ? item.value : `'${item.value}'`;
      format.push(valueToBePushed);
    }
  });

  return format.join("");
};
