import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";

import { useMuiForm } from "@sinch/hooks/useMuiForm";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { FC } from "react";
import { FormProvider, useFormState } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { DialogCloseIcon } from "../../DialogCloseIcon/DialogCloseIcon";
import { LinkedPositionsCheckbox } from "./LinkedPositionsCheckbox";
import { PaymentMethodSelect } from "./PaymentMethodSelect";
import { RequireConfirmationCheckbox } from "./RequireConfirmationCheckbox";
import { signInDialogFormSchema } from "./signInDialogFormSchema";
import { SignInDialogFormFields, SignInDialogProps } from "./types";

/**
 * Dialog for selecting worker contract before join to shift
 */
export const SignInDialog: FC<SignInDialogProps> = ({
  workerId,
  paymentMethod,
  onChange,
  onClose,
  workerName,
  positionName,
  linkedPositions,
  attendanceConfirmation,
}) => {
  const { t } = useTranslation();

  const form = useMuiForm<SignInDialogFormFields>({
    schema: signInDialogFormSchema({
      attendanceConfirmation: !!attendanceConfirmation,
      includePaymentMethod: !!paymentMethod,
    }),
  });

  const handleSubmit = () => {
    onChange(form.getValues());
    onClose();
  };

  return (
    <FormProvider {...form}>
      <Dialog maxWidth="sm" onClose={onClose} open>
        <DialogTitle>
          {t("SignInDialog.addStaffToPosition")}
          <DialogCloseIcon onClick={onClose} />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            {paymentMethod && (
              <PaymentMethodSelect
                effectiveDate={paymentMethod.effectiveDate}
                positionName={positionName}
                workerId={workerId}
                workerName={workerName}
              />
            )}
            <RequireConfirmationCheckbox />
            {isNotNilOrEmpty(linkedPositions) && (
              <LinkedPositionsCheckbox
                linkedPositions={linkedPositions}
                positionName={positionName}
                workerName={workerName}
              />
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <DialogActionButtons onClose={onClose} onSubmit={handleSubmit} />
        </DialogActions>
      </Dialog>
    </FormProvider>
  );
};

interface DialogActionsComponentProps {
  onClose: () => void;
  onSubmit: () => void;
}

export const DialogActionButtons: FC<DialogActionsComponentProps> = ({ onClose, onSubmit }) => {
  const { t } = useTranslation();

  const { isValid } = useFormState();

  return (
    <>
      <Button onClick={onClose} variant="text">
        {t("cancel")}
      </Button>
      <Button disabled={!isValid} onClick={onSubmit} variant="contained">
        {t("confirm")}
      </Button>
    </>
  );
};
