import { SvgIconProps, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { MdsInfoXs } from "@sinch/icons";
import React, { FC, ReactNode } from "react";
import { MdsIcon } from "../../MdsIcon/MdsIcon";

export interface InfoTooltipProps extends Omit<SvgIconProps, "icon" | "ref"> {
  /**
   * Title of tooltip
   */
  title?: ReactNode;
}

/**
 * Info icon with tooltip message
 */
export const InfoTooltip: FC<InfoTooltipProps> = ({ title, sx, ...props }) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  return (
    <Tooltip arrow enterTouchDelay={0} placement={isMobile ? "top" : "right"} title={title}>
      <MdsIcon fontSize="xs" icon={MdsInfoXs} {...props} sx={{ color: ({ palette }) => palette.grey[500], ...sx }} />
    </Tooltip>
  );
};
