import { Stack, StackProps, styled } from "@mui/material";
import React, { FC, PropsWithChildren } from "react";

export const Toolbar: FC<PropsWithChildren> = ({ children }) => (
  <TextEditorToolbar alignItems="center" direction="row" spacing={1}>
    {children}
  </TextEditorToolbar>
);

const TextEditorToolbar = styled(Stack)<StackProps>(({ theme }) => ({
  backgroundColor: theme.palette.grey["100"],
  borderTopLeftRadius: `${theme.shape.borderRadius}px !important`,
  borderTopRightRadius: `${theme.shape.borderRadius}px !important`,
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  flexWrap: "wrap",
}));
