import { useRef } from "react";

/**
 * Trigger pointer up only when there was no movement of mouse
 */
export const usePointerUpNoMovement = (handler: (event: any) => void) => {
  const moved = useRef(false);

  const handlePointerMove = () => (moved.current = true);
  const handlePointerDown = () => (moved.current = false);
  const handlePointerUp = (event: any) => {
    if (!moved.current) {
      handler(event);
    }
  };

  return { onPointerUp: handlePointerUp, onPointerDown: handlePointerDown, onPointerMove: handlePointerMove };
};
