import { Grid, Link, Stack, Typography } from "@mui/material";

import { useFormat } from "@sinch/core/hooks/format";
import { useAppNavigate } from "@sinch/core/providers";
import { File, Maybe, Worker, Workerinfo } from "@sinch/types/sinch.types";
import React, { FC } from "react";
import { UserAvatar } from "../UserAvatar/UserAvatar";
import { useWorker } from "./hooks";
import { WorkerRating } from "@sinch/components/WorkerRating/WorkerRating";

export interface WorkerListItemProps {
  worker: Pick<Worker, "id" | "name" | "surname" | "phone"> & { workerinfo?: Maybe<Pick<Workerinfo, "rating">> } & {
    avatar?: Maybe<Pick<File, "url">>;
  };
  /**
   * This rating will be added to staff member rating
   */
  expectedRating?: number;
}

/**
 * Row with base information of staff member
 */
export const WorkerListItem: FC<WorkerListItemProps> = ({ worker, expectedRating }) => {
  const { phone } = useFormat();
  const { getWorkerName } = useWorker();
  const { workerDetailPath, navigateToWorkerDetail } = useAppNavigate();
  return (
    <Grid
      columnSpacing={0.5}
      container
      rowSpacing={0}
      sx={{
        alignItems: "center",
      }}
    >
      <Grid item xs="auto">
        <UserAvatar
          sx={{
            height: 24,
            width: 24,
            fontSize: "0.75rem",
          }}
          worker={worker}
        />
      </Grid>
      <Grid item xs>
        <Stack>
          <Typography
            sx={{
              whiteSpace: "nowrap",
            }}
            variant="descriptionSmallSemi"
          >
            <Link
              href={workerDetailPath({ id: worker.id })}
              onClick={navigateToWorkerDetail({ id: worker.id })}
              sx={{ "&, &:hover": { color: "inherit" } }}
              target="_blank"
            >
              {getWorkerName(worker)}
            </Link>{" "}
            (
            <WorkerRating rating={Number(worker.workerinfo?.rating)} />
            /
            <WorkerRating rating={Math.trunc(Number(expectedRating) + Number(worker.workerinfo?.rating))} />)
          </Typography>
          {worker.phone && <Typography variant="descriptionTiny">{phone.national(worker.phone)}</Typography>}
        </Stack>
      </Grid>
    </Grid>
  );
};
