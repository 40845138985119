import { InputAdornment, InputAdornmentProps, OutlinedInputProps } from "@mui/material";
import { DatePickerProps } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { useSettings } from "@sinch/core/providers/AppSettings";
import { MdsCalendarTodayXs, MdsCloseXs } from "@sinch/icons";
import type { OverwriteProps } from "@sinch/types/utility.types";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import { DateTime } from "luxon";
import React, { ElementType, FC, forwardRef, useState } from "react";
import { MdsIcon } from "../../MdsIcon/MdsIcon";

type Props = OverwriteProps<
  DatePickerProps<DateTime>,
  {
    value?: Date | null;
    /**
     * Maxium date user can select
     */
    maxDate?: Date | null;
    /**
     * Minimum date user can select
     */
    minDate?: Date | null;
    /**
     * Add clear input icon button
     */
    clearable?: boolean;
    onChange: (value: Date | null) => void;
  } & Pick<OutlinedInputProps, "error" | "required" | "fullWidth" | "inputProps" | "size">
>;

const isValidDateOrNull = (date: Date | undefined | null) => (date && date.toString() !== "Invalid Date" ? date : null);

export const DateInputLuxonAdapter: FC<Props> = ({ value, minDate, maxDate, onChange, error, ...props }) => {
  const { timeZone } = useSettings();
  const [open, setOpen] = useState(false);

  const toZone = (date: DateTime | undefined) => date?.setZone(timeZone);

  return (
    <DatePicker
      {...props}
      closeOnSelect
      maxDate={maxDate ? toZone(toLuxon(maxDate)) : undefined}
      minDate={minDate ? toZone(toLuxon(minDate)) : undefined}
      onChange={(val) => onChange(val && val.isValid ? val.toJSDate() : null)}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      slotProps={{
        switchViewButton: { ...props.inputProps?.switchViewButton },
        field: {
          clearable: props.clearable,
        },
        textField: {
          size: props.size,
          error: error,
          onClick: () => setOpen(!open),
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <MdsIcon fontSize="xs" icon={MdsCalendarTodayXs} />
              </InputAdornment>
            ),
          },
          inputProps: { ...props.inputProps?.dateInput },
        },
      }}
      slots={{
        clearButton: (clearButtonProps) => (
          <MdsIcon {...clearButtonProps} fontSize="xs" icon={MdsCloseXs} sx={{ cursor: "pointer" }} />
        ),
        inputAdornment: DatePickerAdornment as ElementType<InputAdornmentProps>,
      }}
      value={toZone(toLuxon(isValidDateOrNull(value)) ?? undefined) ?? null}
    />
  );
};
export type DateInputLuxonAdapterProps = Props;

const DatePickerAdornment = forwardRef<HTMLDivElement, InputAdornmentProps>(({ children, ...props }, ref) => (
  // This is hack to make date picker open faster, otherwise it has some issues, don't know why
  <InputAdornment {...props} ref={ref} style={{ width: 0, margin: 0, padding: 0 }} />
));
DatePickerAdornment.displayName = "DatePickerAdornment";
