import { Box, Button, Popover, PopoverProps, Stack, styled } from "@mui/material";
import { MdsCheckXs, MdsErrorXs, MdsWarningXs } from "@sinch/icons";
import { isNotNil } from "ramda-adjunct";
import React, { PropsWithChildren, ReactElement, useEffect, useRef } from "react";
import { MdsIcon } from "../MdsIcon/MdsIcon";

export enum AlertPopoverType {
  Regular,
  Warning,
  Error,
}

export interface AlertPopoverProps<T extends Element> extends PropsWithChildren {
  /**
   * The element to which the popover is anchored.
   */
  anchorEl: T | null;
  type?: AlertPopoverType;

  /**
   * Icon to display in the popover, popover will not display any icon if set to false
   */
  icon?: ReactElement | false;
  actions?: {
    label: string;
    onClick: () => void;
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  }[];
  /**
   * Callback fired when the component requests to be closed.
   */
  onClose: () => void;
  /**
   * Time in seconds after which the popover should close automatically
   */
  closeAfter?: number;
}

const MAX_WIDTH = 300;

export const AlertPopover = <T extends Element>({
  type = AlertPopoverType.Regular,
  children,
  icon,
  actions,
  anchorEl,
  onClose,
  closeAfter,
}: AlertPopoverProps<T>) => {
  const open = Boolean(anchorEl);
  const timeoutRef = useRef<number | null>(null);
  const timeoutTimestamp = useRef<number>(0);
  const timeoutLength = useRef<number>(0);

  useEffect(() => {
    if (closeAfter) {
      timeoutTimestamp.current = new Date().getTime();
      timeoutLength.current = closeAfter * 1000;
      timeoutRef.current = window.setTimeout(() => {
        onClose();
      }, closeAfter * 1000);
    }
    return () => {
      if (isNotNil(timeoutRef.current)) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [closeAfter, open]);

  const handlePauseTimer = () => {
    if (closeAfter && timeoutRef.current) {
      timeoutLength.current -= new Date().getTime() - timeoutTimestamp.current;
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const handleUnpauseTimer = () => {
    if (closeAfter && timeoutLength.current > 0 && !timeoutRef.current) {
      timeoutRef.current = window.setTimeout(() => {
        onClose();
      }, timeoutLength.current);
    }
  };

  const handleClose = () => {
    if (isNotNil(timeoutRef.current)) {
      clearTimeout(timeoutRef.current);
    }
    onClose();
  };

  const getIcon = () => {
    if (icon) {
      return icon;
    }
    if (icon === false) {
      return null;
    }
    if (type === AlertPopoverType.Warning) {
      return <MdsIcon color="warning" fontSize="small" icon={MdsWarningXs} />;
    } else if (type === AlertPopoverType.Error) {
      return <MdsIcon color="error" fontSize="small" icon={MdsErrorXs} />;
    }
    return <MdsIcon color="success" fontSize="small" icon={MdsCheckXs} />;
  };

  const popoverPositionLeft =
    (anchorEl ? anchorEl.getBoundingClientRect().left + anchorEl.clientWidth : 0) + MAX_WIDTH > window.innerWidth;

  return (
    <AlertPopoverStyled
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: popoverPositionLeft ? "left" : "right",
      }}
      disableEscapeKeyDown
      onClose={handleClose}
      open={open}
      popoverHorizontalPosition={popoverPositionLeft ? "left" : "right"}
      slotProps={{
        paper: {
          onMouseLeave: handleUnpauseTimer,
          onMouseOver: handlePauseTimer,
        },
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: popoverPositionLeft ? "right" : "left",
      }}
      type={type}
    >
      <Stack direction="column" spacing={0.5}>
        <Stack
          direction="row"
          spacing={0.5}
          sx={{
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              alignSelf: "flex-start",
            }}
          >
            {getIcon()}
          </Box>
          <Box>{children}</Box>
        </Stack>
        {actions?.length && (
          <Stack
            direction="row"
            spacing={1}
            sx={{
              justifyContent: "flex-end",
            }}
          >
            {actions.map((action, index) => (
              <Button
                key={index}
                color={action.color}
                onClick={action.onClick}
                size="small"
                sx={{ whiteSpace: "nowrap" }}
                variant="text"
              >
                {action.label}
              </Button>
            ))}
          </Stack>
        )}
      </Stack>
    </AlertPopoverStyled>
  );
};

const AlertPopoverStyled = styled(Popover, {
  shouldForwardProp: (propName) => propName !== "type" && propName !== "popoverHorizontalPosition",
})<PopoverProps & { type: AlertPopoverType; popoverHorizontalPosition: "left" | "right" }>(({
  theme,
  type,
  popoverHorizontalPosition,
}) => {
  const backgroundColor = (() => {
    if (type === AlertPopoverType.Error) {
      return theme.palette.custom.red["100"];
    }
    if (type === AlertPopoverType.Warning) {
      return theme.palette.custom.orange["50"];
    }
    return theme.palette.common.white;
  })();

  return {
    ".MuiPopover-paper": {
      position: "relative",
      padding: theme.spacing(1),
      maxWidth: MAX_WIDTH,
      overflow: "visible",
      ...(popoverHorizontalPosition === "left"
        ? {
            marginRight: "3px",
          }
        : {
            marginLeft: "3px",
          }),

      backgroundColor,
      "&::after": {
        position: "absolute",
        content: "''",
        border: "5px solid transparent",
        top: "calc(50% - 5px)",
        ...(popoverHorizontalPosition === "left"
          ? {
              borderLeftColor: backgroundColor,
              left: "100%",
            }
          : {
              borderRightColor: backgroundColor,
              left: -10,
            }),
      },
    },
  };
});
