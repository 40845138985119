import { Alert, IconButton } from "@mui/material";
import { MdsClose } from "@sinch/icons";
import { CustomContentProps, SnackbarContent, useSnackbar } from "notistack";
import React, { forwardRef, useCallback } from "react";
import { MdsIcon } from "../MdsIcon/MdsIcon";

type ToastProps = CustomContentProps;

export const Toast = forwardRef<HTMLDivElement, ToastProps>(({ id, variant, message }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Alert
        action={
          <IconButton color={variant} onClick={handleDismiss} size="small">
            <MdsIcon icon={MdsClose} />
          </IconButton>
        }
        icon={false}
        severity={variant === "default" ? undefined : variant}
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
});

Toast.displayName = "Toast";
