import { Autocomplete as GoogleMapsAutocomplete, AutocompleteProps } from "@react-google-maps/api";
import { useGoogleMapsApiLoad } from "@sinch/hooks/utilities/useGoogleMapsApiLoad";
import React, { FC, PropsWithChildren, useRef } from "react";

type AutocompleteObject = google.maps.places.Autocomplete;

export type GooglePlaceAutocompleteWrapperProps = PropsWithChildren<
  Omit<AutocompleteProps, "onPlaceChanged"> & {
    onPlaceChanged?: (autocompleteObject: AutocompleteObject) => void;
    /**
     * Google Maps API key
     * @internal
     */
    apiKey?: string;
  }
>;

/**
 * Turn children into a Google Places autocomplete input via vanilla js
 * See [https://react-google-maps-api-docs.netlify.app/#autocomplete](https://react-google-maps-api-docs.netlify.app/#autocomplete)
 *
 */
export const GooglePlaceAutocompleteWrapper: FC<GooglePlaceAutocompleteWrapperProps> = ({
  onPlaceChanged,
  children,
  apiKey,
  onLoad,
  ...nativeGoogleAutocompleteProps
}) => {
  const { isLoaded } = useGoogleMapsApiLoad();
  const autocompleteObjectRef = useRef<AutocompleteObject>();

  if (!isLoaded) {
    return <>{children}</>;
  }

  return (
    <GoogleMapsAutocomplete
      {...nativeGoogleAutocompleteProps}
      onLoad={(autocompleteObject) => {
        autocompleteObjectRef.current = autocompleteObject;
        onLoad?.(autocompleteObject);
      }}
      onPlaceChanged={() => {
        if (autocompleteObjectRef.current) {
          onPlaceChanged?.(autocompleteObjectRef.current);
        }
      }}
    >
      {children}
    </GoogleMapsAutocomplete>
  );
};
