import type { Coordinates } from "@sinch/types/common.types";
import type { Country, Region } from "@sinch/types/sinch.types";

import { parseStringifiedHtml } from "@sinch/utils/dom";
import { extractCompleteCoordinates } from "@sinch/utils/geolocation/coordinates";
import { where } from "ramda";
import { isNotNilOrEmpty, isString } from "ramda-adjunct";
import { PlaceFormFields } from "./formSchema";

type GeocoderAddressComponent = google.maps.GeocoderAddressComponent;

// Careful - not all types are unique across all address components in a single location object.
// This function is probably quite useless for such types
export const getGeocoderAddressComponentByType = (components: GeocoderAddressComponent[]) => (type: string) =>
  components?.find((component) => component.types.includes(type)) ?? undefined;

// Note: Adr is an address format: http://microformats.org/wiki/adr
// Google API returns a stringified HTML with separate lines of formatted address wrapped
// in div tags, marked by separate css classes. This is the simplest way to parse them out.
export const getAdrAddressComponentByType =
  (adrAddress: string) =>
  (type: string): string | undefined | null => {
    const dom = parseStringifiedHtml(`<div>${adrAddress?.trim()}</div>`)[0];
    return dom.querySelector(`[class='${type}']`)?.textContent;
  };

const joinAddressChunks = (chunks: (string | number | null | undefined)[]): string =>
  chunks.filter(isNotNilOrEmpty).join("");

export const parseAddress = (place: google.maps.places.PlaceResult): Partial<PlaceFormFields> => {
  // We need to parse out our values from two different formats provided in Google API response.
  // This is because we need atomic values (place.address_components), but we also need user-readable
  // chunks composed of multiple atomic values, and there is no clear way to reliably compose that from
  // atomic values for an arbitrary country. So we parse lines of address from place.adr_address for this.
  const getAdrComponent = getAdrAddressComponentByType(place.adr_address!);
  const getAddressComponent = getGeocoderAddressComponentByType(place.address_components!);

  return {
    address: joinAddressChunks([getAdrComponent("street-address"), getAdrComponent("extended-address")]),
    country: getAddressComponent("country")?.short_name ?? "",
    zip: getAddressComponent("postal_code")?.short_name ?? "",
    city: getAdrComponent("locality") || getAddressComponent("locality")?.long_name || "",
    region: getAdrComponent("region") ?? "",
  };
};

export const getCoordinates = (place: google.maps.places.PlaceResult): Coordinates | undefined =>
  extractCompleteCoordinates({
    lat: place.geometry?.location?.lat(),
    lng: place.geometry?.location?.lng(),
  }) ?? undefined;

export type CountryWithRegions = Country & { regions: Region[]; regionLabel: string };

export const hasRegions = (country: Country): country is CountryWithRegions => {
  const condition = { regions: isNotNilOrEmpty, regionLabel: isString };
  return where(condition)(country);
};
