import { isNil, map } from "ramda";
import { isNilOrEmpty, isNotNilOrEmpty } from "ramda-adjunct";
import { useCallback, useMemo, useState } from "react";
import { Schema } from "yup";
import { useSanitizationFromExternalStore } from "./utilities/useSanitizationFromExternalStore";

interface UseUrlParamsStateProps {
  /**
   * URL query key
   */
  key?: string;
  /**
   * reviever for parse json
   * @see JSON.parse second param
   */
  reviver?: (key: string, value: any) => any;
}

/**
 * Hook getting and setting URL query
 */
export const useUrlParamsState = <TData = any,>({ key, reviver }: UseUrlParamsStateProps = {}) => {
  const getUriState = useCallback(() => {
    const params = new URLSearchParams(window.location.search);
    const currentState = Object.fromEntries(params);
    const value = key ? currentState[key] : currentState;
    if (isNilOrEmpty(value)) {
      return value;
    }
    return key
      ? JSON.parse(value as string, reviver)
      : map<Record<string, string>, Record<string, unknown>>(
          (val) => JSON.parse(val, reviver),
          value as Record<string, string>
        );
  }, [window.location.search, key]);

  const [mirroredState, setMirroredState] = useState<TData>(getUriState());

  const setState = useCallback(
    (newValue: TData) => {
      const params = new URLSearchParams(window.location.search);
      const setOrDelete = (queryKey: string) => {
        if (isNilOrEmpty(newValue)) {
          params.delete(queryKey);
        } else {
          params.set(queryKey, JSON.stringify(newValue));
        }
      };
      if (key) {
        setOrDelete(key);
      } else if (isNil(key)) {
        // Add new newValue from the object
        for (const item in newValue) {
          setOrDelete(item);
        }
      }
      setMirroredState(newValue);
      window.history.replaceState({}, "", `${window.location.pathname}?${params.toString()}`);
    },
    [window.location.search]
  );

  return [mirroredState, setState];
};

type UseUrlCleanQueryStateReturn = [
  /**
   * State value from URL
   */
  any,
  /**
   * Setter/dispatch function
   */
  (newValue: any) => void,
  {
    /**
     * Indicating if URL param was set at entry of page
     */
    isSetAtEntry: boolean;
  },
];

/**
 * Hook getting and setting URL query with checking data against schema
 */
export const useUrlCleanQueryState = (key: string, schema: Schema): UseUrlCleanQueryStateReturn => {
  const [urlQueryState, setUrlQueryState] = useUrlParamsState({ key });
  const sanitize = useSanitizationFromExternalStore(schema);
  const query: any = useMemo(() => sanitize(urlQueryState), [window.location.search, urlQueryState]);
  const isSetAtEntry = useMemo(() => isNotNilOrEmpty(urlQueryState), []);

  return [query, setUrlQueryState, { isSetAtEntry }];
};
