import { Box, InputAdornment, OutlinedInput, OutlinedInputProps, Stack } from "@mui/material";
import { MdsTimerXs } from "@sinch/icons";

import { roundAt } from "@sinch/utils/math";
import { DateTime } from "luxon";
import React, { FocusEvent, KeyboardEventHandler, ReactElement, useCallback } from "react";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { DateTimeInput } from "../DateTimeInput/DateTimeInput";
import { DateTimeInputBaseProps, DateTimeInputProps } from "../DateTimeInput/DateTimeInput.types";

export enum InputVariants {
  TimeDuration = "timeDuration",
  // eslint-disable-next-line @typescript-eslint/no-shadow
  DateTime = "dateTime",
}

export interface DateTimeDurationInputBaseProps
  extends Omit<
      OutlinedInputProps,
      | "value"
      | "onInvalid"
      | "onKeyDown"
      | "onKeyUp"
      | "onError"
      | "ref"
      | "slots"
      | "slotProps"
      | "components"
      | "componentsProps"
      | "onChange"
    >,
    Pick<DateTimeInputBaseProps, "value" | "onChange" | "minTime" | "maxTime" | "referenceDate"> {
  /**
   * Variant of visible input, can be number input or date time input
   */
  inputVariant?: InputVariants;
  /**
   * Time from which is calculated duration
   */
  timePoint: DateTime;
  /**
   * Label of number input adornment
   */
  timeDurationAdornmentLabel: string;

  /**
   * Input props for date time input
   */
  DateTimeInputProps?: Omit<
    DateTimeInputProps,
    "onChange" | "timePoint" | "value" | "margin" | "slots" | "slotProps" | "minTime" | "maxTime" | "label"
  >;
  /**
   * Unit of duration
   */
  unit?: "hours" | "minutes";
  defaultValue?: DateTime;
  disablePortal?: boolean;
  /**
   * If set date will be shown greyed out, but will be still selectable
   */
  dimmedDate?: boolean;
}

/**
 * Combined date time input with text input duration, switched by param
 */
export function DateTimeDurationInputBase({
  inputVariant = InputVariants.DateTime,
  value,
  onChange,
  timePoint,
  timeDurationAdornmentLabel,
  DateTimeInputProps: dateTimeInputProps = {},
  disabled,
  unit = "hours",
  minTime,
  maxTime,
  disablePortal,
  referenceDate,
  startAdornment,
  ...props
}: DateTimeDurationInputBaseProps): ReactElement {
  const [empty, setEmpty] = React.useState(false);
  const handleNumberInputChange: OutlinedInputProps["onChange"] = (e) => {
    const newTime = timePoint.plus({ [unit]: +e.target.value });
    if (!newTime.isValid) {
      return +e.target.value > 0 ? DateTime.fromMillis(8640000000000000) : DateTime.fromMillis(-8640000000000000);
    }
    if (e.target.value === "") {
      setEmpty(true);
      return;
    }
    setEmpty(false);

    if (minTime && newTime?.valueOf() < minTime?.valueOf()) {
      onChange(newTime);
      return;
    }
    if (maxTime && newTime?.valueOf() > maxTime?.valueOf()) {
      onChange(maxTime);
      return;
    }
    onChange(newTime);
  };

  // Set minimum time if input is lower than minimum or last value if input is not empty
  const handleInputBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      props.onBlur?.(e);
      if (empty) {
        setEmpty(false);
        return;
      }
      const newTime = timePoint.plus({ [unit]: +e.target.value });
      if (minTime && newTime?.valueOf() < minTime?.valueOf()) {
        onChange(minTime);
        return;
      }
    },
    [empty, minTime, onChange, props, timePoint, unit]
  );

  // Prevent e, E, + in number input, if minimum is lower than zero, allow minus symbol
  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault(),
    []
  );

  return inputVariant === InputVariants.TimeDuration ? (
    <OutlinedInput
      {...props}
      data-cy="date-time-duration-input"
      disabled={disabled || dateTimeInputProps?.timeDisabled}
      endAdornment={
        <InputAdornment position="end">
          <Stack
            direction="row"
            spacing={0.25}
            sx={{
              alignItems: "center",
            }}
          >
            <Box>{timeDurationAdornmentLabel}</Box>
            {props.endAdornment}
          </Stack>
        </InputAdornment>
      }
      inputProps={{
        step: 0.25 * (unit === "minutes" ? 60 : 1),
        min: 0,
        ...props.inputProps?.durationInput,
      }}
      onBlur={handleInputBlur}
      onChange={handleNumberInputChange}
      onKeyDown={handleKeyDown}
      startAdornment={
        startAdornment ?? (
          <InputAdornment position="start">
            <MdsIcon fontSize="xs" icon={MdsTimerXs} />
          </InputAdornment>
        )
      }
      sx={(theme) => ({ padding: theme.spacing(0, 1), "& > input": { paddingLeft: "0 !important" } })}
      type="number"
      value={empty ? "" : roundAt(3)(value?.diff(timePoint, unit)[unit] ?? 0)}
    />
  ) : (
    <DateTimeInput
      {...props}
      {...dateTimeInputProps}
      disabled={disabled}
      disablePortal={disablePortal}
      maxTime={maxTime?.toJSDate()}
      minTime={minTime?.toJSDate()}
      onChange={(val) => onChange(val ? DateTime.fromJSDate(val) : val)}
      referenceDate={referenceDate}
      startAdornment={startAdornment}
      sx={(theme) => ({ padding: theme.spacing(0, 1) })}
      timePoint={timePoint?.toJSDate()}
      value={value?.toJSDate()}
    />
  );
}
