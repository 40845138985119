import { useTheme } from "@mui/material";
import { AttendanceStatusEnum } from "@sinch/core/entities/serverEnums";
import { SinchPersonAttendingXs, SinchPersonPendingXs } from "@sinch/icons";
import { ElementType } from "react";
import { useTranslation } from "react-i18next";
import { useEntityProps } from "../utilsHooks";

export const useAttendanceStatus = () => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const attendancePresence = {
    [AttendanceStatusEnum.AssignedByAdmin]: {
      label: t("Attendance.assignedByAdmin"),
      icon: SinchPersonAttendingXs,
      color: palette.custom.blue["900"],
    },
    [AttendanceStatusEnum.AssignedByWorker]: {
      label: t("Attendance.assignedByWorker"),
      icon: SinchPersonAttendingXs,
      color: palette.custom.blue["900"],
    },
    [AttendanceStatusEnum.ConfirmationPending]: {
      label: t("Attendance.waitingForConfirmation"),
      icon: SinchPersonPendingXs,
      color: palette.custom.orange["400"],
    },
    [AttendanceStatusEnum.ConfirmationConfirmed]: {
      label: t("Attendance.confirmed"),
      icon: SinchPersonAttendingXs,
      color: palette.success.main,
    },
  } as Record<AttendanceStatusEnum, { label: string; icon: ElementType; color: string }>;
  const getProp = useEntityProps(attendancePresence);

  const getStatusLabel = getProp<string>("label");
  const getStatusIcon = getProp<ElementType>("icon");
  const getStatusColor = getProp<string>("color");

  return { getStatusLabel, getStatusIcon, getStatusColor };
};
