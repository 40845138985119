import { useSettings } from "@sinch/core/providers/AppSettings";
import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import { Contact } from "@sinch/types/sinch.types";
import { hasTruthyProps } from "@sinch/utils/object";
import { mapToOptions, Option } from "@sinch/utils/option";
import { isNil, last } from "ramda";
import { isNotNilOrEmpty } from "ramda-adjunct";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../../../form/AutocompleteInput/AutocompleteInput";
import { AutocompleteInputProps, AutocompleteOptions } from "../../../form/AutocompleteInput/types";
import { AddContactDialog } from "./AddContactDialog";
import { Contacts } from "./queries.graphql";
import { ContactsQuery, ContactsQueryVariables } from "./queries.types";

export type ContactSelectInputProps = Omit<
  AutocompleteInputProps<AutocompleteOptions, true, true, true>,
  "value" | "ref" | "options"
> & {
  companyId: string;
  value: string[];
};

export const ContactSelectInput: FC<ContactSelectInputProps> = ({ companyId, onChange, value, ...props }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState<string>("");
  const { crewbossName } = useSettings();

  const { data: clients, loading } = useQueryHandler<ContactsQuery, ContactsQueryVariables>(Contacts, {
    companyId: companyId,
  });

  const contactsList: Option[] = useMemo(() => {
    const allContacts = clients?.contacts ?? [];
    const viableContacts = allContacts.filter(hasTruthyProps(["id", "name"]));
    const options: Option[] = mapToOptions("id", ({ name, phone }) =>
      isNotNilOrEmpty(phone) ? `${name} [${phone}]` : name
    )(viableContacts);
    return options;
  }, [t, clients]);

  const handleAddContact = (contact?: Contact | null) => {
    setOpen(false);
    if (contact) {
      onChange?.([...value, contact.id]);
    }
    setTextFieldValue("");
  };

  const handleChange = (val: (string | number)[] | null) => {
    if (!val || last(val) !== textFieldValue) {
      onChange?.(val);
      setTextFieldValue("");
    }
  };

  return (
    <>
      <AutocompleteInput
        disableClearable
        disabled={!companyId}
        freeSolo
        infoTooltip={t("ContactSelectInput.contactVisibleToTheLeadWorker", crewbossName)}
        inputValue={textFieldValue}
        label={t("contactPerson")}
        loading={loading}
        multiple
        onAddAction={() => setOpen(true)}
        onInputChange={(event, newInputValue) => {
          if (event.type === "change") {
            setTextFieldValue(newInputValue);
          }
        }}
        skeleton={!isNil(value) && loading}
        slotProps={{ addOption: { label: t("Contact.add") } }}
        textFieldProps={{
          inputProps: { "data-cy": "contactPersonInput" },
        }}
        {...props}
        onChange={handleChange}
        options={contactsList}
        value={value}
      />
      {open && (
        <AddContactDialog
          companyId={companyId}
          defaults={{ name: textFieldValue }}
          onCancel={() => setOpen(false)}
          onConfirm={handleAddContact}
          open
        />
      )}
    </>
  );
};
