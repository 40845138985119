import { Box, BoxProps, Button, CircularProgress, IconButton, styled } from "@mui/material";
import { unstable_generateUtilityClasses as generateUtilityClasses } from "@mui/utils";
import { FILE_STATES } from "@rpldy/shared";
import { MdsDelete } from "@sinch/icons";
import React from "react";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../../MdsIcon/MdsIcon";
import { FileItem, PreviewItemProps } from "../types";

const classes = generateUtilityClasses("SinchDragZoneImagePreview", [
  "progress",
  "delete",
  "progressContainer",
  "footer",
  "nameContainer",
  "remove",
]);

export const PreviewImage: React.FC<FileItem & PreviewItemProps> = ({
  url,
  removePreview,
  name,
  state,
  progress,
  multiple,
}) => {
  const { t } = useTranslation();

  const handleRemove = () => {
    removePreview?.();
  };

  const isUploading = state !== FILE_STATES.FINISHED;

  if (multiple) {
    return (
      <PreviewImageCardContainer uploading={isUploading}>
        <img alt={name} src={url} />
        <div className={classes.footer}>
          <div className={classes.nameContainer}>{name}</div>
          <IconButton className={classes.remove} onClick={handleRemove}>
            <MdsIcon className={classes.remove} icon={MdsDelete} />
          </IconButton>
        </div>
        {isUploading && (
          <CircularProgress
            className={classes.progress}
            sx={{ position: "absolute" }}
            value={progress}
            variant="determinate"
          />
        )}
      </PreviewImageCardContainer>
    );
  }

  return (
    <PreviewImageOverlayContainer isUploading={isUploading}>
      <img alt={name} src={url} />
      <div className={classes.delete}>
        <Button onClick={handleRemove} startIcon={<MdsIcon className={classes.remove} icon={MdsDelete} />}>
          {t("Action.remove")}
        </Button>
      </div>
      {isUploading && <CircularProgress sx={{ position: "absolute" }} value={progress} variant="determinate" />}
    </PreviewImageOverlayContainer>
  );
};

const PreviewImageOverlayContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "isUploading" })<
  BoxProps & { isUploading: boolean }
>(({ isUploading, theme }) => ({
  position: "absolute",
  height: "100%",
  width: "100%",
  top: 0,
  left: 0,
  overflow: "hidden",
  background: theme.palette.grey["200"],
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& > img": {
    width: "100%",
    height: "3.5rem",
    opacity: isUploading ? 0.3 : 1,
    objectFit: "contain",
  },
  [`& > ${classes.progress}`]: {
    position: "absolute",
    background: "none",
  },
  [`& > .${classes.delete}`]: {
    position: "absolute",
    bottom: "8px",
    right: "12px",
  },
  [`& .${classes.remove}`]: {
    color: theme.palette.error.main,
  },
}));

const PreviewImageCardContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "uploading" })<
  BoxProps & { uploading: boolean }
>(({ uploading, theme }) => ({
  height: "10rem",
  width: "8.5rem",
  overflow: "hidden",
  borderColor: theme.palette.grey[400],
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: `${theme.shape.borderRadius}px !important`,
  background: theme.palette.grey["200"],
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  "& > img": {
    width: "100%",
    height: "8.5rem",
    opacity: uploading ? 0.3 : 1,
    objectFit: "cover",
  },
  [`& > .${classes.progress}`]: {
    position: "absolute",
    background: "none",
  },
  [`& > .${classes.footer}`]: {
    padding: "0 0.25rem 0 0.5rem",
    display: "flex",
    [`& > .${classes.nameContainer}`]: {
      width: "6rem",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
      size: theme.typography.body2.fontSize,
    },
    [`& > .${classes.remove}`]: {
      cursor: "pointer",
      color: theme.palette.error.main,
      padding: 0,
      "&:hover": {
        color: theme.palette.primary.light,
      },
      "& > svg": {
        height: "1.3rem",
      },
    },
  },
}));
