import { Divider } from "@mui/material";
import { MdsAddXs } from "@sinch/icons";
import React from "react";
import { MdsIcon } from "../../MdsIcon/MdsIcon";

export const AdornmentPlus = () => (
  <MdsIcon
    fontSize="small"
    icon={MdsAddXs}
    sx={({ palette, spacing }) => ({
      color: palette.secondary.dark,
      marginX: spacing(0.75),
    })}
  />
);

export const AdornmentLine = () => (
  <Divider
    orientation="vertical"
    sx={({ palette, spacing }) => ({
      borderColor: palette.secondary.dark,
      borderRadius: 1.5,
      borderWidth: 0.75,
      height: 112,
      marginX: spacing(1.875),
    })}
  />
);
