import { UIEvent, useState } from "react";

export type UseAnchorOpenReturn<TElement extends HTMLElement = HTMLElement> = [
  TElement | null,
  (el: TElement | UIEvent<TElement>) => void,
  () => void,
];

/**
 * Hook to manage the anchor element for a popover or other component that requires an anchor.
 */
export const useAnchorOpen = <TElement extends HTMLElement = HTMLElement>(): UseAnchorOpenReturn<TElement> => {
  const [anchorEl, setAnchorEl] = useState<TElement | null>(null);

  const open = (el: TElement | UIEvent<TElement>) => {
    setAnchorEl((el as UIEvent<TElement>).currentTarget ?? el);
  };

  const close = () => {
    setAnchorEl(null);
  };

  return [anchorEl, open, close];
};
