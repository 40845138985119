import { alpha, Box, BoxProps, Fade, Menu, Popover, styled, TooltipProps } from "@mui/material";
import React, { FC, ReactElement, useRef } from "react";

/**
 * Wrapper for edited content
 */
const EditableBox = styled(Box, {
  name: "EditableBox",
  shouldForwardProp: (prop) => prop !== "focused" && prop !== "error",
})<BoxProps & { focused?: boolean; error?: boolean }>(({ theme, focused, error }) => ({
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  borderStyle: "solid",
  borderWidth: 1,
  borderRadius: `${theme.shape.borderRadius}px !important`,
  borderColor: "transparent",
  cursor: "pointer",
  marginLeft: theme.spacing(-0.5),
  "&:hover": {
    backgroundColor: theme.palette.custom.blue[50],
    borderColor: theme.palette.primary.main,
  },
  ...(focused
    ? {
        backgroundColor: theme.palette.background.paper,
        borderColor: theme.palette.primary.main,
        boxShadow: `0px 0px 4px ${alpha(theme.palette.primary.main, 0.25)}`,
      }
    : {}),
  ...(error
    ? {
        borderColor: theme.palette.error.main,
      }
    : {}),
}));

export interface HtmlTooltipProps extends TooltipProps {
  /**
   * Call when user click on box
   */
  onClick?: () => void;
  /**
   * Call when user click close tooltip or click away
   */
  onClose?: () => void;
  /**
   * If true, edit button will be full width, default true
   */
  fullWidth?: boolean;
  /**
   * Props applied to EditableBox
   */
  boxProps?: BoxProps;

  variant?: "popover" | "menu";

  error?: boolean;
}

/**
 * Provide wrapper to show rich content tooltip. Show box for open tooltip, can be used as quick edit tool.
 */
export const QuickEditButton: FC<HtmlTooltipProps> = ({
  children,
  title,
  open,
  onClick,
  onClose,
  fullWidth = true,
  boxProps,
  variant = "popover",
  error,
}): ReactElement => {
  const anchorEl = useRef();

  const PopoverComponent = variant === "menu" ? Menu : Popover;

  return (
    <div>
      <EditableBox
        ref={anchorEl}
        error={error}
        focused={open}
        onClick={onClick}
        sx={fullWidth ? {} : { width: "fit-content", display: "flex" }}
        {...boxProps}
      >
        {children}
      </EditableBox>

      <PopoverComponent
        anchorEl={anchorEl.current}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        onClose={onClose}
        open={!!open}
        slotProps={{
          paper:
            variant === "menu"
              ? {}
              : { elevation: 4, sx: { p: 2, borderRadius: "12px !important", overflow: "visible" } },
        }}
        TransitionComponent={Fade}
      >
        {title}
      </PopoverComponent>
    </div>
  );
};
