import { Tooltip, TooltipProps, useForkRef } from "@mui/material";
import React, { forwardRef, ReactNode, useEffect, useState } from "react";

/**
 * Show tooltip only when content of children is overflowing
 */
export const OverflowTooltip = forwardRef<unknown, TooltipProps>(({ children, title, ...props }, ref) => {
  const customRef = React.useRef<HTMLElement>(null);
  const [visibleTitle, setVisibleTitle] = useState<ReactNode>(false);

  useEffect(() => {
    if (customRef.current) {
      setVisibleTitle(customRef.current.offsetWidth < customRef.current.scrollWidth);
    }
  }, [customRef]);

  const handleRef = useForkRef(customRef, ref);
  return (
    <Tooltip {...props} enterDelay={1000} title={visibleTitle ? title : null}>
      {React.cloneElement(children, { ref: handleRef })}
    </Tooltip>
  );
});
OverflowTooltip.displayName = "OverflowTooltip";
