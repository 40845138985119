export const getCurrencySymbol = (currencyCode: string) => {
  const format = new Intl.NumberFormat("en-US", {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    currency: currencyCode,
  });

  const parts = format.formatToParts(1);
  const symbol = parts.find((part) => part.type === "currency")?.value;

  return symbol;
};
