import { Checkbox, CheckboxProps } from "@mui/material";
import { isNil } from "ramda";
import React, { ReactElement } from "react";
import {
  BinaryInputControlWrapperProps,
  BinaryInputControlWrapper,
} from "../BinaryInputControlWrapper/BinaryInputControlWrapper";

/**
 * Checkbox input extended with tooltip
 */
export interface CheckboxInputProps
  extends CheckboxProps,
    Pick<
      BinaryInputControlWrapperProps,
      "helperText" | "FormControlProps" | "FormHelperTextProps" | "infoTooltip" | "label" | "error" | "errorMessage"
    > {
  value?: boolean;
}

/**
 * Checkbox input, see [https://mui.com/material-ui/api/checkbox/](https://mui.com/material-ui/api/checkbox/)
 */
export function CheckboxInput({
  helperText,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  infoTooltip,
  label,
  error,
  errorMessage,
  required,
  value,
  color = "success",
  disabled,
  ...props
}: CheckboxInputProps): ReactElement {
  return (
    <BinaryInputControlWrapper
      disabled={disabled}
      error={error}
      errorMessage={errorMessage}
      FormControlProps={formControlProps}
      helperText={helperText}
      infoTooltip={infoTooltip}
      label={label}
      required={required}
    >
      <Checkbox
        checked={!isNil(value) && Boolean(value)}
        color={error ? "error" : color}
        disabled={disabled}
        required={required}
        value={value}
        {...props}
      />
    </BinaryInputControlWrapper>
  );
}
