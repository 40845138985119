import {
  Box,
  Button,
  ButtonGroup,
  ButtonGroupProps,
  ButtonProps,
  FormControlProps,
  Popover,
  styled,
} from "@mui/material";
import { DateRangeCalendar, DateRangeCalendarProps } from "@mui/x-date-pickers-pro";
import { useFormat } from "@sinch/core/hooks/format";
import { useSettings } from "@sinch/core/providers/AppSettings";
import { MdsChevronLeft, MdsChevronRight } from "@sinch/icons";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";

import { DateTime } from "luxon";
import React, { FC, useCallback, useState } from "react";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { FormControlWrapper, InputWrapperProps } from "../FormControlWrapper/FormControlWrapper";

export interface DateSliderInputProps extends DateSliderInputBaseProps, Omit<InputWrapperProps, "slotProps" | "slots"> {
  FormControlProps?: FormControlProps;
}

/**
 * Represents a date slider input component where date can be set by calendar popup or by slide date by back and forward buttons
 */
export const DateSliderInput: FC<DateSliderInputProps> = ({
  error,
  errorMessage,
  FormHelperTextProps: formHelperTextProps,
  helperText,
  infoTooltip,
  label,
  FormControlProps: formControlProps,
  ...props
}) => (
  <FormControlWrapper
    error={error}
    errorMessage={errorMessage}
    FormHelperTextProps={formHelperTextProps}
    helperText={helperText}
    infoTooltip={infoTooltip}
    label={label}
    {...formControlProps}
  >
    <DateSliderInputBase {...props}></DateSliderInputBase>
  </FormControlWrapper>
);

interface DateSliderInputBaseProps extends Omit<DateRangeCalendarProps<DateTime>, "value" | "onChange"> {
  value: { start: Date; end: Date };
  onChange: (value: { start: Date; end: Date }) => void;
  /**
   * How many days can be selected
   */
  maxSelectableRange?: number;
}

const DateSliderInputBase: FC<DateSliderInputBaseProps> = ({ value, onChange, maxSelectableRange, ...props }) => {
  const { timeZone } = useSettings();
  const { date } = useFormat();

  /**
   * Move the range to past or future
   */
  const move = useCallback(
    (direction: "past" | "future") => {
      const dir = direction === "past" ? 1 : -1;
      const interval = value.end.getTime() - value.start.getTime();
      onChange({
        start: new Date(value.start.valueOf() - interval * dir),
        end: new Date(value.end.valueOf() - interval * dir),
      });
    },
    [onChange, value]
  );

  // Set value for render labels and set value to datepicker
  const isStartOfDay = toLuxon(value.start).equals(toLuxon(value.start).startOf("day"));
  const start = toLuxon(value.start)
    .plus({ day: isStartOfDay ? 0 : 1 })
    .startOf("day")
    .toJSDate();
  const end = toLuxon(value.end).plus({ day: -1 }).endOf("day").toJSDate();

  const { open, anchorEl, handleClick, handleClose } = usePopoverAnchor();

  // Change viewport by value from dropdown
  const handleChangeViewport = useCallback(
    (newStart: Date | null | undefined, newEnd: Date | null | undefined) => {
      if (newStart && newEnd) {
        const roundedStart = toLuxon(newStart).setZone(timeZone).startOf("day").toJSDate();
        const roundedEnd = toLuxon(newEnd).setZone(timeZone).plus({ day: 1 }).startOf("day").toJSDate();
        onChange({
          start: roundedStart,
          end: roundedEnd,
        });
      }
    },
    [onChange]
  );

  const [temporaryRange, setTemporaryRange] = useState<[DateTime | null, DateTime | null] | null>(null);
  const [maxDate, setMaxDate] = useState<DateTime | undefined>(undefined);

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      <DateSliderButtonGroup orientation="horizontal" size="medium" variant="outlined">
        <Button onClick={() => move("past")}>
          <MdsIcon fontSize="xs" icon={MdsChevronLeft} />
        </Button>
        <DateSliderDateSelect onClick={handleClick}>
          {`${date.mediumMonth(start)} - ${date.mediumMonth(end)}`}
        </DateSliderDateSelect>
        <Button onClick={() => move("future")}>
          <MdsIcon fontSize="xs" icon={MdsChevronRight} />
        </Button>
      </DateSliderButtonGroup>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => {
          setTemporaryRange(null);
          setMaxDate(undefined);
          handleClose();
        }}
        open={open}
      >
        <DateRangeCalendar<DateTime>
          disableDragEditing
          maxDate={maxDate}
          onChange={([newStart, newEnd], selectionState) => {
            if (selectionState !== "finish") {
              setTemporaryRange([newStart, newStart]);
              if (maxSelectableRange) {
                setMaxDate(newStart?.plus({ day: maxSelectableRange }));
              }
            } else if (selectionState === "finish") {
              setTemporaryRange(null);
              setMaxDate(undefined);
              handleChangeViewport(newStart?.toJSDate(), newEnd?.toJSDate());
            }
          }}
          timezone={timeZone}
          value={temporaryRange ? temporaryRange : [toLuxon(start), toLuxon(end)]}
          {...props}
        />
      </Popover>
    </Box>
  );
};

const DateSliderButtonGroup = styled(ButtonGroup, { name: "DateSlider", slot: "root" })<ButtonGroupProps>(
  ({ theme }) => ({
    "&.MuiButtonGroup-grouped.MuiButtonGroup-groupedHorizontal": {
      whiteSpace: "nowrap",
      width: "fit-content",
      minWidth: "150px",
    },
    "& .MuiButtonBase-root": {
      paddingLeft: "8px",
      paddingRight: "8px",
      borderColor: theme.palette.grey[400],
      "&:hover": {
        borderColor: theme.palette.grey[400],
      },
    },
  })
);

const DateSliderDateSelect = styled(Button, { name: "DateSlider", slot: "dateSelect" })<ButtonProps>(() => ({
  "&.MuiButtonGroup-grouped.MuiButtonGroup-groupedHorizontal": {
    whiteSpace: "nowrap",
    width: "fit-content",
    minWidth: "150px",
  },
}));

const usePopoverAnchor = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return { open, anchorEl, handleClick, handleClose };
};
