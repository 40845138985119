import { LocalizationProvider } from "@mui/x-date-pickers";
// eslint-disable-next-line import/no-internal-modules
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import React, { FC, PropsWithChildren } from "react";
import { useLanguageTools } from "../hooks/useLanguageTools";
import { useSettings } from "./AppSettings";

/**
 * Provide localisation for MaterialUi date/time pickers
 */
export const DateTimePickersL10nProvider: FC<PropsWithChildren> = ({ children }) => {
  const { locale } = useSettings();
  const { convertToStandardString } = useLanguageTools();

  // in case of use custom languages as cs-VOL, convert it to correct language (eg cs-VOL -> cs)
  const localeFallback = convertToStandardString(locale);

  return (
    <LocalizationProvider adapterLocale={localeFallback} dateAdapter={AdapterLuxon}>
      {children}
    </LocalizationProvider>
  );
};
