import React from "react";
import { useTranslation } from "react-i18next";
import { useMuiController } from "../../../form/MuiController/MuiController";
import { TextInput } from "../../../form/TextInput/TextInput";
import { PlaceFormFields } from "./formSchema";

export const NoteInput = () => {
  const { t } = useTranslation();
  const { field } = useMuiController<PlaceFormFields>({
    name: "note",
  });

  return <TextInput minRows={4} multiline {...field} label={t("Place.note")} />;
};
