query ShiftsData($filter: ShiftFilter, $limit: Int) {
  shifts(filter: $filter, limit: $limit, orderBy: { id: DESC }) {
    id
    name
  }
}

query JobsData($filter: JobFilter, $limit: Int) {
  jobs(filter: $filter, limit: $limit, orderBy: { id: DESC }) {
    id
    name
  }
}

query OrdersData($filter: OrderFilter, $limit: Int) {
  orders(filter: $filter, limit: $limit, orderBy: { id: DESC }) {
    id
    name
    number
  }
}
