import { darken } from "@mui/material";
import { round } from "ramda-adjunct";

/*
 * Uniquely distributes available colors to all indices.
 * When colors run out, starts darkening them
 */
export const getTagColorForElementIndex = (availableColors: string[] | readonly string[], index: number) => {
  const colorCount = availableColors.length;
  const baseColorForIndex = availableColors[index % colorCount];

  return darken(baseColorForIndex, 0.3 * round(index / colorCount));
};

/**
 * Generate letter name from number in format [A-Z, AA-ZZ, AAA-ZZZ, ...]
 */
export const generateLetterName = (index: number) => {
  let result = "";
  do {
    result = ((index % 26) + 10).toString(36) + result;
    index = Math.floor(index / 26) - 1;
  } while (index >= 0);
  return result.toUpperCase();
};
