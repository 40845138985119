import i18next, { changeLanguage } from "i18next";
import React, { FC, PropsWithChildren } from "react";
import { I18nextProvider } from "react-i18next";

/**
 * Provide context for application settings
 */
export const TranslateProvider: FC<PropsWithChildren<{ language: string }>> = ({ language, children }) => {
  changeLanguage(language);
  return (
    <I18nextProvider defaultNS="common" i18n={i18next}>
      {children}
    </I18nextProvider>
  );
};
