import { setRef } from "@mui/material";
import React, { MutableRefObject, ReactNode, RefObject, useEffect, useRef, useState } from "react";

interface AnchorByElement {
  /**
   * Element that will be centered by offset from top of referenced element
   */
  anchorElement: ReactNode;
  anchorRef?: never;
}
interface AnchorByRef {
  anchorElement?: never;
  /**
   * Ref of element that will be centered by offset from top of referenced element
   */
  anchorRef: RefObject<HTMLElement>;
}

type UseVerticalCenterWithElementOptions = {
  /**
   * Element that will be referenced for vertical centering
   */
  alignedElementRef?: RefObject<HTMLElement>;
  /**
   * Name of ref prop of anchorElement
   */
  refPropName?: string;
} & (AnchorByElement | AnchorByRef);

interface UseVerticalCenterWithElementReturn<TParentRef extends HTMLElement = HTMLElement> {
  /**
   * Ref that should be passed to common parent of aligned elements
   */
  parentRef: MutableRefObject<TParentRef | null>;
  /**
   * Offset from top of referenced element
   */
  marginTop: number;
  /**
   * Anchor element with ref
   */
  anchorChildren?: ReactNode;
}

/**
 * This hook return data for vertical centering of element with another element byt offset from top of referenced element
 */
export function useVerticalCenterWithElement<TParentRef extends HTMLElement = HTMLElement>({
  anchorElement,
  anchorRef,
  alignedElementRef,
  refPropName = "ref",
}: UseVerticalCenterWithElementOptions): UseVerticalCenterWithElementReturn<TParentRef> {
  const [offsetTop, setOffsetTop] = useState(0);
  const childrenInputRef = useRef<HTMLElement>(null);
  const childrenWrapperRef = useRef<TParentRef | null>(null);

  const anchor = anchorRef || childrenInputRef;

  // Get offset and heights of elements and calculate offset
  useEffect(() => {
    const anchorRect = anchor.current?.getBoundingClientRect?.();
    const wrapperRect = childrenWrapperRef.current?.getBoundingClientRect();
    const targetRect = alignedElementRef?.current?.getBoundingClientRect();
    if (anchorRect && wrapperRect && targetRect) {
      setOffsetTop(anchorRect.top - wrapperRect.top + anchorRect.height / 2 - targetRect.height / 2);
    }
  }, [childrenWrapperRef, anchor, alignedElementRef, anchorElement]);

  // if children is used as anchor, get children ref
  let childrenProps = {};
  if (anchorElement && React.isValidElement(anchorElement)) {
    const anchorProps = anchorElement.props as Record<string, any>;
    const anchorElRef = anchorProps[refPropName];
    if (!anchorElRef) {
      childrenProps = {
        ...anchorProps,
        [refPropName]: childrenInputRef,
      };
    } else {
      childrenProps = {
        ...anchorProps,
        [refPropName]: (input: HTMLElement) => {
          setRef(childrenInputRef, input);
          setRef(anchorElRef, input);
        },
      };
    }

    const anchorChildren = React.isValidElement(anchorElement)
      ? React.cloneElement(anchorElement, childrenProps)
      : anchorElement;

    return {
      parentRef: childrenWrapperRef,
      marginTop: offsetTop,
      anchorChildren,
    };
  } else if (alignedElementRef && anchorElement && process?.env?.MODE === "development") {
    console.warn("useVerticalCenterWithElement Hook: anchorElement prop is not valid React element.");
  }

  return {
    parentRef: childrenWrapperRef,
    marginTop: offsetTop,
    anchorChildren: anchorElement,
  };
}
