import { Grid, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import { unstable_generateUtilityClasses as generateUtilityClasses } from "@mui/utils";
import { useSettings } from "@sinch/core/providers/AppSettings";
import { MdsDeleteXs, MdsHourglassEmptyXs, MdsLocalAtmXs } from "@sinch/icons";
import { getCurrencySymbol } from "@sinch/utils/currency";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { useMuiController } from "../MuiController/MuiController";
import { SwitchInput } from "../SwitchInput/SwitchInput";
import { TextInput } from "../TextInput/TextInput";
import { AdornmentLine, AdornmentPlus } from "./Adornments";
import { StyledCard } from "./StyledCard";
import { Wage, WageStep, WageType } from "./types";
import { decimalToTimeString, getWageLabel, roundDuration, timeStringToDecimal } from "./utils";

const classes = generateUtilityClasses("WageCard", ["deleteButton"]);

export const WageCard: React.FC<{
  index: number;
  wage: Wage;
  prefix: string;
  onRemove: (index: number) => void;
  step: WageStep;
}> = ({ index, wage, step, prefix, onRemove }) => {
  const { t } = useTranslation();
  const { currency } = useSettings();
  const durationInputRef = useRef<HTMLInputElement>();
  const valueInputRef = useRef<HTMLInputElement>();

  const nextWageDuration = useWatch({ name: `${prefix}.${index + 1}.duration` });
  const prevWageDuration = useWatch({ name: `${prefix}.${index - 1}.duration` });

  const { register, setValue } = useFormContext();
  register(`${prefix}.${index}.type`);

  const { field: valueField } = useMuiController({
    name: `${prefix}.${index}.value`,
  });

  const {
    field: { value: duration, onChange: onDurationChange, ...durationField },
  } = useMuiController({
    name: `${prefix}.${index}.duration`,
  });

  const [parsedDuration, setParsedDuration] = useState<string | number>(decimalToTimeString(duration));

  useEffect(() => {
    setParsedDuration(decimalToTimeString(duration));
  }, [duration]);

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newDuration = event.target.value;
    const pattern = /^\d{1,}\.?:?\d{0,2}$/;

    if (newDuration.match(pattern)) {
      setParsedDuration(newDuration);
    }
  }, []);

  const handleBlur = useCallback(
    (value: string) => {
      let newDuration = value.includes(":") ? timeStringToDecimal(value) : Number(value);
      if (index > 1 && prevWageDuration && newDuration <= prevWageDuration + step) {
        newDuration = prevWageDuration + 2 * step;
      }
      if (nextWageDuration && newDuration >= nextWageDuration - step) {
        newDuration = nextWageDuration - 2 * step;
      }
      onDurationChange(roundDuration(newDuration, step));
    },
    [index, prevWageDuration, nextWageDuration, step, onDurationChange]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleBlur(event.currentTarget.value);
      }
    },
    [handleBlur]
  );

  return (
    <Grid
      container
      direction="row"
      sx={{
        alignItems: "center",
      }}
      wrap="nowrap"
    >
      <Grid item xs="auto">
        {duration !== 0 && (wage.type === WageType.HOURLY ? <AdornmentPlus /> : <AdornmentLine />)}
      </Grid>
      <Grid item xs="auto">
        <StyledCard
          fixed={wage.type === WageType.FIXED && nextWageDuration === 0}
          sx={{ marginLeft: duration === 0 && wage.type !== WageType.FIXED ? 4 : 0 }}
          type={wage.type}
        >
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
              height: 18,
              justifyContent: wage.type === WageType.FIXED ? "start" : "space-between",
              width: "100%",
            }}
          >
            {wage.type === WageType.FIXED && (
              <Stack
                sx={{
                  width: 28,
                }}
              >
                <SwitchInput
                  onChange={() => {
                    if (nextWageDuration === 0) {
                      setValue(`${prefix}.${index + 1}.duration`, 1);
                    }
                    if (nextWageDuration > 0) {
                      setValue(`${prefix}.${index + 1}.duration`, 0);
                    }
                  }}
                  size="small"
                  sx={{ ml: -0.5 }}
                  value={nextWageDuration !== 0}
                />
              </Stack>
            )}
            <Typography
              sx={{
                whiteSpace: "nowrap",
                ml: 0.5,
              }}
              variant="descriptionSmall"
            >
              {getWageLabel(wage.type)}
            </Typography>
            {wage.type !== WageType.FIXED && (
              <IconButton className={classes.deleteButton} onClick={() => onRemove(index)} sx={{ p: 0 }}>
                <MdsIcon color="error" fontSize="xs" icon={MdsDeleteXs} />
              </IconButton>
            )}
          </Stack>
          {nextWageDuration !== 0 ? (
            <Grid
              container
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
              }}
            >
              <Grid item xs={8}>
                <TextInput
                  {...durationField}
                  disabled={wage.type === WageType.FIXED}
                  inputRef={durationInputRef}
                  onBlur={(e) => handleBlur(e.target.value)}
                  onChange={handleChange}
                  onClick={() => durationInputRef.current?.select()}
                  onFocus={() => durationInputRef.current?.select()}
                  onKeyDown={handleKeyDown}
                  size="small"
                  startAdornment={
                    <InputAdornment position="start">
                      <MdsIcon fontSize="xs" icon={MdsHourglassEmptyXs} />
                    </InputAdornment>
                  }
                  value={parsedDuration}
                />
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                  }}
                  variant="descriptionSmall"
                >
                  {nextWageDuration
                    ? `– ${decimalToTimeString(nextWageDuration - step)}`
                    : `– ${t("end").toLowerCase()}`}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  endAdornment={
                    <InputAdornment position="end">
                      {wage.type === WageType.FIXED || wage.type === WageType.THRESHOLD_FIX
                        ? getCurrencySymbol(currency)
                        : t("perHour")}
                    </InputAdornment>
                  }
                  {...valueField}
                  inputRef={valueInputRef}
                  onClick={() => valueInputRef.current?.select()}
                  onFocus={() => valueInputRef.current?.select()}
                  size="small"
                  startAdornment={
                    <InputAdornment position="start">
                      <MdsIcon fontSize="xs" icon={MdsLocalAtmXs} />
                    </InputAdornment>
                  }
                  type="number"
                />
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography
                sx={{
                  color: ({ palette }) => palette.grey["800"],
                }}
                variant="body2"
              >
                {t("WageBuilder.setGuaranteedWage")}
              </Typography>
            </Grid>
          )}
        </StyledCard>
      </Grid>
    </Grid>
  );
};
