import { debounce } from "@mui/material";
import { mergeRight } from "ramda";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DefaultValues, FieldValues, UseFormProps } from "react-hook-form";
import { ObjectSchema } from "yup";
import { useMuiForm } from "./useMuiForm";

/**
 * This hook allow use state of form. Its usable when you want optimistic update of form with send data with each change,
 * accept same params as useMuiForm but do not allow async default values
 *
 * When updating state, debounce is set to 500ms
 */
export const useFormAsStore = <TFieldValues extends FieldValues = FieldValues, TFormContext = any>(
  initValue: DefaultValues<TFieldValues> | null,
  formProps: {
    formOptions?: UseFormProps<TFieldValues, TFormContext> & {
      defaultValues?: DefaultValues<TFieldValues> | undefined;
    };
    schema?: ObjectSchema<TFieldValues>;
  }
) => {
  const { formOptions, schema } = formProps;
  const defaultValues = useMemo(() => {
    if (formOptions?.defaultValues && initValue) {
      return mergeRight(formOptions.defaultValues, initValue) as DefaultValues<TFieldValues>;
    }
    return formOptions?.defaultValues ?? initValue ?? undefined;
  }, [formOptions?.defaultValues, initValue]);
  const form = useMuiForm<TFieldValues, TFormContext>({
    formOptions: {
      ...formProps,
      defaultValues: defaultValues,
    },
    schema,
  });
  const [mirroredState, setMirroredState] = useState<TFieldValues>(form.getValues());

  const updateState = useCallback(debounce(setMirroredState, 500), [setMirroredState]);

  useEffect(() => {
    const subscription = form.watch((newValue) => {
      updateState(newValue as TFieldValues);
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  const handleChangeValues = (values: TFieldValues | null) => {
    form.reset(values ?? undefined, { keepDefaultValues: true });
  };

  return [mirroredState, handleChangeValues, form];
};
