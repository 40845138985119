import { useSettings } from "@sinch/core/providers/AppSettings";

/**
 * Hook returning localized currency by settings
 */
export const useCurrencySymbol = () => {
  const { currency, locale } = useSettings();

  const numberFormat = new Intl.NumberFormat(locale, { style: "currency", currency: currency });
  const parts = numberFormat.formatToParts(0);
  const symbol = parts.find((p) => p.type === "currency");

  return symbol?.value;
};
