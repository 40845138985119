import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { useSettings } from "@sinch/core/providers/AppSettings";
import { getCurrencySymbol } from "@sinch/utils/currency";
import React, { useState } from "react";
import { useWatch } from "react-hook-form";
import { useWagePointConverter } from "./hooks";
import { Point, PointType, PointWrapper } from "./Point";
import { ActiveTimeLine, TimeLine } from "./TimeLine";

interface LineProps {
  prefix: string;
}

export const WageTimeline: React.FC<LineProps> = ({ prefix }) => {
  const [activeLength, setActiveLength] = useState<number | null>(null);
  const wages = useWatch({ name: prefix });
  const { currency } = useSettings();
  const points = useWagePointConverter(wages);

  if (!wages) {
    return null;
  }

  return (
    <Box sx={{ position: "relative", mt: 2 }}>
      <TimeLine
        fixed={(wages[1] !== undefined && wages[1].duration !== 0) || wages[1] === undefined}
        length={wages.length}
      />
      {points !== undefined &&
        points.map((point, i) => (
          <Tooltip
            key={i}
            arrow
            placement="bottom"
            PopperProps={{
              sx: {
                "&.MuiTooltip-popper[data-popper-placement*='bottom'] .MuiTooltip-tooltip ": {
                  marginTop: "2px !important",
                },
                "&.MuiTooltip-popper[data-popper-placement*='top'] .MuiTooltip-tooltip ": {
                  marginBottom: "2px !important",
                },
              },
            }}
            title={
              <Stack direction="column">
                <Typography
                  sx={{
                    fontSize: "11px",
                  }}
                >
                  {point.type === PointType.LAST && i + 1 !== points.length
                    ? `${point.hours - 1}:00`
                    : `${point.hours}:00${i + 1 === points.length ? "+" : ""}`}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "11px",
                    fontWeight: 700,
                  }}
                >
                  {`${point.value}${point.endAdornment ? "+" : ""} ${getCurrencySymbol(currency)}`}
                </Typography>
              </Stack>
            }
          >
            <PointWrapper
              onMouseEnter={() => setActiveLength(point.location)}
              onMouseLeave={() => setActiveLength(null)}
              {...point}
            >
              <Point currentLocation={activeLength} {...point} />
            </PointWrapper>
          </Tooltip>
        ))}
      <ActiveTimeLine
        fixed={(wages[1] !== undefined && wages[1].duration !== 0) || wages[1] === undefined}
        length={activeLength ?? 0}
      />
    </Box>
  );
};
