import { IconButton, IconButtonProps } from "@mui/material";
import { MdsClose } from "@sinch/icons";
import React, { FC } from "react";
import { MdsIcon } from "../MdsIcon/MdsIcon";

export interface DialogCloseIconProps extends IconButtonProps {
  onClick: () => void;
}

export const DialogCloseIcon: FC<DialogCloseIconProps> = ({ ...props }) => (
  <IconButton
    {...props}
    sx={{
      position: "absolute",
      right: 16,
      top: 16,
      color: (theme) => theme.palette.secondary.dark,
    }}
  >
    <MdsIcon fontSize="small" icon={MdsClose} sx={{ color: ({ palette }) => palette.secondary.dark }} />
  </IconButton>
);
