import { Stack, Typography, TypographyVariant } from "@mui/material";
import React, { FC, ReactNode } from "react";

interface ViewTitleProps {
  title: ReactNode;
  subtitle?: ReactNode;
  titleVariant?: TypographyVariant;
  subtitleVariant?: TypographyVariant;
}
export const ViewTitle: FC<ViewTitleProps> = ({ title, subtitle, titleVariant, subtitleVariant }) => (
  <Stack>
    {subtitle && (
      <Typography
        sx={{
          color: (theme) => theme.palette.grey["800"],
          fontWeight: "bold",
          lineHeight: 1.2,
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        variant={subtitleVariant ?? "subtitle1"}
      >
        {subtitle}
      </Typography>
    )}
    <Typography variant={titleVariant ?? "h2"}>{title}</Typography>
  </Stack>
);
