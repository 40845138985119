import { AttributeTypeEnum } from "@sinch/core/entities/serverEnums";
import { FormatJsIntlProvider } from "@sinch/core/providers/FormatJsIntlProvider";
import { mapToOptions } from "@sinch/utils/option";
import { last } from "ramda";
import { ensureArray, isNotNil } from "ramda-adjunct";
import React, { MutableRefObject, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../../form/AutocompleteInput/AutocompleteInput";
import { AutocompleteOptions } from "../../form/AutocompleteInput/types";
import { DateInput } from "../../form/DateInput/DateInput";
import { MuiController } from "../../form/MuiController/MuiController";
import { TextInput } from "../../form/TextInput/TextInput";
import { DropZoneImageUpload } from "../../form/UploadInput/DropZoneImageUpload/DropZoneImageUpload";
import { UploadChipPreviews } from "../../form/UploadInput/UploadChipPreview/UploadChipPreviews";
import { BaseAttribute } from "./types";

interface CustomAttributeInputProps<AttributeType extends BaseAttribute, PrefixType extends string> {
  attribute: AttributeType;
  inputRef?: MutableRefObject<HTMLInputElement>;
  prefix?: PrefixType;
}

export const CustomAttributeInput = <AttributeType extends BaseAttribute, PrefixType extends string = string>({
  attribute,
  prefix,
  inputRef,
}: CustomAttributeInputProps<AttributeType, PrefixType>) => {
  const { t } = useTranslation();
  const { register } = useFormContext();
  const prefixWithDot = prefix ? `${prefix}.` : "";
  register(`${prefixWithDot}attributeId`, { value: attribute.id });
  const options = useMemo(() => mapToOptions("id", "name")(attribute.options || []), [attribute.options]);

  const booleanOptions = useMemo<AutocompleteOptions[]>(
    () => [
      // TODO: Figure out how to use true and false as values
      { value: "", label: t("Attribute.notSet") },
      { value: 1, label: t("yes") },
      { value: 0, label: t("no") },
    ],
    [t]
  );

  switch (attribute.dataType) {
    case AttributeTypeEnum.Bool:
      return (
        <MuiController
          defaultValue=""
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <AutocompleteInput
              {...field}
              inputRef={inputRef}
              label={attribute.name}
              onChange={(val) => {
                if (val === 1) {
                  field.onChange(true);
                } else if (val === 0) {
                  field.onChange(false);
                } else {
                  field.onChange(val);
                }
              }}
              options={booleanOptions}
              placeholder={t("enterPlaceholder", {
                inputName: attribute.name.toLowerCase(),
              })}
              value={field.value === true ? 1 : field.value === false ? 0 : ""}
            />
          )}
        />
      );
    case AttributeTypeEnum.Choice:
      return (
        <MuiController
          defaultValue={null}
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <AutocompleteInput
              {...field}
              inputRef={inputRef}
              label={attribute.name}
              options={options}
              placeholder={t("enterPlaceholder", {
                inputName: attribute.name.toLowerCase(),
              })}
              value={isNotNil(field.value) ? String(field.value) : field.value}
            />
          )}
        />
      );
    case AttributeTypeEnum.Text:
      return (
        <MuiController
          defaultValue=""
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <TextInput
              {...field}
              inputRef={inputRef}
              label={attribute.name}
              placeholder={t("enterPlaceholder", {
                inputName: attribute.name.toLowerCase(),
              })}
            />
          )}
        />
      );
    case AttributeTypeEnum.Date:
      return (
        // Set local timezone for providers
        <MuiController
          defaultValue={null}
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <FormatJsIntlProvider>
              <DateInput {...field} inputRef={inputRef} label={attribute.name} />
            </FormatJsIntlProvider>
          )}
        />
      );
    case AttributeTypeEnum.File:
      return (
        <MuiController
          defaultValue={null}
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <UploadChipPreviews
              {...field}
              endpoint="/admin/attributes/uploadFile"
              label={attribute.name}
              onChange={(files) => {
                field.onChange(last(files) ?? []);
              }}
              title={t("Action.uploadFile")}
              value={ensureArray(field.value ?? [])}
            />
          )}
        />
      );
    case AttributeTypeEnum.Gallery:
      return (
        <MuiController
          defaultValue={[]}
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <DropZoneImageUpload {...field} endpoint="/admin/attributes/uploadFile/1" label={attribute.name} multiple />
          )}
        />
      );
    case AttributeTypeEnum.Image:
      return (
        <MuiController
          defaultValue={[]}
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <DropZoneImageUpload
              {...field}
              endpoint="/admin/attributes/uploadFile/1"
              label={attribute.name}
              onChange={(files) => field.onChange(last(files) ?? [])}
              value={ensureArray(field.value)}
            />
          )}
        />
      );
    case AttributeTypeEnum.Int:
      return (
        <MuiController
          defaultValue=""
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <TextInput
              {...field}
              allowEmpty
              inputRef={inputRef}
              label={attribute.name}
              placeholder={t("enterPlaceholder", {
                inputName: attribute.name.toLowerCase(),
              })}
              type="number"
            />
          )}
        />
      );
    case AttributeTypeEnum.Multiplechoice:
      return (
        <MuiController
          defaultValue={[]}
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <AutocompleteInput
              {...field}
              inputRef={inputRef}
              label={attribute.name}
              multiple
              options={options}
              placeholder={t("enterPlaceholder", {
                inputName: attribute.name.toLowerCase(),
              })}
              value={field.value.map((val: any) => (isNotNil(val) ? String(val) : val))}
            />
          )}
        />
      );
    case AttributeTypeEnum.Range:
      return (
        <MuiController
          defaultValue=""
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <AutocompleteInput
              {...field}
              inputRef={inputRef}
              label={attribute.name}
              options={options}
              placeholder={t("enterPlaceholder", {
                inputName: attribute.name.toLowerCase(),
              })}
              value={isNotNil(field.value) ? String(field.value) : field.value}
            />
          )}
        />
      );
    case AttributeTypeEnum.Textarea:
      return (
        <MuiController
          defaultValue=""
          name={`${prefixWithDot}value`}
          render={({ field }) => (
            <TextInput
              {...field}
              inputRef={inputRef}
              label={attribute.name}
              maxRows={15}
              minRows={5}
              multiline
              placeholder={t("enterPlaceholder", {
                inputName: attribute.name.toLowerCase(),
              })}
            />
          )}
        />
      );
    default:
      return null;
  }
};
