import { Stack, Typography } from "@mui/material";
import { PositionStatusEnum } from "@sinch/core/entities/serverEnums";
import { useFormat } from "@sinch/core/hooks/format";
import { useSettings } from "@sinch/core/providers/AppSettings";
import type { Order, Position } from "@sinch/types/sinch.types";

import { DateTime } from "luxon";
import { all, propEq } from "ramda";
import React, { useMemo } from "react";
import { CancelledStatusPill } from "../application/Position/Status/CancelledStatusPill";
import { ProvisionalPill } from "../application/Position/Status/ProvisionalPill";
import { QuotePill } from "../application/Position/Status/QuotePill";
import { JobPill, OrderPill, ShiftPill } from "../Pill/Pill";
import { ViewTitle } from "../typography/ViewTitle/ViewTitle";
import { findEarliestBeginning, findLatestEnd, getStatusLabel } from "./utils";

interface ShiftHeaderProps {
  shift: {
    positions: Pick<Position, "beginning" | "end" | "status" | "cancelled">[];
    id: string | number;
    name: string;
    job: {
      id: string | number;
      name: string;
      order: Pick<Order, "id" | "name" | "number" | "quote" | "provisional">;
    };
  };
}

export const ShiftHeader: React.FC<ShiftHeaderProps> = ({ shift }) => {
  const { date, time } = useFormat();
  const { crewbossName } = useSettings();
  const allCancelled = all(propEq("cancelled", true), shift.positions);

  const formatDate = (datetime?: DateTime) =>
    datetime ? date.short(datetime.toJSDate()) + ", " + time.short(datetime.toJSDate()) : "";

  const begining = useMemo(() => {
    const earliestBeginning = findEarliestBeginning(shift.positions ?? []);
    return formatDate(earliestBeginning);
  }, [shift.positions]);

  const end = useMemo(() => {
    const latestEnd = findLatestEnd(shift.positions ?? []);
    return formatDate(latestEnd);
  }, [shift.positions]);

  return (
    <Stack direction="column">
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: "end",
        }}
      >
        {allCancelled && <CancelledStatusPill />}
        {shift.job.order.quote && <QuotePill />}
        {shift.job.order.provisional && <ProvisionalPill />}
      </Stack>
      <Stack alignItems="end" direction="row" spacing={1}>
        <ViewTitle
          subtitle={
            <Stack
              direction="row"
              spacing={1}
              sx={{
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: ({ palette }) => palette.grey[900] }} variant="descriptionTinyBold">
                {shift.job.order.name}
              </Typography>
              <OrderPill id={shift.job.order.number} size="tiny" />
              <JobPill id={shift.job.id} size="tiny" />
            </Stack>
          }
          title={
            <>
              {shift.job.name} - {shift.name} <ShiftPill id={shift.id} size="small" />
            </>
          }
          titleVariant="h4"
        />
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          mt: 1,
        }}
      >
        <Typography>{`${begining} – ${end}`}</Typography>
        {shift.positions?.[0]?.status && (
          <Typography
            sx={{
              color: (theme) => theme.palette.success.main,
            }}
          >
            {getStatusLabel(shift.positions[0].status as PositionStatusEnum, crewbossName)}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
