import { PositionRoleEnum } from "@sinch/core/entities/serverEnums";
import type { Position } from "@sinch/types/sinch.types";

import { pipe, sort } from "ramda";

export const usePosition = () => {
  const sortPositions = <TPosition extends Pick<Position, "beginning" | "role">>(
    positions: TPosition[]
  ): TPosition[] => {
    const roles = [PositionRoleEnum.Crewboss, PositionRoleEnum.Worker, PositionRoleEnum.Standby];
    return pipe(
      sort<TPosition>((a, b) => a.beginning.getTime() - b.beginning.getTime()),
      sort<TPosition>((a, b) => {
        const roleA = roles.indexOf(a.role as PositionRoleEnum);
        const roleB = roles.indexOf(b.role as PositionRoleEnum);
        return roleA - roleB;
      })
    )(positions);
  };

  return { sortPositions };
};
