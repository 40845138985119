import { useQueryHandler } from "@sinch/hooks/useQueryHandler";
import { mapToOptions, Option } from "@sinch/utils/option";
import React, { FC, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AutocompleteInput } from "../../../form/AutocompleteInput/AutocompleteInput";
import { useMuiController } from "../../../form/MuiController/MuiController";
import { Countries } from "../query.graphql";
import { CountriesQuery } from "../query.types";
import { PlaceFormFields } from "./formSchema";
import { AutocompleteInputProps } from "@sinch/components/form/AutocompleteInput/types";

export const CountrySelect: FC = () => {
  const { t } = useTranslation();
  const { setValue } = useFormContext<PlaceFormFields>();

  const { data, loading } = useQueryHandler<CountriesQuery>(Countries);

  const baseOptions: Option[] = useMemo(() => mapToOptions("code", "name")(data?.countries || []), [data?.countries]);

  const {
    field: { onChange, ...field },
  } = useMuiController({
    name: "country",
    required: true,
  });

  const handleChange: AutocompleteInputProps["onChange"] = (e) => {
    setValue("region", "");
    onChange(e);
  };

  return (
    <AutocompleteInput
      {...field}
      FormHelperTextProps={{
        "data-cy": "locationCountryError",
      }}
      label={t("country")}
      loading={loading}
      onChange={handleChange}
      options={baseOptions}
      placeholder={t("choose")}
    />
  );
};
