import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { FormControlProps, OutlinedInput, OutlinedInputProps, useForkRef } from "@mui/material";
import { $createParagraphNode, $getRoot, $insertNodes, $isDecoratorNode, $isElementNode, LexicalEditor } from "lexical";
import isEqual from "lodash.isequal";
import { map } from "ramda";
import React, { forwardRef, type ReactNode, useEffect, useMemo, useRef } from "react";
import { FormControlWrapper, InputWrapperProps } from "../FormControlWrapper/FormControlWrapper";
import { MinimalTextEditor } from "./variants/MinimalTextEditor";
import { SimpleTextEditor } from "./variants/SimpleTextEditor";
import { PlainTextEditor } from "@sinch/components/form/TextEditor/variants/PlainTextEditor";

export interface TextEditorProps<TVariant extends "simple" | "minimal" | "plain" = "simple">
  extends Omit<OutlinedInputProps, "slotProps" | "slots" | "onChange">,
    InputWrapperProps {
  FormControlProps?: FormControlProps;
  onChange?: (value: string) => void;
  value?: string;
  maxLength?: number;
  /**
   * variant of editor, differ in list of utilities and features (bold, italic, lists, etc..)
   */
  variant?: TVariant;
  /**
   * Position of toolbar
   */
  toolbarPosition?: "top" | "bottom";
  /**
   * Add placeholders option to Simple variant
   */
  placeholders?: TVariant extends "simple" | "plain" ? { label: string; value: string }[] : never;
  editorRef?: React.Ref<LexicalEditor | null>;
  secondaryToolbar?: ReactNode;
}

/**
 * Rich text editor based on Lexical.
 */
export const TextEditor = <TVariant extends "simple" | "minimal" | "plain" = "simple">({
  label,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
  helperText,
  errorMessage,
  error,
  required,
  infoTooltip,
  onChange,
  value,
  slotProps,
  slots,
  maxLength,
  variant,
  toolbarPosition = "top",
  placeholders,
  editorRef,
  secondaryToolbar,
  ...props
}: TextEditorProps<TVariant>) => {
  const ref = useRef<LexicalEditor>(null);
  const setRef = useForkRef(ref, editorRef);
  const stringValue = useRef("");

  useEffect(() => {
    const editor = ref.current;
    if (editor) {
      const currentEditorState = editor.getEditorState().toJSON();
      editor.update(
        () => {
          if (value && value !== stringValue.current) {
            // In the browser you can use the native DOMParser API to parse the HTML string.
            const parser = new DOMParser();
            const dom = parser.parseFromString(value, "text/html");

            // Once you have the DOM instance it's easy to generate LexicalNodes.
            const nodes = $generateNodesFromDOM(editor, dom);

            const root = $getRoot();
            root.clear();

            const rootChildren = map((node) => {
              if ($isElementNode(node) || $isDecoratorNode(node)) {
                return node;
              } else {
                const p = $createParagraphNode();
                p.append(node);
                return p;
              }
            }, nodes);

            // Insert them at a selection.
            $insertNodes(rootChildren);
          }
        },
        { tag: "collaboration" }
      );

      const removeUpdateListener = editor.registerUpdateListener(({ editorState }) => {
        if (!isEqual(currentEditorState, editorState.toJSON())) {
          editorState.read(() => {
            const htmlValue = $generateHtmlFromNodes(editor, null);

            const parser = new DOMParser();
            const element = parser.parseFromString(htmlValue, "text/html");

            const result = element.body.textContent?.trim() === "" ? "" : htmlValue;
            stringValue.current = htmlValue;

            onChange?.(result);
          });
        }
      });

      return () => removeUpdateListener();
    }
  }, [value, onChange]);

  // noinspection LocalVariableNamingConventionJS
  const InputSlot = useMemo(() => {
    if (variant === "plain") {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const memoizedEditor = forwardRef((inputProps, _) => (
        <PlainTextEditor
          {...inputProps}
          innerRef={setRef}
          placeholders={placeholders}
          toolbarPosition={toolbarPosition}
        />
      ));
      memoizedEditor.displayName = "MinimalTextEditor";
      return memoizedEditor;
    }
    if (variant === "minimal") {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const memoizedEditor = forwardRef((inputProps, _) => (
        <MinimalTextEditor {...inputProps} innerRef={setRef} toolbarPosition={toolbarPosition} />
      ));
      memoizedEditor.displayName = "MinimalTextEditor";
      return memoizedEditor;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const memoizedEditor = forwardRef((inputProps, _) => (
      <SimpleTextEditor
        {...inputProps}
        innerRef={setRef}
        placeholders={placeholders}
        toolbarPosition={toolbarPosition}
      />
    ));
    memoizedEditor.displayName = "SimpleTextEditor";
    return memoizedEditor;
  }, [variant, toolbarPosition]);
  InputSlot.displayName = "InputSlot";

  return (
    <FormControlWrapper
      error={error}
      errorMessage={errorMessage}
      FormHelperTextProps={formHelperTextProps}
      helperText={helperText}
      infoTooltip={infoTooltip}
      label={label}
      required={required}
      slotProps={slotProps}
      slots={slots}
      {...formControlProps}
    >
      <OutlinedInput
        {...props}
        fullWidth
        slotProps={{
          input: { maxLength, secondaryToolbar },
        }}
        slots={{
          input: InputSlot,
        }}
      />
    </FormControlWrapper>
  );
};

declare module "@mui/material/InputBase" {
  interface InputBaseComponentsPropsOverrides {
    secondaryToolbar?: ReactNode;
  }
}
