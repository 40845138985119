import { debounce } from "@mui/material";
import { useLocalStorage } from "@sinch/hooks/utilities/useLocalStorage";
import { toLuxon } from "@sinch/utils/dateTime/toLuxon";
import { useEffect, useMemo } from "react";
import { FieldValues, useFormContext, useFormState, useWatch } from "react-hook-form";

const PERSIST_EXPIRATION_IN_DAYS = 1;

export function usePersistFormState<TFieldValues extends FieldValues>(
  localstorageKey: string,
  isAdoptionPending: boolean
) {
  const [, setStorageItem, deleteStorageItem] = useLocalStorage(localstorageKey, {
    expiration: toLuxon(new Date()).plus({ days: PERSIST_EXPIRATION_IN_DAYS }).toJSDate(),
  });
  const form = useFormContext<TFieldValues>();
  const useWatchState = useWatch<TFieldValues>();

  const { dirtyFields, isSubmitSuccessful, isSubmitted } = useFormState<TFieldValues>();
  const isSomeFieldDirty = Object.keys(dirtyFields).length === 0 && dirtyFields.constructor === Object;

  const persistState = useMemo(
    () => debounce(() => setStorageItem({ ...useWatchState, ...form.getValues() }), 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localstorageKey]
  );

  // When form is dirty, persist state
  useEffect(() => {
    if (!isSubmitted && !isAdoptionPending && !isSomeFieldDirty) {
      persistState();
    }
  }, [useWatchState, persistState, isAdoptionPending, isSomeFieldDirty, isSubmitted]);

  // When form is successfully submitted, clear the persisted state
  useEffect(() => {
    if (isSubmitSuccessful) {
      // set all fields non-dirty (they are PURIFIED!)
      form.reset(undefined, {
        keepValues: true,
        keepTouched: true,
        keepSubmitCount: true,
        keepIsValid: true,
        keepIsSubmitted: true,
        keepDefaultValues: true,
        keepErrors: true,
      });
      deleteStorageItem();
    }
  }, [form, localstorageKey, isSubmitSuccessful]);
}
