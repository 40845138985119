import type { Coordinates } from "@sinch/types/common.types";

import { InferType, number, object, string } from "yup";

export const placeSchema = object({
  name: string().default("").optional(),
  alias: string().default("").optional(),
  address: string().default("").required(),
  city: string().default("").optional(),
  zip: string().default("").optional(),
  region: string().default(null).nullable(),
  country: string().default(null).required(),
  note: string().default("").optional(),
  coordinates: object<Coordinates>({ lat: number().required(), lng: number().required() }).required(),
});

export type PlaceFormFields = InferType<typeof placeSchema>;
