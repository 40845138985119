import { createFilterOptions } from "@mui/base";
import { Autocomplete, Chip, Popper, PopperProps, styled, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { MdsAdd, MdsClose, MdsCloseXs, MdsExpandMore } from "@sinch/icons";
import { find, isEmpty, omit, prop, propEq } from "ramda";
import React, { ForwardedRef, forwardRef, useId, useMemo } from "react";

import { useTranslation } from "react-i18next";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { IconTypography } from "../../typography/IconTypography/IconTypography";
import { FormControlWrapper } from "../FormControlWrapper/FormControlWrapper";
import { InputSkeleton } from "../InputSkeleton/InputSkeleton";
import { useAutocompleteInput } from "./useAutocompleteInput";
import {
  AutocompleteAddOptionProps,
  AutocompleteInputProps,
  AutocompleteOptions,
} from "@sinch/components/form/AutocompleteInput/types";

const AddOption = ({ icon, label, value, ...props }: AutocompleteAddOptionProps) => (
  <IconTypography
    {...props}
    icon={icon}
    iconColor={(theme) => theme.palette.info.main}
    sx={{ color: (theme) => theme.palette.info.main }}
  >
    {label}
  </IconTypography>
);

function AutocompleteInputBase<
  T extends AutocompleteOptions = AutocompleteOptions,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  {
    label,
    FormHelperTextProps: formHelperTextProps,
    FormControlProps: formControlProps,
    helperText,
    errorMessage,
    error,
    required,
    infoTooltip,
    multiple,
    options = [],
    onChange,
    freeSolo,
    value,
    placeholder,
    fullWidth,
    size,
    paperWidth = "100%",
    textFieldProps,
    skeleton,
    inputReadOnly,
    disablePortal = true,
    onAddAction,
    slots,
    dropdownWidth,
    ...props
  }: AutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo>,
  ref: React.Ref<unknown>
) {
  const { t } = useTranslation();
  const addOptionId = useId();
  const noOptionsId = useId();

  const AddOptionSlot = slots?.addOption ?? AddOption;
  const addOptionProps = useMemo(
    () => ({ icon: MdsAdd, label: t("createNew"), ...props.slotProps?.addOption }),
    [props.slotProps?.addOption, t]
  );
  const addOptionValue = props.slotProps?.addOption?.value ?? addOptionId;
  const addOption = {
    value: addOptionValue,
    label: addOptionProps.label,
    option: <AddOptionSlot {...addOptionProps} />,
  };

  const noOptions = {
    value: noOptionsId,
    label: t("noOptions"),
    option: <AutocompleteNoOptions>{t("noOptions")}</AutocompleteNoOptions>,
  };

  const {
    onChange: handleChange,
    onInputChange: handleInputChange,
    value: scalarValue,
    minWidth: minInputWidthForMultiple,
  } = useAutocompleteInput<T, Multiple, DisableClearable, FreeSolo>({
    options,
    value,
    multiple,
    onChange,
    freeSolo,
    placeholder,
    onAddAction,
    addOptionValue,
  });

  const extendedOptions = useMemo(
    () => (onAddAction ? [addOption, ...options] : options),
    [onAddAction, options, addOptionProps, AddOptionSlot, t]
  );

  return (
    <FormControlWrapper
      error={error}
      errorMessage={errorMessage}
      FormHelperTextProps={formHelperTextProps}
      fullWidth={fullWidth}
      helperText={helperText}
      infoTooltip={infoTooltip}
      label={label}
      required={required}
      {...formControlProps}
    >
      <InputSkeleton active={skeleton} animation="wave" width="100%">
        <Autocomplete<T, Multiple, DisableClearable, FreeSolo>
          disablePortal={disablePortal}
          fullWidth={fullWidth}
          getOptionLabel={(option) => prop("label", option) ?? ""}
          isOptionEqualToValue={(option, val) => prop("value", option) === prop("value", val)}
          multiple={multiple}
          onChange={handleChange}
          onInputChange={(event, inputValue, reason) => {
            handleInputChange(event, inputValue, reason);
            props.onInputChange?.(event, inputValue, reason);
          }}
          renderOption={(optionProps, option) => {
            if (option.value === noOptionsId) {
              return option.option;
            }
            return <AutocompleteOption<T> {...optionProps} {...option} key={option.value} />;
          }}
          renderTags={(vals, getTagProps) =>
            vals.map((val, index) => (
              <Chip
                {...getTagProps({ index })}
                key={val?.value || index}
                deleteIcon={<MdsIcon fontSize="xs" icon={MdsCloseXs} />}
                label={val?.label}
              />
            ))
          }
          selectOnFocus={props.readOnly || inputReadOnly ? false : undefined}
          slotProps={{
            paper: { sx: { width: paperWidth }, "data-cy": "dropdownOptions" },
            popper: {
              placement: "bottom-start",
              popperOptions: {
                modifiers: [
                  {
                    name: "flip",
                    options: { padding: 32 },
                  },
                  {
                    name: "offset",
                    options: {
                      offset: [0, 2],
                    },
                  },
                ],
              },
            },
          }}
          sx={{
            pointerEvents: props.readOnly ? "none" : undefined,
          }}
          {...omit(["inputRef"], props)}
          ref={ref}
          clearIcon={<MdsIcon fontSize="small" icon={MdsClose} />}
          filterOptions={(filterOptions, state) => {
            if (props.loading) {
              return [];
            }

            const opt = (props.filterOptions || createFilterOptions<T>())(filterOptions, {
              ...state,
              inputValue: state.inputValue.trim(),
            });
            if (!isEmpty(opt) && onAddAction && !find(propEq("value", addOptionValue), opt)) {
              return [addOption, ...opt] as T[];
            } else if (isEmpty(opt) && onAddAction) {
              return [addOption, noOptions] as T[];
            }
            return opt as T[];
          }}
          freeSolo={freeSolo}
          options={extendedOptions as readonly T[]}
          PopperComponent={dropdownWidth === "input-width" ? undefined : PopperContentWidth}
          popupIcon={<MdsIcon fontSize="small" icon={MdsExpandMore} />}
          renderInput={(params) => (
            <TextField
              {...textFieldProps}
              {...params}
              error={error}
              inputProps={{
                enterKeyHint: freeSolo && multiple ? "enter" : undefined,
                ...textFieldProps?.inputProps,
                ...params.inputProps,
                readOnly: props.readOnly || inputReadOnly,
              }}
              InputProps={{
                ...textFieldProps?.InputProps,
                ...params.InputProps,
                startAdornment: (
                  <>
                    {size === "small" &&
                      !params.InputProps.startAdornment &&
                      !textFieldProps?.InputProps?.startAdornment && (
                        <Box
                          sx={{
                            paddingLeft: 1.25,
                          }}
                        />
                      )}
                    {params.InputProps.startAdornment
                      ? params.InputProps.startAdornment
                      : textFieldProps?.InputProps?.startAdornment}
                  </>
                ),
                endAdornment: !props.readOnly ? (
                  <>
                    {textFieldProps?.InputProps?.endAdornment}
                    {params.InputProps.endAdornment} {/* The "clear X" */}
                  </>
                ) : undefined,
                notched: false,
                size: size,
              }}
              inputRef={props.inputRef}
              placeholder={placeholder}
              required={required}
              sx={() => ({
                // When multiple calculate min width by placeholder
                "& .MuiAutocomplete-input.MuiInputBase-input": {
                  minWidth: minInputWidthForMultiple,
                },
              })}
              variant="outlined"
            />
          )}
          value={scalarValue}
        />
      </InputSkeleton>
    </FormControlWrapper>
  );
}

/**
 * Autocomplete input, behave as select
 * For documentation see [https://mui.com/material-ui/api/autocomplete/](https://mui.com/material-ui/api/autocomplete/)
 */
export const AutocompleteInput = forwardRef(AutocompleteInputBase) as <
  T extends AutocompleteOptions = AutocompleteOptions,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>(
  props: AutocompleteInputProps<T, Multiple, DisableClearable, FreeSolo> & {
    ref?: ForwardedRef<HTMLDivElement | undefined> | undefined;
  }
) => ReturnType<typeof AutocompleteInputBase>;

const AutocompleteOption = <T extends AutocompleteOptions = AutocompleteOptions>({
  value: optionValue,
  option,
  label: optionLabel,
  ...optionProps
}: React.HTMLAttributes<HTMLLIElement> & T) => (
  <li
    data-cy={`dropdownOption-${optionProps?.["data-option-index" as keyof React.HTMLAttributes<HTMLLIElement>]}`}
    {...optionProps}
    key={optionValue}
    style={{ whiteSpace: "nowrap" }}
  >
    {option ?? (
      <Box
        component="span"
        sx={{
          overflowX: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {optionLabel}
      </Box>
    )}
  </li>
);

const AutocompleteNoOptions = styled("div", {
  name: "MuiAutocomplete",
  slot: "NoOptions",
  overridesResolver: (props, styles) => styles.noOptions,
})(({ theme }) => ({
  color: theme.palette.text.secondary,
  padding: "14px 16px",
  margin: "0 8px",
}));

const PopperContentWidth = (props: PopperProps) => <Popper {...props} sx={{ minWidth: "fit-content" }} />;
