import type { AnyObject } from "@sinch/types/utility.types";

import { isFunction, isObject } from "ramda-adjunct";
import { FieldValues, Path, PathValue, SetValueConfig, UseFormReturn } from "react-hook-form";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSuitableFormRootData = (data: any): data is AnyObject | unknown[] => isObject(data) && !isFunction(data);

export function setFormRootData<TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues>,
  data: Partial<TFieldValues> | unknown[],
  config?: SetValueConfig
) {
  if (isSuitableFormRootData(data)) {
    Object.entries(data).forEach(([key, value]) => {
      form.setValue(key as Path<TFieldValues>, value as PathValue<TFieldValues, Path<TFieldValues>>, config);
    });
  }
}
