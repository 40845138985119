import {
  ButtonBase,
  Divider,
  InputAdornment,
  InputAdornmentProps,
  OutlinedInput,
  OutlinedInputProps,
  Stack,
  StackProps,
  styled,
  useForkRef,
} from "@mui/material";
import { MdsAddXs, MdsRemoveXs } from "@sinch/icons";

import { removeExceptFirst } from "@sinch/utils/string";
import React, { ChangeEvent, FocusEvent, ReactElement, useCallback } from "react";
import { MdsIcon } from "../../MdsIcon/MdsIcon";
import { useNumberInput } from "./useNumberInput";

/**
 * Stretch adornment to full height
 */
const FullHeightAdornment = styled(InputAdornment)<InputAdornmentProps>(() => ({
  alignSelf: "stretch",
  maxHeight: "inherit",
  height: "auto",
  //borderLeftColor: theme.palette.grey[400],
  //borderLeftWidth: "1px",
  //borderLeftStyle: "solid",
}));

/**
 * Stack for full heigt adornment
 */
const FullHeightAdornmentStack = styled(Stack)<StackProps>(({ theme }) => ({
  position: "relative",
  marginRight: "-13px",
  height: "calc( 100% - 2px)",
  width: "32px",
  "& .MuiButtonBase-root:first-of-type": {
    borderTopRightRadius: `${theme.shape.borderRadius}px`,
  },
  "& .MuiButtonBase-root:last-of-type": {
    borderBottomRightRadius: `${theme.shape.borderRadius}px`,
  },
  "& .MuiButtonBase-root": {
    padding: 0,
    height: "100%",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.custom.blue[50],
    },
  },
  "& .MuiButtonBase-root.Mui-disabled": {},
}));

export interface NumberInputBaseProps extends Omit<OutlinedInputProps, "onChange"> {
  /**
   * Step to increment/decrement value,
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number#step
   */
  step?: number | "any";
  /**
   * Allow any value, increment by step but not force it.
   */
  anyStep?: boolean;
  /**
   * change value handler, trigger when user write value or click on button
   * @param value
   */
  onChange: (value: number) => void;
  /**
   * number value
   */
  value?: number | null;
  /**
   * Minimum if input
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number#min
   */
  min?: number;
  /**
   * Maximum value of input
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/number#max
   */
  max?: number;
  /**
   * Allow empty value for number input
   */
  allowEmpty?: boolean;
  /**
   * Hide plus/minus buttons
   */
  hideButtons?: boolean;
  /**
   * Number of max decimal places, the value will be stripped to this number
   */
  decimal?: number;
}

/**
 * Number input with vertical buttons on side
 */
export function NumberInputBase({
  step,
  onChange,
  value,
  min,
  max,
  disabled,
  readOnly,
  endAdornment,
  allowEmpty,
  hideButtons,
  anyStep = step === "any",
  decimal = step === "any" ? undefined : (String(step ?? 0).split(".")[1]?.length ?? 0),
  ...props
}: NumberInputBaseProps): ReactElement {
  const { inputProps, plusButtonProps, minusButtonProps } = useNumberInput({
    disabled,
    max,
    min,
    step: step === "any" ? 1 : step,
    onChange,
    value,
    readOnly,
    anyStep,
    decimal,
  });
  const stripToDecimal = (val: number) => (decimal ? parseFloat(Number(val).toFixed(decimal)) : Number(val));

  const inputRefHandler = useForkRef(props.inputRef, inputProps.inputRef);
  // TODO: Make NumberInput special case of TextInput
  const [empty, setEmpty] = React.useState(false);
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      // Special case for number input
      let inputValue = event.target.value;
      if (inputValue === "") {
        setEmpty(true);
        if (allowEmpty) {
          onChange?.(stripToDecimal(Number(inputValue)));
        }
      } else if (inputValue !== "") {
        setEmpty(false);
        inputValue = inputValue.replace(/[^\d.-]/g, ""); // remove all non-numeric chars
        inputValue = removeExceptFirst(inputValue, ".");
        inputValue = inputValue.slice(0, 1) + inputValue.slice(1).replace("-", "");
        onChange?.(stripToDecimal(Number(inputValue)));
      } else {
        onChange?.(stripToDecimal(Number(inputValue)));
      }
    },
    [allowEmpty, onChange]
  );

  const handleBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      props.onBlur?.(e);
      const inputValue = e.target.value;
      if (allowEmpty && e.target.value === "") {
        return;
      }
      // Special case for number input
      if (e.target.value === "") {
        setEmpty(false);
        e.target.value = String(min || 0);
        inputProps.onChange?.(e);
      } else if (e.target.value !== "") {
        setEmpty(false);
        e.target.value = String(+inputValue);
        inputProps.onChange?.(e);
      }
    },
    [allowEmpty, inputProps, min, props]
  );

  return (
    <OutlinedInput
      endAdornment={
        <>
          {endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>}
          {!hideButtons && (
            <FullHeightAdornment position="end">
              <Divider orientation="vertical" />
              <FullHeightAdornmentStack
                divider={<Divider sx={{ position: "absolute", width: "100%" }} />}
                justifyContent="space-evenly"
              >
                <ButtonBase {...plusButtonProps} tabIndex={-1}>
                  <MdsIcon
                    fontSize="xs"
                    icon={MdsAddXs}
                    sx={{
                      paddingTop: "1px",
                    }}
                  />
                </ButtonBase>
                <ButtonBase {...minusButtonProps} tabIndex={-1}>
                  <MdsIcon
                    fontSize="xs"
                    icon={MdsRemoveXs}
                    sx={{
                      paddingBottom: "1px",
                    }}
                  />
                </ButtonBase>
              </FullHeightAdornmentStack>
            </FullHeightAdornment>
          )}
        </>
      }
      {...props}
      {...inputProps}
      inputProps={{ step: anyStep ? "any" : step, min, max, ...props.inputProps }}
      inputRef={inputRefHandler}
      onBlur={handleBlur}
      onChange={handleChange}
      type="number"
      value={empty ? "" : value}
    />
  );
}
