import { FetchResult, OperationVariables } from "@apollo/client";

import { captureError } from "@sinch/core/config/sentry";
import { extractMutationData, parseMutationErrorsFromData, throwIfNoMutationData } from "@sinch/utils/apollo";

import { applyErrorToForm } from "@sinch/utils/react-hook-form/errorHandling";
import { identity, prop, tap, values } from "ramda";
import { isNilOrEmpty } from "ramda-adjunct";
import { FieldValues } from "react-hook-form";
import { UseFormMutationProps, UseFormMutationReturn } from "./useFormMutation.types";
import { SinchMutations, useMutationHandler } from "./useMutationHandler";

/**
 * Create react-hook-form methods with graphql endpoint for sending data
 */
export function useFormMutation<
  TData extends SinchMutations,
  TVariables = OperationVariables,
  TFieldValues extends FieldValues = FieldValues,
  TFormContext = any,
>({
  mutation,
  form,
  variableMapper = identity as any,
}: UseFormMutationProps<TData, TVariables, TFieldValues, TFormContext>): UseFormMutationReturn<
  TData,
  TVariables,
  TFieldValues
> {
  const [mutate, { loading, error, reset, mutationMessages }] = useMutationHandler<TData, TVariables>(mutation.mutate, {
    ...mutation.baseOptions,
    errorPolicy: "all",
  });

  /**
   * Submits react-hook-forms form via graphql mutation
   */
  const handleSubmit: UseFormMutationReturn<TData, TVariables, TFieldValues>["handleSubmit"] = (options) => {
    const { variableMapper: overriddenVariableMapper, errorMapper } = options || {};
    return new Promise<TData>((resolve, reject) =>
      form.handleSubmit(async (data) => {
        form.clearErrors();

        const fillServerErrors = ({ errors, data: responseData }: FetchResult<TData>) => {
          if (errors) {
            form.clearErrors();
            Object.entries(errors).forEach(([key, value]) => {
              form.setError(`root.serverError-${key}`, {
                type: "serverError",
                message: value?.message,
              });
            });
          }
          if (responseData) {
            values(responseData).forEach((mutationResponse) => {
              if (prop("result", mutationResponse) === false && isNilOrEmpty(prop("messages", mutationResponse))) {
                form.setError(`root.responseError`, { type: "responseError" });
              }
            });
          }
        };

        const applyErrorsToForm = (responseData: TData) => {
          const errorHandler = errorMapper ?? parseMutationErrorsFromData<TData>;
          errorHandler(responseData as TData).forEach(applyErrorToForm(form as any, "serverValidation"));
        };

        const requestVariables = (overriddenVariableMapper ?? variableMapper)(data);

        return mutate({ variables: requestVariables })
          .then(tap(fillServerErrors))
          .then(extractMutationData<TData>)
          .then(tap(throwIfNoMutationData))
          .then(tap(applyErrorsToForm))
          .then(resolve)
          .catch((reason) => {
            form.clearErrors();
            form.setError("root.serverError", { type: "serverError" });
            reject(null);
            if (import.meta.env.MODE !== "production") {
              console.error(reason);
            } else {
              captureError(reason);
            }
            return null;
          });
      })()
    );
  };

  return { handleSubmit, loading, error, reset, mutationMessages };
}
