import { uuid } from "@sinch/utils/crypto";
import { flatten, isEmpty, isNil, map, sum, values } from "ramda";
import { isNumber, noop } from "ramda-adjunct";
import React, { FC, PropsWithChildren, useContext, useEffect, useId, useRef, useState } from "react";

type ProgressScope = Record<string, Record<string, number>>;
interface State {
  progress: number;
  setProgress: (scope: string, key: string, progress: number) => void;
  clearProgressScope: (scope: string) => void;
}

/**
 * Provide state for hold progress status. TODO: add mechanism to use promises
 */
export const ProgressContext = React.createContext<State>({
  progress: 100,
  setProgress: noop,
  clearProgressScope: noop,
});
ProgressContext.displayName = "ProgressContext";

/**
 * Handling progress status from multiple async operations
 */
export const ProgressProvider: FC<PropsWithChildren> = ({ children }) => {
  const progressQueue = useRef<ProgressScope>({});
  const [progress, setProgress] = useState<number>(100);

  const handleSetProgress = (scope: string, key: string, value: number) => {
    const newScopeState = { ...progressQueue.current[scope], [key]: Math.min(value, 100) };
    if (!isEmpty(values(newScopeState)) && !values(newScopeState).some((percents) => percents < 100)) {
      progressQueue.current = {
        ...progressQueue.current,
        [scope]: {},
      };
    }

    progressQueue.current = {
      ...progressQueue.current,
      [scope]: newScopeState,
    };
    updateProgressStatus();
  };

  const clearProgressScope = (scope: string) => {
    progressQueue.current = {
      ...progressQueue.current,
      [scope]: {},
    };
    updateProgressStatus();
  };

  const updateProgressStatus = () => {
    const progressValues = flatten(map(values, values(progressQueue.current)));
    const overallProgress = isEmpty(progressValues) ? 100 : sum(progressValues) / progressValues.length;
    setProgress(overallProgress);
  };

  return (
    <ProgressContext.Provider value={{ progress, setProgress: handleSetProgress, clearProgressScope }}>
      {children}
    </ProgressContext.Provider>
  );
};
interface UseProgressReturn {
  /**
   * Overall progress status
   */
  overallProgress: number;
  /**
   * Set progress status in percents, if key is not used, the global is used instead
   */
  setProgress: <TKey extends string>(key: TKey, value: number) => void;
  /**
   * Start progress status, if key is not used, the global is used instead
   */
  startProgress: (key?: string) => string;
  /**
   * Finish progress status, if key is not used, the global is used instead
   */
  finishProgress: (key: string) => void;
  /**
   * Run async operation and update progress status
   */
  progressUntil: (callback: (updateProgress: (progressStatus?: number) => void) => void) => void;
}

/**
 * Provide function for get or set progress status
 */
export function useProgress(scope?: string): UseProgressReturn {
  const uniqId = useId();
  const hookKey = scope ?? uniqId;
  const contextVal = useContext<State>(ProgressContext);

  useEffect(
    () => () => {
      if (contextVal) {
        clearProgressScope(hookKey);
      }
    },
    []
  );

  if (!contextVal) {
    return {
      overallProgress: 100,
      setProgress: noop,
      finishProgress: noop,
      startProgress: (key) => key || uuid(),
      progressUntil: noop,
    };
  }
  const { progress, setProgress, clearProgressScope } = contextVal;

  const progressUntil = (callback: (updateProgress: (progressStatus?: number) => void) => void) => {
    const progressId = uuid();
    setProgress(hookKey, progressId, 0);
    return new Promise<void>((resolve) => {
      callback((progressStatus?: number) => {
        if (isNil(progressStatus) || (isNumber(progressStatus) && progressStatus >= 100)) {
          resolve();
        } else if (isNumber(progressStatus)) {
          setProgress(hookKey, progressId, progressStatus);
        }
      });
    }).finally(() => {
      setProgress(hookKey, progressId, 100);
    });
  };

  const handleStartProgress: UseProgressReturn["startProgress"] = (key) => {
    const progressKey = key ?? uuid();
    setProgress(hookKey, progressKey, 0);
    return progressKey;
  };

  const handleFinishProgress: UseProgressReturn["finishProgress"] = (key) => {
    setProgress(hookKey, key, 100);
  };

  const handleSetProgress: UseProgressReturn["setProgress"] = (key, value) => {
    setProgress(hookKey, key, value);
  };

  return {
    overallProgress: progress,
    startProgress: handleStartProgress,
    setProgress: handleSetProgress,
    finishProgress: handleFinishProgress,
    progressUntil,
  };
}
