import {
  Box,
  FormControl,
  FormControlProps,
  FormHelperText,
  FormLabel,
  formLabelClasses,
  getFormLabelUtilityClasses,
  Stack,
  styled,
  unstable_composeClasses as composeClasses,
} from "@mui/material";
import { isEmpty, isNil, map } from "ramda";
import React, { ReactElement } from "react";
import { InputWrapperProps } from "../FormControlWrapper/FormControlWrapper";
import { InfoTooltip } from "../LabelWithInfo/InfoTooltip";

export type BinaryInputControlWrapperProps = FormControlProps & InputWrapperProps;

/**
 * Wrap binary inputs as switch, checkbox or radio
 */
export const BinaryInputControlWrapper = ({
  children,
  label,
  infoTooltip,
  helperText,
  errorMessage,
  error,
  required,
  disabled,
  FormHelperTextProps: formHelperTextProps,
  FormControlProps: formControlProps,
}: BinaryInputControlWrapperProps): ReactElement => (
  <FormControl disabled={disabled} error={error} required={required} {...formControlProps}>
    <FormLabel error={error} required={false} sx={{ width: "fit-content" }}>
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          alignItems: "center",
        }}
      >
        {children}
        <Box className="SinchBinaryInputControlWrapper-label" component="span">
          {label}
        </Box>
        {required && (
          <AsteriskComponent
            className={
              composeClasses(
                {
                  asterisk: ["asterisk", error && "error"],
                },
                getFormLabelUtilityClasses
              ).asterisk
            }
          >
            *
          </AsteriskComponent>
        )}
        {infoTooltip && <InfoTooltip onClick={(e) => e.preventDefault()} title={infoTooltip} />}
      </Stack>
    </FormLabel>

    {helperText && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
    {!isNil(errorMessage) &&
      !isEmpty(errorMessage) &&
      map(
        (message) => (
          <FormHelperText key={message} {...formHelperTextProps}>
            {message}
          </FormHelperText>
        ),
        errorMessage
      )}
  </FormControl>
);

const AsteriskComponent = styled("span", {
  name: "MuiFormLabel",
  slot: "Asterisk",
  overridesResolver: (props, styles) => styles.asterisk,
})(({ theme }) => ({
  [`&.${formLabelClasses.error}`]: {
    color: theme.palette.error.main,
  },
}));
